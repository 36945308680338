import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { AppSettings } from '../shared/app.settings';


@Injectable()
export class EncryptionDecryptionService {
    private  secretKey = AppSettings.ENCRYPTION_DECRYPTION_KEY;
    constructor() {
    }
    public encrypt(data: string): string {
        return CryptoJS.AES.encrypt(data.trim(), this.secretKey.trim()).toString();
    }
    public decrypt(data: string): string {
        return CryptoJS.AES.decrypt(data.trim(), this.secretKey.trim()).toString(CryptoJS.enc.Utf8);
    }
}
