import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { LeadFormServiceProxy } from "src/app/Services/leadForm.service";
import { User } from "src/environments/User";
import Swal from "sweetalert2";

import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { BranchServiceProxy } from "src/app/Services/branchServiceProxy.service";
import { PermissionService } from "src/app/Services/PermissionServices.service";
import { Location } from '@angular/common';

@Component({
  selector:"reception-lead-form",
  templateUrl:'./reception-lead-form.componet.html',
})

export class ReceptionLeadFormComponent implements OnInit{
  loader: any;
  public addApplication: FormGroup;
  allBranches:any;
  blist:any;

  submit = false;
  public Editor = ClassicEditor;
  constructor(
    private formBuilder: FormBuilder,
    private leadFormService: LeadFormServiceProxy,
    private branchService: BranchServiceProxy,
    private permission:PermissionService,
    private location: Location,


  ){
    this.loader = false;


  }
  backBtn(){
    this.location.back();
  }
 async getAllData(){
   let self = this;
    let promise= this.branchService.getAllData().toPromise();
    await promise.then(resp=>{
      self.allBranches = resp['Branch'];
    })
  }
  ngOnInit(){
    if(!this.blist){
      this.blist=(User.BranchId);
    }
    this.getAllData();
    this.addApplication = this.formBuilder.group({
      Id: [0],
      name: ["", [Validators.required]],
      email: [null],
      phoneNumber: [null, [Validators.required]],
      notes: [""],
      source:'',
      matterType:'',
      status:'',
      branch:'',
      branchId:[null, [Validators.required]],
    });

  }
  checkPermission=(name,key,BranchId)=>{
    var ex = this.permission.checkVisibilityByModuleName(name,key,BranchId)
    return ex
  }
  Submit(){
    if(this.addApplication.valid){
      this.submit = true;
      let data = this.addApplication.value;
      data = JSON.stringify(data);
      data = JSON.parse(data);
      data["isDeleted"] = false;
      data["isDrafted"] = true;
      data['addedByUserId']= User.Id;
      this.leadFormService.Post(data).subscribe(Response => {
        this.submit = false;
        Swal.fire("Success", "Lead Form created successfully", "success");
        this.addApplication.reset();
        // this.router.navigateByUrl(AppSettings.DraftedLeadForm_URL);

      }, error => {
        this.submit = false;

        Swal.fire("Error", error.error.error, "error");
      })

    }else{
      for( let i in this.addApplication.controls){
        this.addApplication.controls[i].markAsTouched();
      }
    }
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }
}
