import { NotificationComponent } from './../all-modules/notifications/notifications.componet';
import { PermissionService } from './../Services/PermissionServices.service';
import { User } from 'src/environments/User';
import { Component, OnInit, ViewChild } from "@angular/core";
import { Router, Event, NavigationEnd } from "@angular/router";
import { AllModulesService } from "../all-modules/all-modules.service";
import { NotifDataService } from '../Services/notifData.service';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { SocketioService } from '../Services/socketio.service';
import { DatePipe } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { AuthService } from '../Services/auth.service';
import { notificationService } from '../Services/notificationService.service';
import { loaderActivation } from 'src/environments/loaderActivation';
@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.css"],

})
export class SidebarComponent implements OnInit {

  urlComplete = {
    mainUrl: "",
    subUrl: "",
    childUrl: "",
  };
  sidebarMenus = {
    default: true,
    chat: false,
    settings: false,
  };

  members = { active: null, total: null };
  groups = { total: null };

  user: any;
  subscription: Subscription;
  constructor(
    private router: Router,
    private allModulesService: AllModulesService,
    private permissions: PermissionService,
    private data: NotifDataService,
    private socketService: SocketioService,
    private notifService: notificationService,
  ) {

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        $(".main-wrapper").removeClass('slide-nav');
        $(".sidebar-overlay").removeClass('opened');
        const url = event.url.split("/");
        this.urlComplete.mainUrl = url[1];
        this.urlComplete.subUrl = url[2];
        this.urlComplete.childUrl = url[3];
        console.log(this.urlComplete);
        if (url[1] === "") {
          this.urlComplete.mainUrl = "dashboard";
          this.urlComplete.subUrl = "admin";
        }

        if (url[2] === "chat" || url[2] === "calls") {
          this.sidebarMenus.chat = true;
          this.sidebarMenus.default = false;
        } else {
          this.sidebarMenus.chat = false;
          this.sidebarMenus.default = true;
        }
      }
    });

    this.groups = { ...this.allModulesService.groups };
    this.members = { ...this.allModulesService.members };
  }
  allNotif = []
  seenNotif = [];
  allSeenNotif = []
  notifications: any;
  Calnotifications() {
    let mySimpleFormatTo = this.pipe.transform(this.user.creationTime, "MM-dd-yyyy hh:mm:ss");
    let userDate = new Date(this.user.creationTime);

    userDate.setHours(0, 0, 0, 0);
    let self = this;
    this.notifications = this.allNotif.filter(x => {
      let check = self.pipe.transform(x.creationTime, "MM-dd-yyyy hh:mm:ss");
      let notifTime = new Date(x.creationTime);
      notifTime.setHours(0, 0, 0, 0);

      let cDate = new Date();
      cDate.setHours(0, 0, 0, 0);

      let current = notifTime.toDateString() == cDate.toDateString();

      console.log(cDate.toDateString(), notifTime.toDateString(), current);
      if ((x.sendBy != parseInt(this.user.Id.toString()) || this.user.RoleId == 1) && (notifTime >= userDate) && (x.roleId == this.user.RoleId || this.user.RoleId == 1 || x.sendTo == this.user.Id)) {
        if (x.type == 2 && this.user.RoleId == 1) {
          // let str =     x.notification_info;

          x.notification_info = x.notification_info.replace("You have been assigned", x.sendToName + " has been assigned");
          x.notification_info = x.notification_info.replace("Its your ", "Its " + x.sendToName + "'s ")

          x.notification_info = x.notification_info.replace("You are selected", x.sendToName + " has been selected ")
        }
        let check = false;
        for (let element of x.seen) {
          if (element.seenBy) {
            if (element.seenBy == this.user.Id && element.seen) {
              check = true;
              break;
            }
          }
        };
        if (check) {
          this.seenNotif.push(x);
          this.allSeenNotif.push(x);
        } else {
          return x;
        }
      }

    });

    this.notifications.forEach(element => {
      if (element['ticketId'] || element['sendTo']) {
        if (this.checkPermissions("ITForm", "Read", element['branchId']) || this.checkPermissions("ITForm", "Read", element['branchId'])) {
          if (element.seen === false) {

          }
        }
      }
    });
    this.allNotif = this.notifications;
    this.seen = this.notifications.length;

    console.log(this.notifications)
  }
  async getData() {

    loaderActivation.loader = true;
    // await this.authservice.checkToken();
    this.seenNotif = []
    this.allNotif = []
    this.allSeenNotif = []
    this.notifications = []
    const promise = this.notifService.getNotif(new Date().toISOString()).toPromise()

    this.notifications = [];
    await promise.then(resp => {

      this.allNotif = resp['data'];
      console.log(this.notifications);
      this.Calnotifications();
    }, error => {
      console.log(error);
    })
    loaderActivation.loader = false;
  }

  checkPermission = (name, key) => {
    var ex = this.permissions.checkVisibilityByModuleName(name, key, this.user.BranchId)

    return ex
  }
  seen = 0;
  public pipe = new DatePipe("en-US");

  checkPermissions = (name, key, branchId) => {

    if (!branchId) {
      branchId = User.BranchId
    }
    var ex = this.permissions.checkVisibilityByModuleName(name, key, branchId)


    return ex
  }
  ngAfterViewInit() {
    this.getData();
  }
  async ngOnInit() {

    await User;
    this.user = User

    this.seen = 0;
    this.subscription = this.data.currentMessage.subscribe(message =>
      this.seen = message
    )
    // this.data.changeNotif(1);



    // Slide up and down of menus
    $(document).on("click", "#sidebar-menu a", function (e) {
      e.stopImmediatePropagation();
      if ($(this).parent().hasClass("submenu")) {
        e.preventDefault();
      }
      if (!$(this).hasClass("subdrop")) {
        $("ul", $(this).parents("ul:first")).slideUp(350);
        $("a", $(this).parents("ul:first")).removeClass("subdrop");
        $(this).next("ul").slideDown(350);
        $(this).addClass("subdrop");
      } else if ($(this).hasClass("subdrop")) {
        $(this).removeClass("subdrop");
        $(this).next("ul").slideUp(350);
      }
    });
    this.socketService.setupNotficationSocket();
    this.socketService.notification_socket.ondisconnect = (msg) => {
      console.log("disconnect", msg);
    }
    this.socketService.notification_socket.onmessage = (msg) => {
      // this.authservice.checkToken();

      let data = JSON.parse(msg['data'])['payload']['data']
      if (data) {
        data = JSON.parse(data);
      }
      let mySimpleFormatTo = this.pipe.transform(this.user.creationTime, "MM-dd-yyyy hh:mm:ss");
      let userDate = new Date(this.user.creationTime);

      let check = this.pipe.transform(data.creationTime, "MM-dd-yyyy hh:mm:ss");
      let notifTime = new Date(data.creationTime);
      if (data) {
        if ((data['sendBy'] != this.user.Id) && (notifTime >= userDate) && (data['roleId'] == this.user.RoleId || this.user.RoleId == 1 || data['sendTo'] == this.user.Id)) {
          if (data.type == 2 && this.user.RoleId == 1) {
            // let str =     x.notification_info;

            data.notification_info = data.notification_info.replace("You have been assigned", data.sendToName + " has been assigned");
            data.notification_info = data.notification_info.replace("Its your ", "Its " + data.sendToName + "'s ")

            data.notification_info = data.notification_info.replace("You are selected", data.sendToName + " has been selected ")
          }


          // if(this.checkPermissions("ITForm","Read",data['branchId']) || this.checkPermissions("Application Process","Read",data['branchId'])){
          console.log("swal");
          Swal.fire({
            text: data['notification_info'],

            // background: '#ccc',

            icon: "success",
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            showClass: {

              popup: 'swal2-show',


            },
            hideClass: {
              popup: 'swal2-hide'
            },

            timer: 5000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener('mouseenter', Swal.stopTimer)
              toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
          });
        }
        // } 
        this.notifications.push(data)
        this.allNotif.push(data);

        // this.getData();    

        //  }
      }
    }

    this.socketService.notification_socket.onerror = (error) => {
      console.log(`The server received: ${error['code']}`);
      this.socketService.setupNotficationSocket();
    }

    this.socketService.notification_socket.onclose = (why) => {
      console.log(`The server received: ${why.code} ${why.reason}`
      );
      this.socketService.setupNotficationSocket();
    }
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  setActive(member) {
    this.allModulesService.members.active = member;
  }
}
