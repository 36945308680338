
import { AuthService } from './../../Services/auth.service';
import { Router, ActivatedRoute, RouterStateSnapshot, RoutesRecognized, NavigationEnd } from '@angular/router';

import { User } from '../../../environments/User';
import Swal from 'sweetalert2';


import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';
import { loaderActivation } from 'src/environments/loaderActivation';

import { filter, pairwise } from 'rxjs/operators';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],

})
export class LoginComponent implements OnInit {

  
  loginForm :FormGroup;
  constructor(    private title : Title,private http: HttpClient,private fb: FormBuilder, private authService: AuthService,
    private _route:ActivatedRoute,   private toastr: ToastrService,private route:Router) { }

  ngOnInit() {
    this.title.setTitle('Login');
    User.isLoggedin=(localStorage.getItem('loggedin')==='true');

    if(User.isLoggedin===true){
  
      if(this.route.url==='/login'){
        if(localStorage.getItem("preUrl") && localStorage.getItem("preUrl")!="/login"){
          this.route.navigateByUrl(localStorage.getItem("preUrl"));
         }else{
           this.route.navigateByUrl("/dashboard")
         }
      }else{
        this.authService.logout();
        this.route.navigateByUrl("login");
      }


     }else{
      this.route.navigateByUrl('login')
     }
    this.loginForm =this.fb.group({
      email:["",Validators.email],
      pass:["",Validators.required],
  })
  }
 error: any;
 Login=()=>{
  loaderActivation.loader=true;
    this.authService.login(this.loginForm.value['email'], this.loginForm.value['pass']).subscribe(
      success => {
        if(localStorage.getItem("preUrl") && localStorage.getItem("preUrl")!="/login"){
         this.route.navigateByUrl(localStorage.getItem("preUrl"));
        }else{
          this.route.navigateByUrl("/dashboard")
        }
    },
      error => {this.error = error
        loaderActivation.loader=false;
      Swal.fire("","Invalid Credentials or you account is not active","error");
    }
    );

 }
}
