import { filter } from 'rxjs/operators';
import { Component, Inject } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { CaseInvoiceServiceProxy } from 'src/app/Services/caseInvoiceServiceProxy.service';
import { OfficeAccountServiceProxy } from "src/app/Services/officeAccountServiceProxy";
import Swal from "sweetalert2";
import { DraftedClientEditComponent } from "../CaseWorkerDetailModal/drafted-client-edit/drafted-client-edit.component";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
declare const $: any;

@Component({
    selector: 'drafted-client',
    templateUrl: 'notify-account-dialog.component.html',
  })
  export class NotifyAccountComponent  {
    caseData:any;
    clientAccount:any;
    officeInvoices:any;
    officeInvoiceId="null";
    transactionDate: any;
    typeOfPayment=['Disbursement','Legal FEE'];
    paymentMethod = ['BACS', 'Cheque', 'Cash', 'Card'];

    public addForm: FormGroup;
  submit: any=false;

    constructor(
        public dialogRef: MatDialogRef<NotifyAccountComponent>,
        private officeAccountService: OfficeAccountServiceProxy,
        private caseInvoiceService: CaseInvoiceServiceProxy,
        private formBuilder: FormBuilder,


        @Inject(MAT_DIALOG_DATA) public data: {data},
        private _dialog: MatDialog,
        ) {
            this.caseData = data.data;
        }



          async ngOnInit() {
            this.addForm = this.formBuilder.group({
              Id: [0],
              paymentType: ["", [Validators.required]],
              paymentMethod: [this.caseData.clientName, [Validators.required]],
              receiptForm: [this.caseData.clientName+','+this.caseData.address, [Validators.required]],
              feePaid: ["", [Validators.required]],
              caseId: [this.caseData.Id],
              creationTime:[new Date(), [Validators.required]]
            });
           }

    close(){
      this.dialogRef.close();
    }

    Submit(){
      if(this.addForm.valid){
        let data = this.addForm.value;
        this.submit = true;
        data = JSON.stringify(data);
        data = JSON.parse(data);
        data['agreedCost']= 0;
        data['referenceOfPayment']= "";
        data['feePaid'] = parseFloat(data['feePaid']).toFixed(2)
        let self = this;
      this.caseInvoiceService.PostCaseInvoice(data).subscribe(function(resp){
        // self.getData();
        Swal.fire(
          'Success!',
          'Account is Notified Successfully',
          'success'
        );
        this.submit = false;
        self.close();
      },error=>{
        this.submit = false;

      })
        }
      else{
        for(let i in this.addForm.controls){
          this.addForm.controls[i].markAsTouched();
        }
      }

    }
  }
