import { SocketioService } from 'src/app/Services/socketio.service';

import { Event } from '@angular/router';
import { PermissionService } from './../../Services/PermissionServices.service';
import { DataTableDirective } from 'angular-datatables';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppSettings } from 'src/app/shared/app.settings';
import { BranchServiceProxy } from './../../Services/branchServiceProxy.service';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Component, OnInit, ViewChild } from "@angular/core";
import { User } from 'src/environments/User';
import Swal from 'sweetalert2';
import { RoleServiceProxy } from 'src/app/Services/rolesServiceProxy.service';
import { loaderActivation } from 'src/environments/loaderActivation';
import { Location } from '@angular/common';

declare const $: any;
@Component({
  selector: "branches",
  templateUrl: "./roles.component.html",

})
export class RoleComponent implements OnInit {
  dtOptions: DataTables.Settings = {};
  @ViewChild(DataTableDirective, { static: false })
  public dtElement: DataTableDirective;
  public allRoles: any = [];
  public tempId: any;
  public addRoleGroup: FormGroup;
  public editRoleForm: FormGroup;
  permissions: any;
  moduleName: string;
  editPermissions: any=[];
  public coloumn : any;
  public dtTrigger: Subject<any> = new Subject();
  loader:any=false;
  constructor(private titleservice: Title,
    private route: Router,
    private roleService: RoleServiceProxy,
    private formBuilder: FormBuilder,
    private permission: PermissionService,
    private location: Location,
    private socketService: SocketioService) {
      this.moduleName = 'Roles';


  }
  backBtn(){
    this.location.back();
  }
  DashboardNavigate() {
    this.route.navigateByUrl(AppSettings.DASHBOARD_URL);
  }
  async getData() {
    this.loader = true;
    loaderActivation.loader=true;
    let self = this;
    const promise = this.roleService.getAllData().toPromise()
    await promise.then(function (response) {
      // console.log(response,"Roles");
      self.allRoles = response['data'];
      self.allRoles = self.allRoles.filter(function (item) {
        var val = "super";
        var index = item.Name.toLowerCase().indexOf(val)
        if (index < 0) { return item; }


      });
      self.permissions = response['module'];
      self.permissions.forEach(element => {
        element['SelectAll']= false;
      });
    }, error => {
      // console.log("error",error)
    });
    // setTimeout(() => {
    //   this.loader= false
    // }, 1000);
    this.coloumn = [
      {'Name':'Id','property':'Id','type':'Id'},
      {'Name':'Name','property':'Name','type':'text'},
      {'Name':'Actions','property':'','type':'actions'}
    ]
    this.loader = false;
    loaderActivation.loader=false;
  }

level(e,i,le){
  if(e.target.checked){
    le.level = i;
  }
}
  user: any;
  checkList: any = []
  clearList() {
    this.checkList = []

  }
  SelectAll() {
    this.allRoles.forEach(element => {
      if (element.Id != 1) {
        this.checkList.push(element.Id)
      }
    });
  }
  Check(Id) {
    const index = this.checkList.indexOf(Id);
    if (index > -1) { return true }
    else {
      return false
    }
  }
  selectAll(e,p){
    if(e.target.checked){
      p.Read= true;
      p.Update = true;
      p.Create = true;
      p.Delete = true;
      p.Visiblity = true;
    }else{
      p.Read= false;
      p.Update = false;
      p.Create = false;
      p.Delete = false;
      p.Visiblity = false;
    }
  }
  changeSelect(e,p){
    if(!e.target.checked){
      p.SelectAll= false;
    }
    else if(p.Read && p.Update && p.Create && p.Delete && p.Visiblity){
      p.SelectAll = true;
    }
  }
  checkChange(e, Id) {
    if (e.target.checked) {
      this.checkList.push(Id)
    } else {
      const index = this.checkList.indexOf(Id);
      if (index > -1) {
        this.checkList.splice(index, 1);
      }

    }
  }
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.dtTrigger.next();
    }, 1000);
  }
  checkPermission = (name, key) => {
    var ex = this.permission.checkVisibilityByModuleName(name, key, User.BranchId)


    return ex
  }
  ngOnInit() {
    this.getData();
    this.socketService.userSocket.onmessage = (msg) => {
      console.log(msg)
    }

    this.socketService.userSocket.onerror = (error) =>
      console.log(`The server received: ${error['code']}`
      );
    this.socketService.userSocket.onclose = (why) =>
      console.log(`The server received: ${why.code} ${why.reason}`
      );
    $('[data-toggle="tooltip"]').tooltip();
    this.user = User;
    if (!this.checkPermission("Roles", "Visiblity")) {
      this.route.navigateByUrl("error401");
    }
    this.titleservice.setTitle("Roles");
    // this.dtOptions = {
    //     // ... skipped ...
    //     pageLength: 10,
    //     dom: "lrtip",
    //   };
    this.addRoleGroup = this.formBuilder.group({
      Id: [0],
      Name: ["", [Validators.required]],

    });

    this.editRoleForm = this.formBuilder.group({
      Id: [0],
      Name: ["", [Validators.required]],

    });
  }
  // async rerender() {
  //   $("#datatable").DataTable().clear();


  //   this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
  //     // dtInstance.clear().draw();
  //     dtInstance.destroy();

  //     // setTimeout(() => {
  //     //   this.dtTrigger.next();
  //     // }, 100);

  //   });
  //   this.allRoles = [];
  //   await this.getData();

  //   this.dtTrigger.next();

  // }
  edit(value) {
    $("#edit_role").appendTo("body");
    $('#edit_role').modal('show');
    let toSetValues= value;
    if(Number.isInteger(value)){
      toSetValues = this.allRoles.find(x=>x.Id=== value);

    }
    else{
      toSetValues = this.allRoles.find(x=>x.Id=== value.Id);

    }

    this.editPermissions = toSetValues.Permissions;
    this.editPermissions.forEach(element => {
      element['SelectAll']= false;
    });
    // console.log(this.editPermissions,"perm")
    this.editRoleForm = this.formBuilder.group({
      Id: toSetValues.Id,
      Name: toSetValues.Name,


      // isActive:toSetValues.fields.isActive
    });
  }
  VisibleClicked(e, p) {
    if (!e.target.checked) {
      p.Read = false;
      p.Update = false;
      p.Create = false;
      p.Delete = false;
      p.Visiblity = false;
      p.SelectAll= false;
    } else if(p.Read && p.Update && p.Create && p.Delete && p.Visiblity){
      p.SelectAll = true;
      p.Visiblity = true;
    } else {
      p.Visiblity = true
    }
  }
  addRole = () => {
    if (this.addRoleGroup.valid) {
      let data = this.addRoleGroup.value;
      data = JSON.stringify(data);
      data = JSON.parse(data);
      data["isDeleted"] = false;
      data["rolePermission"] = this.permissions;
      this.roleService.PostRole(data).subscribe(Response => {
        Swal.fire("Success", "Role created successfully", "success");
        this.addRoleGroup.reset();
        this.getData();
        // this.rerender();
        if (   this.socketService.userSocket.readyState === WebSocket.CLOSED ||this.socketService.userSocket.readyState ===WebSocket.CLOSING ) {
          this.socketService.setupUserConnetion()
        }
        this.socketService.sendUserData()
        $('#add_role').modal('hide');
      }, error => {

        Swal.fire("Error", error.error.error, "error");
      })

    }
  }
  createRole() {
    $("#add_role").appendTo("body");
    $('#add_role').modal('show');
    this.addRoleGroup.reset();
  }
  checkOtherPerm(p) {
    if (p.Delete || p.Create || p.Update || p.Read) {
      p.Visiblity = true
      return true;
    } else {
      p.Visiblity = false
      return false
    }
  }
  editRole() {
    if (this.editRoleForm.valid) {
      let data = this.editRoleForm.value;
      data = JSON.stringify(data);
      data = JSON.parse(data);
      data["isDeleted"] = false;
      data["rolePermission"] = this.editPermissions;
      this.roleService.PostRole(data).subscribe(Response => {
        Swal.fire("Success", "Role updated successfully", "success");
        this.editRoleForm.reset();
        // this.rerender();
        if (   this.socketService.userSocket.readyState === WebSocket.CLOSED ||this.socketService.userSocket.readyState ===WebSocket.CLOSING ) {
          this.socketService.setupUserConnetion()
        }
        this.socketService.sendUserData()
        $('#edit_role').modal('hide');
        this.getData();
      }, error => {
        Swal.fire("Error", error.error.error, "error");
      })

    }
  }
  async deleteSelected(checkL) {
    await checkL.forEach(element => {
      let toSetValues = this.allRoles.find(x => x.Id === element);
      toSetValues['isDeleted'] = true;
      toSetValues["rolePermission"] = []
      const promise = this.roleService.PostRole(toSetValues).toPromise()
      promise.then(Response => {

        this.tempId = -1;



      }, error => {
        // console.log("",error.toString(),"error");
        Swal.fire("Error", "Something is not right", "error");
      })
    });
    this.getData();
    this.checkList = [];
    Swal.fire("Success", "Roles deleted successfully", "success");
    $("#delete_selected_role").modal("hide");
  }
  deleteRole(id) {
    let toSetValues = this.allRoles.find(x => x.Id === id);
    toSetValues['isDeleted'] = true;
    toSetValues["rolePermission"] = []
    this.roleService.PostRole(toSetValues).subscribe(Response => {
      Swal.fire("Success", "Role deleted successfully", "success");
      this.tempId = -1;

      this.getData();
      $("#delete_role").modal("hide");
    }, error => {
      // console.log("",error.toString(),"error");
      Swal.fire("Error", "Something is not right", "error");
    })
  }
}
