import { AuthService } from './Services/auth.service';
import { SocketioService } from 'src/app/Services/socketio.service';
import { loaderActivation } from './../environments/loaderActivation';
import { AllModulesService } from './all-modules/all-modules.service';

import { User } from './../environments/User';
import { Title } from '@angular/platform-browser';
import { Component, OnInit, HostListener, NgZone, AfterViewInit, ViewChild, ChangeDetectorRef } from "@angular/core";
import { Router, Event, NavigationEnd } from "@angular/router";
import { NotificationComponent } from './all-modules/notifications/notifications.componet';
import { NotifDataService } from './Services/notifData.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  urlComplete = {
    mainUrl: "",
    subUrl: "",
    childUrl: "",
  };
   showDiv=true;
  user=User;
  loader = loaderActivation;
   constructor(private route:Router,private cdr: ChangeDetectorRef,private titleService: Title,private ngZone: NgZone, private router: Router,private allModulesService: AllModulesService,private socketService:SocketioService,
    private authService:AuthService,private data:NotifDataService){
    // this.titleService.setTitle("Login");

    window.onresize = (e) => {
      this.ngZone.run(() => {
        this.innerHeight = window.innerHeight + "px";
      });
    };
    this.getScreenHeight();
  }

ngAfterViewInit() {
this.data.sendClickEvent();
this.cdr.detectChanges();

}

  public innerHeight: any;

  getScreenHeight() {
    this.innerHeight = window.innerHeight + "px";
  }


  onResize(event) {
    this.innerHeight = event.target.innerHeight + "px";
  }
  ngOnInit() {
    this.socketService.setupUserConnetion();
  this.socketService.userSocket.onmessage=(msg)=>{
    console.log(msg,"app Component");
    this.authService.checkToken();
}

this.socketService.userSocket.onerror = (error) =>
    console.log(`The server received: ${error['code']}`
    );
    this.socketService.userSocket.onclose = (why) =>
    console.log(`The server received: ${why.code} ${why.reason}`
    );
    User.isLoggedin=(localStorage.getItem('loggedin')==='true');

    if(User.isLoggedin===false){

      this.route.navigateByUrl('login')


     }
     this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {

        const url = event.url.split("/");
        this.urlComplete.mainUrl = url[1];
        this.urlComplete.subUrl = url[2];
        this.urlComplete.childUrl = url[3];
        // console.log(this.urlComplete);
        if(this.urlComplete.mainUrl==="error404" || this.urlComplete.mainUrl==="error500"|| this.urlComplete.mainUrl==="error401"){
this.showDiv=false;
        }
else{
  this.showDiv=true;
}
if(this.urlComplete.mainUrl==="" && this.user.isLoggedin===true){
  this.route.navigateByUrl('dashboard')
}else if(this.urlComplete.mainUrl==="" && this.user.isLoggedin===false){
  this.route.navigateByUrl('login')
}

      }
    });
    // Minified Sidebar

    $(document).on('click', '#toggle_btn', () => {
      if ($('body').hasClass('mini-sidebar')) {
        $('body').removeClass('mini-sidebar');
        $('.subdrop + ul').slideDown();
      } else {
        $('body').addClass('mini-sidebar');
        $('.subdrop + ul').slideUp();
      }
      return false;
    });

    $(document).on('mouseover', (e) => {
      e.stopPropagation();
      if ($('body').hasClass('mini-sidebar') && $('#toggle_btn').is(':visible')) {
        const targ = $(e.target).closest('.sidebar').length;
        if (targ) {
          $('body').addClass('expand-menu');
          $('.subdrop + ul').slideDown();
        } else {
          $('body').removeClass('expand-menu');
          $('.subdrop + ul').slideUp();
        }
        return false;
      }
    });
    $('body').append('<div class="sidebar-overlay"></div>');
    $(document).on('click', '#mobile_btn', function() {
      var $wrapper = $('.main-wrapper');
      $wrapper.toggleClass('slide-nav');
      $('.sidebar-overlay').toggleClass('opened');
      $('html').addClass('menu-opened');
      $('#task_window').removeClass('opened');
      return false;
    });

    $(".sidebar-overlay").on("click", function () {
      var $wrapper = $('.main-wrapper');
        $('html').removeClass('menu-opened');
        $(this).removeClass('opened');
        $wrapper.removeClass('slide-nav');
        $('.sidebar-overlay').removeClass('opened');
        $('#task_window').removeClass('opened');
    });
  }
}
