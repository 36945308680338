import { CaseListModal } from './CaseListModal/CaseListModal';
import { SocketioService } from '../../Services/socketio.service';

import { AuthService } from '../../Services/auth.service';
import { PermissionService } from '../../Services/PermissionServices.service';
import { BranchServiceProxy } from '../../Services/branchServiceProxy.service';
import { User } from '../../../environments/User';
import Swal from 'sweetalert2';
import { deepCopy } from '@angular-devkit/core/src/utils/object';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AppSettings } from 'src/app/shared/app.settings';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';


import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { UserServiceProxy } from '../../Services/userServiceProxy.service';
import { AutoCompleteModel } from 'src/@shared-components/auto-complete/models/auto-model';
import { MatDialog } from '@angular/material/dialog';
import { CaseServiceProxy } from 'src/app/Services/caseServiceProxy.service';
import { EncryptionDecryptionService } from 'src/app/Services/encryption-decryption.service';
import { CaseWorkerDetailModal } from '../to-do/CaseWorkerDetailModal/CaseWorkerDetailModal';
import { loaderActivation } from 'src/environments/loaderActivation';

declare const $: any;
@Component({
  templateUrl: './application-process-started.component.html',

})
export class ApplicationProcessStartedComponent implements OnInit {
 dashboardRoute: any;
 public coloumn : any;
  moduleName: string;
  loader:any=false;
  blist:any;
  allCases:any = [];
  ActivecaseList = [];
 AwaitingcaseList = [];
 AwaitingCompletioncaseList = [];
 ArchivecaseList = [];
 archiveCol = [];
 CompletionCol=[];
 caseWorker :boolean= false;
 personResponsible :boolean= false;
 await:boolean=false;
 AwaitCol:any=[];
 user=User
  constructor(
    private title : Title,
    private formBuilder: FormBuilder,
    private router: Router,
    private permission:PermissionService,
    private auth:AuthService,
    private _dialog: MatDialog,
    private socketService:SocketioService,
    private caseService: CaseServiceProxy,
    private encryptionDecryptionService: EncryptionDecryptionService,
  ) {

    this.moduleName = "Application Process";
       }
 async getData(){

  let self = this;
  var check=false;


  this.archiveCol = [];
 this.CompletionCol=[];



 this.AwaitCol=[];

 const promise=  this.caseService.getAllData().toPromise();
  await promise.then(function(response){
    self.allCases = [];
    self.ActivecaseList = [];
    self.AwaitingcaseList = [];
    self.AwaitingCompletioncaseList = [];
    self.ArchivecaseList = [];

    let data = response['result'];
    data.forEach(element => {
      if(element['isDraft']){
        self.allCases.push(element);

      }

    });
    self.caseWorker =false;
    self.personResponsible= false;
    self.await = false;
    self.allCases.forEach(el => {
      let total = 0;
      let completed = 0;
      let caseWorkerList = el.caseWorkerList;
      let totalCompleted=0;


      if(el["personResposibleId"]==self.user.Id ){
        self.personResponsible = true;
      }
    if(caseWorkerList.length>0){

      caseWorkerList.forEach(element => {
        if(element.status==2){
          totalCompleted +=1;
        }
        if(element['userId']===self.user.Id ){
          self.caseWorker =true;
        }
        if(element['caseWorkerGroupList'].length>0){
          element['caseWorkerGroupList'].forEach(element1 => {
            total+=1;
            if(element1['isCompleted']){
              completed+=1;
            }
          });
        }
      });
    }
    if((totalCompleted==caseWorkerList.length && el["personResposibleId"]==self.user.Id && el['caseStatus']===0)){
      el["approve"] = true
      check=true;
    }else{
      el["approve"]=false
    }
    if(self.caseWorker && el['caseStatus']===1){
      self.await=true;
    }
    let status = completed.toString() + ' / ' + total.toString();
    el['status'] = status;
    if(el['caseStatus']===0){
      self.ActivecaseList.push(el)
    }else if(el['caseStatus']===1 && (self.caseWorker)){
      self.AwaitingcaseList.push(el)
    }else if(el['caseStatus']===2  && (el["personResposibleId"]==self.user.Id)){
      el["archive"] = true
      self.AwaitingCompletioncaseList.push(el)
    }
    else if(el['caseStatus']===3  && (el["personResposibleId"]==self.user.Id)){
      self.ArchivecaseList.push(el)
    }
    });



  });
  if(!this.blist){
    this.blist=(User.BranchId);
   }
this.coloumn = [
  {'Name':'Id','property':'Id','type':'Id'},
  {'Name':'Client Name','property':'clientName','type':'text'},
  {'Name':'Matter Type','property':'matterName','type':'text'},
  {'Name':'Creation Date','property':'creationTime','type':'date'},
  {'Name':'Deadline','property':'deadLine','type':'date'},
  {'Name':'Status','property':'status','type':'text'},
  {'Name':'Actions','property':'','type':'actions'}
];
this.archiveCol =  [
  {'Name':'Id','property':'Id','type':'Id'},
  {'Name':'Client Name','property':'clientName','type':'text'},
  {'Name':'Matter Type','property':'matterName','type':'text'},
  {'Name':'Creation Date','property':'creationTime','type':'date'},
  {'Name':'Deadline','property':'deadLine','type':'date'},
  {'Name':'Status','property':'status','type':'text'},
  {'Name':'Actions','property':'','type':'actions'}
];
this.CompletionCol =  [
  {'Name':'Id','property':'Id','type':'Id'},
  {'Name':'Client Name','property':'clientName','type':'text'},
  {'Name':'Matter Type','property':'matterName','type':'text'},
  {'Name':'Creation Date','property':'creationTime','type':'date'},
  {'Name':'Deadline','property':'deadLine','type':'date'},
  {'Name':'Status','property':'status','type':'text'},
  {'Name':'Actions','property':'','type':'actions'}
];
this.AwaitCol = [
  {'Name':'Id','property':'Id','type':'Id'},
  {'Name':'Client Name','property':'clientName','type':'text'},
  {'Name':'Matter Type','property':'matterName','type':'text'},
  {'Name':'Creation Date','property':'creationTime','type':'date'},
  {'Name':'Deadline','property':'deadLine','type':'date'},
  {'Name':'Status','property':'status','type':'text'},
  {'Name':'Actions','property':'','type':'actions'}
];
if(check ){
this.coloumn.push({'Name':'Verification','property':'approve',"type":"approve"})
}
if( this.personResponsible){
  this.CompletionCol.push({'Name':'Verification','property':'archive',"type":"archive"})
}
if(this.AwaitingcaseList.length>0){
  this.AwaitCol.push({'Name':'Verification','property':'approve',"type":"approveAwait"})
}
this.loader = false;
loaderActivation.loader=false;
  }
  grant(data){
      this._dialog.open(CaseListModal,{
      width:'400px',
      height:'450px',
      disableClose: true ,
      data: {
        case:data,
        reject:false,
        isEditable:true
      }
    }).afterClosed().subscribe(async (result) => {
      // this.getData();
  });
  }
  Reject(data){
    this._dialog.open(CaseListModal,{
      width:'400px',
      disableClose: true ,
      height:'450px',
      data: {
        case:data,
        reject:true,
        isEditable:true
      }
    }).afterClosed().subscribe(async (result) => {
      // this.getData();
  });
  }
  createApplicationProcess(){
  //   this._dialog.open(ApplicationProcessCreateComponent,{
  //     width:'800px',
  //     height:'750px',
  //     data: {
  //     }
  //   }).afterClosed().subscribe(async (result) => {
  //     this.getData();
  // });
  }
  ngAfterViewInit(): void {

  }
  checkPermission=(name,key,BranchId)=>{
    var ex = this.permission.checkVisibilityByModuleName(name,key,BranchId)
    return ex
  }
  ngOnInit() {
    if(!this.checkPermission("Application Process","Visiblity",User.BranchId)){
      this.router.navigateByUrl("error401");
  }
  this.loader = true;
  loaderActivation.loader=true;
  this.socketService.setupCaseSocket();
  this.socketService.caseSocket.onmessage=(msg)=>{
this.getData();
}

this.socketService.caseSocket.onerror = (error) =>
    console.log(`The server received: ${error['code']}`
    );
    this.socketService.caseSocket.onclose = (why) =>
    console.log(`The server received: ${why.code} ${why.reason}`
    );

    this.getData();
    this.title.setTitle('Application Process');
  }
  edit(value) {
    this._dialog.open(CaseWorkerDetailModal,{width:'800px',
  height:'768px',
  disableClose: true ,
  data: {
  "data": value,
  "disabled":true,
  }
}).afterClosed().subscribe(async (result) => {
});
  }
  async deleteSelected(checkL){
    await checkL.forEach(element => {
       let toSetValues = this.allCases.find(x=>x.Id=== element);
       toSetValues['isDeleted']=true;
       toSetValues["addedByUserId"] = User.Id;
       const promise = this.caseService.PostCase(toSetValues).toPromise();
       promise.then(Response=>{
          //  this.getData();
          },error=>{
           Swal.fire("Error","Something is not right","error");
          })
     });
    //  this.getData();
     Swal.fire("Success","Case deleted successfully","success");
   }
  deleteCase(id){
    let toSetValues = this.allCases.find(x=>x.Id=== id);
    toSetValues['isDeleted']=true;
    toSetValues["addedByUserId"] = User.Id;
    this.caseService.PostCase(toSetValues).subscribe(Response=>{
        Swal.fire("Success","Case deleted successfully","success");
        // this.getData();
       },error=>{
        Swal.fire("Error","Something is not right","error");
       });
}
  DashboardNavigate()
  {
    this.router.navigateByUrl(AppSettings.DASHBOARD_URL);
  }
  approveCompletion(data){
var reject = false;
    if(data['visaExpiry']){
      reject = false;
    }else{
      reject = true
    }
    this._dialog.open(CaseListModal,{
      width:'400px',
      height:'450px',
      disableClose: true ,
      data: {
        case:data,
        reject:reject,
        isEditable:true
      }
    }).afterClosed().subscribe(async (result) => {
      // this.getData();

    var self = this;
    Swal.fire({
      title: 'Are you sure to archive this?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.isConfirmed) {
    data['caseStatus']=3
    data['addedByUserId']=User.Id;
    self.caseService.PostCase(data).subscribe(function(resp){
      // self.getData();
      Swal.fire(
        'Update!',
        'Case has been updated.',
        'success'
      )
    })
  }
});
});



}
  approveClick(data){
    var self = this;
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.isConfirmed) {
    data['caseStatus']=1
    data['addedByUserId']=User.Id;
    self.caseService.PostCase(data).subscribe(function(resp){
      // self.getData();
      Swal.fire(
        'Update!',
        'Case has been updated.',
        'success'
      )
    })
  }
});
  }
}
