import { tap, shareReplay } from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { environment } from './../../environments/environment';
import { HttpClient, HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';



@Injectable()
export class notificationService{
    private apiRoot = environment.apiURL;

    constructor(private http: HttpClient) { }
    getNotif(data){
        return this.http.get(this.apiRoot.concat('notification/?date='+data)).pipe(
            tap(response =>{
      
              }),
            shareReplay(),
          );
    }
    seen(data){
        return this.http.post(this.apiRoot.concat('seen/'),data).pipe(
            tap(response =>{
      
              }),
            shareReplay(),
          );
    }
}