import { SelectMarkettingDialougeComponent } from './all-modules/marketing/select-marketting-dialogue/select-marketting-dialogue.component';
import { MorrisJsModule } from 'angular-morris-js';
import { CaseListModal } from './all-modules/application-process-started/CaseListModal/CaseListModal';
import { CaseWorkerDetailModal } from './all-modules/to-do/CaseWorkerDetailModal/CaseWorkerDetailModal';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSnackBarModule } from '@angular/material/snack-bar'
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button'
import { MatGridListModule } from '@angular/material/grid-list'
import { MatSlideToggleModule } from '@angular/material/slide-toggle'
import { MatRadioModule } from '@angular/material/radio'
import { MatInputModule } from '@angular/material/input'
import { MatDialogModule } from '@angular/material/dialog'
import { CryptoService } from './Services/CryptoService.service';
import { ticket } from 'src/app/shared/ticket';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { DocumentFileServiceProxy } from './Services/document.service';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { PermissionService } from './Services/PermissionServices.service';
import { BranchServiceProxy } from './Services/branchServiceProxy.service';
import { AllModulesService } from 'src/app/all-modules/all-modules.service';
import { HtmlEditorService, ImageService, LinkService, RichTextEditorModule, ToolbarService } from '@syncfusion/ej2-angular-richtexteditor';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule, RoutingComponents } from './app-routing.module';
import { AppComponent } from './app.component';
import { MatTooltipModule } from '@angular/material/tooltip';
// Bootstrap DataTable
import { DataTablesModule } from 'angular-datatables';
import { ToastrModule } from 'ngx-toastr';
import { FormsModule, ReactiveFormsModule, ɵNgSelectMultipleOption } from '@angular/forms';
import { AuthService, AuthInterceptor, AuthGuard } from '../app/Services/auth.service';
import { CommonModule, DatePipe } from '@angular/common';
import { HeaderComponent } from '../app/header/header.component';
import { SidebarComponent } from '../app/sidebar/sidebar.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
// Perfect Scrollbar
import { PerfectScrollbarModule, PerfectScrollbarConfigInterface, PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { HeaderService } from '../app/header/header.service';
import { UiSwitchModule } from 'ngx-ui-switch';
// Api All Modules Database
import { AllModulesData } from 'src/assets/all-modules-data/all-modules-data';
import { ItFormServiceProxy } from './Services/itformServiceProxy.service';
import { User } from 'src/environments/User';
import { filesTransfers } from 'src/environments/filesTransfers';
import { UserServiceProxy } from './Services/userServiceProxy.service';
import { RoleServiceProxy } from './Services/rolesServiceProxy.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ITFormViewDialogComponent } from './Support/it-form/it-form-view/it-form-view.component';
import { MatTableModule } from '@angular/material/table';
import { FileDragNDropDirective } from './shared/file-drag-n-drop.directive';
import { DialogConfirmComponent } from './shared/dialog-confirm/dialog-confirm.component';
import { commentService } from './Services/commentService.service';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {};
import { GalleryModule } from 'ng-gallery';
import { LightboxModule } from 'ng-gallery/lightbox';
import { SocketioService } from './Services/socketio.service';
import { AddNewRoleDialogComponent } from './all-modules/roles/add-new-role-dialog/add-new-role-dialog.component';
import { AutocompleteModule } from 'src/@shared-components/auto-complete/auto-complete.module';
import { LoaderComponent } from './shared/loader/loader.component';
import { notificationService } from './Services/notificationService.service';
import { TableModule } from 'primeng/table';
import { MomentModule } from 'ngx-moment';
import { DataGridModule } from 'src/@shared-components/data-grid/data-grid.module';
import { MatSelectModule } from '@angular/material/select';
import { ApplicationProcessCreateComponent } from './all-modules/application-process/application-process-create/application-process-create.component';
import { MatterCreateComponent } from './all-modules/matter/matter-create/matter-create.component';
import { MatterServiceProxy } from './Services/matterServiceProxy.service';
import { MatterGroupServiceProxy } from './Services/matterGroupServiceProxy.service';
import { MatterGroupListServiceProxy } from './Services/matterGroupListServiceProxy.service';
import { CaseServiceProxy } from './Services/caseServiceProxy.service';
import { EncryptionDecryptionService } from './Services/encryption-decryption.service';
import { MatTabsModule } from '@angular/material/tabs';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { PrioritiesServiceProxy } from './Services/prioritiesServiceProxy.service';
import { ApplicationProcessWorkerComponent } from './all-modules/application-process/application-process-worker/application-process-worker.component';
import { CaseGroupListServiceProxy } from './Services/caseGroupListServiceProxy.service';
import { CaseWorkerServiceProxy } from './Services/caseWorkerServiceProxy.service';
import { WorkSourceServiceProxy } from './Services/workSourceServiceProxy';
import { WorkSourceDialogComponent } from './all-modules/work-source/work-source.component';
import { DraftedClientAccountComponent } from './all-modules/to-do/drafted-client-account/drafted-client-account.component';
import { CaseInvoiceServiceProxy } from './Services/caseInvoiceServiceProxy.service';
import { DraftedClientEditComponent } from './all-modules/to-do/CaseWorkerDetailModal/drafted-client-edit/drafted-client-edit.component';
import { ClientAccountServiceProxy } from './Services/clienAccountServiceProxy.service';
import { AddNewPaymentComponent } from './all-modules/to-do/add-new-payment/add-new-payment.component';
import { AddNewInvoiceComponent } from './all-modules/to-do/add-new-invoice/add-new-invoice.component';
import { OfficeInvoiceServiceProxy } from './Services/officeInvoiceServiceProxy.service';
import { teamsCalendarServiceProxy } from './Services/teamsCalendarServiceProxy.service';
import { MoveToOfficeDialogeComponent } from './all-modules/to-do/move-to-office-dialog/move-to-office-dialog.component';
import { OfficeAccountServiceProxy } from './Services/officeAccountServiceProxy';
import { AddNewReceiptOfficeAccountComponent } from './all-modules/to-do/add-new-receipt-office-account/add-new-receipt-office-account.component';
import { AddNewPaymentOfficeAccountComponent } from './all-modules/to-do/add-new-payment-office-account/add-new-payment-office-account.component';
import { EditClientAccountComponent } from './all-modules/to-do/edit-client-account/edit-client-account.component';
import { EditOfficeAccountComponent } from './all-modules/to-do/edit-office-account/edit-office-account.component';
import { NotifyAccountComponent } from './all-modules/to-do/notify-account/notify-account-dialog.component';
import { ApplicationProcessSendEmailDialogueComponent } from './all-modules/application-process/application-process-send-email-dialogue/application-process-send-email-dialogue.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { EmailSenderServiceProxy } from './Services/emailSenderServiceProxy.service';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { LeadFormServiceProxy } from './Services/leadForm.service';
import { MarketingLeadFormServiceProxy } from './Services/marketingLeadFormProxy.service';
import { AddNewDispostionDialougeComponent } from './all-modules/marketing/add-new-call-dispostion-dialouge/add-new-call-dispostion-dialouge.component';
import { DispositionServiceProxy } from './Services/dispositionServiceProxy.service';
import { LeadFormMailSenderServiceProxy } from './Services/leadFormMailServiceProxy.service';

import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { TabViewModule } from 'primeng/tabview';
import { DashboardServiceProxy } from './Services/dashboard.service';
import { NgxMaskModule, IConfig } from 'ngx-mask'

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;
import { CalendarModule } from "primeng/calendar";
import { DashboardViewComponent } from './all-modules/dashboard/admin-dashboard/Dashboard-view/dashboard-view/dashboard-view.component';
import { NotifDataService } from './Services/notifData.service';
import { LeadFormNotesServiceProxy } from './Services/leadFormNotes.service';
import { DraftedApplicationProcessComponent } from './all-modules/application-process/drafted-application-process/drafted-application-process.component';
import { ApplicationProcessApproveComponent } from './all-modules/application-process/application-process-approve/application-process-approve.component';
import { CreateReminderDialougeComponent } from './all-modules/marketing/create-reminder-dialouge/create-reminder-dialouge.component';
import { LeadFormReminderServiceProxy } from './Services/leadFormReminderServiceProxy.service';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { DatetimePopupModule } from 'ngx-bootstrap-datetime-popup';
import { AddNewKnowledgeBaseDialougeComponent } from './all-modules/knowledge-base/add-new-knowledge-base-dialouge/add-new-knowledge-base-dialouge.component';
import { KnowledgeBaseServiceProxy } from './Services/knowledgeBaseServiceProxy.service';
import { MarkAsFlaggedDialougeComponent } from './all-modules/knowledge-base/mark-as-flagged-dialouge/mark-as-flagged-dialouge.component';
import { DocumentGeneratorServiceProxy } from './Services/documentGeneratorServiceProxy.service';
import { EcSpouseEmployedMaleComponent } from './all-modules/document-generator/ec-spouse-employed-male/ec-spouse-employed-male.component';
import { EcSpouseEmployedFemaleComponent } from './all-modules/document-generator/ec-spouse-employed-female/ec-spouse-employed-female.component';
import { EcSpouseDirectorLtdCompanyComponent } from './all-modules/document-generator/ec-spouse-director-ltd-company/ec-spouse-director-ltd-company.component';
import { EcSpouseDirectorLtdCompanyMaleComponent } from './all-modules/document-generator/ec-spouse-director-ltd-company-male/ec-spouse-director-ltd-company-male.component';
import { CaseTagsServiceProxy } from './Services/caseTagsServiceProxy.service';
import { ReportsServiceProxy } from './Services/reportServiceProxy.service';

import { DropdownModule } from 'primeng/dropdown';
import { KnobModule } from 'primeng/knob';

import { ChartModule } from 'primeng/chart';

import { ProfileServiceProxy } from './Services/profile.service';

import { MatCardModule } from '@angular/material/card';
import { AllActiveLeadsDialougeComponent } from './all-modules/dashboard/all-active-lead-dialouge/all-active-lead-dialouge.component';
import { PaginatorModule } from 'primeng/paginator';
import { CaseNeedActionsServiceProxy } from './Services/caseNeedActions.service';
import {UpdateOfficeInvoiceComponent} from './all-modules/to-do/update-office-invoice/update-office-invoice.component';
import {NgTinyUrlModule} from 'ng-tiny-url';
import { DeleteDialougeComponent } from 'src/@shared-components/data-grid/delete-dialoge/delete-dialoge.component';
import { DeleteSelectedDialougeComponent } from 'src/@shared-components/data-grid/selected-delete-dialoge/selected-delete-dialoge.component';
import { ApplicationProcessWorkerReviseComponent } from './all-modules/application-process/application-process-worker-revise/application-process-worker-revise.component';
@NgModule({
    declarations: [
        AppComponent,
        EcSpouseEmployedMaleComponent,
        EcSpouseEmployedFemaleComponent,
        EcSpouseDirectorLtdCompanyComponent,
        EcSpouseDirectorLtdCompanyMaleComponent,
        RoutingComponents,
        HeaderComponent,
        SidebarComponent,
        FileDragNDropDirective,
        DialogConfirmComponent,
        ITFormViewDialogComponent,
        CaseWorkerDetailModal,
        WorkSourceDialogComponent,
        CaseListModal,
        AddNewRoleDialogComponent,
        DraftedClientAccountComponent,
        ApplicationProcessCreateComponent,
        MatterCreateComponent,
        ApplicationProcessWorkerComponent,
        DraftedClientEditComponent,
        AddNewPaymentComponent,
        AddNewInvoiceComponent,
        MoveToOfficeDialogeComponent,
        DraftedApplicationProcessComponent,
        ApplicationProcessApproveComponent,
        AddNewReceiptOfficeAccountComponent,
        AddNewPaymentOfficeAccountComponent,
        EditClientAccountComponent,
        EditOfficeAccountComponent,
        NotifyAccountComponent,
        CreateReminderDialougeComponent,
        AddNewDispostionDialougeComponent,
        UpdateOfficeInvoiceComponent,
        ApplicationProcessSendEmailDialogueComponent,
        SelectMarkettingDialougeComponent,
        AddNewKnowledgeBaseDialougeComponent,
        AllActiveLeadsDialougeComponent,
        MarkAsFlaggedDialougeComponent,
        DeleteDialougeComponent,
        DeleteSelectedDialougeComponent,
        ApplicationProcessWorkerReviseComponent,
        LoaderComponent,
        DashboardViewComponent,
    ],
    imports: [
        ChartModule,
        DropdownModule,
        TabViewModule,
        MomentModule.forRoot({
            relativeTimeThresholdOptions: {
                'm': 59
            }
        }), AvatarModule,
        KnobModule,
        AvatarGroupModule,
        DataTablesModule,
        NgTinyUrlModule,
        PaginatorModule,
        CKEditorModule,
        RichTextEditorModule,
        CalendarModule,
        NgxMaskModule.forRoot(),
        TableModule,
        GalleryModule,
        LightboxModule,
        MatCheckboxModule,
        MatSnackBarModule,
        MatIconModule,
        MatTableModule,
        MatInputModule,
        MatSelectModule,
        MatTooltipModule,
        AvatarModule,
        AutocompleteModule,
        CalendarModule,
        DataGridModule,
        MatAutocompleteModule,
        MatCardModule,
        MatListModule,
        MatButtonModule,
        MatGridListModule,
        MatSlideToggleModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        DataTablesModule,
        HttpClientModule,
        AvatarGroupModule,
        CommonModule,
        NgbModule,
        FormsModule,
        MatDialogModule,
        PerfectScrollbarModule,
        MorrisJsModule,
        CommonModule,
        MatTabsModule,
        BsDropdownModule.forRoot(),
        BsDatepickerModule.forRoot(),
        TimepickerModule.forRoot(),
        NgMultiSelectDropDownModule.forRoot(),
        // DatetimePopupModule.forRoot(),
        ReactiveFormsModule,
        ToastrModule.forRoot({
            timeOut: 1500,
            positionClass: 'toast-bottom-right',
            preventDuplicates: true,
        }),

    ],
    entryComponents: [
        DialogConfirmComponent
    ],
    providers: [Title, DatePipe,
        ToolbarService, LinkService, ImageService, HtmlEditorService,
        SocketioService,
        AllModulesService,
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
        },
        notificationService,
        HeaderService,
        AuthService,
        ItFormServiceProxy,
        DashboardServiceProxy,
        UserServiceProxy,
        BranchServiceProxy,
        RoleServiceProxy,
        DocumentFileServiceProxy,
        PermissionService,
        CryptoService,
        MatterServiceProxy,
        MatterGroupServiceProxy,
        MatterGroupListServiceProxy,
        CaseServiceProxy,
        CaseTagsServiceProxy,
        ProfileServiceProxy,
        DocumentGeneratorServiceProxy,
        PrioritiesServiceProxy,
        EncryptionDecryptionService,
        NotifDataService,
        CaseGroupListServiceProxy,
        CaseWorkerServiceProxy,
        CaseInvoiceServiceProxy,
        CaseNeedActionsServiceProxy,
        ticket,
        commentService,
        WorkSourceServiceProxy,
        ClientAccountServiceProxy,
        OfficeInvoiceServiceProxy,
        OfficeAccountServiceProxy,
        EmailSenderServiceProxy,
        LeadFormServiceProxy,
        MarketingLeadFormServiceProxy,
        DispositionServiceProxy,
        LeadFormMailSenderServiceProxy,
        LeadFormNotesServiceProxy,
        LeadFormReminderServiceProxy,
        teamsCalendarServiceProxy,
        KnowledgeBaseServiceProxy,
        ReportsServiceProxy,
        AuthGuard,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
        },],
    bootstrap: [AppComponent]
})
export class AppModule {
}
