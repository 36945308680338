import { tap, shareReplay } from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { environment } from './../../environments/environment';
import { HttpClient, HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';



@Injectable()
export class UserServiceProxy{

  private apiRoot = environment.apiURL;

  constructor(private http: HttpClient) { }
   getRoles=()=>{
    var url =  this.apiRoot.concat('getAllRoles')
    return this.http.get(
   url
   ).pipe(
     tap(response =>{

       }),
     shareReplay(),
   );

   }
   PostUserForm(data){
    return this.http.post(this.apiRoot.concat('postuser/'),data).pipe(
      tap(response =>{

        }),
      shareReplay(),
    );
  }
  getAllData()
  {

    var url =  this.apiRoot.concat('getAllUsers')
     return this.http.get(
    url
    ).pipe(
      tap(response =>{

        }),
      shareReplay(),
    );


  }
  getAllUserData()
  {

    var url =  this.apiRoot.concat('getAllUsersDataOnly')
     return this.http.get(
    url
    ).pipe(
      tap(response =>{

        }),
      shareReplay(),
    );


  }
getUserbyId(id){
     return this.http.put(this.apiRoot.concat('getUserbyId/'+id+'/'),{}).pipe(
      tap(response =>{

        }),
      shareReplay(),
    );
}
}
