import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CaseListModal } from 'src/app/all-modules/application-process-started/CaseListModal/CaseListModal';
import { CaseServiceProxy } from 'src/app/Services/caseServiceProxy.service';
import { CryptoService } from 'src/app/Services/CryptoService.service';
import { EncryptionDecryptionService } from 'src/app/Services/encryption-decryption.service';
import { LeadFormServiceProxy } from 'src/app/Services/leadForm.service';
import { AppSettings } from 'src/app/shared/app.settings';
import { loaderActivation } from 'src/environments/loaderActivation';
import { User } from 'src/environments/User';
import Swal from 'sweetalert2';

declare const $: any;
@Component({
  selector: 'app-dashboard-view',
  templateUrl: './lead-file-view.component.html',
})

export class LeadFileViewComponent implements OnInit {
  isLeadFiles: boolean;
  heading: string;
  moduleName: string;
  LeadFiles: any=[];
  caseWorker: boolean;
  user = User;
  filterFields: string[];
  coloumn: { Name: string; property: string; type: string; }[];
  constructor(private route: ActivatedRoute,
    private encrypt: CryptoService,
    private router: Router,
    private leadFormService: LeadFormServiceProxy,
    private title: Title,
    private encryptionDecryptionService: EncryptionDecryptionService,) {

     }
  getData() {
    loaderActivation.loader = true;

    var id = this.route.snapshot.queryParamMap.get('LeadFiles');
    let self = this;

    if (id) {
      this.isLeadFiles = true;
      this.heading = 'Lead Files';
      this.title.setTitle('Lead Files');
      try {
        var check=false;
        this.moduleName = 'Leads';
        id = JSON.parse(this.encrypt.decryptUsingAES256(id));
        id = JSON.parse(id);

        this.LeadFiles = id;
        // if(this.activeFiles.length>0){
        //   this.activeFiles.forEach(el => {
        //     let caseWorkerList = el.caseWorkerList;
        //     let totalCompleted=0;
        //     let completed = 0;
        //     let total = 0;
        //     let checkStatus = true;
        //     if(caseWorkerList.length>0){
        //       let caseWorkerLs = [];
        //        let dic = {}
        //        caseWorkerList.forEach(element => {
        //         let caseWorkDic ={
        //           id: element['userId_id'],
        //           name: element.CaseWorkerName,
        //           profilePic:element.profilePic
        //         }
        //         // if(element['userId'] in case)
        //         caseWorkerLs.push(caseWorkDic);
        //         if(Object.keys(dic).indexOf(element['userId_id'])){
        //           dic[element['userId_id']]=[]
        //           dic[element['userId_id']].push(caseWorkDic)
        //         }
        //         else{
        //           dic[element['userId_id']].push(caseWorkDic)
        //         }
        //         if(element.status==2){
        //           totalCompleted +=1;
        //         }

        //         if(element.status==1){
        //           checkStatus= false;

        //         }
        //         if(element['userId']===self.user.Id ){
        //           self.caseWorker =true;
        //         }

        //         if(element['caseWorkerGroupList'].length>0){
        //           element['caseWorkerGroupList'].forEach(element1 => {
        //             total+=1;
        //             if(element1['isCompleted']){
        //               completed+=1;
        //             }
        //           });
        //         }

        //       });

        //       let caseWorkerDistant =  []
        // for (const [key, value] of Object.entries(dic)) {
        //   caseWorkerDistant.push(dic[key][0]);

        // }
        // el['caseWorkerProfileList']= caseWorkerDistant;
        //     }
        //     if((totalCompleted==caseWorkerList.length && (el["personResposibleId"]==self.user.Id || self.user.RoleId==1)&& el['caseStatus']===0)){
        //       el["approve"] = true
        //       check=true;
        //     }else{
        //       el["approve"]=false
        //     }

        //     if(checkStatus){
        //       let status = completed.toString() + ' / ' + total.toString();
        //     el['status'] = status;
        //     }
        //     else{
        //       el['status']= 'Waiting For Aproval';
        //     }

        //   });
        // }
      } catch (error) {
        // console.error(error);
        // expected output: ReferenceError: nonExistentFunction is not defined
        // Note - error messages will vary depending on browser
      }
      this.coloumn = [
        {'Name':'Id','property':'Id','type':'Id'},
        {'Name':'Name','property':'name','type':'text'},
        {'Name':'Email','property':'email','type':'text'},
        {'Name':'Phone Number','property':'phoneNumber','type':'text'},
        {'Name':'Matter Type','property':'matterType','type':'text'},
        {'Name':'Status','property':'status','type':'text'},
        {'Name':'Deal Owner','property':'dealOwnerName','type':'bgtext-1'},
        {'Name':'Actions','property':'','type':'actions'},
      ];
      this.filterFields = ['Id','name','email','phoneNumber','matterType','status'];
      // if(check){
      //   this.coloumn.pop();
      //   this.coloumn.push({'Name':'Actions','property':'approve',"type":"actions-with-approve"})
      // }
    }

    var id = this.route.snapshot.queryParamMap.get('LeadFilesMatter');
    if (id) {
      this.isLeadFiles = true;
      this.heading = 'Lead Files';
      this.title.setTitle('Lead Files Matter');

      try {
        var check=false;
        this.moduleName = 'Leads';
        id = JSON.parse(this.encrypt.decryptUsingAES256(id));
        id = JSON.parse(id);

        this.LeadFiles = id;
        // if(this.activeFiles.length>0){
        //   this.activeFiles.forEach(el => {
        //     let caseWorkerList = el.caseWorkerList;
        //     let totalCompleted=0;
        //     let completed = 0;
        //     let total = 0;
        //     let checkStatus = true;
        //     if(caseWorkerList.length>0){
        //       let caseWorkerLs = [];
        //        let dic = {}
        //        caseWorkerList.forEach(element => {
        //         let caseWorkDic ={
        //           id: element['userId_id'],
        //           name: element.CaseWorkerName,
        //           profilePic:element.profilePic
        //         }
        //         // if(element['userId'] in case)
        //         caseWorkerLs.push(caseWorkDic);
        //         if(Object.keys(dic).indexOf(element['userId_id'])){
        //           dic[element['userId_id']]=[]
        //           dic[element['userId_id']].push(caseWorkDic)
        //         }
        //         else{
        //           dic[element['userId_id']].push(caseWorkDic)
        //         }
        //         if(element.status==2){
        //           totalCompleted +=1;
        //         }

        //         if(element.status==1){
        //           checkStatus= false;

        //         }
        //         if(element['userId']===self.user.Id ){
        //           self.caseWorker =true;
        //         }

        //         if(element['caseWorkerGroupList'].length>0){
        //           element['caseWorkerGroupList'].forEach(element1 => {
        //             total+=1;
        //             if(element1['isCompleted']){
        //               completed+=1;
        //             }
        //           });
        //         }

        //       });

        //       let caseWorkerDistant =  []
        // for (const [key, value] of Object.entries(dic)) {
        //   caseWorkerDistant.push(dic[key][0]);

        // }
        // el['caseWorkerProfileList']= caseWorkerDistant;
        //     }
        //     if((totalCompleted==caseWorkerList.length && (el["personResposibleId"]==self.user.Id || self.user.RoleId==1)&& el['caseStatus']===0)){
        //       el["approve"] = true
        //       check=true;
        //     }else{
        //       el["approve"]=false
        //     }

        //     if(checkStatus){
        //       let status = completed.toString() + ' / ' + total.toString();
        //     el['status'] = status;
        //     }
        //     else{
        //       el['status']= 'Waiting For Aproval';
        //     }

        //   });
        // }
      } catch (error) {
        // console.error(error);
        // expected output: ReferenceError: nonExistentFunction is not defined
        // Note - error messages will vary depending on browser
      }
      this.coloumn = [
        {'Name':'Id','property':'Id','type':'Id'},
        {'Name':'Name','property':'name','type':'text'},
        {'Name':'Email','property':'email','type':'text'},
        {'Name':'Phone Number','property':'phoneNumber','type':'text'},
        {'Name':'Matter Type','property':'matterType','type':'bgtext-1'},
        {'Name':'Status','property':'status','type':'text'},
        {'Name':'Deal Owner','property':'dealOwnerName','type':'bgtext'},
        {'Name':'Actions','property':'','type':'actions'},
      ];
      this.filterFields = ['Id','name','email','phoneNumber','matterType','status'];
      // if(check){
      //   this.coloumn.pop();
      //   this.coloumn.push({'Name':'Actions','property':'approve',"type":"actions-with-approve"})
      // }
    }
    var id = this.route.snapshot.queryParamMap.get('LeadFilesRevenue');
    if (id) {
      this.isLeadFiles = true;
      this.heading = 'Lead Files';
      this.title.setTitle('Lead Files Revenue');

      try {
        var check=false;
        this.moduleName = 'Leads';
        id = JSON.parse(this.encrypt.decryptUsingAES256(id));
        id = JSON.parse(id);

        this.LeadFiles = id;
      } catch (error) {
      }
      this.coloumn = [
        {'Name':'Id','property':'Id','type':'Id'},
        {'Name':'Name','property':'name','type':'text'},
        {'Name':'Email','property':'email','type':'text'},
        {'Name':'Phone Number','property':'phoneNumber','type':'text'},
        {'Name':'Deal Amount','property':'dealAmount','type':'bgtext-1'},
        {'Name':'Status','property':'status','type':'text'},
        {'Name':'Deal Owner','property':'dealOwnerName','type':'bgtext'},
        {'Name':'Actions','property':'','type':'actions'},
      ];
      this.filterFields = ['Id','name','email','phoneNumber','dealAmount','status'];
      // if(check){
      //   this.coloumn.pop();
      //   this.coloumn.push({'Name':'Actions','property':'approve',"type":"actions-with-approve"})
      // }
    }

    loaderActivation.loader = false;


  }

  ngOnInit() {

    this.getData();
    if (!this.isLeadFiles ) {
      this.router.navigateByUrl("/error404");
    }
  }


  DashboardNavigate() {
    this.router.navigateByUrl(AppSettings.DASHBOARD_URL);
  }
  async deleteSelected(checkL) {
    await checkL.forEach(element => {
      let toSetValues = this.LeadFiles.find(x => x.Id === element);
      toSetValues['isDeleted'] = true;
      toSetValues["rolePermission"] = []
      const promise = this.leadFormService.Post(toSetValues).toPromise()
      promise.then(Response => {
        Swal.fire("Success", "Lead deleted successfully", "success");
      }, error => {
        Swal.fire("Error", "Something is not right", "error");
      })
    });
    this.getData();
    Swal.fire("Success", "Leads deleted successfully", "success");
    $("#delete_selected_role").modal("hide");
  }
  rowClick(value){
    let toSetValues= value;
    const encId = this.encryptionDecryptionService.encrypt(toSetValues.Id.toString());
    const param = toSetValues.Id.toString()
    // const param = { queryParams: { row: toSetValues.Id.toString() } };
    this.router.navigate([AppSettings.DetailLeadForm_URL,param]);
  }
  deleteLeads(id) {
    let toSetValues = this.LeadFiles.find(x => x.Id === id);
    toSetValues['isDeleted'] = true;
    toSetValues['addedByUserId'] = User.Id;
    this.isLeadFiles=false;
    this.leadFormService.Post(toSetValues).subscribe(Response => {
      Swal.fire("Success", "Lead deleted successfully", "success");
      let index = this.LeadFiles.indexOf(toSetValues)
     this.LeadFiles.splice(index,1);
     this.LeadFiles = [...this.LeadFiles];
     this.isLeadFiles= true;
    //  this.srch = [...this.rows];

      // this.tempId = -1;

      // this.getData();
      $("#delete_role").modal("hide");
    }, error => {
      // console.log("",error.toString(),"error");
      Swal.fire("Error", "Something is not right", "error");
    })
  }
}
