import { User } from 'src/environments/User';
import { tap, shareReplay } from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { environment } from './../../environments/environment';
import { HttpClient, HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';



@Injectable()
export class PermissionService{
    user = User

    checkVisibilityByModuleName=(moduleName,key,branchId)=>{
        var check = false;
        if(this.user){

    //    this.user.Permissions = this.user.Permissions.filter(x=>x.branchId === branchId)
       this.user.Permissions.forEach(element => {
           if(element.branchId === branchId){

            element.permissions.forEach(element1 => {
            if(element1.ModuleName===moduleName){
                if(element1[key]===true)
                {

                    check=true

                }


            }
        });
        }
        });
    }

        return check;
    }
    checkDashBoardPermission=(moduleName,key,branchId)=>{
        var check = false;
        var values= [];
        if(this.user){

    //    this.user.Permissions = this.user.Permissions.filter(x=>x.branchId === branchId)
       this.user.Permissions.forEach(element => {
           if(element.branchName === branchId){

            element.permissions.forEach(element1 => {
            if(element1.ModuleName===moduleName){
                if(element1[key])
                {
                    values.push(element1[key]);
                   check=true;
                    return ;

                }


            }
            if(check){
                return;
               }
             });

        }else if(branchId==='All'){
            element.permissions.forEach(element1 => {
                if(element1.ModuleName===moduleName){
                    if(element1[key])
                    {

                         values.push(element1[key]);
                        check = true;
                        return;

                    }


                }
                if(check){
                 return;
                }
        });
    }
    if(check){
        return ;
       }
        });
    }
    return values;
}
checkDashBoadPermissionForSpecificUser=(moduleName,key,branchId,user)=>{
  var check = false;
  var values= [];
  if(user){

//    this.user.Permissions = this.user.Permissions.filter(x=>x.branchId === branchId)
 user.Permissions.forEach(element => {
     if(element.branchId === branchId){

      element.permissions.forEach(element1 => {
      if(element1.ModuleName===moduleName){
          if(element1[key])
          {
              values.push(element1[key]);
             check=true;
              return ;

          }


      }
      if(check){
          return;
         }
       });

  }else if(branchId==='All'){
      element.permissions.forEach(element1 => {
          if(element1.ModuleName===moduleName){
              if(element1[key])
              {

                   values.push(element1[key]);
                  check = true;
                  return;

              }


          }
          if(check){
           return;
          }
  });
}
if(check){
  return ;
 }
  });
}
return values;
}
    }
