import { User } from 'src/environments/User';
import { DocumentFileServiceProxy } from '../../../Services/document.service';
import { Title } from '@angular/platform-browser';
import { CryptoService } from '../../../Services/CryptoService.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Component, Inject, Injector, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA, } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar'
import { MatCheckboxChange } from '@angular/material/checkbox'
import { finalize, filter, elementAt } from 'rxjs/operators';
import * as _ from 'lodash';

import {
  Gallery,
  GalleryItem,
  ThumbnailsPosition,
  ImageSize,
  ImageItem
} from "ng-gallery";

import { Lightbox } from "ng-gallery/lightbox";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ticket } from 'src/app/shared/ticket';
import { ItFormServiceProxy } from 'src/app/Services/itformServiceProxy.service';
import Swal from 'sweetalert2';
import { commentService } from 'src/app/Services/commentService.service';
import { RoleServiceProxy } from 'src/app/Services/rolesServiceProxy.service';
import { MatterServiceProxy } from 'src/app/Services/matterServiceProxy.service';
import { CaseServiceProxy } from 'src/app/Services/caseServiceProxy.service';
import { CaseGroupListServiceProxy } from 'src/app/Services/caseGroupListServiceProxy.service';
import { CaseWorkerServiceProxy } from 'src/app/Services/caseWorkerServiceProxy.service';

declare const $: any;


@Component({
  templateUrl: './application-process-worker-revise.component.html',
})

export class ApplicationProcessWorkerReviseComponent
  implements OnInit {
  public addWorker: FormGroup;
  matterList = [{"Id":"k"}];
  caseListItems= [];
  caseWorker: any;
  user: any;
  allUser:any;
  allMatterGroups: any;
  allMatterList: any;
  contentEditable: boolean = false;
  newListName:any;
  selectedCheckList = [];
  submit = false;
  checkedLenght:any=0;
  constructor(
    public dialog: MatDialog,
    private roleService: RoleServiceProxy,
    private formBuilder: FormBuilder,
    private matterService: MatterServiceProxy,
    private caseService: CaseServiceProxy,
    private caseGroupListService: CaseGroupListServiceProxy,
    private caseWorkerService: CaseWorkerServiceProxy,
    public dialogRef: MatDialogRef<ApplicationProcessWorkerReviseComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { value: any },
  ) {
    this.submit = false;

  }

   ngOnInit()  {
    this.getData();
    if(this.data.value.user){

      this.allUser= this.data.value.allUser.filter(x=>x.isAvaiblible);
      this.allMatterGroups= this.data.value.caseGroups;

      this.allMatterGroups.forEach(element => {
        element['groupChecked']=false;
        element['caseGroupList'].forEach(element1 => {
          element1['checked']=false;
          element1['editable']= false;
          element1['display']= false;
        });
      });
      console.log("Has User");
      this.caseWorker= this.data.value.user;
      this.allUser.push(this.data.value.allUser.find(x=>x.User.Id==this.caseWorker['userId_id']));
      let caseWorkerGroupList = this.caseWorker.caseWorkerGroupList;
      caseWorkerGroupList.forEach(element => {
        if(element['caseGroupId_id']===null){
          element['checked'] = true;
          element['display'] = false;
          element['editable'] = false;
          element['addedByUserId']= User.Id;
          this.caseListItems.push(element);
          this.selectedCheckList.push(element);
        }
        else{

          // let index = this.allMatterGroups.findIndex(x=>x.Id==element['caseGroupId_id']);
          // // enabling the Group, which items is assigned to them
          // this.allMatterGroups[index].isAssinged= false;
          // this.allMatterGroups[index].groupChecked= true;
          // let i = this.allMatterGroups[index].caseGroupList.findIndex(x=>x.Id==element['Id']);
          // // checked the item which is assigned
          // this.allMatterGroups[index].caseGroupList[i].checked= true;
          // this.selectedCheckList.push(this.allMatterGroups[index].caseGroupList[i]);
          element['checked'] = true;
          element['display'] = false;
          element['editable'] = false;
          element['addedByUserId']= User.Id;
          this.caseListItems.push(element);
          this.selectedCheckList.push(element);




        }
      });
      this.addWorker = this.formBuilder.group({
        Id: this.caseWorker.Id,
        selectedUser: this.caseWorker['userId_id'],
        dueDate: this.caseWorker['dueDate'],
        canEdit: this.caseWorker['canEdit'],
      });
    }
    else{
      this.allUser= this.data.value.allUser.filter(x=>x.isAvaiblible);
      this.allMatterGroups= this.data.value.caseGroups;
      this.allMatterGroups.forEach(element => {
        element['groupChecked']=false;
        element['caseGroupList'].forEach(element1 => {
          // adding new elements for their checked
          element1['checked']=false;
          // adding new element for editable
          element1['editable']= false;
          // adding new element for make it disable while updating
          element1['display']= false;
        });
      });
      console.log("Don't Have user");
      this.addWorker = this.formBuilder.group({
        Id: [0],
        selectedUser: [null,[Validators.required]],
        dueDate: [null],
        canEdit: false,
      });
    }
    this.user = User;
    this.checkedLenght = this.selectedCheckList.length;
  }
  selectDelete(outter,inner) {
    let obj = this.allMatterGroups[outter].caseGroupList[inner];
    obj['addedByUserId']= User.Id;
    obj['isDeleted']= true;
    this.caseGroupListService.PostCaseGroupList(obj).subscribe(Response=>{
      this.allMatterGroups[outter].caseGroupList[inner].display= true;
      setTimeout(()=>{
        this.allMatterGroups[outter].caseGroupList[inner].display= false;
    this.allMatterGroups[outter].caseGroupList[inner].editable= false;
    this.allMatterGroups[outter].caseGroupList.splice(inner,1);
    }, 2000);

    });

}
_selectDelete(outter) {
  this.caseListItems[outter].display = true;
    setTimeout(()=>{
      this.caseListItems[outter].display = false;
      this.caseListItems[outter].editable= false;
  this.caseListItems.splice(outter,1);
  }, 2000);

}
  selectEdit(outter,inner) {
    this.allMatterGroups[outter].caseGroupList[inner].editable= true;

}
_selectEdit(outter) {
  this.caseListItems[outter].editable= true;

}
selectUpdate(outter,inner) {
  let obj = this.allMatterGroups[outter].caseGroupList[inner];
  obj['addedByUserId']= User.Id;
  this.caseGroupListService.PostCaseGroupList(obj).subscribe(Response=>{
    this.allMatterGroups[outter].caseGroupList[inner].display= true;
    setTimeout(()=>{
      this.allMatterGroups[outter].caseGroupList[inner].display= false;
  this.allMatterGroups[outter].caseGroupList[inner].editable= false;
  }, 2000);

  });

}
_selectUpdate(outter) {
  this.caseListItems[outter].display = true;
  setTimeout(()=>{
    this.caseListItems[outter].display = false;
    this.caseListItems[outter].editable= false;
}, 2000);


}
addNewListItem(){
  let item = {
    Id: 0,
    addedByUserId: User.Id,
    caseGroupId:null,
    caseWorkerId:null,
    checked: false,
    display:false,
    editable:false,
    isDeleted:false,

    name:this.newListName
  }
  this.caseListItems.push(item);
  this.newListName= "";
}
onNameChangeList(val, outter,inner) {
  this.allMatterGroups[outter].caseGroupList[inner].name= "";
  this.allMatterGroups[outter].caseGroupList[inner].name= val;

}
_onNameChangeList(val, outter) {
  this.caseListItems[outter].name ="";
  this.caseListItems[outter].name =val;

}
  GroupChecked(e, i) {

    if (e.checked) {
      this.allMatterGroups[i].caseGroupList.forEach(element => {

        element['checked']=true;
        this.selectedCheckList.push(element);
      });
    }
    else {
      this.allMatterGroups[i].caseGroupList.forEach(element => {
        if(element['checked']==true){
          let index = this.selectedCheckList.indexOf(element);
          this.selectedCheckList.splice(index,1);
        }
        element['checked']=false;
      });
    }

  }
  ItemChecked(e, outter,inner) {
    if(this.data.value.revised && !this.data.value.editable){
      let index = this.selectedCheckList.findIndex(x=>x.Id===this.allMatterGroups[outter].caseGroupList[inner].Id)
      if(e.checked){
        if(index<0){
          this.selectedCheckList.push(this.allMatterGroups[outter].caseGroupList[inner]);
        }else{
          this.selectedCheckList[index].checked=true;
        }
      }
      else{
        this.selectedCheckList[index].checked=false;
          this.selectedCheckList[index].isCompleted=false;
      }
    }else{
    if (e.checked) {
      this.selectedCheckList.push(this.allMatterGroups[outter].caseGroupList[inner]);
    }
    else {
     let index = this.selectedCheckList.findIndex(x=>x.Id===this.allMatterGroups[outter].caseGroupList[inner].Id)
     this.selectedCheckList.splice(index,1);
    }
    }
  }
  ListItemChecked(e,outter){
    if(this.data.value.revised && !this.data.value.editable){
      let index = this.selectedCheckList.indexOf(this.caseListItems[outter])
      if(e.checked){
        if(index<0){
        this.selectedCheckList.push(this.caseListItems[outter]);
      }else{
        this.selectedCheckList[index].checked=true;

      }
        }
        else{
          let index = this.selectedCheckList.indexOf(this.caseListItems[outter])
          this.selectedCheckList[index].checked=false;
          this.selectedCheckList[index].isCompleted=false;
        }
    }else{
    if(e.checked){
    this.selectedCheckList.push(this.caseListItems[outter]);
    }
    else{
      let index = this.selectedCheckList.indexOf(this.caseListItems[outter])
     this.selectedCheckList.splice(index,1);
    }



  }

  this.checkedLenght=0
  this.selectedCheckList.forEach(element=>{
   if(element.checked){
     this.checkedLenght+=1;

   }
  })

  }
  Submit = () => {
    if (this.addWorker.valid) {
      let data = this.addWorker.value;
      if(data['Id']==0){
        this.submit = true;
          data = JSON.stringify(data);
          data = JSON.parse(data);
          data["isDeleted"] = false;
          data["status"]=0;
          data["caseId"] = this.data.value.caseId;
          data['addedByUserId']= User.Id;
          data['selectedCheckList'] = this.selectedCheckList;
          this.caseWorkerService.PostCase(data).subscribe(Response=>{
            this.submit = false;
            Swal.fire("Success", "Case created successfully", "success");
            this.addWorker.reset();
            this.close();
          },error=>{
            this.submit = false;
            Swal.fire("Error", error.error.error, "error");
          })
      }
      else{
        this.update();
      }
    }
    else{
      for(let i in this.addWorker.controls){
        this.addWorker.controls[i].markAsTouched();
      }
    }
  }
  update()  {
    this.submit = true;
            let group =[];
            let groupsUpdate = [];
            this.allMatterGroups.forEach(element => {
              if(!element['isAssinged'])  {
                group.push(element);
              }
            });
            group.forEach(element=>{
            let check = false;
            element['caseGroupList'].forEach(element1 => {
              this.selectedCheckList.forEach(el=>{
                if(el['Id']==element1['Id']){
                  check = true
                }
              });
            });
            if(check){
              let item = {
                groupId: element['Id'],
                isAssinged: true,
              }
              groupsUpdate.push(item);
            }
            else{
              let item = {
                groupId: element['Id'],
                isAssinged: false,
              }
              groupsUpdate.push(item);
            }
            });
      let data = this.addWorker.value;
      data = JSON.stringify(data);
      data = JSON.parse(data);
      data["isDeleted"] = false;
      data["caseId"] = this.data.value.caseId;
      data['addedByUserId']= User.Id;
      data['selectedCheckList'] = this.selectedCheckList;
      data['groupsUpdate'] = groupsUpdate;
      data["status"]=0;
      if(this.data.value.revised){
        data["revised"]=true
      }else{
        data["revised"]=false

      }
      if(this.data.value.editable){

        data["editable"]=true
      }else{
        data["editable"]=false

      }
      this.caseWorkerService.PostCase(data).subscribe(Response=>{
        Swal.fire("Success", "Case Updated successfully", "success");
        this.addWorker.reset();
        this.close();
        this.submit = false;
      },error=>{
        this.submit = false;
        Swal.fire("Error", error.error.error, "error");
      })
    }
delete(){
  let data = this.addWorker.value;
  data = JSON.stringify(data);
      data = JSON.parse(data);
      data["isDeleted"] = true;
      data["caseId"] = this.data.value.caseId;
      data['addedByUserId']= User.Id;
      data["status"]=0;
      data['selectedCheckList'] = this.selectedCheckList;
      this.caseWorkerService.PostCase(data).subscribe(Response=>{
        Swal.fire("Success", "Case created successfully", "success");
        this.addWorker.reset();
        this.close();
      },error=>{
        Swal.fire("Error", error.error.error, "error");
      })
}
  async getData() {
    // let self = this;
    // const promise=this.matterService.getAllData().toPromise();
    // await promise.then(function(response){
    //   self.matterList = response['Matter'];
    // });
  }

  save(): void {
  }

   close() {

    this.dialogRef.close();
  }

}
