import { SocketioService } from '../../Services/socketio.service';

import { AuthService } from '../../Services/auth.service';
import { PermissionService } from '../../Services/PermissionServices.service';
import { BranchServiceProxy } from '../../Services/branchServiceProxy.service';
import { User } from '../../../environments/User';
import Swal from 'sweetalert2';
import { deepCopy } from '@angular-devkit/core/src/utils/object';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AppSettings } from 'src/app/shared/app.settings';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';


import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { UserServiceProxy } from '../../Services/userServiceProxy.service';
import { AutoCompleteModel } from 'src/@shared-components/auto-complete/models/auto-model';
import { MatDialog } from '@angular/material/dialog';
import { ApplicationProcessCreateComponent } from './application-process-create/application-process-create.component';
import { CaseServiceProxy } from 'src/app/Services/caseServiceProxy.service';
import { EncryptionDecryptionService } from 'src/app/Services/encryption-decryption.service';
import { loaderActivation } from 'src/environments/loaderActivation';
import { DraftedApplicationProcessComponent } from './drafted-application-process/drafted-application-process.component';
import { Location } from '@angular/common';
declare const $: any;
@Component({
  templateUrl: './application-process.component.html',

})
export class ApplicationProcessComponent implements OnInit {
 dashboardRoute: any;
 public coloumn : any;
  moduleName: string;
  loader:any=false;
  blist:any;
  allCases:any = [];
  caseList = [];
  filterFields: string[];
  constructor(
    private title : Title,
    private formBuilder: FormBuilder,
    private router: Router,
    private permission:PermissionService,
    private auth:AuthService,
    private _dialog: MatDialog,
    private socketService:SocketioService,
    private caseService: CaseServiceProxy,
    private encryptionDecryptionService: EncryptionDecryptionService,
    private location: Location,
  ) {

    this.moduleName = "Retainer";
       }
       backBtn(){
        this.location.back();
      }
 async getData(){

  this.loader = true;
  loaderActivation.loader = true;
  this.allCases = [];
  let self = this;
 const promise=  this.caseService.getRetainer().toPromise();
  await promise.then(function(response){
    self.allCases= response['result'];
    self.allCases = self.allCases.filter(x=>x.isApproved);
    self.allCases = self.allCases.sort((a, b) => (new Date(b.creationTime) < new Date(a.creationTime) ? -1 : 1));
    // data.forEach(element => {
    //   if(!element['isDraft']){
    //     self.allCases.push(element);
    //   }
    // });

  });
  if(!this.blist){
    this.blist=(User.BranchId);
   }
this.coloumn = [
  {'Name':'Id','property':'Id','type':'Id'},
  {'Name':'Client Name','property':'clientName','type':'text'},
  {'Name':'Email','property':'email','type':'text'},
  {'Name':'Phone Number','property':'phoneNumber','type':'text'},
  {'Name':'Work Source','property':'workSource','type':'text'},
  {'Name':'Creation Date','property':'creationTime','type':'date'},
  {'Name':'Actions','property':'','type':'actions'}
];
this.filterFields = ['Id','clientName','email','phoneNumber','workSource','creationTime'];
this.loader = false;
loaderActivation.loader = false;
  }
  createApplicationProcess(){
    this._dialog.open(ApplicationProcessCreateComponent,{
      disableClose:true,
      width:'800px',
      height:'750px',
      data: {
        check:false,
      }
    }).afterClosed().subscribe(async (result) => {
      this.getData();
  });
  }
  openDraftedApplicationProcess(){
    this._dialog.open(DraftedApplicationProcessComponent,{
      disableClose:true,
      width:'1200px',
      height:'550px',
      data: {
        check:false,
      }
    }).afterClosed().subscribe(async (result) => {
      this.getData();
  });
  }
  ngAfterViewInit(): void {

  }
  checkPermission=(name,key,BranchId)=>{
    var ex = this.permission.checkVisibilityByModuleName(name,key,BranchId)
    return ex
  }
  ngOnInit() {
    if(!this.checkPermission("Retainer","Visiblity",User.BranchId)){
      this.router.navigateByUrl("error401");
  }
  this.socketService.userSocket.onmessage=(msg)=>{
    this.getData();
}

this.socketService.userSocket.onerror = (error) =>
    console.log(`The server received: ${error['code']}`
    );
    this.socketService.userSocket.onclose = (why) =>
    console.log(`The server received: ${why.code} ${why.reason}`
    );

    this.getData();
    this.title.setTitle('Retainer');
  }
  edit(value) {
    let toSetValues= value;
    if(Number.isInteger(value)){
      toSetValues = this.allCases.find(x=>x.Id=== value);
    }
    const encId = this.encryptionDecryptionService.encrypt(toSetValues.Id.toString());
    const param = { queryParams: { row: encId } };
    this.router.navigate([AppSettings.ApplicationProcessDetailed_URL],param);
    // const url = this.router.serializeUrl(this.router.createUrlTree([AppSettings.ApplicationProcessDetailed_URL],param));
    //   window.open(url);
  }
  async deleteSelected(checkL){
    await checkL.forEach(element => {
       let toSetValues = this.allCases.find(x=>x.Id=== element);
       toSetValues['isDeleted']=true;
       toSetValues["addedByUserId"] = User.Id;
       const promise = this.caseService.PostCase(toSetValues).toPromise();
       promise.then(Response=>{
           this.getData();
          },error=>{
           Swal.fire("Error","Something is not right","error");
          })
     });
     this.getData();
     Swal.fire("Success","Case deleted successfully","success");
   }
  deleteCase(id){
    let toSetValues = this.allCases.find(x=>x.Id=== id);
    toSetValues['isDeleted']=true;
    toSetValues["addedByUserId"] = User.Id;
    toSetValues['caseInvoiceId'] = null;
    this.caseService.PostCase(toSetValues).subscribe(Response=>{
        Swal.fire("Success","Case deleted successfully","success");
        this.getData();
       },error=>{
        Swal.fire("Error","Something is not right","error");
       });
}
  DashboardNavigate()
  {
    this.router.navigateByUrl(AppSettings.DASHBOARD_URL);
  }
}
