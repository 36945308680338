import { DatePipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { FormBuilder  } from "@angular/forms";
import { Router } from "@angular/router";
import { DocumentGeneratorServiceProxy } from "src/app/Services/documentGeneratorServiceProxy.service";
import { AppSettings } from "src/app/shared/app.settings";
import { Location } from '@angular/common';


@Component({
  selector:"document-generator",
  templateUrl:'./document-generator.componet.html',
})

export class DocumentGeneratorComponent implements OnInit{
  options= ['EC spouse employed male','EC spouse employed female',
  'EC Spouse- Director of a Ltd Company Female',
'EC Spouse- Director of a Ltd Company Male'];
  selectedOption="0";
  showForm = false;
  constructor(private router: Router,
    public datepipe: DatePipe,
    private location: Location,){
  }
  backBtn(){
    this.location.back();
  }
  ngOnInit(){

  }
  DashboardNavigate()
  {
    this.router.navigateByUrl(AppSettings.DASHBOARD_URL);
  }
  changeOption(){
    this.showForm= true;
  }
  changeDocument(){
    this.showForm= false;

  }

}
