import { User } from '../../../../environments/User';
import { Component, Inject } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { CaseInvoiceServiceProxy } from 'src/app/Services/caseInvoiceServiceProxy.service';
import { ClientAccountServiceProxy } from "src/app/Services/clienAccountServiceProxy.service";
import Swal from "sweetalert2";
import { OfficeAccountServiceProxy } from 'src/app/Services/officeAccountServiceProxy';
declare const $: any;

@Component({
    selector: 'add-new-payment-office-account',
    templateUrl: 'add-new-payment-office-account.component.html',
  })
  export class AddNewPaymentOfficeAccountComponent  {
    caseData:any;
    officeAccount:any;
    receiptForm:any;
    entryDate:any;
    transactionDate:any;
    reasonOfReceipt: any;
    amount: any;
    memo: any;
    addnew:any;
    paymentAccountls = ['Manchester', 'London', 'Birmingham', 'Chester'];
    typeOfPayment=['Disbursement','Legal FEE'];
    paymentMethod = ['BACS', 'Cheque', 'Cash', 'Card'];
    constructor(
        public dialogRef: MatDialogRef<AddNewPaymentOfficeAccountComponent>,
        private officeAccountService: OfficeAccountServiceProxy,
        @Inject(MAT_DIALOG_DATA) public data: {data},
        private _dialog: MatDialog,
        ) {
            this.caseData = data.data.caseData;
            this.officeAccount = data.data.officeAccount;
            this.addnew = data.data.addNew;
        }



          async ngOnInit() {

           }

    close(){
      this.dialogRef.close();
    }
    bankAccountValid= false;
    paymentMethodValid= false;
    payToValid= false;
    addressValid= false;
    reasonValid= false;
    memoValid= false;
    checkValidation() {
      let ret = true;
      if (this.officeAccount.bankAccount == null || this.officeAccount.bankAccount == undefined) {
        this.bankAccountValid = true;
        ret = false;

      } else {
        this.bankAccountValid = false;

      }


      if (this.officeAccount.paymentMethod == null || this.officeAccount.paymentMethod == undefined) {
        this.paymentMethodValid = true;
        ret = false;

      } else {
        this.paymentMethodValid = false;

      }


      if (this.officeAccount.payTo == null || this.officeAccount.payTo == undefined) {
        this.payToValid = true;
        ret = false;

      } else {
        this.payToValid = false;

      }


      if (this.officeAccount.address == null || this.officeAccount.address == undefined) {
        this.addressValid = true;
        ret = false;

      } else {
        this.addressValid = false;

      }


      if (this.officeAccount.reasonOfPayment == null || this.officeAccount.reasonOfPayment == undefined) {
        this.reasonValid = true;
        ret = false;

      } else {
        this.reasonValid = false;

      }

      if (this.officeAccount.memo == null || this.officeAccount.memo == undefined) {
        this.memoValid = true;
        ret = false;

      } else {
        this.memoValid = false;

      }
      return ret;
    }

    Submit(){
      if(this.checkValidation()){
        let self = this;
      this.officeAccount['addedByUserId']= User.Id;
      if(this.addnew){
      this.officeAccount['isCompleted']= true;
      }
      this.officeAccount['addNew'] = this.addnew;
      this.officeAccountService.Post(this.officeAccount).subscribe(function(resp){
        // self.getData();
        Swal.fire(
          'Update!',
          'Payment Is Updated.',
          'success'
        );
        self.close();
      });
      }

    }
  }
