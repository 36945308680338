import { User } from './../../../environments/User';
import { Subject } from 'rxjs';
import { Component, Inject } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { WorkSourceServiceProxy } from 'src/app/Services/workSourceServiceProxy';
import Swal from 'sweetalert2';
@Component({
    selector: 'work-source',
    templateUrl: 'work-source.component.html',
  })
  export class WorkSourceDialogComponent  {
    workSource: any;
    workSourceId:any;
    constructor(
        public dialogRef: MatDialogRef<WorkSourceDialogComponent>,
        public workSourceService: WorkSourceServiceProxy,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _dialog: MatDialog,
        ) {
            this.workSource = data.workSourceName;
            console.log(this.workSource);
        }
           ngOnInit() {

             }
    close(){
      this.dialogRef.close(this.workSourceId);
    }
    Submit(){
      let obj ={
        'Id':0,
        'Name':this.workSource,
        'addedByUserId':User.Id,
        'isDeleted':false
      }
      let self = this;
      this.workSourceService.PostWorkSource(obj).subscribe(function(response){
        self.workSourceId = response['WorkSource']['Id'];
        Swal.fire(
          'Created!',
          'WorkSource Has been Created Successfully.',
          'success'
        );
        self.close();
      });
    }
  }
