import { SocketioService } from 'src/app/Services/socketio.service';
import { PermissionService } from '../../Services/PermissionServices.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppSettings } from 'src/app/shared/app.settings';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Component, OnInit, ViewChild } from "@angular/core";
import { User } from 'src/environments/User';
import Swal from 'sweetalert2';
import { DataTableDirective } from 'angular-datatables';
import { loaderActivation } from 'src/environments/loaderActivation';
import { CaseTagsServiceProxy } from 'src/app/Services/caseTagsServiceProxy.service';
import { Location } from '@angular/common';

declare const $: any;
@Component({
  selector: "application-tags",
  templateUrl: "./application-tags.component.html",

})
export class ApplicationTagsComponent implements OnInit {
  dtOptions: DataTables.Settings = {};
  @ViewChild(DataTableDirective, { static: false })
  public dtElement: DataTableDirective;
loader:any=false;
    public allData:any = [];
    public tempId: any;
    public addCaseTagsGroup: FormGroup;
    public editCaseTagsForm: FormGroup;
    public coloumn : any;
    moduleName: string;
 public dtTrigger: Subject<any> = new Subject();
  submit: any=false;

    constructor(private titleservice:Title,private route:Router,
      private caseTagsService:CaseTagsServiceProxy, private formBuilder: FormBuilder
      ,private permission:PermissionService,
      private location: Location
      ,private socketService:SocketioService) {
        this.moduleName = 'Case Tags';

       }
       backBtn(){
        this.location.back();
      }
       DashboardNavigate()
       {
         this.route.navigateByUrl(AppSettings.DASHBOARD_URL);
       }
      async getData(){
        this.loader = true;
        loaderActivation.loader=true;
        this.allData = [];

           let self = this;
           const promise =   this.caseTagsService.getAllData().toPromise();
      await promise.then(function(response){
            // console.log(response,"branches");
            self.allData = response['CaseTags'];

        },error=>{
          // console.log("error",error)
        });
        // setTimeout(() => {
        //   this.loader = false;
        // }, 1000);
        this.coloumn = [
          {'Name':'Id','property':'Id','type':'Id'},
          {'Name':'Name','property':'Name','type':'text'},
          {'Name':'Actions','property':'','type':'actions'}
        ]
        this.loader = false;
        loaderActivation.loader=false;
    }


    user:any;
    ngAfterViewInit(): void {
        setTimeout(() => {
          this.dtTrigger.next();
        }, 1000);
      }
      checkPermission=(name,key)=>{
        var ex = this.permission.checkVisibilityByModuleName(name,key,User.BranchId)

        return ex
      }

      ngOnInit() {
        this.getData();
        this.socketService.userSocket.onmessage=(msg)=>{
          console.log(msg)
          // this.rerender();
      }

      this.socketService.userSocket.onerror = (error) =>
          console.log(`The server received: ${error['code']}`
          );
          this.socketService.userSocket.onclose = (why) =>
          console.log(`The server received: ${why.code} ${why.reason}`
          );
        this.user=User;
        var per = this.checkPermission("Case Tags","Visiblity")

        if(!per){
            this.route.navigateByUrl("error401");
        }
        this.titleservice.setTitle("Case Tags");

          this.addCaseTagsGroup = this.formBuilder.group({
            Id: [0],
            Name: ["", [Validators.required]],

          });

          this.editCaseTagsForm = this.formBuilder.group({
            Id: [0],
            Name: ["", [Validators.required]],

          });
    }
    edit(value) {
      $("#casetags_edit").appendTo("body");
      $('#casetags_edit').modal('show');
      let toSetValues = value;
      if(Number.isInteger(value)){
        toSetValues = this.allData.find(x=>x.Id=== value);

      }
      else{
        toSetValues = this.allData.find(x=>x.Id=== value.Id);

      }



        this.editCaseTagsForm = this.formBuilder.group({
            Id: toSetValues.Id,
            Name:toSetValues.Name,


            // isActive:toSetValues.fields.isActive
        });
      }
    addCaseTags=()=>{

        if(this.addCaseTagsGroup.valid){
            let data = this.addCaseTagsGroup.value;
            this.submit= true;
            data = JSON.stringify(data);
            data = JSON.parse(data);
            data["isDeleted"]=false;
            data["addedByUserId"]= User.Id;
       this.caseTagsService.Post(data).subscribe(Response=>{
        Swal.fire("Success","Case Tags created successfully","success");
        this.addCaseTagsGroup.reset();
        if (   this.socketService.userSocket.readyState === WebSocket.CLOSED ||this.socketService.userSocket.readyState ===WebSocket.CLOSING ) {
          this.socketService.setupUserConnetion()
        }
        this.socketService.sendUserData()
        $('#add_casetags').modal('hide');
        this.getData();
        this.submit= false;
       },error=>{
        Swal.fire("Error",error.error.error,"error");
        this.submit= false;
       })

        }
    }
    createCaseTags(){
      $("#add_casetags").appendTo("body");
      $('#add_casetags').modal('show');
      this.addCaseTagsGroup.reset();
    }
    editCaseTags(){
        if(this.editCaseTagsForm.valid){
            let data = this.editCaseTagsForm.value;
            data = JSON.stringify(data);
            data = JSON.parse(data);
            data["isDeleted"]=false;
            data["addedByUserId"] = User.Id;
       this.caseTagsService.Post(data).subscribe(Response=>{
        Swal.fire("Success","Case Tags updated successfully","success");
        this.editCaseTagsForm.reset();
        this.getData();
        // this.rerender();
        if (   this.socketService.userSocket.readyState === WebSocket.CLOSED ||this.socketService.userSocket.readyState ===WebSocket.CLOSING ) {
          this.socketService.setupUserConnetion()
        }
        this.socketService.sendUserData()
        $('#casetags_edit').modal('hide');
       },error=>{Swal.fire("Error",error.error.error,"error");
       })

        }
    }
    checkList:any=[]
    clearList(){
      this.checkList=[]

    }
    SelectAll(){
      this.allData.forEach(element => {
        if(element.Id !=1){
        this.checkList.push(element.Id)
        }
      });
    }
    Check(Id){
      const index = this.checkList.indexOf(Id);
      if (index > -1) {return true}
      else{
        return false
      }
    }
    checkChange(e,Id){
      if(e.target.checked){
        this.checkList.push(Id)
      }else{
        const index = this.checkList.indexOf(Id);
        if (index > -1) {
          this.checkList.splice(index, 1);
        }

      }
    }
    deleteCaseTags(id){
        let toSetValues = this.allData.find(x=>x.Id=== id);
        toSetValues['isDeleted']=true;
        toSetValues["addedByUserId"] = User.Id;
        this.caseTagsService.Post(toSetValues).subscribe(Response=>{
            Swal.fire("Success","Case Tags deleted successfully","success");
            this.tempId=-1;
            this.getData();
            // this.rerender();
         $("#delete_casetags").modal("hide");
           },error=>{
            // console.log("",error.toString(),"error");
            Swal.fire("Error","Something is not right","error");
           })
    }
   async deleteSelected(checkL){
     await checkL.forEach(element => {
        let toSetValues = this.allData.find(x=>x.Id=== element);
        toSetValues['isDeleted']=true;
        toSetValues["rolePermission"] = [];
        toSetValues["addedByUserId"] = User.Id;
        const promise = this.caseTagsService.Post(toSetValues).toPromise();
        promise.then(Response=>{

            this.tempId=-1;
            this.getData();


           },error=>{
            // console.log("",error.toString(),"error");
            Swal.fire("Error","Something is not right","error");
           })
      });
      // this.rerender();
      this.checkList=[];
      this.getData();
      Swal.fire("Success","Case Tags deleted successfully","success");
      // $("#delete_selected_branch").modal("hide");
    }
}
