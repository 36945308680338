import { User } from '../../../../environments/User';
import { Component, Inject } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { CaseInvoiceServiceProxy } from 'src/app/Services/caseInvoiceServiceProxy.service';
import { ClientAccountServiceProxy } from "src/app/Services/clienAccountServiceProxy.service";
import Swal from "sweetalert2";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { OfficeInvoiceServiceProxy } from 'src/app/Services/officeInvoiceServiceProxy.service';
import { UserServiceProxy } from 'src/app/Services/userServiceProxy.service';
import { DispositionServiceProxy } from 'src/app/Services/dispositionServiceProxy.service';
import { DocumentFileServiceProxy } from 'src/app/Services/document.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Gallery, GalleryItem, ImageItem, ImageSize, ThumbnailsPosition } from 'ng-gallery';
import { Lightbox } from 'ng-gallery/lightbox';
import { commentService } from 'src/app/Services/commentService.service';
import { SocketioService } from 'src/app/Services/socketio.service';

declare const $: any;

@Component({
    selector: 'select-marketting-dialogue',
    templateUrl: 'select-marketting-dialogue.component.html',
  })
  export class SelectMarkettingDialougeComponent  {
    leadForm:any;
    selectedLeadForm= null;
    selectedRetainer=null;
    userList: any;
    dispositionTypels = ['No answer', 'Discovery call', 'Follow up call',
    'Hung up','Not interested','Unfit','Wrong number','Sale','Post-sale call/ Gathering information'];
    public addForm: FormGroup;
    files:any=[];
  postFiles:any=[];
  fileIndex:any=0;
  fileId:any=-1;
  items: GalleryItem[];
  retainer: any;
  isRetainer: any;
  isLeadForm: any;
  phoneNumber: any;
  email: any;
  notes: any;



    constructor(
        public dialogRef: MatDialogRef<SelectMarkettingDialougeComponent>,
        private dispositionService: DispositionServiceProxy,
        private userService: UserServiceProxy,
        private formBuilder: FormBuilder,
        public gallery: Gallery,
        public lightbox: Lightbox,
        private _snackBar:MatSnackBar,
        public docService:DocumentFileServiceProxy,
        private commentService: commentService,
        private socketService: SocketioService,



        @Inject(MAT_DIALOG_DATA) public data: any,
        private _dialog: MatDialog,
        ) {
            this.isRetainer = data.isRetainer;
            this.isLeadForm = data.isLeadForm;
            this.phoneNumber = data.phoneNumber;
            this.email = data.email;
            this.notes = data.notes;
            let self = this;
            self.leadForm = [];
            data.leadForm.forEach(element => {
              if(element.phoneNumber== this.phoneNumber || element.email==this.email){
                element['checked']= false;
                self.leadForm.push(element);
              }
            });

            self.retainer = [];
            data.retainer.forEach(element => {
              if(element.phoneNumber== this.phoneNumber || element.email==this.email){
                element['checked']= false;
                self.retainer.push(element);
              }
            });
        }
        async getData() {

        }



           ngOnInit() {

            this.addForm = this.formBuilder.group({
              Id: [0],
              type: ["", [Validators.required]],
              personActing: ["", [Validators.required]],
              comments: ["", [Validators.required]],
            });
           }

    close(){
      this.dialogRef.close();
    }
    selectLeadForm(e,row){
      this.selectedLeadForm =null;
      this.leadForm.forEach(element => {
        if(row!=element){
          element.checked=false;
        }
      });
      if(e.target.checked){
      this.selectedLeadForm=row;
      }


    }
    selectRetainer(e,row){
      this.selectedRetainer = null;
      this.retainer.forEach(element => {
        if(row!=element){
          element.checked=false;
        }
      });
      if(e.target.checked){
        this.selectedRetainer=row;
        }
    }
    Submit(){
      if(this.selectedLeadForm!=null && this.selectedRetainer==null){
        let obj={};
        obj['Id'] = 0;
        obj['type'] = 'Contact Made';
        obj['personActing'] = User.Id;
        obj['leadFormId'] = this.selectedLeadForm['Id'];
        obj['comments'] = this.notes;
        obj['isDrafted'] = false;
        let self = this;
        this.dispositionService.Post(obj).subscribe(function(resp){
          Swal.fire(
            'Success!',
            'Disposition is Created.',
            'success'
          );
          self.close();
        })
      }
      if(this.selectedRetainer!=null && this.selectedLeadForm==null){
        let data = {};

        data["Id"] = 0;
        data["sendBy"] = User.Id;

        data["caseId"] = this.selectedRetainer['Id'];
        data["isDeleted"] = false;
        data["comment"]= this.notes;
        let self = this;

        this.commentService.PostComment(data).subscribe(resp => {
          Swal.fire(
            'Success!',
            'Comment Is Posted.',
            'success'
          );

          // if (this.socketService.socket.readyState === WebSocket.CLOSED || this.socketService.socket.readyState === WebSocket.CLOSING) {
          //   this.socketService.setupSocketConnection(this.selectedRetainer['Id']);
          // }

          self.close();

        }, error => {
        })
      }

      if( this.selectedLeadForm!=null && this.selectedRetainer!=null){
        let obj={};
        obj['Id'] = 0;
        obj['type'] = 'Contact Made';
        obj['personActing'] = User.Id;
        obj['leadFormId'] = this.selectedLeadForm['Id'];
        obj['comments'] = this.notes;
        obj['isDrafted'] = false;
        let self = this;
        this.dispositionService.Post(obj).subscribe(function(resp){
          let data = {};
        data["Id"] = 0;
        data["sendBy"] = User.Id;
        data["caseId"] = this.selectedRetainer['Id'];
        data["isDeleted"] = false;
        data["comment"]= this.notes;
        let self = this;
        this.commentService.PostComment(data).subscribe(resp => {
          Swal.fire(
            'Success!',
            'Disposition and Coment is Done.',
            'success'
          );
          // if (this.socketService.socket.readyState === WebSocket.CLOSED || this.socketService.socket.readyState === WebSocket.CLOSING) {
          //   this.socketService.setupSocketConnection(this.selectedRetainer['Id']);
          // }

          self.close();
        }, error => {
        })
        })
      }


    }

  }
