import { CryptoService } from './../../../Services/CryptoService.service';
import { PermissionService } from './../../../Services/PermissionServices.service';
import Chart from 'chart.js/auto'
import { loaderActivation } from './../../../../environments/loaderActivation';
import { Title } from '@angular/platform-browser';
import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { User } from 'src/environments/User';
import { DashboardServiceProxy } from 'src/app/Services/dashboard.service';
import { NavigationExtras, Router } from '@angular/router';
import { ViewportScroller } from '@angular/common';
import { AppSettings } from 'src/app/shared/app.settings';
import { EncryptionDecryptionService } from 'src/app/Services/encryption-decryption.service';
import { ITFormViewDialogComponent } from 'src/app/Support/it-form/it-form-view/it-form-view.component';
import { MatDialog } from '@angular/material/dialog';
import { AllActiveLeadsDialougeComponent } from '../all-active-lead-dialouge/all-active-lead-dialouge.component';
import {NgTinyUrlService} from 'ng-tiny-url';
import { Location } from '@angular/common'
@Component({
  selector: "app-admin-dashboard",
  templateUrl: "./admin-dashboard.component.html",
  styleUrls: ["./admin-dashboard.component.css"],
})
export class AdminDashboardComponent implements OnInit, AfterViewInit {
  viewAllFilesByMatter=false;
  canvas: any;
  ctx: any;
  leadscanvas: any;
  leadsLabel:any=[];
  leadsctx: any;
  chart: any;
  canvas1: any;
  ctx1: any;
  ctxExpected: any;
  viewAllPendingBal= false;
  allPendingBalanceData:any=[];
  chart1: any;
  chartExcpected: any;
  pbcanvas1: any;
  pbctx1: any;
  pbchart1: any;
  pscanvas1: any;
  psctx1: any;
  pschart1: any;
  currentcanvas1: any;
  currentctx1: any;
  currentchart1: any;
  outcanvas1: any;
  outctx1: any;
  outchart1: any;

  dealcanvas1: any;
  dealctx1: any;
  dealchart1: any;
  mychart: any;
  leadsChart;
  mychart1;
  expectedRevn;
  pendingBalanceChart;
  prevSalechart;
  currentSalechart;
  outSalechart;
  outChartData: any;
  outChartoptions: any;
  canvasExpected: any;
  pendingClientAccountAmount: any;
  clientAmountFiles: any=[];
  ngAfterViewInit() {
    loaderActivation.loader = true;
    try {
      this.getData();
      var canvas = $('canvas')
      canvas.css({ 'width': '100% !important', 'height': '100% !important' })
    } catch {
      loaderActivation.loader = false
    }
    this.cdr.detectChanges();
  }
  round(num) {
    return Math.round((num + Number.EPSILON) * 100) / 100
  }
  MatterColor: any = ['#FFE0B1', '#B77A3D', '#F1BE6A', '#fe7388', '#32455A']
  loader: any = false;
  user: any;
  ActiveFiles: any = [];
  allDraftedInvoices: any = [];
  lateTodo: any = [];
  CurrentTodo: any = [];
  allLeadForm: any = [];
  AwaitingFiles: any = [];
  leadWinFiles:any=[];
  leadLoseFiles:any=[];
  OutStandingFiles: any=[];
  ActiveBalanceFiles:any=[];
  draftBalanceFiles:any=[];
  disOutFiles:any=[];
  disInFiles:any=[];
  ActiveBalance: any = 0;
  AwaitingBalance: any = 0;
  outStandingBalance: any = 0;
  lostRevenue: any = 0;
  winRevenue: any = 0;
  expectedRevenue: any = 0;
  cases: any;
  ActiveCases: any = 0;
  AwaitingCases: any = 0;
  inCompletedCases: any = 0;
  branchId: any = "All";
  visaDate: any = new Date();
  leadPerStageDate: any = new Date();
  leadByMatterTypeDate: any = new Date();
  leadPerStageWinLostDate: any = new Date();
  WorkSourceDate: any = new Date();
  agreedCost: any = 0;
  disIn: any = 0;
  pendingBalance: any = 0;
  disOut: any = 0;
  manchesterPrev: any = 0;
  londonPrev: any = 0;
  chesterPrev: any = 0;
  birminghamPrev: any = 0;
  lateFiles: any = 0;
  manchesterCurrent: any = 0;
  londonCurrent: any = 0;
  chesterCurrent: any = 0;
  birminghamCurrent: any = 0;
  filteredUser: any = [];
  manchesterOutstanding: any = 0;
  londonOutstanding: any = 0;
  chesterOutstanding: any = 0;
  retainers: any = 0;
  dealWonAmountThisMonth: any = 0;
  dealLostAmountThisMonth: any = 0;
  birminghamOutstanding: any = 0;
  matters: any = [];
  pendingApprovals: any = [];
  Deadlines: any = [];
  IncompletCadenceData: any = [];

  brp: any = [];
  leadFormDetail: any = {};
  leadFormFiles :any =[];
  SolveIT: any;
  branches = User.Permissions;
  mainbranches = User.Permissions;
  inCompleteIt: any;
  public chartData;
  public MatterData;
  public barColors = {
    a: "rgb(255, 155, 68)",
    b: "rgb(252, 96, 117)",
  };
  public lateMatters;
  public It: any = [];
  public lineData;
  public lineOption;
  workSourceList: any = [];
  public matterOption;

  public pendingBalanceData;
  public leadPerStageData: any = [];
  public leadByMatterData: any = [];
  public leadPerStageWinLostData: any = [];
  public pendingBalanceOption;

  public leadPerStageOption;

  public VisaData;
  public VisaOption;
  prevMonthBalance: any = [];
  thisMonthBalance: any = [];
  OutStandingBalance: any = [];
  workSource: any = [];
  public lineColors = {
    a: "rgb(0, 0, 255)",

  };
  users: any = [];
  retainer: any = [];
  nDeadline = {}
  public chartOptions;
  colors = [
    "#376182",
    "#6c607e",
    "#c36e83",
    "#f0727d",
    "#f7b497",
    "#a6a6a6",
    "#d9d9d9",
    "#DEEBF7",
    "#E2F0D9",
    "#FFF2CC",
    "#EA4961",
    "#c41433",
    "#f5b196",
    "#CBCC31",
    "#31c141",
    "#355e7e",
    "#6a5e7c",
    "#c26d82",
    "#faeced",
    "#70c1b3",

    "#FFEFBD",
    "#fae39f",
    "#FFE699",
    "#FAD9C2",
    "#fdd4b7",
    "#F8CBAD",
    "#CEE1F2",
    "#beddf8",
    "#BDD7EE"


  ]
  lateCases: any = [];
  awaitingCompletion: any = [];
  activeLeads = []
  PendingApprovalFiles: any = [];
  AllIt: any = [];
  solvedITTicket: any = [];
  PendingITTicket: any = [];
  constructor(private titleservice: Title,
    private tinyUrl: NgTinyUrlService,
    private dashboardService: DashboardServiceProxy,
    private permissions: PermissionService,
    private encrypt: CryptoService,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private _dialog: MatDialog,
    private _vps: ViewportScroller,
    private location: Location,
    private encryptionDecryptionService: EncryptionDecryptionService) {
    this.titleservice.setTitle("Dashboard");

    // Chart.register(bar,
    //   CategoryScale,
    //   LinearScale,
    //   PointElement,
    //   LineElement,

    //   Tooltip,
    //   Legend

    // )
  }
  backBtn(){
    this.location.back();
  }
  viewProfile(id){
    let navigationExtras: NavigationExtras = {
      queryParams: {
        "userId": this.encrypt.encryptUsingAES256(JSON.stringify(id))
      }
    };
    this.router.navigate(['/profile'], navigationExtras);
    // const url = this.router.serializeUrl(this.router.createUrlTree(['/profile'],navigationExtras));
    //   window.open(url);

  }

  navigateToVisa(files){
    if(files.length>0){
      loaderActivation.loader = true;

      let navigationExtras: NavigationExtras = {
        queryParams: {
          "VISA": this.encrypt.encryptUsingAES256(JSON.stringify(files))
        }
      };
      this.router.navigate(['/dashboard-view'], navigationExtras);
      // const url = this.router.serializeUrl(this.router.createUrlTree(['/dashboard-view'],navigationExtras));
      // window.open(url);
    }

  }
  navigateToDisIn(){
    loaderActivation.loader = true;

    let navigationExtras: NavigationExtras = {
      queryParams: {
        "DisInBalance": this.encrypt.encryptUsingAES256(JSON.stringify(this.disInFiles))
      }
    };
    this.router.navigate(['/dashboard-view'], navigationExtras);
    // const url = this.router.serializeUrl(this.router.createUrlTree(['/dashboard-view'],navigationExtras));
    // window.open(url);
  }
  navigateToDisOut(){
    loaderActivation.loader = true;

    let navigationExtras: NavigationExtras = {
      queryParams: {
        "DisOutBalance": this.encrypt.encryptUsingAES256(JSON.stringify(this.disOutFiles))
      }
    };
    this.router.navigate(['/dashboard-view'], navigationExtras);
    // const url = this.router.serializeUrl(this.router.createUrlTree(['/dashboard-view'],navigationExtras));
    // window.open(url);
  }
  navigateToActiveFileBalance(){
    loaderActivation.loader = true;

    let navigationExtras: NavigationExtras = {
      queryParams: {
        "ActiveFileBalance": this.encrypt.encryptUsingAES256(JSON.stringify(this.ActiveBalanceFiles))
      }
    };
    this.router.navigate(['/dashboard-view'], navigationExtras);
    // const url = this.router.serializeUrl(this.router.createUrlTree(['/dashboard-view'],navigationExtras));
    // window.open(url);
  }
  navigateToFilesByMatter(obj){
    loaderActivation.loader = true;

    let files = obj.files
    let navigationExtras: NavigationExtras = {
      queryParams: {
        "FilesByMatter": this.encrypt.encryptUsingAES256(JSON.stringify(files))
      }
    };
    this.router.navigate(['/dashboard-view'], navigationExtras);
    // const url = this.router.serializeUrl(this.router.createUrlTree(['/dashboard-view'],navigationExtras));
    // window.open(url);
  }
  navigateToLeadFiles(obj){
    loaderActivation.loader = true;

    let navigationExtras: NavigationExtras = {
      queryParams: {
        "LeadFiles": this.encrypt.encryptUsingAES256(JSON.stringify(obj))
      }
    };
    this.router.navigate(['/lead-file-view'], navigationExtras);
    // const url = this.router.serializeUrl(this.router.createUrlTree(['/lead-file-view'],navigationExtras));
    // window.open(url);
  }
  navigateToLeadFilesMatter(obj){
    loaderActivation.loader = true;

    let navigationExtras: NavigationExtras = {
      queryParams: {
        "LeadFilesMatter": this.encrypt.encryptUsingAES256(JSON.stringify(obj))
      }
    };
    this.router.navigate(['/lead-file-view'], navigationExtras);
    // const url = this.router.serializeUrl(this.router.createUrlTree(['/lead-file-view'],navigationExtras));
    // window.open(url);
  }
  navigateToDraftBalance(){
    // loaderActivation.loader = true;

    let navigationExtras: NavigationExtras = {
      queryParams: {
        "DraftBalanceFiles": this.encrypt.encryptUsingAES256(JSON.stringify(this.draftBalanceFiles))
      }
    };
    this.router.navigate(['/dashboard-view'], navigationExtras);
  }
  navigateToOutStandingBalance(){
    loaderActivation.loader = true;

    let navigationExtras: NavigationExtras = {
      queryParams: {
        "OutStandingBalanceFiles": this.encrypt.encryptUsingAES256(JSON.stringify(this.OutStandingFiles))
      }
    };
    this.router.navigate(['/dashboard-view'], navigationExtras);
    // const url = this.router.serializeUrl(this.router.createUrlTree(['/dashboard-view'],navigationExtras));
    // window.open(url);
  }
  navigateToCaseWorkerFiles(files) {
    loaderActivation.loader = true;

    let navigationExtras: NavigationExtras = {
      queryParams: {
        "CaseWorkerFiles": this.encrypt.encryptUsingAES256(JSON.stringify(files))
      }
    };
    this.router.navigate(['/dashboard-view'], navigationExtras);
    // const url = this.router.serializeUrl(this.router.createUrlTree(['/dashboard-view'],navigationExtras));
    // window.open(url);
  }
  navigateToActiveFiles() {
    loaderActivation.loader = true;
    let navigationExtras: NavigationExtras = {
      queryParams: {
        "ActiveFiles": this.encrypt.encryptUsingAES256(JSON.stringify(this.ActiveFiles))
      }
    };
    this.router.navigate(['/dashboard-view'], navigationExtras);
    // const url = this.router.serializeUrl(this.router.createUrlTree(['/dashboard-view'],navigationExtras));
    // window.open(url);
  }
  navigateToAwaitingFiles() {
    loaderActivation.loader = true;

    let navigationExtras: NavigationExtras = {
      queryParams: {
        "AwaitingFiles": this.encrypt.encryptUsingAES256(JSON.stringify(this.AwaitingFiles))
      }
    };
    this.router.navigate(['/dashboard-view'], navigationExtras);
    // const url = this.router.serializeUrl(this.router.createUrlTree(['/dashboard-view'],navigationExtras));
    // window.open(url);
  }
  navigateToClientToOfficeTransfers() {
    // loaderActivation.loader = true;

    let navigationExtras: NavigationExtras = {
      queryParams: {
        "clientAmount": this.encrypt.encryptUsingAES256(JSON.stringify(this.clientAmountFiles))
      }
    };
    this.router.navigate(['/dashboard-view'], navigationExtras);
    // const url = this.router.serializeUrl(this.router.createUrlTree(['/dashboard-view'],navigationExtras));
    // window.open(url);
  }
  navigateToAwaitingCompleteion() {
    loaderActivation.loader = true;

    let navigationExtras: NavigationExtras = {
      queryParams: {
        "AwaitingCompletion": this.encrypt.encryptUsingAES256(JSON.stringify(this.awaitingCompletion))
      }
    };
    this.router.navigate(['/dashboard-view'], navigationExtras);
    // const url = this.router.serializeUrl(this.router.createUrlTree(['/dashboard-view'],navigationExtras));
    // window.open(url);
  }
  navigateToRetainer() {
    loaderActivation.loader = true;

    let navigationExtras: NavigationExtras = {
      queryParams: {
        "Retainer": this.encrypt.encryptUsingAES256(JSON.stringify(this.retainer))
      }
    };
    this.router.navigate(['/dashboard-view'], navigationExtras);
    // const url = this.router.serializeUrl(this.router.createUrlTree(['/dashboard-view'],navigationExtras));
    // window.open(url);
  }
  ncolors = [];
  randomColorPicker() {
    var color;
    // while(true){
    let randomNumber = Math.floor(Math.random() * (this.colors.length - 1));
    color = this.colors[randomNumber]
    if (this.ncolors.indexOf(color) == -1) {
      this.ncolors.push(color);
      //  break;
    }
    // }
    return color;
  }
  navigateToLateFiles() {
    loaderActivation.loader = true;

    let navigationExtras: NavigationExtras = {
      queryParams: {
        "Latefiles": this.encrypt.encryptUsingAES256(JSON.stringify(this.lateCases))
      }
    };
    this.router.navigate(['/dashboard-view'], navigationExtras);
    // const url = this.router.serializeUrl(this.router.createUrlTree(['/dashboard-view'],navigationExtras));
    // window.open(url);
  }
  navigateToPending() {
    loaderActivation.loader = true;

    let navigationExtras: NavigationExtras = {
      queryParams: {
        "Pending": this.encrypt.encryptUsingAES256(JSON.stringify(this.PendingApprovalFiles))
      }
    };
    this.router.navigate(['/dashboard-view'], navigationExtras);
    // const url = this.router.serializeUrl(this.router.createUrlTree(['/dashboard-view'],navigationExtras));
    // window.open(url);
  }
  navigateToITTickets() {
    loaderActivation.loader = true;

    let navigationExtras: NavigationExtras = {
      queryParams: {
        "ITTicket": this.encrypt.encryptUsingAES256(JSON.stringify(this.AllIt))
      }
    };
    this.router.navigate(['/dashboard-view'], navigationExtras);
    // const url = this.router.serializeUrl(this.router.createUrlTree(['/dashboard-view'],navigationExtras));
    // window.open(url);
  }
  navigateToSolvedITTickets() {
    loaderActivation.loader = true;

    let navigationExtras: NavigationExtras = {
      queryParams: {
        "Solved": this.encrypt.encryptUsingAES256(JSON.stringify(this.solvedITTicket))
      }
    };
    this.router.navigate(['/dashboard-view'], navigationExtras);
    // const url = this.router.serializeUrl(this.router.createUrlTree(['/dashboard-view'],navigationExtras));
    // window.open(url);
  }
  navigateToPendingITTickets() {
    loaderActivation.loader = true;

    let navigationExtras: NavigationExtras = {
      queryParams: {
        "PendingIT": this.encrypt.encryptUsingAES256(JSON.stringify(this.PendingITTicket))
      }
    };
    this.router.navigate(['/dashboard-view'], navigationExtras);
    // const url = this.router.serializeUrl(this.router.createUrlTree(['/dashboard-view'],navigationExtras));
    // window.open(url);
  }

  navigateToPendingPrevSale() {
    loaderActivation.loader = true;

    let navigationExtras: NavigationExtras = {
      queryParams: {
        "PrevMonth": this.encrypt.encryptUsingAES256(JSON.stringify(this.prevMonthBalance))
      }
    };
    this.router.navigate(['/dashboard-view'], navigationExtras);
    // const url = this.router.serializeUrl(this.router.createUrlTree(['/dashboard-view'],navigationExtras));
    // window.open(url);
  }


  navigateToPendingCurrentSale() {
    loaderActivation.loader = true;

    let navigationExtras: NavigationExtras = {
      queryParams: {
        "CurrentMonth": this.encrypt.encryptUsingAES256(JSON.stringify(this.thisMonthBalance))
      }
    };
    this.router.navigate(['/dashboard-view'], navigationExtras);
    // const url = this.router.serializeUrl(this.router.createUrlTree(['/dashboard-view'],navigationExtras));
    // window.open(url);
  }

  navigateToPendingOutstandSale() {
    loaderActivation.loader = true;

    let navigationExtras: NavigationExtras = {
      queryParams: {
        "OutStanding": this.encrypt.encryptUsingAES256(JSON.stringify(this.OutStandingBalance))
      }
    };
    this.router.navigate(['/dashboard-view'], navigationExtras);
    // const url = this.router.serializeUrl(this.router.createUrlTree(['/dashboard-view'],navigationExtras));
    // window.open(url);
  }
  showMoreCadence() {
    this.cadenceShow += 4;
  }
  showLessCadence(index) {
    this.cadenceShow -= 4;
    var id = index - 10;
    let el = document.getElementById(id + "_cadence");
    var nid = id + "_cadence";
    // el.scrollIntoView({behavior: 'smooth'});
    this._vps.scrollToAnchor(nid);
  }
  showMore() {
    this.todoShow += 4;
  }
  crrTodo: any = [];
  overDueTodo: any = [];
  showLess(index) {
    this.todoShow -= 4;
    var id = index - 10;
    let el = document.getElementById(id + "_todo");
    var nid = id + "_todo";
    // el.scrollIntoView({behavior: 'smooth'});
    this._vps.scrollToAnchor(nid);
  }
  showMoreOverDue() {
    this.overDue += 4;
  }
  showLessOverDues(index) {
    this.overDue -= 4;
    var id = index - 10;
    let el = document.getElementById(id + "_overdue");
    var nid = id + "_overdue";
    // el.scrollIntoView({behavior: 'smooth'});
    this._vps.scrollToAnchor(nid);
  }
  checkTodo() {
    if (this._todo != "All") {
      var type;
      if (this._todo === "App") {
        type = 1;
      } else if (this._todo === "Retainers") {
        type = 2;
      } else if (this._todo === "IT") {
        type = 0;
      } else if (this._todo === "Leads") {
        type = 3;
      } else if (this._todo === "CaseInvoices") {
        type = 4;
      }
      var ctodo = this.CurrentTodo.filter((x => x.type === type));
      if (ctodo.length > 0) {
        return false;
      } else {
        return true;
      }
    }
  }
  checkOverDue() {
    if (this._todo != "All") {
      var type;
      if (this._todo === "App") {
        type = 1;
      } else if (this._todo === "Retainers") {
        type = 2;
      } else if (this._todo === "IT") {
        type = 0;
      } else if (this._todo === "Leads") {
        type = 3;
      } else if (this._todo === "CaseInvoices") {
        type = 4;
      }
      var ctodo = this.lateTodo.filter((x => x.type === type));
      if (ctodo.length > 0) {
        return false;
      } else {
        return true;
      }
    }
  }
  npColor: any = [];
  async getData() {
    $("#p-tabpanel-0").attr("aria-hidden", "false");
    $("#p-tabpanel-1").attr("aria-hidden", "false");
    $("#p-tabpanel-2").attr("aria-hidden", "false");
    $("#p-tabpanel-3").attr("aria-hidden", "false");
    var self = this;
    const promise = this.dashboardService.getCases().toPromise();
    await promise.then(function (resp) {
      self.cases = resp['result'];
      self.matters = resp['Matters'];
      self.It = resp['IT'];
      self.workSource = resp["WorkSource"];
      self.users = resp["Users"];
      self.allLeadForm = resp['leadForms'];
      console.log(resp);
      self.toDoFirstTurn= false;
      self.todo();
      // self.legal();
      // self.marketing();
      // self.accounts();
      // self.userFunc();
    })
    this.loader = false;
    loaderActivation.loader=false;

  }
  todoShow = 4;
  cadenceShow = 4;
  overDue = 4;
  cadenceClick(value) {
    let toSetValues = value;
    const encId = this.encryptionDecryptionService.encrypt(toSetValues.toString());
    const param = toSetValues.toString()
    // const param = { queryParams: { row: toSetValues.Id.toString() } };
    this.router.navigate([AppSettings.DetailLeadForm_URL, param]);
    // const url = this.router.serializeUrl(this.router.createUrlTree([AppSettings.DetailLeadForm_URL],param));
    // window.open(url);
  }
  prevSale = { 'color': '#57a7fe', 'label': 'Manchester', 'value': 0 }
  currSale = { 'color': '#57a7fe', 'label': 'Manchester', 'value': 0 }
  outBalance = { 'color': '#57a7fe', 'label': 'Manchester', 'value': 0 }
  todo(){
    this.CurrentTodo = [];
    this.lateTodo = [];
    let self = this;
    this.allLeadForm.forEach(element => {
      if (!element.isDrafted) {

        // Leads Reminder TOdo
        var ar = [0, 3, 4, 5, 6, 7, 9];
        var p = this.checkPermission('Dashboard', 'level', this.branchId);
        var check = false;
        p.forEach(function (el) {
          if (ar.indexOf(parseInt(el)) < 0) {
            check = true;
            return;
          }
        });
        if ((check && element.branchId == self.user.branchId) || self.user.RoleId == 1) {
          element.reminders.forEach(element1 => {
            let dateSent = new Date(element1.creationTime);
            let currentDate = new Date(element1.date);
            var ntime = Math.floor((Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()) - Date.UTC(dateSent.getFullYear(), dateSent.getMonth(), dateSent.getDate())) / (1000 * 60 * 60 * 24));
            if (ntime < 1) {
              this.lateTodo.push({ "Id": element.Id, "Text": element1.comments, "ItemId": element1.personActingName, "type": 3, "Date": element1.creationTime })
            } else {
              this.CurrentTodo.push({ "Id": element.Id, "Text": element1.comments, "ItemId": element1.personActingName, "type": 3, "Date": element1.creationTime })
            }
          });
        }
      }
    });
    this.It.forEach(element => {
      if (self.branchId === "All" || self.branchId === element.branchName) {
        if ((element.userId === self.user.Id || self.user.RoleId === 1 || self.user.RoleName === "IT") && !element.isCompleted) {

          self.PendingITTicket.push(element);
          this.AllIt.push(element);
          self.inCompleteIt += 1;
          let dateSent = new Date(element.creationTime);
          let currentDate = new Date();
          var ntime = Math.floor((Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()) - Date.UTC(dateSent.getFullYear(), dateSent.getMonth(), dateSent.getDate())) / (1000 * 60 * 60 * 24));
          if ((ntime > 1 && element.priorityName === "High") || (ntime > 3 && element.priorityName === "Medium") || (ntime > 5 && element.priorityName === "Low")) {
            this.lateTodo.push({ "Id": element.Id, "Text": element.ticketSubject, "ItemId": element.ticketId, "type": 0, "Date": element.creationTime })
          } else {
            this.CurrentTodo.push({ "Id": element.Id, "Text": element.ticketSubject, "ItemId": element.ticketId, "type": 0, "Date": element.creationTime })
          }
        }
      }
    });
    self.cases.forEach(element => {
      element['outBalance']= 0;
      var ar = [1, 2, 4];
      var p = this.checkPermission('Dashboard', 'level', this.branchId);
      var check = false;
      p.forEach(function (el) {
        if (ar.indexOf(parseInt(el)) >= 0) {
          check = true;
          return;
        }
      });


      if (check) {
        element.caseInvoice.forEach(element1 => {
          if (element1.isDraft) {
            this.allDraftedInvoices.push(element1);
            let dateSent = new Date(element1.creationTime);
            let currentDate = new Date(element.deadLine);
            var ntime = Math.floor((Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()) - Date.UTC(dateSent.getFullYear(), dateSent.getMonth(), dateSent.getDate())) / (1000 * 60 * 60 * 24));

            this.CurrentTodo.push({ "Id": element.Id, "Text": "You Got a new Draft Payement", "ItemId": element.fileNumber, "type": 4, "Date": element1.creationTime })
          }
        });
      }
      if (element.isDraft == true) {
        let deadline = new Date(element.deadLine);
        let current_date = new Date();
        var p = self.checkPermission('Dashboard', 'level', self.branchId);
        var pcheck = false;
        var arr = [2];
        p.forEach(function (el) {
          if (arr.indexOf(parseInt(el)) > -1) {
            pcheck = true;
            return;
          }
        });
        var Acheck = false;
        var narr = [4];
        p.forEach(function (el) {
          if (narr.indexOf(parseInt(el)) > -1) {
            Acheck = true;
            return;
          }
        });
        if ((element.personResposibleId == self.user.Id) || (self.user.RoleId == 1 || pcheck || Acheck)) {
          if (self.branchId === element.personResponsilbeBranchName) {
            if (current_date > deadline && element.caseStatus === 0) {
              if ((element.personResposibleId == self.user.Id) || (self.user.RoleId == 1) || (!pcheck && !Acheck)) {
                this.lateTodo.push({ "Id": element.Id, "Text": element.matterName, "ItemId": element.fileNumber, "type": 1, "Date": element.deadLine });
              }
            }
            if (current_date < deadline && element.caseStatus == 0) {
              if ((element.personResposibleId == self.user.Id) || (self.user.RoleId == 1) || (!pcheck && !Acheck)) {
                this.CurrentTodo.push({ "Id": element.Id, "Text": element.matterName, "ItemId": element.fileNumber, "type": 1, "Date": element.deadLine });
              }
            }
            else if (element.caseStatus == 1) {
              if (current_date < deadline) {
                if ((element.personResposibleId == self.user.Id) || (self.user.RoleId == 1) || (!pcheck && !Acheck)) {
                  this.CurrentTodo.push({ "Id": element.Id, "Text": element.matterName, "ItemId": element.fileNumber, "type": 1, "Date": element.deadLine });
                }
              }
            }
            else if (element.caseStatus == 2) {
              if (current_date < deadline) {
                if ((element.personResposibleId == self.user.Id) || (self.user.RoleId == 1) || (!pcheck && !Acheck)) {
                  this.CurrentTodo.push({ "Id": element.Id, "Text": element.matterName, "ItemId": element.fileNumber, "type": 1, "Date": element.deadLine });
                }
              }
            }
          } else if (self.branchId === "All") {
            if (current_date > deadline && element.caseStatus === 0) {
              self.lateFiles += 1;
              if ((element.personResposibleId == self.user.Id) || (self.user.RoleId == 1) || (!pcheck && !Acheck)) {
                this.lateTodo.push({ "Id": element.Id, "Text": element.matterName, "ItemId": element.fileNumber, "type": 1, "Date": element.deadLine });
              }
            }
            if (current_date < deadline && element.caseStatus == 0) {
              if ((element.personResposibleId == self.user.Id) || (self.user.RoleId == 1) || (!pcheck && !Acheck)) {
                this.CurrentTodo.push({ "Id": element.Id, "Text": element.matterName, "ItemId": element.fileNumber, "type": 1, "Date": element.deadLine });
              }
            } else if (element.caseStatus == 1) {
              if (current_date < deadline) {
                if ((element.personResposibleId == self.user.Id) || (self.user.RoleId == 1) || (!pcheck && !Acheck)) {
                  this.CurrentTodo.push({ "Id": element.Id, "Text": element.matterName, "ItemId": element.fileNumber, "type": 1, "Date": element.deadLine });
                }
              }
            }
            else if (element.caseStatus == 2) {
              if (current_date < deadline) {
                if ((element.personResposibleId == self.user.Id) || (self.user.RoleId == 1) || (!pcheck && !Acheck)) {
                  this.CurrentTodo.push({ "Id": element.Id, "Text": element.matterName, "ItemId": element.fileNumber, "type": 1, "Date": element.deadLine });
                }
              }
            }
          }
        }
         else {

          let deadline = new Date(element.deadLine);
          let current_date = new Date();
          var caseworkerIds = element.caseWorkerList.map(x => { return x.userId_id });
          var workersIndex = caseworkerIds.indexOf(self.user.Id) > -1;
          var p = self.checkPermission('Dashboard', 'level', self.branchId);
          var pcheck = false;
          var arr = [5, 6];
          p.forEach(function (el) {
            if (arr.indexOf(parseInt(el)) > -1) {
              pcheck = true;
              return;
            }
          });



          if (workersIndex && (pcheck)) {
            if (self.branchId === element.personResponsilbeBranchName) {

              if (current_date > deadline && element.caseStatus === 0) {
                if ((element.personResposibleId == self.user.Id) || (self.user.RoleId == 1) || (pcheck)) {
                  this.lateTodo.push({ "Id": element.Id, "Text": element.matterName, "ItemId": element.fileNumber, "type": 1, "Date": element.deadLine });
                }
              }
              if (current_date < deadline && element.caseStatus == 0) {
                if ((element.personResposibleId == self.user.Id) || (self.user.RoleId == 1) || (pcheck)) {
                  this.CurrentTodo.push({ "Id": element.Id, "Text": element.matterName, "ItemId": element.fileNumber, "type": 1, "Date": element.deadLine });
                }
              } else if (element.caseStatus == 1) {
                if (current_date < deadline) {
                  if ((element.personResposibleId == self.user.Id) || (self.user.RoleId == 1) || (pcheck)) {
                    this.CurrentTodo.push({ "Id": element.Id, "Text": element.matterName, "ItemId": element.fileNumber, "type": 1, "Date": element.deadLine });
                  }
                }

              }
              else if (element.caseStatus == 2) {
                if (current_date < deadline) {
                  if ((element.personResposibleId == self.user.Id) || (self.user.RoleId == 1) || (pcheck)) {
                    this.CurrentTodo.push({ "Id": element.Id, "Text": element.matterName, "ItemId": element.fileNumber, "type": 1, "Date": element.deadLine });
                  }
                }
              }
            } else if (self.branchId === "All") {

              if (current_date > deadline && element.caseStatus === 0) {
                this.lateTodo.push({ "Id": element.Id, "Text": element.matterName, "ItemId": element.fileNumber, "type": 1, "Date": element.deadLine });
              }
              if (current_date < deadline && element.caseStatus == 0) {
                this.CurrentTodo.push({ "Id": element.Id, "Text": element.matterName, "ItemId": element.fileNumber, "type": 1, "Date": element.deadLine });
              } else if (element.caseStatus == 1) {
                if (current_date < deadline) {
                  this.CurrentTodo.push({ "Id": element.Id, "Text": element.matterName, "ItemId": element.fileNumber, "type": 1, "Date": element.deadLine });
                }
              }
              else if (element.caseStatus == 2) {
                if (current_date < deadline) {
                  this.CurrentTodo.push({ "Id": element.Id, "Text": element.matterName, "ItemId": element.fileNumber, "type": 1, "Date": element.deadLine });
                }
              }
            }
          }
        }
      }
      else {
        var p = self.checkPermission('Dashboard', 'level', self.branchId);
        var pcheck = false;
        var arr = [1, 2, 3];
        p.forEach(function (el) {
          if (arr.indexOf(parseInt(el)) > -1) {
            pcheck = true;
            return;
          }
        });
        let deadline = new Date(element.deadLine);
        let current_date = new Date();
        if (pcheck) {
          if (current_date > deadline) {
            this.lateTodo.push({ "Id": element.Id, "Text": element.matterName, "ItemId": "Client Name : " + element.clientName, "type": 2, "Date": element.deadLine });
          } else {
            this.CurrentTodo.push({ "Id": element.Id, "Text": element.matterName, "ItemId": "Client Name : " + element.clientName, "type": 2, "Date": element.deadLine });
          }
        }
      }
    });

    this.CurrentTodo = this.CurrentTodo.sort((a, b) => (new Date(b.Date) < new Date(a.Date) ? -1 : 1));
    this.lateTodo = this.lateTodo.sort((a, b) => (new Date(a.Date) > new Date(b.Date) ? 1 : -1));
    this.overDueTodo = [];
    this.crrTodo = [];
    this.overDueTodo = this.lateTodo;
    this.crrTodo = this.CurrentTodo;
  }
  legal(){
    let self = this;
    this.ActiveFiles = [];
    this.ActiveCases = 0;
    this.AwaitingFiles = [];
    this.OutStandingFiles = [];
    this.ActiveBalanceFiles = [];
    this.draftBalanceFiles = [];
    this.disOutFiles = [];
    this.disInFiles = [];
    this.awaitingCompletion = [];
    this.retainers = 0;
    this.agreedCost = 0;
    this.inCompletedCases = 0;
    this.AwaitingCases = 0;
    this.outStandingBalance = 0;
    this.disOut = 0;
    this.disIn = 0;
    this.ActiveBalance = 0;
    this.Deadlines = [];
    this.nDeadline = {};
    var matterList: any = {};
    this.VisaData = [];
    this.MatterData = [];
    var refused = 0;
    var refusedFiles =[];
    var Granted = 0
    var GrantedFiles =[];
    var matterIds = this.matters.map(x => { return x.pk });
    this.matters.forEach(element => {

      matterList[element.pk] = { value: 0, name: element.fields.name ,files:[]};
    });
    this.cases.forEach(element => {
      element['outBalance']= 0;
      var ar = [1, 2, 4];
      var p = this.checkPermission('Dashboard', 'level', this.branchId);
      var check = false;
      p.forEach(function (el) {
        if (ar.indexOf(parseInt(el)) >= 0) {
          check = true;
          return;
        }
      });
      // if (check) {
      //   element.caseInvoice.forEach(element1 => {
      //     if (element1.isDraft) {
      //       this.allDraftedInvoices.push(element1);
      //       let dateSent = new Date(element1.creationTime);
      //       let currentDate = new Date(element.deadLine);
      //       var ntime = Math.floor((Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()) - Date.UTC(dateSent.getFullYear(), dateSent.getMonth(), dateSent.getDate())) / (1000 * 60 * 60 * 24));
      //       // if (ntime < 1) {
      //       // this.lateTodo.push({ "Id": element.Id, "Text": "You Got a new Draft Payement", "ItemId": element.fileNumber, "type": 4, "Date": element1.creationTime })
      //       // } else {
      //       this.CurrentTodo.push({ "Id": element.Id, "Text": "You Got a new Draft Payement", "ItemId": element.fileNumber, "type": 4, "Date": element1.creationTime })
      //       // }
      //     }
      //   });
      // }

      // if(self.branchId===element.personResponsilbeBranchName){
      if (element.isDraft == true) {
        let deadline = new Date(element.deadLine);
        let current_date = new Date();

        let creationDate = new Date(element.creationTime)
        // if (self.branchId === element.personResponsilbeBranchName || self.branchId === "All") {
        //   let wDate = new Date(self.WorkSourceDate);
        //   console.log(creationDate.getMonth(), wDate.getMonth(), creationDate.getFullYear(), wDate.getFullYear(), creationDate.getMonth() === wDate.getMonth() && creationDate.getFullYear() === wDate.getFullYear())

        //   if (!self.WorkSourceDate) {
        //     if (element.workSourceId) {
        //       workSrc[element.workSourceId].value += 1
        //     }
        //   } else {
        //     let wDate = new Date(self.WorkSourceDate);
        //     if (creationDate.getMonth() === wDate.getMonth() && creationDate.getFullYear() === wDate.getFullYear()) {
        //       if (element.workSourceId) {
        //         workSrc[element.workSourceId].value += 1
        //       }
        //     }
        //   }
        // }
        if (element.visaExpiry && element.caseStatus >= 2) {
          if (self.branchId === element.personResponsilbeBranchName || self.branchId === "All") {
            self.brp.push({ "Name": element.clientName, "Date": element.visaExpiry })
            if (!self.visaDate) {
              Granted += 1;
              element['grantOrReject']="Granted";
              GrantedFiles.push(element);
            } else {
              let vDate = new Date(self.visaDate);

              let vDateMonth = vDate.getMonth();
              let vDateYear = vDate.getFullYear();
              let cMonth = creationDate.getMonth();
              let cYear = creationDate.getFullYear();
              if (cMonth === vDateMonth && vDateYear === cYear) {
                Granted += 1;
                element['grantOrReject']="Granted";
                GrantedFiles.push(element);
              }
            }
          }
        } else {
          if (element.caseStatus >= 2) {
            if (self.branchId === element.personResponsilbeBranchName || self.branchId === "All") {
              if (!self.visaDate) {
                refused += 1;
                element['grantOrReject']='Refused'
                refusedFiles.push(element);
              } else {
                let vDate = new Date(self.visaDate);
                let vDateMonth = vDate.getMonth();
                let vDateYear = vDate.getFullYear();
                let cMonth = creationDate.getMonth();
                let cYear = creationDate.getFullYear();
                if (cMonth === vDateMonth && vDateYear === cYear) {
                  refused += 1;
                element['grantOrReject']='Refused'

                refusedFiles.push(element);

                }
              }
            }
          }
        }
        var p = self.checkPermission('Dashboard', 'level', self.branchId);
        var pcheck = false;
        var arr = [2];
        p.forEach(function (el) {
          if (arr.indexOf(parseInt(el)) > -1) {
            pcheck = true;
            return;
          }
        });
        var Acheck = false;
        var narr = [4];
        p.forEach(function (el) {
          if (narr.indexOf(parseInt(el)) > -1) {
            Acheck = true;
            return;
          }
        });
        if ((element.personResposibleId == self.user.Id) || (self.user.RoleId == 1 || pcheck || Acheck)) {
          let disInCheck = false;
          let disInTotal =0;
          let disOutCheck = false;
          let disOutTotal =0;
          element.caseWorkerList.forEach(element1 => {
            if (element1.branchName === self.branchId || self.branchId === "All") {
              if (current_date < deadline && element.caseStatus === 0) {
                this.Deadlines.push({"Id":element.Id, "Name": element1.CaseWorkerName,"profilePic":element1.profilePic, "Date": deadline, "ClientName": element.clientName, "MatterName": element.matterName, 'FileNumber': element.fileNumber });
              }
            }
          });
          if (self.branchId === element.personResponsilbeBranchName) {
            var index = matterIds.indexOf(element.matterId);
            if (index > -1 && !Acheck) {
              if (element.caseStatus != 3) {
                matterList[element.matterId].value += 1;
                matterList[element.matterId].name = element.matterName;
                matterList[element.matterId].files.push(element)
              }
            }

            if (element.caseStatus === 0) {
              self.ActiveFiles.push(element);
              self.ActiveCases += 1;
            }
            else if (element.caseStatus == 1) {
              // if (current_date < deadline) {
              //   if ((element.personResposibleId == self.user.Id) || (self.user.RoleId == 1) || (!pcheck && !Acheck)) {
              //     this.CurrentTodo.push({ "Id": element.Id, "Text": element.matterName, "ItemId": element.fileNumber, "type": 1, "Date": element.deadLine });
              //   }
              // }

              self.AwaitingCases += 1
              self.AwaitingFiles.push(element);
            }

            else if (element.caseStatus == 2) {
              // if (current_date < deadline) {
              //   if ((element.personResposibleId == self.user.Id) || (self.user.RoleId == 1) || (!pcheck && !Acheck)) {
              //     this.CurrentTodo.push({ "Id": element.Id, "Text": element.matterName, "ItemId": element.fileNumber, "type": 1, "Date": element.deadLine });
              //   }
              // }
              self.inCompletedCases += 1;
              self.awaitingCompletion.push(element);
            }

            // var caseWorkersId = [];
            // element.caseWorkerList.forEach(element1 => {
            //   if (element1.status === 1) {
            //     self.pendingApprovals += 1;
            //     self.PendingApprovalFiles.push(element);
            //     return;
            //   }

            // });
          }
          else if (self.branchId === "All") {
            var index = matterIds.indexOf(element.matterId);
            if (index > -1 && !Acheck) {
              if (element.caseStatus != 3) {
                matterList[element.matterId].value += 1;
                matterList[element.matterId].name = element.matterName;
                matterList[element.matterId].files.push(element)


              }
              // if (current_date > deadline && element.caseStatus === 0) {
              //   LateMatter[element.matterId].value += 1;
              //   LateMatter[element.matterId].name = element.matterName;
              //   LateMatter[element.matterId].files.push(element)

              // }
            }

            if (element.caseStatus === 0) {
              // self.lateFiles += 1;
              // var caseWorkers = element.caseWorkerList.map(x => x.userId_id)
              // var wIndex = 0
              // console.log(caseIds);
              // caseWorkers.forEach(item => {
              //   var keys = Object.keys(LateFiles)
              //   var index = keys.indexOf(item.toString()) > -1


              //   if (caseWorkersId.indexOf(item) < 0) {
              //     if (index) {
              //       LateFiles[item].Files += 1;
              //       console.log(LateFiles[item].Files, LateFiles[item].Name, element.Id, "lateFiles")
              //     }
              //     // else {
              //     //   if (current_date > deadline) {
              //     //     LateFiles[item] = { Files: 1, Name: element.caseWorkerList[wIndex].CaseWorkerName };

              //     //   }
              //     //   //  else {
              //     //   // LateFiles[item] = { Files: 1, Name: element.caseWorkerList[wIndex].CaseWorkerName };
              //     //   // }
              //     // }
              //     caseWorkersId.push(item)



              //   }

              //   wIndex += 1
              // });
              self.ActiveFiles.push(element);
              self.ActiveCases += 1;
              // if ((element.personResposibleId == self.user.Id) || (self.user.RoleId == 1) || (!pcheck && !Acheck)) {
              //   this.lateTodo.push({ "Id": element.Id, "Text": element.matterName, "ItemId": element.fileNumber, "type": 1, "Date": element.deadLine });
              // }
              // this.lateCases.push(element);
            }
            // if (current_date < deadline && element.caseStatus == 0) {
            //   self.ActiveCases += 1;
            //   if ((element.personResposibleId == self.user.Id) || (self.user.RoleId == 1) || (!pcheck && !Acheck)) {
            //     this.CurrentTodo.push({ "Id": element.Id, "Text": element.matterName, "ItemId": element.fileNumber, "type": 1, "Date": element.deadLine });
            //   }
            //   self.ActiveFiles.push(element);
            // }
            else if (element.caseStatus == 1) {
              // if (current_date < deadline) {
              //   if ((element.personResposibleId == self.user.Id) || (self.user.RoleId == 1) || (!pcheck && !Acheck)) {
              //     this.CurrentTodo.push({ "Id": element.Id, "Text": element.matterName, "ItemId": element.fileNumber, "type": 1, "Date": element.deadLine });
              //   }
              // }
              self.AwaitingCases += 1;

              self.AwaitingFiles.push(element);
            }
            else if (element.caseStatus == 2) {
              // if (current_date < deadline) {
              //   if ((element.personResposibleId == self.user.Id) || (self.user.RoleId == 1) || (!pcheck && !Acheck)) {
              //     this.CurrentTodo.push({ "Id": element.Id, "Text": element.matterName, "ItemId": element.fileNumber, "type": 1, "Date": element.deadLine });
              //   }
              // }
              self.inCompletedCases += 1;
              self.awaitingCompletion.push(element);

            }
            // element.caseWorkerList.forEach(element1 => {
            //   if (element1.status === 1) {
            //     self.pendingApprovals += 1;
            //     self.PendingApprovalFiles.push(element);
            //     return;
            //   }
            // });
          }
          let invoiceCheck= false;
          let invoiceBal = 0;
          element['caseInvoice'].forEach(invoice => {
            if (self.branchId === "All") {
              if(invoice.isDraft && invoice.amountPaid){
                self.agreedCost += parseFloat(invoice.amountPaid);
                invoiceBal+=parseFloat(invoice.amountPaid);
                invoiceCheck= true;
              }

              // self.agreedCost += parseFloat(invoice.agreedCost);
            } else {
              if (self.branchId === element.personResponsilbeBranchName) {
                if(invoice.isDraft && invoice.amountPaid){
                  self.agreedCost += parseFloat(invoice.amountPaid);
                  invoiceBal+=parseFloat(invoice.amountPaid);
                invoiceCheck= true;
                }
                // self.agreedCost += parseFloat(invoice.agreedCost);
              }
            }

          });
          if(invoiceCheck){
            if(invoiceBal>0){
              element['invoiceBal']=invoiceBal;
              self.draftBalanceFiles.push(element);

            }
          }
          let outStandCheck=false;
          let activeBalanceCheck=false;
          let outBalance = 0;
          let activeBalance = 0;
          element['officeInvoice'].forEach(function (r) {

            var i = 0;
            let totalAmount = Number(r['amount'])
             let balance = totalAmount;
            r['officeAccount'].forEach(element1 => {
              if (element1['isInPayment'] && !element1['isDeleted']) {
                let amount = Number(element1['amount'])
                balance = balance - amount;

              }
            });
            r['balance'] = balance;
            if(r['bankAccount']==self.branchId){
              if (r.typeOfPayment === "Legal FEE" && (element.caseStatus == 0 || element.caseStatus == 1) && !Acheck) {
                self.outStandingBalance += parseFloat(r['balance']);
                outBalance+= parseFloat(r['balance']);
                outStandCheck= true;

              }
              if (r.typeOfPayment === "Legal FEE" && (element.caseStatus == 0) && !Acheck) {
                self.ActiveBalance += parseFloat(r['balance']);
                activeBalance +=parseFloat(r['balance']);
                activeBalanceCheck= true;
              }
            }
            else if(self.branchId === "All"){
              if (r.typeOfPayment === "Legal FEE" && (element.caseStatus == 0 || element.caseStatus == 1) && !Acheck) {
                self.outStandingBalance += parseFloat(r['balance']);
                outBalance+= parseFloat(r['balance']);
                outStandCheck= true;
              }
              if (r.typeOfPayment === "Legal FEE" && (element.caseStatus == 0) && !Acheck) {
                self.ActiveBalance += parseFloat(r['balance']);
                activeBalance +=parseFloat(r['balance']);
                activeBalanceCheck= true;
              }
            }
            // if()
            r['officeAccount'].forEach(office => {
              if (office.isInPayment) {
                let latest_date = new Date(office.entryDate);
                let date = new Date();

                // if (office.bankAccount === "Manchester" && self.branchId == "Manchester" && office.typeOfPayment === "Legal FEE") {
                //   if (last_Month === current_Month + 1 && latest_date.getFullYear() === date.getFullYear() && office.typeOfPayment === "Legal FEE") {
                //     self.manchesterCurrent += parseFloat(office.amount);
                //     console.log('This Month Sale ', element.Id, ' amount: ', office.amount)
                //     var amount = parseFloat(office.amount);
                //     var vat = amount;
                //     if (element.vatStatus) {
                //       vat = (amount * 0.8);
                //     }
                //     var data = {
                //       "Case Id": element.Id, "FileNumber": element.fileNumber,
                //       "ClientName": element.clientName,
                //       "paymentIn": office.entryDate,
                //       "Branch": office.bankAccount,
                //       "AmountIn": amount,
                //       "VAT": vat
                //     }
                //     self.thisMonthBalance.push(data);

                //   }
                //   else if (last_Month === current_Month && latest_date.getFullYear() === date.getFullYear()) {
                //     self.manchesterPrev += parseFloat(office.amount);
                //     console.log('Prev Month Sale ', element.Id, ' amount: ', office.amount)
                //     var amount = parseFloat(office.amount);
                //     var vat = amount;
                //     if (element.vatStatus) {
                //       vat = (amount * 0.8);
                //     }
                //     var data = {
                //       "Case Id": element.Id, "FileNumber": element.fileNumber,
                //       "ClientName": element.clientName,
                //       "paymentIn": office.entryDate,
                //       "Branch": office.bankAccount,
                //       "AmountIn": amount,
                //       "VAT": vat
                //     }
                //     self.prevMonthBalance.push(data);
                //   }
                //   if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0 || element.caseStatus == 1)) {
                //     self.manchesterOutstanding += parseFloat(office.amount);
                //     var amount = parseFloat(office.amount);
                //     var vat = amount;
                //     if (element.vatStatus) {
                //       vat = (amount * 0.8);
                //     }
                //     var data = {
                //       "Case Id": element.Id, "FileNumber": element.fileNumber,
                //       "ClientName": element.clientName,
                //       "paymentIn": office.entryDate,
                //       "Branch": office.bankAccount,
                //       "AmountIn": amount,
                //       "VAT": vat
                //     }
                //     self.OutStandingBalance.push(data);
                //   }
                // }
                //  else if (office.bankAccount === "Chester" && self.branchId == "Chester" && office.typeOfPayment === "Legal FEE") {
                //   if (last_Month === current_Month + 1 && latest_date.getFullYear() === date.getFullYear()) {
                //     self.chesterCurrent += parseFloat(office.amount); var amount = parseFloat(office.amount);
                //     console.log('OutStanding Balance ', element.Id, ' amount: ', office.amount)
                //     var vat = amount;
                //     if (element.vatStatus) {
                //       vat = (amount * 0.8);
                //     }
                //     var data = {
                //       "Case Id": element.Id, "FileNumber": element.fileNumber,
                //       "ClientName": element.clientName,
                //       "paymentIn": office.entryDate,
                //       "Branch": office.bankAccount,
                //       "AmountIn": amount,
                //       "VAT": vat
                //     }
                //     self.thisMonthBalance.push(data);

                //   }
                //   else if (last_Month === current_Month && latest_date.getFullYear() === date.getFullYear()) {
                //     self.chesterPrev += parseFloat(office.amount); var amount = parseFloat(office.amount);
                //     var vat = amount;
                //     if (element.vatStatus) {
                //       vat = (amount * 0.8);
                //     }
                //     var data = {
                //       "Case Id": element.Id, "FileNumber": element.fileNumber,
                //       "ClientName": element.clientName,
                //       "paymentIn": office.entryDate,
                //       "Branch": office.bankAccount,
                //       "AmountIn": amount,
                //       "VAT": vat
                //     }
                //     self.prevMonthBalance.push(data);
                //   }
                //   if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0 || element.caseStatus == 1)) {
                //     self.chesterOutstanding += parseFloat(office.amount);



                //     var amount = parseFloat(office.amount);
                //     var vat = amount;
                //     if (element.vatStatus) {
                //       vat = (amount * 0.8);
                //     }
                //     var data = {
                //       "Case Id": element.Id, "FileNumber": element.fileNumber,
                //       "ClientName": element.clientName,
                //       "paymentIn": office.entryDate,
                //       "Branch": office.bankAccount,
                //       "AmountIn": amount,
                //       "VAT": vat
                //     }
                //     self.OutStandingBalance.push(data);
                //   }
                // }
                // else if (office.bankAccount === "London" && self.branchId == "London" && office.typeOfPayment === "Legal FEE") {
                //   if (last_Month === current_Month + 1 && latest_date.getFullYear() === date.getFullYear()) {
                //     self.londonCurrent += parseFloat(office.amount); var amount = parseFloat(office.amount);
                //     var vat = amount;
                //     if (element.vatStatus) {
                //       vat = (amount * 0.8);
                //     }
                //     var data = {
                //       "Case Id": element.Id, "FileNumber": element.fileNumber,
                //       "ClientName": element.clientName,
                //       "paymentIn": office.entryDate,
                //       "Branch": office.bankAccount,
                //       "AmountIn": amount,
                //       "VAT": vat
                //     }
                //     self.thisMonthBalance.push(data);
                //   }
                //   else if (last_Month === current_Month && latest_date.getFullYear() === date.getFullYear()) {
                //     self.londonPrev += parseFloat(office.amount); var amount = parseFloat(office.amount);
                //     var vat = amount;
                //     if (element.vatStatus) {
                //       vat = (amount * 0.8);
                //     }
                //     var data = {
                //       "Case Id": element.Id, "FileNumber": element.fileNumber,
                //       "ClientName": element.clientName,
                //       "paymentIn": office.entryDate,
                //       "Branch": office.bankAccount,
                //       "AmountIn": amount,
                //       "VAT": vat
                //     }
                //     self.prevMonthBalance.push(data);
                //   }
                //   if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0 || element.caseStatus == 1)) {
                //     self.londonOutstanding += parseFloat(office.amount);


                //     var amount = parseFloat(office.amount);
                //     var vat = amount;
                //     if (element.vatStatus) {
                //       vat = (amount * 0.8);
                //     }
                //     var data = {
                //       "Case Id": element.Id, "FileNumber": element.fileNumber,
                //       "ClientName": element.clientName,
                //       "paymentIn": office.entryDate,
                //       "Branch": office.bankAccount,
                //       "AmountIn": amount,
                //       "VAT": vat
                //     }
                //     self.OutStandingBalance.push(data);
                //   }
                // }
                // else if (office.bankAccount === "Birmingham" && self.branchId == "Birmingham" && office.typeOfPayment === "Legal FEE") {
                //   if (last_Month === current_Month + 1 && latest_date.getFullYear() === date.getFullYear()) {
                //     self.birminghamCurrent += parseFloat(office.amount);
                //     var amount = parseFloat(office.amount);
                //     var vat = amount;
                //     if (element.vatStatus) {
                //       vat = (amount * 0.8);
                //     }
                //     var data = {
                //       "Case Id": element.Id, "FileNumber": element.fileNumber,
                //       "ClientName": element.clientName,
                //       "paymentIn": office.entryDate,
                //       "Branch": office.bankAccount,
                //       "AmountIn": amount,
                //       "VAT": vat
                //     }
                //     self.thisMonthBalance.push(data);
                //   } else if (last_Month === current_Month && latest_date.getFullYear() === date.getFullYear()) {
                //     self.birminghamPrev += parseFloat(office.amount); var amount = parseFloat(office.amount);
                //     var vat = amount;
                //     if (element.vatStatus) {
                //       vat = (amount * 0.8);
                //     }
                //     var data = {
                //       "Case Id": element.Id, "FileNumber": element.fileNumber,
                //       "ClientName": element.clientName,
                //       "paymentIn": office.entryDate,
                //       "Branch": office.bankAccount,
                //       "AmountIn": amount,
                //       "VAT": vat
                //     }
                //     self.prevMonthBalance.push(data);
                //   }
                //   if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0 || element.caseStatus == 1)) {
                //     self.birminghamOutstanding += parseFloat(office.amount);

                //     var amount = parseFloat(office.amount);
                //     var vat = amount;
                //     if (element.vatStatus) {
                //       vat = (amount * 0.8);
                //     }
                //     var data = {
                //       "Case Id": element.Id, "FileNumber": element.fileNumber,
                //       "ClientName": element.clientName,
                //       "paymentIn": office.entryDate,
                //       "Branch": office.bankAccount,
                //       "AmountIn": amount,
                //       "VAT": vat
                //     }
                //     self.OutStandingBalance.push(data);
                //   }
                // }
                if (office.bankAccount == self.branchId) {
                  if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 1) && !Acheck) {
                    self.AwaitingBalance += parseFloat(office.amount);
                  }
                  if (office.typeOfPayment === "Disbursement") {
                    if(office.isInPayment){
                      self.disIn += parseFloat(office.amount);
                      disInCheck= true;
                      disInTotal+=parseFloat(office.amount);
                    }
                    else{
                      self.disOut +=parseFloat(office.amount);
                      disOutCheck= true;
                      disOutTotal+=parseFloat(office.amount);
                    }

                  }
                  // if (office.typeOfPayment == "Legal FEE" && !Acheck) {
                  //   // self.disOut +=parseFloat(office.amount);
                  //   account += parseFloat(office.amount);
                  //   if (r['officeAccount'].length - 1 === i) {
                  //     var total = parseFloat(r.amount) - account;
                  //     self.pendingBalance += total;
                  //   }
                  // }
                }
                else if (self.branchId === "All") {
                  // if (office.bankAccount === "Manchester" && office.typeOfPayment === "Legal FEE") {
                  //   if (last_Month === current_Month + 1 && latest_date.getFullYear() === date.getFullYear()) {
                  //     self.manchesterCurrent += parseFloat(office.amount);
                  //     var amount = parseFloat(office.amount);
                  //     var vat = amount;
                  //     if (element.vatStatus) {
                  //       vat = (amount * 0.8);
                  //     }
                  //     var data = {
                  //       "Case Id": element.Id, "FileNumber": element.fileNumber,
                  //       "ClientName": element.clientName,
                  //       "paymentIn": office.entryDate,
                  //       "Branch": office.bankAccount,
                  //       "AmountIn": amount,
                  //       "VAT": vat
                  //     }
                  //     self.thisMonthBalance.push(data);
                  //   }
                  //   else if (last_Month === current_Month && latest_date.getFullYear() === date.getFullYear()) {
                  //     self.manchesterPrev += parseFloat(office.amount);
                  //     var amount = parseFloat(office.amount);
                  //     var vat = amount;
                  //     if (element.vatStatus) {
                  //       vat = (amount * 0.8);
                  //     }
                  //     var data = {
                  //       "Case Id": element.Id, "FileNumber": element.fileNumber,
                  //       "ClientName": element.clientName,
                  //       "paymentIn": office.entryDate,
                  //       "Branch": office.bankAccount,
                  //       "AmountIn": amount,
                  //       "VAT": vat
                  //     }
                  //     self.prevMonthBalance.push(data);
                  //   }
                  //   if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0 || element.caseStatus == 1)) {
                  //     self.manchesterOutstanding += parseFloat(office.amount);
                  //     var amount = parseFloat(office.amount);
                  //     var vat = amount;
                  //     if (element.vatStatus) {
                  //       vat = (amount * 0.8);
                  //     }
                  //     var data = {
                  //       "Case Id": element.Id, "FileNumber": element.fileNumber,
                  //       "ClientName": element.clientName,
                  //       "paymentIn": office.entryDate,
                  //       "Branch": office.bankAccount,
                  //       "AmountIn": amount,
                  //       "VAT": vat
                  //     }
                  //     self.OutStandingBalance.push(data);
                  //   }
                  // } else if (office.bankAccount === "Chester" && office.typeOfPayment === "Legal FEE") {
                  //   if (last_Month === current_Month + 1 && latest_date.getFullYear() === date.getFullYear()) {
                  //     self.chesterCurrent += parseFloat(office.amount);
                  //     var amount = parseFloat(office.amount);
                  //     var vat = amount;
                  //     if (element.vatStatus) {
                  //       vat = (amount * 0.8);
                  //     }
                  //     var data = {
                  //       "Case Id": element.Id, "FileNumber": element.fileNumber,
                  //       "ClientName": element.clientName,
                  //       "paymentIn": office.entryDate,
                  //       "Branch": office.bankAccount,
                  //       "AmountIn": amount,
                  //       "VAT": vat
                  //     }
                  //     self.thisMonthBalance.push(data);
                  //   }
                  //   else if (last_Month === current_Month && latest_date.getFullYear() === date.getFullYear()) {
                  //     self.chesterPrev += parseFloat(office.amount);
                  //     var amount = parseFloat(office.amount);
                  //     var vat = amount;
                  //     if (element.vatStatus) {
                  //       vat = (amount * 0.8);
                  //     }
                  //     var data = {
                  //       "Case Id": element.Id, "FileNumber": element.fileNumber,
                  //       "ClientName": element.clientName,
                  //       "paymentIn": office.entryDate,
                  //       "Branch": office.bankAccount,
                  //       "AmountIn": amount,
                  //       "VAT": vat
                  //     }
                  //     self.prevMonthBalance.push(data);
                  //   }
                  //   if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0 || element.caseStatus == 1)) {
                  //     self.chesterOutstanding += parseFloat(office.amount);
                  //     var amount = parseFloat(office.amount);
                  //     var vat = amount;
                  //     if (element.vatStatus) {
                  //       vat = (amount * 0.8);
                  //     }
                  //     var data = {
                  //       "Case Id": element.Id, "FileNumber": element.fileNumber,
                  //       "ClientName": element.clientName,
                  //       "paymentIn": office.entryDate,
                  //       "Branch": office.bankAccount,
                  //       "AmountIn": amount,
                  //       "VAT": vat
                  //     }
                  //     self.OutStandingBalance.push(data);
                  //   }
                  // }
                  // else if (office.bankAccount === "London" && office.typeOfPayment === "Legal FEE") {
                  //   if (last_Month === current_Month + 1 && latest_date.getFullYear() === date.getFullYear()) {
                  //     self.londonCurrent += parseFloat(office.amount);
                  //     var amount = parseFloat(office.amount);
                  //     var vat = amount;
                  //     if (element.vatStatus) {
                  //       vat = (amount * 0.8);
                  //     }
                  //     var data = {
                  //       "Case Id": element.Id, "FileNumber": element.fileNumber,
                  //       "ClientName": element.clientName,
                  //       "paymentIn": office.entryDate,
                  //       "Branch": office.bankAccount,
                  //       "AmountIn": amount,
                  //       "VAT": vat
                  //     }
                  //     self.thisMonthBalance.push(data);
                  //   }
                  //   else if (last_Month === current_Month && latest_date.getFullYear() === date.getFullYear()) {
                  //     self.londonPrev += parseFloat(office.amount);
                  //     var amount = parseFloat(office.amount);
                  //     var vat = amount;
                  //     if (element.vatStatus) {
                  //       vat = (amount * 0.8);
                  //     }
                  //     var data = {
                  //       "Case Id": element.Id, "FileNumber": element.fileNumber,
                  //       "ClientName": element.clientName,
                  //       "paymentIn": office.entryDate,
                  //       "Branch": office.bankAccount,
                  //       "AmountIn": amount,
                  //       "VAT": vat
                  //     }
                  //     self.prevMonthBalance.push(data);
                  //   }
                  //   if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0 || element.caseStatus == 1)) {
                  //     self.londonOutstanding += parseFloat(office.amount);
                  //     var amount = parseFloat(office.amount);
                  //     var vat = amount;
                  //     if (element.vatStatus) {
                  //       vat = (amount * 0.8);
                  //     }
                  //     var data = {
                  //       "Case Id": element.Id, "FileNumber": element.fileNumber,
                  //       "ClientName": element.clientName,
                  //       "paymentIn": office.entryDate,
                  //       "Branch": office.bankAccount,
                  //       "AmountIn": amount,
                  //       "VAT": vat
                  //     }
                  //     self.OutStandingBalance.push(data);
                  //   }
                  // }
                  // else if (office.bankAccount === "Birmingham" && office.typeOfPayment === "Legal FEE") {
                  //   if (last_Month === current_Month + 1 && latest_date.getFullYear() === date.getFullYear()) {
                  //     self.birminghamCurrent += parseFloat(office.amount);
                  //     var amount = parseFloat(office.amount);
                  //     var vat = amount;
                  //     if (element.vatStatus) {
                  //       vat = (amount * 0.8);
                  //     }
                  //     var data = {
                  //       "Case Id": element.Id, "FileNumber": element.fileNumber,
                  //       "ClientName": element.clientName,
                  //       "paymentIn": office.entryDate,
                  //       "Branch": office.bankAccount,
                  //       "AmountIn": amount,
                  //       "VAT": vat
                  //     }
                  //     self.thisMonthBalance.push(data);
                  //   } else if (last_Month === current_Month && latest_date.getFullYear() === date.getFullYear()) {
                  //     self.birminghamPrev += parseFloat(office.amount);
                  //     var amount = parseFloat(office.amount);
                  //     var vat = amount;
                  //     if (element.vatStatus) {
                  //       vat = (amount * 0.8);
                  //     }
                  //     var data = {
                  //       "Case Id": element.Id, "FileNumber": element.fileNumber,
                  //       "ClientName": element.clientName,
                  //       "paymentIn": office.entryDate,
                  //       "Branch": office.bankAccount,
                  //       "AmountIn": amount,
                  //       "VAT": vat
                  //     }
                  //     self.prevMonthBalance.push(data);
                  //   }
                  //   if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0 || element.caseStatus == 1)) {
                  //     self.birminghamOutstanding += parseFloat(office.amount);
                  //     var amount = parseFloat(office.amount);
                  //     var vat = amount;
                  //     if (element.vatStatus) {
                  //       vat = (amount * 0.8);
                  //     }
                  //     var data = {
                  //       "Case Id": element.Id, "FileNumber": element.fileNumber,
                  //       "ClientName": element.clientName,
                  //       "paymentIn": office.entryDate,
                  //       "Branch": office.bankAccount,
                  //       "AmountIn": amount,
                  //       "VAT": vat
                  //     }
                  //     self.OutStandingBalance.push(data);
                  //   }
                  // }
                  // if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0 || element.caseStatus == 1) && !Acheck) {
                  //   self.outStandingBalance += parseFloat(office.amount);
                  // }
                  // if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0) && !Acheck) {
                  //   self.ActiveBalance += parseFloat(office.amount);
                  // }
                  if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 1) && !Acheck) {
                    self.AwaitingBalance += parseFloat(office.amount);
                  }
                  if (office.typeOfPayment === "Disbursement") {
                    if(office.isInPayment){
                      self.disIn += parseFloat(office.amount);
                      disInCheck= true;
                      disInTotal+=parseFloat(office.amount);
                    }
                    else{
                      self.disOut +=parseFloat(office.amount);
                      disOutCheck= true;
                      disOutTotal+=parseFloat(office.amount);
                    }


                  }
                  // if (office.typeOfPayment == "Legal FEE" && !Acheck) {
                  //   // self.disOut +=parseFloat(office.amount);
                  //   account += parseFloat(office.amount);
                  //   if (r['officeAccount'].length - 1 === i) {
                  //     var total = parseFloat(r.amount) - account;
                  //     self.pendingBalance += total;

                  //   }
                  // }

                }
              }
              else {
                if (office.typeOfPayment === "Disbursement") {
                  self.disOut += parseFloat(office.amount);
                  disOutCheck= true;
                  disOutTotal+=parseFloat(office.amount);
                }

                // if (office.typeOfPayment == "Legal FEE") {
                //   // self.disOut +=parseFloat(office.amount);
                //   account += parseFloat(office.amount);
                //   if (r['officeAccount'].length - 1 === i) {
                //     var total = parseFloat(r.amount) - account;
                //     self.pendingBalance += total;
                //   }
                // }
              }


              i += 1;
            });



            // if (r['officeAccount'].length == 0) {
            //   if (self.branchId === "All") {
            //     self.pendingBalance += parseFloat(r.amount);
            //   }
            //   else if (element.personResponsilbeBranchName === self.branchId) {
            //     self.pendingBalance += parseFloat(r.amount);
            //   }
            // }

          });
          if(outStandCheck){

            if(outBalance>0){
              element['outBalance']= outBalance;
              self.OutStandingFiles.push(element);
            }



          }

          if(activeBalanceCheck){
            if(activeBalance>0){
              element['activeBalance']=activeBalance;
              self.ActiveBalanceFiles.push(element);
            }

          }
          element['clientAccount'].forEach(function (client) {

            if(client.bankAccount == self.branchId){
              if(client.typeOfPayment === "Disbursement"){
                if(client.isInPayment){
                  self.disIn += parseFloat(client.amount);
                  disInCheck= true;
                      disInTotal+=parseFloat(client.amount);
                }
                else{
                  self.disOut += parseFloat(client.amount);
                  disOutCheck= true;
                  disOutTotal+=parseFloat(client.amount);
                }
              }
            }
            else if(self.branchId === "All"){
              if(client.typeOfPayment === "Disbursement"){
                if(client.isInPayment){
                  self.disIn += parseFloat(client.amount);
                  disInCheck= true;
                  disInTotal+=parseFloat(client.amount);
                }
                else{
                  self.disOut += parseFloat(client.amount);
                  disOutCheck= true;
                  disOutTotal+=parseFloat(client.amount);
                }
              }
            }
            // if (client.isInPayment) {
            //   if (client.bankAccount == self.branchId) {
            //     if (client.typeOfPayment === "Disbursement") {
            //       self.disIn += parseFloat(client.amount);
            //     } else {
            //       self.disOut += parseFloat(client.amount);
            //     }
            //   }
            //   else if (self.branchId === "All") {
            //     if (client.typeOfPayment === "Disbursement") {
            //       self.disIn += parseFloat(client.amount);
            //     } else {
            //       self.disOut += parseFloat(client.amount);
            //     }
            //   }
            // }
            // else {
            //   if (client.typeOfPayment === "Disbursement") {
            //     self.disOut += parseFloat(client.amount);
            //   }
            // }

          });
          // if(element['outBalance']!=0){
          //   let userId =[]
          //   userId  = element.caseWorkerList.map(x=>x.userId_id);
          //   let uniqDic =[...new Set(userId)];
          //   uniqDic.forEach(el => {
          //     if (pendingPaymentsByUsers.hasOwnProperty(el)) {
          //       pendingPaymentsByUsers[el].value += parseFloat(element['outBalance']);
          //       // element['pendingBalance'] += parseFloat(self.pendingBalance);
          //       pendingPaymentsByUsers[el].files.push(element);
          //       // console.log(self.pendingBalance, pendingPaymentsByUsers[element1.userId_id].value,pendingPaymentsByUsers[element1.userId_id].Name)
          //     }
          //   });
          // }

          // element.caseWorkerList.forEach(element1 => {
          //   if (pendingPaymentsByUsers.hasOwnProperty(element1.userId_id)) {
          //     pendingPaymentsByUsers[element1.userId_id].value += parseFloat(self.pendingBalance);
          //     element['pendingBalance'] += parseFloat(self.pendingBalance);
          //     pendingPaymentsByUsers[element1.userId_id].files.push(element);
          //     // console.log(self.pendingBalance, pendingPaymentsByUsers[element1.userId_id].value,pendingPaymentsByUsers[element1.userId_id].Name)
          //   }
          // });
            if(disOutCheck){
              if(disOutTotal>0){
                element['disOutTotal']=disOutTotal;
                self.disOutFiles.push(element);
              }
            }
            if(disInCheck){
              if(disInTotal>0){
                element['disInTotal']=disInTotal;
                self.disInFiles.push(element);
              }
            }

        }
         else {
          let disInCheck = false;
          let disInTotal =0;
          let disOutCheck = false;
          let disOutTotal =0;

          // let deadline = new Date(element.deadLine);
          // let current_date = new Date();
          // if(current_date>deadline && element.caseStatus===0){
          //   self.lateFiles+=1;
          // }

          let deadline = new Date(element.deadLine);
          let current_date = new Date();
          var caseworkerIds = element.caseWorkerList.map(x => { return x.userId_id });
          // console.log(caseworkerIds,"CaseWorkerIds")
          var workersIndex = caseworkerIds.indexOf(self.user.Id) > -1;
          var p = self.checkPermission('Dashboard', 'level', self.branchId);
          var pcheck = false;
          var arr = [5, 6];
          p.forEach(function (el) {
            if (arr.indexOf(parseInt(el)) > -1) {
              pcheck = true;
              return;
            }
          });

          element.caseWorkerList.forEach(element1 => {
            if (element1.branchName === self.branchId || self.branchId === "All") {
              // var keys = Object.keys(LateFiles)
              // var index = keys.indexOf(element1.userId_id.toString()) > -1

              // if (index) {
              //   // LateFiles[element1.userId_id].Files += 1;
              // } else {
              //   if (caseIds.indexOf(element1.caseId_id) < 0) {
              //     LateFiles[element1.userId_id] = { Files: 0, Name: element1.CaseWorkerName };
              //     caseIds.push(element1.caseId_id)
              //   }
              // }
              // if(!pendingPaymentsByUsers.hasOwnProperty(element1.userId_id)){
              //   pendingPaymentsByUsers[element1.userId_id] = { value: 0, Name: element1.CaseWorkerName , files:[] };

              //   }
              if (current_date < deadline && element.caseStatus === 0 && workersIndex && (pcheck)) {
                this.Deadlines.push({"Id":element.Id, "Name": element1.CaseWorkerName,"profilePic":element1.profilePic, "Date": deadline, "ClientName": element.clientName, "MatterName": element.matterName, 'FileNumber': element.fileNumber });

              }
            }
          });

          // if(element['outBalance']!=0){
          //   let userId =[]
          //   userId  = element.caseWorkerList.map(x=>x.userId_id);
          //   let uniqDic =[...new Set(userId)];
          //   uniqDic.forEach(el => {
          //     if (pendingPaymentsByUsers.hasOwnProperty(el)) {
          //       pendingPaymentsByUsers[el].value += parseFloat(element['outBalance']);
          //       // element['pendingBalance'] += parseFloat(self.pendingBalance);
          //       pendingPaymentsByUsers[el].files.push(element);
          //       // console.log(self.pendingBalance, pendingPaymentsByUsers[element1.userId_id].value,pendingPaymentsByUsers[element1.userId_id].Name)
          //     }
          //   });
          // }

          if (workersIndex && (pcheck)) {
            if (self.branchId === element.personResponsilbeBranchName) {
              var index = matterIds.indexOf(element.matterId);
              if (index > -1) {
                if (element.caseStatus != 3) {
                  matterList[element.matterId].value += 1;
                  matterList[element.matterId].name = element.matterName;
                  matterList[element.matterId].files.push(element)

                }

              }

              if (element.caseStatus === 0) {
                // self.lateFiles += 1;
                // var caseWorkers = element.caseWorkerList.map(x => x.userId_id)
                // var wIndex = 0
                // console.log(caseIds);
                // caseWorkers.forEach(item => {
                //   var keys = Object.keys(LateFiles)
                //   var index = keys.indexOf(item.toString()) > -1


                //   if (caseWorkersId.indexOf(item) < 0) {
                //     if (index) {
                //       LateFiles[item].Files += 1;
                //     }
                //     // else {
                //     //   if (current_date > deadline) {
                //     //     LateFiles[item] = { Files: 1, Name: element.caseWorkerList[wIndex].CaseWorkerName };

                //     //   }
                //     //   //  else {
                //     //   // LateFiles[item] = { Files: 1, Name: element.caseWorkerList[wIndex].CaseWorkerName };
                //     //   // }
                //     // }
                //     caseWorkersId.push(item)



                //   }
                //   console.log(LateFiles, "lateFiles")
                //   wIndex += 1
                // });
                self.ActiveFiles.push(element);
                self.ActiveCases += 1;
                // if ((element.personResposibleId == self.user.Id) || (self.user.RoleId == 1) || (pcheck)) {
                //   this.lateTodo.push({ "Id": element.Id, "Text": element.matterName, "ItemId": element.fileNumber, "type": 1, "Date": element.deadLine });
                // }
                // this.lateCases.push(element);
              }
              // if (current_date < deadline && element.caseStatus == 0) {
              //   self.ActiveCases += 1;
              //   self.ActiveFiles.push(element);
              //   if ((element.personResposibleId == self.user.Id) || (self.user.RoleId == 1) || (pcheck)) {
              //     this.CurrentTodo.push({ "Id": element.Id, "Text": element.matterName, "ItemId": element.fileNumber, "type": 1, "Date": element.deadLine });
              //   }
              // }
              else if (element.caseStatus == 1) {
                // if (current_date < deadline) {
                //   if ((element.personResposibleId == self.user.Id) || (self.user.RoleId == 1) || (pcheck)) {
                //     this.CurrentTodo.push({ "Id": element.Id, "Text": element.matterName, "ItemId": element.fileNumber, "type": 1, "Date": element.deadLine });
                //   }
                // }
                self.AwaitingCases += 1;

                self.AwaitingFiles.push(element);

              }
              else if (element.caseStatus == 2) {
                // if (current_date < deadline) {
                //   if ((element.personResposibleId == self.user.Id) || (self.user.RoleId == 1) || (pcheck)) {
                //     this.CurrentTodo.push({ "Id": element.Id, "Text": element.matterName, "ItemId": element.fileNumber, "type": 1, "Date": element.deadLine });
                //   }
                // }
                self.inCompletedCases += 1;
                self.awaitingCompletion.push(element);

              }
              // element.caseWorkerList.forEach(element1 => {
              //   if (element1.status === 1) {
              //     self.pendingApprovals += 1;
              //     self.PendingApprovalFiles.push(element);
              //     return;
              //   }
              // });
            }
            else if (self.branchId === "All") {
              var index = matterIds.indexOf(element.matterId);
              if (index > -1) {
                if (element.caseStatus != 3) {
                  matterList[element.matterId].value += 1;
                  matterList[element.matterId].name = element.matterName;
                  matterList[element.matterId].files.push(element)

                }

              }

              if (element.caseStatus === 0) {
                // self.lateFiles += 1;
                // var caseWorkers = element.caseWorkerList.map(x => x.userId_id)
                // var wIndex = 0
                // console.log(caseIds);
                // caseWorkers.forEach(item => {
                //   var keys = Object.keys(LateFiles)
                //   var index = keys.indexOf(item.toString()) > -1


                //   if (caseWorkersId.indexOf(item) < 0) {
                //     if (index) {
                //       LateFiles[item].Files += 1;
                //     }
                //     // else {
                //     //   if (current_date > deadline) {
                //     //     LateFiles[item] = { Files: 1, Name: element.caseWorkerList[wIndex].CaseWorkerName };

                //     //   }
                //     //   //  else {
                //     //   // LateFiles[item] = { Files: 1, Name: element.caseWorkerList[wIndex].CaseWorkerName };
                //     //   // }
                //     // }
                //     caseWorkersId.push(item)



                //   }
                //   console.log(LateFiles, "lateFiles")
                //   wIndex += 1
                // });
                self.ActiveFiles.push(element);
                self.ActiveCases += 1;
                // this.lateTodo.push({ "Id": element.Id, "Text": element.matterName, "ItemId": element.fileNumber, "type": 1, "Date": element.deadLine });
                // this.lateCases.push(element);
              }
              // if (current_date < deadline && element.caseStatus == 0) {
              //   self.ActiveCases += 1;
              //   this.CurrentTodo.push({ "Id": element.Id, "Text": element.matterName, "ItemId": element.fileNumber, "type": 1, "Date": element.deadLine });
              //   self.ActiveFiles.push(element);
              // }
              else if (element.caseStatus == 1) {
                // if (current_date < deadline) {
                //   this.CurrentTodo.push({ "Id": element.Id, "Text": element.matterName, "ItemId": element.fileNumber, "type": 1, "Date": element.deadLine });

                // }
                self.AwaitingCases += 1;
                self.AwaitingFiles.push(element);
              }
              else if (element.caseStatus == 2) {
                // if (current_date < deadline) {
                //   this.CurrentTodo.push({ "Id": element.Id, "Text": element.matterName, "ItemId": element.fileNumber, "type": 1, "Date": element.deadLine });

                // }
                self.inCompletedCases += 1;
                self.awaitingCompletion.push(element);
              }
              // element.caseWorkerList.forEach(element1 => {
              //   if (element1.status === 1) {
              //     self.pendingApprovals += 1;
              //     self.PendingApprovalFiles.push(element);
              //     return;
              //   }
              // });
            }
            let invoiceCheck= false;
          let invoiceBal = 0;
            element['caseInvoice'].forEach(invoice => {
              if (self.branchId === "All") {
                if(invoice.isDraft && invoice.amountPaid){
                  self.agreedCost += parseFloat(invoice.amountPaid);
                  invoiceBal+= parseFloat(invoice.amountPaid);
                }
                // self.agreedCost += parseFloat(invoice.agreedCost);
              } else {
                if (self.branchId === element.personResponsilbeBranchName) {
                  if(invoice.isDraft && invoice.amountPaid){
                    self.agreedCost += parseFloat(invoice.amountPaid);
                    invoiceBal+= parseFloat(invoice.amountPaid);
                  }
                  // self.agreedCost += parseFloat(invoice.agreedCost);
                }
              }
            });
            if(invoiceCheck){
              if(invoiceBal>0){
                element['invoiceBal']=invoiceBal;
              self.draftBalanceFiles.push(element);

              }
            }
            let outStandCheck = false;
            let activeBalanceCheck = false;
            let outBalance=0;
            let activeBalance = 0;
            element['officeInvoice'].forEach(function (r) {
              var i = 0;
              let totalAmount = Number(r['amount'])
              let balance = totalAmount;
             r['officeAccount'].forEach(element1 => {
               if (element1['isInPayment'] && !element1['isDeleted']) {
                 let amount = Number(element1['amount'])
                 balance = balance - amount;

               }
             });
             r['balance'] = balance;
             if(r['bankAccount']==self.branchId){
               if (r.typeOfPayment === "Legal FEE" && (element.caseStatus == 0 || element.caseStatus == 1) && !Acheck) {
                 self.outStandingBalance += parseFloat(r['balance']);
                 outBalance+= parseFloat(r['balance']);
                 outStandCheck= true;

               }
               if (r.typeOfPayment === "Legal FEE" && (element.caseStatus == 0) && !Acheck) {
                 self.ActiveBalance += parseFloat(r['balance']);
                 activeBalance+= parseFloat(r['balance']);
                 activeBalanceCheck= true;
               }
             }
             else if(self.branchId === "All"){
               if (r.typeOfPayment === "Legal FEE" && (element.caseStatus == 0 || element.caseStatus == 1) && !Acheck) {
                 self.outStandingBalance += parseFloat(r['balance']);
                 outBalance+= parseFloat(r['balance']);

                 outStandCheck = true;
               }
               if (r.typeOfPayment === "Legal FEE" && (element.caseStatus == 0) && !Acheck) {
                 self.ActiveBalance += parseFloat(r['balance']);
                 activeBalance+= parseFloat(r['balance']);

                 activeBalanceCheck= true;
               }
             }
              r['officeAccount'].forEach(office => {
                var account = 0;
                if (office.isInPayment) {
                  // var current_Month = (date.getMonth() - 1);
                  // var last_Month = latest_date.getMonth();
                  // if (office.bankAccount === "Manchester" && office.typeOfPayment === "Legal FEE") {
                  //   if (last_Month === current_Month + 1 && latest_date.getFullYear() === date.getFullYear()) {
                  //     self.manchesterCurrent += parseFloat(office.amount);
                  //   }
                  //   else if (last_Month === current_Month && latest_date.getFullYear() === date.getFullYear()) {
                  //     self.manchesterPrev += parseFloat(office.amount);
                  //   }
                  //   if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0 || element.caseStatus == 1)) {
                  //     self.manchesterOutstanding += parseFloat(office.amount);
                  //   }
                  // } else if (office.bankAccount === "Chester" && office.typeOfPayment === "Legal FEE") {
                  //   if (last_Month === current_Month + 1 && latest_date.getFullYear() === date.getFullYear()) {
                  //     self.chesterCurrent += parseFloat(office.amount);
                  //   }
                  //   else if (last_Month === current_Month && latest_date.getFullYear() === date.getFullYear()) {
                  //     self.chesterPrev += parseFloat(office.amount);
                  //   }
                  //   if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0 || element.caseStatus == 1)) {
                  //     self.chesterOutstanding += parseFloat(office.amount);
                  //   }
                  // }
                  // else if (office.bankAccount === "London" && office.typeOfPayment === "Legal FEE") {
                  //   if (last_Month === current_Month + 1 && latest_date.getFullYear() === date.getFullYear()) {
                  //     self.londonCurrent += parseFloat(office.amount);
                  //   }
                  //   else if (last_Month === current_Month && latest_date.getFullYear() === date.getFullYear()) {
                  //     self.londonPrev += parseFloat(office.amount);
                  //   }
                  //   if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0 || element.caseStatus == 1)) {
                  //     self.londonOutstanding += parseFloat(office.amount);
                  //   }
                  // }
                  // else if (office.bankAccount === "Birmingham" && office.typeOfPayment === "Legal FEE") {
                  //   if (last_Month === current_Month + 1 && latest_date.getFullYear() === date.getFullYear()) {
                  //     self.birminghamCurrent += parseFloat(office.amount);
                  //   } else if (last_Month === current_Month && latest_date.getFullYear() === date.getFullYear()) {
                  //     self.birminghamPrev += parseFloat(office.amount);
                  //   }
                  //   if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0 || element.caseStatus == 1)) {
                  //     self.birminghamOutstanding += parseFloat(office.amount);
                  //   }
                  // }
                  if (office.bankAccount == self.branchId) {
                    // if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0 || element.caseStatus == 1) && !Acheck) {
                    //   self.outStandingBalance += parseFloat(office.amount);
                    // }
                    // if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0) && !Acheck) {
                    //   self.ActiveBalance += parseFloat(office.amount);
                    // }
                    if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 1) && !Acheck) {
                      self.AwaitingBalance += parseFloat(office.amount);
                    } if (office.typeOfPayment === "Disbursement") {
                      if(office.isInPayment){
                        self.disIn += parseFloat(office.amount);
                        disInCheck=true;
                        disInTotal+=parseFloat(office.amount);
                      }
                      else{
                        self.disOut +=parseFloat(office.amount);
                        disOutCheck=true;
                        disOutTotal+=parseFloat(office.amount);
                      }

                    } if (office.typeOfPayment == "Legal FEE") {
                      // self.disOut +=parseFloat(office.amount);
                      account += parseFloat(office.amount);
                      if (r['officeAccount'].length - 1 === i) {
                        var total = parseFloat(r.amount) - account;
                        self.pendingBalance += total;
                      }
                    }
                  } else if (self.branchId == "All") {
                    // if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0 || element.caseStatus == 1) && !Acheck) {
                    //   self.outStandingBalance += parseFloat(office.amount);
                    // }
                    // if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0) && !Acheck) {
                    //   self.ActiveBalance += parseFloat(office.amount);
                    // }
                    if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 1) && !Acheck) {
                      self.AwaitingBalance += parseFloat(office.amount);
                    }
                    if (office.typeOfPayment === "Disbursement") {
                      if(office.isInPayment){
                        self.disIn += parseFloat(office.amount);
                        disInCheck=true;
                        disInTotal+=parseFloat(office.amount);
                      }
                      else{
                        self.disOut += parseFloat(office.amount);
                        disOutCheck=true;
                        disOutTotal+=parseFloat(office.amount);
                      }

                    } else {
                      // self.disOut += parseFloat(office.amount);
                      account += parseFloat(office.amount);
                      if (r['officeAccount'].length - 1 === i) {
                        var total = parseFloat(r.amount) - account;
                        self.pendingBalance += total;
                      }
                    }
                  }
                } else {
                  if (office.typeOfPayment === "Disbursement") {
                    self.disOut += parseFloat(office.amount);
                    disOutCheck=true;
                        disOutTotal+=parseFloat(office.amount);
                  } if (office.typeOfPayment == "Legal FEE") {
                    // self.disOut +=parseFloat(office.amount);
                    account += parseFloat(office.amount);
                    if (r['officeAccount'].length - 1 === i) {
                      var total = parseFloat(r.amount) - account;
                      self.pendingBalance += total;
                    }
                  }
                }
                i += 1;
              });

              if (r['officeAccount'].length == 0) {
                if (self.branchId === "All") {
                  self.pendingBalance += parseFloat(r.amount);
                }
                else if (element.personResponsilbeBranchName === self.branchId) {
                  self.pendingBalance += parseFloat(r.amount);
                }
              }





            });
            if(outStandCheck){
              if(outBalance>0){
                element['outBalance']= outBalance;
              self.OutStandingFiles.push(element);
              }

            }
            if(activeBalanceCheck){
              if(activeBalance>0){
                element['activeBalance']=activeBalance;
              self.ActiveBalanceFiles.push(element);
              }
            }
            element['clientAccount'].forEach(function (client) {
              if (client.isInPayment) {
                if (client.bankAccount == self.branchId) {
                  if (client.typeOfPayment === "Disbursement") {
                    self.disIn += parseFloat(client.amount);
                    disInCheck=true;
                        disInTotal+=parseFloat(client.amount);
                  } else {
                    self.disOut += parseFloat(client.amount);
                    disOutCheck=true;
                        disOutTotal+=parseFloat(client.amount);
                  }
                }
                else if (self.branchId == "All") {
                  if (client.typeOfPayment === "Disbursement") {
                    self.disIn += parseFloat(client.amount);
                    disInCheck=true;
                        disInTotal+=parseFloat(client.amount);
                  } else {
                    self.disOut += parseFloat(client.amount);
                    disOutCheck=true;
                    disOutTotal+=parseFloat(client.amount);

                  }
                }
              }

            });
          }
          // element.caseWorkerList.forEach(element1 => {
          //   if (element1.userId_id == self.user.Id && self.checkPermission('Dashboard','level',self.branchId)===5 && self.checkPermission('Dashboard','level',self.branchId)===6) {

          //     if (self.branchId === element.personResponsilbeBranchName) {
          //       var index = matterIds.indexOf(element.matterId);
          //       if (index > -1) {
          //         if(element.caseStatus===0)
          //     {    matterList[element.matterId].value += 1;
          //         matterList[element.matterId].name = element.matterName;}

          //       }

          //       if (current_date > deadline && element.caseStatus === 0) {
          //         self.lateFiles += 1;
          //       }
          //       if (element.caseStatus == 0) {
          //         self.ActiveCases += 1;
          //       } else if (element.caseStatus == 1) {
          //         self.AwaitingCases += 1
          //       }
          //       else if (element.caseStatus == 2) {
          //         self.inCompletedCases += 1
          //       }
          //       element.caseWorkerList.forEach(element1 => {
          //         if (element1.status === 1) {
          //           self.pendingApprovals += 1;
          //           return;
          //         }
          //       });
          //     } else if (self.branchId === "All") {
          //       var index = matterIds.indexOf(element.matterId);
          //       if (index > -1) {
          //         if(element.caseStatus===0)
          //        { matterList[element.matterId].value += 1;
          //         matterList[element.matterId].name = element.matterName;
          //       }

          //       }

          //       if (current_date > deadline && element.caseStatus === 0) {
          //         self.lateFiles += 1;
          //       }
          //       if (element.caseStatus == 0) {
          //         self.ActiveCases += 1;
          //       } else if (element.caseStatus == 1) {
          //         self.AwaitingCases += 1
          //       }
          //       else if (element.caseStatus == 2) {
          //         self.inCompletedCases += 1
          //       }
          //       element.caseWorkerList.forEach(element1 => {
          //         if (element1.status === 1) {
          //           self.pendingApprovals += 1;
          //           return;
          //         }
          //       });
          //     }
          //     element['caseInvoice'].forEach(invoice => {
          //       if (self.branchId === "All") {
          //         self.agreedCost += parseFloat(invoice.agreedCost);
          //       } else {
          //         if (self.branchId === element.personResponsilbeBranchName) {
          //           self.agreedCost += parseFloat(invoice.agreedCost);
          //         }
          //       }
          //     });

          //     element['officeInvoice'].forEach(function (r) {
          //       var i = 0;
          //       r['officeAccount'].forEach(office => {
          //         var account = 0;
          //         if (office.isInPayment) {
          //           let latest_date = new Date(office.entryDate);
          //           let date = new Date();
          //           var current_Month = (date.getMonth() - 1);
          //           var last_Month = latest_date.getMonth();
          //           if (office.bankAccount === "Manchester" && office.typeOfPayment === "Legal FEE") {
          //             if (last_Month === current_Month + 1 && latest_date.getFullYear() === date.getFullYear()) {
          //               self.manchesterCurrent += parseFloat(office.amount);
          //             }
          //             else if (last_Month === current_Month && latest_date.getFullYear() === date.getFullYear()) {
          //               self.manchesterPrev += parseFloat(office.amount);
          //             }
          //             if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0 || element.caseStatus == 1)) {
          //               self.manchesterOutstanding += parseFloat(office.amount);
          //             }
          //           } else if (office.bankAccount === "Chester" && office.typeOfPayment === "Legal FEE") {
          //             if (last_Month === current_Month + 1 && latest_date.getFullYear() === date.getFullYear()) {
          //               self.chesterCurrent += parseFloat(office.amount);
          //             }
          //             else if (last_Month === current_Month && latest_date.getFullYear() === date.getFullYear()) {
          //               self.chesterPrev += parseFloat(office.amount);
          //             }
          //             if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0 || element.caseStatus == 1)) {
          //               self.chesterOutstanding += parseFloat(office.amount);
          //             }
          //           }
          //           else if (office.bankAccount === "London" && office.typeOfPayment === "Legal FEE") {
          //             if (last_Month === current_Month + 1 && latest_date.getFullYear() === date.getFullYear()) {
          //               self.londonCurrent += parseFloat(office.amount);
          //             }
          //             else if (last_Month === current_Month && latest_date.getFullYear() === date.getFullYear()) {
          //               self.londonPrev += parseFloat(office.amount);
          //             }
          //             if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0 || element.caseStatus == 1)) {
          //               self.londonOutstanding += parseFloat(office.amount);
          //             }
          //           }
          //           else if (office.bankAccount === "Birmingham" && office.typeOfPayment === "Legal FEE") {
          //             if (last_Month === current_Month + 1 && latest_date.getFullYear() === date.getFullYear()) {
          //               self.birminghamCurrent += parseFloat(office.amount);
          //             } else if (last_Month === current_Month && latest_date.getFullYear() === date.getFullYear()) {
          //               self.birminghamPrev += parseFloat(office.amount);
          //             }
          //             if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0 || element.caseStatus == 1)) {
          //               self.birminghamOutstanding += parseFloat(office.amount);
          //             }
          //           }
          //           if (office.bankAccount == self.branchId) {
          //             if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0 || element.caseStatus == 1)) {
          //               self.outStandingBalance += parseFloat(office.amount);
          //             }
          //             if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0)) {
          //               self.ActiveBalance += parseFloat(office.amount);
          //             }
          //             if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 1)) {
          //               self.AwaitingBalance += parseFloat(office.amount);
          //             } if (office.typeOfPayment === "Disbursement") {
          //               self.disIn += parseFloat(office.amount);
          //             } if (office.typeOfPayment == "Legal FEE") {
          //               // self.disOut +=parseFloat(office.amount);
          //               account += parseFloat(office.amount);
          //               if (r['officeAccount'].length - 1 === i) {
          //                 var total = parseFloat(r.amount) - account;
          //                 self.pendingBalance += total;
          //               }
          //             }
          //           } else if (self.branchId == "All") {
          //             if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0 || element.caseStatus == 1)) {
          //               self.outStandingBalance += parseFloat(office.amount);
          //             }
          //             if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0)) {
          //               self.ActiveBalance += parseFloat(office.amount);
          //             }
          //             if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 1)) {
          //               self.AwaitingBalance += parseFloat(office.amount);
          //             }
          //             if (office.typeOfPayment === "Disbursement") {
          //               self.disIn += parseFloat(office.amount);
          //             } else {
          //               self.disOut += parseFloat(office.amount);
          //               account += parseFloat(office.amount);
          //               if (r['officeAccount'].length - 1 === i) {
          //                 var total = parseFloat(r.amount) - account;
          //                 self.pendingBalance += total;
          //               }
          //             }
          //           }
          //         } else {
          //           if (office.typeOfPayment === "Disbursement") {
          //             self.disOut += parseFloat(office.amount);
          //           } if (office.typeOfPayment == "Legal FEE") {
          //             // self.disOut +=parseFloat(office.amount);
          //             account += parseFloat(office.amount);
          //             if (r['officeAccount'].length - 1 === i) {
          //               var total = parseFloat(r.amount) - account;
          //               self.pendingBalance += total;
          //             }
          //           }
          //         }
          //         i += 1;
          //       });







          //     });
          //     element['caseInvoice'].forEach(function (client) {
          //       if (client.isInPayment) {
          //         if (client.bankAccount == self.branchId) {
          //           if (client.typeOfPayment === "Disbursement") {
          //             self.disIn += parseFloat(client.amount);
          //           } else {
          //             self.disOut += parseFloat(client.amount);
          //           }
          //         }
          //         else if (self.branchId == "All") {
          //           if (client.typeOfPayment === "Disbursement") {
          //             self.disIn += parseFloat(client.amount);
          //           } else {
          //             self.disOut += parseFloat(client.amount);
          //           }
          //         }
          //       }

          //     });
          //     return;
          //   }


          // });
          // var index = matterIds.indexOf(element.matterId);
          // if (index > -1) {
          //   matterList[element.matterId].value += 1;
          //   matterList[element.matterId].name = element.matterName;

          // }
          if(disOutCheck){
            if(disOutTotal>0){
              element['disOutTotal']=disOutTotal;
              self.disOutFiles.push(element);
            }
          }
          if(disInCheck){
            if(disInTotal>0){
              element['disInTotal']=disInTotal;
              self.disInFiles.push(element);
            }
          }
        }

      }
      else {
        var p = self.checkPermission('Dashboard', 'level', self.branchId);
        var pcheck = false;
        var arr = [1, 2, 3];
        p.forEach(function (el) {
          if (arr.indexOf(parseInt(el)) > -1) {
            pcheck = true;
            return;
          }
        });
        // let deadline = new Date(element.deadLine);
        // let current_date = new Date();
        // if (pcheck) {
        //   this.retainers += 1;
        //   if (current_date > deadline) {
        //     this.lateTodo.push({ "Id": element.Id, "Text": element.matterName, "ItemId": "Client Name : " + element.clientName, "type": 2, "Date": element.deadLine });

        //   } else {
        //     this.CurrentTodo.push({ "Id": element.Id, "Text": element.matterName, "ItemId": "Client Name : " + element.clientName, "type": 2, "Date": element.deadLine });

        //   }
        //   this.retainer.push(element);
        // }
        // if (self.branchId === element.personResponsilbeBranchName || element.personResponsilbeBranchName == null) {
        //   this.retainers += 1;
        //   this.retainer.push(element);
        //   this.CurrentTodo.push({ "Id": element.Id, "Text": element.matterName, "ItemId": "Client Name : " + element.clientName, "type": 2, "Date": element.deadLine });
        // } else if (self.branchId === "All") {
        //   this.retainers += 1;
        //   this.retainer.push(element);
        //   this.CurrentTodo.push({ "Id": element.Id, "Text": element.matterName, "ItemId": "Client Name : " + element.clientName, "type": 2, "Date": element.deadLine });
        // }
      }
      // }else if(this.branchId === "All"){
      //   if(element.isDraft == true){
      //   if ((element.personResposibleId == self.user.Id || self.user.RoleId == 1)  ) {

      //     let deadline = new Date(element.deadLine);
      //     let current_date = new Date();
      //     if(current_date>deadline && element.caseStatus===0){
      //       self.lateFiles+=1;
      //     }
      //     if (element.caseStatus == 0) {
      //       self.ActiveCases += 1;
      //     } else if (element.caseStatus == 1) {
      //       self.AwaitingCases += 1
      //     }
      //     else if (element.caseStatus == 2) {
      //       self.inCompletedCases += 1
      //     }
      //     element.caseWorkerList.forEach(element1 => {
      //       if(element1.status===1){
      //     self.pendingApprovals+=1;
      //     return;
      //   }
      //   });
      //     element['caseInvoice'].forEach(invoice => {
      //       if (self.branchId === "All") {

      //         self.agreedCost += parseFloat(invoice.agreedCost);
      //         }else{
      //           if(self.branchId===element.personResponsilbeBranchName)
      //           {
      //             self.agreedCost += parseFloat(invoice.agreedCost);
      //           }
      //         }
      //     });
      //     element['officeInvoice'].forEach(function (r) {

      //       var i = 0;
      //       r['officeAccount'].forEach(office => {
      //         var account = 0;
      //         if (office.isInPayment) {
      //           let latest_date = new Date(office.entryDate);
      //           let date = new Date();
      //           var current_Month = (date.getMonth() - 1);
      //           var last_Month = latest_date.getMonth();
      //           if (office.bankAccount === "Manchester" && office.typeOfPayment === "Legal FEE") {
      //             if (last_Month === current_Month + 1 && latest_date.getFullYear() === date.getFullYear()) {
      //               self.manchesterCurrent += parseFloat(office.amount);
      //             }
      //             else if (last_Month === current_Month && latest_date.getFullYear() === date.getFullYear()) {
      //               self.manchesterPrev += parseFloat(office.amount);
      //             }
      //             if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0 || element.caseStatus == 1)) {
      //               self.manchesterOutstanding += parseFloat(office.amount);
      //             }
      //           } else if (office.bankAccount === "Chester" && office.typeOfPayment === "Legal FEE") {
      //             if (last_Month === current_Month + 1 && latest_date.getFullYear() === date.getFullYear()) {
      //               self.chesterCurrent += parseFloat(office.amount);
      //             }
      //             else if (last_Month === current_Month && latest_date.getFullYear() === date.getFullYear()) {
      //               self.chesterPrev += parseFloat(office.amount);
      //             }
      //             if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0 || element.caseStatus == 1)) {
      //               self.chesterOutstanding += parseFloat(office.amount);
      //             }
      //           }
      //           else if (office.bankAccount === "London" && office.typeOfPayment === "Legal FEE") {
      //             if (last_Month === current_Month + 1 && latest_date.getFullYear() === date.getFullYear()) {
      //               self.londonCurrent += parseFloat(office.amount);
      //             }
      //             else if (last_Month === current_Month && latest_date.getFullYear() === date.getFullYear()) {
      //               self.londonPrev += parseFloat(office.amount);
      //             }
      //             if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0 || element.caseStatus == 1)) {
      //               self.londonOutstanding += parseFloat(office.amount);
      //             }
      //           }
      //           else if (office.bankAccount === "Birmingham" && office.typeOfPayment === "Legal FEE") {
      //             if (last_Month === current_Month + 1 && latest_date.getFullYear() === date.getFullYear()) {
      //               self.birminghamCurrent += parseFloat(office.amount);
      //             } else if (last_Month === current_Month && latest_date.getFullYear() === date.getFullYear()) {
      //               self.birminghamPrev += parseFloat(office.amount);
      //             }
      //             if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0 || element.caseStatus == 1)) {
      //               self.birminghamOutstanding += parseFloat(office.amount);
      //             }
      //           }

      //           if (office.bankAccount == self.branchId) {
      //             if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0 || element.caseStatus == 1)) {
      //               self.outStandingBalance += parseFloat(office.amount);
      //             }
      //             if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0)) {
      //               self.ActiveBalance += parseFloat(office.amount);
      //             }
      //             if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 1)) {
      //               self.AwaitingBalance += parseFloat(office.amount);
      //             }
      //             if (office.typeOfPayment === "Disbursement") {
      //               self.disIn += parseFloat(office.amount);
      //             } if (office.typeOfPayment == "Legal FEE") {
      //               // self.disOut +=parseFloat(office.amount);
      //               account += parseFloat(office.amount);
      //               if (r['officeAccount'].length - 1 === i) {
      //                 var total = parseFloat(r.amount) - account;
      //                 self.pendingBalance += total;
      //               }
      //             }
      //           } else if (self.branchId === "All") {

      //             if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0 || element.caseStatus == 1)) {
      //               self.outStandingBalance += parseFloat(office.amount);
      //             }
      //             if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0)) {
      //               self.ActiveBalance += parseFloat(office.amount);
      //             }
      //             if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 1)) {
      //               self.AwaitingBalance += parseFloat(office.amount);
      //             }
      //             if (office.typeOfPayment === "Disbursement") {
      //               self.disIn += parseFloat(office.amount);
      //             } if (office.typeOfPayment == "Legal FEE") {
      //               // self.disOut +=parseFloat(office.amount);
      //               account += parseFloat(office.amount);
      //               if (r['officeAccount'].length - 1 === i) {
      //                 var total = parseFloat(r.amount) - account;
      //                 self.pendingBalance += total;
      //               }
      //             }

      //           }}
      //            else {
      //             if (office.typeOfPayment === "Disbursement") {
      //               self.disOut += parseFloat(office.amount);
      //             } if (office.typeOfPayment == "Legal FEE") {
      //               // self.disOut +=parseFloat(office.amount);
      //               account += parseFloat(office.amount);
      //               if (r['officeAccount'].length - 1 === i) {
      //                 var total = parseFloat(r.amount) - account;
      //                 self.pendingBalance += total;
      //               }
      //             }
      //           }


      //           i += 1;
      //         });





      //     });
      //     element['caseInvoice'].forEach(function (client) {
      //       if (client.isInPayment) {
      //         if (client.bankAccount == self.branchId) {
      //           if (client.typeOfPayment === "Disbursement") {
      //             self.disIn += parseFloat(client.amount);
      //           } else {
      //             self.disOut += parseFloat(client.amount);
      //           }
      //         }
      //         else if (self.branchId === "All") {
      //           if (client.typeOfPayment === "Disbursement") {
      //             self.disIn += parseFloat(client.amount);
      //           } else {
      //             self.disOut += parseFloat(client.amount);
      //           }
      //         }
      //       } else {
      //         if (client.typeOfPayment === "Disbursement") {
      //           self.disOut += parseFloat(client.amount);
      //         }
      //       }

      //     });
      //     var index = matterIds.indexOf(element.matterId);
      //     if (index > -1) {
      //       matterList[element.matterId].value += 1;
      //       matterList[element.matterId].name = element.matterName;

      //     }

      //   } else {

      //       let deadline = new Date(element.deadLine);
      //       let current_date = new Date();
      //       if(current_date>deadline && element.caseStatus===0){
      //         self.lateFiles+=1;
      //       }
      //       element.caseWorkerList.forEach(element1 => {
      //         if (element1.userId == self.user.Id) {
      //           if (element.caseStatus == 0) {
      //             self.ActiveCases += 1;
      //             // return;
      //           } else if (element.caseStatus == 1) {
      //             self.AwaitingCases += 1;
      //             // return;
      //           }
      //           else if (element.caseStatus == 2) {
      //             self.inCompletedCases += 1;
      //             // return;
      //           }
      //           element['caseInvoice'].forEach(invoice => {
      //             if (self.branchId === "All") {
      //             self.agreedCost += parseFloat(invoice.agreedCost);
      //             }else{
      //               if(self.branchId===element.personResponsilbeBranchName)
      //               {
      //                 self.agreedCost += parseFloat(invoice.agreedCost);
      //               }
      //             }
      //           });

      //           element['officeInvoice'].forEach(function (r) {
      //             var i = 0;
      //             r['officeAccount'].forEach(office => {
      //               var account = 0;
      //               if (office.isInPayment) {
      //                 let latest_date = new Date(office.entryDate);
      //                 let date = new Date();
      //                 var current_Month = (date.getMonth() - 1);
      //                 var last_Month = latest_date.getMonth();
      //                 if (office.bankAccount === "Manchester" && office.typeOfPayment === "Legal FEE") {
      //                   if (last_Month === current_Month + 1 && latest_date.getFullYear() === date.getFullYear()) {
      //                     self.manchesterCurrent += parseFloat(office.amount);
      //                   }
      //                   else if (last_Month === current_Month && latest_date.getFullYear() === date.getFullYear()) {
      //                     self.manchesterPrev += parseFloat(office.amount);
      //                   }
      //                   if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0 || element.caseStatus == 1)) {
      //                     self.manchesterOutstanding += parseFloat(office.amount);
      //                   }
      //                 } else if (office.bankAccount === "Chester" && office.typeOfPayment === "Legal FEE") {
      //                   if (last_Month === current_Month + 1 && latest_date.getFullYear() === date.getFullYear()) {
      //                     self.chesterCurrent += parseFloat(office.amount);
      //                   }
      //                   else if (last_Month === current_Month && latest_date.getFullYear() === date.getFullYear()) {
      //                     self.chesterPrev += parseFloat(office.amount);
      //                   }
      //                   if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0 || element.caseStatus == 1)) {
      //                     self.chesterOutstanding += parseFloat(office.amount);
      //                   }
      //                 }
      //                 else if (office.bankAccount === "London" && office.typeOfPayment === "Legal FEE") {
      //                   if (last_Month === current_Month + 1 && latest_date.getFullYear() === date.getFullYear()) {
      //                     self.londonCurrent += parseFloat(office.amount);
      //                   }
      //                   else if (last_Month === current_Month && latest_date.getFullYear() === date.getFullYear()) {
      //                     self.londonPrev += parseFloat(office.amount);
      //                   }
      //                   if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0 || element.caseStatus == 1)) {
      //                     self.londonOutstanding += parseFloat(office.amount);
      //                   }
      //                 }
      //                 else if (office.bankAccount === "Birmingham" && office.typeOfPayment === "Legal FEE") {
      //                   if (last_Month === current_Month + 1 && latest_date.getFullYear() === date.getFullYear()) {
      //                     self.birminghamCurrent += parseFloat(office.amount);
      //                   } else if (last_Month === current_Month && latest_date.getFullYear() === date.getFullYear()) {
      //                     self.birminghamPrev += parseFloat(office.amount);
      //                   }
      //                   if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0 || element.caseStatus == 1)) {
      //                     self.birminghamOutstanding += parseFloat(office.amount);
      //                   }
      //                 }
      //                 if (office.bankAccount == self.branchId) {
      //                   if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0 || element.caseStatus == 1)) {
      //                     self.outStandingBalance += parseFloat(office.amount);
      //                   }
      //                   if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0)) {
      //                     self.ActiveBalance += parseFloat(office.amount);
      //                   }
      //                   if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 1)) {
      //                     self.AwaitingBalance += parseFloat(office.amount);
      //                   } if (office.typeOfPayment === "Disbursement") {
      //                     self.disIn += parseFloat(office.amount);
      //                   } if (office.typeOfPayment == "Legal FEE") {
      //                     // self.disOut +=parseFloat(office.amount);
      //                     account += parseFloat(office.amount);
      //                     if (r['officeAccount'].length - 1 === i) {
      //                       var total = parseFloat(r.amount) - account;
      //                       self.pendingBalance += total;
      //                     }
      //                   }
      //                 } else if (self.branchId == "All") {
      //                   if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0 || element.caseStatus == 1)) {
      //                     self.outStandingBalance += parseFloat(office.amount);
      //                   }
      //                   if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0)) {
      //                     self.ActiveBalance += parseFloat(office.amount);
      //                   }
      //                   if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 1)) {
      //                     self.AwaitingBalance += parseFloat(office.amount);
      //                   }
      //                   if (office.typeOfPayment === "Disbursement") {
      //                     self.disIn += parseFloat(office.amount);
      //                   } else {
      //                     self.disOut += parseFloat(office.amount);
      //                     account += parseFloat(office.amount);
      //                     if (r['officeAccount'].length - 1 === i) {
      //                       var total = parseFloat(r.amount) - account;
      //                       self.pendingBalance += total;
      //                     }
      //                   }
      //                 }
      //               } else {
      //                 if (office.typeOfPayment === "Disbursement") {
      //                   self.disOut += parseFloat(office.amount);
      //                 } if (office.typeOfPayment == "Legal FEE") {
      //                   // self.disOut +=parseFloat(office.amount);
      //                   account += parseFloat(office.amount);
      //                   if (r['officeAccount'].length - 1 === i) {
      //                     var total = parseFloat(r.amount) - account;
      //                     self.pendingBalance += total;
      //                   }
      //                 }
      //               }
      //               i += 1;
      //             });







      //           });
      //           element['caseInvoice'].forEach(function (client) {
      //             if (client.isInPayment) {
      //               if (client.bankAccount == self.branchId) {
      //                 if (client.typeOfPayment === "Disbursement") {
      //                   self.disIn += parseFloat(client.amount);
      //                 } else {
      //                   self.disOut += parseFloat(client.amount);
      //                 }
      //               }
      //               else if (self.branchId == "All") {
      //                 if (client.typeOfPayment === "Disbursement") {
      //                   self.disIn += parseFloat(client.amount);
      //                 } else {
      //                   self.disOut += parseFloat(client.amount);
      //                 }
      //               }
      //             }

      //           });
      //           return;
      //         }


      //       });
      //       var index = matterIds.indexOf(element.matterId);
      //       if (index > -1) {
      //         matterList[element.matterId].value += 1;
      //         matterList[element.matterId].name = element.matterName;

      //       }

      //   }
      // }
      // else{
      //   this.retainers+=1;
      // }
      // }
    });
    this.Deadlines = this.Deadlines.sort((a, b) => (a.Date > b.Date ? 1 : -1));

    this.Deadlines.forEach(element => {
      if (Object.keys(this.nDeadline).indexOf(element.FileNumber) < 0) {
        this.nDeadline[element.FileNumber] = []


        this.nDeadline[element.FileNumber].push(element)



      } else {

        this.nDeadline[element.FileNumber].push(element)

      }

    });
    for (const [key, value] of Object.entries(this.nDeadline)) {
      var name = []
      var profPic = []
      this.nDeadline[key].forEach(item => {
        if (name.indexOf(item.Name) < 0) {
          name.push(item.Name)

          profPic.push({
            name:item.Name,
            profPic:item.profilePic
          })
        }
      })

      this.nDeadline[key][0]["Names"] = profPic

    }
    var mattertotal = 0

    for (const [key, value] of Object.entries(matterList)) {
      // var r;
      // r = ((1<<24)*Math.random() | 0).toString(16);
      // while (true) {

      //   if (arr.indexOf(r) === -1 && r!="ffffff") {
      //     arr.push(r);
      //     break;
      //   }
      // }
      mattertotal += value["value"];
      if (value['value'] > 0) {
        this.MatterData.push({ label: value["name"], value: value["value"], color: this.generateRandomColor(),
      files:value["files"]});
      }
    }

    this.MatterData = this.MatterData.sort((a, b) => (a.value < b.value ? 1 : -1));
    this.MatterData.push({ total: mattertotal });
    this.VisaData = [{ label: "Visa Granted", value: Granted, legendItem: "A", color: "rgb(108, 96, 126)",files:GrantedFiles },
    { label: "Visa Refused", value: refused, legendItem: "A", color: "rgb(247, 223, 188)",files:refusedFiles },
    ];
    this.canvas = $('#myChart');
    this.ctx = this.canvas[0].getContext('2d');
    if (this.chart) {
      this.chart.destroy();
    }
    this.chart = new Chart(this.ctx, {
      type: 'bar',

      data: {
        labels: ['OB', 'DB', 'AFB', 'DI', 'DO'],
        datasets: [{
          // label: 'Account Statistics',
          borderRadius: 2,
          maxBarThickness: 20,
          backgroundColor: [
            '#ce6cf1',
            '#706661',
            '#32455A',
            '#fe7388',
            '#F1BE6A',
          ],
          yAxisID: 'y',
          data: [this.outStandingBalance, this.agreedCost, this.ActiveBalance, this.disIn, this.disOut]
        }]
      },
      options: {
        // scales: {
        //   xAxes: [{
        //     gridLines: {
        //       offsetGridLines: false
        //     }
        //   }]
        // },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            grid: {
              display: false
            }
          },
          y: {
            beginAtZero: true,
            grid: {
              display: false
            }
          },


        },
        plugins: {
          legend: {
            display: false,
            labels: {
              color: '#495057',

            }
          },

        },
      }

    });
  }
  marketing(){
    this.leadFormDetail = {};
    this.leadFormFiles = [];
    this.activeLeads = [];
    this.leadPerStageData = [];
    var leadByStage = {};
    this.leadPerStageWinLostData = [];
    var leadWin = 0;
    this.leadWinFiles =[];
    this.leadLoseFiles =[];
    var leadlost = 0;
    this.leadByMatterData = [];
    this.workSourceList = [];
    var leadByMatter = {};
    var workSrc = {};
    let self = this;
    this.allLeadForm.forEach(element => {
      if (!element.isDrafted) {
        // Active Leads
        if (element.dealOwner && (element.status.toLowerCase() == 'lost' || element.status.toLowerCase() == 'win')) {
          if (element.dealOwner in this.leadFormDetail) {
            let count = this.leadFormDetail[element.dealOwner]["count"]
            count++;
            this.leadFormDetail[element.dealOwner]["count"] = count;
            this.leadFormDetail[element.dealOwner]["files"].push(element);


          }
          else {
            let count = 1;
            var color = this.randomColorPicker();
            let files =[]
            files.push(element);
            this.leadFormDetail[element.dealOwner] = { "count": count, "Name": element.dealOwnerName,
            "dealOwnerProfilePic":element.dealOwnerProfilePic,
             "color": color,"files":files };
          }
        }
        // Lead Per Stage
        // Lead Per Stage for All
        if (!this.leadPerStageDate) {
          if (element.status in leadByStage) {
            let count = leadByStage[element.status]['count'];
            count++;
            leadByStage[element.status]['count'] = count;
            leadByStage[element.status]['Files'].push(element);
          }
          else {
            let count = 1
            let files =[]
            files.push(element);
            leadByStage[element.status] = { "count": count, "Name": element.status,"Files":files};
          }
        } // Lead Per Stage for Specific Month
        else {
          let ldate = new Date(this.leadPerStageDate)
          let lmonth = ldate.getMonth();
          let lyear = ldate.getFullYear();
          let cdate = new Date(element.creationTime);
          let cmonth = cdate.getMonth();
          let cyear = cdate.getFullYear();
          if (lmonth === cmonth && lyear === cyear) {

            if (element.status in leadByStage) {
              let count = leadByStage[element.status]['count'];
              count++;
              leadByStage[element.status]['count'] = count;
              leadByStage[element.status]['Files'].push(element);
            }
            else {
              let count = 1
              let files =[]
              files.push(element)
              leadByStage[element.status] = { "count": count, "Name": element.status,"Files":files };
            }
          }
        }
        if(element.status.toLowerCase() == 'lost'){
          this.lostRevenue += Number(element.dealAmount)
        }
        else if(element.status.toLowerCase() == 'win'){
          this.winRevenue += Number(element.dealAmount)
        }
        else{
          this.expectedRevenue += Number(element.dealAmount)
        }

        // WIN / LOSS Percentage
        if (!this.leadPerStageWinLostDate) {
          if (element.status && (element.status.toLowerCase() == 'lost')) {
            leadlost += 1;
            this.leadLoseFiles.push(element);
          }
          else if (element.status && element.status.toLowerCase() == 'win') {
            leadWin += 1;
            this.leadWinFiles.push(element);
          }
        }
        else {
          let ldate = new Date(this.leadPerStageWinLostDate)
          let lmonth = ldate.getMonth();
          let lyear = ldate.getFullYear();
          let cdate = new Date(element.creationTime);
          let cmonth = cdate.getMonth();
          let cyear = cdate.getFullYear();
          if (lmonth === cmonth && lyear === cyear) {
            if (element.status && (element.status.toLowerCase() == 'lost')) {
              leadlost += 1;
              this.leadLoseFiles.push(element);
            }
            else if (element.status && element.status.toLowerCase() == 'win') {
              leadWin += 1;
              this.leadWinFiles.push(element);
            }
          }
        }

        // WIN/ LOST  Deal Amount This Month

        let today = new Date()
        let thisMonth = today.getMonth();
        let thisYear = today.getFullYear();
        let cdate = new Date(element.creationTime);
        let cmonth = cdate.getMonth();
        let cyear = cdate.getFullYear();
        if (thisMonth === cmonth && thisYear === cyear) {
          if (element.status && (element.status.toLowerCase() == 'lost')) {
            if (element.dealAmount) {
              this.dealLostAmountThisMonth += Number(element.dealAmount);
            }

          }
          else if (element.status && element.status.toLowerCase() == 'win') {
            this.dealWonAmountThisMonth += Number(element.dealAmount);
          }
        }

        // Leads By Matter Type
        // For All date
        if (!this.leadByMatterTypeDate) {
          if (element.matterType in leadByMatter) {
            let count = leadByMatter[element.matterType]['count'];
            count++;
            leadByMatter[element.matterType]['count'] = count;
            leadByMatter[element.matterType]['files'].push(element);

          }
          else {
            let count = 1
            let files = []
              files.push(element)
            leadByMatter[element.matterType] = { "count": count, "Name": element.matterType,"files":files };
          }
        } // Lead Per Stage for Specific Month
        else {
          let ldate = new Date(this.leadByMatterTypeDate)
          let lmonth = ldate.getMonth();
          let lyear = ldate.getFullYear();
          let cdate = new Date(element.creationTime);
          let cmonth = cdate.getMonth();
          let cyear = cdate.getFullYear();
          if (lmonth === cmonth && lyear === cyear) {

            if (element.matterType in leadByMatter) {
              let count = leadByMatter[element.matterType]['count'];
              count++;
              leadByMatter[element.matterType]['count'] = count;
              leadByMatter[element.matterType]['files'].push(element);
            }
            else {
              let count = 1
              let files = []
              files.push(element)
              leadByMatter[element.matterType] = { "count": count, "Name": element.matterType,"files":files };
            }
          }
        }


        // Leads Reminder TOdo
        var ar = [0, 3, 4, 5, 6, 7, 9];
        var p = this.checkPermission('Dashboard', 'level', this.branchId);
        var check = false;
        p.forEach(function (el) {
          if (ar.indexOf(parseInt(el)) < 0) {
            check = true;
            return;
          }
        });
        if ((check && element.branchId == self.user.branchId) || self.user.RoleId == 1) {
          element.reminders.forEach(element1 => {
            let dateSent = new Date(element1.creationTime);
            let currentDate = new Date(element1.date);
            var ntime = Math.floor((Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()) - Date.UTC(dateSent.getFullYear(), dateSent.getMonth(), dateSent.getDate())) / (1000 * 60 * 60 * 24));
            if (ntime < 1) {
              this.lateTodo.push({ "Id": element.Id, "Text": element1.comments, "ItemId": element1.personActingName, "type": 3, "Date": element1.creationTime })
            } else {
              this.CurrentTodo.push({ "Id": element.Id, "Text": element1.comments, "ItemId": element1.personActingName, "type": 3, "Date": element1.creationTime })
            }
          });
        }








        //Leads with Incomplete Cadence
        let count = 0;
        if (element.isCalled && element.isEmailed && element.isMessaged) {
          count += 1;
        }
        if (element.isCalledStep2 && element.isEmailedStep2 && element.isMessagedStep2) {
          count += 1;
        }
        if (element.isCalledStep3 && element.isEmailedStep3 && element.isMessagedStep3) {
          count += 1;
        }
        if (count < 3) {
          this.IncompletCadenceData.push({
            "Id": element.Id,
            "clientName": element.name,
            "cadence": 'Cadence (' + count.toString() + '/3)',
            'status': element.status,
            "dealOwner": element.dealOwnerName,
            "dealOwnerProfilePic":element.dealOwnerProfilePic,
          })
        }
      }
    });

    this.workSource.forEach(element => {
      workSrc[element.Id] = { value: 0, Name: element.Name }
    });
    this.cases.forEach(element => {

      // if(self.branchId===element.personResponsilbeBranchName){
      if (element.isDraft == true) {
        let creationDate = new Date(element.creationTime)
        if (self.branchId === element.personResponsilbeBranchName || self.branchId === "All") {
          if (!self.WorkSourceDate) {
            if (element.workSourceId) {
              workSrc[element.workSourceId].value += 1
            }
          } else {
            let wDate = new Date(self.WorkSourceDate);
            if (creationDate.getMonth() === wDate.getMonth() && creationDate.getFullYear() === wDate.getFullYear()) {
              if (element.workSourceId) {
                workSrc[element.workSourceId].value += 1
              }
            }
          }
        }
      }
    });




    for (const [key, value] of Object.entries(this.leadFormDetail)) {
      console.log("active Lead", key, value);
      this.activeLeads.push({ Name: value["Name"], value: value["count"], color: value['color'],
      dealOwnerProfilePic:value['dealOwnerProfilePic'],
      files:value['files'] });

    }
    for (const [key, value] of Object.entries(leadByMatter)) {
      this.leadByMatterData.push({ Name: value["Name"], value: value["count"],files:value["files"] });

    }

    for (const [key, value] of Object.entries(leadByStage)) {
      if (value["Name"].toUpperCase() === "WIN" || value["Name"].toUpperCase() === "DISCOVERY" || value["Name"].toUpperCase() === "QUALIFIED" || value["Name"].toUpperCase() === "NEGOTIATION" || value["Name"].toUpperCase() === "LOST") {
        this.leadPerStageData.push({ y: value["Name"].toUpperCase(), a: value["count"] ,files:value['Files'] });
      }
    }

    var total = leadWin + leadlost;
    var lostPerc = 0;
    var WinPerc = 0;
    if (total != 0) {
      lostPerc = Math.round(leadlost / total * 100);
      WinPerc = Math.round(leadWin / total * 100);
    }

    this.leadPerStageWinLostData = [{ label: "Win Percentage", value: WinPerc, legendItem: "A", color: "rgb(247, 180, 151)" },
    { label: "Lost Percentage", value: lostPerc, legendItem: "A", color: "rgb(226, 240, 217)" },
    ];

    for (const [key, value] of Object.entries(workSrc)) {
      this.workSourceList.push({ Name: value["Name"], value: value["value"] });

    }


    this.leadspstage();
    this.dealWInCHart();
    this.expectedRevenueFun();
  }
  userFunc(){
    var p =  this.checkPermission('User Profile','level',"All");
    let arr = []
    p.forEach(element => {
      element.forEach(element1 => {
        arr.push(element1['Id'])
      });
    });
    this.filteredUser = [];
    if(User.Id!=1){
      this.filteredUser = this.users.filter(x=> x.Id != 1 && x.isActive == true && arr.includes(x.Id));
    }
    else{
      this.filteredUser = this.users;
    }
    // this.filteredUser = this.filteredUser.filter(x => x.Id != 1 && x.isActive == true && (x.branchName === this.branchId || this.branchId === "All"));
  }
  // calData() {
  //   this.ActiveFiles = [];
  //   this.lateTodo = [];
  //   this.activeLeads = [];
  //   this.CurrentTodo = [];
  //   this.prevMonthBalance = [];
  //   this.thisMonthBalance = [];
  //   this.OutStandingBalance = [];
  //   this.brp = [];
  //   this.solvedITTicket = [];
  //   this.AllIt = [];
  //   this.PendingITTicket = [];
  //   this.retainers = [];
  //   this.awaitingCompletion = [];
  //   this.PendingApprovalFiles = [];
  //   this.AwaitingFiles = [];
  //   this.OutStandingFiles = [];
  //   this.ActiveBalanceFiles = [];
  //   this.draftBalanceFiles = [];
  //   this.disOutFiles = [];
  //   this.disInFiles = [];
  //   var self = this;
  //   this.filteredUser = [];
  //   this.workSourceList = [];
  //   this.lateCases = [];
  //   this.Deadlines = [];
  //   this.SolveIT = 0;
  //   this.inCompleteIt = 0;
  //   var LateMatter = {};
  //   this.nDeadline = {}
  //   this.retainers = 0;
  //   this.agreedCost = 0;
  //   this.ActiveCases = 0;
  //   this.lateFiles = 0;
  //   this.AwaitingBalance = 0;
  //   this.inCompletedCases = 0;
  //   this.ActiveBalance = 0;
  //   this.AwaitingCases = 0;
  //   this.outStandingBalance = 0;
  //   this.disOut = 0;
  //   this.disIn = 0;
  //   this.pendingBalance = 0;
  //   this.manchesterPrev = 0;
  //   this.londonPrev = 0;
  //   this.chesterPrev = 0;
  //   this.birminghamPrev = 0;
  //   this.pendingApprovals = 0
  //   this.manchesterCurrent = 0;
  //   this.londonCurrent = 0;
  //   this.chesterCurrent = 0;
  //   this.birminghamCurrent = 0;

  //   this.manchesterOutstanding = 0;
  //   this.londonOutstanding = 0;
  //   this.chesterOutstanding = 0;
  //   this.birminghamOutstanding = 0;
  //   var pendingPaymentsByUsers = {}
  //   var leadByStage = {};
  //   var leadByMatter = {};
  //   var leadWin = 0;
  //   this.leadWinFiles =[];
  //   this.leadLoseFiles =[];
  //   var leadlost = 0;
  //   var workSrc = {};
  //   var matterIds = this.matters.map(x => { return x.pk });
  //   var matterList: any = {};
  //   var LateFiles: any = {};
  //   var refused = 0;
  //   var refusedFiles =[];
  //   var Granted = 0
  //   var GrantedFiles =[];
  //   this.dealLostAmountThisMonth = 0;
  //   this.dealWonAmountThisMonth = 0;
  //   this.IncompletCadenceData = [];
  //   this.allDraftedInvoices = [];
  //   this.leadFormDetail = {};
  //   this.leadFormFiles = [];
  //   this.allLeadForm.forEach(element => {
  //     if (!element.isDrafted) {
  //       // Active Leads
  //       if (element.dealOwner && (element.status.toLowerCase() == 'lost' || element.status.toLowerCase() == 'win')) {
  //         if (element.dealOwner in this.leadFormDetail) {
  //           let count = this.leadFormDetail[element.dealOwner]["count"]
  //           count++;
  //           this.leadFormDetail[element.dealOwner]["count"] = count;
  //           this.leadFormDetail[element.dealOwner]["files"].push(element);


  //         }
  //         else {
  //           let count = 1;
  //           var color = this.randomColorPicker();
  //           let files =[]
  //           files.push(element);
  //           this.leadFormDetail[element.dealOwner] = { "count": count, "Name": element.dealOwnerName,
  //           "dealOwnerProfilePic":element.dealOwnerProfilePic,
  //            "color": color,"files":files };
  //         }
  //       }
  //       // Lead Per Stage
  //       // Lead Per Stage for All
  //       if (!this.leadPerStageDate) {
  //         if (element.status in leadByStage) {
  //           let count = leadByStage[element.status]['count'];
  //           count++;
  //           leadByStage[element.status]['count'] = count;
  //           leadByStage[element.status]['Files'].push(element);
  //         }
  //         else {
  //           let count = 1
  //           let files =[]
  //           files.push(element);
  //           leadByStage[element.status] = { "count": count, "Name": element.status,"Files":files};
  //         }
  //       } // Lead Per Stage for Specific Month
  //       else {
  //         let ldate = new Date(this.leadPerStageDate)
  //         let lmonth = ldate.getMonth();
  //         let lyear = ldate.getFullYear();
  //         let cdate = new Date(element.creationTime);
  //         let cmonth = cdate.getMonth();
  //         let cyear = cdate.getFullYear();
  //         if (lmonth === cmonth && lyear === cyear) {

  //           if (element.status in leadByStage) {
  //             let count = leadByStage[element.status]['count'];
  //             count++;
  //             leadByStage[element.status]['count'] = count;
  //             leadByStage[element.status]['Files'].push(element);
  //           }
  //           else {
  //             let count = 1
  //             let files =[]
  //             files.push(element)
  //             leadByStage[element.status] = { "count": count, "Name": element.status,"Files":files };
  //           }
  //         }
  //       }

  //       // WIN / LOSS Percentage
  //       if (!this.leadPerStageWinLostDate) {
  //         if (element.status && (element.status.toLowerCase() == 'lost')) {
  //           leadlost += 1;
  //           this.leadLoseFiles.push(element);
  //         }
  //         else if (element.status && element.status.toLowerCase() == 'win') {
  //           leadWin += 1;
  //           this.leadWinFiles.push(element);
  //         }
  //       }
  //       else {
  //         let ldate = new Date(this.leadPerStageWinLostDate)
  //         let lmonth = ldate.getMonth();
  //         let lyear = ldate.getFullYear();
  //         let cdate = new Date(element.creationTime);
  //         let cmonth = cdate.getMonth();
  //         let cyear = cdate.getFullYear();
  //         if (lmonth === cmonth && lyear === cyear) {
  //           if (element.status && (element.status.toLowerCase() == 'lost')) {
  //             leadlost += 1;
  //             this.leadLoseFiles.push(element);
  //           }
  //           else if (element.status && element.status.toLowerCase() == 'win') {
  //             leadWin += 1;
  //             this.leadWinFiles.push(element);
  //           }
  //         }
  //       }

  //       // WIN/ LOST  Deal Amount This Month

  //       let today = new Date()
  //       let thisMonth = today.getMonth();
  //       let thisYear = today.getFullYear();
  //       let cdate = new Date(element.creationTime);
  //       let cmonth = cdate.getMonth();
  //       let cyear = cdate.getFullYear();
  //       if (thisMonth === cmonth && thisYear === cyear) {
  //         if (element.status && (element.status.toLowerCase() == 'lost')) {
  //           if (element.dealAmount) {
  //             this.dealLostAmountThisMonth += Number(element.dealAmount);
  //           }

  //         }
  //         else if (element.status && element.status.toLowerCase() == 'win') {
  //           this.dealWonAmountThisMonth += Number(element.dealAmount);
  //         }
  //       }

  //       // Leads By Matter Type
  //       // For All date
  //       if (!this.leadByMatterTypeDate) {
  //         if (element.matterType in leadByMatter) {
  //           let count = leadByMatter[element.matterType]['count'];
  //           count++;
  //           leadByMatter[element.matterType]['count'] = count;
  //           leadByMatter[element.matterType]['files'].push(element);

  //         }
  //         else {
  //           let count = 1
  //           let files = []
  //             files.push(element)
  //           leadByMatter[element.matterType] = { "count": count, "Name": element.matterType,"files":files };
  //         }
  //       } // Lead Per Stage for Specific Month
  //       else {
  //         let ldate = new Date(this.leadByMatterTypeDate)
  //         let lmonth = ldate.getMonth();
  //         let lyear = ldate.getFullYear();
  //         let cdate = new Date(element.creationTime);
  //         let cmonth = cdate.getMonth();
  //         let cyear = cdate.getFullYear();
  //         if (lmonth === cmonth && lyear === cyear) {

  //           if (element.matterType in leadByMatter) {
  //             let count = leadByMatter[element.matterType]['count'];
  //             count++;
  //             leadByMatter[element.matterType]['count'] = count;
  //             leadByMatter[element.matterType]['files'].push(element);
  //           }
  //           else {
  //             let count = 1
  //             let files = []
  //             files.push(element)
  //             leadByMatter[element.matterType] = { "count": count, "Name": element.matterType,"files":files };
  //           }
  //         }
  //       }


  //       // Leads Reminder TOdo
  //       var ar = [0, 3, 4, 5, 6, 7, 9];
  //       var p = this.checkPermission('Dashboard', 'level', this.branchId);
  //       var check = false;
  //       p.forEach(function (el) {
  //         if (ar.indexOf(parseInt(el)) < 0) {
  //           check = true;
  //           return;
  //         }
  //       });
  //       if ((check && element.branchId == self.user.branchId) || self.user.RoleId == 1) {
  //         element.reminders.forEach(element1 => {
  //           let dateSent = new Date(element1.creationTime);
  //           let currentDate = new Date(element1.date);
  //           var ntime = Math.floor((Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()) - Date.UTC(dateSent.getFullYear(), dateSent.getMonth(), dateSent.getDate())) / (1000 * 60 * 60 * 24));
  //           if (ntime < 1) {
  //             this.lateTodo.push({ "Id": element.Id, "Text": element1.comments, "ItemId": element1.personActingName, "type": 3, "Date": element1.creationTime })
  //           } else {
  //             this.CurrentTodo.push({ "Id": element.Id, "Text": element1.comments, "ItemId": element1.personActingName, "type": 3, "Date": element1.creationTime })
  //           }
  //         });
  //       }








  //       //Leads with Incomplete Cadence
  //       let count = 0;
  //       if (element.isCalled && element.isEmailed && element.isMessaged) {
  //         count += 1;
  //       }
  //       if (element.isCalledStep2 && element.isEmailedStep2 && element.isMessagedStep2) {
  //         count += 1;
  //       }
  //       if (element.isCalledStep3 && element.isEmailedStep3 && element.isMessagedStep3) {
  //         count += 1;
  //       }
  //       if (count < 3) {
  //         this.IncompletCadenceData.push({
  //           "Id": element.Id,
  //           "clientName": element.name,
  //           "cadence": 'Cadence (' + count.toString() + '/3)',
  //           'status': element.status,
  //           "dealOwner": element.dealOwnerName,
  //           "dealOwnerProfilePic":element.dealOwnerProfilePic,
  //         })
  //       }
  //     }
  //   });

  //   this.matters.forEach(element => {

  //     matterList[element.pk] = { value: 0, name: element.fields.name ,files:[]};
  //     LateMatter[element.pk] = { value: 0, name: element.fields.name ,files:[]};
  //   });
  //   this.filteredUser = this.users.filter(x => x.Id != 1 && x.isActive == true && (x.branchName === this.branchId || this.branchId === "All"));
  //   this.workSource.forEach(element => {
  //     workSrc[element.Id] = { value: 0, Name: element.Name }
  //   });
  //   this.It.forEach(element => {
  //     if (self.branchId === "All" || self.branchId === element.branchName) {
  //       if (element.isCompleted && (element.userId_id === self.user.Id || self.user.RoleId === 1)) {
  //         self.SolveIT += 1;
  //         this.AllIt.push(element);
  //         self.solvedITTicket.push(element);
  //       } else if ((element.userId === self.user.Id || self.user.RoleId === 1 || self.user.RoleName === "IT") && !element.isCompleted) {

  //         self.PendingITTicket.push(element);
  //         this.AllIt.push(element);
  //         self.inCompleteIt += 1;
  //         let dateSent = new Date(element.creationTime);
  //         let currentDate = new Date();
  //         var ntime = Math.floor((Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()) - Date.UTC(dateSent.getFullYear(), dateSent.getMonth(), dateSent.getDate())) / (1000 * 60 * 60 * 24));
  //         if ((ntime > 1 && element.priorityName === "High") || (ntime > 3 && element.priorityName === "Medium") || (ntime > 5 && element.priorityName === "Low")) {
  //           this.lateTodo.push({ "Id": element.Id, "Text": element.ticketSubject, "ItemId": element.ticketId, "type": 0, "Date": element.creationTime })
  //         } else {
  //           this.CurrentTodo.push({ "Id": element.Id, "Text": element.ticketSubject, "ItemId": element.ticketId, "type": 0, "Date": element.creationTime })
  //         }
  //       }
  //     }
  //   })

  //   var caseIds = []
  //   self.cases.forEach(element => {
  //     element['outBalance']= 0;
  //     var ar = [1, 2, 4];
  //     var p = this.checkPermission('Dashboard', 'level', this.branchId);
  //     var check = false;
  //     p.forEach(function (el) {
  //       if (ar.indexOf(parseInt(el)) >= 0) {
  //         check = true;
  //         return;
  //       }
  //     });


  //     if (check) {
  //       element.caseInvoice.forEach(element1 => {
  //         if (element1.isDraft) {
  //           this.allDraftedInvoices.push(element1);
  //           let dateSent = new Date(element1.creationTime);
  //           let currentDate = new Date(element.deadLine);
  //           var ntime = Math.floor((Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()) - Date.UTC(dateSent.getFullYear(), dateSent.getMonth(), dateSent.getDate())) / (1000 * 60 * 60 * 24));
  //           // if (ntime < 1) {
  //           // this.lateTodo.push({ "Id": element.Id, "Text": "You Got a new Draft Payement", "ItemId": element.fileNumber, "type": 4, "Date": element1.creationTime })
  //           // } else {
  //           this.CurrentTodo.push({ "Id": element.Id, "Text": "You Got a new Draft Payement", "ItemId": element.fileNumber, "type": 4, "Date": element1.creationTime })
  //           // }
  //         }
  //       });
  //     }

  //     // if(self.branchId===element.personResponsilbeBranchName){
  //     if (element.isDraft == true) {
  //       let deadline = new Date(element.deadLine);
  //       let current_date = new Date();

  //       let creationDate = new Date(element.creationTime)
  //       if (self.branchId === element.personResponsilbeBranchName || self.branchId === "All") {
  //         let wDate = new Date(self.WorkSourceDate);
  //         console.log(creationDate.getMonth(), wDate.getMonth(), creationDate.getFullYear(), wDate.getFullYear(), creationDate.getMonth() === wDate.getMonth() && creationDate.getFullYear() === wDate.getFullYear())

  //         if (!self.WorkSourceDate) {
  //           if (element.workSourceId) {
  //             workSrc[element.workSourceId].value += 1
  //           }
  //         } else {
  //           let wDate = new Date(self.WorkSourceDate);
  //           if (creationDate.getMonth() === wDate.getMonth() && creationDate.getFullYear() === wDate.getFullYear()) {
  //             if (element.workSourceId) {
  //               workSrc[element.workSourceId].value += 1
  //             }
  //           }
  //         }
  //       }
  //       if (element.visaExpiry && element.caseStatus >= 2) {
  //         if (self.branchId === element.personResponsilbeBranchName || self.branchId === "All") {
  //           self.brp.push({ "Name": element.clientName, "Date": element.visaExpiry })
  //           if (!self.visaDate) {
  //             Granted += 1;
  //             element['grantOrReject']="Granted";
  //             GrantedFiles.push(element);
  //           } else {
  //             let vDate = new Date(self.visaDate);

  //             let vDateMonth = vDate.getMonth();
  //             let vDateYear = vDate.getFullYear();
  //             let cMonth = creationDate.getMonth();
  //             let cYear = creationDate.getFullYear();
  //             if (cMonth === vDateMonth && vDateYear === cYear) {
  //               Granted += 1;
  //               element['grantOrReject']="Granted";
  //               GrantedFiles.push(element);
  //             }
  //           }
  //         }
  //       } else {
  //         if (element.caseStatus >= 2) {
  //           if (self.branchId === element.personResponsilbeBranchName || self.branchId === "All") {
  //             if (!self.visaDate) {
  //               refused += 1;
  //               element['grantOrReject']='Refused'
  //               refusedFiles.push(element);
  //             } else {
  //               let vDate = new Date(self.visaDate);
  //               let vDateMonth = vDate.getMonth();
  //               let vDateYear = vDate.getFullYear();
  //               let cMonth = creationDate.getMonth();
  //               let cYear = creationDate.getFullYear();
  //               if (cMonth === vDateMonth && vDateYear === cYear) {
  //                 refused += 1;
  //               element['grantOrReject']='Refused'

  //               refusedFiles.push(element);

  //               }
  //             }
  //           }
  //         }
  //       }
  //       var p = self.checkPermission('Dashboard', 'level', self.branchId);
  //       var pcheck = false;
  //       var arr = [2];
  //       p.forEach(function (el) {
  //         if (arr.indexOf(parseInt(el)) > -1) {
  //           pcheck = true;
  //           return;
  //         }
  //       });
  //       var Acheck = false;
  //       var narr = [4];
  //       p.forEach(function (el) {
  //         if (narr.indexOf(parseInt(el)) > -1) {
  //           Acheck = true;
  //           return;
  //         }
  //       });
  //       var caseWorkersId = [];
  //       if ((element.personResposibleId == self.user.Id) || (self.user.RoleId == 1 || pcheck || Acheck)) {
  //         let disInCheck = false;
  //         let disInTotal =0;
  //         let disOutCheck = false;
  //         let disOutTotal =0;



  //         element.caseWorkerList.forEach(element1 => {
  //           if (element1.branchName === self.branchId || self.branchId === "All") {


  //             var keys = Object.keys(LateFiles)
  //             var index = keys.indexOf(element1.userId_id.toString()) < 0
  //             if (deadline < current_date) {
  //               if (caseIds.indexOf(element.Id) < 0 && index) {
  //                 LateFiles[element1.userId_id] = { Files: 0, Name: element1.CaseWorkerName };
  //               }
  //             }
  //             // if (index) {
  //             //   // LateFiles[element1.userId_id].Files += 1;
  //             // } else {
  //             // if (caseIds.indexOf(element1.caseId_id) < 0) {
  //             // var caseWorkers = element.caseWorkerList.map(x => x.userId_id)

  //             // caseWorkers.forEach(item => {
  //             //   if (caseWorkersId.indexOf(item) < 0) {
  //             //     if (deadline > current_date) {
  //             //       LateFiles[item] = { Files: 0, Name: element1.CaseWorkerName };
  //             //     } else {
  //             //       LateFiles[item] = { Files: 0, Name: element1.CaseWorkerName };
  //             //     }
  //             //
  //             //   }
  //             // });
  //             // caseWorkersId.push(element1.userId_id)
  //             caseIds.push(element1.caseId_id)
  //             // }
  //             // }
  //             if(!pendingPaymentsByUsers.hasOwnProperty(element1.userId_id)){
  //             pendingPaymentsByUsers[element1.userId_id] = { value: 0, Name: element1.CaseWorkerName , files:[] };

  //             }

  //             if (current_date < deadline && element.caseStatus === 0) {
  //               this.Deadlines.push({"Id":element.Id, "Name": element1.CaseWorkerName,"profilePic":element1.profilePic, "Date": deadline, "ClientName": element.clientName, "MatterName": element.matterName, 'FileNumber': element.fileNumber });

  //             }
  //           }
  //         });
  //         // var caseWorkersId = [];
  //         // element.caseWorkerList.forEach(element1 => {

  //         //   if (element1.branchName === self.branchId) {

  //         //     if (deadline > current_date) {
  //         //       // if (caseWorkersId.indexOf(element1.userId_id) === -1) {
  //         //       // if (caseIds.indexOf(element1.caseId_id) < 0) {
  //         //       var caseWorkers = element.caseWorkerList.map(x => x.userId_id)

  //         //       caseWorkers.forEach(item => {
  //         //         if (caseWorkersId.indexOf(item) < 0) {
  //         //           LateFiles[item].Files += 1;
  //         //           caseWorkersId.push(item)
  //         //         }

  //         //       });
  //         //       // caseIds.push(element1.caseId_id)
  //         //       // }
  //         //       // caseWorkersId.push(element1.userId_id);
  //         //       // }
  //         //     }
  //         //   } else if (self.branchId === "All") {
  //         //     if (deadline < current_date) {
  //         //       // if (caseWorkersId.indexOf(element1.userId_id) === -1) {
  //         //       // if (caseIds.indexOf(element1.caseId_id) < 0) {
  //         //       var caseWorkers = element.caseWorkerList.map(x => x.userId_id)

  //         //       caseWorkers.forEach(item => {
  //         //         if (caseWorkersId.indexOf(item) < 0) {
  //         //           LateFiles[item].Files += 1;
  //         //           caseWorkersId.push(item)
  //         //         }

  //         //       });
  //         //       //   caseIds.push(element1.caseId_id)
  //         //       // }
  //         //       // caseWorkersId.push(element1.userId_id);
  //         //       // }
  //         //     }
  //         //   }
  //         // });
  //         if (self.branchId === element.personResponsilbeBranchName) {
  //           var index = matterIds.indexOf(element.matterId);
  //           if (index > -1 && !Acheck) {
  //             if (element.caseStatus != 3) {
  //               matterList[element.matterId].value += 1;
  //               matterList[element.matterId].name = element.matterName;
  //               matterList[element.matterId].files.push(element)
  //             }
  //             if (current_date > deadline && element.caseStatus === 0) {
  //               LateMatter[element.matterId].value += 1;
  //               LateMatter[element.matterId].name = element.matterName;
  //               LateMatter[element.matterId].files.push(element)
  //             }
  //           }

  //           if (current_date > deadline && element.caseStatus === 0) {
  //             self.lateFiles += 1;
  //             var caseWorkers = element.caseWorkerList.map(x => x.userId_id)
  //             var wIndex = 0
  //             console.log(caseIds);
  //             caseWorkers.forEach(item => {
  //               var keys = Object.keys(LateFiles)
  //               var index = keys.indexOf(item.toString()) > -1


  //               if (caseWorkersId.indexOf(item) < 0) {
  //                 if (index) {
  //                   LateFiles[item].Files += 1;
  //                 }
  //                 // else {
  //                 //   if (current_date > deadline) {
  //                 //     LateFiles[item] = { Files: 1, Name: element.caseWorkerList[wIndex].CaseWorkerName };

  //                 //   }
  //                 //   //  else {
  //                 //   // LateFiles[item] = { Files: 1, Name: element.caseWorkerList[wIndex].CaseWorkerName };
  //                 //   // }
  //                 // }
  //                 caseWorkersId.push(item)



  //               }
  //               console.log(LateFiles, "lateFiles")
  //               wIndex += 1
  //             });
  //             if ((element.personResposibleId == self.user.Id) || (self.user.RoleId == 1) || (!pcheck && !Acheck)) {
  //               this.lateTodo.push({ "Id": element.Id, "Text": element.matterName, "ItemId": element.fileNumber, "type": 1, "Date": element.deadLine });
  //             }
  //             self.ActiveFiles.push(element);
  //             self.ActiveCases += 1;
  //             this.lateCases.push(element);
  //           }
  //           if (current_date < deadline && element.caseStatus == 0) {
  //             self.ActiveCases += 1;
  //             self.ActiveFiles.push(element);
  //             if ((element.personResposibleId == self.user.Id) || (self.user.RoleId == 1) || (!pcheck && !Acheck)) {
  //               this.CurrentTodo.push({ "Id": element.Id, "Text": element.matterName, "ItemId": element.fileNumber, "type": 1, "Date": element.deadLine });
  //             }
  //           }
  //           else if (element.caseStatus == 1) {
  //             if (current_date < deadline) {
  //               if ((element.personResposibleId == self.user.Id) || (self.user.RoleId == 1) || (!pcheck && !Acheck)) {
  //                 this.CurrentTodo.push({ "Id": element.Id, "Text": element.matterName, "ItemId": element.fileNumber, "type": 1, "Date": element.deadLine });
  //               }
  //             }
  //             self.AwaitingCases += 1
  //             self.AwaitingFiles.push(element);

  //           }

  //           else if (element.caseStatus == 2) {
  //             if (current_date < deadline) {
  //               if ((element.personResposibleId == self.user.Id) || (self.user.RoleId == 1) || (!pcheck && !Acheck)) {
  //                 this.CurrentTodo.push({ "Id": element.Id, "Text": element.matterName, "ItemId": element.fileNumber, "type": 1, "Date": element.deadLine });
  //               }
  //             }
  //             self.inCompletedCases += 1;
  //             self.awaitingCompletion.push(element);

  //           }

  //           var caseWorkersId = [];
  //           element.caseWorkerList.forEach(element1 => {
  //             if (element1.status === 1) {
  //               self.pendingApprovals += 1;
  //               self.PendingApprovalFiles.push(element);
  //               return;
  //             }

  //           });
  //         } else if (self.branchId === "All") {
  //           var index = matterIds.indexOf(element.matterId);
  //           if (index > -1 && !Acheck) {
  //             if (element.caseStatus != 3) {
  //               matterList[element.matterId].value += 1;
  //               matterList[element.matterId].name = element.matterName;
  //               matterList[element.matterId].files.push(element)


  //             }
  //             if (current_date > deadline && element.caseStatus === 0) {
  //               LateMatter[element.matterId].value += 1;
  //               LateMatter[element.matterId].name = element.matterName;
  //               LateMatter[element.matterId].files.push(element)

  //             }
  //           }

  //           if (current_date > deadline && element.caseStatus === 0) {
  //             self.lateFiles += 1;
  //             var caseWorkers = element.caseWorkerList.map(x => x.userId_id)
  //             var wIndex = 0
  //             console.log(caseIds);
  //             caseWorkers.forEach(item => {
  //               var keys = Object.keys(LateFiles)
  //               var index = keys.indexOf(item.toString()) > -1


  //               if (caseWorkersId.indexOf(item) < 0) {
  //                 if (index) {
  //                   LateFiles[item].Files += 1;
  //                   console.log(LateFiles[item].Files, LateFiles[item].Name, element.Id, "lateFiles")
  //                 }
  //                 // else {
  //                 //   if (current_date > deadline) {
  //                 //     LateFiles[item] = { Files: 1, Name: element.caseWorkerList[wIndex].CaseWorkerName };

  //                 //   }
  //                 //   //  else {
  //                 //   // LateFiles[item] = { Files: 1, Name: element.caseWorkerList[wIndex].CaseWorkerName };
  //                 //   // }
  //                 // }
  //                 caseWorkersId.push(item)



  //               }

  //               wIndex += 1
  //             });
  //             self.ActiveFiles.push(element);
  //             self.ActiveCases += 1;
  //             if ((element.personResposibleId == self.user.Id) || (self.user.RoleId == 1) || (!pcheck && !Acheck)) {
  //               this.lateTodo.push({ "Id": element.Id, "Text": element.matterName, "ItemId": element.fileNumber, "type": 1, "Date": element.deadLine });
  //             }
  //             this.lateCases.push(element);
  //           }
  //           if (current_date < deadline && element.caseStatus == 0) {
  //             self.ActiveCases += 1;
  //             if ((element.personResposibleId == self.user.Id) || (self.user.RoleId == 1) || (!pcheck && !Acheck)) {
  //               this.CurrentTodo.push({ "Id": element.Id, "Text": element.matterName, "ItemId": element.fileNumber, "type": 1, "Date": element.deadLine });
  //             }
  //             self.ActiveFiles.push(element);
  //           } else if (element.caseStatus == 1) {
  //             if (current_date < deadline) {
  //               if ((element.personResposibleId == self.user.Id) || (self.user.RoleId == 1) || (!pcheck && !Acheck)) {
  //                 this.CurrentTodo.push({ "Id": element.Id, "Text": element.matterName, "ItemId": element.fileNumber, "type": 1, "Date": element.deadLine });
  //               }
  //             }
  //             self.AwaitingCases += 1;

  //             self.AwaitingFiles.push(element);
  //           }
  //           else if (element.caseStatus == 2) {
  //             if (current_date < deadline) {
  //               if ((element.personResposibleId == self.user.Id) || (self.user.RoleId == 1) || (!pcheck && !Acheck)) {
  //                 this.CurrentTodo.push({ "Id": element.Id, "Text": element.matterName, "ItemId": element.fileNumber, "type": 1, "Date": element.deadLine });
  //               }
  //             }
  //             self.inCompletedCases += 1;
  //             self.awaitingCompletion.push(element);

  //           }
  //           element.caseWorkerList.forEach(element1 => {
  //             if (element1.status === 1) {
  //               self.pendingApprovals += 1;
  //               self.PendingApprovalFiles.push(element);
  //               return;
  //             }
  //           });
  //         }
  //         let invoiceCheck= false;
  //         let invoiceBal = 0;
  //         element['caseInvoice'].forEach(invoice => {
  //           if (self.branchId === "All") {
  //             if(invoice.isDraft && invoice.amountPaid){
  //               self.agreedCost += parseFloat(invoice.amountPaid);
  //               invoiceBal+=parseFloat(invoice.amountPaid);
  //               invoiceCheck= true;
  //             }

  //             // self.agreedCost += parseFloat(invoice.agreedCost);
  //           } else {
  //             if (self.branchId === element.personResponsilbeBranchName) {
  //               if(invoice.isDraft && invoice.amountPaid){
  //                 self.agreedCost += parseFloat(invoice.amountPaid);
  //                 invoiceBal+=parseFloat(invoice.amountPaid);
  //               invoiceCheck= true;
  //               }
  //               // self.agreedCost += parseFloat(invoice.agreedCost);
  //             }
  //           }

  //         });
  //         if(invoiceCheck){
  //           if(invoiceBal>0){
  //             element['invoiceBal']=invoiceBal;
  //             self.draftBalanceFiles.push(element);

  //           }
  //         }
  //         let outStandCheck=false;
  //         let activeBalanceCheck=false;
  //         let outBalance = 0;
  //         let activeBalance = 0;
  //         element['officeInvoice'].forEach(function (r) {

  //           var i = 0;
  //           let totalAmount = Number(r['amount'])
  //            let balance = totalAmount;
  //           r['officeAccount'].forEach(element1 => {
  //             if (element1['isInPayment'] && !element1['isDeleted']) {
  //               let amount = Number(element1['amount'])
  //               balance = balance - amount;

  //             }
  //           });
  //           r['balance'] = balance;
  //           if(r['bankAccount']==self.branchId){
  //             if (r.typeOfPayment === "Legal FEE" && (element.caseStatus == 0 || element.caseStatus == 1) && !Acheck) {
  //               self.outStandingBalance += parseFloat(r['balance']);
  //               outBalance+= parseFloat(r['balance']);
  //               outStandCheck= true;

  //             }
  //             if (r.typeOfPayment === "Legal FEE" && (element.caseStatus == 0) && !Acheck) {
  //               self.ActiveBalance += parseFloat(r['balance']);
  //               activeBalance +=parseFloat(r['balance']);
  //               activeBalanceCheck= true;
  //             }
  //           }
  //           else if(self.branchId === "All"){
  //             if (r.typeOfPayment === "Legal FEE" && (element.caseStatus == 0 || element.caseStatus == 1) && !Acheck) {
  //               self.outStandingBalance += parseFloat(r['balance']);
  //               outBalance+= parseFloat(r['balance']);
  //               outStandCheck= true;
  //             }
  //             if (r.typeOfPayment === "Legal FEE" && (element.caseStatus == 0) && !Acheck) {
  //               self.ActiveBalance += parseFloat(r['balance']);
  //               activeBalance +=parseFloat(r['balance']);
  //               activeBalanceCheck= true;
  //             }
  //           }
  //           // if()
  //           r['officeAccount'].forEach(office => {
  //             var account = 0;
  //             if (office.isInPayment) {
  //               let latest_date = new Date(office.entryDate);
  //               let date = new Date();
  //               var current_Month = (date.getMonth() - 1);
  //               var last_Month = latest_date.getMonth();

  //               if (office.bankAccount === "Manchester" && self.branchId == "Manchester" && office.typeOfPayment === "Legal FEE") {
  //                 if (last_Month === current_Month + 1 && latest_date.getFullYear() === date.getFullYear() && office.typeOfPayment === "Legal FEE") {
  //                   self.manchesterCurrent += parseFloat(office.amount);
  //                   console.log('This Month Sale ', element.Id, ' amount: ', office.amount)
  //                   var amount = parseFloat(office.amount);
  //                   var vat = amount;
  //                   if (element.vatStatus) {
  //                     vat = (amount * 0.8);
  //                   }
  //                   var data = {
  //                     "Case Id": element.Id, "FileNumber": element.fileNumber,
  //                     "ClientName": element.clientName,
  //                     "paymentIn": office.entryDate,
  //                     "Branch": office.bankAccount,
  //                     "AmountIn": amount,
  //                     "VAT": vat
  //                   }
  //                   self.thisMonthBalance.push(data);

  //                 }
  //                 else if (last_Month === current_Month && latest_date.getFullYear() === date.getFullYear()) {
  //                   self.manchesterPrev += parseFloat(office.amount);
  //                   console.log('Prev Month Sale ', element.Id, ' amount: ', office.amount)
  //                   var amount = parseFloat(office.amount);
  //                   var vat = amount;
  //                   if (element.vatStatus) {
  //                     vat = (amount * 0.8);
  //                   }
  //                   var data = {
  //                     "Case Id": element.Id, "FileNumber": element.fileNumber,
  //                     "ClientName": element.clientName,
  //                     "paymentIn": office.entryDate,
  //                     "Branch": office.bankAccount,
  //                     "AmountIn": amount,
  //                     "VAT": vat
  //                   }
  //                   self.prevMonthBalance.push(data);
  //                 }
  //                 if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0 || element.caseStatus == 1)) {
  //                   self.manchesterOutstanding += parseFloat(office.amount);
  //                   var amount = parseFloat(office.amount);
  //                   var vat = amount;
  //                   if (element.vatStatus) {
  //                     vat = (amount * 0.8);
  //                   }
  //                   var data = {
  //                     "Case Id": element.Id, "FileNumber": element.fileNumber,
  //                     "ClientName": element.clientName,
  //                     "paymentIn": office.entryDate,
  //                     "Branch": office.bankAccount,
  //                     "AmountIn": amount,
  //                     "VAT": vat
  //                   }
  //                   self.OutStandingBalance.push(data);
  //                 }
  //               } else if (office.bankAccount === "Chester" && self.branchId == "Chester" && office.typeOfPayment === "Legal FEE") {
  //                 if (last_Month === current_Month + 1 && latest_date.getFullYear() === date.getFullYear()) {
  //                   self.chesterCurrent += parseFloat(office.amount); var amount = parseFloat(office.amount);
  //                   console.log('OutStanding Balance ', element.Id, ' amount: ', office.amount)
  //                   var vat = amount;
  //                   if (element.vatStatus) {
  //                     vat = (amount * 0.8);
  //                   }
  //                   var data = {
  //                     "Case Id": element.Id, "FileNumber": element.fileNumber,
  //                     "ClientName": element.clientName,
  //                     "paymentIn": office.entryDate,
  //                     "Branch": office.bankAccount,
  //                     "AmountIn": amount,
  //                     "VAT": vat
  //                   }
  //                   self.thisMonthBalance.push(data);

  //                 }
  //                 else if (last_Month === current_Month && latest_date.getFullYear() === date.getFullYear()) {
  //                   self.chesterPrev += parseFloat(office.amount); var amount = parseFloat(office.amount);
  //                   var vat = amount;
  //                   if (element.vatStatus) {
  //                     vat = (amount * 0.8);
  //                   }
  //                   var data = {
  //                     "Case Id": element.Id, "FileNumber": element.fileNumber,
  //                     "ClientName": element.clientName,
  //                     "paymentIn": office.entryDate,
  //                     "Branch": office.bankAccount,
  //                     "AmountIn": amount,
  //                     "VAT": vat
  //                   }
  //                   self.prevMonthBalance.push(data);
  //                 }
  //                 if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0 || element.caseStatus == 1)) {
  //                   self.chesterOutstanding += parseFloat(office.amount);



  //                   var amount = parseFloat(office.amount);
  //                   var vat = amount;
  //                   if (element.vatStatus) {
  //                     vat = (amount * 0.8);
  //                   }
  //                   var data = {
  //                     "Case Id": element.Id, "FileNumber": element.fileNumber,
  //                     "ClientName": element.clientName,
  //                     "paymentIn": office.entryDate,
  //                     "Branch": office.bankAccount,
  //                     "AmountIn": amount,
  //                     "VAT": vat
  //                   }
  //                   self.OutStandingBalance.push(data);
  //                 }
  //               }
  //               else if (office.bankAccount === "London" && self.branchId == "London" && office.typeOfPayment === "Legal FEE") {
  //                 if (last_Month === current_Month + 1 && latest_date.getFullYear() === date.getFullYear()) {
  //                   self.londonCurrent += parseFloat(office.amount); var amount = parseFloat(office.amount);
  //                   var vat = amount;
  //                   if (element.vatStatus) {
  //                     vat = (amount * 0.8);
  //                   }
  //                   var data = {
  //                     "Case Id": element.Id, "FileNumber": element.fileNumber,
  //                     "ClientName": element.clientName,
  //                     "paymentIn": office.entryDate,
  //                     "Branch": office.bankAccount,
  //                     "AmountIn": amount,
  //                     "VAT": vat
  //                   }
  //                   self.thisMonthBalance.push(data);
  //                 }
  //                 else if (last_Month === current_Month && latest_date.getFullYear() === date.getFullYear()) {
  //                   self.londonPrev += parseFloat(office.amount); var amount = parseFloat(office.amount);
  //                   var vat = amount;
  //                   if (element.vatStatus) {
  //                     vat = (amount * 0.8);
  //                   }
  //                   var data = {
  //                     "Case Id": element.Id, "FileNumber": element.fileNumber,
  //                     "ClientName": element.clientName,
  //                     "paymentIn": office.entryDate,
  //                     "Branch": office.bankAccount,
  //                     "AmountIn": amount,
  //                     "VAT": vat
  //                   }
  //                   self.prevMonthBalance.push(data);
  //                 }
  //                 if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0 || element.caseStatus == 1)) {
  //                   self.londonOutstanding += parseFloat(office.amount);


  //                   var amount = parseFloat(office.amount);
  //                   var vat = amount;
  //                   if (element.vatStatus) {
  //                     vat = (amount * 0.8);
  //                   }
  //                   var data = {
  //                     "Case Id": element.Id, "FileNumber": element.fileNumber,
  //                     "ClientName": element.clientName,
  //                     "paymentIn": office.entryDate,
  //                     "Branch": office.bankAccount,
  //                     "AmountIn": amount,
  //                     "VAT": vat
  //                   }
  //                   self.OutStandingBalance.push(data);
  //                 }
  //               }
  //               else if (office.bankAccount === "Birmingham" && self.branchId == "Birmingham" && office.typeOfPayment === "Legal FEE") {
  //                 if (last_Month === current_Month + 1 && latest_date.getFullYear() === date.getFullYear()) {
  //                   self.birminghamCurrent += parseFloat(office.amount);
  //                   var amount = parseFloat(office.amount);
  //                   var vat = amount;
  //                   if (element.vatStatus) {
  //                     vat = (amount * 0.8);
  //                   }
  //                   var data = {
  //                     "Case Id": element.Id, "FileNumber": element.fileNumber,
  //                     "ClientName": element.clientName,
  //                     "paymentIn": office.entryDate,
  //                     "Branch": office.bankAccount,
  //                     "AmountIn": amount,
  //                     "VAT": vat
  //                   }
  //                   self.thisMonthBalance.push(data);
  //                 } else if (last_Month === current_Month && latest_date.getFullYear() === date.getFullYear()) {
  //                   self.birminghamPrev += parseFloat(office.amount); var amount = parseFloat(office.amount);
  //                   var vat = amount;
  //                   if (element.vatStatus) {
  //                     vat = (amount * 0.8);
  //                   }
  //                   var data = {
  //                     "Case Id": element.Id, "FileNumber": element.fileNumber,
  //                     "ClientName": element.clientName,
  //                     "paymentIn": office.entryDate,
  //                     "Branch": office.bankAccount,
  //                     "AmountIn": amount,
  //                     "VAT": vat
  //                   }
  //                   self.prevMonthBalance.push(data);
  //                 }
  //                 if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0 || element.caseStatus == 1)) {
  //                   self.birminghamOutstanding += parseFloat(office.amount);

  //                   var amount = parseFloat(office.amount);
  //                   var vat = amount;
  //                   if (element.vatStatus) {
  //                     vat = (amount * 0.8);
  //                   }
  //                   var data = {
  //                     "Case Id": element.Id, "FileNumber": element.fileNumber,
  //                     "ClientName": element.clientName,
  //                     "paymentIn": office.entryDate,
  //                     "Branch": office.bankAccount,
  //                     "AmountIn": amount,
  //                     "VAT": vat
  //                   }
  //                   self.OutStandingBalance.push(data);
  //                 }
  //               }
  //               if (office.bankAccount == self.branchId) {

  //                 // if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0 || element.caseStatus == 1) && !Acheck) {
  //                 //   self.outStandingBalance += parseFloat(office.amount);

  //                 // }
  //                 // if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0) && !Acheck) {
  //                 //   self.ActiveBalance += parseFloat(office.amount);
  //                 // }
  //                 if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 1) && !Acheck) {
  //                   self.AwaitingBalance += parseFloat(office.amount);
  //                 }
  //                 if (office.typeOfPayment === "Disbursement") {
  //                   if(office.isInPayment){
  //                     self.disIn += parseFloat(office.amount);
  //                     disInCheck= true;
  //                     disInTotal+=parseFloat(office.amount);
  //                   }
  //                   else{
  //                     self.disOut +=parseFloat(office.amount);
  //                     disOutCheck= true;
  //                     disOutTotal+=parseFloat(office.amount);
  //                   }

  //                 } if (office.typeOfPayment == "Legal FEE" && !Acheck) {
  //                   // self.disOut +=parseFloat(office.amount);
  //                   account += parseFloat(office.amount);
  //                   if (r['officeAccount'].length - 1 === i) {
  //                     var total = parseFloat(r.amount) - account;
  //                     self.pendingBalance += total;
  //                   }
  //                 }
  //               }
  //               else if (self.branchId === "All") {
  //                 if (office.bankAccount === "Manchester" && office.typeOfPayment === "Legal FEE") {
  //                   if (last_Month === current_Month + 1 && latest_date.getFullYear() === date.getFullYear()) {
  //                     self.manchesterCurrent += parseFloat(office.amount);
  //                     var amount = parseFloat(office.amount);
  //                     var vat = amount;
  //                     if (element.vatStatus) {
  //                       vat = (amount * 0.8);
  //                     }
  //                     var data = {
  //                       "Case Id": element.Id, "FileNumber": element.fileNumber,
  //                       "ClientName": element.clientName,
  //                       "paymentIn": office.entryDate,
  //                       "Branch": office.bankAccount,
  //                       "AmountIn": amount,
  //                       "VAT": vat
  //                     }
  //                     self.thisMonthBalance.push(data);
  //                   }
  //                   else if (last_Month === current_Month && latest_date.getFullYear() === date.getFullYear()) {
  //                     self.manchesterPrev += parseFloat(office.amount);
  //                     var amount = parseFloat(office.amount);
  //                     var vat = amount;
  //                     if (element.vatStatus) {
  //                       vat = (amount * 0.8);
  //                     }
  //                     var data = {
  //                       "Case Id": element.Id, "FileNumber": element.fileNumber,
  //                       "ClientName": element.clientName,
  //                       "paymentIn": office.entryDate,
  //                       "Branch": office.bankAccount,
  //                       "AmountIn": amount,
  //                       "VAT": vat
  //                     }
  //                     self.prevMonthBalance.push(data);
  //                   }
  //                   if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0 || element.caseStatus == 1)) {
  //                     self.manchesterOutstanding += parseFloat(office.amount);
  //                     var amount = parseFloat(office.amount);
  //                     var vat = amount;
  //                     if (element.vatStatus) {
  //                       vat = (amount * 0.8);
  //                     }
  //                     var data = {
  //                       "Case Id": element.Id, "FileNumber": element.fileNumber,
  //                       "ClientName": element.clientName,
  //                       "paymentIn": office.entryDate,
  //                       "Branch": office.bankAccount,
  //                       "AmountIn": amount,
  //                       "VAT": vat
  //                     }
  //                     self.OutStandingBalance.push(data);
  //                   }
  //                 } else if (office.bankAccount === "Chester" && office.typeOfPayment === "Legal FEE") {
  //                   if (last_Month === current_Month + 1 && latest_date.getFullYear() === date.getFullYear()) {
  //                     self.chesterCurrent += parseFloat(office.amount);
  //                     var amount = parseFloat(office.amount);
  //                     var vat = amount;
  //                     if (element.vatStatus) {
  //                       vat = (amount * 0.8);
  //                     }
  //                     var data = {
  //                       "Case Id": element.Id, "FileNumber": element.fileNumber,
  //                       "ClientName": element.clientName,
  //                       "paymentIn": office.entryDate,
  //                       "Branch": office.bankAccount,
  //                       "AmountIn": amount,
  //                       "VAT": vat
  //                     }
  //                     self.thisMonthBalance.push(data);
  //                   }
  //                   else if (last_Month === current_Month && latest_date.getFullYear() === date.getFullYear()) {
  //                     self.chesterPrev += parseFloat(office.amount);
  //                     var amount = parseFloat(office.amount);
  //                     var vat = amount;
  //                     if (element.vatStatus) {
  //                       vat = (amount * 0.8);
  //                     }
  //                     var data = {
  //                       "Case Id": element.Id, "FileNumber": element.fileNumber,
  //                       "ClientName": element.clientName,
  //                       "paymentIn": office.entryDate,
  //                       "Branch": office.bankAccount,
  //                       "AmountIn": amount,
  //                       "VAT": vat
  //                     }
  //                     self.prevMonthBalance.push(data);
  //                   }
  //                   if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0 || element.caseStatus == 1)) {
  //                     self.chesterOutstanding += parseFloat(office.amount);
  //                     var amount = parseFloat(office.amount);
  //                     var vat = amount;
  //                     if (element.vatStatus) {
  //                       vat = (amount * 0.8);
  //                     }
  //                     var data = {
  //                       "Case Id": element.Id, "FileNumber": element.fileNumber,
  //                       "ClientName": element.clientName,
  //                       "paymentIn": office.entryDate,
  //                       "Branch": office.bankAccount,
  //                       "AmountIn": amount,
  //                       "VAT": vat
  //                     }
  //                     self.OutStandingBalance.push(data);
  //                   }
  //                 }
  //                 else if (office.bankAccount === "London" && office.typeOfPayment === "Legal FEE") {
  //                   if (last_Month === current_Month + 1 && latest_date.getFullYear() === date.getFullYear()) {
  //                     self.londonCurrent += parseFloat(office.amount);
  //                     var amount = parseFloat(office.amount);
  //                     var vat = amount;
  //                     if (element.vatStatus) {
  //                       vat = (amount * 0.8);
  //                     }
  //                     var data = {
  //                       "Case Id": element.Id, "FileNumber": element.fileNumber,
  //                       "ClientName": element.clientName,
  //                       "paymentIn": office.entryDate,
  //                       "Branch": office.bankAccount,
  //                       "AmountIn": amount,
  //                       "VAT": vat
  //                     }
  //                     self.thisMonthBalance.push(data);
  //                   }
  //                   else if (last_Month === current_Month && latest_date.getFullYear() === date.getFullYear()) {
  //                     self.londonPrev += parseFloat(office.amount);
  //                     var amount = parseFloat(office.amount);
  //                     var vat = amount;
  //                     if (element.vatStatus) {
  //                       vat = (amount * 0.8);
  //                     }
  //                     var data = {
  //                       "Case Id": element.Id, "FileNumber": element.fileNumber,
  //                       "ClientName": element.clientName,
  //                       "paymentIn": office.entryDate,
  //                       "Branch": office.bankAccount,
  //                       "AmountIn": amount,
  //                       "VAT": vat
  //                     }
  //                     self.prevMonthBalance.push(data);
  //                   }
  //                   if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0 || element.caseStatus == 1)) {
  //                     self.londonOutstanding += parseFloat(office.amount);
  //                     var amount = parseFloat(office.amount);
  //                     var vat = amount;
  //                     if (element.vatStatus) {
  //                       vat = (amount * 0.8);
  //                     }
  //                     var data = {
  //                       "Case Id": element.Id, "FileNumber": element.fileNumber,
  //                       "ClientName": element.clientName,
  //                       "paymentIn": office.entryDate,
  //                       "Branch": office.bankAccount,
  //                       "AmountIn": amount,
  //                       "VAT": vat
  //                     }
  //                     self.OutStandingBalance.push(data);
  //                   }
  //                 }
  //                 else if (office.bankAccount === "Birmingham" && office.typeOfPayment === "Legal FEE") {
  //                   if (last_Month === current_Month + 1 && latest_date.getFullYear() === date.getFullYear()) {
  //                     self.birminghamCurrent += parseFloat(office.amount);
  //                     var amount = parseFloat(office.amount);
  //                     var vat = amount;
  //                     if (element.vatStatus) {
  //                       vat = (amount * 0.8);
  //                     }
  //                     var data = {
  //                       "Case Id": element.Id, "FileNumber": element.fileNumber,
  //                       "ClientName": element.clientName,
  //                       "paymentIn": office.entryDate,
  //                       "Branch": office.bankAccount,
  //                       "AmountIn": amount,
  //                       "VAT": vat
  //                     }
  //                     self.thisMonthBalance.push(data);
  //                   } else if (last_Month === current_Month && latest_date.getFullYear() === date.getFullYear()) {
  //                     self.birminghamPrev += parseFloat(office.amount);
  //                     var amount = parseFloat(office.amount);
  //                     var vat = amount;
  //                     if (element.vatStatus) {
  //                       vat = (amount * 0.8);
  //                     }
  //                     var data = {
  //                       "Case Id": element.Id, "FileNumber": element.fileNumber,
  //                       "ClientName": element.clientName,
  //                       "paymentIn": office.entryDate,
  //                       "Branch": office.bankAccount,
  //                       "AmountIn": amount,
  //                       "VAT": vat
  //                     }
  //                     self.prevMonthBalance.push(data);
  //                   }
  //                   if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0 || element.caseStatus == 1)) {
  //                     self.birminghamOutstanding += parseFloat(office.amount);
  //                     var amount = parseFloat(office.amount);
  //                     var vat = amount;
  //                     if (element.vatStatus) {
  //                       vat = (amount * 0.8);
  //                     }
  //                     var data = {
  //                       "Case Id": element.Id, "FileNumber": element.fileNumber,
  //                       "ClientName": element.clientName,
  //                       "paymentIn": office.entryDate,
  //                       "Branch": office.bankAccount,
  //                       "AmountIn": amount,
  //                       "VAT": vat
  //                     }
  //                     self.OutStandingBalance.push(data);
  //                   }
  //                 }
  //                 // if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0 || element.caseStatus == 1) && !Acheck) {
  //                 //   self.outStandingBalance += parseFloat(office.amount);
  //                 // }
  //                 // if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0) && !Acheck) {
  //                 //   self.ActiveBalance += parseFloat(office.amount);
  //                 // }
  //                 if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 1) && !Acheck) {
  //                   self.AwaitingBalance += parseFloat(office.amount);
  //                 }
  //                 if (office.typeOfPayment === "Disbursement") {
  //                   if(office.isInPayment){
  //                     self.disIn += parseFloat(office.amount);
  //                     disInCheck= true;
  //                     disInTotal+=parseFloat(office.amount);
  //                   }
  //                   else{
  //                     self.disOut +=parseFloat(office.amount);
  //                     disOutCheck= true;
  //                     disOutTotal+=parseFloat(office.amount);
  //                   }


  //                 } if (office.typeOfPayment == "Legal FEE" && !Acheck) {
  //                   // self.disOut +=parseFloat(office.amount);
  //                   account += parseFloat(office.amount);
  //                   if (r['officeAccount'].length - 1 === i) {
  //                     var total = parseFloat(r.amount) - account;
  //                     self.pendingBalance += total;

  //                   }
  //                 }

  //               }
  //             }
  //             else {
  //               if (office.typeOfPayment === "Disbursement") {
  //                 self.disOut += parseFloat(office.amount);
  //                 disOutCheck= true;
  //                 disOutTotal+=parseFloat(office.amount);
  //               } if (office.typeOfPayment == "Legal FEE") {
  //                 // self.disOut +=parseFloat(office.amount);
  //                 account += parseFloat(office.amount);
  //                 if (r['officeAccount'].length - 1 === i) {
  //                   var total = parseFloat(r.amount) - account;
  //                   self.pendingBalance += total;
  //                 }
  //               }
  //             }


  //             i += 1;
  //           });



  //           if (r['officeAccount'].length == 0) {
  //             if (self.branchId === "All") {
  //               self.pendingBalance += parseFloat(r.amount);
  //             }
  //             else if (element.personResponsilbeBranchName === self.branchId) {
  //               self.pendingBalance += parseFloat(r.amount);
  //             }
  //           }

  //         });
  //         if(outStandCheck){

  //           if(outBalance>0){
  //             element['outBalance']= outBalance;
  //             self.OutStandingFiles.push(element);
  //           }



  //         }

  //         if(activeBalanceCheck){
  //           if(activeBalance>0){
  //             element['activeBalance']=activeBalance;
  //             self.ActiveBalanceFiles.push(element);
  //           }

  //         }
  //         element['clientAccount'].forEach(function (client) {

  //           if(client.bankAccount == self.branchId){
  //             if(client.typeOfPayment === "Disbursement"){
  //               if(client.isInPayment){
  //                 self.disIn += parseFloat(client.amount);
  //                 disInCheck= true;
  //                     disInTotal+=parseFloat(client.amount);
  //               }
  //               else{
  //                 self.disOut += parseFloat(client.amount);
  //                 disOutCheck= true;
  //                 disOutTotal+=parseFloat(client.amount);
  //               }
  //             }
  //           }
  //           else if(self.branchId === "All"){
  //             if(client.typeOfPayment === "Disbursement"){
  //               if(client.isInPayment){
  //                 self.disIn += parseFloat(client.amount);
  //                 disInCheck= true;
  //                 disInTotal+=parseFloat(client.amount);
  //               }
  //               else{
  //                 self.disOut += parseFloat(client.amount);
  //                 disOutCheck= true;
  //                 disOutTotal+=parseFloat(client.amount);
  //               }
  //             }
  //           }
  //           // if (client.isInPayment) {
  //           //   if (client.bankAccount == self.branchId) {
  //           //     if (client.typeOfPayment === "Disbursement") {
  //           //       self.disIn += parseFloat(client.amount);
  //           //     } else {
  //           //       self.disOut += parseFloat(client.amount);
  //           //     }
  //           //   }
  //           //   else if (self.branchId === "All") {
  //           //     if (client.typeOfPayment === "Disbursement") {
  //           //       self.disIn += parseFloat(client.amount);
  //           //     } else {
  //           //       self.disOut += parseFloat(client.amount);
  //           //     }
  //           //   }
  //           // }
  //           // else {
  //           //   if (client.typeOfPayment === "Disbursement") {
  //           //     self.disOut += parseFloat(client.amount);
  //           //   }
  //           // }

  //         });
  //         if(element['outBalance']!=0){
  //           let userId =[]
  //           userId  = element.caseWorkerList.map(x=>x.userId_id);
  //           let uniqDic =[...new Set(userId)];
  //           uniqDic.forEach(el => {
  //             if (pendingPaymentsByUsers.hasOwnProperty(el)) {
  //               pendingPaymentsByUsers[el].value += parseFloat(element['outBalance']);
  //               // element['pendingBalance'] += parseFloat(self.pendingBalance);
  //               pendingPaymentsByUsers[el].files.push(element);
  //               // console.log(self.pendingBalance, pendingPaymentsByUsers[element1.userId_id].value,pendingPaymentsByUsers[element1.userId_id].Name)
  //             }
  //           });
  //         }

  //         // element.caseWorkerList.forEach(element1 => {
  //         //   if (pendingPaymentsByUsers.hasOwnProperty(element1.userId_id)) {
  //         //     pendingPaymentsByUsers[element1.userId_id].value += parseFloat(self.pendingBalance);
  //         //     element['pendingBalance'] += parseFloat(self.pendingBalance);
  //         //     pendingPaymentsByUsers[element1.userId_id].files.push(element);
  //         //     // console.log(self.pendingBalance, pendingPaymentsByUsers[element1.userId_id].value,pendingPaymentsByUsers[element1.userId_id].Name)
  //         //   }
  //         // });
  //           if(disOutCheck){
  //             if(disOutTotal>0){
  //               element['disOutTotal']=disOutTotal;
  //               self.disOutFiles.push(element);
  //             }
  //           }
  //           if(disInCheck){
  //             if(disInTotal>0){
  //               element['disInTotal']=disInTotal;
  //               self.disInFiles.push(element);
  //             }
  //           }

  //       }
  //        else {
  //         let disInCheck = false;
  //         let disInTotal =0;
  //         let disOutCheck = false;
  //         let disOutTotal =0;

  //         // let deadline = new Date(element.deadLine);
  //         // let current_date = new Date();
  //         // if(current_date>deadline && element.caseStatus===0){
  //         //   self.lateFiles+=1;
  //         // }

  //         let deadline = new Date(element.deadLine);
  //         let current_date = new Date();
  //         var caseworkerIds = element.caseWorkerList.map(x => { return x.userId_id });
  //         // console.log(caseworkerIds,"CaseWorkerIds")
  //         var workersIndex = caseworkerIds.indexOf(self.user.Id) > -1;
  //         var p = self.checkPermission('Dashboard', 'level', self.branchId);
  //         var pcheck = false;
  //         var arr = [5, 6];
  //         p.forEach(function (el) {
  //           if (arr.indexOf(parseInt(el)) > -1) {
  //             pcheck = true;
  //             return;
  //           }
  //         });

  //         element.caseWorkerList.forEach(element1 => {
  //           if (element1.branchName === self.branchId || self.branchId === "All") {
  //             var keys = Object.keys(LateFiles)
  //             var index = keys.indexOf(element1.userId_id.toString()) > -1

  //             if (index) {
  //               // LateFiles[element1.userId_id].Files += 1;
  //             } else {
  //               if (caseIds.indexOf(element1.caseId_id) < 0) {
  //                 LateFiles[element1.userId_id] = { Files: 0, Name: element1.CaseWorkerName };
  //                 caseIds.push(element1.caseId_id)
  //               }
  //             }
  //             if(!pendingPaymentsByUsers.hasOwnProperty(element1.userId_id)){
  //               pendingPaymentsByUsers[element1.userId_id] = { value: 0, Name: element1.CaseWorkerName , files:[] };

  //               }
  //             if (current_date < deadline && element.caseStatus === 0 && workersIndex && (pcheck)) {
  //               this.Deadlines.push({"Id":element.Id, "Name": element1.CaseWorkerName,"profilePic":element1.profilePic, "Date": deadline, "ClientName": element.clientName, "MatterName": element.matterName, 'FileNumber': element.fileNumber });

  //             }
  //           }
  //         });

  //         if(element['outBalance']!=0){
  //           let userId =[]
  //           userId  = element.caseWorkerList.map(x=>x.userId_id);
  //           let uniqDic =[...new Set(userId)];
  //           uniqDic.forEach(el => {
  //             if (pendingPaymentsByUsers.hasOwnProperty(el)) {
  //               pendingPaymentsByUsers[el].value += parseFloat(element['outBalance']);
  //               // element['pendingBalance'] += parseFloat(self.pendingBalance);
  //               pendingPaymentsByUsers[el].files.push(element);
  //               // console.log(self.pendingBalance, pendingPaymentsByUsers[element1.userId_id].value,pendingPaymentsByUsers[element1.userId_id].Name)
  //             }
  //           });
  //         }

  //         if (workersIndex && (pcheck)) {
  //           if (self.branchId === element.personResponsilbeBranchName) {
  //             var index = matterIds.indexOf(element.matterId);
  //             if (index > -1) {
  //               if (element.caseStatus != 3) {
  //                 matterList[element.matterId].value += 1;
  //                 matterList[element.matterId].name = element.matterName;
  //                 matterList[element.matterId].files.push(element)

  //               }

  //             }

  //             if (current_date > deadline && element.caseStatus === 0) {
  //               self.lateFiles += 1;
  //               var caseWorkers = element.caseWorkerList.map(x => x.userId_id)
  //               var wIndex = 0
  //               console.log(caseIds);
  //               caseWorkers.forEach(item => {
  //                 var keys = Object.keys(LateFiles)
  //                 var index = keys.indexOf(item.toString()) > -1


  //                 if (caseWorkersId.indexOf(item) < 0) {
  //                   if (index) {
  //                     LateFiles[item].Files += 1;
  //                   }
  //                   // else {
  //                   //   if (current_date > deadline) {
  //                   //     LateFiles[item] = { Files: 1, Name: element.caseWorkerList[wIndex].CaseWorkerName };

  //                   //   }
  //                   //   //  else {
  //                   //   // LateFiles[item] = { Files: 1, Name: element.caseWorkerList[wIndex].CaseWorkerName };
  //                   //   // }
  //                   // }
  //                   caseWorkersId.push(item)



  //                 }
  //                 console.log(LateFiles, "lateFiles")
  //                 wIndex += 1
  //               });
  //               self.ActiveFiles.push(element);
  //               self.ActiveCases += 1;
  //               if ((element.personResposibleId == self.user.Id) || (self.user.RoleId == 1) || (pcheck)) {
  //                 this.lateTodo.push({ "Id": element.Id, "Text": element.matterName, "ItemId": element.fileNumber, "type": 1, "Date": element.deadLine });
  //               }
  //               this.lateCases.push(element);
  //             }
  //             if (current_date < deadline && element.caseStatus == 0) {
  //               self.ActiveCases += 1;
  //               self.ActiveFiles.push(element);
  //               if ((element.personResposibleId == self.user.Id) || (self.user.RoleId == 1) || (pcheck)) {
  //                 this.CurrentTodo.push({ "Id": element.Id, "Text": element.matterName, "ItemId": element.fileNumber, "type": 1, "Date": element.deadLine });
  //               }
  //             } else if (element.caseStatus == 1) {
  //               if (current_date < deadline) {
  //                 if ((element.personResposibleId == self.user.Id) || (self.user.RoleId == 1) || (pcheck)) {
  //                   this.CurrentTodo.push({ "Id": element.Id, "Text": element.matterName, "ItemId": element.fileNumber, "type": 1, "Date": element.deadLine });
  //                 }
  //               }
  //               self.AwaitingCases += 1;

  //               self.AwaitingFiles.push(element);

  //             }
  //             else if (element.caseStatus == 2) {
  //               if (current_date < deadline) {
  //                 if ((element.personResposibleId == self.user.Id) || (self.user.RoleId == 1) || (pcheck)) {
  //                   this.CurrentTodo.push({ "Id": element.Id, "Text": element.matterName, "ItemId": element.fileNumber, "type": 1, "Date": element.deadLine });
  //                 }
  //               }
  //               self.inCompletedCases += 1;
  //               self.awaitingCompletion.push(element);

  //             }
  //             element.caseWorkerList.forEach(element1 => {
  //               if (element1.status === 1) {
  //                 self.pendingApprovals += 1;
  //                 self.PendingApprovalFiles.push(element);
  //                 return;
  //               }
  //             });
  //           } else if (self.branchId === "All") {
  //             var index = matterIds.indexOf(element.matterId);
  //             if (index > -1) {
  //               if (element.caseStatus != 3) {
  //                 matterList[element.matterId].value += 1;
  //                 matterList[element.matterId].name = element.matterName;
  //                 matterList[element.matterId].files.push(element)

  //               }

  //             }

  //             if (current_date > deadline && element.caseStatus === 0) {
  //               self.lateFiles += 1;
  //               var caseWorkers = element.caseWorkerList.map(x => x.userId_id)
  //               var wIndex = 0
  //               console.log(caseIds);
  //               caseWorkers.forEach(item => {
  //                 var keys = Object.keys(LateFiles)
  //                 var index = keys.indexOf(item.toString()) > -1


  //                 if (caseWorkersId.indexOf(item) < 0) {
  //                   if (index) {
  //                     LateFiles[item].Files += 1;
  //                   }
  //                   // else {
  //                   //   if (current_date > deadline) {
  //                   //     LateFiles[item] = { Files: 1, Name: element.caseWorkerList[wIndex].CaseWorkerName };

  //                   //   }
  //                   //   //  else {
  //                   //   // LateFiles[item] = { Files: 1, Name: element.caseWorkerList[wIndex].CaseWorkerName };
  //                   //   // }
  //                   // }
  //                   caseWorkersId.push(item)



  //                 }
  //                 console.log(LateFiles, "lateFiles")
  //                 wIndex += 1
  //               });
  //               self.ActiveFiles.push(element);
  //               self.ActiveCases += 1;
  //               this.lateTodo.push({ "Id": element.Id, "Text": element.matterName, "ItemId": element.fileNumber, "type": 1, "Date": element.deadLine });
  //               this.lateCases.push(element);
  //             }
  //             if (current_date < deadline && element.caseStatus == 0) {
  //               self.ActiveCases += 1;
  //               this.CurrentTodo.push({ "Id": element.Id, "Text": element.matterName, "ItemId": element.fileNumber, "type": 1, "Date": element.deadLine });
  //               self.ActiveFiles.push(element);
  //             } else if (element.caseStatus == 1) {
  //               if (current_date < deadline) {
  //                 this.CurrentTodo.push({ "Id": element.Id, "Text": element.matterName, "ItemId": element.fileNumber, "type": 1, "Date": element.deadLine });

  //               }
  //               self.AwaitingCases += 1;
  //               self.AwaitingFiles.push(element);
  //             }
  //             else if (element.caseStatus == 2) {
  //               if (current_date < deadline) {
  //                 this.CurrentTodo.push({ "Id": element.Id, "Text": element.matterName, "ItemId": element.fileNumber, "type": 1, "Date": element.deadLine });

  //               }
  //               self.inCompletedCases += 1;
  //               self.awaitingCompletion.push(element);
  //             }
  //             element.caseWorkerList.forEach(element1 => {
  //               if (element1.status === 1) {
  //                 self.pendingApprovals += 1;
  //                 self.PendingApprovalFiles.push(element);
  //                 return;
  //               }
  //             });
  //           }
  //           let invoiceCheck= false;
  //         let invoiceBal = 0;
  //           element['caseInvoice'].forEach(invoice => {
  //             if (self.branchId === "All") {
  //               if(invoice.isDraft && invoice.amountPaid){
  //                 self.agreedCost += parseFloat(invoice.amountPaid);
  //                 invoiceBal+= parseFloat(invoice.amountPaid);
  //               }
  //               // self.agreedCost += parseFloat(invoice.agreedCost);
  //             } else {
  //               if (self.branchId === element.personResponsilbeBranchName) {
  //                 if(invoice.isDraft && invoice.amountPaid){
  //                   self.agreedCost += parseFloat(invoice.amountPaid);
  //                   invoiceBal+= parseFloat(invoice.amountPaid);
  //                 }
  //                 // self.agreedCost += parseFloat(invoice.agreedCost);
  //               }
  //             }
  //           });
  //           if(invoiceCheck){
  //             if(invoiceBal>0){
  //               element['invoiceBal']=invoiceBal;
  //             self.draftBalanceFiles.push(element);

  //             }
  //           }
  //           let outStandCheck = false;
  //           let activeBalanceCheck = false;
  //           let outBalance=0;
  //           let activeBalance = 0;
  //           element['officeInvoice'].forEach(function (r) {
  //             var i = 0;
  //             let totalAmount = Number(r['amount'])
  //             let balance = totalAmount;
  //            r['officeAccount'].forEach(element1 => {
  //              if (element1['isInPayment'] && !element1['isDeleted']) {
  //                let amount = Number(element1['amount'])
  //                balance = balance - amount;

  //              }
  //            });
  //            r['balance'] = balance;
  //            if(r['bankAccount']==self.branchId){
  //              if (r.typeOfPayment === "Legal FEE" && (element.caseStatus == 0 || element.caseStatus == 1) && !Acheck) {
  //                self.outStandingBalance += parseFloat(r['balance']);
  //                outBalance+= parseFloat(r['balance']);
  //                outStandCheck= true;

  //              }
  //              if (r.typeOfPayment === "Legal FEE" && (element.caseStatus == 0) && !Acheck) {
  //                self.ActiveBalance += parseFloat(r['balance']);
  //                activeBalance+= parseFloat(r['balance']);
  //                activeBalanceCheck= true;
  //              }
  //            }
  //            else if(self.branchId === "All"){
  //              if (r.typeOfPayment === "Legal FEE" && (element.caseStatus == 0 || element.caseStatus == 1) && !Acheck) {
  //                self.outStandingBalance += parseFloat(r['balance']);
  //                outBalance+= parseFloat(r['balance']);

  //                outStandCheck = true;
  //              }
  //              if (r.typeOfPayment === "Legal FEE" && (element.caseStatus == 0) && !Acheck) {
  //                self.ActiveBalance += parseFloat(r['balance']);
  //                activeBalance+= parseFloat(r['balance']);

  //                activeBalanceCheck= true;
  //              }
  //            }
  //             r['officeAccount'].forEach(office => {
  //               var account = 0;
  //               if (office.isInPayment) {
  //                 let latest_date = new Date(office.entryDate);
  //                 let date = new Date();
  //                 var current_Month = (date.getMonth() - 1);
  //                 var last_Month = latest_date.getMonth();
  //                 if (office.bankAccount === "Manchester" && office.typeOfPayment === "Legal FEE") {
  //                   if (last_Month === current_Month + 1 && latest_date.getFullYear() === date.getFullYear()) {
  //                     self.manchesterCurrent += parseFloat(office.amount);
  //                   }
  //                   else if (last_Month === current_Month && latest_date.getFullYear() === date.getFullYear()) {
  //                     self.manchesterPrev += parseFloat(office.amount);
  //                   }
  //                   if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0 || element.caseStatus == 1)) {
  //                     self.manchesterOutstanding += parseFloat(office.amount);
  //                   }
  //                 } else if (office.bankAccount === "Chester" && office.typeOfPayment === "Legal FEE") {
  //                   if (last_Month === current_Month + 1 && latest_date.getFullYear() === date.getFullYear()) {
  //                     self.chesterCurrent += parseFloat(office.amount);
  //                   }
  //                   else if (last_Month === current_Month && latest_date.getFullYear() === date.getFullYear()) {
  //                     self.chesterPrev += parseFloat(office.amount);
  //                   }
  //                   if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0 || element.caseStatus == 1)) {
  //                     self.chesterOutstanding += parseFloat(office.amount);
  //                   }
  //                 }
  //                 else if (office.bankAccount === "London" && office.typeOfPayment === "Legal FEE") {
  //                   if (last_Month === current_Month + 1 && latest_date.getFullYear() === date.getFullYear()) {
  //                     self.londonCurrent += parseFloat(office.amount);
  //                   }
  //                   else if (last_Month === current_Month && latest_date.getFullYear() === date.getFullYear()) {
  //                     self.londonPrev += parseFloat(office.amount);
  //                   }
  //                   if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0 || element.caseStatus == 1)) {
  //                     self.londonOutstanding += parseFloat(office.amount);
  //                   }
  //                 }
  //                 else if (office.bankAccount === "Birmingham" && office.typeOfPayment === "Legal FEE") {
  //                   if (last_Month === current_Month + 1 && latest_date.getFullYear() === date.getFullYear()) {
  //                     self.birminghamCurrent += parseFloat(office.amount);
  //                   } else if (last_Month === current_Month && latest_date.getFullYear() === date.getFullYear()) {
  //                     self.birminghamPrev += parseFloat(office.amount);
  //                   }
  //                   if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0 || element.caseStatus == 1)) {
  //                     self.birminghamOutstanding += parseFloat(office.amount);
  //                   }
  //                 }
  //                 if (office.bankAccount == self.branchId) {
  //                   // if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0 || element.caseStatus == 1) && !Acheck) {
  //                   //   self.outStandingBalance += parseFloat(office.amount);
  //                   // }
  //                   // if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0) && !Acheck) {
  //                   //   self.ActiveBalance += parseFloat(office.amount);
  //                   // }
  //                   if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 1) && !Acheck) {
  //                     self.AwaitingBalance += parseFloat(office.amount);
  //                   } if (office.typeOfPayment === "Disbursement") {
  //                     if(office.isInPayment){
  //                       self.disIn += parseFloat(office.amount);
  //                       disInCheck=true;
  //                       disInTotal+=parseFloat(office.amount);
  //                     }
  //                     else{
  //                       self.disOut +=parseFloat(office.amount);
  //                       disOutCheck=true;
  //                       disOutTotal+=parseFloat(office.amount);
  //                     }

  //                   } if (office.typeOfPayment == "Legal FEE") {
  //                     // self.disOut +=parseFloat(office.amount);
  //                     account += parseFloat(office.amount);
  //                     if (r['officeAccount'].length - 1 === i) {
  //                       var total = parseFloat(r.amount) - account;
  //                       self.pendingBalance += total;
  //                     }
  //                   }
  //                 } else if (self.branchId == "All") {
  //                   // if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0 || element.caseStatus == 1) && !Acheck) {
  //                   //   self.outStandingBalance += parseFloat(office.amount);
  //                   // }
  //                   // if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0) && !Acheck) {
  //                   //   self.ActiveBalance += parseFloat(office.amount);
  //                   // }
  //                   if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 1) && !Acheck) {
  //                     self.AwaitingBalance += parseFloat(office.amount);
  //                   }
  //                   if (office.typeOfPayment === "Disbursement") {
  //                     if(office.isInPayment){
  //                       self.disIn += parseFloat(office.amount);
  //                       disInCheck=true;
  //                       disInTotal+=parseFloat(office.amount);
  //                     }
  //                     else{
  //                       self.disOut += parseFloat(office.amount);
  //                       disOutCheck=true;
  //                       disOutTotal+=parseFloat(office.amount);
  //                     }

  //                   } else {
  //                     // self.disOut += parseFloat(office.amount);
  //                     account += parseFloat(office.amount);
  //                     if (r['officeAccount'].length - 1 === i) {
  //                       var total = parseFloat(r.amount) - account;
  //                       self.pendingBalance += total;
  //                     }
  //                   }
  //                 }
  //               } else {
  //                 if (office.typeOfPayment === "Disbursement") {
  //                   self.disOut += parseFloat(office.amount);
  //                   disOutCheck=true;
  //                       disOutTotal+=parseFloat(office.amount);
  //                 } if (office.typeOfPayment == "Legal FEE") {
  //                   // self.disOut +=parseFloat(office.amount);
  //                   account += parseFloat(office.amount);
  //                   if (r['officeAccount'].length - 1 === i) {
  //                     var total = parseFloat(r.amount) - account;
  //                     self.pendingBalance += total;
  //                   }
  //                 }
  //               }
  //               i += 1;
  //             });

  //             if (r['officeAccount'].length == 0) {
  //               if (self.branchId === "All") {
  //                 self.pendingBalance += parseFloat(r.amount);
  //               }
  //               else if (element.personResponsilbeBranchName === self.branchId) {
  //                 self.pendingBalance += parseFloat(r.amount);
  //               }
  //             }





  //           });
  //           if(outStandCheck){
  //             if(outBalance>0){
  //               element['outBalance']= outBalance;
  //             self.OutStandingFiles.push(element);
  //             }

  //           }
  //           if(activeBalanceCheck){
  //             if(activeBalance>0){
  //               element['activeBalance']=activeBalance;
  //             self.ActiveBalanceFiles.push(element);
  //             }
  //           }
  //           element['clientAccount'].forEach(function (client) {
  //             if (client.isInPayment) {
  //               if (client.bankAccount == self.branchId) {
  //                 if (client.typeOfPayment === "Disbursement") {
  //                   self.disIn += parseFloat(client.amount);
  //                   disInCheck=true;
  //                       disInTotal+=parseFloat(client.amount);
  //                 } else {
  //                   self.disOut += parseFloat(client.amount);
  //                   disOutCheck=true;
  //                       disOutTotal+=parseFloat(client.amount);
  //                 }
  //               }
  //               else if (self.branchId == "All") {
  //                 if (client.typeOfPayment === "Disbursement") {
  //                   self.disIn += parseFloat(client.amount);
  //                   disInCheck=true;
  //                       disInTotal+=parseFloat(client.amount);
  //                 } else {
  //                   self.disOut += parseFloat(client.amount);
  //                   disOutCheck=true;
  //                   disOutTotal+=parseFloat(client.amount);

  //                 }
  //               }
  //             }

  //           });
  //         }
  //         // element.caseWorkerList.forEach(element1 => {
  //         //   if (element1.userId_id == self.user.Id && self.checkPermission('Dashboard','level',self.branchId)===5 && self.checkPermission('Dashboard','level',self.branchId)===6) {

  //         //     if (self.branchId === element.personResponsilbeBranchName) {
  //         //       var index = matterIds.indexOf(element.matterId);
  //         //       if (index > -1) {
  //         //         if(element.caseStatus===0)
  //         //     {    matterList[element.matterId].value += 1;
  //         //         matterList[element.matterId].name = element.matterName;}

  //         //       }

  //         //       if (current_date > deadline && element.caseStatus === 0) {
  //         //         self.lateFiles += 1;
  //         //       }
  //         //       if (element.caseStatus == 0) {
  //         //         self.ActiveCases += 1;
  //         //       } else if (element.caseStatus == 1) {
  //         //         self.AwaitingCases += 1
  //         //       }
  //         //       else if (element.caseStatus == 2) {
  //         //         self.inCompletedCases += 1
  //         //       }
  //         //       element.caseWorkerList.forEach(element1 => {
  //         //         if (element1.status === 1) {
  //         //           self.pendingApprovals += 1;
  //         //           return;
  //         //         }
  //         //       });
  //         //     } else if (self.branchId === "All") {
  //         //       var index = matterIds.indexOf(element.matterId);
  //         //       if (index > -1) {
  //         //         if(element.caseStatus===0)
  //         //        { matterList[element.matterId].value += 1;
  //         //         matterList[element.matterId].name = element.matterName;
  //         //       }

  //         //       }

  //         //       if (current_date > deadline && element.caseStatus === 0) {
  //         //         self.lateFiles += 1;
  //         //       }
  //         //       if (element.caseStatus == 0) {
  //         //         self.ActiveCases += 1;
  //         //       } else if (element.caseStatus == 1) {
  //         //         self.AwaitingCases += 1
  //         //       }
  //         //       else if (element.caseStatus == 2) {
  //         //         self.inCompletedCases += 1
  //         //       }
  //         //       element.caseWorkerList.forEach(element1 => {
  //         //         if (element1.status === 1) {
  //         //           self.pendingApprovals += 1;
  //         //           return;
  //         //         }
  //         //       });
  //         //     }
  //         //     element['caseInvoice'].forEach(invoice => {
  //         //       if (self.branchId === "All") {
  //         //         self.agreedCost += parseFloat(invoice.agreedCost);
  //         //       } else {
  //         //         if (self.branchId === element.personResponsilbeBranchName) {
  //         //           self.agreedCost += parseFloat(invoice.agreedCost);
  //         //         }
  //         //       }
  //         //     });

  //         //     element['officeInvoice'].forEach(function (r) {
  //         //       var i = 0;
  //         //       r['officeAccount'].forEach(office => {
  //         //         var account = 0;
  //         //         if (office.isInPayment) {
  //         //           let latest_date = new Date(office.entryDate);
  //         //           let date = new Date();
  //         //           var current_Month = (date.getMonth() - 1);
  //         //           var last_Month = latest_date.getMonth();
  //         //           if (office.bankAccount === "Manchester" && office.typeOfPayment === "Legal FEE") {
  //         //             if (last_Month === current_Month + 1 && latest_date.getFullYear() === date.getFullYear()) {
  //         //               self.manchesterCurrent += parseFloat(office.amount);
  //         //             }
  //         //             else if (last_Month === current_Month && latest_date.getFullYear() === date.getFullYear()) {
  //         //               self.manchesterPrev += parseFloat(office.amount);
  //         //             }
  //         //             if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0 || element.caseStatus == 1)) {
  //         //               self.manchesterOutstanding += parseFloat(office.amount);
  //         //             }
  //         //           } else if (office.bankAccount === "Chester" && office.typeOfPayment === "Legal FEE") {
  //         //             if (last_Month === current_Month + 1 && latest_date.getFullYear() === date.getFullYear()) {
  //         //               self.chesterCurrent += parseFloat(office.amount);
  //         //             }
  //         //             else if (last_Month === current_Month && latest_date.getFullYear() === date.getFullYear()) {
  //         //               self.chesterPrev += parseFloat(office.amount);
  //         //             }
  //         //             if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0 || element.caseStatus == 1)) {
  //         //               self.chesterOutstanding += parseFloat(office.amount);
  //         //             }
  //         //           }
  //         //           else if (office.bankAccount === "London" && office.typeOfPayment === "Legal FEE") {
  //         //             if (last_Month === current_Month + 1 && latest_date.getFullYear() === date.getFullYear()) {
  //         //               self.londonCurrent += parseFloat(office.amount);
  //         //             }
  //         //             else if (last_Month === current_Month && latest_date.getFullYear() === date.getFullYear()) {
  //         //               self.londonPrev += parseFloat(office.amount);
  //         //             }
  //         //             if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0 || element.caseStatus == 1)) {
  //         //               self.londonOutstanding += parseFloat(office.amount);
  //         //             }
  //         //           }
  //         //           else if (office.bankAccount === "Birmingham" && office.typeOfPayment === "Legal FEE") {
  //         //             if (last_Month === current_Month + 1 && latest_date.getFullYear() === date.getFullYear()) {
  //         //               self.birminghamCurrent += parseFloat(office.amount);
  //         //             } else if (last_Month === current_Month && latest_date.getFullYear() === date.getFullYear()) {
  //         //               self.birminghamPrev += parseFloat(office.amount);
  //         //             }
  //         //             if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0 || element.caseStatus == 1)) {
  //         //               self.birminghamOutstanding += parseFloat(office.amount);
  //         //             }
  //         //           }
  //         //           if (office.bankAccount == self.branchId) {
  //         //             if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0 || element.caseStatus == 1)) {
  //         //               self.outStandingBalance += parseFloat(office.amount);
  //         //             }
  //         //             if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0)) {
  //         //               self.ActiveBalance += parseFloat(office.amount);
  //         //             }
  //         //             if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 1)) {
  //         //               self.AwaitingBalance += parseFloat(office.amount);
  //         //             } if (office.typeOfPayment === "Disbursement") {
  //         //               self.disIn += parseFloat(office.amount);
  //         //             } if (office.typeOfPayment == "Legal FEE") {
  //         //               // self.disOut +=parseFloat(office.amount);
  //         //               account += parseFloat(office.amount);
  //         //               if (r['officeAccount'].length - 1 === i) {
  //         //                 var total = parseFloat(r.amount) - account;
  //         //                 self.pendingBalance += total;
  //         //               }
  //         //             }
  //         //           } else if (self.branchId == "All") {
  //         //             if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0 || element.caseStatus == 1)) {
  //         //               self.outStandingBalance += parseFloat(office.amount);
  //         //             }
  //         //             if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0)) {
  //         //               self.ActiveBalance += parseFloat(office.amount);
  //         //             }
  //         //             if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 1)) {
  //         //               self.AwaitingBalance += parseFloat(office.amount);
  //         //             }
  //         //             if (office.typeOfPayment === "Disbursement") {
  //         //               self.disIn += parseFloat(office.amount);
  //         //             } else {
  //         //               self.disOut += parseFloat(office.amount);
  //         //               account += parseFloat(office.amount);
  //         //               if (r['officeAccount'].length - 1 === i) {
  //         //                 var total = parseFloat(r.amount) - account;
  //         //                 self.pendingBalance += total;
  //         //               }
  //         //             }
  //         //           }
  //         //         } else {
  //         //           if (office.typeOfPayment === "Disbursement") {
  //         //             self.disOut += parseFloat(office.amount);
  //         //           } if (office.typeOfPayment == "Legal FEE") {
  //         //             // self.disOut +=parseFloat(office.amount);
  //         //             account += parseFloat(office.amount);
  //         //             if (r['officeAccount'].length - 1 === i) {
  //         //               var total = parseFloat(r.amount) - account;
  //         //               self.pendingBalance += total;
  //         //             }
  //         //           }
  //         //         }
  //         //         i += 1;
  //         //       });







  //         //     });
  //         //     element['caseInvoice'].forEach(function (client) {
  //         //       if (client.isInPayment) {
  //         //         if (client.bankAccount == self.branchId) {
  //         //           if (client.typeOfPayment === "Disbursement") {
  //         //             self.disIn += parseFloat(client.amount);
  //         //           } else {
  //         //             self.disOut += parseFloat(client.amount);
  //         //           }
  //         //         }
  //         //         else if (self.branchId == "All") {
  //         //           if (client.typeOfPayment === "Disbursement") {
  //         //             self.disIn += parseFloat(client.amount);
  //         //           } else {
  //         //             self.disOut += parseFloat(client.amount);
  //         //           }
  //         //         }
  //         //       }

  //         //     });
  //         //     return;
  //         //   }


  //         // });
  //         // var index = matterIds.indexOf(element.matterId);
  //         // if (index > -1) {
  //         //   matterList[element.matterId].value += 1;
  //         //   matterList[element.matterId].name = element.matterName;

  //         // }
  //         if(disOutCheck){
  //           if(disOutTotal>0){
  //             element['disOutTotal']=disOutTotal;
  //             self.disOutFiles.push(element);
  //           }
  //         }
  //         if(disInCheck){
  //           if(disInTotal>0){
  //             element['disInTotal']=disInTotal;
  //             self.disInFiles.push(element);
  //           }
  //         }
  //       }

  //     }
  //     else {
  //       var p = self.checkPermission('Dashboard', 'level', self.branchId);
  //       var pcheck = false;
  //       var arr = [1, 2, 3];
  //       p.forEach(function (el) {
  //         if (arr.indexOf(parseInt(el)) > -1) {
  //           pcheck = true;
  //           return;
  //         }
  //       });
  //       let deadline = new Date(element.deadLine);
  //       let current_date = new Date();
  //       if (pcheck) {
  //         this.retainers += 1;
  //         if (current_date > deadline) {
  //           this.lateTodo.push({ "Id": element.Id, "Text": element.matterName, "ItemId": "Client Name : " + element.clientName, "type": 2, "Date": element.deadLine });

  //         } else {
  //           this.CurrentTodo.push({ "Id": element.Id, "Text": element.matterName, "ItemId": "Client Name : " + element.clientName, "type": 2, "Date": element.deadLine });

  //         }
  //         this.retainer.push(element);
  //       }
  //       // if (self.branchId === element.personResponsilbeBranchName || element.personResponsilbeBranchName == null) {
  //       //   this.retainers += 1;
  //       //   this.retainer.push(element);
  //       //   this.CurrentTodo.push({ "Id": element.Id, "Text": element.matterName, "ItemId": "Client Name : " + element.clientName, "type": 2, "Date": element.deadLine });
  //       // } else if (self.branchId === "All") {
  //       //   this.retainers += 1;
  //       //   this.retainer.push(element);
  //       //   this.CurrentTodo.push({ "Id": element.Id, "Text": element.matterName, "ItemId": "Client Name : " + element.clientName, "type": 2, "Date": element.deadLine });
  //       // }
  //     }
  //     // }else if(this.branchId === "All"){
  //     //   if(element.isDraft == true){
  //     //   if ((element.personResposibleId == self.user.Id || self.user.RoleId == 1)  ) {

  //     //     let deadline = new Date(element.deadLine);
  //     //     let current_date = new Date();
  //     //     if(current_date>deadline && element.caseStatus===0){
  //     //       self.lateFiles+=1;
  //     //     }
  //     //     if (element.caseStatus == 0) {
  //     //       self.ActiveCases += 1;
  //     //     } else if (element.caseStatus == 1) {
  //     //       self.AwaitingCases += 1
  //     //     }
  //     //     else if (element.caseStatus == 2) {
  //     //       self.inCompletedCases += 1
  //     //     }
  //     //     element.caseWorkerList.forEach(element1 => {
  //     //       if(element1.status===1){
  //     //     self.pendingApprovals+=1;
  //     //     return;
  //     //   }
  //     //   });
  //     //     element['caseInvoice'].forEach(invoice => {
  //     //       if (self.branchId === "All") {

  //     //         self.agreedCost += parseFloat(invoice.agreedCost);
  //     //         }else{
  //     //           if(self.branchId===element.personResponsilbeBranchName)
  //     //           {
  //     //             self.agreedCost += parseFloat(invoice.agreedCost);
  //     //           }
  //     //         }
  //     //     });
  //     //     element['officeInvoice'].forEach(function (r) {

  //     //       var i = 0;
  //     //       r['officeAccount'].forEach(office => {
  //     //         var account = 0;
  //     //         if (office.isInPayment) {
  //     //           let latest_date = new Date(office.entryDate);
  //     //           let date = new Date();
  //     //           var current_Month = (date.getMonth() - 1);
  //     //           var last_Month = latest_date.getMonth();
  //     //           if (office.bankAccount === "Manchester" && office.typeOfPayment === "Legal FEE") {
  //     //             if (last_Month === current_Month + 1 && latest_date.getFullYear() === date.getFullYear()) {
  //     //               self.manchesterCurrent += parseFloat(office.amount);
  //     //             }
  //     //             else if (last_Month === current_Month && latest_date.getFullYear() === date.getFullYear()) {
  //     //               self.manchesterPrev += parseFloat(office.amount);
  //     //             }
  //     //             if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0 || element.caseStatus == 1)) {
  //     //               self.manchesterOutstanding += parseFloat(office.amount);
  //     //             }
  //     //           } else if (office.bankAccount === "Chester" && office.typeOfPayment === "Legal FEE") {
  //     //             if (last_Month === current_Month + 1 && latest_date.getFullYear() === date.getFullYear()) {
  //     //               self.chesterCurrent += parseFloat(office.amount);
  //     //             }
  //     //             else if (last_Month === current_Month && latest_date.getFullYear() === date.getFullYear()) {
  //     //               self.chesterPrev += parseFloat(office.amount);
  //     //             }
  //     //             if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0 || element.caseStatus == 1)) {
  //     //               self.chesterOutstanding += parseFloat(office.amount);
  //     //             }
  //     //           }
  //     //           else if (office.bankAccount === "London" && office.typeOfPayment === "Legal FEE") {
  //     //             if (last_Month === current_Month + 1 && latest_date.getFullYear() === date.getFullYear()) {
  //     //               self.londonCurrent += parseFloat(office.amount);
  //     //             }
  //     //             else if (last_Month === current_Month && latest_date.getFullYear() === date.getFullYear()) {
  //     //               self.londonPrev += parseFloat(office.amount);
  //     //             }
  //     //             if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0 || element.caseStatus == 1)) {
  //     //               self.londonOutstanding += parseFloat(office.amount);
  //     //             }
  //     //           }
  //     //           else if (office.bankAccount === "Birmingham" && office.typeOfPayment === "Legal FEE") {
  //     //             if (last_Month === current_Month + 1 && latest_date.getFullYear() === date.getFullYear()) {
  //     //               self.birminghamCurrent += parseFloat(office.amount);
  //     //             } else if (last_Month === current_Month && latest_date.getFullYear() === date.getFullYear()) {
  //     //               self.birminghamPrev += parseFloat(office.amount);
  //     //             }
  //     //             if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0 || element.caseStatus == 1)) {
  //     //               self.birminghamOutstanding += parseFloat(office.amount);
  //     //             }
  //     //           }

  //     //           if (office.bankAccount == self.branchId) {
  //     //             if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0 || element.caseStatus == 1)) {
  //     //               self.outStandingBalance += parseFloat(office.amount);
  //     //             }
  //     //             if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0)) {
  //     //               self.ActiveBalance += parseFloat(office.amount);
  //     //             }
  //     //             if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 1)) {
  //     //               self.AwaitingBalance += parseFloat(office.amount);
  //     //             }
  //     //             if (office.typeOfPayment === "Disbursement") {
  //     //               self.disIn += parseFloat(office.amount);
  //     //             } if (office.typeOfPayment == "Legal FEE") {
  //     //               // self.disOut +=parseFloat(office.amount);
  //     //               account += parseFloat(office.amount);
  //     //               if (r['officeAccount'].length - 1 === i) {
  //     //                 var total = parseFloat(r.amount) - account;
  //     //                 self.pendingBalance += total;
  //     //               }
  //     //             }
  //     //           } else if (self.branchId === "All") {

  //     //             if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0 || element.caseStatus == 1)) {
  //     //               self.outStandingBalance += parseFloat(office.amount);
  //     //             }
  //     //             if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0)) {
  //     //               self.ActiveBalance += parseFloat(office.amount);
  //     //             }
  //     //             if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 1)) {
  //     //               self.AwaitingBalance += parseFloat(office.amount);
  //     //             }
  //     //             if (office.typeOfPayment === "Disbursement") {
  //     //               self.disIn += parseFloat(office.amount);
  //     //             } if (office.typeOfPayment == "Legal FEE") {
  //     //               // self.disOut +=parseFloat(office.amount);
  //     //               account += parseFloat(office.amount);
  //     //               if (r['officeAccount'].length - 1 === i) {
  //     //                 var total = parseFloat(r.amount) - account;
  //     //                 self.pendingBalance += total;
  //     //               }
  //     //             }

  //     //           }}
  //     //            else {
  //     //             if (office.typeOfPayment === "Disbursement") {
  //     //               self.disOut += parseFloat(office.amount);
  //     //             } if (office.typeOfPayment == "Legal FEE") {
  //     //               // self.disOut +=parseFloat(office.amount);
  //     //               account += parseFloat(office.amount);
  //     //               if (r['officeAccount'].length - 1 === i) {
  //     //                 var total = parseFloat(r.amount) - account;
  //     //                 self.pendingBalance += total;
  //     //               }
  //     //             }
  //     //           }


  //     //           i += 1;
  //     //         });





  //     //     });
  //     //     element['caseInvoice'].forEach(function (client) {
  //     //       if (client.isInPayment) {
  //     //         if (client.bankAccount == self.branchId) {
  //     //           if (client.typeOfPayment === "Disbursement") {
  //     //             self.disIn += parseFloat(client.amount);
  //     //           } else {
  //     //             self.disOut += parseFloat(client.amount);
  //     //           }
  //     //         }
  //     //         else if (self.branchId === "All") {
  //     //           if (client.typeOfPayment === "Disbursement") {
  //     //             self.disIn += parseFloat(client.amount);
  //     //           } else {
  //     //             self.disOut += parseFloat(client.amount);
  //     //           }
  //     //         }
  //     //       } else {
  //     //         if (client.typeOfPayment === "Disbursement") {
  //     //           self.disOut += parseFloat(client.amount);
  //     //         }
  //     //       }

  //     //     });
  //     //     var index = matterIds.indexOf(element.matterId);
  //     //     if (index > -1) {
  //     //       matterList[element.matterId].value += 1;
  //     //       matterList[element.matterId].name = element.matterName;

  //     //     }

  //     //   } else {

  //     //       let deadline = new Date(element.deadLine);
  //     //       let current_date = new Date();
  //     //       if(current_date>deadline && element.caseStatus===0){
  //     //         self.lateFiles+=1;
  //     //       }
  //     //       element.caseWorkerList.forEach(element1 => {
  //     //         if (element1.userId == self.user.Id) {
  //     //           if (element.caseStatus == 0) {
  //     //             self.ActiveCases += 1;
  //     //             // return;
  //     //           } else if (element.caseStatus == 1) {
  //     //             self.AwaitingCases += 1;
  //     //             // return;
  //     //           }
  //     //           else if (element.caseStatus == 2) {
  //     //             self.inCompletedCases += 1;
  //     //             // return;
  //     //           }
  //     //           element['caseInvoice'].forEach(invoice => {
  //     //             if (self.branchId === "All") {
  //     //             self.agreedCost += parseFloat(invoice.agreedCost);
  //     //             }else{
  //     //               if(self.branchId===element.personResponsilbeBranchName)
  //     //               {
  //     //                 self.agreedCost += parseFloat(invoice.agreedCost);
  //     //               }
  //     //             }
  //     //           });

  //     //           element['officeInvoice'].forEach(function (r) {
  //     //             var i = 0;
  //     //             r['officeAccount'].forEach(office => {
  //     //               var account = 0;
  //     //               if (office.isInPayment) {
  //     //                 let latest_date = new Date(office.entryDate);
  //     //                 let date = new Date();
  //     //                 var current_Month = (date.getMonth() - 1);
  //     //                 var last_Month = latest_date.getMonth();
  //     //                 if (office.bankAccount === "Manchester" && office.typeOfPayment === "Legal FEE") {
  //     //                   if (last_Month === current_Month + 1 && latest_date.getFullYear() === date.getFullYear()) {
  //     //                     self.manchesterCurrent += parseFloat(office.amount);
  //     //                   }
  //     //                   else if (last_Month === current_Month && latest_date.getFullYear() === date.getFullYear()) {
  //     //                     self.manchesterPrev += parseFloat(office.amount);
  //     //                   }
  //     //                   if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0 || element.caseStatus == 1)) {
  //     //                     self.manchesterOutstanding += parseFloat(office.amount);
  //     //                   }
  //     //                 } else if (office.bankAccount === "Chester" && office.typeOfPayment === "Legal FEE") {
  //     //                   if (last_Month === current_Month + 1 && latest_date.getFullYear() === date.getFullYear()) {
  //     //                     self.chesterCurrent += parseFloat(office.amount);
  //     //                   }
  //     //                   else if (last_Month === current_Month && latest_date.getFullYear() === date.getFullYear()) {
  //     //                     self.chesterPrev += parseFloat(office.amount);
  //     //                   }
  //     //                   if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0 || element.caseStatus == 1)) {
  //     //                     self.chesterOutstanding += parseFloat(office.amount);
  //     //                   }
  //     //                 }
  //     //                 else if (office.bankAccount === "London" && office.typeOfPayment === "Legal FEE") {
  //     //                   if (last_Month === current_Month + 1 && latest_date.getFullYear() === date.getFullYear()) {
  //     //                     self.londonCurrent += parseFloat(office.amount);
  //     //                   }
  //     //                   else if (last_Month === current_Month && latest_date.getFullYear() === date.getFullYear()) {
  //     //                     self.londonPrev += parseFloat(office.amount);
  //     //                   }
  //     //                   if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0 || element.caseStatus == 1)) {
  //     //                     self.londonOutstanding += parseFloat(office.amount);
  //     //                   }
  //     //                 }
  //     //                 else if (office.bankAccount === "Birmingham" && office.typeOfPayment === "Legal FEE") {
  //     //                   if (last_Month === current_Month + 1 && latest_date.getFullYear() === date.getFullYear()) {
  //     //                     self.birminghamCurrent += parseFloat(office.amount);
  //     //                   } else if (last_Month === current_Month && latest_date.getFullYear() === date.getFullYear()) {
  //     //                     self.birminghamPrev += parseFloat(office.amount);
  //     //                   }
  //     //                   if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0 || element.caseStatus == 1)) {
  //     //                     self.birminghamOutstanding += parseFloat(office.amount);
  //     //                   }
  //     //                 }
  //     //                 if (office.bankAccount == self.branchId) {
  //     //                   if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0 || element.caseStatus == 1)) {
  //     //                     self.outStandingBalance += parseFloat(office.amount);
  //     //                   }
  //     //                   if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0)) {
  //     //                     self.ActiveBalance += parseFloat(office.amount);
  //     //                   }
  //     //                   if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 1)) {
  //     //                     self.AwaitingBalance += parseFloat(office.amount);
  //     //                   } if (office.typeOfPayment === "Disbursement") {
  //     //                     self.disIn += parseFloat(office.amount);
  //     //                   } if (office.typeOfPayment == "Legal FEE") {
  //     //                     // self.disOut +=parseFloat(office.amount);
  //     //                     account += parseFloat(office.amount);
  //     //                     if (r['officeAccount'].length - 1 === i) {
  //     //                       var total = parseFloat(r.amount) - account;
  //     //                       self.pendingBalance += total;
  //     //                     }
  //     //                   }
  //     //                 } else if (self.branchId == "All") {
  //     //                   if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0 || element.caseStatus == 1)) {
  //     //                     self.outStandingBalance += parseFloat(office.amount);
  //     //                   }
  //     //                   if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0)) {
  //     //                     self.ActiveBalance += parseFloat(office.amount);
  //     //                   }
  //     //                   if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 1)) {
  //     //                     self.AwaitingBalance += parseFloat(office.amount);
  //     //                   }
  //     //                   if (office.typeOfPayment === "Disbursement") {
  //     //                     self.disIn += parseFloat(office.amount);
  //     //                   } else {
  //     //                     self.disOut += parseFloat(office.amount);
  //     //                     account += parseFloat(office.amount);
  //     //                     if (r['officeAccount'].length - 1 === i) {
  //     //                       var total = parseFloat(r.amount) - account;
  //     //                       self.pendingBalance += total;
  //     //                     }
  //     //                   }
  //     //                 }
  //     //               } else {
  //     //                 if (office.typeOfPayment === "Disbursement") {
  //     //                   self.disOut += parseFloat(office.amount);
  //     //                 } if (office.typeOfPayment == "Legal FEE") {
  //     //                   // self.disOut +=parseFloat(office.amount);
  //     //                   account += parseFloat(office.amount);
  //     //                   if (r['officeAccount'].length - 1 === i) {
  //     //                     var total = parseFloat(r.amount) - account;
  //     //                     self.pendingBalance += total;
  //     //                   }
  //     //                 }
  //     //               }
  //     //               i += 1;
  //     //             });







  //     //           });
  //     //           element['caseInvoice'].forEach(function (client) {
  //     //             if (client.isInPayment) {
  //     //               if (client.bankAccount == self.branchId) {
  //     //                 if (client.typeOfPayment === "Disbursement") {
  //     //                   self.disIn += parseFloat(client.amount);
  //     //                 } else {
  //     //                   self.disOut += parseFloat(client.amount);
  //     //                 }
  //     //               }
  //     //               else if (self.branchId == "All") {
  //     //                 if (client.typeOfPayment === "Disbursement") {
  //     //                   self.disIn += parseFloat(client.amount);
  //     //                 } else {
  //     //                   self.disOut += parseFloat(client.amount);
  //     //                 }
  //     //               }
  //     //             }

  //     //           });
  //     //           return;
  //     //         }


  //     //       });
  //     //       var index = matterIds.indexOf(element.matterId);
  //     //       if (index > -1) {
  //     //         matterList[element.matterId].value += 1;
  //     //         matterList[element.matterId].name = element.matterName;

  //     //       }

  //     //   }
  //     // }
  //     // else{
  //     //   this.retainers+=1;
  //     // }
  //     // }
  //   });
  //   this.prevSale = { 'color': '#57a7fe', 'label': 'Manchester', 'value': this.manchesterPrev }
  //   this.currSale = { 'color': '#57a7fe', 'label': 'Manchester', 'value': this.manchesterCurrent }
  //   this.outBalance = { 'color': '#57a7fe', 'label': 'Manchester', 'value': this.manchesterOutstanding }

  //   this.Deadlines = this.Deadlines.sort((a, b) => (a.Date > b.Date ? 1 : -1));

  //   this.Deadlines.forEach(element => {
  //     if (Object.keys(this.nDeadline).indexOf(element.FileNumber) < 0) {
  //       this.nDeadline[element.FileNumber] = []


  //       this.nDeadline[element.FileNumber].push(element)



  //     } else {

  //       this.nDeadline[element.FileNumber].push(element)

  //     }

  //   });
  //   for (const [key, value] of Object.entries(this.nDeadline)) {
  //     var name = []
  //     var profPic = []
  //     this.nDeadline[key].forEach(item => {
  //       if (name.indexOf(item.Name) < 0) {
  //         name.push(item.Name)

  //         profPic.push({
  //           name:item.Name,
  //           profPic:item.profilePic
  //         })
  //       }
  //     })

  //     this.nDeadline[key][0]["Names"] = profPic

  //   }
  //   var names = {}

  //   this.brp = this.brp.sort((a, b) => (a.Date > b.Date ? 1 : -1));
  //   this.CurrentTodo = this.CurrentTodo.sort((a, b) => (new Date(b.Date) < new Date(a.Date) ? -1 : 1));
  //   this.lateTodo = this.lateTodo.sort((a, b) => (new Date(a.Date) > new Date(b.Date) ? 1 : -1));
  //   // console.log(this.CurrentTodo,"current");

  //   //   console.log(this.lateTodo,"late");
  //   this.MatterData = [];
  //   this.pendingBalanceData = [];
  //   this.leadPerStageData = [];
  //   this.leadByMatterData = [];
  //   this.leadPerStageWinLostData = [];
  //   this.lineData = [];
  //   this.VisaData = [];
  //   this.workSourceList = [];
  //   for (const [key, value] of Object.entries(workSrc)) {
  //     this.workSourceList.push({ Name: value["Name"], value: value["value"] });

  //   }
  //   for (const [key, value] of Object.entries(this.leadFormDetail)) {
  //     console.log("active Lead", key, value);
  //     this.activeLeads.push({ Name: value["Name"], value: value["count"], color: value['color'],
  //     dealOwnerProfilePic:value['dealOwnerProfilePic'],
  //     files:value['files'] });

  //   }
  //   this.activeLeads = this.activeLeads.sort((a, b) => (a.value < b.value ? 1 : -1));
  //   var total = leadWin + leadlost;
  //   var lostPerc = 0;
  //   var WinPerc = 0;
  //   if (total != 0) {
  //     lostPerc = Math.round(leadlost / total * 100);
  //     WinPerc = Math.round(leadWin / total * 100);
  //   }


  //   this.leadPerStageWinLostData = [{ label: "Win Percentage", value: WinPerc, legendItem: "A", color: "rgb(247, 180, 151)" },
  //   { label: "Lost Percentage", value: lostPerc, legendItem: "A", color: "rgb(226, 240, 217)" },
  //   ];
  //   // console.log(this.workSourceList,"worksource");
  //   this.VisaData = [{ label: "Visa Granted", value: Granted, legendItem: "A", color: "rgb(108, 96, 126)",files:GrantedFiles },
  //   { label: "Visa Refused", value: refused, legendItem: "A", color: "rgb(247, 223, 188)",files:refusedFiles },
  //   ];
  //   // console.log(matterList,"mlist");
  //   for (const [key, value] of Object.entries(pendingPaymentsByUsers)) {
  //     this.pendingBalanceData.push({ y: value["Name"], a: value["value"], files:value['files'] });

  //   }
  //   this.pendingBalanceData = this.pendingBalanceData.sort((a, b) => (a.a < b.a ? 1 : -1));
  //   for (const [key, value] of Object.entries(leadByStage)) {
  //     if (value["Name"].toUpperCase() === "WIN" || value["Name"].toUpperCase() === "DISCOVERY" || value["Name"].toUpperCase() === "QUALIFIED" || value["Name"].toUpperCase() === "NEGOTIATION" || value["Name"].toUpperCase() === "LOST") {
  //       this.leadPerStageData.push({ y: value["Name"].toUpperCase(), a: value["count"] ,files:value['Files'] });
  //     }
  //   }
  //   for (const [key, value] of Object.entries(leadByMatter)) {
  //     this.leadByMatterData.push({ Name: value["Name"], value: value["count"],files:value["files"] });

  //   }



  //   for (const [key, value] of Object.entries(LateFiles)) {
  //     this.lineData.push({ y: value["Name"], a: value["Files"] });

  //   }

  //   // console.log(this.lineData, "this.lineData");
  //   this.lateMatters = [];
  //   for (const [key, value] of Object.entries(LateMatter)) {
  //     this.lateMatters.push({ y: value["name"], a: value["value"] ,files:value["files"]});

  //   }

  //   console.log(this.lateMatters, "this.lateMatters");
  //   var mattertotal = 0
  //   for (const [key, value] of Object.entries(matterList)) {
  //     // var r;
  //     // r = ((1<<24)*Math.random() | 0).toString(16);
  //     // while (true) {

  //     //   if (arr.indexOf(r) === -1 && r!="ffffff") {
  //     //     arr.push(r);
  //     //     break;
  //     //   }
  //     // }
  //     mattertotal += value["value"];
  //     if (value['value'] > 0) {
  //       this.MatterData.push({ label: value["name"], value: value["value"], color: this.generateRandomColor(),
  //     files:value["files"]});
  //     }
  //   }
  //   this.MatterData = this.MatterData.sort((a, b) => (a.value < b.value ? 1 : -1));
  //   // this.MatterData.push({ total: mattertotal });


  //   // console.log(this.MatterData);
  //   this.overDueTodo = [];
  //   this.crrTodo = [];
  //   this.overDueTodo = this.lateTodo;
  //   this.crrTodo = this.CurrentTodo;
  //   // this.chartData = [
  //   //   { label: "Outstanding Balance", value: this.outStandingBalance, legendItem: "Outstanding Balance", color: "#376182" },
  //   //   { label: "Active Balance", value: this.ActiveBalance, legendItem: "Active Balance", color: "#6c607e" },
  //   //   { label: "Awaiting Balance", value: this.AwaitingBalance, legendItem: "Awaiting Balance", color: "#c36e83" },
  //   //   { label: "Draft Payment", value: this.agreedCost, legendItem: "Draft Balance", color: "#f0727d" },
  //   //   { label: "Disbursment in Balance", value: this.disIn, legendItem: "Awaiting Balance", color: "#f7b497" },
  //   //   { label: "Disbursment out Balance", value: this.disOut, legendItem: "Awaiting Balance", color: "#a6a6a6" },

  //   //   { label: "Pending Balance", value: this.pendingBalance, legendItem: "Pending Balance", color: "#d9d9d9" },
  //   // ];
  //   // this.chartData = {
  //   //   labels: ['TOB', 'TDB', 'AFB', 'DIA', 'DO'],
  //   //   datasets: [{
  //   //     label: 'Account Statistics',
  //   //     backgroundColor: [
  //   //       '#F7DFBC',
  //   //       '#3ddabb',
  //   //       '#32455A',
  //   //       '#fe7388',
  //   //       '#F1BE6A',

  //   //     ],
  //   //     yAxisID: 'y',
  //   //     data: [this.outStandingBalance, this.agreedCost, this.ActiveBalance, this.disIn, this.disOut]
  //   //   }]
  //   // }
  //   this.canvas = $('#myChart');
  //   this.ctx = this.canvas[0].getContext('2d');
  //   if (this.chart) {
  //     this.chart.destroy();
  //   }
  //   this.chart = new Chart(this.ctx, {
  //     type: 'bar',

  //     data: {
  //       labels: ['OB', 'DB', 'AFB', 'DI', 'DO'],
  //       datasets: [{
  //         // label: 'Account Statistics',
  //         borderRadius: 2,
  //         maxBarThickness: 20,
  //         backgroundColor: [
  //           '#ce6cf1',
  //           '#706661',
  //           '#32455A',
  //           '#fe7388',
  //           '#F1BE6A',
  //         ],
  //         yAxisID: 'y',
  //         data: [this.outStandingBalance, this.agreedCost, this.ActiveBalance, this.disIn, this.disOut]
  //       }]
  //     },
  //     options: {
  //       // scales: {
  //       //   xAxes: [{
  //       //     gridLines: {
  //       //       offsetGridLines: false
  //       //     }
  //       //   }]
  //       // },
  //       responsive: true,
  //       maintainAspectRatio: false,
  //       scales: {
  //         x: {
  //           grid: {
  //             display: false
  //           }
  //         },
  //         y: {
  //           beginAtZero: true,
  //           grid: {
  //             display: false
  //           }
  //         },


  //       },
  //       plugins: {
  //         legend: {
  //           display: false,
  //           labels: {
  //             color: '#495057',

  //           }
  //         },

  //       },
  //     }

  //   });


  //   // this.leadscanvas = this.leadsChart.nativeElement;
  //   // this.leadsctx = this.leadscanvas.getContext('2d');


  //   this.leadspstage();
  //   this.prevChart();
  //   this.currChart();
  //   this.outChart();
  //   this.accountB();
  //   this.pendingB();
  //   this.dealWInCHart();

  //   loaderActivation.loader = false;

  // }
  sleep(time) {
    return new Promise((resolve) => setTimeout(resolve, time));
  }
  accounts(){
    this.pendingClientAccountAmount = 0;
    this.clientAmountFiles = [];
    this.manchesterPrev = 0;
    this.londonPrev = 0;
    this.chesterPrev = 0;
    this.birminghamPrev = 0;
    this.manchesterCurrent = 0;
    this.londonCurrent = 0;
    this.chesterCurrent = 0;
    this.birminghamCurrent = 0;
    this.manchesterOutstanding = 0;
    this.londonOutstanding = 0;
    this.chesterOutstanding = 0;
    this.birminghamOutstanding = 0;

    this.OutStandingFiles = [];
    this.ActiveBalanceFiles = [];
    this.draftBalanceFiles = [];
    this.disOutFiles = [];
    this.disInFiles = [];
    this.pendingBalanceData = [];
    var pendingPaymentsByUsers = {}
    let self = this;

    this.cases.forEach(element => {




      // if(self.branchId===element.personResponsilbeBranchName){
      if (element.isDraft == true) {
        let deadline = new Date(element.deadLine);
        let current_date = new Date();

        let creationDate = new Date(element.creationTime)

        var p = self.checkPermission('Dashboard', 'level', self.branchId);
        var pcheck = false;
        var arr = [2];
        p.forEach(function (el) {
          if (arr.indexOf(parseInt(el)) > -1) {
            pcheck = true;
            return;
          }
        });
        var Acheck = false;
        var narr = [4];
        p.forEach(function (el) {
          if (narr.indexOf(parseInt(el)) > -1) {
            Acheck = true;
            return;
          }
        });
        var caseWorkersId = [];
        if ((element.personResposibleId == self.user.Id) || (self.user.RoleId == 1 || pcheck || Acheck)) {
          let disInCheck = false;
          let clientAmountCheck= false;
          let clientAmount= 0;
          let disInTotal =0;
          let disOutCheck = false;
          let disOutTotal =0;



          element.caseWorkerList.forEach(element1 => {
            if (element1.branchName === self.branchId || self.branchId === "All") {

              if(!pendingPaymentsByUsers.hasOwnProperty(element1.userId_id)){
              pendingPaymentsByUsers[element1.userId_id] = { value: 0, Name: element1.CaseWorkerName , files:[] };
              }
            }
          });
          if (self.branchId === element.personResponsilbeBranchName) {


            if (element.caseStatus === 0) {

              self.ActiveFiles.push(element);
              self.ActiveCases += 1;
            }
            // else if (element.caseStatus == 1) {
            //   if (current_date < deadline) {
            //     if ((element.personResposibleId == self.user.Id) || (self.user.RoleId == 1) || (!pcheck && !Acheck)) {
            //       this.CurrentTodo.push({ "Id": element.Id, "Text": element.matterName, "ItemId": element.fileNumber, "type": 1, "Date": element.deadLine });
            //     }
            //   }
            //   self.AwaitingCases += 1
            //   self.AwaitingFiles.push(element);

            // }

            // else if (element.caseStatus == 2) {
            //   if (current_date < deadline) {
            //     if ((element.personResposibleId == self.user.Id) || (self.user.RoleId == 1) || (!pcheck && !Acheck)) {
            //       this.CurrentTodo.push({ "Id": element.Id, "Text": element.matterName, "ItemId": element.fileNumber, "type": 1, "Date": element.deadLine });
            //     }
            //   }
            //   self.inCompletedCases += 1;
            //   self.awaitingCompletion.push(element);

            // }

            // var caseWorkersId = [];
            // element.caseWorkerList.forEach(element1 => {
            //   if (element1.status === 1) {
            //     self.pendingApprovals += 1;
            //     self.PendingApprovalFiles.push(element);
            //     return;
            //   }

            // });
          } else if (self.branchId === "All") {


            if (element.caseStatus === 0) {

              self.ActiveFiles.push(element);
              self.ActiveCases += 1;
            }
            // else if (element.caseStatus == 1) {
            //   if (current_date < deadline) {
            //     if ((element.personResposibleId == self.user.Id) || (self.user.RoleId == 1) || (!pcheck && !Acheck)) {
            //       this.CurrentTodo.push({ "Id": element.Id, "Text": element.matterName, "ItemId": element.fileNumber, "type": 1, "Date": element.deadLine });
            //     }
            //   }
            //   self.AwaitingCases += 1;

            //   self.AwaitingFiles.push(element);
            // }
            // else if (element.caseStatus == 2) {
            //   if (current_date < deadline) {
            //     if ((element.personResposibleId == self.user.Id) || (self.user.RoleId == 1) || (!pcheck && !Acheck)) {
            //       this.CurrentTodo.push({ "Id": element.Id, "Text": element.matterName, "ItemId": element.fileNumber, "type": 1, "Date": element.deadLine });
            //     }
            //   }
            //   self.inCompletedCases += 1;
            //   self.awaitingCompletion.push(element);

            // }

          }
          let invoiceCheck= false;
          let invoiceBal = 0;
          element['caseInvoice'].forEach(invoice => {
            if (self.branchId === "All") {
              if(invoice.isDraft && invoice.amountPaid){
                self.agreedCost += parseFloat(invoice.amountPaid);
                invoiceBal+=parseFloat(invoice.amountPaid);
                invoiceCheck= true;
              }

            } else {
              if (self.branchId === element.personResponsilbeBranchName) {
                if(invoice.isDraft && invoice.amountPaid){
                  self.agreedCost += parseFloat(invoice.amountPaid);
                  invoiceBal+=parseFloat(invoice.amountPaid);
                invoiceCheck= true;
                }
              }
            }

          });
          if(invoiceCheck){
            if(invoiceBal>0){
              element['invoiceBal']=invoiceBal;
              self.draftBalanceFiles.push(element);

            }
          }
          let outStandCheck=false;
          let activeBalanceCheck=false;
          let outBalance = 0;
          let activeBalance = 0;
          element['officeInvoice'].forEach(function (r) {

            var i = 0;
            let totalAmount = Number(r['amount'])
             let balance = totalAmount;
            r['officeAccount'].forEach(element1 => {
              if (element1['isInPayment'] && !element1['isDeleted']) {
                let amount = Number(element1['amount'])
                balance = balance - amount;

              }
            });
            r['balance'] = balance;
            if(r['bankAccount']==self.branchId){
              if (r.typeOfPayment === "Legal FEE" && (element.caseStatus == 0 || element.caseStatus == 1) && !Acheck) {
                self.outStandingBalance += parseFloat(r['balance']);
                outBalance+= parseFloat(r['balance']);
                outStandCheck= true;

              }
              if (r.typeOfPayment === "Legal FEE" && (element.caseStatus == 0) && !Acheck) {
                self.ActiveBalance += parseFloat(r['balance']);
                activeBalance +=parseFloat(r['balance']);
                activeBalanceCheck= true;
              }
            }
            else if(self.branchId === "All"){
              if (r.typeOfPayment === "Legal FEE" && (element.caseStatus == 0 || element.caseStatus == 1) && !Acheck) {
                self.outStandingBalance += parseFloat(r['balance']);
                outBalance+= parseFloat(r['balance']);
                outStandCheck= true;
              }
              if (r.typeOfPayment === "Legal FEE" && (element.caseStatus == 0) && !Acheck) {
                self.ActiveBalance += parseFloat(r['balance']);
                activeBalance +=parseFloat(r['balance']);
                activeBalanceCheck= true;
              }
            }
            // if()
            r['officeAccount'].forEach(office => {
              var account = 0;
              if (office.isInPayment) {
                let latest_date = new Date(office.entryDate);
                let date = new Date();
                var current_Month = (date.getMonth() - 1);
                var last_Month = latest_date.getMonth();

                if (office.bankAccount === "Manchester" && self.branchId == "Manchester" && office.typeOfPayment === "Legal FEE") {
                  if (last_Month === current_Month + 1 && latest_date.getFullYear() === date.getFullYear() && office.typeOfPayment === "Legal FEE") {
                    self.manchesterCurrent += parseFloat(office.amount);
                    console.log('This Month Sale ', element.Id, ' amount: ', office.amount)
                    var amount = parseFloat(office.amount);
                    var vat = amount;
                    if (element.vatStatus) {
                      vat = (amount * 0.8);
                    }
                    var data = {
                      "Case Id": element.Id, "FileNumber": element.fileNumber,
                      "ClientName": element.clientName,
                      "paymentIn": office.entryDate,
                      "Branch": office.bankAccount,
                      "AmountIn": amount,
                      "VAT": vat
                    }
                    self.thisMonthBalance.push(data);

                  }
                  else if (last_Month === current_Month && latest_date.getFullYear() === date.getFullYear()) {
                    self.manchesterPrev += parseFloat(office.amount);
                    console.log('Prev Month Sale ', element.Id, ' amount: ', office.amount)
                    var amount = parseFloat(office.amount);
                    var vat = amount;
                    if (element.vatStatus) {
                      vat = (amount * 0.8);
                    }
                    var data = {
                      "Case Id": element.Id, "FileNumber": element.fileNumber,
                      "ClientName": element.clientName,
                      "paymentIn": office.entryDate,
                      "Branch": office.bankAccount,
                      "AmountIn": amount,
                      "VAT": vat
                    }
                    self.prevMonthBalance.push(data);
                  }
                  if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0 || element.caseStatus == 1)) {
                    self.manchesterOutstanding += parseFloat(office.amount);
                    var amount = parseFloat(office.amount);
                    var vat = amount;
                    if (element.vatStatus) {
                      vat = (amount * 0.8);
                    }
                    var data = {
                      "Case Id": element.Id, "FileNumber": element.fileNumber,
                      "ClientName": element.clientName,
                      "paymentIn": office.entryDate,
                      "Branch": office.bankAccount,
                      "AmountIn": amount,
                      "VAT": vat
                    }
                    self.OutStandingBalance.push(data);
                  }
                } else if (office.bankAccount === "Chester" && self.branchId == "Chester" && office.typeOfPayment === "Legal FEE") {
                  if (last_Month === current_Month + 1 && latest_date.getFullYear() === date.getFullYear()) {
                    self.chesterCurrent += parseFloat(office.amount); var amount = parseFloat(office.amount);
                    console.log('OutStanding Balance ', element.Id, ' amount: ', office.amount)
                    var vat = amount;
                    if (element.vatStatus) {
                      vat = (amount * 0.8);
                    }
                    var data = {
                      "Case Id": element.Id, "FileNumber": element.fileNumber,
                      "ClientName": element.clientName,
                      "paymentIn": office.entryDate,
                      "Branch": office.bankAccount,
                      "AmountIn": amount,
                      "VAT": vat
                    }
                    self.thisMonthBalance.push(data);

                  }
                  else if (last_Month === current_Month && latest_date.getFullYear() === date.getFullYear()) {
                    self.chesterPrev += parseFloat(office.amount); var amount = parseFloat(office.amount);
                    var vat = amount;
                    if (element.vatStatus) {
                      vat = (amount * 0.8);
                    }
                    var data = {
                      "Case Id": element.Id, "FileNumber": element.fileNumber,
                      "ClientName": element.clientName,
                      "paymentIn": office.entryDate,
                      "Branch": office.bankAccount,
                      "AmountIn": amount,
                      "VAT": vat
                    }
                    self.prevMonthBalance.push(data);
                  }
                  if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0 || element.caseStatus == 1)) {
                    self.chesterOutstanding += parseFloat(office.amount);



                    var amount = parseFloat(office.amount);
                    var vat = amount;
                    if (element.vatStatus) {
                      vat = (amount * 0.8);
                    }
                    var data = {
                      "Case Id": element.Id, "FileNumber": element.fileNumber,
                      "ClientName": element.clientName,
                      "paymentIn": office.entryDate,
                      "Branch": office.bankAccount,
                      "AmountIn": amount,
                      "VAT": vat
                    }
                    self.OutStandingBalance.push(data);
                  }
                }
                else if (office.bankAccount === "London" && self.branchId == "London" && office.typeOfPayment === "Legal FEE") {
                  if (last_Month === current_Month + 1 && latest_date.getFullYear() === date.getFullYear()) {
                    self.londonCurrent += parseFloat(office.amount); var amount = parseFloat(office.amount);
                    var vat = amount;
                    if (element.vatStatus) {
                      vat = (amount * 0.8);
                    }
                    var data = {
                      "Case Id": element.Id, "FileNumber": element.fileNumber,
                      "ClientName": element.clientName,
                      "paymentIn": office.entryDate,
                      "Branch": office.bankAccount,
                      "AmountIn": amount,
                      "VAT": vat
                    }
                    self.thisMonthBalance.push(data);
                  }
                  else if (last_Month === current_Month && latest_date.getFullYear() === date.getFullYear()) {
                    self.londonPrev += parseFloat(office.amount); var amount = parseFloat(office.amount);
                    var vat = amount;
                    if (element.vatStatus) {
                      vat = (amount * 0.8);
                    }
                    var data = {
                      "Case Id": element.Id, "FileNumber": element.fileNumber,
                      "ClientName": element.clientName,
                      "paymentIn": office.entryDate,
                      "Branch": office.bankAccount,
                      "AmountIn": amount,
                      "VAT": vat
                    }
                    self.prevMonthBalance.push(data);
                  }
                  if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0 || element.caseStatus == 1)) {
                    self.londonOutstanding += parseFloat(office.amount);


                    var amount = parseFloat(office.amount);
                    var vat = amount;
                    if (element.vatStatus) {
                      vat = (amount * 0.8);
                    }
                    var data = {
                      "Case Id": element.Id, "FileNumber": element.fileNumber,
                      "ClientName": element.clientName,
                      "paymentIn": office.entryDate,
                      "Branch": office.bankAccount,
                      "AmountIn": amount,
                      "VAT": vat
                    }
                    self.OutStandingBalance.push(data);
                  }
                }
                else if (office.bankAccount === "Birmingham" && self.branchId == "Birmingham" && office.typeOfPayment === "Legal FEE") {
                  if (last_Month === current_Month + 1 && latest_date.getFullYear() === date.getFullYear()) {
                    self.birminghamCurrent += parseFloat(office.amount);
                    var amount = parseFloat(office.amount);
                    var vat = amount;
                    if (element.vatStatus) {
                      vat = (amount * 0.8);
                    }
                    var data = {
                      "Case Id": element.Id, "FileNumber": element.fileNumber,
                      "ClientName": element.clientName,
                      "paymentIn": office.entryDate,
                      "Branch": office.bankAccount,
                      "AmountIn": amount,
                      "VAT": vat
                    }
                    self.thisMonthBalance.push(data);
                  } else if (last_Month === current_Month && latest_date.getFullYear() === date.getFullYear()) {
                    self.birminghamPrev += parseFloat(office.amount); var amount = parseFloat(office.amount);
                    var vat = amount;
                    if (element.vatStatus) {
                      vat = (amount * 0.8);
                    }
                    var data = {
                      "Case Id": element.Id, "FileNumber": element.fileNumber,
                      "ClientName": element.clientName,
                      "paymentIn": office.entryDate,
                      "Branch": office.bankAccount,
                      "AmountIn": amount,
                      "VAT": vat
                    }
                    self.prevMonthBalance.push(data);
                  }
                  if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0 || element.caseStatus == 1)) {
                    self.birminghamOutstanding += parseFloat(office.amount);

                    var amount = parseFloat(office.amount);
                    var vat = amount;
                    if (element.vatStatus) {
                      vat = (amount * 0.8);
                    }
                    var data = {
                      "Case Id": element.Id, "FileNumber": element.fileNumber,
                      "ClientName": element.clientName,
                      "paymentIn": office.entryDate,
                      "Branch": office.bankAccount,
                      "AmountIn": amount,
                      "VAT": vat
                    }
                    self.OutStandingBalance.push(data);
                  }
                }
                if (office.bankAccount == self.branchId) {
                  // if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 1) && !Acheck) {
                  //   self.AwaitingBalance += parseFloat(office.amount);
                  // }
                  if (office.typeOfPayment === "Disbursement") {
                    if(office.isInPayment){
                      self.disIn += parseFloat(office.amount);
                      disInCheck= true;
                      disInTotal+=parseFloat(office.amount);
                    }
                    else{
                      self.disOut +=parseFloat(office.amount);
                      disOutCheck= true;
                      disOutTotal+=parseFloat(office.amount);
                    }

                  }
                  //  if (office.typeOfPayment == "Legal FEE" && !Acheck) {
                  //   // self.disOut +=parseFloat(office.amount);
                  //   account += parseFloat(office.amount);
                  //   if (r['officeAccount'].length - 1 === i) {
                  //     var total = parseFloat(r.amount) - account;
                  //     self.pendingBalance += total;
                  //   }
                  // }
                }
                else if (self.branchId === "All") {
                  if (office.bankAccount === "Manchester" && office.typeOfPayment === "Legal FEE") {
                    if (last_Month === current_Month + 1 && latest_date.getFullYear() === date.getFullYear()) {
                      self.manchesterCurrent += parseFloat(office.amount);
                      var amount = parseFloat(office.amount);
                      var vat = amount;
                      if (element.vatStatus) {
                        vat = (amount * 0.8);
                      }
                      var data = {
                        "Case Id": element.Id, "FileNumber": element.fileNumber,
                        "ClientName": element.clientName,
                        "paymentIn": office.entryDate,
                        "Branch": office.bankAccount,
                        "AmountIn": amount,
                        "VAT": vat
                      }
                      self.thisMonthBalance.push(data);
                    }
                    else if (last_Month === current_Month && latest_date.getFullYear() === date.getFullYear()) {
                      self.manchesterPrev += parseFloat(office.amount);
                      var amount = parseFloat(office.amount);
                      var vat = amount;
                      if (element.vatStatus) {
                        vat = (amount * 0.8);
                      }
                      var data = {
                        "Case Id": element.Id, "FileNumber": element.fileNumber,
                        "ClientName": element.clientName,
                        "paymentIn": office.entryDate,
                        "Branch": office.bankAccount,
                        "AmountIn": amount,
                        "VAT": vat
                      }
                      self.prevMonthBalance.push(data);
                    }
                    if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0 || element.caseStatus == 1)) {
                      self.manchesterOutstanding += parseFloat(office.amount);
                      var amount = parseFloat(office.amount);
                      var vat = amount;
                      if (element.vatStatus) {
                        vat = (amount * 0.8);
                      }
                      var data = {
                        "Case Id": element.Id, "FileNumber": element.fileNumber,
                        "ClientName": element.clientName,
                        "paymentIn": office.entryDate,
                        "Branch": office.bankAccount,
                        "AmountIn": amount,
                        "VAT": vat
                      }
                      self.OutStandingBalance.push(data);
                    }
                  } else if (office.bankAccount === "Chester" && office.typeOfPayment === "Legal FEE") {
                    if (last_Month === current_Month + 1 && latest_date.getFullYear() === date.getFullYear()) {
                      self.chesterCurrent += parseFloat(office.amount);
                      var amount = parseFloat(office.amount);
                      var vat = amount;
                      if (element.vatStatus) {
                        vat = (amount * 0.8);
                      }
                      var data = {
                        "Case Id": element.Id, "FileNumber": element.fileNumber,
                        "ClientName": element.clientName,
                        "paymentIn": office.entryDate,
                        "Branch": office.bankAccount,
                        "AmountIn": amount,
                        "VAT": vat
                      }
                      self.thisMonthBalance.push(data);
                    }
                    else if (last_Month === current_Month && latest_date.getFullYear() === date.getFullYear()) {
                      self.chesterPrev += parseFloat(office.amount);
                      var amount = parseFloat(office.amount);
                      var vat = amount;
                      if (element.vatStatus) {
                        vat = (amount * 0.8);
                      }
                      var data = {
                        "Case Id": element.Id, "FileNumber": element.fileNumber,
                        "ClientName": element.clientName,
                        "paymentIn": office.entryDate,
                        "Branch": office.bankAccount,
                        "AmountIn": amount,
                        "VAT": vat
                      }
                      self.prevMonthBalance.push(data);
                    }
                    if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0 || element.caseStatus == 1)) {
                      self.chesterOutstanding += parseFloat(office.amount);
                      var amount = parseFloat(office.amount);
                      var vat = amount;
                      if (element.vatStatus) {
                        vat = (amount * 0.8);
                      }
                      var data = {
                        "Case Id": element.Id, "FileNumber": element.fileNumber,
                        "ClientName": element.clientName,
                        "paymentIn": office.entryDate,
                        "Branch": office.bankAccount,
                        "AmountIn": amount,
                        "VAT": vat
                      }
                      self.OutStandingBalance.push(data);
                    }
                  }
                  else if (office.bankAccount === "London" && office.typeOfPayment === "Legal FEE") {
                    if (last_Month === current_Month + 1 && latest_date.getFullYear() === date.getFullYear()) {
                      self.londonCurrent += parseFloat(office.amount);
                      var amount = parseFloat(office.amount);
                      var vat = amount;
                      if (element.vatStatus) {
                        vat = (amount * 0.8);
                      }
                      var data = {
                        "Case Id": element.Id, "FileNumber": element.fileNumber,
                        "ClientName": element.clientName,
                        "paymentIn": office.entryDate,
                        "Branch": office.bankAccount,
                        "AmountIn": amount,
                        "VAT": vat
                      }
                      self.thisMonthBalance.push(data);
                    }
                    else if (last_Month === current_Month && latest_date.getFullYear() === date.getFullYear()) {
                      self.londonPrev += parseFloat(office.amount);
                      var amount = parseFloat(office.amount);
                      var vat = amount;
                      if (element.vatStatus) {
                        vat = (amount * 0.8);
                      }
                      var data = {
                        "Case Id": element.Id, "FileNumber": element.fileNumber,
                        "ClientName": element.clientName,
                        "paymentIn": office.entryDate,
                        "Branch": office.bankAccount,
                        "AmountIn": amount,
                        "VAT": vat
                      }
                      self.prevMonthBalance.push(data);
                    }
                    if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0 || element.caseStatus == 1)) {
                      self.londonOutstanding += parseFloat(office.amount);
                      var amount = parseFloat(office.amount);
                      var vat = amount;
                      if (element.vatStatus) {
                        vat = (amount * 0.8);
                      }
                      var data = {
                        "Case Id": element.Id, "FileNumber": element.fileNumber,
                        "ClientName": element.clientName,
                        "paymentIn": office.entryDate,
                        "Branch": office.bankAccount,
                        "AmountIn": amount,
                        "VAT": vat
                      }
                      self.OutStandingBalance.push(data);
                    }
                  }
                  else if (office.bankAccount === "Birmingham" && office.typeOfPayment === "Legal FEE") {
                    if (last_Month === current_Month + 1 && latest_date.getFullYear() === date.getFullYear()) {
                      self.birminghamCurrent += parseFloat(office.amount);
                      var amount = parseFloat(office.amount);
                      var vat = amount;
                      if (element.vatStatus) {
                        vat = (amount * 0.8);
                      }
                      var data = {
                        "Case Id": element.Id, "FileNumber": element.fileNumber,
                        "ClientName": element.clientName,
                        "paymentIn": office.entryDate,
                        "Branch": office.bankAccount,
                        "AmountIn": amount,
                        "VAT": vat
                      }
                      self.thisMonthBalance.push(data);
                    } else if (last_Month === current_Month && latest_date.getFullYear() === date.getFullYear()) {
                      self.birminghamPrev += parseFloat(office.amount);
                      var amount = parseFloat(office.amount);
                      var vat = amount;
                      if (element.vatStatus) {
                        vat = (amount * 0.8);
                      }
                      var data = {
                        "Case Id": element.Id, "FileNumber": element.fileNumber,
                        "ClientName": element.clientName,
                        "paymentIn": office.entryDate,
                        "Branch": office.bankAccount,
                        "AmountIn": amount,
                        "VAT": vat
                      }
                      self.prevMonthBalance.push(data);
                    }
                    if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0 || element.caseStatus == 1)) {
                      self.birminghamOutstanding += parseFloat(office.amount);
                      var amount = parseFloat(office.amount);
                      var vat = amount;
                      if (element.vatStatus) {
                        vat = (amount * 0.8);
                      }
                      var data = {
                        "Case Id": element.Id, "FileNumber": element.fileNumber,
                        "ClientName": element.clientName,
                        "paymentIn": office.entryDate,
                        "Branch": office.bankAccount,
                        "AmountIn": amount,
                        "VAT": vat
                      }
                      self.OutStandingBalance.push(data);
                    }
                  }
                  // if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0 || element.caseStatus == 1) && !Acheck) {
                  //   self.outStandingBalance += parseFloat(office.amount);
                  // }
                  // if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0) && !Acheck) {
                  //   self.ActiveBalance += parseFloat(office.amount);
                  // }
                  // if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 1) && !Acheck) {
                  //   self.AwaitingBalance += parseFloat(office.amount);
                  // }
                  if (office.typeOfPayment === "Disbursement") {
                    if(office.isInPayment){
                      self.disIn += parseFloat(office.amount);
                      disInCheck= true;
                      disInTotal+=parseFloat(office.amount);
                    }
                    else{
                      self.disOut +=parseFloat(office.amount);
                      disOutCheck= true;
                      disOutTotal+=parseFloat(office.amount);
                    }


                  } if (office.typeOfPayment == "Legal FEE" && !Acheck) {
                    // self.disOut +=parseFloat(office.amount);
                    account += parseFloat(office.amount);
                    if (r['officeAccount'].length - 1 === i) {
                      var total = parseFloat(r.amount) - account;
                      self.pendingBalance += total;

                    }
                  }

                }
              }
              else {
                if (office.typeOfPayment === "Disbursement") {
                  self.disOut += parseFloat(office.amount);
                  disOutCheck= true;
                  disOutTotal+=parseFloat(office.amount);
                }
                // if (office.typeOfPayment == "Legal FEE") {
                //   // self.disOut +=parseFloat(office.amount);
                //   account += parseFloat(office.amount);
                //   if (r['officeAccount'].length - 1 === i) {
                //     var total = parseFloat(r.amount) - account;
                //     self.pendingBalance += total;
                //   }
                // }
              }


              i += 1;
            });



            // if (r['officeAccount'].length == 0) {
            //   if (self.branchId === "All") {
            //     self.pendingBalance += parseFloat(r.amount);
            //   }
            //   else if (element.personResponsilbeBranchName === self.branchId) {
            //     self.pendingBalance += parseFloat(r.amount);
            //   }
            // }

          });
          if(outStandCheck){

            if(outBalance>0){
              element['outBalance']= outBalance;
              self.OutStandingFiles.push(element);
            }



          }

          if(activeBalanceCheck){
            if(activeBalance>0){
              element['activeBalance']=activeBalance;
              self.ActiveBalanceFiles.push(element);
            }

          }
          element['clientAccount'].forEach(function (client) {

            if(client.bankAccount == self.branchId){
              if(client.isInPayment && !client.isCompleted && !client.isDeleted){
                self.pendingClientAccountAmount+=  parseFloat(client.amount);
                clientAmount+=  parseFloat(client.amount);
                clientAmountCheck = true;
              }
              if(client.typeOfPayment === "Disbursement"){
                if(client.isInPayment){
                  self.disIn += parseFloat(client.amount);
                  disInCheck= true;
                      disInTotal+=parseFloat(client.amount);
                }
                else{
                  self.disOut += parseFloat(client.amount);
                  disOutCheck= true;
                  disOutTotal+=parseFloat(client.amount);
                }
              }
            }
            else if(self.branchId === "All"){
              if(client.isInPayment && !client.isCompleted && !client.isDeleted){
                self.pendingClientAccountAmount+=  parseFloat(client.amount);
                clientAmount+=  parseFloat(client.amount);
                clientAmountCheck = true;
              }
              if(client.typeOfPayment === "Disbursement"){
                if(client.isInPayment){
                  self.disIn += parseFloat(client.amount);
                  disInCheck= true;
                  disInTotal+=parseFloat(client.amount);
                }
                else{
                  self.disOut += parseFloat(client.amount);
                  disOutCheck= true;
                  disOutTotal+=parseFloat(client.amount);
                }
              }
            }
            // if (client.isInPayment) {
            //   if (client.bankAccount == self.branchId) {
            //     if (client.typeOfPayment === "Disbursement") {
            //       self.disIn += parseFloat(client.amount);
            //     } else {
            //       self.disOut += parseFloat(client.amount);
            //     }
            //   }
            //   else if (self.branchId === "All") {
            //     if (client.typeOfPayment === "Disbursement") {
            //       self.disIn += parseFloat(client.amount);
            //     } else {
            //       self.disOut += parseFloat(client.amount);
            //     }
            //   }
            // }
            // else {
            //   if (client.typeOfPayment === "Disbursement") {
            //     self.disOut += parseFloat(client.amount);
            //   }
            // }

          });
          if(element['outBalance']!=0){
            let userId =[]
            userId  = element.caseWorkerList.map(x=>x.userId_id);
            let uniqDic =[...new Set(userId)];
            uniqDic.forEach(el => {
              if (pendingPaymentsByUsers.hasOwnProperty(el)) {
                pendingPaymentsByUsers[el].value += parseFloat(element['outBalance']);
                // element['pendingBalance'] += parseFloat(self.pendingBalance);
                pendingPaymentsByUsers[el].files.push(element);
                // console.log(self.pendingBalance, pendingPaymentsByUsers[element1.userId_id].value,pendingPaymentsByUsers[element1.userId_id].Name)
              }
            });
          }

          // element.caseWorkerList.forEach(element1 => {
          //   if (pendingPaymentsByUsers.hasOwnProperty(element1.userId_id)) {
          //     pendingPaymentsByUsers[element1.userId_id].value += parseFloat(self.pendingBalance);
          //     element['pendingBalance'] += parseFloat(self.pendingBalance);
          //     pendingPaymentsByUsers[element1.userId_id].files.push(element);
          //     // console.log(self.pendingBalance, pendingPaymentsByUsers[element1.userId_id].value,pendingPaymentsByUsers[element1.userId_id].Name)
          //   }
          // });
            if(disOutCheck){
              if(disOutTotal>0){
                element['disOutTotal']=disOutTotal;
                self.disOutFiles.push(element);
              }
            }
            if(disInCheck){
              if(disInTotal>0){
                element['disInTotal']=disInTotal;
                self.disInFiles.push(element);
              }
            }
            if(clientAmountCheck){
              if(clientAmount>0){
                element['clientAmount']=clientAmount;
                self.clientAmountFiles.push(element);
              }

            }

        }
         else {
          let disInCheck = false;
          let disInTotal =0;
          let disOutCheck = false;
          let disOutTotal =0;

          // let deadline = new Date(element.deadLine);
          // let current_date = new Date();
          // if(current_date>deadline && element.caseStatus===0){
          //   self.lateFiles+=1;
          // }

          let deadline = new Date(element.deadLine);
          let current_date = new Date();
          var caseworkerIds = element.caseWorkerList.map(x => { return x.userId_id });
          // console.log(caseworkerIds,"CaseWorkerIds")
          var workersIndex = caseworkerIds.indexOf(self.user.Id) > -1;
          var p = self.checkPermission('Dashboard', 'level', self.branchId);
          var pcheck = false;
          var arr = [5, 6];
          p.forEach(function (el) {
            if (arr.indexOf(parseInt(el)) > -1) {
              pcheck = true;
              return;
            }
          });

          element.caseWorkerList.forEach(element1 => {
            if (element1.branchName === self.branchId || self.branchId === "All") {
              if(!pendingPaymentsByUsers.hasOwnProperty(element1.userId_id)){
                pendingPaymentsByUsers[element1.userId_id] = { value: 0, Name: element1.CaseWorkerName , files:[] };
                }
            }
          });

          if(element['outBalance']!=0){
            let userId =[]
            userId  = element.caseWorkerList.map(x=>x.userId_id);
            let uniqDic =[...new Set(userId)];
            uniqDic.forEach(el => {
              if (pendingPaymentsByUsers.hasOwnProperty(el)) {
                pendingPaymentsByUsers[el].value += parseFloat(element['outBalance']);
                // element['pendingBalance'] += parseFloat(self.pendingBalance);
                pendingPaymentsByUsers[el].files.push(element);
                // console.log(self.pendingBalance, pendingPaymentsByUsers[element1.userId_id].value,pendingPaymentsByUsers[element1.userId_id].Name)
              }
            });
          }

          if (workersIndex && (pcheck)) {
            if (self.branchId === element.personResponsilbeBranchName) {

              if (element.caseStatus === 0) {
                self.ActiveFiles.push(element);
                self.ActiveCases += 1;

              }
            //  else if (element.caseStatus == 1) {
            //     if (current_date < deadline) {
            //       if ((element.personResposibleId == self.user.Id) || (self.user.RoleId == 1) || (pcheck)) {
            //         this.CurrentTodo.push({ "Id": element.Id, "Text": element.matterName, "ItemId": element.fileNumber, "type": 1, "Date": element.deadLine });
            //       }
            //     }
            //     self.AwaitingCases += 1;

            //     self.AwaitingFiles.push(element);

            //   }
              // else if (element.caseStatus == 2) {
              //   if (current_date < deadline) {
              //     if ((element.personResposibleId == self.user.Id) || (self.user.RoleId == 1) || (pcheck)) {
              //       this.CurrentTodo.push({ "Id": element.Id, "Text": element.matterName, "ItemId": element.fileNumber, "type": 1, "Date": element.deadLine });
              //     }
              //   }
              //   self.inCompletedCases += 1;
              //   self.awaitingCompletion.push(element);

              // }
              // element.caseWorkerList.forEach(element1 => {
              //   if (element1.status === 1) {
              //     self.pendingApprovals += 1;
              //     self.PendingApprovalFiles.push(element);
              //     return;
              //   }
              // });
            }
            else if (self.branchId === "All") {
              // var index = matterIds.indexOf(element.matterId);
              // if (index > -1) {
              //   if (element.caseStatus != 3) {
              //     matterList[element.matterId].value += 1;
              //     matterList[element.matterId].name = element.matterName;
              //     matterList[element.matterId].files.push(element)

              //   }

              // }

              if (element.caseStatus === 0) {
                self.ActiveFiles.push(element);
                self.ActiveCases += 1;
              }
              // else if (element.caseStatus == 1) {
              //   if (current_date < deadline) {
              //     this.CurrentTodo.push({ "Id": element.Id, "Text": element.matterName, "ItemId": element.fileNumber, "type": 1, "Date": element.deadLine });

              //   }
              //   self.AwaitingCases += 1;
              //   self.AwaitingFiles.push(element);
              // }
              // else if (element.caseStatus == 2) {
              //   if (current_date < deadline) {
              //     this.CurrentTodo.push({ "Id": element.Id, "Text": element.matterName, "ItemId": element.fileNumber, "type": 1, "Date": element.deadLine });

              //   }
              //   self.inCompletedCases += 1;
              //   self.awaitingCompletion.push(element);
              // }
              // element.caseWorkerList.forEach(element1 => {
              //   if (element1.status === 1) {
              //     self.pendingApprovals += 1;
              //     self.PendingApprovalFiles.push(element);
              //     return;
              //   }
              // });
            }
            let invoiceCheck= false;
          let invoiceBal = 0;
            element['caseInvoice'].forEach(invoice => {
              if (self.branchId === "All") {
                if(invoice.isDraft && invoice.amountPaid){
                  self.agreedCost += parseFloat(invoice.amountPaid);
                  invoiceBal+= parseFloat(invoice.amountPaid);
                }
                // self.agreedCost += parseFloat(invoice.agreedCost);
              } else {
                if (self.branchId === element.personResponsilbeBranchName) {
                  if(invoice.isDraft && invoice.amountPaid){
                    self.agreedCost += parseFloat(invoice.amountPaid);
                    invoiceBal+= parseFloat(invoice.amountPaid);
                  }
                  // self.agreedCost += parseFloat(invoice.agreedCost);
                }
              }
            });
            if(invoiceCheck){
              if(invoiceBal>0){
                element['invoiceBal']=invoiceBal;
              self.draftBalanceFiles.push(element);

              }
            }
            let outStandCheck = false;
            let activeBalanceCheck = false;
            let outBalance=0;
            let activeBalance = 0;
            element['officeInvoice'].forEach(function (r) {
              var i = 0;
              let totalAmount = Number(r['amount'])
              let balance = totalAmount;
             r['officeAccount'].forEach(element1 => {
               if (element1['isInPayment'] && !element1['isDeleted']) {
                 let amount = Number(element1['amount'])
                 balance = balance - amount;

               }
             });
             r['balance'] = balance;
             if(r['bankAccount']==self.branchId){
               if (r.typeOfPayment === "Legal FEE" && (element.caseStatus == 0 || element.caseStatus == 1) && !Acheck) {
                 self.outStandingBalance += parseFloat(r['balance']);
                 outBalance+= parseFloat(r['balance']);
                 outStandCheck= true;

               }
               if (r.typeOfPayment === "Legal FEE" && (element.caseStatus == 0) && !Acheck) {
                 self.ActiveBalance += parseFloat(r['balance']);
                 activeBalance+= parseFloat(r['balance']);
                 activeBalanceCheck= true;
               }
             }
             else if(self.branchId === "All"){
               if (r.typeOfPayment === "Legal FEE" && (element.caseStatus == 0 || element.caseStatus == 1) && !Acheck) {
                 self.outStandingBalance += parseFloat(r['balance']);
                 outBalance+= parseFloat(r['balance']);

                 outStandCheck = true;
               }
               if (r.typeOfPayment === "Legal FEE" && (element.caseStatus == 0) && !Acheck) {
                 self.ActiveBalance += parseFloat(r['balance']);
                 activeBalance+= parseFloat(r['balance']);

                 activeBalanceCheck= true;
               }
             }
              r['officeAccount'].forEach(office => {
                var account = 0;
                if (office.isInPayment) {
                  let latest_date = new Date(office.entryDate);
                  let date = new Date();
                  var current_Month = (date.getMonth() - 1);
                  var last_Month = latest_date.getMonth();
                  if (office.bankAccount === "Manchester" && office.typeOfPayment === "Legal FEE") {
                    if (last_Month === current_Month + 1 && latest_date.getFullYear() === date.getFullYear()) {
                      self.manchesterCurrent += parseFloat(office.amount);
                    }
                    else if (last_Month === current_Month && latest_date.getFullYear() === date.getFullYear()) {
                      self.manchesterPrev += parseFloat(office.amount);
                    }
                    if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0 || element.caseStatus == 1)) {
                      self.manchesterOutstanding += parseFloat(office.amount);
                    }
                  } else if (office.bankAccount === "Chester" && office.typeOfPayment === "Legal FEE") {
                    if (last_Month === current_Month + 1 && latest_date.getFullYear() === date.getFullYear()) {
                      self.chesterCurrent += parseFloat(office.amount);
                    }
                    else if (last_Month === current_Month && latest_date.getFullYear() === date.getFullYear()) {
                      self.chesterPrev += parseFloat(office.amount);
                    }
                    if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0 || element.caseStatus == 1)) {
                      self.chesterOutstanding += parseFloat(office.amount);
                    }
                  }
                  else if (office.bankAccount === "London" && office.typeOfPayment === "Legal FEE") {
                    if (last_Month === current_Month + 1 && latest_date.getFullYear() === date.getFullYear()) {
                      self.londonCurrent += parseFloat(office.amount);
                    }
                    else if (last_Month === current_Month && latest_date.getFullYear() === date.getFullYear()) {
                      self.londonPrev += parseFloat(office.amount);
                    }
                    if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0 || element.caseStatus == 1)) {
                      self.londonOutstanding += parseFloat(office.amount);
                    }
                  }
                  else if (office.bankAccount === "Birmingham" && office.typeOfPayment === "Legal FEE") {
                    if (last_Month === current_Month + 1 && latest_date.getFullYear() === date.getFullYear()) {
                      self.birminghamCurrent += parseFloat(office.amount);
                    } else if (last_Month === current_Month && latest_date.getFullYear() === date.getFullYear()) {
                      self.birminghamPrev += parseFloat(office.amount);
                    }
                    if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0 || element.caseStatus == 1)) {
                      self.birminghamOutstanding += parseFloat(office.amount);
                    }
                  }
                  if (office.bankAccount == self.branchId) {
                    // if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0 || element.caseStatus == 1) && !Acheck) {
                    //   self.outStandingBalance += parseFloat(office.amount);
                    // }
                    // if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0) && !Acheck) {
                    //   self.ActiveBalance += parseFloat(office.amount);
                    // }
                    if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 1) && !Acheck) {
                      self.AwaitingBalance += parseFloat(office.amount);
                    } if (office.typeOfPayment === "Disbursement") {
                      if(office.isInPayment){
                        self.disIn += parseFloat(office.amount);
                        disInCheck=true;
                        disInTotal+=parseFloat(office.amount);
                      }
                      else{
                        self.disOut +=parseFloat(office.amount);
                        disOutCheck=true;
                        disOutTotal+=parseFloat(office.amount);
                      }

                    }
                    // if (office.typeOfPayment == "Legal FEE") {
                    //   // self.disOut +=parseFloat(office.amount);
                    //   account += parseFloat(office.amount);
                    //   if (r['officeAccount'].length - 1 === i) {
                    //     var total = parseFloat(r.amount) - account;
                    //     self.pendingBalance += total;
                    //   }
                    // }
                  } else if (self.branchId == "All") {
                    // if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0 || element.caseStatus == 1) && !Acheck) {
                    //   self.outStandingBalance += parseFloat(office.amount);
                    // }
                    // if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0) && !Acheck) {
                    //   self.ActiveBalance += parseFloat(office.amount);
                    // }
                    // if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 1) && !Acheck) {
                    //   self.AwaitingBalance += parseFloat(office.amount);
                    // }
                    if (office.typeOfPayment === "Disbursement") {
                      if(office.isInPayment){
                        self.disIn += parseFloat(office.amount);
                        disInCheck=true;
                        disInTotal+=parseFloat(office.amount);
                      }
                      else{
                        self.disOut += parseFloat(office.amount);
                        disOutCheck=true;
                        disOutTotal+=parseFloat(office.amount);
                      }

                    } else {
                      // self.disOut += parseFloat(office.amount);
                      // account += parseFloat(office.amount);
                      // if (r['officeAccount'].length - 1 === i) {
                      //   var total = parseFloat(r.amount) - account;
                      //   self.pendingBalance += total;
                      // }
                    }
                  }
                } else {
                  if (office.typeOfPayment === "Disbursement") {
                    self.disOut += parseFloat(office.amount);
                    disOutCheck=true;
                        disOutTotal+=parseFloat(office.amount);
                  } if (office.typeOfPayment == "Legal FEE") {
                    // self.disOut +=parseFloat(office.amount);
                    account += parseFloat(office.amount);
                    if (r['officeAccount'].length - 1 === i) {
                      var total = parseFloat(r.amount) - account;
                      self.pendingBalance += total;
                    }
                  }
                }
                i += 1;
              });

              if (r['officeAccount'].length == 0) {
                if (self.branchId === "All") {
                  self.pendingBalance += parseFloat(r.amount);
                }
                else if (element.personResponsilbeBranchName === self.branchId) {
                  self.pendingBalance += parseFloat(r.amount);
                }
              }





            });
            if(outStandCheck){
              if(outBalance>0){
                element['outBalance']= outBalance;
              self.OutStandingFiles.push(element);
              }

            }
            if(activeBalanceCheck){
              if(activeBalance>0){
                element['activeBalance']=activeBalance;
              self.ActiveBalanceFiles.push(element);
              }
            }
            element['clientAccount'].forEach(function (client) {
              if (client.isInPayment) {
                if (client.bankAccount == self.branchId) {
                  if (client.typeOfPayment === "Disbursement") {
                    self.disIn += parseFloat(client.amount);
                    disInCheck=true;
                        disInTotal+=parseFloat(client.amount);
                  } else {
                    self.disOut += parseFloat(client.amount);
                    disOutCheck=true;
                        disOutTotal+=parseFloat(client.amount);
                  }
                }
                else if (self.branchId == "All") {
                  if (client.typeOfPayment === "Disbursement") {
                    self.disIn += parseFloat(client.amount);
                    disInCheck=true;
                        disInTotal+=parseFloat(client.amount);
                  } else {
                    self.disOut += parseFloat(client.amount);
                    disOutCheck=true;
                    disOutTotal+=parseFloat(client.amount);

                  }
                }
              }

            });
          }
          // element.caseWorkerList.forEach(element1 => {
          //   if (element1.userId_id == self.user.Id && self.checkPermission('Dashboard','level',self.branchId)===5 && self.checkPermission('Dashboard','level',self.branchId)===6) {

          //     if (self.branchId === element.personResponsilbeBranchName) {
          //       var index = matterIds.indexOf(element.matterId);
          //       if (index > -1) {
          //         if(element.caseStatus===0)
          //     {    matterList[element.matterId].value += 1;
          //         matterList[element.matterId].name = element.matterName;}

          //       }

          //       if (current_date > deadline && element.caseStatus === 0) {
          //         self.lateFiles += 1;
          //       }
          //       if (element.caseStatus == 0) {
          //         self.ActiveCases += 1;
          //       } else if (element.caseStatus == 1) {
          //         self.AwaitingCases += 1
          //       }
          //       else if (element.caseStatus == 2) {
          //         self.inCompletedCases += 1
          //       }
          //       element.caseWorkerList.forEach(element1 => {
          //         if (element1.status === 1) {
          //           self.pendingApprovals += 1;
          //           return;
          //         }
          //       });
          //     } else if (self.branchId === "All") {
          //       var index = matterIds.indexOf(element.matterId);
          //       if (index > -1) {
          //         if(element.caseStatus===0)
          //        { matterList[element.matterId].value += 1;
          //         matterList[element.matterId].name = element.matterName;
          //       }

          //       }

          //       if (current_date > deadline && element.caseStatus === 0) {
          //         self.lateFiles += 1;
          //       }
          //       if (element.caseStatus == 0) {
          //         self.ActiveCases += 1;
          //       } else if (element.caseStatus == 1) {
          //         self.AwaitingCases += 1
          //       }
          //       else if (element.caseStatus == 2) {
          //         self.inCompletedCases += 1
          //       }
          //       element.caseWorkerList.forEach(element1 => {
          //         if (element1.status === 1) {
          //           self.pendingApprovals += 1;
          //           return;
          //         }
          //       });
          //     }
          //     element['caseInvoice'].forEach(invoice => {
          //       if (self.branchId === "All") {
          //         self.agreedCost += parseFloat(invoice.agreedCost);
          //       } else {
          //         if (self.branchId === element.personResponsilbeBranchName) {
          //           self.agreedCost += parseFloat(invoice.agreedCost);
          //         }
          //       }
          //     });

          //     element['officeInvoice'].forEach(function (r) {
          //       var i = 0;
          //       r['officeAccount'].forEach(office => {
          //         var account = 0;
          //         if (office.isInPayment) {
          //           let latest_date = new Date(office.entryDate);
          //           let date = new Date();
          //           var current_Month = (date.getMonth() - 1);
          //           var last_Month = latest_date.getMonth();
          //           if (office.bankAccount === "Manchester" && office.typeOfPayment === "Legal FEE") {
          //             if (last_Month === current_Month + 1 && latest_date.getFullYear() === date.getFullYear()) {
          //               self.manchesterCurrent += parseFloat(office.amount);
          //             }
          //             else if (last_Month === current_Month && latest_date.getFullYear() === date.getFullYear()) {
          //               self.manchesterPrev += parseFloat(office.amount);
          //             }
          //             if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0 || element.caseStatus == 1)) {
          //               self.manchesterOutstanding += parseFloat(office.amount);
          //             }
          //           } else if (office.bankAccount === "Chester" && office.typeOfPayment === "Legal FEE") {
          //             if (last_Month === current_Month + 1 && latest_date.getFullYear() === date.getFullYear()) {
          //               self.chesterCurrent += parseFloat(office.amount);
          //             }
          //             else if (last_Month === current_Month && latest_date.getFullYear() === date.getFullYear()) {
          //               self.chesterPrev += parseFloat(office.amount);
          //             }
          //             if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0 || element.caseStatus == 1)) {
          //               self.chesterOutstanding += parseFloat(office.amount);
          //             }
          //           }
          //           else if (office.bankAccount === "London" && office.typeOfPayment === "Legal FEE") {
          //             if (last_Month === current_Month + 1 && latest_date.getFullYear() === date.getFullYear()) {
          //               self.londonCurrent += parseFloat(office.amount);
          //             }
          //             else if (last_Month === current_Month && latest_date.getFullYear() === date.getFullYear()) {
          //               self.londonPrev += parseFloat(office.amount);
          //             }
          //             if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0 || element.caseStatus == 1)) {
          //               self.londonOutstanding += parseFloat(office.amount);
          //             }
          //           }
          //           else if (office.bankAccount === "Birmingham" && office.typeOfPayment === "Legal FEE") {
          //             if (last_Month === current_Month + 1 && latest_date.getFullYear() === date.getFullYear()) {
          //               self.birminghamCurrent += parseFloat(office.amount);
          //             } else if (last_Month === current_Month && latest_date.getFullYear() === date.getFullYear()) {
          //               self.birminghamPrev += parseFloat(office.amount);
          //             }
          //             if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0 || element.caseStatus == 1)) {
          //               self.birminghamOutstanding += parseFloat(office.amount);
          //             }
          //           }
          //           if (office.bankAccount == self.branchId) {
          //             if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0 || element.caseStatus == 1)) {
          //               self.outStandingBalance += parseFloat(office.amount);
          //             }
          //             if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0)) {
          //               self.ActiveBalance += parseFloat(office.amount);
          //             }
          //             if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 1)) {
          //               self.AwaitingBalance += parseFloat(office.amount);
          //             } if (office.typeOfPayment === "Disbursement") {
          //               self.disIn += parseFloat(office.amount);
          //             } if (office.typeOfPayment == "Legal FEE") {
          //               // self.disOut +=parseFloat(office.amount);
          //               account += parseFloat(office.amount);
          //               if (r['officeAccount'].length - 1 === i) {
          //                 var total = parseFloat(r.amount) - account;
          //                 self.pendingBalance += total;
          //               }
          //             }
          //           } else if (self.branchId == "All") {
          //             if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0 || element.caseStatus == 1)) {
          //               self.outStandingBalance += parseFloat(office.amount);
          //             }
          //             if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0)) {
          //               self.ActiveBalance += parseFloat(office.amount);
          //             }
          //             if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 1)) {
          //               self.AwaitingBalance += parseFloat(office.amount);
          //             }
          //             if (office.typeOfPayment === "Disbursement") {
          //               self.disIn += parseFloat(office.amount);
          //             } else {
          //               self.disOut += parseFloat(office.amount);
          //               account += parseFloat(office.amount);
          //               if (r['officeAccount'].length - 1 === i) {
          //                 var total = parseFloat(r.amount) - account;
          //                 self.pendingBalance += total;
          //               }
          //             }
          //           }
          //         } else {
          //           if (office.typeOfPayment === "Disbursement") {
          //             self.disOut += parseFloat(office.amount);
          //           } if (office.typeOfPayment == "Legal FEE") {
          //             // self.disOut +=parseFloat(office.amount);
          //             account += parseFloat(office.amount);
          //             if (r['officeAccount'].length - 1 === i) {
          //               var total = parseFloat(r.amount) - account;
          //               self.pendingBalance += total;
          //             }
          //           }
          //         }
          //         i += 1;
          //       });







          //     });
          //     element['caseInvoice'].forEach(function (client) {
          //       if (client.isInPayment) {
          //         if (client.bankAccount == self.branchId) {
          //           if (client.typeOfPayment === "Disbursement") {
          //             self.disIn += parseFloat(client.amount);
          //           } else {
          //             self.disOut += parseFloat(client.amount);
          //           }
          //         }
          //         else if (self.branchId == "All") {
          //           if (client.typeOfPayment === "Disbursement") {
          //             self.disIn += parseFloat(client.amount);
          //           } else {
          //             self.disOut += parseFloat(client.amount);
          //           }
          //         }
          //       }

          //     });
          //     return;
          //   }


          // });
          // var index = matterIds.indexOf(element.matterId);
          // if (index > -1) {
          //   matterList[element.matterId].value += 1;
          //   matterList[element.matterId].name = element.matterName;

          // }
          if(disOutCheck){
            if(disOutTotal>0){
              element['disOutTotal']=disOutTotal;
              self.disOutFiles.push(element);
            }
          }
          if(disInCheck){
            if(disInTotal>0){
              element['disInTotal']=disInTotal;
              self.disInFiles.push(element);
            }
          }
        }

      }
      else {
        var p = self.checkPermission('Dashboard', 'level', self.branchId);
        var pcheck = false;
        var arr = [1, 2, 3];
        p.forEach(function (el) {
          if (arr.indexOf(parseInt(el)) > -1) {
            pcheck = true;
            return;
          }
        });
        let deadline = new Date(element.deadLine);
        let current_date = new Date();
        // if (pcheck) {
        //   this.retainers += 1;
        //   if (current_date > deadline) {
        //     this.lateTodo.push({ "Id": element.Id, "Text": element.matterName, "ItemId": "Client Name : " + element.clientName, "type": 2, "Date": element.deadLine });

        //   } else {
        //     this.CurrentTodo.push({ "Id": element.Id, "Text": element.matterName, "ItemId": "Client Name : " + element.clientName, "type": 2, "Date": element.deadLine });

        //   }
        //   this.retainer.push(element);
        // }
        // if (self.branchId === element.personResponsilbeBranchName || element.personResponsilbeBranchName == null) {
        //   this.retainers += 1;
        //   this.retainer.push(element);
        //   this.CurrentTodo.push({ "Id": element.Id, "Text": element.matterName, "ItemId": "Client Name : " + element.clientName, "type": 2, "Date": element.deadLine });
        // } else if (self.branchId === "All") {
        //   this.retainers += 1;
        //   this.retainer.push(element);
        //   this.CurrentTodo.push({ "Id": element.Id, "Text": element.matterName, "ItemId": "Client Name : " + element.clientName, "type": 2, "Date": element.deadLine });
        // }
      }
      // }else if(this.branchId === "All"){
      //   if(element.isDraft == true){
      //   if ((element.personResposibleId == self.user.Id || self.user.RoleId == 1)  ) {

      //     let deadline = new Date(element.deadLine);
      //     let current_date = new Date();
      //     if(current_date>deadline && element.caseStatus===0){
      //       self.lateFiles+=1;
      //     }
      //     if (element.caseStatus == 0) {
      //       self.ActiveCases += 1;
      //     } else if (element.caseStatus == 1) {
      //       self.AwaitingCases += 1
      //     }
      //     else if (element.caseStatus == 2) {
      //       self.inCompletedCases += 1
      //     }
      //     element.caseWorkerList.forEach(element1 => {
      //       if(element1.status===1){
      //     self.pendingApprovals+=1;
      //     return;
      //   }
      //   });
      //     element['caseInvoice'].forEach(invoice => {
      //       if (self.branchId === "All") {

      //         self.agreedCost += parseFloat(invoice.agreedCost);
      //         }else{
      //           if(self.branchId===element.personResponsilbeBranchName)
      //           {
      //             self.agreedCost += parseFloat(invoice.agreedCost);
      //           }
      //         }
      //     });
      //     element['officeInvoice'].forEach(function (r) {

      //       var i = 0;
      //       r['officeAccount'].forEach(office => {
      //         var account = 0;
      //         if (office.isInPayment) {
      //           let latest_date = new Date(office.entryDate);
      //           let date = new Date();
      //           var current_Month = (date.getMonth() - 1);
      //           var last_Month = latest_date.getMonth();
      //           if (office.bankAccount === "Manchester" && office.typeOfPayment === "Legal FEE") {
      //             if (last_Month === current_Month + 1 && latest_date.getFullYear() === date.getFullYear()) {
      //               self.manchesterCurrent += parseFloat(office.amount);
      //             }
      //             else if (last_Month === current_Month && latest_date.getFullYear() === date.getFullYear()) {
      //               self.manchesterPrev += parseFloat(office.amount);
      //             }
      //             if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0 || element.caseStatus == 1)) {
      //               self.manchesterOutstanding += parseFloat(office.amount);
      //             }
      //           } else if (office.bankAccount === "Chester" && office.typeOfPayment === "Legal FEE") {
      //             if (last_Month === current_Month + 1 && latest_date.getFullYear() === date.getFullYear()) {
      //               self.chesterCurrent += parseFloat(office.amount);
      //             }
      //             else if (last_Month === current_Month && latest_date.getFullYear() === date.getFullYear()) {
      //               self.chesterPrev += parseFloat(office.amount);
      //             }
      //             if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0 || element.caseStatus == 1)) {
      //               self.chesterOutstanding += parseFloat(office.amount);
      //             }
      //           }
      //           else if (office.bankAccount === "London" && office.typeOfPayment === "Legal FEE") {
      //             if (last_Month === current_Month + 1 && latest_date.getFullYear() === date.getFullYear()) {
      //               self.londonCurrent += parseFloat(office.amount);
      //             }
      //             else if (last_Month === current_Month && latest_date.getFullYear() === date.getFullYear()) {
      //               self.londonPrev += parseFloat(office.amount);
      //             }
      //             if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0 || element.caseStatus == 1)) {
      //               self.londonOutstanding += parseFloat(office.amount);
      //             }
      //           }
      //           else if (office.bankAccount === "Birmingham" && office.typeOfPayment === "Legal FEE") {
      //             if (last_Month === current_Month + 1 && latest_date.getFullYear() === date.getFullYear()) {
      //               self.birminghamCurrent += parseFloat(office.amount);
      //             } else if (last_Month === current_Month && latest_date.getFullYear() === date.getFullYear()) {
      //               self.birminghamPrev += parseFloat(office.amount);
      //             }
      //             if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0 || element.caseStatus == 1)) {
      //               self.birminghamOutstanding += parseFloat(office.amount);
      //             }
      //           }

      //           if (office.bankAccount == self.branchId) {
      //             if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0 || element.caseStatus == 1)) {
      //               self.outStandingBalance += parseFloat(office.amount);
      //             }
      //             if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0)) {
      //               self.ActiveBalance += parseFloat(office.amount);
      //             }
      //             if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 1)) {
      //               self.AwaitingBalance += parseFloat(office.amount);
      //             }
      //             if (office.typeOfPayment === "Disbursement") {
      //               self.disIn += parseFloat(office.amount);
      //             } if (office.typeOfPayment == "Legal FEE") {
      //               // self.disOut +=parseFloat(office.amount);
      //               account += parseFloat(office.amount);
      //               if (r['officeAccount'].length - 1 === i) {
      //                 var total = parseFloat(r.amount) - account;
      //                 self.pendingBalance += total;
      //               }
      //             }
      //           } else if (self.branchId === "All") {

      //             if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0 || element.caseStatus == 1)) {
      //               self.outStandingBalance += parseFloat(office.amount);
      //             }
      //             if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0)) {
      //               self.ActiveBalance += parseFloat(office.amount);
      //             }
      //             if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 1)) {
      //               self.AwaitingBalance += parseFloat(office.amount);
      //             }
      //             if (office.typeOfPayment === "Disbursement") {
      //               self.disIn += parseFloat(office.amount);
      //             } if (office.typeOfPayment == "Legal FEE") {
      //               // self.disOut +=parseFloat(office.amount);
      //               account += parseFloat(office.amount);
      //               if (r['officeAccount'].length - 1 === i) {
      //                 var total = parseFloat(r.amount) - account;
      //                 self.pendingBalance += total;
      //               }
      //             }

      //           }}
      //            else {
      //             if (office.typeOfPayment === "Disbursement") {
      //               self.disOut += parseFloat(office.amount);
      //             } if (office.typeOfPayment == "Legal FEE") {
      //               // self.disOut +=parseFloat(office.amount);
      //               account += parseFloat(office.amount);
      //               if (r['officeAccount'].length - 1 === i) {
      //                 var total = parseFloat(r.amount) - account;
      //                 self.pendingBalance += total;
      //               }
      //             }
      //           }


      //           i += 1;
      //         });





      //     });
      //     element['caseInvoice'].forEach(function (client) {
      //       if (client.isInPayment) {
      //         if (client.bankAccount == self.branchId) {
      //           if (client.typeOfPayment === "Disbursement") {
      //             self.disIn += parseFloat(client.amount);
      //           } else {
      //             self.disOut += parseFloat(client.amount);
      //           }
      //         }
      //         else if (self.branchId === "All") {
      //           if (client.typeOfPayment === "Disbursement") {
      //             self.disIn += parseFloat(client.amount);
      //           } else {
      //             self.disOut += parseFloat(client.amount);
      //           }
      //         }
      //       } else {
      //         if (client.typeOfPayment === "Disbursement") {
      //           self.disOut += parseFloat(client.amount);
      //         }
      //       }

      //     });
      //     var index = matterIds.indexOf(element.matterId);
      //     if (index > -1) {
      //       matterList[element.matterId].value += 1;
      //       matterList[element.matterId].name = element.matterName;

      //     }

      //   } else {

      //       let deadline = new Date(element.deadLine);
      //       let current_date = new Date();
      //       if(current_date>deadline && element.caseStatus===0){
      //         self.lateFiles+=1;
      //       }
      //       element.caseWorkerList.forEach(element1 => {
      //         if (element1.userId == self.user.Id) {
      //           if (element.caseStatus == 0) {
      //             self.ActiveCases += 1;
      //             // return;
      //           } else if (element.caseStatus == 1) {
      //             self.AwaitingCases += 1;
      //             // return;
      //           }
      //           else if (element.caseStatus == 2) {
      //             self.inCompletedCases += 1;
      //             // return;
      //           }
      //           element['caseInvoice'].forEach(invoice => {
      //             if (self.branchId === "All") {
      //             self.agreedCost += parseFloat(invoice.agreedCost);
      //             }else{
      //               if(self.branchId===element.personResponsilbeBranchName)
      //               {
      //                 self.agreedCost += parseFloat(invoice.agreedCost);
      //               }
      //             }
      //           });

      //           element['officeInvoice'].forEach(function (r) {
      //             var i = 0;
      //             r['officeAccount'].forEach(office => {
      //               var account = 0;
      //               if (office.isInPayment) {
      //                 let latest_date = new Date(office.entryDate);
      //                 let date = new Date();
      //                 var current_Month = (date.getMonth() - 1);
      //                 var last_Month = latest_date.getMonth();
      //                 if (office.bankAccount === "Manchester" && office.typeOfPayment === "Legal FEE") {
      //                   if (last_Month === current_Month + 1 && latest_date.getFullYear() === date.getFullYear()) {
      //                     self.manchesterCurrent += parseFloat(office.amount);
      //                   }
      //                   else if (last_Month === current_Month && latest_date.getFullYear() === date.getFullYear()) {
      //                     self.manchesterPrev += parseFloat(office.amount);
      //                   }
      //                   if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0 || element.caseStatus == 1)) {
      //                     self.manchesterOutstanding += parseFloat(office.amount);
      //                   }
      //                 } else if (office.bankAccount === "Chester" && office.typeOfPayment === "Legal FEE") {
      //                   if (last_Month === current_Month + 1 && latest_date.getFullYear() === date.getFullYear()) {
      //                     self.chesterCurrent += parseFloat(office.amount);
      //                   }
      //                   else if (last_Month === current_Month && latest_date.getFullYear() === date.getFullYear()) {
      //                     self.chesterPrev += parseFloat(office.amount);
      //                   }
      //                   if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0 || element.caseStatus == 1)) {
      //                     self.chesterOutstanding += parseFloat(office.amount);
      //                   }
      //                 }
      //                 else if (office.bankAccount === "London" && office.typeOfPayment === "Legal FEE") {
      //                   if (last_Month === current_Month + 1 && latest_date.getFullYear() === date.getFullYear()) {
      //                     self.londonCurrent += parseFloat(office.amount);
      //                   }
      //                   else if (last_Month === current_Month && latest_date.getFullYear() === date.getFullYear()) {
      //                     self.londonPrev += parseFloat(office.amount);
      //                   }
      //                   if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0 || element.caseStatus == 1)) {
      //                     self.londonOutstanding += parseFloat(office.amount);
      //                   }
      //                 }
      //                 else if (office.bankAccount === "Birmingham" && office.typeOfPayment === "Legal FEE") {
      //                   if (last_Month === current_Month + 1 && latest_date.getFullYear() === date.getFullYear()) {
      //                     self.birminghamCurrent += parseFloat(office.amount);
      //                   } else if (last_Month === current_Month && latest_date.getFullYear() === date.getFullYear()) {
      //                     self.birminghamPrev += parseFloat(office.amount);
      //                   }
      //                   if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0 || element.caseStatus == 1)) {
      //                     self.birminghamOutstanding += parseFloat(office.amount);
      //                   }
      //                 }
      //                 if (office.bankAccount == self.branchId) {
      //                   if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0 || element.caseStatus == 1)) {
      //                     self.outStandingBalance += parseFloat(office.amount);
      //                   }
      //                   if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0)) {
      //                     self.ActiveBalance += parseFloat(office.amount);
      //                   }
      //                   if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 1)) {
      //                     self.AwaitingBalance += parseFloat(office.amount);
      //                   } if (office.typeOfPayment === "Disbursement") {
      //                     self.disIn += parseFloat(office.amount);
      //                   } if (office.typeOfPayment == "Legal FEE") {
      //                     // self.disOut +=parseFloat(office.amount);
      //                     account += parseFloat(office.amount);
      //                     if (r['officeAccount'].length - 1 === i) {
      //                       var total = parseFloat(r.amount) - account;
      //                       self.pendingBalance += total;
      //                     }
      //                   }
      //                 } else if (self.branchId == "All") {
      //                   if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0 || element.caseStatus == 1)) {
      //                     self.outStandingBalance += parseFloat(office.amount);
      //                   }
      //                   if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 0)) {
      //                     self.ActiveBalance += parseFloat(office.amount);
      //                   }
      //                   if (office.typeOfPayment === "Legal FEE" && (element.caseStatus == 1)) {
      //                     self.AwaitingBalance += parseFloat(office.amount);
      //                   }
      //                   if (office.typeOfPayment === "Disbursement") {
      //                     self.disIn += parseFloat(office.amount);
      //                   } else {
      //                     self.disOut += parseFloat(office.amount);
      //                     account += parseFloat(office.amount);
      //                     if (r['officeAccount'].length - 1 === i) {
      //                       var total = parseFloat(r.amount) - account;
      //                       self.pendingBalance += total;
      //                     }
      //                   }
      //                 }
      //               } else {
      //                 if (office.typeOfPayment === "Disbursement") {
      //                   self.disOut += parseFloat(office.amount);
      //                 } if (office.typeOfPayment == "Legal FEE") {
      //                   // self.disOut +=parseFloat(office.amount);
      //                   account += parseFloat(office.amount);
      //                   if (r['officeAccount'].length - 1 === i) {
      //                     var total = parseFloat(r.amount) - account;
      //                     self.pendingBalance += total;
      //                   }
      //                 }
      //               }
      //               i += 1;
      //             });







      //           });
      //           element['caseInvoice'].forEach(function (client) {
      //             if (client.isInPayment) {
      //               if (client.bankAccount == self.branchId) {
      //                 if (client.typeOfPayment === "Disbursement") {
      //                   self.disIn += parseFloat(client.amount);
      //                 } else {
      //                   self.disOut += parseFloat(client.amount);
      //                 }
      //               }
      //               else if (self.branchId == "All") {
      //                 if (client.typeOfPayment === "Disbursement") {
      //                   self.disIn += parseFloat(client.amount);
      //                 } else {
      //                   self.disOut += parseFloat(client.amount);
      //                 }
      //               }
      //             }

      //           });
      //           return;
      //         }


      //       });
      //       var index = matterIds.indexOf(element.matterId);
      //       if (index > -1) {
      //         matterList[element.matterId].value += 1;
      //         matterList[element.matterId].name = element.matterName;

      //       }

      //   }
      // }
      // else{
      //   this.retainers+=1;
      // }
      // }
    });


    for (const [key, value] of Object.entries(pendingPaymentsByUsers)) {
      this.pendingBalanceData.push({ y: value["Name"], a: value["value"], files:value['files'] });

    }
    this.pendingBalanceData = this.pendingBalanceData.sort((a, b) => (a.a < b.a ? 1 : -1));
    this.prevSale = { 'color': '#57a7fe', 'label': 'Manchester', 'value': this.manchesterPrev }
    this.currSale = { 'color': '#57a7fe', 'label': 'Manchester', 'value': this.manchesterCurrent }
    this.outBalance = { 'color': '#57a7fe', 'label': 'Manchester', 'value': this.manchesterOutstanding }
    this.accountB();
    this.pendingB();
    this.prevChart();
    this.currChart();
    this.outChart();
  }
  dealWInCHart() {


    // this.outcanvas1 = this.outSalechart.nativeElement;
    var val = this.leadPerStageWinLostData[0].value === 0 && this.leadPerStageWinLostData[1].value === 0
    if (val) {
      $('#dealWinDiv').css({ "display": "none", "height": "0 !important", "width": "0 !important" });
    } else {
      $('#dealWinDiv').css({ "display": "block", "height": "0 !important", "width": "0 !important" });
      try {
        this.dealchart1.destroy();
        this.dealchart1.clear();
      } catch {

      }
      this.sleep(500).then(() => {
        this.dealcanvas1 = $('#dealWin');
        this.dealctx1 = this.dealcanvas1[0].getContext('2d');
        let _data2 = this.leadPerStageWinLostData.map(x => x.value);



        this.dealchart1 = new Chart(this.dealctx1, {
          type: 'doughnut',

          data: {
            labels: ["Deal Won %", "Deal Lost %"],
            datasets: [{
              // label: 'Account Statistics',
              borderWidth: 1,
              backgroundColor: ['#43DBDE', '#DCDCDC'],

              data: _data2,
              // data: [0, 1, 1, 1]
            }]
          },
          options: {
            // scales: {
            //   xAxes: [{
            //     gridLines: {
            //       offsetGridLines: false
            //     }
            //   }]
            // },
            responsive: true,
            maintainAspectRatio: false,
            cutout: 90,
            plugins: {
              legend: {
                display: false,
                labels: {
                  color: '#495057',

                }
              },

            },
          }


        });
      })
    }
  }
  checkLeadperstage() {
    let check = false;
    if (this.leadPerStageData) {
      this.leadPerStageData.forEach(element => {
        if (element.a != 0) {
          check = true
          return true
        }
      });
      if (check) {
        return false
      } else {
        return true;
      }
    }
    else {
      return true
    }
  }
  leadspstage() {
    let val = this.checkLeadperstage();

    if (val) {
      $('#leadsDiv').css({ "display": "none", "height": "0 !important", "width": "0 !important" });
    } else {
      $('#leadsDiv').css({ "display": "block", "height": "0 !important", "width": "0 !important" });
      this.sleep(500).then(() => {
        this.leadscanvas = $('#leads');
        this.leadsctx = this.leadscanvas[0].getContext('2d');
        // if (this.leadsChart != undefined) {
        //
        // }
        try {
          this.leadsChart.destroy();
        }
        catch {

        }
        this.leadsLabel =[]
        var lb = this.leadPerStageData.map(x => x.y);
        let color =[
          '#ce6cf1',
          '#3ddabb',
          '#706661',
          '#fe7388',
          '#F1BE6A',
          '#69993a',
          '#57a7fe',
        ]
        let index =0;
        lb.forEach(element => {
          this.leadsLabel.push({
            label: element,
            color: color[index]
          })
          index++;
        });
        index =0;
        this.leadPerStageData.forEach(element => {
          element['color'] = color[index];


          index++;

        });
        // this.leadsLabel = lb;
        var ldata = this.leadPerStageData.map(x => x.a)
        this.leadsChart = new Chart('leads', {
          type: 'bar',

          data: {
            // labels: ['WIN', 'DISCOVERY', 'QUALIFIED', 'NEGOTIATION', 'LOST'],
            labels: lb,
            datasets: [{
              // label: 'Account Statistics',
              borderRadius: 2,
              maxBarThickness: 20,
              backgroundColor: [
                '#ce6cf1',
                '#3ddabb',
                '#706661',
                '#fe7388',
                '#F1BE6A',
                '#69993a',
                '#57a7fe',
              ],
              yAxisID: 'y',
              data: ldata
            }]
          },
          options: {
            // scales: {
            //   xAxes: [{
            //     gridLines: {
            //       offsetGridLines: false
            //     }
            //   }]
            // },
            responsive: true,
            maintainAspectRatio: false,
            scales: {
              x: {
                grid: {
                  display: false
                }
              },
              y: {
                beginAtZero: true,
                grid: {
                  display: false
                }
              },


            },
            plugins: {
              legend: {
                display: false,
                labels: {
                  color: '#495057',

                }
              },

            },
          }
        });
      });
    }
  }
  prevChart() {
    $('#prevSaleDiv').css({ "display": "block", "height": "0 !important", "width": "0 !important" });
    try {
      this.prevSalechart.destroy()
    } catch {

    }

    this.pscanvas1 = $('#prevSalechart');
    var pv = this.manchesterPrev === 0 && this.birminghamPrev === 0 && this.chesterPrev === 0 && this.londonPrev === 0
    if (pv) {
      $('#prevSaleDiv').css({ "display": "none", "height": "0 !important", "width": "0 !important" });
    }
    this.psctx1 = this.pscanvas1[0].getContext('2d');
    let _data = [this.manchesterPrev, this.birminghamPrev, this.londonPrev, this.chesterPrev]

    this.prevSalechart = $('#prevSalechart');
    this.prevSalechart = new Chart(this.psctx1, {
      type: 'doughnut',

      data: {
        labels: ["Manchester", "Birmingham", "London", "Chester"],
        datasets: [{
          // label: 'Account Statistics',
          borderWidth: 1,
          backgroundColor: ['#57a7fe', '#3ddabb', '#ffc16a', '#fe7388'],

          data: _data
          // data: [0, 1, 1, 1]
        }]
      },
      options: {
        // scales: {
        //   xAxes: [{
        //     gridLines: {
        //       offsetGridLines: false
        //     }
        //   }]
        // },
        cutout: 90,
        responsive: true,
        maintainAspectRatio: false,

        plugins: {
          legend: {
            display: false,
            labels: {
              color: '#495057',

            }
          },

        },
      }

    });
    // this.prevSalechart.resize(200, 200);

    // Resync the render size
    // this.prevSalechart.resize();
  }
  currChart() {
    $('#currentSaleDiv').css({ "display": "block", "height": "0 !important", "width": "0 !important" });
    try {
      this.currentSalechart.destroy();
    } catch {
    }
    this.currentcanvas1 = $('#currentSalechart');
    var cv = this.manchesterCurrent === 0 && this.birminghamCurrent === 0 && this.londonCurrent === 0 && this.chesterCurrent === 0
    if (cv) {
      $('#currentSaleDiv').css({ "display": "none", "height": "0 !important", "width": "0 !important" });
    }
    this.currentctx1 = this.currentcanvas1[0].getContext('2d');
    let _data1 = [this.manchesterCurrent, this.birminghamCurrent, this.londonCurrent, this.chesterCurrent]


    this.currentSalechart = new Chart(this.currentctx1, {
      type: 'doughnut',

      data: {
        labels: ["Manchester", "Birmingham", "London", "Chester"],
        datasets: [{
          // label: 'Account Statistics',
          borderWidth: 1,
          backgroundColor: ['#57a7fe', '#3ddabb', '#ffc16a', '#fe7388'],

          data: _data1
          // data: [0, 1, 1, 1]
        }]
      },
      options: {
        // scales: {
        //   xAxes: [{
        //     gridLines: {
        //       offsetGridLines: false
        //     }
        //   }]
        // },
        cutout: 90,
        responsive: true,
        maintainAspectRatio: false,

        plugins: {
          legend: {
            display: false,
            labels: {
              color: '#495057',

            }
          },

        },
      }

    });
    // this.currentSalechart.resize(200, 200);

    // Resync the render size
    // this.currentSalechart.resize();
  }
  outChart() {
    $('#outSaleDiv').css({ "display": "block", "height": "0 !important", "width": "0 !important" });

    // this.outcanvas1 = this.outSalechart.nativeElement;
    var val = this.manchesterOutstanding === 0 && this.birminghamOutstanding === 0 && this.londonOutstanding === 0 && this.chesterOutstanding === 0
    if (val) {
      $('#outSaleDiv').css({ "display": "none", "height": "0 !important", "width": "0 !important" });
    } else {
      try {
        this.outSalechart.destroy();
        this.outSalechart.clear();
      } catch {

      }
      this.outcanvas1 = $('#outSalechart');
      this.outctx1 = this.outcanvas1[0].getContext('2d');
      let _data2 = [this.manchesterOutstanding, this.birminghamOutstanding, this.londonOutstanding, this.chesterOutstanding]



      this.outSalechart = new Chart(this.outctx1, {
        type: 'doughnut',

        data: {
          labels: ["Manchester", "Birmingham", "London", "Chester"],
          datasets: [{
            // label: 'Account Statistics',
            borderWidth: 1,
            backgroundColor: ['#57a7fe', '#3ddabb', '#ffc16a', '#fe7388'],

            data: _data2,
            // data: [0, 1, 1, 1]
          }]
        },
        options: {
          // scales: {
          //   xAxes: [{
          //     gridLines: {
          //       offsetGridLines: false
          //     }
          //   }]
          // },
          responsive: true,
          maintainAspectRatio: false,
          cutout: 90,
          plugins: {
            legend: {
              display: false,
              labels: {
                color: '#495057',

              }
            },

          },
        }


      });
      // this.outSalechart.update();
      // this.outSalechart.height = 200
      // this.outSalechart.width = 200

      // Resync the render size
      // this.outSalechart.update();
    }
  }
  expectedRevenueFun() {

    try {
      this.chartExcpected.destroy();
    } catch {

      this.canvasExpected = $('#expectedRevn');
      this.ctxExpected = this.canvasExpected[0].getContext('2d');

    }

    this.chartExcpected = new Chart(this.ctxExpected, {
      type: 'bar',

      data: {
        labels: ['Lost Revenue', 'Revenue Closed', 'Expected Revenue'],
        datasets: [{
          // label: 'Account Statistics',
          maxBarThickness: 20,
          borderRadius: 2,
          backgroundColor: [
            '#fe7388',
            '#3ddabb',
            '#57a7fe',
          ],
          yAxisID: 'y',
          data: [this.lostRevenue, this.winRevenue, this.expectedRevenue]
        }]
      },
      options: {
        // scales: {
        //   xAxes: [{
        //     gridLines: {
        //       offsetGridLines: false
        //     }
        //   }]
        // },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            grid: {
              display: false
            }
          },
          y: {
            beginAtZero: true,
            grid: {
              display: false
            }
          },


        },
        plugins: {
          legend: {
            display: false,
            labels: {
              color: '#495057',

            }
          },

        },
      }

    });
  }
  accountB() {

    try {
      this.chart1.destroy();
    } catch {

      this.canvas1 = $('#myChart1');
      this.ctx1 = this.canvas1[0].getContext('2d');

    }

    this.chart1 = new Chart(this.ctx1, {
      type: 'bar',

      data: {
        labels: ['OB', 'DB', 'AFB', 'DI', 'DO'],
        datasets: [{
          // label: 'Account Statistics',
          maxBarThickness: 20,
          borderRadius: 2,
          backgroundColor: [
            '#ce6cf1',
            '#706661',
            '#32455A',
            '#fe7388',
            '#F1BE6A',

          ],
          yAxisID: 'y',
          data: [this.outStandingBalance, this.agreedCost, this.ActiveBalance, this.disIn, this.disOut]
        }]
      },
      options: {
        // scales: {
        //   xAxes: [{
        //     gridLines: {
        //       offsetGridLines: false
        //     }
        //   }]
        // },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            grid: {
              display: false
            }
          },
          y: {
            beginAtZero: true,
            grid: {
              display: false
            }
          },


        },
        plugins: {
          legend: {
            display: false,
            labels: {
              color: '#495057',

            }
          },

        },
      }

    });
  }
  pendingB() {
    var pLabels = this.pendingBalanceData.map((x) => { return x.y.split(' ')[0] })
    var pcolor = ['#ce6cf1',
      '#3ddabb',
      '#706661',
      '#fe7388',
      '#F1BE6A',
      '#69993a',
      '#57a7fe',
    ]
    this.npColor = []
    if (pLabels.length > 7) {
      pLabels.length = 7;
      for (var i = 0; i < pLabels.length; i++) {
        this.npColor.push(pcolor[i])
      }
    }
    this.allPendingBalanceData = this.pendingBalanceData;
    var pData = this.pendingBalanceData.map((x) => { return x.a })
    if (pData.length > 7) {
      pData.length = 7;

    }
    try {
      this.pendingBalanceChart.destroy();
    } catch {

    }
    this.pbcanvas1 = $('#pendingBalance');
    this.pbctx1 = this.pbcanvas1[0].getContext('2d');


    this.pendingBalanceChart = new Chart(this.pbctx1, {
      type: 'bar',

      data: {
        labels: pLabels,
        datasets: [{
          // label: 'Account Statistics',
          maxBarThickness: 20,
          borderRadius: 2,
          backgroundColor: this.npColor,
          yAxisID: 'y',
          data: pData
        }]
      },
      options: {
        // scales: {
        //   xAxes: [{
        //     gridLines: {
        //       offsetGridLines: false
        //     }
        //   }]
        // },
        responsive: true,
        maintainAspectRatio: false,

        scales: {
          x: {
            grid: {
              display: false
            }
          },
          y: {
            beginAtZero: true,
            grid: {
              display: false
            }
          },


        },
        plugins: {
          legend: {
            display: false,
            labels: {
              color: '#495057',

            }
          },

        },
      }

    });
    this.viewAllPendingBal= false;
  }
  viewAllPendingBalance(){

    var pLabels = this.pendingBalanceData.map((x) => { return x.y.split(' ')[0] })
    var pcolor = ['#ce6cf1',
      '#3ddabb',
      '#706661',
      '#fe7388',
      '#F1BE6A',
      '#69993a',
      '#57a7fe',
      '#F1BE6A',
      '#706661',
      '#69993a',
      '#ce6cf1',
      '#fe7388',
      '#F1BE6A',
      '#69993a',
      '#57a7fe',
      '#F1BE6A',
      '#706661',
      '#69993a',
      '#ce6cf1',
      '#fe7388',
      '#F1BE6A',
      '#69993a',
      '#57a7fe',
      '#F1BE6A',
      '#706661',
      '#69993a',
      '#ce6cf1',
      '#fe7388',
      '#F1BE6A',
      '#69993a',
      '#57a7fe',
      '#F1BE6A',
      '#706661',
      '#69993a',
      '#ce6cf1',



    ]
    for (var i = 0; i < pLabels.length; i++) {
      this.npColor.push(pcolor[i])
    }
    this.pendingBalanceData = this.allPendingBalanceData;
    var pData = this.pendingBalanceData.map((x) => { return x.a })
    try {
      this.pendingBalanceChart.destroy();
    } catch {

    }
    this.pbcanvas1 = $('#pendingBalance');
    this.pbctx1 = this.pbcanvas1[0].getContext('2d');


    this.pendingBalanceChart = new Chart(this.pbctx1, {
      type: 'bar',

      data: {
        labels: pLabels,
        datasets: [{
          // label: 'Account Statistics',
          maxBarThickness: 20,
          borderRadius: 2,
          backgroundColor: this.npColor,
          yAxisID: 'y',
          data: pData
        }]
      },
      options: {
        // scales: {
        //   xAxes: [{
        //     gridLines: {
        //       offsetGridLines: false
        //     }
        //   }]
        // },
        responsive: true,
        maintainAspectRatio: false,

        scales: {
          x: {
            grid: {
              display: false
            }
          },
          y: {
            beginAtZero: true,
            grid: {
              display: false
            }
          },


        },
        plugins: {
          legend: {
            display: false,
            labels: {
              color: '#495057',

            }
          },

        },
      }

    });
    this.viewAllPendingBal= true;
  }
toDoFirstTurn=true;
legalFirstTurn=true;
marketingFirstTurn=true;
accountFirstTurn=true;
userFirstTurn=true;
tabindex = -1;
  tabChange(e) {
   let  text = e.originalEvent.path[0].innerText;

    if(text=="To-Do"){
      this.tabindex=0;
      if(this.toDoFirstTurn){
        this.toDoFirstTurn= false;
        this.todo();
      }
    }

    if(text=="Legal"){
      this.tabindex=1;
      if(this.legalFirstTurn){
        this.legalFirstTurn= false;
        this.legal();
      }
    }
    if(text=="Marketing"){
      this.tabindex=2;
      if(this.marketingFirstTurn){
        this.marketingFirstTurn= false;
        this.marketing();
      }

    }
    if(text=="Accounts"){
      this.tabindex=3;
      if(this.accountFirstTurn){
        this.accountFirstTurn= false;
        this.accounts();
      }

    }
    if(text=="Users"){
      this.tabindex=4;
      if(this.userFirstTurn){
        this.userFirstTurn= false;
        this.userFunc();
      }

    }


  }
  PrevHover(color, label, value) {
    // this.prevSale['color'] = color;
    // this.prevSale['label'] = label;
    // this.prevSale['value'] = value;

  }
  CurrHover(color, label, value) {
    // this.currSale['color'] = color;
    // this.currSale['label'] = label;
    // this.currSale['value'] = value;

  }
  OutHover(color, label, value) {
    //   this.outBalance['color'] = color;
    //   this.outBalance['label'] = label;
    //   this.outBalance['value'] = value;

  }
  generateRandomColor() {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  toggleSelection(branch) {

    // console.log(branch);
    this.branchId = branch;
this.legalFirstTurn=true;
this.marketingFirstTurn=true;
this.accountFirstTurn=true;
this.userFirstTurn=true;
if(this.tabindex==0){
  this.toDoFirstTurn= false;
  this.todo();
}
else if(this.tabindex==1){
  this.legalFirstTurn= false;
  this.legal();
}
else if(this.tabindex==2){
  this.marketingFirstTurn= false;
  this.marketing();
}
else if(this.tabindex==3){
  this.accountFirstTurn= false;
  this.accounts();
}
else if(this.tabindex==4){
  this.userFirstTurn= false;
  this.userFunc();
}

    // this.calData()
  }
  checkRow1() {
    var arr = [0, 4, 7, 8, 9];
    var p = this.checkPermission('Dashboard', 'level', this.branchId);
    var check = false;
    p.forEach(function (el) {
      if (arr.indexOf(parseInt(el)) == -1) {
        check = true;
        return;
      }

    });
    if (check) {
      return true
    } else {
      return false;
    }
  }
  checkRow2() {

    var arr = [0, 5, 6, 7, 9];
    var p = this.checkPermission('Dashboard', 'level', this.branchId);
    var check = false;
    p.forEach(function (el) {
      let r = arr.indexOf(parseInt(el))
      if (arr.indexOf(parseInt(el)) == -1) {
        check = true;
        return;
      }

    });
    if (check) {
      return true
    } else {
      return false;
    }
  }

  checkRow3() {

    var arr = [7, 9];
    var p = this.checkPermission('Dashboard', 'level', this.branchId);
    var check = false;
    p.forEach(function (el) {
      if (arr.indexOf(parseInt(el)) == -1) {
        check = true;
        return;
      }

    });
    if (check) {
      return true
    } else {
      return false;
    }
  }
  // monthChange() {
  //   // console.log("Month Change",this.visaDate);
  //   // this.legal();
  //   this.calData();
  // }
  monthChangeLegal(){
    this.legal();
  }
  monthChangeMarketing(){
    this.marketing();
  }
  // monthToggle() {
  //   this.visaDate = null;
  //   this.calData();
  // }
  // monthLeadPerStage() {
  //   this.leadPerStageDate = null;
  //   this.calData();
  // }
  // monthLeadPerStageWinLost() {
  //   this.leadPerStageWinLostDate = null;
  //   this.calData();
  // }
  // monthWorkSource() {
  //   this.WorkSourceDate = null
  //   this.calData();
  // }
  // monthLeadByMatter() {
  //   this.leadByMatterTypeDate = null
  //   this.calData();
  // }

  checkRow4() {
    var Acheck = false;
    var narr = [4, 5, 6, 7, 8, 9];
    var p = this.checkPermission('Dashboard', 'level', this.branchId);
    p.forEach(function (el) {
      if (narr.indexOf(parseInt(el)) > -1) {
        Acheck = true;
        return;
      }
    });
    if (Acheck) {
      return false;
    } else {
      return true;
    }
  }
  LateFilesByPerson() {
    var Acheck = false;
    var narr = [4, 5, 6, 7, 8, 9];
    var p = this.checkPermission('Dashboard', 'level', this.branchId);
    p.forEach(function (el) {
      if (narr.indexOf(parseInt(el)) > -1) {
        Acheck = true;
        return;
      }
    });
    if (Acheck) {
      return false;
    } else {
      return true;
    }
  }
  LateFilesByMatter() {
    var Acheck = false;
    var narr = [4, 5, 6, 7, 8, 9];
    var p = this.checkPermission('Dashboard', 'level', this.branchId);
    p.forEach(function (el) {
      if (narr.indexOf(parseInt(el)) > -1) {
        Acheck = true;
        return;
      }
    });
    if (Acheck) {
      return false;
    } else {
      return true;
    }
  }
  PendingBalanceByCaseWorker() {
    var Acheck = false;
    var narr = [5, 6, 7, 8, 9];
    var p = this.checkPermission('Dashboard', 'level', this.branchId);
    p.forEach(function (el) {
      if (narr.indexOf(parseInt(el)) > -1) {
        Acheck = true;
        return;
      }
    });
    if (Acheck) {
      return false;
    } else {
      return true;
    }
  }
  public _todo = "All";
  Brp() {
    var Acheck = false;
    var narr = [4, 7, 8, 9];
    var p = this.checkPermission('Dashboard', 'level', this.branchId);
    p.forEach(function (el) {
      if (narr.indexOf(parseInt(el)) > -1) {
        Acheck = true;
        return;
      }
    });
    if (Acheck) {
      return false;
    } else {
      return true;
    }
  }
  viewAllActiveLeads(){
    this._dialog.open(AllActiveLeadsDialougeComponent,{
      disableClose:true,
          width:'800px',
          height:'510px',
          data: {
            activeLeads: this.activeLeads
          }
        }).afterClosed().subscribe(async (result) => {
          // await this.getAllWorkSources();
          // this.addApplication.patchValue({workSourceId:result});
      });
  }
  UpComingDeadlines() {
    var Acheck = false;
    var narr = [4, 7, 8, 9];
    var p = this.checkPermission('Dashboard', 'level', this.branchId);
    p.forEach(function (el) {
      if (narr.indexOf(parseInt(el)) > -1) {
        Acheck = true;
        return;
      }
    });
    if (Acheck) {
      return false;
    } else {
      return true;
    }
  }
  ItTicketPermission() {
    var Acheck = false;
    var narr = [3, 4, 5, 6, 7, 8];
    var p = this.checkPermission('Dashboard', 'level', this.branchId);
    p.forEach(function (el) {
      if (narr.indexOf(parseInt(el)) > -1) {
        Acheck = true;
        return;
      }
    });
    if (Acheck) {
      return false;
    } else {
      return true;
    }
  }
  ActiveLeads() {
    var Acheck = false;
    var narr = [3, 4, 5, 6, 7, 9];
    var p = this.checkPermission('Dashboard', 'level', this.branchId);
    p.forEach(function (el) {
      if (narr.indexOf(parseInt(el)) > -1) {
        Acheck = true;
        return;
      }
    });
    if (Acheck) {
      return false;
    } else {
      return true;
    }
  }
  legalTab(){
    var Acheck = false;
    var narr = [4, 7,8, 9];
    var p = this.checkPermission('Dashboard', 'level', this.branchId);
    p.forEach(function (el) {
      if (narr.indexOf(parseInt(el)) > -1) {
        Acheck = true;
        return;
      }
    });
    if (Acheck) {
      return false;
    } else {
      return true;
    }
  }
  marketingTab(){
    var Acheck = false;
    var narr = [3,4,5,6, 7, 9];
    var p = this.checkPermission('Dashboard', 'level', this.branchId);
    p.forEach(function (el) {
      if (narr.indexOf(parseInt(el)) > -1) {
        Acheck = true;
        return;
      }
    });
    if (Acheck) {
      return false;
    } else {
      return true;
    }
  }
  accountsTab(){
    var Acheck = false;
    var narr = [3,5,6, 7,8, 9];
    var p = this.checkPermission('Dashboard', 'level', this.branchId);
    p.forEach(function (el) {
      if (narr.indexOf(parseInt(el)) > -1) {
        Acheck = true;
        return;
      }
    });
    if (Acheck) {
      return false;
    } else {
      return true;
    }
  }
  DealWontoLossPermission() {
    var Acheck = false;
    var narr = [3, 4, 5, 6, 7, 9];
    var p = this.checkPermission('Dashboard', 'level', this.branchId);
    p.forEach(function (el) {
      if (narr.indexOf(parseInt(el)) > -1) {
        Acheck = true;
        return;
      }
    });
    if (Acheck) {
      return false;
    } else {
      return true;
    }
  }
  DealWonLostAmountPermission() {
    var Acheck = false;
    var narr = [3, 4, 5, 6, 7, 9];
    var p = this.checkPermission('Dashboard', 'level', this.branchId);
    p.forEach(function (el) {
      if (narr.indexOf(parseInt(el)) > -1) {
        Acheck = true;
        return;
      }
    });
    if (Acheck) {
      return false;
    } else {
      return true;
    }
  }
  LeadsByMatterPermission() {
    var Acheck = false;
    var narr = [3, 4, 5, 6, 7, 9];
    var p = this.checkPermission('Dashboard', 'level', this.branchId);
    p.forEach(function (el) {
      if (narr.indexOf(parseInt(el)) > -1) {
        Acheck = true;
        return;
      }
    });
    if (Acheck) {
      return false;
    } else {
      return true;
    }
  }
  inCompleteCadence() {
    var Acheck = false;
    var narr = [3, 4, 5, 6, 7, 9];
    var p = this.checkPermission('Dashboard', 'level', this.branchId);
    p.forEach(function (el) {
      if (narr.indexOf(parseInt(el)) > -1) {
        Acheck = true;
        return;
      }
    });
    if (Acheck) {
      return false;
    } else {
      return true;
    }
  }
  axisTeamPermission() {
    var Acheck = false;
    var narr = [4, 5, 6, 7, 8, 9];
    var p = this.checkPermission('Dashboard', 'level', this.branchId);
    p.forEach(function (el) {
      if (narr.indexOf(parseInt(el)) == -1) {
        Acheck = true;
        return;
      }
    });
    if (Acheck) {
      return true;
    } else {
      return false;
    }
  }
  LeadPerSatgePermission() {
    var Acheck = false;
    var narr = [3, 4, 5, 6, 7, 9];
    var p = this.checkPermission('Dashboard', 'level', this.branchId);
    p.forEach(function (el) {
      if (narr.indexOf(parseInt(el)) > -1) {
        Acheck = true;
        return;
      }
    });
    if (Acheck) {
      return false;
    } else {
      return true;
    }
  }
  TodoChange(e) {
    this._todo = e;
    if (e === "IT") {
      this.CurrentTodo = [];
      this.CurrentTodo = this.crrTodo.filter(x => x.type === 0);
      this.lateTodo = [];
      this.lateTodo = this.overDueTodo.filter(x => x.type == 0);
    } else if (e === "App") {
      this.CurrentTodo = [];
      this.CurrentTodo = this.crrTodo.filter(x => x.type === 1);
      this.lateTodo = [];
      this.lateTodo = this.overDueTodo.filter(x => x.type == 1);
    } else if (e === "Retainers") {
      this.CurrentTodo = [];
      this.CurrentTodo = this.crrTodo.filter(x => x.type === 2);
      this.lateTodo = [];
      this.lateTodo = this.overDueTodo.filter(x => x.type == 2);
    } else if (e === "Leads") {
      this.CurrentTodo = [];
      this.CurrentTodo = this.crrTodo.filter(x => x.type === 3);
      this.lateTodo = [];
      this.lateTodo = this.overDueTodo.filter(x => x.type == 3);
    } else if (e === "CaseInvoices") {
      this.CurrentTodo = [];
      this.CurrentTodo = this.crrTodo.filter(x => x.type === 4);
      this.lateTodo = [];
      this.lateTodo = this.overDueTodo.filter(x => x.type == 4);
    }
    else {
      this.CurrentTodo = this.crrTodo;
      this.lateTodo = this.overDueTodo;
    }
  }
  visaRejection() {
    var Acheck = false;
    var narr = [4, 5, 6, 7, 9];
    var p = this.checkPermission('Dashboard', 'level', this.branchId);
    p.forEach(function (el) {
      if (narr.indexOf(parseInt(el)) > -1) {
        Acheck = true;
        return;
      }
    });
    if (Acheck) {
      return false;
    } else {
      return true;
    }
  }

  CheckWorkSource() {
    var Acheck = false;
    var narr = [3, 4, 5, 6, 7, 9];
    var p = this.checkPermission('Dashboard', 'level', this.branchId);
    p.forEach(function (el) {
      if (narr.indexOf(parseInt(el)) > -1) {
        Acheck = true;
        return;
      }
    });
    if (Acheck) {
      return false;
    } else {
      return true;
    }
  }
  typeOfMatter() {
    var Acheck = false;
    var narr = [4, 7, 9];
    var p = this.checkPermission('Dashboard', 'level', this.branchId);
    p.forEach(function (el) {
      if (narr.indexOf(parseInt(el)) > -1) {
        Acheck = true;
        return;
      }
    });
    if (Acheck) {
      return false;
    } else {
      return true;
    }
  }
  checkAllVisible() {
    var i = 0;

    this.branches.forEach(function (element) {
      if (element.branchVisible) {
        i++;
      }
    });
    if (i > 1) {
      return true;
    } else {
      return false;
    }
  }
  checkPermission = (moduleName, key, branchId) => {
    var p = this.permissions.checkDashBoardPermission(moduleName, key, branchId);
    return p
  }
  NavigateToPages(id, type) {
    if (type == 0) { // It Form View
      this._dialog.open(ITFormViewDialogComponent, {
        disableClose: true,
        width: '800px',
        height: '768px',
        data: {
          Id: id
        }
      }).afterClosed().subscribe(async (result) => {
      });
    } else if (type == 1) { // Case View
      const encId = this.encryptionDecryptionService.encrypt(id.toString());
      const param = { queryParams: { row: encId } };
      this.router.navigate([AppSettings.Case_View_URL], param);
      // const url = this.router.serializeUrl(this.router.createUrlTree([AppSettings.Case_View_URL],param));
      // window.open(url);
    } else if (type == 2) { // Detail retainer
      const encId = this.encryptionDecryptionService.encrypt(id.toString());
      const param = { queryParams: { row: encId } };
      this.router.navigate([AppSettings.ApplicationProcessDetailed_URL], param);
      // const url = this.router.serializeUrl(this.router.createUrlTree([AppSettings.ApplicationProcessDetailed_URL],param));
      // window.open(url);
    } else if (type == 3) { // Detail Lead Page
      this.cadenceClick(id.toString())
    }
    else if (type == 4) {// Case View
      const encId = this.encryptionDecryptionService.encrypt(id.toString());
      const param = { queryParams: { row: encId } };
      this.router.navigate([AppSettings.Case_View_URL], param);
      // const url = this.router.serializeUrl(this.router.createUrlTree([AppSettings.Case_View_URL],param));
      // window.open(url);
    }
  }



  ngOnInit() {

    var i = 0;
    var branchName;
    this.branches.forEach(function (element) {
      if (element.branchVisible) {
        branchName = element.branchName;
        i++;
      }

    });
    this.mainbranches = this.mainbranches.filter(x => x.branchVisible);
    this.mainbranches = [{ branchId: 'All', branchName: 'All', branchVisible: true }].concat(this.mainbranches);
    if (i == 1) {
      this.branchId = branchName;
    }


    this.chartOptions = {

      plugins: {
        legend: {
          display: false, show: false,
          labels: {
            color: '#495057',
            display: false, show: false
          }
        },
        tooltips: {
          mode: 'index',
          intersect: true
        }
      },
      legend: { display: false, show: false }
    };

    this.lineOption = {
      xkey: "y",
      ykeys: ["a"],
      labels: ["File"],
      resize: true,
      barColors: ["#f7b497"],
    };
    this.matterOption = {
      xkey: "y",
      ykeys: ["a"],
      labels: ["File"],
      resize: true,
      barColors: ["rgb(55, 97, 130)"],
    };

    this.pendingBalanceOption = {
      xkey: "y",
      ykeys: ["a"],
      labels: ["Balance"],
      resize: true,
      horizontal: true,
      barColors: ["rgb(195, 110, 131)"],
    };

    this.leadPerStageOption = {
      xkey: "y",
      ykeys: ["a"],
      labels: ["count"],
      resize: true,
      horizontal: true,
      barColors: ["#91E9FF"],
    };

    this.lineData = [
      { y: "Ahsan", a: 50 },

    ]
    this.lateMatters = [
      { y: "Ahsan", a: 50 },

    ]
    this.pendingBalanceData = [
      { y: "Ahsan", a: 50 },

    ]

    this.loader = true;
    this.user = User;
    this.VisaData = [{ label: "Visa Granted", value: 0, legendItem: "A", color: "rgb(247, 180, 151)", files:[] },
    { label: "Visa Refused", value: 0, legendItem: "A", color: "rgb(226, 240, 217)" , files:[]},
    ];
    this.leadPerStageWinLostData = [{ label: "Won Percentage", value: 5, legendItem: "A", color: "rgb(247, 180, 151)" },
    { label: "Lost Percentage", value: 5, legendItem: "A", color: "rgb(226, 240, 217)" },
    ];
    this.MatterData = [{ label: "Friends", value: 0, legendItem: "A", color: "red" }];

  }
  checkTodoPerm(id) {
    var ar = [0, 3, 4, 5, 6, 7, 9];
    var p = this.checkPermission('Dashboard', 'level', this.branchId);
    var check = false;
    p.forEach(function (el) {
      if (ar.indexOf(parseInt(el)) == -1) {
        check = true;
        return;
      }
    });
  }
}
