import { tap, shareReplay } from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { environment } from './../../environments/environment';
import { HttpClient, HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';



@Injectable()
export class ProfileServiceProxy{

  private apiRoot = environment.apiURL;

  constructor(private http: HttpClient) { }

  //  Post(data){
  //   return this.http.post(this.apiRoot.concat('profile/'),data).pipe(
  //     tap(response =>{

  //       }),
  //     shareReplay(),
  //   );
  // }
  // getAllData()
  // {

  //   var url =  this.apiRoot.concat('getAllUsers')
  //    return this.http.get(
  //   url
  //   ).pipe(
  //     tap(response =>{

  //       }),
  //     shareReplay(),
  //   );


  // }

getbyId(id){
     return this.http.put(this.apiRoot.concat('profile/'+id+'/'),{}).pipe(
      tap(response =>{

        }),
      shareReplay(),
    );
}
}
