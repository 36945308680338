import { ApplicationProcessSendEmailDialogueComponent } from '../application-process-send-email-dialogue/application-process-send-email-dialogue.component';
import { User } from 'src/environments/User';
import { Component, Inject, Injector, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA, } from '@angular/material/dialog';
import * as _ from 'lodash';

import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { MatterServiceProxy } from 'src/app/Services/matterServiceProxy.service';
import { CaseServiceProxy } from 'src/app/Services/caseServiceProxy.service';
import { WorkSourceServiceProxy } from 'src/app/Services/workSourceServiceProxy';
import { AutoCompleteModel } from 'src/@shared-components/auto-complete/models/auto-model';
import { WorkSourceDialogComponent } from '../../work-source/work-source.component';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

declare const $: any;
@Component({
  templateUrl: './application-process-approve.component.html',
})

export class ApplicationProcessApproveComponent
  implements OnInit {
  public addApplication: FormGroup;
  matterList = [{"Id":"k"}];
  worksource :any;
  paymentMethod = ['BACS', 'Cheque', 'Cash', 'Card'];
  user: any;
  workSourceDropDownData: Array<AutoCompleteModel>;
 workSourceAutoCompleteCtrl: FormControl;
 public Editor = ClassicEditor;


  constructor(
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private matterService: MatterServiceProxy,
    private caseService: CaseServiceProxy,
    private workSourceService: WorkSourceServiceProxy,
    public dialogRef: MatDialogRef<ApplicationProcessApproveComponent>,
    private _dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public ReceviedData: any ,
  ) {
    this.workSourceDropDownData = new Array<AutoCompleteModel>();
    this.workSourceAutoCompleteCtrl = new FormControl();


  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }
   ngOnInit()  {
    this.getData();
    this.user = User;
    if(this.ReceviedData.check){
      // debugger;
      this.workSourceAutoCompleteCtrl.setValue(this.ReceviedData.array.workSource);
      this.addApplication = this.formBuilder.group({
        Id: this.ReceviedData.array.Id,
        clientName: this.ReceviedData.array.clientName,
        matterId: this.ReceviedData.array.matterId,
        workSourceId: this.ReceviedData.array.workSourceId,
        email: this.ReceviedData.array.email,
        phoneNumber: this.ReceviedData.array.phoneNumber,
        deadLine: this.ReceviedData.array.deadLine,
        agreedCost: this.ReceviedData.array.caseInvoice[0].agreedCost,
        vatStatus: this.ReceviedData.array.vatStatus,
        inclusiveOfVat: this.ReceviedData.array.inclusiveOfVat,
        feePaid: this.ReceviedData.array.caseInvoice[0].amountPaid,
        paymentMethod:this.ReceviedData.array.caseInvoice[0].methodOfPayment,
        referenceOfPayment: this.ReceviedData.array.caseInvoice[0].refOfPayment,
        detailsOfMatter: this.ReceviedData.array.detailsOfMatter,
        isApproved:true,
        fileNumber:this.ReceviedData.array.fileNumber,
        priorityId:this.ReceviedData.array.priorityId,
        personResposibleId:this.ReceviedData.array.personResposibleId,
        mobileNumber:this.ReceviedData.array.mobileNumber,
        address:this.ReceviedData.array.address,
        dueDate:this.ReceviedData.array.dueDate,
        paymentAccount:this.ReceviedData.array.paymentAccount,
        imagePath:this.ReceviedData.array.imagePath,
        amlCompleted:this.ReceviedData.array.amlCompleted,
        amlDate:this.ReceviedData.array.amlDate,
        amlEvidenceFile:this.ReceviedData.array.amlEvidenceFile,
        amlNotes:this.ReceviedData.array.amlNotes,
        conflictCompleted:this.ReceviedData.array.conflictCompleted,
        conflictDate:this.ReceviedData.array.conflictDate,
        conflictNotes:this.ReceviedData.array.conflictNotes,
        creationTime:this.ReceviedData.array.creationTime,
        deletionTime:this.ReceviedData.array.deletionTime,
        identityCompleted:this.ReceviedData.array.identityCompleted,
        identityDate:this.ReceviedData.array.identityDate,
        identityEvidenceFile:this.ReceviedData.array.identityEvidenceFile,
        identityNotes:this.ReceviedData.array.identityNotes,
        isCompleted:this.ReceviedData.array.isCompleted,
        isDeleted:this.ReceviedData.array.isDeleted,
        isDraft:this.ReceviedData.array.isDraft,
        resolvedBy:this.ReceviedData.array.resolvedBy,
        invoiceNumber: this.ReceviedData.array.invoiceNumber,
        methodOfPayment: this.ReceviedData.array.methodOfPayment,
        notesOnCost: this.ReceviedData.array.notesOnCost,
        refOfPayment: this.ReceviedData.array.refOfPayment,
        typeOfPayment:this.ReceviedData.array.typeOfPayment,
        caseInvoiceId:this.ReceviedData.array.caseInvoiceId,
      });
      if(this.addApplication.get('vatStatus').value)
      {
        this.addApplication.patchValue({ vatStatus: 'true' })
      }
      else{
        this.addApplication.patchValue({ vatStatus: 'false' })
      }
      if(this.addApplication.get('inclusiveOfVat').value)
      {
        this.addApplication.patchValue({ inclusiveOfVat: 'true' })
      }
      else{
        this.addApplication.patchValue({ inclusiveOfVat: 'false' })
      }
    }
    else{
      this.addApplication = this.formBuilder.group({
        Id: [0],
        clientName: ["", [Validators.required]],
        matterId: [null, [Validators.required]],
        workSourceId: [null, [Validators.required]],
        email: ["", [Validators.required]],
        phoneNumber: ["", [Validators.required]],
        deadLine: ["", [Validators.required]],
        agreedCost: ["", [Validators.required]],
        vatStatus: ['false', [Validators.required]],
        inclusiveOfVat: ['false', [Validators.required]],
        feePaid: ["", [Validators.required]],
        paymentMethod: ["", [Validators.required]],
        referenceOfPayment: ["", [Validators.required]],
        detailsOfMatter: ["", [Validators.required]],
        isApproved:true
      });
    }

  }

  addApplicationProcess = () => {
    let self = this;
    if (this.addApplication.valid) {
      let bol = this.addApplication.get('vatStatus').value=="true";
      this.addApplication.patchValue({ vatStatus: bol })
      let bol1 = this.addApplication.get('inclusiveOfVat').value=="true";
      this.addApplication.patchValue({ inclusiveOfVat: bol1 })
      let data = this.addApplication.value;
      data = JSON.stringify(data);
      data = JSON.parse(data);
      data["isDeleted"] = false;
      data['addedByUserId']= User.Id;
      this.caseService.PostCase(data).subscribe(Response => {
        Swal.fire("Success", "Case created successfully", "success");
        this.addApplication.reset();
        this._dialog.open(ApplicationProcessSendEmailDialogueComponent,{
          disableClose:true,
              width:'600px',
              height:'510px',
            }).afterClosed().subscribe(async (result) => {
              self.dialogRef.close(true);
          });
      }, error => {

        Swal.fire("Error", error.error.error, "error");
      })

    }
    else{
      for( let i in this.addApplication.controls){
        this.addApplication.controls[i].markAsTouched();
      }
    }
  }

  async getData() {
    let self = this;
    const promise=this.matterService.getAllData().toPromise();
    await promise.then(function(response){
      self.matterList = response['Matter'];
    });
    await this.getAllWorkSources();


  }
 async getAllWorkSources(){
  let self = this;
    const prom = this.workSourceService.getAllData().toPromise();
    await prom.then(function(response){
      self.worksource = response['WorkSource'];
    });
    this.workSourceDropDownData = [];
    this.worksource.forEach(item => {
      this.workSourceDropDownData.push({
          text: item.Name,
          url: '',
          icon: '',
          action: '',
          value: item.Id
      });
      this.workSourceDropDownData = [...this.workSourceDropDownData];
  });
  }
  addNewWorkSource() {

    let bol = this.addApplication.get('workSourceId').value ? false: true;
    if(!this.addApplication.get('workSourceId').value)
    {
      console.log('Add new WorkSource Event');
      this._dialog.open(WorkSourceDialogComponent,{
        disableClose:true,
            width:'800px',
            height:'510px',
            data: {
              workSourceName: this.workSourceAutoCompleteCtrl.value
            }
          }).afterClosed().subscribe(async (result) => {
            await this.getAllWorkSources();
            this.addApplication.patchValue({workSourceId:result});
        });
    }
  }
  autoFillValueChangedForWorkSource(data: any) {
    if(data){
      this.addApplication.patchValue({ workSourceId: data });
    }

    // this.roleChange(data);
  }
  save(): void {
  }
  closePermissions(){
    // this.selected_permission=[];
  }
  close() {
    this.dialogRef.close(false);
  }
  SendEmail(){
    let self = this;
    this._dialog.open(ApplicationProcessSendEmailDialogueComponent,{
      disableClose:true,
          width:'600px',
          height:'510px',
          // data: {
          //   workSourceName: this.workSourceAutoCompleteCtrl.value
          // }
        }).afterClosed().subscribe(async (result) => {
          self.dialogRef.close(true);
      });
  }
}
