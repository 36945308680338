import { filter } from 'rxjs/operators';
import { Component, Inject } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { CaseInvoiceServiceProxy } from 'src/app/Services/caseInvoiceServiceProxy.service';
import { OfficeAccountServiceProxy } from "src/app/Services/officeAccountServiceProxy";
import Swal from "sweetalert2";
import { DraftedClientEditComponent } from "../CaseWorkerDetailModal/drafted-client-edit/drafted-client-edit.component";
declare const $: any;

@Component({
    selector: 'drafted-client',
    templateUrl: 'move-to-office-dialog.component.html',
  })
  export class MoveToOfficeDialogeComponent  {
    caseData:any;
    clientAccount:any;
    officeInvoices:any;
    officeInvoiceId="null";
    transactionDate: any;
    typeOfPayment=['Disbursement','Legal FEE'];
  clientToOfcSubmit: any=false;
    constructor(
        public dialogRef: MatDialogRef<MoveToOfficeDialogeComponent>,
        private officeAccountService: OfficeAccountServiceProxy,

        @Inject(MAT_DIALOG_DATA) public data: {data},
        private _dialog: MatDialog,
        ) {
            this.caseData = data.data.caseData;
            this.clientAccount = data.data.clientAccount;
            this.officeInvoices = data.data.officeInvoices;
        }

        async getData(){
  this.officeInvoices= this.officeInvoices.filter(
    (x)=>
    {
      if(x.typeOfPayment===this.clientAccount['typeOfPayment'] && x.balance!=0){
        return x
      }
    });
  // this.officeInvoices = this.officeInvoices.filter(x=>x.balance!=0);


           }

          async ngOnInit() {
            await this.getData();
           }

    close(){
      this.dialogRef.close();
    }
    transDateInvalid = false;
  officeInvoiceInvalid= false;
  checkValidation() {
    let ret = true;

    if (this.transactionDate == null) {
      this.transDateInvalid = true;
      ret = false;
    } else {
      this.transDateInvalid = false;
    }


    if (this.officeInvoiceId == "null") {
      this.officeInvoiceInvalid = true;
      ret = false;
    } else {
      this.officeInvoiceInvalid = false;
    }


    return ret;
  }
    Submit(){
      if(this.checkValidation()){
        let self = this;
        this.clientToOfcSubmit= true;
      if(this.officeInvoiceId!== null && this.transactionDate!=null){
        let temp1 = Number(this.officeInvoiceId)
        let temp = this.officeInvoices.find(x=>x.Id===temp1)
        let officeAmount = Number(temp.balance);
        let clientAmount = Number(this.clientAccount.amount);
        if(officeAmount<clientAmount && clientAmount>=0){
          Swal.fire(
            'Need Revision',
            'The Invoice Amount is less than the payment amount.',
            'error'
          );
          this.clientToOfcSubmit= false;
        }
        else{
          let data = this.clientAccount;
      data['clientAccountId']=data['Id'];
      data['Id']=0;
      data['entryDate'] = new Date();
      data['transactionDate'] =  this.transactionDate;
      data['clientEntryDate'] = new Date();
      data['clientTransactionDate'] = this.transactionDate;
      data['officeInvoiceId']= this.officeInvoiceId;
      this.officeAccountService.Post(data).subscribe(function(resp){
        // self.getData();
        Swal.fire(
          'Success!',
          'Payment Success Fully Moved.',
          'success'
        );
        this.clientToOfcSubmit= false;
        self.close();
      },error=>{
        this.clientToOfcSubmit=false;
      });
        }
      }
      }

    }
  }
