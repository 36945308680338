
import { environment } from 'src/environments/environment';
import * as io from 'socket.io-client';
import { Observable } from 'rxjs';
export class SocketioService {
    socket;
    userSocket
    notification_socket;
    itForm;
    caseSocket;
    waitForOpenSocket(socket) {
      if(socket){
      return new Promise<void>((resolve, _reject) => {
        while (socket.readyState !== socket.OPEN) { /* no-op */ }
        return resolve()
      })
    }
    }
    
    constructor() {   }
    setupSocketConnection(id) {
      this.socket =new WebSocket(environment.socketUrl+'ws/comment/'+id);
   
    }
    setupCaseSocket(){
      this.caseSocket = new WebSocket(environment.socketUrl+"ws/case/1");
    }
    setupItForm(){
      this.itForm = new WebSocket(environment.socketUrl+"ws/itFormSocket/1");
    }
    setupNotficationSocket(){
      this.notification_socket = new WebSocket(environment.socketUrl+"ws/notifSocket/1");
    }
    setupUserConnetion(){
      this.userSocket = new WebSocket(environment.socketUrl+'ws/user/1');
      
    }
   getUserSocket(){
        return this.userSocket
    }
    async sendUserData(){
      await this.waitForOpenSocket(this.userSocket)
      this.userSocket.send(JSON.stringify({ message: "hello_user" }));
    }
    async  sendCaseData(){
      await this.waitForOpenSocket(this.caseSocket)
        this.caseSocket.send(JSON.stringify({ message: "hello" }));
    }
    async  sendNotifData(){
      await this.waitForOpenSocket(this.notification_socket)
        this.notification_socket.send(JSON.stringify({ message: "hello" }));
    }
    async   senditFormData(){
      await this.waitForOpenSocket(this.itForm)
        this.itForm.send(JSON.stringify({ message: "hello" }));
    }
    async   sendData(){
      await this.waitForOpenSocket(this.socket)
        this.socket.send(JSON.stringify({ message: "hello" }));
    }

}