import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { EncryptionDecryptionService } from "src/app/Services/encryption-decryption.service";
import { LeadFormServiceProxy } from "src/app/Services/leadForm.service";
import { PermissionService } from "src/app/Services/PermissionServices.service";
import { AppSettings } from "src/app/shared/app.settings";
import { loaderActivation } from "src/environments/loaderActivation";
import { User } from "src/environments/User";
import Swal from "sweetalert2";
import { Location } from '@angular/common';


declare const $: any;

@Component({
  selector:"leads",
  templateUrl:'./leads.componet.html',
})
export class LeadComponent implements OnInit{
  blist:any;
  loader:any=false;
  moduleName: any;
  allLeadForms:any=[];
  leadForms:any=[];
 public coloumn : any;
 userbranches: any[];
 Name:any;
 rows: any;
 srch: any[];
  filterFields: string[];

  constructor(
    private router: Router,
    private permission:PermissionService,
    private leadFormService: LeadFormServiceProxy,
    private encryptionDecryptionService: EncryptionDecryptionService,
    private location: Location,
  ){
    this.moduleName = 'Leads';

  }
  backBtn(){
    this.location.back();
  }

  async getData(){
    this.loader = true;
    loaderActivation.loader=true;
    this.userbranches = User.Permissions;
    if(!this.blist){
      this.blist=(User.BranchId);
     }
    let self = this;
    this.allLeadForms = []
    this.leadForms = []
    const promise=  this.leadFormService.getAllData().toPromise();
    await promise.then(function(response){
      let data = response['LeadForms'];
      data.forEach(element => {
        if(!element['isDrafted']){
          self.allLeadForms.push(element);
        }
      });

    });
    this.allLeadForms = this.allLeadForms.reverse();
    this.leadForms = this.allLeadForms;
    this.allLeadForms = self.allLeadForms.filter(x=>x.branchId==self.blist)
    this.rows = this.allLeadForms;
    this.srch = [...this.rows];
     this.coloumn = [
      {'Name':'Id','property':'Id','type':'Id'},
      {'Name':'Name','property':'name','type':'text'},
      {'Name':'Email','property':'email','type':'text'},
      {'Name':'Phone Number','property':'phoneNumber','type':'text'},
      {'Name':'Matter Type','property':'matterType','type':'text'},
      {'Name':'Status','property':'status','type':'text'},
      {'Name':'Deal Owner','property':'dealOwnerName','type':'text'},
      {'Name':'Actions','property':'','type':'actions'},
    ];
    this.filterFields = ['Id','name','email','phoneNumber','matterType','status','dealOwnerName'];
    this.loader = false;
    loaderActivation.loader=false;

    // setTimeout(function(){
    //   $('data-grid  table  tbody tr').each(function() {
    //   var td = $(this).find('td').eq(5);

    //     if(td.text().includes("Discovery")){
    //       td.addClass("bg-pink-medium text-center");
    //     }
    //     if(td.text().includes("Contact Made")){
    //       td.addClass("bg-lightyellow text-center");
    //     }

    //     if(td.text().includes("Negotiation")){
    //       td.addClass("bg-negotiation-green text-center");
    //     }


    //     if(td.text().includes("Qualified")){
    //       td.addClass("bg-seagreen text-center");
    //     }


    //     if(td.text().includes("Closed")){
    //       td.addClass("bg-shocking-pink text-center");
    //     }


    //     if(td.text().includes("Lost")){
    //       td.addClass("bg-web-red text-center");
    //     }

    //   });
    // //  alert( $('data-grid table tr:eq(2) td:eq(4)').text())

    // },700);
  }
  allBranches(){
    this.allLeadForms = this.leadForms;
  }
  ngOnInit(){
    this.getData();
  }
  checkPermission=(name,key,BranchId)=>{
    var ex = this.permission.checkVisibilityByModuleName(name,key,BranchId)
    return ex
  }
  DashboardNavigate()
  {
    this.router.navigateByUrl(AppSettings.DASHBOARD_URL);
  }
  rowClick(value){
    let toSetValues= value;
    const encId = this.encryptionDecryptionService.encrypt(toSetValues.Id.toString());
    const param = toSetValues.Id.toString()
    // const param = { queryParams: { row: toSetValues.Id.toString() } };
    this.router.navigate([AppSettings.DetailLeadForm_URL,param]);
    // const url = this.router.serializeUrl(this.router.createUrlTree([AppSettings.DetailLeadForm_URL],param));
    // window.open(url);
  }
  async toggleSelection(id){

    this.blist=id;
    // this.auth.checkToken();
    if(!this.checkPermission("Leads","Visiblity",this.blist)){
      this.router.navigateByUrl("error401");
    }

      await  this.getData();


  }
  searchName(val) {
    this.rows.splice(0, this.rows.length);
    let temp = []
    this.srch.forEach(function (d) {
      val = val.toLowerCase();
      if( d.name.toLowerCase().indexOf(val) !== -1 || !val)
      {


          temp.push(d);
      }
    });
   this.allLeadForms=[];
    this.allLeadForms=temp;
  }

  async deleteSelected(checkL) {
    await checkL.forEach(element => {
      let toSetValues = this.allLeadForms.find(x => x.Id === element);
      toSetValues['isDeleted'] = true;
      toSetValues["rolePermission"] = []
      const promise = this.leadFormService.Post(toSetValues).toPromise()
      promise.then(Response => {
        Swal.fire("Success", "Lead deleted successfully", "success");
      }, error => {
        Swal.fire("Error", "Something is not right", "error");
      })
    });
    this.getData();
    Swal.fire("Success", "Leads deleted successfully", "success");
    $("#delete_selected_role").modal("hide");
  }
  deleteLeads(id) {
    let toSetValues = this.allLeadForms.find(x => x.Id === id);
    toSetValues['isDeleted'] = true;
    toSetValues['addedByUserId'] = User.Id;
    this.leadFormService.Post(toSetValues).subscribe(Response => {
      Swal.fire("Success", "Lead deleted successfully", "success");
      // this.tempId = -1;

      this.getData();
      $("#delete_role").modal("hide");
    }, error => {
      // console.log("",error.toString(),"error");
      Swal.fire("Error", "Something is not right", "error");
    })
  }
}
