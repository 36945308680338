import { Component, OnInit, ViewChild } from "@angular/core";
import { Location } from '@angular/common';



@Component({
  selector:"goals",
  templateUrl:'./goals.componet.html',
})

export class GoalsComponent implements OnInit{
  constructor(
    private location: Location,
  ){

  }
  backBtn(){
    this.location.back();
  }
  ngOnInit(){

  }
}
