import { AfterViewInit } from '@angular/core';
import { loaderActivation } from './../../../../environments/loaderActivation';
import { User } from 'src/environments/User';
import { DocumentFileServiceProxy } from './../../../Services/document.service';
import { Title } from '@angular/platform-browser';
import { CryptoService } from './../../../Services/CryptoService.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Component, Inject, Injector, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA,  } from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar'
import {MatCheckboxChange} from '@angular/material/checkbox'
import { finalize } from 'rxjs/operators';
import * as _ from 'lodash';


import {
    Gallery,
    GalleryItem,
    ThumbnailsPosition,
    ImageSize,
    ImageItem
  } from "ng-gallery";

  import { Lightbox } from "ng-gallery/lightbox";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ticket } from 'src/app/shared/ticket';
import { ItFormServiceProxy } from 'src/app/Services/itformServiceProxy.service';
import Swal from 'sweetalert2';
import { commentService } from 'src/app/Services/commentService.service';

import { SocketioService } from 'src/app/Services/socketio.service';
import { PermissionService } from 'src/app/Services/PermissionServices.service';


declare const $: any;
@Component({
  templateUrl: './it-form-view.component.html',
  styleUrls: ["../it-form.component.css"]

})

export class ITFormViewDialogComponent
  implements OnInit,AfterViewInit {
  form: FormGroup;
  CommentForm: FormGroup;
  ticket:any={files:[]};
  blist: any;
  constructor(
    public dialog: MatDialog,
   private _snackBar:MatSnackBar,
    private formBuilder: FormBuilder,
    private shareTicket :ticket,
    private itformservice: ItFormServiceProxy,
    private router:Router,
    private activatedRoute :ActivatedRoute,
    private CryptoService:CryptoService,
    private title:Title,
    private docService:DocumentFileServiceProxy,
    private commentService:commentService,
    public gallery: Gallery,public lightbox: Lightbox,
    private socketService: SocketioService,
    private permission: PermissionService,
    public dialogRef: MatDialogRef<ITFormViewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { Id: number },
  ) {
    // super({ url: environment.socketUrl+'ws/comment/1', options: {} });
  }
  ngAfterViewInit(): void {

    this.getData();
  }
  fileId:any=-1;
  id:any=0;
  user:any;

  items: GalleryItem[];
  ngOnInit(): void {
    // loaderActivation.loader= true
    this.user = User;

   this.ticket ={'Comments':[],'files':[]}

      this.CommentForm = this.formBuilder.group({
        comment: ["", [Validators.required]],
       // roleId: ["", [Validators.required]],

     });

    //  this.id = this.CryptoService.decryptUsingAES256(params['id'].toString().replace('xMl3Jk', '+' ).replace('Por21Ld', '/').replace('Ml32', '='))


    this.socketService.setupSocketConnection(this.data.Id);

    this.socketService.socket.onmessage=(msg)=>{
      let  data = JSON.parse(msg['data'])['payload']['data']
      data = JSON.parse(data);

      this.ticket=data;
      if(data['isDeleted']===true){
        Swal.fire({
          title: "Ooops!",
          text: "The issue has been removed by the support team. In case you need any other help,please create a new issue,Thank You!",
          icon: "warning",
          confirmButtonText: "OK"
      }).then((result) => {
        if (result.isConfirmed) {
          this.close();
          // this.router.navigateByUrl('/support/it-form')



        }
      });
      }

  }
//   this.socketService.userSocket.onmessage=(msg)=>{
//     this.getData();
//  }
  this.socketService.socket.onerror = (error) =>{
    console.log("error")
      console.log(`The server received: ${error['code']}`
      );
      setTimeout(function(){
        this.socketService.setupSocketConnection(this.data.Id);
      },5000)


    this.socketService.setupSocketConnection(this.data.Id);
  }
      this.socketService.socket.onclose = (why) =>{
        console.log("closed")
      console.log(`The server received: ${why.code} ${why.reason}`
      );

      setTimeout(function(){
        this.socketService.setupSocketConnection(this.data.Id);
      },5000)
      }
      this.socketService.socket.onopen=()=>{
console.log('Connected')
      }

  // }else{
  //   this.router.navigateByUrl('/support/it-form')
  // }
  //   });
  // setTimeout(() => {

  //   loaderActivation.loader= false
  // }, 1000);
  }
  files:any=[];
  deleteFromArray(index) {
    // console.log(this.files);
    this.files.splice(index, 1);
    this.fileIndex--;
  }
  fileIndex:any=0

  onFileChange(event) {
    let pFileList = event.target.files;
    let _files = Object.keys(pFileList).map(key => pFileList[key]);
    this._snackBar.open("Successfully upload!", "Close", {
      duration: 2000
    });

    if (event.target.files && event.target.files[0]) {
      _files.forEach(item => {
        var reader = new FileReader();

        reader.readAsDataURL(item); // read file as data url

        reader.onload = event => {
          // called once readAsDataURL is completed
          item.filePath = event.target.result;

        };
        item.Id=this.fileIndex;
        this.files.push(item);
        this.fileIndex++;
      });
    }
  }
  checkPermission = (name, key, branchId) => {

    if (!branchId) {
      branchId = User.BranchId
    }
    var ex = this.permission.checkVisibilityByModuleName(name, key, branchId)


    return ex
  }
async getData(){
  if (!this.blist) {
    this.blist = (User.BranchId);
  }
  const promise = this.itformservice.getById(this.data.Id).toPromise();
  await promise.then(resp=>{
    console.log(resp['data']);
    if(resp['data']===0){
      // Swal.fire("Ooops!","The issue has been removed by the support team. In case you need any other help, please …","warning");

      Swal.fire({
        title: "Ooops!",
        text: "The issue has been removed by the support team. In case you need any other help,please create a new issue,Thank You!",
        icon: "warning",
        confirmButtonText: "OK"
    }).then((result) => {
      if (result.isConfirmed) {
        this.close();
        // this.router.navigateByUrl('/support/it-form')



      }
    });
    }else{
    this.ticket = resp['data'];
    this.title.setTitle(this.ticket.ticketSubject);
    // if(this.ticket.isCompleted){
    //   Swal.fire({
    //     title: "Ooops!",
    //     text: "This issue has already been resolved. If you need any other help, please create a new issue. Thank You!",
    //     icon: "warning",
    //     confirmButtonText: "OK"
    // }).then((result) => {
    //   if (result.isConfirmed) {
    //     // this.close();
    //     // this.router.navigateByUrl('/support/it-form')



    //   }
    // });
    // }
    if(JSON.stringify(this.ticket)==="{}"){
    //  this.router.navigateByUrl('/support/it-form')
    this.close()
    }
  }
  },error=>{

    // this.router.navigateByUrl('/support/it-form')
    this.close();
  }

    );

}

  deleteFile(){
this.docService.PostDoc({"Id":this.fileId,'isDeleted':true}).subscribe(resp=>{
this.fileId=-1
$('#delete_file').modal('hide')
this.fileId=-1;
this.getData();
},error=>{
  // console.log("error",error)
});
  }
  openImage(files,i){
    this.items = files.map(item => {
      return new ImageItem({ src: item.filePath, thumb: item.filePath });
    });

  const lightboxRef = this.gallery.ref("lightbox");
  lightboxRef.setConfig({
    imageSize: ImageSize.Cover,
    thumbPosition: ThumbnailsPosition.Top,

    gestures: false
  });
  // Add custom gallery config to the lightbox (optional)

  // Load items into the lightbox gallery ref
  lightboxRef.load(this.items);

    this.lightbox.open(i,'lightbox');
  }
postComment(){
 let data = this.CommentForm.value;

  data["Id"]=0;
  data["sendBy"]=User.Id;

  data["itFormId"]=this.data.Id;
  data["isDeleted"]=false;
  let imgs = []
  this.files.forEach(item=>{
    imgs.push({"filePath":item.filePath,'name':item.name,"type":item.type})
  })
  data["files"]= this.files.length;
this.commentService.PostComment(data).subscribe(resp=>{
  let docData = {"Id":0,"commentId":resp['data'].Id,"files":imgs,"userId":null}
  this.docService.PostDoc(docData).subscribe(resp=>{
    this.files=[];
  },error=>{
    // console.log(error);
  });
//  this.getData();
// console.log(this.socketService.socket.readyState);
if (   this.socketService.socket.readyState === WebSocket.CLOSED ||this.socketService.socket.readyState ===WebSocket.CLOSING ) {
  this.socketService.setupSocketConnection(this.data.Id);
}
// if(this.socketService.socket.readyState ===WebSocket.OPEN)
// {this.socketService.sendData();
// }

 this.CommentForm.reset();
},error=>{
  // console.log(error);
})
}
  save(): void {
  }
  resolve(){
    this.ticket.isCompleted= true;
    let data = this.ticket;
    data['resolvedBy'] = User.Name;
    data['resolvedById'] = User.Id;
    data = JSON.stringify(data);
    data = JSON.parse(data);
    this.itformservice.PostItForm(data).subscribe(
      success=>{

        Swal.fire({ width: 1024,title:success['data']['ticketId'],text:"This ticket id is Succesfully Resolved.",icon:'success'});

        // if(this.socketService.itForm.readyState ===WebSocket.OPEN)
        // {this.socketService.senditFormData();
        // }

    this.close()
      },error=>{
      //  console.log("",error.toString(),"error");
      }
    );
  }
  close(){
    this.dialogRef.close();

  }
}
