import { ApplicationProcessSendEmailDialogueComponent } from '../application-process-send-email-dialogue/application-process-send-email-dialogue.component';
import { User } from 'src/environments/User';
import { DocumentFileServiceProxy } from '../../../Services/document.service';
import { Title } from '@angular/platform-browser';
import { CryptoService } from '../../../Services/CryptoService.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Component, Inject, Injector, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA, } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar'
import { MatCheckboxChange } from '@angular/material/checkbox'
import { finalize } from 'rxjs/operators';
import * as _ from 'lodash';

import {
  Gallery,
  GalleryItem,
  ThumbnailsPosition,
  ImageSize,
  ImageItem
} from "ng-gallery";

import { Lightbox } from "ng-gallery/lightbox";
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ticket } from 'src/app/shared/ticket';
import { ItFormServiceProxy } from 'src/app/Services/itformServiceProxy.service';
import Swal from 'sweetalert2';
import { commentService } from 'src/app/Services/commentService.service';
import { RoleServiceProxy } from 'src/app/Services/rolesServiceProxy.service';
import { MatterServiceProxy } from 'src/app/Services/matterServiceProxy.service';
import { CaseServiceProxy } from 'src/app/Services/caseServiceProxy.service';
import { WorkSourceServiceProxy } from 'src/app/Services/workSourceServiceProxy';
import { AutoCompleteModel } from 'src/@shared-components/auto-complete/models/auto-model';
import { WorkSourceDialogComponent } from '../../work-source/work-source.component';
import { loaderActivation } from 'src/environments/loaderActivation';
import { PermissionService } from 'src/app/Services/PermissionServices.service';
import { ApplicationProcessApproveComponent } from '../application-process-approve/application-process-approve.component';

declare const $: any;
@Component({
  templateUrl: './drafted-application-process.component.html',
})

export class DraftedApplicationProcessComponent
  implements OnInit {
  public addApplication: FormGroup;
  matterList = [{"Id":"k"}];
  worksource :any;
  paymentMethod = ['BACS', 'Cheque', 'Cash', 'Card'];
  user: any;
  workSourceDropDownData: Array<AutoCompleteModel>;
 workSourceAutoCompleteCtrl: FormControl;
  blist: any;
  coloumn: { Name: string; property: string; type: string; }[];
  loader: boolean;
  allCases: any[];
  caseInvoice: any;


  constructor(
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private matterService: MatterServiceProxy,
    private caseService: CaseServiceProxy,
    private workSourceService: WorkSourceServiceProxy,
    private permission:PermissionService,

    public dialogRef: MatDialogRef<DraftedApplicationProcessComponent>,
    private _dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public ReceviedData: any ,
  ) {
    this.workSourceDropDownData = new Array<AutoCompleteModel>();
    this.workSourceAutoCompleteCtrl = new FormControl();


  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }
   ngOnInit()  {
    this.getData();
    this.user = User;
          this.addApplication = this.formBuilder.group({
        Id: [0],
        clientName: ["", [Validators.required]],
        matterId: [null, [Validators.required]],
        workSourceId: [null, [Validators.required]],
        email: ["", [Validators.required]],
        phoneNumber: ["", [Validators.required]],
        deadLine: ["", [Validators.required]],
        agreedCost: ["", [Validators.required]],
        vatStatus: ['false', [Validators.required]],
        inclusiveOfVat: ['false', [Validators.required]],
        feePaid: ["", [Validators.required]],
        paymentMethod: ["", [Validators.required]],
        referenceOfPayment: ["", [Validators.required]],
        detailsOfMatter: ["", [Validators.required]],
        isApproved:true
      });
    // if(this.ReceviedData.check){
    //   this.addApplication = this.formBuilder.group({
    //     Id: [0],
    //     clientName: this.ReceviedData.array.name,
    //     matterId: [null, [Validators.required]],
    //     workSourceId: [null, [Validators.required]],
    //     email: this.ReceviedData.array.email,
    //     phoneNumber: this.ReceviedData.array.phoneNumber,
    //     deadLine: ["", [Validators.required]],
    //     agreedCost: this.ReceviedData.array.dealAmount,
    //     vatStatus: ['false', [Validators.required]],
    //     inclusiveOfVat: ['false', [Validators.required]],
    //     feePaid: ["", [Validators.required]],
    //     paymentMethod: ["", [Validators.required]],
    //     referenceOfPayment: ["", [Validators.required]],
    //     detailsOfMatter: this.ReceviedData.array.notes,
    //     isApproved:false,
    //   });
    // }
    // else{
    //   this.addApplication = this.formBuilder.group({
    //     Id: [0],
    //     clientName: ["", [Validators.required]],
    //     matterId: [null, [Validators.required]],
    //     workSourceId: [null, [Validators.required]],
    //     email: ["", [Validators.required]],
    //     phoneNumber: ["", [Validators.required]],
    //     deadLine: ["", [Validators.required]],
    //     agreedCost: ["", [Validators.required]],
    //     vatStatus: ['false', [Validators.required]],
    //     inclusiveOfVat: ['false', [Validators.required]],
    //     feePaid: ["", [Validators.required]],
    //     paymentMethod: ["", [Validators.required]],
    //     referenceOfPayment: ["", [Validators.required]],
    //     detailsOfMatter: ["", [Validators.required]],
    //     isApproved:true
    //   });
    // }

  }

  addApplicationProcess = () => {
    // let self = this;
    // if (this.addApplication.valid) {
    //   let bol = this.addApplication.get('vatStatus').value=="true";
    //   this.addApplication.patchValue({ vatStatus: bol })
    //   let bol1 = this.addApplication.get('inclusiveOfVat').value=="true";
    //   this.addApplication.patchValue({ inclusiveOfVat: bol1 })
    //   let data = this.addApplication.value;
    //   data = JSON.stringify(data);
    //   data = JSON.parse(data);
    //   data["isDeleted"] = false;
    //   data['addedByUserId']= User.Id;
    //   this.caseService.PostCase(data).subscribe(Response => {
    //     Swal.fire("Success", "Case created successfully", "success");
    //     this.addApplication.reset();
    //     this._dialog.open(ApplicationProcessSendEmailDialogueComponent,{
    //       disableClose:true,
    //           width:'600px',
    //           height:'510px',
    //         }).afterClosed().subscribe(async (result) => {
    //           self.dialogRef.close(true);
    //       });
    //   }, error => {

    //     Swal.fire("Error", error.error.error, "error");
    //   })

    // }
    // else{
    //   for( let i in this.addApplication.controls){
    //     this.addApplication.controls[i].markAsTouched();
    //   }
    // }
  }
  checkPermission=(name,key,BranchId)=>{
    var ex = this.permission.checkVisibilityByModuleName(name,key,BranchId)
    return ex
  }
  async getData(){

    this.loader = true;
    loaderActivation.loader = true;
    this.allCases = [];
    let self = this;
   const promise=  this.caseService.getRetainer().toPromise();
    await promise.then(function(response){
      self.allCases= response['result'];
      self.allCases=self.allCases.filter(x=>x.isApproved==false);
      // data.forEach(element => {
      //   if(!element['isDraft']){
      //     self.allCases.push(element);
      //   }
      // });

    });
    if(!this.blist){
      this.blist=(User.BranchId);
     }
  this.coloumn = [
    {'Name':'Id','property':'Id','type':'Id'},
    {'Name':'Client Name','property':'clientName','type':'text'},
    {'Name':'Email','property':'email','type':'text'},
    {'Name':'Phone Number','property':'phoneNumber','type':'text'},
    {'Name':'Work Source','property':'workSource','type':'text'},
    {'Name':'Creation Date','property':'creationTime','type':'date'},
    {'Name':'Created By','property':'createdBy','type':'text'},
    {'Name':'Verification','property':'approve',"type":"button"}
  ];
  this.loader = false;
  loaderActivation.loader = false;
  }
  close() {
     this.dialogRef.close(false);
  }
  openShortRetainer(data){
    // let data = this.addApplication.value;
      data = JSON.stringify(data);
      data = JSON.parse(data);
    this.close();
    this._dialog.open(ApplicationProcessApproveComponent,{
      disableClose:true,
      width:'800px',
      height:'750px',
      data: {
        check:true,
        array:data
      }
    }).afterClosed().subscribe(async (result) => {



  });
  }
  approve(data){
     let self = this;
      let data1 = JSON.stringify(data);
      data1 = JSON.parse(data1);
      data1["isDeleted"] = false;
      data1['addedByUserId']= User.Id;
      data1['isApproved']=true;
      this.caseService.PostCase(data1).subscribe(Response => {
        Swal.fire("Success", "Retainer Approved successfully", "success");
        this.addApplication.reset();
        this._dialog.open(ApplicationProcessSendEmailDialogueComponent,{
          disableClose:true,
              width:'600px',
              height:'510px',
            }).afterClosed().subscribe(async (result) => {
              self.dialogRef.close(true);
          });
      }, error => {
        Swal.fire("Error", error.error.error, "error");
      })
  }
}
