import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { filter, map, startWith } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { AutoCompleteModel } from './models/auto-model';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';

@Component({
    selector: 'auto-complete',
    templateUrl: './auto-complete.component.html',
    styleUrls: ['./auto-complete.component.scss']
})
export class AutocompleteComponent implements OnInit {


    @Input() autoCompleteCtrl: FormControl;
    filteredData$: Observable<AutoCompleteModel[]>;
    @Input() hint: string;
    @Input() placeHolder: string;
    @Input() plainField: boolean;
    @Input() label: string;
    @Input() data: Array<AutoCompleteModel>;
    @Output() addNewEventEmitter: EventEmitter<any>;
    @Output() closeEventEmitter: EventEmitter<any>;
    @Output() valueChangedEventEmitter: EventEmitter<any>;
    @Output() loadDataEventEmitter: EventEmitter<any>;
    @Input() addNewDialog : boolean;
    @ViewChild(MatAutocompleteTrigger) autocomplete: MatAutocompleteTrigger;
    showAddButton: boolean = false;
    prompt = 'Press <enter> to add "';
    constructor() {
        this.addNewEventEmitter = new EventEmitter();
        this.closeEventEmitter = new EventEmitter();
        this.valueChangedEventEmitter = new EventEmitter();
        this.loadDataEventEmitter = new EventEmitter();
        this.plainField = false;
        this.addNewDialog = true;
        this.data = new Array<AutoCompleteModel>();
        this.autoCompleteCtrl = new FormControl();
    }
    ngOnInit(){

    }
    ngAfterViewInit() {


        this.filteredData$ = this.autoCompleteCtrl.valueChanges.pipe(
            startWith(" "),
            map(value => {
                if(!value){
                    value="";
                }
                if (value) {
                    const item = this.data.find(x => x.text.toLowerCase().includes((value as string).toLowerCase()));

                    if (item) {
                        this.valueChanged(item.value);
                        return item ? this.filterData(item.text) : this.data.slice();
                        this.showAddButton = false;
                    }
                    else{
                      this.valueChanged(null);
                      this.showAddButton = true;
                      let results = [this.prompt + value + '"'];
                      // this.data.push(results);
                    }
                }
                return this.data;
            })
        );
    }

    filterData(text: string) {
        if (this.data.length > 0) {
            let fData =  this.data.filter(item =>
                item.text.toLowerCase().includes(text.toLowerCase()) === true);
            if(fData.length<=0){
              this.showAddButton = true;
            }else{
              this.showAddButton = false;
            }



            return fData;
        } else {
            this.loadData();
        }
    }
    Data(value){
        this.autoCompleteCtrl.setValue(value);
    }
    autoCompleteAddNewClick() {
        this.addNewEventEmitter.emit();
        this.autocomplete.closePanel();
    }

    loadData() {
        this.loadDataEventEmitter.emit();
    }
    valueChanged(value) {
        this.valueChangedEventEmitter.emit(value);
    }
    close() {
        this.autoCompleteCtrl.reset();

        this.closeEventEmitter.emit();
          this.Data("");
    }
}
