import { tap, shareReplay } from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { environment } from '../../environments/environment';
import { HttpClient, HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';



@Injectable()
export class OfficeAccountServiceProxy{

  private apiRoot = environment.apiURL;

  constructor(private http: HttpClient) { }

  Post(data){
    return this.http.post(this.apiRoot.concat('officeAccount/'),data).pipe(
      tap(response =>{

        }),
      shareReplay(),
    );
  }
  getById(id){
    return this.http.put(this.apiRoot.concat('officeAccountbyCaseId/'+id+'/'),{}).pipe(
      tap(response =>{

        }),
      shareReplay(),
    );
  }
  getAllData()
  {

    var url =  this.apiRoot.concat('officeAccount')
     return this.http.get(
    url
    ).pipe(
      tap(response =>{

        }),
      shareReplay(),
    );


  }

}
