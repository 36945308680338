import { DatePipe } from "@angular/common";
import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { EncryptionDecryptionService } from "src/app/Services/encryption-decryption.service";
import { LeadFormServiceProxy } from "src/app/Services/leadForm.service";
import { PermissionService } from "src/app/Services/PermissionServices.service";
import { AppSettings } from "src/app/shared/app.settings";
import { loaderActivation } from "src/environments/loaderActivation";
import { User } from "src/environments/User";
import Swal from "sweetalert2";
import { Location } from '@angular/common';
declare const $: any;



@Component({
  selector:"drafted-lead-form",
  templateUrl:'./drafted-lead-form.componet.html',
  styleUrls: ["./drafted-lead-form.component.scss"],
})

export class DraftedLeadFormComponent implements OnInit{
  blist:any;
  loader:any=false;
 Name:any;
 rangeDates: any;
  moduleName: any;
  storeLeads :any =[];
  allLeadForms:any=[];
  leadForms:any=[];
 public coloumn : any;
  userbranches: any[];
  rows: any;
  srch: any[];
  public pipe = new DatePipe("en-US");

  filterFields: string[];
  constructor(
    private permission:PermissionService,
    private router: Router,
    private leadFormService: LeadFormServiceProxy,
    private encryptionDecryptionService: EncryptionDecryptionService,
    private location: Location,


  ){
    this.moduleName = 'Drafted Lead Forms';

  }
  backBtn(){
    this.location.back();
  }
  async getData(){
    this.loader = true;
    loaderActivation.loader=true;
    this.userbranches = User.Permissions;
    if(!this.blist){
      this.blist=(User.BranchId);
     }
    let self = this;
    this.allLeadForms = []
    const promise=  this.leadFormService.getAllData().toPromise();
    await promise.then(function(response){
      let data = response['LeadForms'];
      self.allLeadForms= data;
      self.leadForms = self.allLeadForms;
      self.allLeadForms = self.allLeadForms.filter(x=>x.branchId==self.blist)
      self.allLeadForms = self.allLeadForms.sort((a, b) => (new Date(b.creationTime) > new Date(a.creationTime) ? -1 : 1));
      self.storeLeads = self.allLeadForms;
      // data.forEach(element => {
      //   if(element['isDrafted']){
      //     self.allLeadForms.push(element);
      //   }
      // });

    });
    this.allLeadForms = this.allLeadForms.reverse();
    this.rows = this.allLeadForms;
    this.srch = [...this.rows];

     this.coloumn = [
      {'Name':'Id','property':'Id','type':'Id'},
      {'Name':'Name','property':'name','type':'text'},
      {'Name':'Email','property':'email','type':'text'},
      {'Name':'Phone Number','property':'phoneNumber','type':'text'},
      {'Name':'Creation Date','property':'creationTime','type':'date'},
      {'Name':'Label','property':'label','type':'text'},
      {'Name':'Disabled','property':'isDrafted','type':'disabled'},
      {'Name':'Actions','property':'','type':'actions'},

    ];
    this.filterFields = ['Id','name','email','phoneNumber','creationTime','label','isDrafted'];
    this.loader = false;
    loaderActivation.loader=false;
    // setTimeout(function(){
    //   $('data-grid  table  tbody tr').each(function() {
    //   var td = $(this).find('td').eq(5);

    //     if(td.text().includes("Discovery")){
    //       td.addClass("bg-pink-medium text-center");
    //     }
    //     if(td.text().includes("Contact Made")){
    //       td.addClass("bg-lightyellow text-center");
    //     }

    //     if(td.text().includes("Negotiation")){
    //       td.addClass("bg-negotiation-green text-center");
    //     }


    //     if(td.text().includes("Qualified")){
    //       td.addClass("bg-seagreen text-center");
    //     }


    //     if(td.text().includes("Closed")){
    //       td.addClass("bg-shocking-pink text-center");
    //     }


    //     if(td.text().includes("Lost")){
    //       td.addClass("bg-web-red text-center");
    //     }

    //     if(td.text().includes("Multi-Label")){
    //       td.addClass("bg-app-light text-center");
    //     }
    //     if(td.text().includes("New Client")){
    //       td.addClass("bg-light-seagreen text-center");
    //     }
    //     if(td.text().includes("Existing-Active")){
    //       td.addClass("bg-retainer-light text-center");
    //     }
    //     if(td.text().includes("Existing-Awaiting Decission")){
    //       td.addClass("bg-retainer-light text-center");
    //     }
    //     if(td.text().includes("Existing-Awaiting Completion")){
    //       td.addClass("bg-retainer-light text-center");
    //     }
    //     if(td.text().includes("Existing-Archieved")){
    //       td.addClass("bg-retainer-light text-center");
    //     }
    //     if(td.text().includes("Existing-Retainer")){
    //       td.addClass("bg-retainer-light text-center");
    //     }

    //   });
    // //  alert( $('data-grid table tr:eq(2) td:eq(4)').text())

    // },700);
  }
  allBranches(){
    this.allLeadForms = this.leadForms;
  }
  dateChange(){
    if(this.rangeDates[1]!= null){
    console.log('Trigerred');
    console.log(this.rangeDates);
    let leads :any=[];
    let mySimpleFormatTo = this.pipe.transform(this.rangeDates[0], "MM-dd-yyyy");
    let dateFrom = new Date(mySimpleFormatTo);

    let mySimpleFormatFrom = this.pipe.transform(this.rangeDates[1], "MM-dd-yyyy");
    let dateTo = new Date(mySimpleFormatFrom);
    this.storeLeads.forEach(element => {
      let check = this.pipe.transform(element.creationTime, "MM-dd-yyyy");
      let issueDate = new Date(check);
      if (dateTo >= issueDate && dateFrom <= issueDate) {
        leads.push(element)
      }

    });

    // this.allLeadForms = [];
    this.allLeadForms = leads;
    // this.allLeadForms = [...this.allLeadForms];

    }
    else{
      this.allLeadForms = this.storeLeads;
    }
  }
  clear(){
    this.allLeadForms = this.storeLeads;

  }
  ngOnInit(){
    this.getData();

  }
  checkPermission=(name,key,BranchId)=>{
    var ex = this.permission.checkVisibilityByModuleName(name,key,BranchId)
    return ex
  }
  DashboardNavigate()
  {
    this.router.navigateByUrl(AppSettings.DASHBOARD_URL);
  }
  rowClick(value){

    let toSetValues= value;
    if(toSetValues.isDrafted){
      const encId = this.encryptionDecryptionService.encrypt(toSetValues.Id.toString());
    const param = { queryParams: { row: encId } };
    this.router.navigate([AppSettings.MarketingLeadForm_URL],param);
    }

  }
  searchName(val) {
    this.rows.splice(0, this.rows.length);
    let temp = []
    this.srch.forEach(function (d) {
      val = val.toLowerCase();
      if( d.name.toLowerCase().indexOf(val) !== -1 || !val)
      {


          temp.push(d);
      }
    });
   this.allLeadForms=[];
    this.allLeadForms=temp;
  }
  createLead(){
    this.router.navigateByUrl(AppSettings.MarketingLeadForm_URL);
  }
  async toggleSelection(id){

    this.blist=id;
    // this.auth.checkToken();
    if(!this.checkPermission("Drafted Lead Forms","Visiblity",this.blist)){
      this.router.navigateByUrl("error401");
    }

      await  this.getData();


 }
      async deleteSelected(checkL) {
        await checkL.forEach(element => {
          let toSetValues = this.allLeadForms.find(x => x.Id === element);
          toSetValues['isDeleted'] = true;
          toSetValues["rolePermission"] = []
          const promise = this.leadFormService.Post(toSetValues).toPromise()
          promise.then(Response => {
            Swal.fire("Success", "Lead deleted successfully", "success");
          }, error => {
            Swal.fire("Error", "Something is not right", "error");
          })
        });
        this.getData();
        Swal.fire("Success", "Leads deleted successfully", "success");
        $("#delete_selected_role").modal("hide");
      }
      deleteLeads(id) {
        let toSetValues = this.allLeadForms.find(x => x.Id === id);
        toSetValues['isDeleted'] = true;
        toSetValues['addedByUserId'] = User.Id;
        this.leadFormService.Post(toSetValues).subscribe(Response => {
          Swal.fire("Success", "Lead deleted successfully", "success");
          // this.tempId = -1;

          this.getData();
          $("#delete_role").modal("hide");
        }, error => {
          // console.log("",error.toString(),"error");
          Swal.fire("Error", "Something is not right", "error");
        })
      }
}

