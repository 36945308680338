import { User } from 'src/environments/User';
import { DocumentFileServiceProxy } from '../../../Services/document.service';
import { Title } from '@angular/platform-browser';
import { CryptoService } from '../../../Services/CryptoService.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Component, Inject, Injector, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA, } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar'
import { MatCheckboxChange } from '@angular/material/checkbox'
import { finalize } from 'rxjs/operators';
import * as _ from 'lodash';

import {
  Gallery,
  GalleryItem,
  ThumbnailsPosition,
  ImageSize,
  ImageItem
} from "ng-gallery";

import { Lightbox } from "ng-gallery/lightbox";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ticket } from 'src/app/shared/ticket';
import { ItFormServiceProxy } from 'src/app/Services/itformServiceProxy.service';
import Swal from 'sweetalert2';
import { commentService } from 'src/app/Services/commentService.service';
import { RoleServiceProxy } from 'src/app/Services/rolesServiceProxy.service';
import { MatterServiceProxy } from 'src/app/Services/matterServiceProxy.service';
import { MatterGroupServiceProxy } from 'src/app/Services/matterGroupServiceProxy.service';
import { MatterGroupListServiceProxy } from 'src/app/Services/matterGroupListServiceProxy.service';
import { PermissionService } from 'src/app/Services/PermissionServices.service';

declare const $: any;
@Component({
  templateUrl: './matter-create.component.html',
})

export class MatterCreateComponent
  implements OnInit {
  public addApplication: FormGroup;
  permissions: any;
  submit = false;
  public allRoles: any = [];
  public RoleId: any;
  public showMatterType: any;
  matterId: any;
  actionString: string;
  groups = [];
  blist:any;

  list = { name: '', Id: '', matterId: '' };
  listOfItems = [];
  worksource = ['Facebook', 'Linkedin', 'Google', 'Walk'];
  paymentMethod = ['BACS', 'Cheque', 'Cash', 'Card'];
  matterGroup = [];
  //  {
  //   Id:0,
  //   matterGroupId:0,
  //   matterId:0,
  //   matterListId:0,
  //   name:'',
  //   isDeleted:false
  // }
  constructor(
    public dialog: MatDialog,
    private matterService: MatterServiceProxy,
    private matterGroupService: MatterGroupServiceProxy,
    private matterGroupListService: MatterGroupListServiceProxy,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<MatterCreateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { value: any },
    private shareTicket: ticket,
    private itformservice: ItFormServiceProxy,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private CryptoService: CryptoService,
    private title: Title,
    private docService: DocumentFileServiceProxy,
    private commentService: commentService,
    private permission:PermissionService,

    public gallery: Gallery, public lightbox: Lightbox
  ) {
  this.submit = false;


  }
  fileId: any = -1;
  id: any = 0;
  user: any;

  items: GalleryItem[];

  ngOnInit(): void {
  this.submit = false;

    if (this.data.value) {
      this.addApplication = this.formBuilder.group({
        Id: this.data.value.Id,
        Name: this.data.value.Name,
      });
      this.matterId = this.data.value.Id;
      this.listOfItems = this.data.value.matterList;
      this.groups = this.data.value.matterGroupList;


      this.groups.forEach(element1 => {
        let groupList = [];
        groupList = element1['matterGroupList'];
        groupList.forEach(element2 => {
          let Ids = this.listOfItems.map(x => { return x.Id });

          let index = Ids.indexOf(element2.matterListId)
          if (index > -1) {
            element2.name = this.listOfItems[index].name;
            element2.isDeleted = this.listOfItems[index].isDeleted;
            this.listOfItems.splice(index, 1);
          }
        });
      });
      console.log(this.listOfItems);
      //

      this.actionString = "Update";
      this.showMatterType = true;
    }
    else {
      this.addApplication = this.formBuilder.group({
        Id: [0],
        Name: ["", [Validators.required]],
      });
      this.actionString = "Create";
      this.showMatterType = false;
    }
    if(!this.blist){
      this.blist=(User.BranchId);
     }
    this.getData();
    this.user = User;

  }
  checkPermission=(name,key,BranchId)=>{
    var ex = this.permission.checkVisibilityByModuleName(name,key,BranchId)
    return ex
  }
  addMatter() {

    if (this.addApplication.valid) {
  this.submit = true;

      let data = this.addApplication.value;
      data = JSON.stringify(data);
      data = JSON.parse(data);
      data["isDeleted"] = false;
      data["isDrafted"] = false;
      data["addedByUserId"] = User.Id;
      data['matterList'] = this.listOfItems;
      data['groups'] = this.groups;
      this.matterService.PostMatter(data).subscribe(Response => {
  this.submit = false;

        this.addApplication.patchValue({
          Id: Response['matter']['Id']
        });
        this.matterId = Response['matter']['Id'];
        this.listOfItems = [];
        this.listOfItems = Response['matterList'];
        this.listOfItems = [...this.listOfItems];
        this.groups = [];
        this.groups = Response['groups'];
        this.groups = [...this.groups];
        // this.close();
        Swal.fire("Success", "Matter created successfully", "success");
        this.showMatterType = true;
        this.actionString = "Update";
      }, error => {
  this.submit = false;

        Swal.fire("Error", error.error.error, "error");
      })

    }
  }
  onNameChange(val, i) {
    this.listOfItems[i].name = "";
    this.listOfItems[i].name = val;
    console.log("Changed", val)
  }
  onNameChangeGroup(val, i) {
    this.matterGroup[i].name = "";
    this.matterGroup[i].name = val;
    console.log("Changed", val)
  }

  ItemChecked(e, i) {
    if (e.checked) {
      let listOfmatterGroup = {
        Id: 0,
        matterId: this.matterId,
        matterGroupId: 0,
        name: this.listOfItems[i].name,
        matterListId: this.listOfItems[i].Id,
        isDeleted: this.listOfItems[i].isDeleted,
        index: i
      };
      this.listOfItems.splice(i, 1);
      this.matterGroup.push(listOfmatterGroup);
      // this.matterGroup['matterId'] = this.matterId;
      // this.matterGroup['matterListId'].push( this.listOfItems[i].Id);
    }
    else {
      // let index = this.matterGroup['matterListId'].indexOf( this.listOfItems[i].Id)
      // this.matterGroup['matterListId'].splice(index,1);
      // this.listOfItems.splice(i,1);
    }

  }
  RemoveFromGroup(i) {

    let item = {
      Id: this.matterGroup[i].matterListId,
      matterId: this.matterId,
      isDeleted: this.matterGroup[i].isDeleted,
      name: this.matterGroup[i].name
    }
    this.listOfItems.push(item);
    this.matterGroup.splice(i, 1);
  }
  RemoveFromGroupList(item, groupindex, listindex) {
    if(this.checkPermission('Matter','Update',this.blist) && this.checkPermission('Matter','Create',this.blist)){
      item['isDeleted'] = true;
    if(item['Id']==0){
      let i = {
        Id:item['matterListId'],
        matterId: this.matterId,
        isDeleted: false,
        name: item['name']
      }
      this.listOfItems.push(i);
      this.groups[groupindex].matterGroupList.splice(listindex, 1);
      if(this.groups[groupindex].matterGroupList.length==0){
        this.deleteGroup(groupindex);
      }
    }
    else{
      this.matterGroupListService.PostMatterGroupList(item).subscribe(Response => {
        let item = {
          Id: Response['matterGroupListItem']['matterListId'],
          matterId: this.matterId,
          isDeleted: false,
          name: Response['matterGroupListItem']['name']
        }
        this.listOfItems.push(item);
        this.groups[groupindex].matterGroupList.splice(listindex, 1);
        if(this.groups[groupindex].matterGroupList.length==0){
          this.deleteGroup(groupindex);
        }
      }, error => {
        Swal.fire("Error", error.error.error, "error");
      });
    }

    }



  }
  AddItemToGroup(index){
    this.matterGroup.forEach(x=>{
      x.matterGroupId= this.groups[index].Id;
      this.groups[index].matterGroupList.push(x);
    });
    let data = this.groups[index];
    data['addedByUserId'] = User.Id;
    data['matterId']= this.matterId;
    this.matterGroupService.PostMatterGroup(data).subscribe(Response => {
      this.groups[index]= Response['group'];
    }, error => {
      Swal.fire("Error", error.error.error, "error");
    })
    this.matterGroup = [];
  }
  deleteGroup(index){
    if(this.checkPermission('Matter','Update',this.blist) && this.checkPermission('Matter','Create',this.blist)){
      this.groups[index].isDeleted= true;
      let data = this.groups[index];
      data['addedByUserId'] = User.Id;
      data['matterId']= this.matterId;
      if(data['Id']==0){
        this.groups[index].matterGroupList.forEach(element => {
          let item = {
            Id: element.matterListId,
            matterId:this.matterId,
            isDeleted: element.isDeleted,
            name: element.name
          }
          this.listOfItems.push(item);
        });
        this.groups.splice(index,1);
      }
      else{
        this.matterGroupService.PostMatterGroup(data).subscribe(Response => {
          this.groups[index].matterGroupList.forEach(element => {
            let item = {
              Id: element.matterListId,
              matterId:this.matterId,
              isDeleted: element.isDeleted,
              name: element.name
            }
          this.listOfItems.push(item);

          });

          this.groups.splice(index,1);
        }, error => {
          Swal.fire("Error", error.error.error, "error");
        })
      }

    }


  }
  Group() {
    Swal.fire({
      title: 'Enter The Name of The Group',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: 'Confirm',
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.isConfirmed) {
        let ls = {
          Id: 0,
          isDeleted: false,
          matterGroupList: this.matterGroup,
          name: result.value
        }
        this.groups.push(ls);
        this.matterGroup = [];

        Swal.fire({
          title: `${result.value}'s Group Is Created`,
        })
      }
    })
  }
  getData() {
    let self = this;

  }

  AddList() {
    if(this.checkPermission('Matter','Update',this.blist) && this.checkPermission('Matter','Create',this.blist)){
      let item = {
        name: 'sample', Id: 0, matterId:this.matterId, isDeleted: false
      }
      this.listOfItems.push(item);
    }


  }
  Remove(i) {
    if(this.checkPermission('Matter','Update',this.blist) && this.checkPermission('Matter','Create',this.blist))
    {
      this.listOfItems[i].isDeleted = true;
      // this.listOfItems.splice(i,1);
    }

  }
  save(): void {
  }

  close() {
    this.dialogRef.close(this.RoleId);
  }
}
