import { User } from '../../../../environments/User';
import { Component, Inject } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { CaseInvoiceServiceProxy } from 'src/app/Services/caseInvoiceServiceProxy.service';
import { ClientAccountServiceProxy } from "src/app/Services/clienAccountServiceProxy.service";
import Swal from "sweetalert2";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { OfficeInvoiceServiceProxy } from 'src/app/Services/officeInvoiceServiceProxy.service';
import { UserServiceProxy } from 'src/app/Services/userServiceProxy.service';
import { DispositionServiceProxy } from 'src/app/Services/dispositionServiceProxy.service';
import { DocumentFileServiceProxy } from 'src/app/Services/document.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Gallery, GalleryItem, ImageItem, ImageSize, ThumbnailsPosition } from 'ng-gallery';
import { Lightbox } from 'ng-gallery/lightbox';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { LeadFormReminderServiceProxy } from 'src/app/Services/leadFormReminderServiceProxy.service';

declare const $: any;

@Component({
    selector: 'create-reminder-dialouge',
    templateUrl: 'create-reminder-dialouge.component.html',
  })
  export class CreateReminderDialougeComponent  {
    leadForm:any;
    userList: any;
  public Editor = ClassicEditor;
  submit = false;

    dispositionTypels = ['No answer', 'Discovery call', 'Follow up call',
    'Hung up','Not interested','Unfit','Wrong number','Sale'];
    public addForm: FormGroup;
    files:any=[];
  postFiles:any=[];
  fileIndex:any=0;
  fileId:any=-1;
  items: GalleryItem[];



    constructor(
        public dialogRef: MatDialogRef<CreateReminderDialougeComponent>,
        private dispositionService: DispositionServiceProxy,
        private userService: UserServiceProxy,
        private formBuilder: FormBuilder,
        public gallery: Gallery,
        public lightbox: Lightbox,
        private _snackBar:MatSnackBar,
        public docService:DocumentFileServiceProxy,
        public leadFormReminderService: LeadFormReminderServiceProxy,

        @Inject(MAT_DIALOG_DATA) public data: {data},
        private _dialog: MatDialog,
        ) {
            this.leadForm = data.data;
            this.submit = false;
        }
        async getData() {
          let self = this;
          const promise=this.userService.getAllData().toPromise();
          await promise.then(function(response){
            self.userList = [];

            let user= response['users'];

            user.forEach(element => {
              element.permission.forEach(element1 => {
                  element1.permissions.forEach(element2 => {
                      if(element2.ModuleName=='Leads'){
                        if(element2.Visiblity){
                          if(!self.userList.includes(element)){
                            self.userList.push(element);
                          }
                        }
                      }
                  });
              });
            });


          });
        }



           ngOnInit() {
             this.getData();
            this.addForm = this.formBuilder.group({
              Id: [0],
              comments: ["", [Validators.required]],
              personActing: ["", [Validators.required]],
              date: ["", [Validators.required]],
            });
           }

    close(){
      this.dialogRef.close();
    }
    Submit(){
      let self = this;
      if(this.addForm.valid){
        this.submit = true;

        let data = this.addForm.value;
        data = JSON.stringify(data);
        data = JSON.parse(data);
        data["isDeleted"] = false;
        data["leadFormId"]= this.leadForm.Id;
        data["isDrafted"]= false;
        data["addedByUserId"]= User.Id;
        let imgs = [];

        this.leadFormReminderService.Post(data).subscribe(function(resp){
          this.submit = false;

          // self.getData();
          Swal.fire(
            'Success!',
            'Reminder is Created.',
            'success'
          );
          self.close();
        }, error => {
          this.submit = false;
          // Swal.fire("Error", error.error.error, "error");
        })

      }
      else{
        for(let i in this.addForm.controls){
          this.addForm.controls[i].markAsTouched();
        }
      }
    }
    openImage(files,i){
      this.items = files.map(item => {
        return new ImageItem({ src: item.filePath, thumb: item.filePath });
      });

    const lightboxRef = this.gallery.ref("lightbox");
    lightboxRef.setConfig({
      imageSize: ImageSize.Cover,
      thumbPosition: ThumbnailsPosition.Top,

      gestures: false
    });
    // Add custom gallery config to the lightbox (optional)

    // Load items into the lightbox gallery ref
    lightboxRef.load(this.items);

      this.lightbox.open(i,'lightbox');
    }
    deleteFile(){
      this.docService.PostDoc({"Id":this.fileId,'isDeleted':true}).subscribe(resp=>{
      this.fileId=-1;
      this.postFiles.splice(this.postFiles.findIndex(x=>x.Id==this.fileId),1);
      },error=>{
        // console.log("error",error)
      });
    }
    deleteFromArray(index) {
      this.files.splice(index, 1);
      this.fileIndex--;
    }
    onFileChange(event) {
      let pFileList = event.target.files;
      let _files = Object.keys(pFileList).map(key => pFileList[key]);


      if (event.target.files && event.target.files[0]) {
        _files.forEach(item => {
          if(item.size<=3145728){
            var reader = new FileReader();

            reader.readAsDataURL(item); // read file as data url

            reader.onload = event => {
              // called once readAsDataURL is completed
              item.filePath = event.target.result;

            };
            item.Id=this.fileIndex;
            this.files.push(item);
            this.fileIndex++;
            this._snackBar.open("Successfully upload!", "Close", {
              duration: 2000
            });
          }
          else{
            this._snackBar.open("Files Size is Bigger Than 3mb, file uploaded but it doesn’t showup with disposition or attachments,but it doesn’t show up with its disposition or even in the attachments tab!", "Close", {
              duration: 2000
            });
          }

        });
      }

    }
  }
