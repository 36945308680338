import { SocketioService } from './../Services/socketio.service';
import { PermissionService } from './../Services/PermissionServices.service';
import { BranchServiceProxy } from './../Services/branchServiceProxy.service';
import { User } from './../../environments/User';
import Swal from 'sweetalert2';
import { deepCopy } from '@angular-devkit/core/src/utils/object';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AppSettings } from 'src/app/shared/app.settings';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { UserServiceProxy } from '../Services/userServiceProxy.service';
import { AutoCompleteModel } from 'src/@shared-components/auto-complete/models/auto-model';
import { MatDialog } from '@angular/material/dialog';
import { AddNewRoleDialogComponent } from '../all-modules/roles/add-new-role-dialog/add-new-role-dialog.component';
import { loaderActivation } from 'src/environments/loaderActivation';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DocumentFileServiceProxy } from '../Services/document.service';
import { Gallery, GalleryItem, ImageItem, ImageSize, ThumbnailsPosition } from 'ng-gallery';
import { Lightbox } from 'ng-gallery/lightbox';

declare const $: any;
@Component({
  templateUrl: './user.component.html',

})
export class usercomponent implements OnInit {
  dtOptions: DataTables.Settings = {};
  @ViewChild(DataTableDirective, { static: false })
  public dtElement: DataTableDirective;
 dashboardRoute: any;
 public user = User;
 hide = true;
 loader:any=false;
public allUsers: any = [];
public Users: any = [];
public userData: any = [];
 public addUserForm: FormGroup;
 public editUserForm: FormGroup;
 public dtTrigger: Subject<any> = new Subject();
 public editId: any;
 public tempId: any;
 public rows = [];
 public srch = [];
 public coloumn : any;
 editDiv:any=null;
 check= true;
 branchDropDownData: Array<AutoCompleteModel>;
 roleDropDownData: Array<AutoCompleteModel>;
 branchAutoCompleteCtrl: FormControl;
 roleAutoCompleteCtrl: FormControl;
 Name:any;
 Status:any;
 Role:any;
 moduleName:string;
 profilePicture:any=[];
 profilePictureIndex:any=0;
 postProfilePicture:any=[];
 profilePictureId:any=1;
 dropdownSettings:IDropdownSettings;
 dropdownSettingsUser:IDropdownSettings;
  onlyUserData: any[];
  constructor(
    private title : Title,
    private formBuilder: FormBuilder,
    private router: Router,
    private userservice: UserServiceProxy,
    private branchService:BranchServiceProxy,
    private permission:PermissionService,
    private location: Location,
    private _dialog: MatDialog,
    private socketService:SocketioService,
    private docService:DocumentFileServiceProxy,
    private _snackBar:MatSnackBar,
    public gallery: Gallery,
    public lightbox: Lightbox,
  ) {

    this.branchDropDownData = new Array<AutoCompleteModel>();
    this.roleDropDownData = new Array<AutoCompleteModel>();
    this.branchAutoCompleteCtrl = new FormControl();
    this.roleAutoCompleteCtrl = new FormControl();
    this.moduleName = "Users";
       }
       backBtn(){
        this.location.back();
      }
  data:any;
  Roles:any;
  caseTags:any;
  selectedItems = [];
  permissions:any;
  userbranches:any;
  branches:any;
  selectedBranch:any;
  blist:any;
  items: GalleryItem[];
 async getData(){
  loaderActivation.loader=true;
  this.Users=[];
  this.allUsers=[];
  this.userData=[];
  this.onlyUserData =[];
  this.populateBranchDropDown();
  let self = this;

    const promise = this.userservice.getAllData().toPromise();
    await promise.then(response=>{
      // console.log(response);

       this.Roles=response['Roles'];
       this.caseTags = response['caseTags'];
       self.dropdownSettings = {
        singleSelection: false,
        idField: 'Id',
        textField: 'Name',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        itemsShowLimit: 3,
        allowSearchFilter: true
      };
      this.onlyUserData = response['onlyUser']

      self.dropdownSettingsUser = {
        singleSelection: false,
        idField: 'Id',
        textField: 'Name',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        itemsShowLimit: 3,
        allowSearchFilter: true
      };


       this.allUsers = response['users'];
       this.allUsers.forEach(element => {
         this.Roles.forEach(role => {
           if(role.Id === element.User.RoleId){
             element.User.roleName = role.Name;
           }
         });
       });
       if(!this.blist){
        this.blist=(User.BranchId);
       }
       this.userbranches = User.Permissions;

       this.permissions = response['module'];
      //  console.log(this.permissions,"userBranches");
      this.userData = this.allUsers;
       this.allUsers= this.allUsers.filter(xx => xx.User.Id != this.user.Id && xx.User.Id != 1 && xx.User.branchId===this.blist);
       this.Roles = this.Roles.filter(xx=>xx.Id!=1);
       this.roleDropDownData = [];
       this.Roles.forEach(item => {
        this.roleDropDownData.push({
            text: item.Name,
            url: '',
            icon: '',
            action: '',
            value: item.Id
        });
        this.roleDropDownData = [...this.roleDropDownData];
    });
       this.rows = this.allUsers;
       this.srch = [...this.rows];

     },error=>{});
     const branch = this.branchService.getAllData().toPromise();
    await branch.then(response=>{

    this.branches = response['Branch']



    },error=>{})
    // console.log("get data");
// setTimeout(() => {
//   this.loader=false;
// }, 1000);
this.coloumn = [
  {'Name':'Id','property':'Id','type':'Id'},
  {'Name':'Name','property':'Name','type':'text'},
  {'Name':'Email','property':'Email','type':'text'},
  {'Name':'Designation','property':'desgination','type':'text'},
  {'Name':'Role','property':'roleName','type':'text'},
  {'Name':'Creation Date','property':'Creation_Time','type':'date'},
  {'Name':'Status','property':'isActive','type':'status'},
  {'Name':'Actions','property':'','type':'actions'}
];
this.allUsers.forEach(element => {
  this.Users.push(element.User)
});
// this.Users = this.allUsers.User;
loaderActivation.loader=false;
  }
  onItemSelect(item: any) {
    console.log(this.selectedItems);
    // console.log(item);
  }
  onSelectAll(items: any) {
    console.log(items);
  }
  allBranches(){
    this.Users = [];
    this.userData.forEach(element => {
      this.Users.push(element.User)
    });
    this.allUsers = this.userData;
  }
   addNewRole() {

    let bol = this.addUserForm.get('RoleId').value ? false: true;
    if(!this.addUserForm.get('RoleId').value)
    {
      // console.log('Add new Role Event');
      this._dialog.open(AddNewRoleDialogComponent,{
        disableClose:true,
            width:'800px',
            height:'510px',
            data: {
              RoleName: this.roleAutoCompleteCtrl.value
            }
          }).afterClosed().subscribe(async (result) => {

            this.addUserForm.patchValue({RoleId:result});
            // console.log("fetching Data")
           await  this.getData();
           if (   this.socketService.userSocket.readyState === WebSocket.CLOSED ||this.socketService.userSocket.readyState ===WebSocket.CLOSING ) {
            this.socketService.setupUserConnetion()
          }
           this.socketService.sendUserData()
          //  console.log("fetch Data")
                this.roleChange(result);
                // console.log("role Change")

        });
    }
  }
   autoFillValueChangedForRole(data: any) {
    this.addUserForm.patchValue({ RoleId: data });

    this.roleChange(data);
  }
  populateBranchDropDown() {
    this.branchService.getAllData().subscribe(response=>{
      const data = response['Branch'] === undefined ? [] : response['Branch'];
      this.branches =  data;
      this.branchDropDownData = [];
      data.forEach(item => {
        this.branchDropDownData.push({
            text: item.branchName,
            url: '',
            icon: '',
            action: '',
            value: item.Id
        });
        this.branchDropDownData = [...this.branchDropDownData];
    });
      },error=>{})
}
checkOtherPerm(p){
  if(p.Delete || p.Create || p.Update || p.Read){
    p.Visiblity=true
  return true;
  }else{
      p.Visiblity=false
    return false}
  }
 addNewbranch() {
  if(!this.addUserForm.get('BranchId').value)
  {
    let data ={
      branchName:this.branchAutoCompleteCtrl.value
    };
    data["isDeleted"]=false;
    data["Id"]=0;
    this.branchService.PostBranch(data).subscribe(async Response=>{
      this.populateBranchDropDown();
      this.addUserForm.patchValue({ BranchId:Response['branch']['Id'] });
      await  this.getData();
      if (   this.socketService.userSocket.readyState === WebSocket.CLOSED ||this.socketService.userSocket.readyState ===WebSocket.CLOSING ) {
        this.socketService.setupUserConnetion()
      }
      this.socketService.sendUserData()

      this.branchChange(Response['branch']['Id']);
     },error=>{
     })
    // console.log('Add new Branch Event');
  }
}
 autoFillValueChangedForBranch(data: any) {
  this.addUserForm.patchValue({ BranchId: data });
  //  this.getData();

  this.branchChange(data)
}

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.dtTrigger.next();
    }, 1000);
  }
  checkPermission=(name,key,BranchId)=>{
    // if(!BranchId){
    //   BranchId=User.BranchId
    // }
    var ex = this.permission.checkVisibilityByModuleName(name,key,BranchId)

    return ex
  }
  ngOnInit() {
    this.profilePicture=[];
    if(!this.checkPermission("Users","Visiblity",User.BranchId)){
      this.router.navigateByUrl("error401");
  }
  // this.socketService.setupUserConnetion();
  this.socketService.userSocket.onmessage=(msg)=>{
    // console.log(msg)
    this.getData();
}

this.socketService.userSocket.onerror = (error) =>
    console.log(`The server received: ${error['code']}`
    );
    this.socketService.userSocket.onclose = (why) =>
    console.log(`The server received: ${why.code} ${why.reason}`
    );

    this.getData();
    this.title.setTitle('Users');
    // this.dashboardRoute = AppSettings.DASHBOARD_URL;
    this.addUserForm = this.formBuilder.group({
      Id: [0],
      Name: ["", [Validators.required]],
      RoleId: ["", [Validators.required]],
      BranchId: ["", [Validators.required]],
      desgination: ["", [Validators.required]],
      Email:["",Validators.email],
      Password: ["", [Validators.required,
        Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}')]],
    //   isActive:[""]
    });
    this.editUserForm = this.formBuilder.group({
        Id: [0],
        isActive: [],
        Name: ["", [Validators.required]],
        RoleId: ["", [Validators.required]],
        BranchId: ["", [Validators.required]],
        desgination: ["", [Validators.required]],
        Email:["",Validators.email],
        Password: [""],
        // isActive:[""]
    });
    this.dtOptions = {
      // ... skipped ...
      pageLength: 10,
      dom: "lrtip",
    };
  }
  selected_permission:any=[];
  public stateChanged(evt,_branch){

    if(evt.checked){
      _branch.branchVisible = true;
  } else {
      _branch.branchVisible = false;


    }

  }
  isChecked(p,k){
    if(p[k]===true){
      return true;
    }else{
      return false;
    }
  }
  Checked(e,id,pid,k){

  this.selected_permission.forEach(x=>{

     var j=0;
     let p =  x.permissions;

     p.forEach(item=>{
        var chk = x.Id== parseInt(id);
        if(chk)
        {
        if(item.Id===pid){
          item[k]=e.target.checked;
        }
        j++;
      }else{
        item[k]=item[k];
      }
      // console.log(item[k],k,x.Id,x.branchName,chk,item.ModuleName,"item",x.permissions);
    }
      )


    })



  }
  VisibleClicked(e , p){
    if(!e.target.checked){
      p.Read= false;
      p.Update = false;
      p.Create = false;
      p.Delete = false;
      p.Visiblity = false;
      p.SelectAll= false;
    }else if(p.Read && p.Update && p.Create && p.Delete && p.Visiblity){
      p.SelectAll = true;
      p.Visiblity = true;
    }
    else{
      p.Visiblity = true
    }
  }
  selectAll(e,p){
    if(e.target.checked){
      p.Read= true;
      p.Update = true;
      p.Create = true;
      p.Delete = true;
      p.Visiblity = true;
    }else{
      p.Read= false;
      p.Update = false;
      p.Create = false;
      p.Delete = false;
      p.Visiblity = false;
    }
  }
  changeSelect(e,p){
    if(!e.target.checked){
      p.SelectAll= false;
    }
    else if(p.Read && p.Update && p.Create && p.Delete && p.Visiblity){
      p.SelectAll = true;
    }
  }

 async toggleSelection(id){

    this.blist=id;
    // this.auth.checkToken();
    if(!this.checkPermission("Users","Visiblity",this.blist)){
      this.router.navigateByUrl("error401");
    }

      await  this.getData();


      }


  Editselected_permission:any=[];
  roleChange(id){



    var self= this;
   id = parseInt(id);
  this.selected_permission =[];
  var i=1;
    this.branches.forEach(element => {

      if(this.selectedBranch){
        if(this.selectedBranch===element.Id){
          element.branchVisible=true;
        }else{
          element.branchVisible=false;
        }
      }else{
        element.branchVisible=false;
      }
      let p  =deepCopy(this.permissions);
           p.forEach(element1=>{
     var per =  element1.filter(x=>x.roleId==id);
     if(per.length>0){
       per.forEach(item=>{
           delete item.Id
           item['SelectAll']= false;
           console.log(item)
       })
     element.permissions =[...per];
     this.selected_permission.push(element)
     }
    });
    });
    // console.log(this.selected_permission)

  }
  level(e,i,le){
    if(e.target.checked){
      le.level = i;
    }
  }
  onProfilePictureChange(event) {
    let pFileList = event.target.files;
    let _files = Object.keys(pFileList).map(key => pFileList[key]);


    if (event.target.files && event.target.files[0]) {
      _files.forEach(item => {
        this._snackBar.open("Successfully upload!", "Close", {
          duration: 2000
        });
        if(item.size<=1048576){
          var reader = new FileReader();

        reader.readAsDataURL(item); // read file as data url

        reader.onload = event => {
          // called once readAsDataURL is completed
          item.filePath = event.target.result;

        };
        item.Id=this.profilePictureIndex;
        this.profilePicture= [];
        this.profilePicture.push(item);
        this.profilePictureIndex++;
        if(this.postProfilePicture.length>0){
          this.postProfilePicture.forEach(element => {
            this.profilePictureId = element.Id;
            this.deleteProfilePic();
          });
        }
        }
        else{
          this._snackBar.open("Files Size is Bigger Than 1mb, Please choose file less than 1mb", "Close", {
            duration: 2000
          });
        }

      });
    }
  }
  deleteProfilePic(){
    this.docService.PostDoc({"Id":this.profilePictureId,'isDeleted':true}).subscribe(resp=>{
    this.profilePictureId=-1;
    this.postProfilePicture.splice(this.postProfilePicture.findIndex(x=>x.Id==this.profilePictureId),1);
    },error=>{
      // console.log("error",error)
    });
  }
  openImage(files,i){
    this.items = files.map(item => {
      return new ImageItem({ src: item.filePath, thumb: item.filePath });
    });

  const lightboxRef = this.gallery.ref("lightbox");
  lightboxRef.setConfig({
    imageSize: ImageSize.Cover,
    thumbPosition: ThumbnailsPosition.Top,

    gestures: false
  });
  // Add custom gallery config to the lightbox (optional)

  // Load items into the lightbox gallery ref
  lightboxRef.load(this.items);

    this.lightbox.open(i,'lightbox');
  }

  deleteFromProfileArray(index) {
    this.profilePicture.splice(index, 1);
    this.profilePictureIndex--;
  }
  closePermissions(){
    this.selected_permission=[];
  }
  EditroleChange(id){
    id = parseInt(id.target.value);
    this.editPermission =[]
    this.branches.forEach(element => {

      if(this.selectedBranch){
        if(this.selectedBranch===element.Id){
          element.branchVisible=true;
        }else{
          element.branchVisible=false;
        }
      }else{
        element.branchVisible=false;
      }
      element.permissions  =   deepCopy( this.permissions);

      element.permissions.forEach(element1=>{
     var per =  element1.filter(x=>x.roleId==id);
     if(per.length>0){
      per.forEach(item=>{
        delete item.Id
        item['SelectAll']= false;
      })
     element.permissions = per;
     this.editPermission.push(element)
     }
    });
    });
   }
  branchChange(id){
    id = parseInt(id);
    if(this.addUserForm.get('RoleId').value){
    this.roleChange(this.addUserForm.get('RoleId').value);}
     this.selectedBranch = id;
   this.selected_permission.forEach(x=>{

      if(x.Id==id || x.branchId==id){
        x.branchVisible=true
      }else{
        x.branchVisible=false
      }});
    }

  EditbranchChange(id){
    id = parseInt(id.target.value);

    this.selectedBranch = id;
      this.editPermission.forEach(x=>{

      if(x.Id==id){
        x.branchVisible=true
      }else{
        x.branchVisible=false
      }});

  }

  Search(){
    let dic ={

    }
    if(this.Name){
      dic['Name'] = this.Name;
    }
    if(this.Status){
      dic['isActive'] = (this.Status==="true");
    }
    if(this.Role){
      dic['RoleId'] = parseInt(this.Role);
    }
    this.rows.splice(0, this.rows.length);
    var self= this;
    let temp =[];
    this.srch.forEach(function (d) {
      // console.log(d);
      let c:number=0;
      let dateCheck=false;
      const keys = Object.keys(dic);

      Object.entries(dic).forEach(([key, value]) => {
      if(d.User[key]==value)
        {  c++;

        }
     });
     console.log(  c,keys.length,keys);
     if(c===keys.length){
      //  console.log(dic);
       temp.push(d.User);
     }
      // return d.ticketSubject.toLowerCase().indexOf(val) !== -1 || !val;
    });
   this.Users=[];
    this.Users=temp
  }
  ClearFilter(){
    this.Role= "";
    this.Name= "";
    this.Status = "";
    this.getData();
  }

  // async rerender(){
  //   // $("#datatable").DataTable().destroy();
  //   $("#datatable").DataTable().clear();


  //   this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
  //     // dtInstance.clear().draw();
  //     dtInstance.destroy();

  //     // setTimeout(() => {
  //     //   this.dtTrigger.next();
  //     // }, 100);

  //   });
  //   this.allUsers = [];
  //   await this.getData();
  //   this.dtTrigger.next();
  //   // $("#datatable").DataTable().draw();






  // }
  searchName(val) {
    this.rows.splice(0, this.rows.length);
    let temp = []
    this.srch.forEach(function (d) {
      val = val.toLowerCase();
      if( d.User.Name.toLowerCase().indexOf(val) !== -1 || !val)
      {


          temp.push(d.User);
      }
    });
   this.Users=[];
    this.Users=temp;
  }
  searchStatus(val) {
    if(val!=""){
      var boolValue = (val =="true");
    this.rows.splice(0, this.rows.length);
    let temp =[];
    this.srch.forEach(function (d) {

      if(d.User.isActive== boolValue){
        temp.push(d.User);
      }
    });
   this.Users=[];
    this.Users=temp;
    }
    else{

      this.rows.splice(0, this.rows.length);
      let temp =[];
      this.srch.forEach(function (d) {
        temp.push(d.User);

      });
     this.Users=[];
      this.Users=temp
    }
  }
  searchPriority(val) {
    if(val!="")
    {
      var i = parseInt(val);
    this.rows.splice(0, this.rows.length);
    let temp =[];
    this.srch.forEach(function (d) {

      if(d.User.RoleId == i){
        temp.push(d.User);
      }
      // val = val.toLowerCase();
      // return d.priorityId.toLowerCase().indexOf(i) !== -1 || !i;
    });
   this.Users=[]; this.Users=temp;
    }
    else{
    this.rows.splice(0, this.rows.length);
    let temp =[];
    this.srch.forEach(function (d) {
        temp.push(d.User);
      // val = val.toLowerCase();
      // return d.priorityId.toLowerCase().indexOf(i) !== -1 || !i;
    });
   this.Users=[]; this.Users=temp;
    }

  }
  DashboardNavigate()
  {
    this.router.navigateByUrl(AppSettings.DASHBOARD_URL);
  }
  editPermission: any;
  editBranches:any
  edit(value) {
    this.profilePicture=[];
    this.postProfilePicture = value['profilePic']
    this.editId = value;
    let toSetValues= value;
    if(Number.isInteger(value)){
      toSetValues = this.allUsers.find(x=>x.User.Id=== value);

    }
    else{
      toSetValues = this.allUsers.find(x=>x.User.Id=== value.Id);

    }
    // let toSetValues = this.allTickets[index];
    this.editPermission = toSetValues.permission;
    this.editBranches = toSetValues.Branch;
  this.editId= toSetValues.User.Id;
    this.editUserForm = this.formBuilder.group({
        Id: toSetValues.User.Id,
        Name:toSetValues.User.Name,
        isActive: toSetValues.User.isActive,
        RoleId:parseInt(toSetValues.User.RoleId),
        Email:toSetValues.User.Email,
        Password: [""],
        desgination:toSetValues.User.desgination,
        BranchId:parseInt(toSetValues.User.branchId),

        // isActive:toSetValues.fields.isActive
    });
    if(this.editDiv){
      this.editDiv.appendTo( "body" );
        this.editDiv = null;

    }
    else{
      $("#edit_ticket").appendTo("body");
    }

    $('#edit_ticket').modal("show");
  }
  closeEdit(){
    $('#edit_ticket').modal("hide");
    this.editDiv= $("#edit_ticket").detach();
    this.editUserForm.reset();
  }
  editUser(){

    if(this.editUserForm.valid){
        let data = this.editUserForm.value;
        data = JSON.stringify(data);
        data = JSON.parse(data);
        data['Id']=parseInt(this.editId);
        data['RoleId'] = parseInt(data['RoleId']);

        data['branchId'] = parseInt(data['BranchId']);
      //   data['Creation_Time']= Date.now;
        data['Deletion_Time']=null;
        data['isDeleted']=false;
        // data['isActive']=true;

        data['is_superuser']=false;
        data['last_login']=null;
        data['CreatorUserId']= User.Id;
        data['permissions'] = this.editPermission;

        data['Branch'] = this.editBranches;
        let dp = [];
      this.profilePicture.forEach(item=>{
        dp.push({"filePath":item.filePath,'name':item.name,"type":item.type})
      });
      this.userservice.PostUserForm(data).subscribe(
        success=>{
          let docData1 = {"Id":0,"userId":data['Id'],"files":dp,"isProfilePicture":true}
        this.docService.PostDoc(docData1).subscribe(resp=>{
          this.profilePicture=[];
        },error=>{
          // console.log(error);
        });

            Swal.fire({ width: 1024,title:"",text:"User updated successfully",icon:'success'});
          $('#edit_ticket').modal('hide');
          this.editId=-1;

          this.editUserForm.reset();
          if (   this.socketService.userSocket.readyState === WebSocket.CLOSED ||this.socketService.userSocket.readyState ===WebSocket.CLOSING ) {
            this.socketService.setupUserConnetion()
          }
          this.socketService.sendUserData();
        },error=>{ Swal.fire("Error",error.error.error,"error");
        }
      );
    }
  }
  addUser(){

    if(this.addUserForm.valid){
      let data = this.addUserForm.value;
      data = JSON.stringify(data);
      data = JSON.parse(data);
      data['RoleId'] = parseInt(data['RoleId']);
      data['branchId'] = parseInt(data['BranchId']);
      data['Id']=0;
    //   data['Creation_Time']= Date.now;
      data['Deletion_Time']=null;
      data['isDeleted']=false;
      data['isActive']=true;
      data['CreatorUserId']= User.Id;
      data['is_superuser']=false;
      data['last_login']=null;
      data['permissions'] = this.selected_permission;
      let dp = [];
      this.profilePicture.forEach(item=>{
        dp.push({"filePath":item.filePath,'name':item.name,"type":item.type})
      });
      data["dp"]= this.profilePicture.length;
      // data['Branch'] = this.branches;
     this.userservice.PostUserForm(data).subscribe(
       success=>{
        let docData1 = {"Id":0,"userId":success['Id'],"files":dp,"isProfilePicture":true}
        this.docService.PostDoc(docData1).subscribe(resp=>{
          this.profilePicture=[];
        },error=>{
          // console.log(error);
        });
        //    console.log(success);

         Swal.fire({ width: 1024,title:"",text:"User created successfully",icon:'success'});
         $('#add_user').modal('hide');
         this.addUserForm.reset();
         if (   this.socketService.userSocket.readyState === WebSocket.CLOSED ||this.socketService.userSocket.readyState ===WebSocket.CLOSING ) {
          this.socketService.setupUserConnetion()
        }
         this.socketService.sendUserData();
       },error=>{
        Swal.fire("Error",error.error.error,"error");

       }
     )
    }
  }
  createUser(){
    $("#add_user").appendTo("body");
    $('#add_user').modal('show');
    this.addUserForm.reset();
    this.branchAutoCompleteCtrl.patchValue("");
    this.roleAutoCompleteCtrl.patchValue("");
  }
  deleteTicket(id) {
    let toSetValues = this.Users.find(x=>x.Id=== id);
    // let perm  = toSetValues.permission;
    toSetValues = toSetValues;
    toSetValues['Id']=id;
    toSetValues['Name']=toSetValues.Name;
    toSetValues['Password']="";
    toSetValues['isDeleted']=true;
    // toSetValues['permissions'] = perm;
    toSetValues['CreatorUserId']= User.Id;
    let data = JSON.stringify(toSetValues);

    data = JSON.parse(data);
    this.userservice.PostUserForm(data).subscribe(
      success=>{
        // $("#datatable").DataTable().clear();
        this.tempId=-1;
        // this.dtTrigger.next();
        this.getData();
     $("#delete_ticket").modal("hide");
     Swal.fire({ width: 1024,title:'Deleted',text:"Succesfully Deleted.",icon:'success'});

      }
    )
  }
  checkList:any=[]
    clearList(){
      this.checkList=[]

    }
    SelectAll(){
      this.allUsers.forEach(element => {
        if(element.Id!=1){
        this.checkList.push(element.Id)
        }
      });
    }
    Check(Id){
      const index = this.checkList.indexOf(Id);
      if (index > -1) {return true}
      else{
        return false
      }
    }
    checkChange(e,Id){
      if(e.target.checked){
        this.checkList.push(Id)
      }else{
        const index = this.checkList.indexOf(Id);
        if (index > -1) {
          this.checkList.splice(index, 1);
        }

      }
    }
 async deleteSelected(checkl){
  await checkl.forEach(element => {
      let toSetValues = this.Users.find(x=>x.Id=== element);
      let perm  = toSetValues.permission;
      toSetValues = toSetValues.User;
      toSetValues['Id']=element;
      toSetValues['Name']=toSetValues.Name;
      toSetValues['Password']="";
      toSetValues['isDeleted']=true;
      toSetValues['permissions'] = perm;
      toSetValues['CreatorUserId']= User.Id;
      let data = JSON.stringify(toSetValues);

      data = JSON.parse(data);
      const promise = this.userservice.PostUserForm(data).toPromise();
       promise.then(Response=>{

          this.tempId=-1;



         },error=>{
          // console.log("",error.toString(),"error");
          Swal.fire("Error","Something is not right","error");
         })
    });
    this.getData();
    this.checkList=[];
    Swal.fire("Success","Users deleted successfully","success");

    $("#delete_selected_user").modal("hide");
  }
}
