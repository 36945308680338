import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { CaseServiceProxy } from "src/app/Services/caseServiceProxy.service";
import { EncryptionDecryptionService } from "src/app/Services/encryption-decryption.service";
import { PermissionService } from "src/app/Services/PermissionServices.service";
import { AppSettings } from "src/app/shared/app.settings";
import { User } from "src/environments/User";
import Swal from "sweetalert2";
import { Location } from '@angular/common'


declare const $: any;
@Component({
  selector:"all-cases",
  templateUrl:'./all-cases.component.html',
})

export class AllCasesComponent implements OnInit{
  allCases:any=[];
  column: { Name: string; property: string; type: string; }[];
  filterFields: string[];
  moduleName: string;
  blist:any;
  caseWorker :boolean= false;
  user = User;
  constructor(
    private caseService: CaseServiceProxy,
    private router: Router,
    private encryptionDecryptionService: EncryptionDecryptionService,
    private permission:PermissionService,
    private location: Location,
  ){
    this.moduleName = "All Cases";
  }
  ngOnInit(){
    this.getData();
    // loaderActivation.loader=true;
  }
  async getData(){
    this.allCases = [];
    let self = this;
    const promise=  this.caseService.getAllData().toPromise();
    await promise.then(function(response){
      self.allCases = [];

      self.allCases = response['result'];
      // self.AwaitingCompletioncaseList = data;
      self.allCases.sort(function(a, b){return b.Id-a.Id});
    });
    this.allCases.forEach(element => {
      let sum = 0;
      let drafted = element['caseInvoice'].filter(x=>x.isDraft);
      drafted.forEach(element1 => {
        sum =sum+ Number(element1['agreedCost']) ;
      });
      let caseWorkerList = element.caseWorkerList;
      let totalCompleted=0;
      let completed = 0;
      let total = 0;
      let checkStatus = true;
      if(caseWorkerList.length>0){
        let caseWorkerLs = [];
        let dic = {}

        caseWorkerList.forEach(element => {
          let caseWorkDic ={
            id: element['userId_id'],
            name: element.CaseWorkerName,
            profilePic:element.profilePic
          }
          // if(element['userId'] in case)
          caseWorkerLs.push(caseWorkDic);
          if(Object.keys(dic).indexOf(element['userId_id'])){
            dic[element['userId_id']]=[]
            dic[element['userId_id']].push(caseWorkDic)
          }
          else{
            dic[element['userId_id']].push(caseWorkDic)
          }
          if(element.status==2){
            totalCompleted +=1;
          }
          if(element.status==1){
            checkStatus= false;

          }
          if(element['userId']===self.user.Id ){
            self.caseWorker =true;
          }
          if(element['caseWorkerGroupList'].length>0){
            element['caseWorkerGroupList'].forEach(element1 => {
              total+=1;
              if(element1['isCompleted']){
                completed+=1;
              }
            });
          }
        });

        let caseWorkerDistant =  []
        for (const [key, value] of Object.entries(dic)) {
          caseWorkerDistant.push(dic[key][0]);

        }
        element['caseWorkerProfileList']= caseWorkerDistant;
      }
      let draftedLength = drafted.length;
      element['draftedLength'] = draftedLength;
      element['totalAmmount'] = sum;
      if(element['caseStatus']==0){
        element['status']="Active";
      }
      else if(element['caseStatus']==1){
        element['status']="Awaiting Decission";
      }
      else if(element['caseStatus']==2){
        element['status']="Awaiting Completion";
      }
      else if(element['caseStatus']==3){
        element['status']="Archeived";
      }
    });
    if(!this.blist){
      this.blist=(User.BranchId);
     }
    this.column =  [
      {'Name':'Id','property':'Id','type':'Id'},
      {'Name':'File Number','property':'fileNumber','type':'text'},
      {'Name':'Client Name','property':'clientName','type':'text'},
      {'Name':'Matter Name','property':'matterName','type':'text'},
      {'Name':'Deadline Date','property':'deadLine','type':'date'},
      {'Name':'Stage','property':'status','type':'bgtext'},
      {'Name':'Case Worker','property':'caseWorkerProfileList','type':'textOrPic'},
      {'Name':'Actions','property':'','type':'actions'},
      // {'Name':'No. Of Draft Payments','property':'draftedLength','type':'number'},
      // {'Name':'Total Amount','property':'totalAmmount','type':'number'},

    ];
    this.filterFields = ['Id','fileNumber','clientName','matterName','deadLine','status'];
  }
  backBtn(){
    this.location.back();
  }
  DashboardNavigate()
  {
    this.router.navigateByUrl(AppSettings.DASHBOARD_URL);
  }
  checkPermission=(name,key,BranchId)=>{
    var ex = this.permission.checkVisibilityByModuleName(name,key,BranchId)
    return ex
  }
  async deleteSelected(checkL) {
    await checkL.forEach(element => {
      let toSetValues = this.allCases.find(x => x.Id === element);
      toSetValues['isDeleted'] = true;
      toSetValues["rolePermission"] = []
      const promise = this.caseService.PostCase(toSetValues).toPromise()
      promise.then(Response => {
        Swal.fire("Success", "Cases deleted successfully", "success");
      }, error => {
        Swal.fire("Error", "Something is not right", "error");
      })
    });
    this.getData();
    Swal.fire("Success", "Leads deleted successfully", "success");
    $("#delete_selected_role").modal("hide");
  }
  deleteCase(id) {
    let toSetValues = this.allCases.find(x => x.Id === id);
    toSetValues['isDeleted'] = true;
    toSetValues['addedByUserId'] = User.Id;
    this.caseService.PostCase(toSetValues).subscribe(Response => {
      Swal.fire("Success", "Case deleted successfully", "success");
      // this.tempId = -1;

      this.getData();
      $("#delete_role").modal("hide");
    }, error => {
      // console.log("",error.toString(),"error");
      Swal.fire("Error", "Something is not right", "error");
    })
  }
  rowClick(e) {

      // let toSetValues = e;
  let toSetValues;
  if(Number.isInteger(e)){
    toSetValues =  e;
  }
  else{
    toSetValues =  e.Id;
  }
    // let toSetValues= e.Id;
    const encId = this.encryptionDecryptionService.encrypt(toSetValues.toString());
    const param = { queryParams: { row: encId } };
    this.router.navigate([AppSettings.Case_View_URL],param);
    // const url = this.router.serializeUrl(this.router.createUrlTree([AppSettings.Case_View_URL],param));
    //   window.open(url);
  }
}
