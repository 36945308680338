import { SocketioService } from 'src/app/Services/socketio.service';
import { AuthService } from './../Services/auth.service';
import { Component, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { NavigationExtras, Router } from "@angular/router";
import { HeaderService } from "./header.service";
import { User } from 'src/environments/User';
import { notificationService } from '../Services/notificationService.service';
import Swal from 'sweetalert2';
import { PermissionService } from '../Services/PermissionServices.service';
import { DatePipe } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { CaseNeedActionsServiceProxy } from '../Services/caseNeedActions.service';
import { loaderActivation } from 'src/environments/loaderActivation';
import { CryptoService } from '../Services/CryptoService.service';


@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
})
export class HeaderComponent implements OnInit {
  jsonData: any = {
    notification: [],
    message: [],
  };
  public pipe = new DatePipe("en-US");
  notifications: any;
  messagesData: any;
  user:any;
  profilePic:any;
  numberOfFiles=0;
  files = []
  constructor(private headerService: HeaderService,
     private router: Router,
     private authservice:AuthService,
    private notifService:notificationService,
    private socketService:SocketioService,
    private sanitizer: DomSanitizer,
    private caseNeedActionService: CaseNeedActionsServiceProxy,
    private encrypt: CryptoService,
    private permission:PermissionService,) {}

    checkPermission=(name,key,branchId)=>{

      if(!branchId){
        branchId=User.BranchId
      }
      var ex = this.permission.checkVisibilityByModuleName(name,key,branchId)


      return ex
    }
async getCaseData(){
  const promise = this.caseNeedActionService.getByUserId(this.user.Id).toPromise();
  await promise.then(resp=>{
    this.numberOfFiles = resp['data'];
    this.files = resp['files']
  })
}
navigateToFiles(){
  loaderActivation.loader = true;

  let navigationExtras: NavigationExtras = {
    queryParams: {
      "all-cases": this.encrypt.encryptUsingAES256(JSON.stringify(this.files))
    }
  };
  this.router.navigate(['/dashboard-view'], navigationExtras);
}
  ngOnInit() {
    console.log("NgonInit");
    // this.getDatas("notification");
    // this.getDatas("message");
    this.user=User;

    if(this.user.profilePic){
      this.profilePic = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(this.user.profilePic.filePath))
    }
    this.getCaseData();



    // this.getData();

    this.notifications = [];

    this.messagesData = [];
  }
  seenFalse:any=0;
  sortNotification(){
    this.notifications.sort(function(a, b){return b.Id-a.Id});
  }
  convertTime(data){

    var unixtimestamp = data.creationTime.split('Z')[0];
    return unixtimestamp;
  }
  async getData(){


    // await this.authservice.checkToken();
  //  const promise =  this.notifService.getNotif().toPromise()

  // this.notifications = [];
  //  await promise.then(resp=>{
  //   this.seenFalse=0;
  //   this.notifications = resp['data'];
  //   // console.log(this.notifications);
  //   let mySimpleFormatTo = this.pipe.transform(this.user.creationTime, "MM-dd-yyyy hh:mm:ss");
  //   let userDate = new Date(this.user.creationTime);

  //   let self=this;
  //   this.notifications = this.notifications.filter(x=>{
  //     let check = self.pipe.transform(x.creationTime, "MM-dd-yyyy hh:mm:ss");
  //     let notifTime = new Date(x.creationTime);
  //     if((x.sendBy!= parseInt(this.user.Id)  ) && (notifTime>=userDate) && (x.roleId==this.user.RoleId || this.user.RoleId==1 || x.sendTo==this.user.Id )){
  //       return x;
  //     }
  //   });

  //   this.notifications.forEach(element => {
  //     if(element['ticketId'] || element['sendTo']){
  //       if(this.checkPermission("ITForm","Read",element['branchId']) || this.checkPermission("ITForm","Read",element['branchId'])){
  //     if(element.seen===false){
  //       this.seenFalse+=1;
  //     }
  //   }
  // }
  //   });
  //   this.sortNotification();

  //   // console.log(this.notifications)
  //  },error=>{
  //    console.log(error);
  //  })

   this.socketService.setupNotficationSocket();
   this.socketService.notification_socket.ondisconnect=(msg)=>{
     console.log("disconnect",msg);
   }
   this.socketService.notification_socket.onmessage = (msg) => {
     // this.authservice.checkToken();

   let  data = JSON.parse(msg['data'])['payload']['data']
   if(data){
   data=JSON.parse(data);
   }
   let mySimpleFormatTo = this.pipe.transform(this.user.creationTime, "MM-dd-yyyy hh:mm:ss");
   let userDate = new Date(this.user.creationTime);

   let check = this.pipe.transform(data.creationTime, "MM-dd-yyyy hh:mm:ss");
   let notifTime = new Date(data.creationTime);
   if(data){
   if((data['sendBy']!=this.user.Id  ) && (notifTime>=userDate) && (data['roleId']==this.user.RoleId || this.user.RoleId==1  || data['sendTo']==this.user.Id)  ){
     if(data['ticketId'] || data['sendTo']){

       if(this.checkPermission("ITForm","Read",data['branchId']) || this.checkPermission("Application Process","Read",data['branchId'])){
         console.log("swal");
     Swal.fire({
     text: data['notification_info'],

     // background: '#ccc',

     icon:"success",
     toast: true,
     position: 'bottom-end',
     showConfirmButton: false,
  showClass: {

 popup: 'swal2-show',


 },
 hideClass: {
   popup: 'swal2-hide'
 },

 timer: 5000,
 timerProgressBar: true,
 didOpen: (toast) => {
   toast.addEventListener('mouseenter', Swal.stopTimer)
   toast.addEventListener('mouseleave', Swal.resumeTimer)
 }
   });
 }}
    data['seen']=false;
    this.seenFalse+=1;
     this.notifications.push(data);
     this.sortNotification();
}
}
   }

   this.socketService.notification_socket.onerror = (error) =>{
     console.log(`The server received: ${error['code']}` );
     this.socketService.setupNotficationSocket();}

   this.socketService.notification_socket.onclose = (why) =>{
     console.log(`The server received: ${why.code} ${why.reason}`
     );
     this.socketService.setupNotficationSocket();
   }

  }
  async seen(Id){
    var data ={
      "Id":0,
      "notifId":Id,
      "seen":true,
      "userId":this.user.Id

    }
    const promise = this.notifService.seen(data).toPromise()
    this.seenFalse=0;
    await promise.then(resp=>this.socketService.sendNotifData(),error=>console.log(error));

  }
  logout(){
    this.authservice.logout();
  }
  getDatas(section) {
    this.headerService.getDataFromJson(section).subscribe((data) => {
      this.jsonData[section] = data;
    });
  }

  clearData(section) {
    this.jsonData[section] = [];
  }
  onSubmit() {
    this.router.navigate(["/pages/search"]);
  }
}
