import { CaseWorkerDetailModal } from './CaseWorkerDetailModal/CaseWorkerDetailModal';
import { CaseServiceProxy } from './../../Services/caseServiceProxy.service';
import { CaseWorkerServiceProxy } from 'src/app/Services/caseWorkerServiceProxy.service';
import { SocketioService } from 'src/app/Services/socketio.service';
import { loaderActivation } from './../../../environments/loaderActivation';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Component, OnInit, ViewChild, Pipe } from '@angular/core';
import { AppSettings } from 'src/app/shared/app.settings';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ItFormServiceProxy } from 'src/app/Services/itformServiceProxy.service';
import { User } from 'src/environments/User';
import Swal from 'sweetalert2';
import { DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { ITFormViewDialogComponent } from 'src/app/Support/it-form/it-form-view/it-form-view.component';
import { EncryptionDecryptionService } from 'src/app/Services/encryption-decryption.service';


declare const $: any;
@Component({
  templateUrl: './to-do.component.html'

})
export class ToDoComponent implements OnInit {
  dtOptions: DataTables.Settings = {};
  @ViewChild(DataTableDirective, { static: false })
  public dtElement: DataTableDirective;
 dashboardRoute: any;
 public dtTrigger: Subject<any> = new Subject();
 completedForm = 0;
 uncompletedForm = 0;
 completeFormPercentage: any=0;
 uncompleteFormPercentage: any=0;
 ticketId: any;
 ticketSubject : any;
 assignName : any;
 deadline : any;
 priority : any;
 Priorities:any;
 public pipe = new DatePipe("en-US");
 tickets:any;
 loader= false;
 todo=[];
 branchList =[];
 responsibilityList:any=[];
 public rows = [];
 public srch = [];
 coloumn = [];
 allCases=[];
 CaseColoumn = [];
 user = User;
  constructor(
    private title : Title,
    private router: Router,
    private itformservice: ItFormServiceProxy,
    private _dialog: MatDialog,
    private socketService:SocketioService,
    private caseworkerService:CaseWorkerServiceProxy,
    private caseService:CaseServiceProxy,
    private encryptionDecryptionService: EncryptionDecryptionService,
  ) {

       }


  async getData(){
    this.loader=true;

    const promise = this.itformservice.getAllData().toPromise();
    await promise.then(response=>{
      this.todo=[];
      this.branchList=[];
      this.srch = [];
      this.rows = [];
      this.completedForm = 0;
      this.uncompletedForm = 0;
      this.Priorities = response['Priority'];
      this.tickets =response['ITForms']
      let branches = User.Permissions;
      branches.forEach(item=>{
        if(item.branchVisible){
          let permissions= item.permissions;
          permissions.forEach(x => {
            if(x.ModuleName==="ITForm"){
              if(x.Update || x.Create){
                this.branchList.push(item.branchId);
              }
            }
          });
        }
      });
      this.tickets.forEach(element => {
        this.branchList.forEach(x=>{
          if(x===element.branchId){
            this.todo.push(element);
          }
        });
      });
      this.todo = this.todo.filter(x=>x.isCompleted===false);

      this.rows = this.todo;
      this.srch = [...this.rows];
      const totalForms = this.todo.length;
      for( let it in this.todo){
        if(this.todo[it].isCompleted){
          this.completedForm+=1;
        }
        else{
          this.uncompletedForm +=1;
        }
      }
      this.completeFormPercentage = ((this.completedForm/totalForms)*100).toFixed(2);
     if(this.completeFormPercentage === "NaN"){
       this.completeFormPercentage=0
     }
      this.uncompleteFormPercentage = ((this.uncompletedForm/totalForms)*100).toFixed(2);
      if(this.uncompleteFormPercentage === "NaN"){
        this.uncompleteFormPercentage=0
      }
      this.coloumn = [
        {'Name':'Id','property':'Id','type':'Id'},
        {'Name':'Ticket Id','property':'ticketId','type':'text'},
        {'Name':'Ticket Subject','property':'ticketSubject','type':'text'},
        {'Name':'Assign Name','property':'createUserName','type':'text'},
        {'Name':'Created Date','property':'creationTime','type':'date'},
        {'Name':'Deadline','property':'issueDate','type':'date'},
        {'Name':'Priority','property':'priority','type':'text'},
        {'Name':'Actions','property':'isCompleted','type':'checkbox-with-click'}
      ]

    },
    error=>{
    // console.log(error)
    })
  // this.loader = false;
  }
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.dtTrigger.next();
    }, 1000);
    this.getCases();
  }
  ticketView(ticket){
    // { state: { id:1 , name:'Angular' } }
    this._dialog.open(ITFormViewDialogComponent,{
      disableClose:true,
      width:'800px',
      height:'768px',
      data: {
        Id: ticket.Id
      }
    }).afterClosed().subscribe(async (result) => {
    });
  }
  resolved(t){
    // e.stopPropagation();
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Resolve this !'
    }).then((result) => {
      if (result.isConfirmed) {
        t.isCompleted=true;
        t["resolvedBy"]=User.Name;
        t['deletedBy']= "";
        t['DeletedbyId'] = "";
        t['resolvedById']=User.Id;
         this.itformservice.PostItForm(t).subscribe(
          success=>{
          //  this.getData();
          this.socketService.sendNotifData()
           Swal.fire(
            'Resolved!',
            'Your Task has been resolved.',
            'success'
          )

          },error=>{
            Swal.fire(
              'Failure!',
              'Fail to resolved.',
              'error'
            )
          //  console.log("",error.toString(),"error");
          }
        );

      }
    })

    console.log("resolved");
  }
  ngOnInit() {
    loaderActivation.loader = true;
    this.getResponsibilites();
    this.socketService.setupCaseSocket();
this.socketService.caseSocket.onmessage=(msg)=>{
this.getCases();
}

this.socketService.caseSocket.onerror = (error) =>
  console.log(`The server received: ${error['code']}`
  );
  this.socketService.caseSocket.onclose = (why) =>
  console.log(`The server received: ${why.code} ${why.reason}`
  );

  
    // this.getResponsibilites();
  this.title.setTitle('Responsiblities');;
    this.dtOptions = {
      // ... skipped ...
      pageLength: 10,
      dom: "lrtip",
    };

  }

 async getResponsibilites() {
   var self= this;
   const promise = this.caseService.getAllData().toPromise();
    await promise.then(function(resp){
      self.responsibilityList = resp["result"];
      self.responsibilityList = self.responsibilityList.filter(x=> (x.personResposibleId==self.user.Id || self.user.RoleId==1 ) && x.caseStatus!=3);
console.log(resp);
  });

  loaderActivation.loader = false;

 }
async getCases(){
 var self = this;
  const promise = this.caseworkerService.getCasesByUserId(this.user.Id).toPromise();
  await promise.then(function(resp){
    var data = resp['data'].map(x=>x.Id);
   self.getCasesData(data);
  })

}
edit(e){
  let toSetValues= e.Id;
  // if(Number.isInteger(value)){
  //   toSetValues = this.allCases.find(x=>x.Id=== value);
  // }
  const encId = this.encryptionDecryptionService.encrypt(toSetValues.toString());
  const param = { queryParams: { row: encId } };
  this.router.navigate([AppSettings.Case_View_URL],param);
//  this._dialog.open(CaseWorkerDetailModal,{width:'800px',
//   height:'768px',
//   disableClose: true ,
//   data: {
//   "data": e,
//   "disabled":false
//   }
// }).afterClosed().subscribe(async (result) => {
// });
}

async getCasesData(data){


  this.allCases = [];
  let self = this;
 const promise=  this.caseService.getAllData().toPromise();
  await promise.then(function(response){
    self.allCases = response['result'];
    self.allCases = self.allCases.filter(x=>data.indexOf( x.Id)!=-1 && x.isDraft==true);
    console.log(self.allCases);
  });
  // if(!this.blist){
  //   this.blist=(User.BranchId);
  //  }
this.CaseColoumn = [
  {'Name':'Id','property':'Id','type':'Id'},
  {'Name':'File Number','property':'fileNumber','type':'text'},
  {'Name':'Client Name','property':'clientName','type':'text'},
  // {'Name':'Email','property':'email','type':'text'},
  // {'Name':'Phone Number','property':'phoneNumber','type':'text'},
  // {'Name':'Work Source','property':'workSource','type':'text'},
  
  {'Name':'Matter Name','property':'matterName','type':'text'},
  {'Name':'Creation Date','property':'creationTime','type':'date'},
  {'Name':'Deadline Date','property':'deadLine','type':'date'},
  {'Name':'Status','property':'status','type':'text'},
  // {'Name':'Actions','property':'','type':'actions'}
];
this.loader = false;
loaderActivation.loader = false;
  }
  ClearFilter(){

    this.ticketId="";
    this.ticketSubject="";
    this.assignName="";
    this.deadline="";
    this.priority="";
    this.getData();
  }
  Search()
  {
    let dic ={

    }
     if(this.ticketSubject){
      dic['ticketSubject']=this.ticketSubject;
     }
     if(this.ticketId){
       dic['ticketId'] = this.ticketId;
     }
     if(this.priority){
       dic['priorityId'] = parseInt(this.priority);
     }
     if(this.assignName){
       dic['createUserName'] = this.assignName;
     }
     if(this.deadline){
      let mySimpleFormat = this.pipe.transform(this.deadline, "MM-dd-yyyy");
       dic['issueDate']=mySimpleFormat;
     }
    this.rows.splice(0, this.rows.length);
    var self= this;
    let temp = this.srch.filter(function (d) {
      // console.log(d);
      let c:number=0;
      const keys = Object.keys(dic);
      Object.entries(dic).forEach(([key, value]) => {
        if(key==="issueDate"){
          let check = self.pipe.transform(d.issueDate, "MM-dd-yyyy");
          if(check===value){
            c++;
          }
        }
      else if(d[key]==value)
        {  c++;
        }
     });
     console.log(c,keys.length);
     if(c===keys.length){
       console.log(dic);
       return d;
     }
    });
    this.rows.push(...temp);
  }
  searchId(val) {
    this.rows.splice(0, this.rows.length);
    let temp = this.srch.filter(function (d) {
      val = val.toLowerCase();
      return d.ticketId.toLowerCase().indexOf(val) !== -1 || !val;
    });
    this.rows.push(...temp);
  }
  searchName(val) {
    this.rows.splice(0, this.rows.length);
    let temp = this.srch.filter(function (d) {
      val = val.toLowerCase();
      return d.ticketSubject.toLowerCase().indexOf(val) !== -1 || !val;
    });
    this.rows.push(...temp);
  }
  searchByAssignName(val) {
    this.rows.splice(0, this.rows.length);
    let temp = this.srch.filter(function (d) {
      val = val.toLowerCase();
      return d.createUserName.toLowerCase().indexOf(val) !== -1 || !val;
    });
    this.rows.push(...temp);
  }
  searchPriority(val) {
    if(val!="")
    {
      var i = parseInt(val);
    this.rows.splice(0, this.rows.length);
    let temp = this.srch.filter(function (d) {
      if(d.priorityId == i){
        return d;
      }
      // val = val.toLowerCase();
      // return d.priorityId.toLowerCase().indexOf(i) !== -1 || !i;
    });
    this.rows.push(...temp);
    }
    else{
    this.rows.splice(0, this.rows.length);
    let temp = this.srch.filter(function (d) {
        return d;
      // val = val.toLowerCase();
      // return d.priorityId.toLowerCase().indexOf(i) !== -1 || !i;
    });
    this.rows.push(...temp);
    }
this.todo = this.rows;
  }
  searchByDeadline(val) {
    let mySimpleFormat = this.pipe.transform(val, "MM-dd-yyyy");
    this.rows.splice(0, this.rows.length);
    var self= this;
    let temp = this.srch.filter(function (d) {
      let check = self.pipe.transform(d.issueDate, "MM-dd-yyyy");

      if(mySimpleFormat === check){
        return d;
      }
    });
    this.rows.push(...temp);
    $(".floating")
      .on("focus blur", function (e) {
        $(this)
          .parents(".form-focus")
          .toggleClass("focused", e.type === "focus" || this.value.length > 0);
      })
      .trigger("blur");
  }

  async rerender() {
    $("#datatable").DataTable().clear();
   await this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // dtInstance.clear().draw();
      dtInstance.destroy();
      // setTimeout(() => {
      //   this.dtTrigger.next();
      // }, 100);
    });
    // this.allTickets = [];
    await this.getData();
    this.dtTrigger.next();
    // setTimeout(() => {
    //   this.dtTrigger.next();
    // }, 1000);
  }
  DashboardNavigate()
  {
    this.router.navigateByUrl(AppSettings.DASHBOARD_URL);
  }

  userId=-1;
}
