import { Component, OnInit, ViewChild } from "@angular/core";




@Component({
  selector:"chat-room",
  templateUrl:'./chat-room.componet.html',
})

export class ChatRoomComponent implements OnInit{
  constructor(){

  }
  ngOnInit(){

  }
}
