import { UserServiceProxy } from './../../Services/userServiceProxy.service';
import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup  } from "@angular/forms";
import { Router } from "@angular/router";
import { LeadFormServiceProxy } from "src/app/Services/leadForm.service";
import { User } from "src/environments/User";
import Swal from "sweetalert2";
import { Location } from '@angular/common';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { BranchServiceProxy } from "src/app/Services/branchServiceProxy.service";
import { PermissionService } from "src/app/Services/PermissionServices.service";
import { ReportsServiceProxy } from 'src/app/Services/reportServiceProxy.service';


@Component({
  selector:"report",
  templateUrl:'./reports.component.html',
})

export class ReportComponent implements OnInit{
  loader: any;
  public addApplication: FormGroup;
  allBranches:any;
  blist:any;
  casesType =[
    {id:5,Name:'Retainer'},
    {id:0,Name:'Active Cases'},
    {id:1,Name:'Awating Decission'},
    {id:2,Name:'Awaiting Completetion'},
    {id:3,Name:'Archived'},

  ]
  submit = false;
  public Editor = ClassicEditor;
  userList: any;

  constructor(
    private formBuilder: FormBuilder,
    private branchService: BranchServiceProxy,
    private permission:PermissionService,
    private userService: UserServiceProxy,
    private reportService: ReportsServiceProxy,
    private location: Location,

  ){
    this.loader = false;


  }
  backBtn(){
    this.location.back();
  }
 async getAllData(){
   let self = this;
    let promise= this.branchService.getAllData().toPromise();
    await promise.then(resp=>{
      self.allBranches = resp['Branch'];
    })

    const prom=this.userService.getAllUserData().toPromise();
    await prom.then(function(response){
      self.userList = response['users'];
    });
  }
  ngOnInit(){
    if(!this.blist){
      this.blist=(User.BranchId);
    }
    this.getAllData();
    this.addApplication = this.formBuilder.group({
      caseType:['undefined'],
      userId:['undefined'],
      branchId:['undefined'],
      personResponsible:[false],
      clientName:[false],
      fileNumber:[false],
      caseTags:[false],
      matterType:[false],
      deadlines:[false],
      email:[false],
      phoneNumber:[false],
      creationDate:[false],
      selectAll:[false]
    });

  }
  checkPermission=(name,key,BranchId)=>{
    var ex = this.permission.checkVisibilityByModuleName(name,key,BranchId)
    return ex
  }
 async Submit(){
    // if(this.addApplication.valid){
      this.loader = true;
      this.submit = true;
      let data = this.addApplication.value;
      data = JSON.stringify(data);
      data = JSON.parse(data);
      data['addedByUserId']= User.Id;
      await this.reportService.Post(data).subscribe(Response => {
        let sheetId = Response['sheetId'];
        let url = 'https://docs.google.com/spreadsheets/d/'+ sheetId
        window.open(url,"_blank")
        this.submit = false;
        Swal.fire("Success", "Report created successfully", "success");
        this.addApplication.reset();
        this.loader = true;
        // this.router.navigateByUrl(AppSettings.DraftedLeadForm_URL);

      }, error => {
        this.submit = false;
        this.loader = true;

        Swal.fire("Error", error.error.error, "error");
      })

    // }else{
    //   for( let i in this.addApplication.controls){
    //     this.addApplication.controls[i].markAsTouched();
    //   }
    // }
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }
  caseChange(value){
    if(value==='5'){
      this.addApplication.patchValue({
        userId:'undefined',
        branchId:'undefined',
      })
    }
    console.log(value)


  }
  selectAll(value){
    if(value.checked){
      this.addApplication.patchValue({
        clientName:true,
      fileNumber:true,
      caseTags:true,
      matterType:true,
      deadlines:true,
      email:true,
      phoneNumber:true,
      creationDate:true,
      })
    }
    else{
      this.addApplication.patchValue({
        clientName:false,
      fileNumber:false,
      caseTags:false,
      matterType:false,
      deadlines:false,
      email:false,
      phoneNumber:false,
      creationDate:false,
      })
    }
  }
}
