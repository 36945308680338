import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable()
export class NotifDataService {

  private messageSource = new BehaviorSubject(0);
  currentMessage = this.messageSource.asObservable();

  constructor() { }

  changeMessage(message: any) {
    this.messageSource.next(message)
  }
  private subject = new Subject<any>();sendClickEvent() {
    this.subject.next();
  }getClickEvent(): Observable<any>{ 
    return this.subject.asObservable();
  }
}
