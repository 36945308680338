import { filter } from 'rxjs/operators';
import { SocketioService } from '../../../Services/socketio.service';
import { AuthService } from '../../../Services/auth.service';
import { PermissionService } from '../../../Services/PermissionServices.service';
import { User } from '../../../../environments/User';
import Swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AppSettings } from 'src/app/shared/app.settings';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { CaseServiceProxy } from 'src/app/Services/caseServiceProxy.service';
import { EncryptionDecryptionService } from 'src/app/Services/encryption-decryption.service';
import { MatterServiceProxy } from 'src/app/Services/matterServiceProxy.service';
import { PrioritiesServiceProxy } from 'src/app/Services/prioritiesServiceProxy.service';
import { UserServiceProxy } from 'src/app/Services/userServiceProxy.service';
import { ApplicationProcessWorkerComponent } from '../application-process-worker/application-process-worker.component';
import { MatTabGroup } from '@angular/material/tabs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Gallery, GalleryItem, ImageItem, ImageSize, ThumbnailsPosition } from 'ng-gallery';
import { Lightbox } from 'ng-gallery/lightbox';
import { DocumentFileServiceProxy } from 'src/app/Services/document.service';
import { CaseWorkerServiceProxy } from 'src/app/Services/caseWorkerServiceProxy.service';
import { AutoCompleteModel } from 'src/@shared-components/auto-complete/models/auto-model';
import { WorkSourceDialogComponent } from '../../work-source/work-source.component';
import { WorkSourceServiceProxy } from 'src/app/Services/workSourceServiceProxy';
import { loaderActivation } from 'src/environments/loaderActivation';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Location } from '@angular/common';
declare const $: any;
@Component({
  selector: "app-application-process-detail",
  templateUrl: './application-process-detail.component.html',
  styleUrls:['./application-process-detail.component.css'],

})
export class ApplicationProcessDetailComponent implements OnInit {
  moduleName: string;
  loader:any=true;
  caseId: any;
  blist:any;
  Priorities:any;
  Users: any;
  matterList = [{"Id":"k"}];
  caseGroups:any;
  caseWorker:any=[];
  caseWorkerError=false;
  caseInvoice: [];
  worksource :any;
  workSourceDropDownData: Array<AutoCompleteModel>;
  workSourceAutoCompleteCtrl: FormControl;
  paymentMethod = ['BACS', 'Cheque', 'Cash', 'Card'];
  paymentAccountls = ['Manchester', 'London', 'Birmingham', 'Chester'];
  typeOfPayment=['Disbursement','Legal FEE'];
  public addApplication: FormGroup;
  profilePicture:any=[];
  postProfilePicture:any=[];
  profilePictureIndex:any=0;
  profilePictureId:any=1;
  public Editor = ClassicEditor;


  files:any=[];
  postFiles:any=[];
  fileIndex:any=0;
  items: GalleryItem[];
  fileId:any=-1;
  @ViewChild(MatTabGroup) tabGroup: MatTabGroup;
  caseTags: any;
  submit= false;
  draftsubmit:any=false;
  confirmsubmit:any=false;

  constructor(
    private title : Title,
    private formBuilder: FormBuilder,
    private router: Router,
    private location: Location,
    private permission:PermissionService,
    private auth:AuthService,
    private _dialog: MatDialog,
    private socketService:SocketioService,
    private caseService: CaseServiceProxy,
    private activateRoute: ActivatedRoute,
    private matterService: MatterServiceProxy,
    private priorityService: PrioritiesServiceProxy,
    private userService: UserServiceProxy,
    private _snackBar:MatSnackBar,
    private workSourceService: WorkSourceServiceProxy,

    public gallery: Gallery,
    public lightbox: Lightbox,
    private caseWorkerService: CaseWorkerServiceProxy,

    private docService:DocumentFileServiceProxy,

    private encryptionDecryptionService: EncryptionDecryptionService,
  ) {

    this.workSourceDropDownData = new Array<AutoCompleteModel>();
    this.workSourceAutoCompleteCtrl = new FormControl();


    this.moduleName = "Application Process";
    this.addApplication = this.formBuilder.group({
      Id: [0],
      clientName: [null, [Validators.required]],
      fileNumber:[null, [Validators.required]],
      deadLine: [null, [Validators.required]],
      matterId: [null, [Validators.required]],
      matterName: [null],
      caseTag: [null, [Validators.required]],
      priorityId:[null,[Validators.required]],
      personResposibleId:[null,[Validators.required]],
      email: [null, [Validators.required]],
      phoneNumber: [null, [Validators.required]],
      mobileNumber: [null,[Validators.required]],
      address: [null,[Validators.required]],
      dueDate: [null,[Validators.required]],
      vatStatus: ['false', [Validators.required]],
      inclusiveOfVat: ['false', [Validators.required]],
      detailsOfMatter: [null, [Validators.required]],
      feePaid: [null, [Validators.required]],
      methodOfPayment: [null, [Validators.required]],
      paymentAccount:[null, [Validators.required]],
      workSourceId: [null, [Validators.required]],
      imagePath:[null],
      referenceOfPayment: [null, [Validators.required]],
      refOfPayment: [null, [Validators.required]],
      agreedCost: [null, [Validators.required]],
      amlCompleted:[null, [Validators.required]],
      amlDate: [null, [Validators.required]],
      amlEvidenceFile:[null, [Validators.required]],
      amlNotes:[null, [Validators.required]],
      conflictCompleted: [null, [Validators.required]],
      conflictDate: [null, [Validators.required]],
      conflictNotes: [null, [Validators.required]],
      creationTime:[null, [Validators.required]],
      deletionTime:[null, [Validators.required]],
      identityCompleted: [null, [Validators.required]],
      identityDate: [null, [Validators.required]],
      identityEvidenceFile: [null, [Validators.required]],
      identityNotes: [null, [Validators.required]],
      isCompleted:[null, [Validators.required]],
      isDeleted:[null, [Validators.required]],
      isDraft: [null, [Validators.required]],
      resolvedBy:[null, [Validators.required]],
      typeOfPayment: [null,[Validators.required]],
      notesOnCost: [null,[Validators.required]],
    });
       }
       backBtn(){
        this.location.back();
      }
 async getData(){


  await this.getMatter();
  await this.getPriorities();
   await this.getUser();
   await this.getAllWorkSources();
   const promise = this.caseService.getById(this.caseId).toPromise();
    await promise.then(resp=>{
      console.log(resp);
      var data = resp['data'][0];
      this.caseGroups = data.matterGroups;
      this.caseTags = resp['caseTags'];
      this.caseWorker = data['caseWorkerList'];
      this.Users.forEach(element => {
        element['isAvaiblible'] = true;
      });
      this.caseWorker.forEach(element => {
        let index = this.Users.findIndex(x=>x.User.Id==element['userId_id']);
        this.Users[index]['isAvaiblible']= true;
      });
      this.caseInvoice = data['caseInvoice'];
      this.postProfilePicture = data['profilePicture']
      this.postFiles = data['files'];
      let agreedCost,feePaid,invoiceNumber,methodOfPayment,notesOnCost,refOfPayment,typeOfPayment,caseInvoiceId;
      this.caseInvoice.forEach(element=>{
        if(element['isDraft']){
            agreedCost= element['agreedCost'];
            feePaid= element['amountPaid'];
            invoiceNumber= element['invoiceNumber'];
            methodOfPayment= element['methodOfPayment'];
            notesOnCost= element['notesOnCost'];
            refOfPayment= element['refOfPayment'];
            typeOfPayment= element['typeOfPayment'];
            caseInvoiceId= element['Id'];
        }
      });
      this.workSourceAutoCompleteCtrl.setValue(data.workSource);
      this.addApplication = this.formBuilder.group({
        Id: data.Id,
        clientName: data.clientName,
        fileNumber:data.fileNumber,
        deadLine: data.deadLine,
        matterId: data.matterId,
        matterName: data.matterName,
        caseTag: data.caseTag,
        priorityId:data.priorityId,
        personResposibleId:data.personResposibleId,
        email: data.email,
        phoneNumber: data.phoneNumber,
        mobileNumber: data.mobileNumber,
        address: data.address,
        dueDate: data.dueDate,
        vatStatus: data.vatStatus,
        inclusiveOfVat: data.inclusiveOfVat,
        detailsOfMatter: data.detailsOfMatter,
        paymentAccount:data.paymentAccount,
        workSourceId: data.workSourceId,
        imagePath:data.imagePath,
        amlCompleted:data.amlCompleted,
        amlDate: data.amlDate,
        amlEvidenceFile:data.amlEvidenceFile,
        amlNotes:data.amlNotes,
        conflictCompleted: data.conflictCompleted,
        conflictDate: data.conflictDate,
        conflictNotes: data.conflictNotes,
        creationTime:data.creationTime,
        deletionTime:data.deletionTime,
        identityCompleted: data.identityCompleted,
        identityDate: data.identityDate,
        identityEvidenceFile: data.identityEvidenceFile,
        identityNotes: data.identityNotes,
        isCompleted:data.isCompleted,
        isDeleted:data.isDeleted,
        isDraft: data.isDraft,
        resolvedBy:data.resolvedBy,
        agreedCost: agreedCost,
        feePaid: feePaid,
        invoiceNumber: invoiceNumber,
        methodOfPayment: methodOfPayment,
        notesOnCost: notesOnCost,
        refOfPayment: refOfPayment,
        typeOfPayment:typeOfPayment,
        caseInvoiceId:caseInvoiceId,
      });
      if(this.addApplication.get('vatStatus').value)
      {
        this.addApplication.patchValue({ vatStatus: 'true' })
      }
      else{
        this.addApplication.patchValue({ vatStatus: 'false' })
      }
      if(this.addApplication.get('inclusiveOfVat').value)
      {
        this.addApplication.patchValue({ inclusiveOfVat: 'true' })
      }
      else{
        this.addApplication.patchValue({ inclusiveOfVat: 'false' })
      }
    },error=>{
    });
    loaderActivation.loader=false;

      if(!this.blist){
        this.blist=(User.BranchId);
      }

  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }
  addApplicationProcess(){
    if(this.caseWorker.length==0){
      this.caseWorkerError= true;
      // this.tabGroup.selectedIndex =0;
      this.activeIndex =0;
    }
    else if( this.addApplication.valid){
      this.confirmsubmit = true;
      let bol = this.addApplication.get('vatStatus').value=="true";
      this.addApplication.patchValue({ vatStatus: bol })
      let bol1 = this.addApplication.get('inclusiveOfVat').value=="true";
      this.addApplication.patchValue({ inclusiveOfVat: bol1 })
      let data = this.addApplication.value;
      data = JSON.stringify(data);
      data = JSON.parse(data);
      data["isDeleted"] = false;
      data['addedByUserId']= User.Id;
      data['isDraft'] = true;
      let dp = [];
      this.profilePicture.forEach(item=>{
        dp.push({"filePath":item.filePath,'name':item.name,"type":item.type})
      });
      let imgs = [];
      this.files.forEach(item=>{
        imgs.push({"filePath":item.filePath,'name':item.name,"type":item.type})
      });
      data["files"]= this.files.length;
      data["dp"]= this.profilePicture.length;
      this.caseService.PostCase(data).subscribe(Response=>{
        let docData = {"Id":0,"caseId":data['Id'],"files":imgs}
         this.docService.PostDoc(docData).subscribe(resp=>{
          this.files=[];
        },error=>{
          // console.log(error);
        });
        let docData1 = {"Id":0,"caseId":data['Id'],"files":dp,"isProfilePicture":true}
        this.docService.PostDoc(docData1).subscribe(resp=>{
          this.profilePicture=[];
        },error=>{
          // console.log(error);
        });
        Swal.fire("Success","Case Updated successfully","success");
        this.confirmsubmit = false;
        this.retainerNavigate();
       },error=>{
        Swal.fire("Error",error.error.error,"error");
        this.confirmsubmit = false;
       });
    }
    else{
      this.caseWorkerError= false;

        for(let i in this.addApplication.controls){
          if(this.addApplication.controls[i].invalid){
            if(i=="Id" || i=="clientName" || i=="fileNumber" || i=="deadLine" || i=="matterId" || i=="caseTag"||
            i=="priorityId" || i=="personResposibleId" || i=="email" || i=="phoneNumber" ||
            i=="mobileNumber" || i=="address" || i=="dueDate" || i=="vatStatus" ||
            i=="inclusiveOfVat" || i=="detailsOfMatter" || i=="typeOfPayment" || i=="notesOnCost" ||
            i=="feePaid" || i=="methodOfPayment" ||i=="paymentAccount" ||i=="workSource" ||
            i=="refOfPayment"){
              // this.tabGroup.selectedIndex =0;
              this.activeIndex =0;
            }
            else{
              // this.tabGroup.selectedIndex =1;
              this.activeIndex =1;
            }
          }
          this.addApplication.controls[i].markAsTouched();
        }

    }

  }
  addDraftedApplicationProcess(){
    // if( !this.addApplication.valid){

    // }
    this.draftsubmit= true;
    let bol = this.addApplication.get('vatStatus').value=="true";
      this.addApplication.patchValue({ vatStatus: bol })
      let bol1 = this.addApplication.get('inclusiveOfVat').value=="true";
      this.addApplication.patchValue({ inclusiveOfVat: bol1 })
      let data = this.addApplication.value;
      data = JSON.stringify(data);
      data = JSON.parse(data);
      data["isDeleted"] = false;
      data['addedByUserId']= User.Id;
      data['isDraft'] = false;
      let dp = [];
      this.profilePicture.forEach(item=>{
        dp.push({"filePath":item.filePath,'name':item.name,"type":item.type})
      });
      let imgs = [];
      this.files.forEach(item=>{
        imgs.push({"filePath":item.filePath,'name':item.name,"type":item.type})
      });
      data["files"]= this.files.length;
      data["dp"]= this.profilePicture.length;
      this.caseService.PostCase(data).subscribe(Response=>{
         let docData = {"Id":0,"caseId":data['Id'],"files":imgs}
         this.docService.PostDoc(docData).subscribe(resp=>{
          this.files=[];
        },error=>{
          // console.log(error);
        });
        let docData1 = {"Id":0,"caseId":data['Id'],"files":dp,"isProfilePicture":true}
        this.docService.PostDoc(docData1).subscribe(resp=>{
          this.profilePicture=[];
        },error=>{
          // console.log(error);
        });


        Swal.fire("Success","Case Drafted successfully","success");
        this.retainerNavigate();
        this.draftsubmit= false;
       },error=>{
        Swal.fire("Error","Something is not right","error");
        this.draftsubmit= false;
       });
  }
  ngAfterViewInit(): void {

  }
  checkPermission=(name,key,BranchId)=>{
    var ex = this.permission.checkVisibilityByModuleName(name,key,BranchId)
    return ex
  }
  async ngOnInit() {
    loaderActivation.loader=true;

    if(!this.checkPermission("Retainer","Read",User.BranchId)){
      this.router.navigateByUrl("error401");
        }

  const row = this.activateRoute.snapshot.queryParams.row;
  if(row){
    this.caseId = JSON.parse(this.encryptionDecryptionService.decrypt(row));
   await this.getData();


  }
//   this.socketService.userSocket.onmessage=(msg)=>{
//     this.getData();
// }

// this.socketService.userSocket.onerror = (error) =>
//     console.log(`The server received: ${error['code']}`
//     );
//     this.socketService.userSocket.onclose = (why) =>
//     console.log(`The server received: ${why.code} ${why.reason}`
//     );

    // await this.getData();
    this.title.setTitle('Application Process');
    this.loader = false;
  }
  async getMatter() {
    let self = this;
    const promise=this.matterService.getAllData().toPromise();
    await promise.then(function(response){
      self.matterList = response['Matter'];
    });
  }
  async getPriorities(){
    let self = this;
    const promise=this.priorityService.getAllData().toPromise();
    await promise.then(function(response){
      self.Priorities = response['data'];
    });
  }
  async getUser(){
    let self = this;
    const promise=this.userService.getAllData().toPromise();
    await promise.then(function(response){
      self.Users = response['users'];
    });
  }

  DashboardNavigate()
  {
    this.router.navigateByUrl(AppSettings.DASHBOARD_URL);
  }
  retainerNavigate()
  {
    this.router.navigateByUrl(AppSettings.Retainer_URL);

  }
  AddNewPersonToGroup(){
    if(this.checkPermission('Retainer','Update',this.blist)){
      let self = this;
      let dataToSend = {
        allUser: this.Users,
        caseGroups: this.caseGroups,
        user:null,
        caseId: this.caseId
      }
      this._dialog.open(ApplicationProcessWorkerComponent,{
        disableClose:true,
        width:'800px',
        height:'750px',
        data: {
          value: dataToSend
        }
      }).afterClosed().subscribe(async (result) => {
        let promise =this.caseWorkerService.getAllData(this.caseId).toPromise();
      await promise.then(function(response){
        self.caseGroups = response['caseGroups'];
        self.caseWorker = response['caseWorker'];
        self.Users.forEach(element => {
          element['isAvaiblible'] = true;
        });
        self.caseWorker.forEach(element => {
          let index = self.Users.findIndex(x=>x.User.Id==element['userId_id']);
          self.Users[index]['isAvaiblible']= true;
        });
      });
        // this.getData();
    });
    }

  }
  OpenGroupDialogue(index){
    if(this.checkPermission('Retainer','Update',this.blist)){
      let self = this;
    let user = this.caseWorker[index];
    let dataToSend = {
      allUser: this.Users,
      caseGroups: this.caseGroups,
      user:user,
      caseId: this.caseId
    }
    this._dialog.open(ApplicationProcessWorkerComponent,{
      disableClose:true,
      width:'800px',
      height:'750px',
      data: {
        value: dataToSend
      }
    }).afterClosed().subscribe(async (result) => {
      let promise =this.caseWorkerService.getAllData(this.caseId).toPromise();
    await promise.then(function(response){
      self.caseGroups = response['caseGroups'];
      self.caseWorker = response['caseWorker'];
      self.Users.forEach(element => {
        element['isAvaiblible'] = true;
      });
      self.caseWorker.forEach(element => {
        let index = self.Users.findIndex(x=>x.User.Id==element['userId_id']);
        self.Users[index]['isAvaiblible']= true;
      });
    });
      // this.ngOnInit();
  });
    }

  }
  activeIndex:any=0
  getTagbyId(id){
    if(id){
    if(this.caseTags){
      let arr =  this.caseTags.filter(x=>x.Id==id);
    return arr;
    }else{
      let arr = [{Name:""}]
      return arr;
    }
  }
  else{
    let arr = [{Name:""}]
    return arr;
  }
  }
  getExistingClientById(Id){
    if(Id){
    if(this.workSourceDropDownData){
      let arr = this.workSourceDropDownData.filter(x=>x.value==Id)
      return arr
    }else{
      let arr = [{text:""}]
      return arr;
    }}else{
      let arr = [{text:""}]
      return arr;
    }
  }
  getPriorityById(Id){
    if(Id){
    if(this.Priorities){
      let arr = this.Priorities.filter(x=>x.Id==Id)
      return arr
    }else{
      let arr = [{Name:""}]
      return arr;
    }}else{
      let arr = [{Name:""}]
      return arr;
    }
  }
  getMatterName(Id){
    let arr = [{Name:""}]

    if(Id){
    if(this.matterList){
      let arr = this.matterList.filter(x=>x.Id==Id)
      return arr
    }else{
      let arr = [{Name:""}]
      return arr;
    }}else{
      let arr = [{Name:""}]
      return arr;
    }
    return arr;
  }
  getCreditName(Id){
    if(Id){
    if(this.Users){
      let arr = this.Users.filter(x=>x.User.Id==Id)
      console.log(arr)
      return arr
    }else{
      let arr = [{User:{Name:""}}]
      return arr;
    }}else{
      let arr = [{User:{Name:""}}]
      return arr;
    }
  }
  changeTab(){
    // this.tabGroup.selectedIndex +=1;
    this.activeIndex =1;
  }
  applicationProcessNavigate()
  {
    this.router.navigateByUrl(AppSettings.ApplicationProcess_URL);

  }
  deleteFromArray(index) {
    this.files.splice(index, 1);
    this.fileIndex--;
  }
  onFileChange(event) {
    let pFileList = event.target.files;
    let _files = Object.keys(pFileList).map(key => pFileList[key]);
    this._snackBar.open("Successfully upload!", "Close", {
      duration: 2000
    });

    if (event.target.files && event.target.files[0]) {
      _files.forEach(item => {
        var reader = new FileReader();

        reader.readAsDataURL(item); // read file as data url

        reader.onload = event => {
          // called once readAsDataURL is completed
          item.filePath = event.target.result;

        };
        item.Id=this.fileIndex;
        item.fileName = item.name,
        item.ext = item.name.split('.'),
        item.ext = item.ext[item.ext.length-1],
        this.files.push(item);
        this.fileIndex++;
      });
    }
  }
  deleteFromProfileArray(index) {
    this.profilePicture.splice(index, 1);
    this.profilePictureIndex--;
  }
  onProfilePictureChange(event) {
    let pFileList = event.target.files;
    let _files = Object.keys(pFileList).map(key => pFileList[key]);
    this._snackBar.open("Successfully upload!", "Close", {
      duration: 2000
    });

    if (event.target.files && event.target.files[0]) {
      _files.forEach(item => {
        var reader = new FileReader();

        reader.readAsDataURL(item); // read file as data url

        reader.onload = event => {
          // called once readAsDataURL is completed
          item.filePath = event.target.result;

        };
        item.Id=this.profilePictureIndex;
        this.profilePicture= [];
        item.fileName = item.name,
        item.ext = item.name.split('.'),
        item.ext = item.ext[item.ext.length-1],
        this.profilePicture.push(item);
        this.profilePictureIndex++;
        if(this.postProfilePicture.length>0){
          this.postProfilePicture.forEach(element => {
            this.profilePictureId = element.Id;
            this.deleteProfilePic();

          });
        }
      });
    }
  }
  openImage(files,i){
    this.items = files.map(item => {
      return new ImageItem({ src: item.filePath, thumb: item.filePath });
    });

  const lightboxRef = this.gallery.ref("lightbox");
  lightboxRef.setConfig({
    imageSize: ImageSize.Cover,
    thumbPosition: ThumbnailsPosition.Top,

    gestures: false
  });
  // Add custom gallery config to the lightbox (optional)

  // Load items into the lightbox gallery ref
  lightboxRef.load(this.items);

    this.lightbox.open(i,'lightbox');
  }
  deleteFile(){
    this.docService.PostDoc({"Id":this.fileId,'isDeleted':true}).subscribe(resp=>{
    this.fileId=-1;
    this.postFiles.splice(this.postFiles.findIndex(x=>x.Id==this.fileId),1);
    },error=>{
      // console.log("error",error)
    });
  }
  deleteProfilePic(){
    this.docService.PostDoc({"Id":this.profilePictureId,'isDeleted':true}).subscribe(resp=>{
    this.profilePictureId=-1;
    this.postProfilePicture.splice(this.postProfilePicture.findIndex(x=>x.Id==this.profilePictureId),1);
    },error=>{
      // console.log("error",error)
    });
  }
  async getAllWorkSources(){
    let self = this;
      const prom = this.workSourceService.getAllData().toPromise();
      await prom.then(function(response){
        self.worksource = response['WorkSource'];
      });
      this.workSourceDropDownData = [];
      this.worksource.forEach(item => {
        this.workSourceDropDownData.push({
            text: item.Name,
            url: '',
            icon: '',
            action: '',
            value: item.Id
        });
        this.workSourceDropDownData = [...this.workSourceDropDownData];
    });
    }
    removeCaseWorker(index){
      if(!this.submit){
        this.submit= true;
        let casewrkr = this.caseWorker[index];
        casewrkr['addedByUserId']= User.Id;
        casewrkr['caseId']= User.Id;
        let data ={
          Id: casewrkr['Id'],
          addedByUserId:  User.Id,
          canEdit:  false,
          caseId:  casewrkr['caseId_id'],
          dueDate:  null,
          editable:  false,
          groupsUpdate:  [],
          isDeleted:  true,
          revised:  false,
          selectedCheckList:  [],
          selectedUser:  casewrkr['userId_id'],
          status:  0,
        }
        this.caseWorkerService.PostCase(data).subscribe(Response=>{
          Swal.fire("Success", "CaseWorker Deleted successfully", "success");
          this.caseWorker.splice(index,1)
          // this.addWorker.reset();
          // this.close();
          this.submit = false;
        },error=>{
          this.submit = false;
          Swal.fire("Error", error.error.error, "error");
        })
      }



    }
    addNewWorkSource() {

      let bol = this.addApplication.get('workSourceId').value ? false: true;
      if(!this.addApplication.get('workSourceId').value)
      {
        console.log('Add new WorkSource Event');
        this._dialog.open(WorkSourceDialogComponent,{
          disableClose:true,
              width:'800px',
              height:'510px',
              data: {
                workSourceName: this.workSourceAutoCompleteCtrl.value
              }
            }).afterClosed().subscribe(async (result) => {
              await this.getAllWorkSources();
              this.addApplication.patchValue({workSourceId:result});
            //   // console.log("fetching Data")
            //  await  this.getData();
            //  if (   this.socketService.userSocket.readyState === WebSocket.CLOSED ||this.socketService.userSocket.readyState ===WebSocket.CLOSING ) {
            //   this.socketService.setupUserConnetion()
            // }
            //  this.socketService.sendUserData()
            // //  console.log("fetch Data")
            //       this.roleChange(result);
            //       // console.log("role Change")

          });
      }
    }
    autoFillValueChangedForWorkSource(data: any) {
      if(data){
        this.addApplication.patchValue({ workSourceId: data });
      }

      // this.roleChange(data);
    }


}

