import { User } from 'src/environments/User';
import { DocumentFileServiceProxy } from '../../../Services/document.service';
import { Title } from '@angular/platform-browser';
import { CryptoService } from '../../../Services/CryptoService.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Component, Inject, Injector, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA, } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar'
import { MatCheckboxChange } from '@angular/material/checkbox'
import { finalize } from 'rxjs/operators';
import * as _ from 'lodash';

import {
  Gallery,
  GalleryItem,
  ThumbnailsPosition,
  ImageSize,
  ImageItem
} from "ng-gallery";

import { Lightbox } from "ng-gallery/lightbox";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ticket } from 'src/app/shared/ticket';
import { ItFormServiceProxy } from 'src/app/Services/itformServiceProxy.service';
import Swal from 'sweetalert2';
import { commentService } from 'src/app/Services/commentService.service';
import { RoleServiceProxy } from 'src/app/Services/rolesServiceProxy.service';

declare const $: any;
@Component({
  templateUrl: './add-new-role-dialog.component.html',
})

export class AddNewRoleDialogComponent
  implements OnInit {
  public addRoleGroup: FormGroup;
  permissions: any;
  public allRoles: any = [];
  public RoleId: any;
  constructor(
    public dialog: MatDialog,
    private roleService: RoleServiceProxy,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<AddNewRoleDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { RoleName: string },
    private shareTicket: ticket,
    private itformservice: ItFormServiceProxy,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private CryptoService: CryptoService,
    private title: Title,
    private docService: DocumentFileServiceProxy,
    private commentService: commentService,
    public gallery: Gallery, public lightbox: Lightbox
  ) {

  }
  fileId: any = -1;
  id: any = 0;
  user: any;

  items: GalleryItem[];
  ngOnInit(): void {
    this.getData();
    this.user = User;
    this.addRoleGroup = this.formBuilder.group({
      Id: [0],
      Name: ["", [Validators.required]],
    });
    this.addRoleGroup.patchValue({ Name: this.data.RoleName })

  }
  VisibleClicked(e, p) {
    if (!e.target.checked) {
      p.Read = false;
      p.Update = false;
      p.Create = false;
      p.Delete = false;
      p.Visiblity = false
    } else {
      p.Visiblity = true
    }
  }
  selectAll(e,p){
    if(e.target.checked){
      p.Read= true;
      p.Update = true;
      p.Create = true;
      p.Delete = true;
      p.Visiblity = true;
    }else{
      p.Read= false;
      p.Update = false;
      p.Create = false;
      p.Delete = false;
      p.Visiblity = false;
    }
  }
  checkOtherPerm(p){
    if(p.Delete || p.Create || p.Update || p.Read){
      p.Visiblity=true
    return true;
    }else{
        p.Visiblity=false
      return false}
  }
  addRole = () => {

    if (this.addRoleGroup.valid) {
      let data = this.addRoleGroup.value;
      data = JSON.stringify(data);
      data = JSON.parse(data);
      data["isDeleted"] = false;
      data["rolePermission"] = this.permissions;
      this.roleService.PostRole(data).subscribe(Response => {
        this.RoleId = Response['role']['Id'];
        Swal.fire("Success", "Role created successfully", "success");
        this.addRoleGroup.reset();
        this.dialogRef.close(this.RoleId);
      }, error => {

        Swal.fire("Error", error.error.error, "error");
      })

    }
  }
  level(e,i,le){
    if(e.target.checked){
      le.level = i;
    }
  }
  getData() {
    let self = this;
    this.roleService.getAllData().subscribe(function (response) {
      // console.log(response, "Roles");
      self.allRoles = response['data'];
      self.allRoles = self.allRoles.filter(function (item) {
        var val = "super";
        var index = item.Name.toLowerCase().indexOf(val)
        if (index < 0) { return item; }


      });
      self.permissions = response['module'];
    }, error => {
      // console.log("error", error)
     });
  }



  save(): void {
  }

  close() {
    this.dialogRef.close(this.RoleId);
  }
}
