import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Title } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { KnowledgeBaseServiceProxy } from "src/app/Services/knowledgeBaseServiceProxy.service";
import { PermissionService } from "src/app/Services/PermissionServices.service";
import { AppSettings } from "src/app/shared/app.settings";
import { User } from "src/environments/User";
import Swal from "sweetalert2";
import { AddNewKnowledgeBaseDialougeComponent } from "./add-new-knowledge-base-dialouge/add-new-knowledge-base-dialouge.component";
import { MarkAsFlaggedDialougeComponent } from "./mark-as-flagged-dialouge/mark-as-flagged-dialouge.component";
import { Location } from '@angular/common';

@Component({
  selector:"knowledge-base",
  templateUrl:'./knowledge-base.componet.html',
})

export class KnowledgeBaseComponent implements OnInit{
  blist:any;
  allKnowledgeBase:any=[];
  allKnowledgeBaseSave:any=[];
  thisUser :any;
  showIcon = false;
  @ViewChild('searchField') inputName;
  constructor(
    private router: Router,
    private permission:PermissionService,
    private _dialog: MatDialog,
    private knowledgeBaseService: KnowledgeBaseServiceProxy,
    private location: Location,
    private title : Title,

  ){
    this.title.setTitle('Knowledge Base');
    this.thisUser = User;
  }
  backBtn(){
    this.location.back();
  }
 async getData(){
   let self = this;
   if(!this.blist){
    this.blist=(User.BranchId);
   }
    const promise=  this.knowledgeBaseService.getAllData().toPromise();
    await promise.then(function(response){
      let data = response['KnowledgBase'];
      if(User.RoleId==1){
      self.allKnowledgeBaseSave= data;
      }
      else{
        self.allKnowledgeBaseSave = data.filter(x=>!x.isFlaged);
      }
    });
    // this.allKnowledgeBaseSave = this.allKnowledgeBase;
  }
  ngOnInit(){
    this.getData();

  }
  checkPermission=(name,key,BranchId)=>{
    var ex = this.permission.checkVisibilityByModuleName(name,key,BranchId)
    return ex
  }
  DashboardNavigate()
  {
    this.router.navigateByUrl(AppSettings.DASHBOARD_URL);
  }
  create(){
    this._dialog.open(AddNewKnowledgeBaseDialougeComponent, {
      disableClose: true,
      width: '800px',
      height: '550px',
      data: {
        data: null
      }
    }).afterClosed().subscribe(async (result) => {
      this.getData();
    });
  }
  edit(row){
    this._dialog.open(AddNewKnowledgeBaseDialougeComponent, {
      disableClose: true,
      width: '800px',
      height: '550px',
      data: {
        data: row
      }
    }).afterClosed().subscribe(async (result) => {
      this.getData();
    });
  }
  markAsResolve(row){
    let self = this;
    row['markAsFlaged'] = false;
  row['addedByUserId'] =  User.Id;
  this.knowledgeBaseService.Post(row).subscribe(function(resp){
      self.getData();
      Swal.fire(
        'Success!',
        'Knowledge Base is Posted.',
        'success'
      );
  });
  }
  Flagged(e,row){
      let self = this;
      row['isFlaged'] = e.checked;
    row['addedByUserId'] =  User.Id;
    this.knowledgeBaseService.Post(row).subscribe(function(resp){
        self.getData();
        Swal.fire(
          'Success!',
          'Knowledge Base is Posted.',
          'success'
        );
    });

  }
  showAll(){
    this.allKnowledgeBase = this.allKnowledgeBaseSave;
  }
  markAsFlag(row){
    this._dialog.open(MarkAsFlaggedDialougeComponent, {
      disableClose: true,
      width: '800px',
      height: '550px',
      data: {
        data: row
      }
    }).afterClosed().subscribe(async (result) => {
      this.getData();
    });
  }
  deleteRow (row){
    row['isDeleted'] = true;
    row['addedByUserId'] =  User.Id;
    let self = this;
    Swal.fire({
      title: 'Are you sure to Delete this?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.isConfirmed) {
         this.knowledgeBaseService.Post(row).subscribe(function(resp){
          self.getData();
          self.inputName.nativeElement.value = '';
          self.allKnowledgeBase =[];
      Swal.fire(
        'Success!',
        'Knowledge Base is Deleted.',
        'success'
      );

    })
  }
});

  }
  search(e){
    if(!e || e.length === 0 ){
      this.allKnowledgeBase =[];
      this.showIcon = false;
    }else{
    this.allKnowledgeBase =  this.allKnowledgeBaseSave.filter(x=>{
     let check=false;
        if(x.question.toLowerCase().includes(e.toLowerCase()) || x.answer.toLowerCase().includes(e.toLowerCase())){
          this.showIcon = true;
          check = true;
          return x
        }
      // if(check){
      //   return x;
      // }
    })
  }
  }
  removeFromList(data){
    debugger
    let index = this.allKnowledgeBase.findIndex(x=>x.Id==data.Id);
    this.allKnowledgeBase.splice(index,1);
  }
  markAsFlagged(data){
      data = JSON.stringify(data);
      data = JSON.parse(data);
      data["isDeleted"] = false;
      data["markAsFlaged"] = false;
      data["isFlaged"] = false;
      data["addedByUserId"]= User.Id;
    let self = this;
      this.knowledgeBaseService.Post(data).subscribe(function(resp){

        Swal.fire(
          'Success!',
          'Knowledge Base is Mark as Flagged.',
          'success'
        );
        // self.close();
      })

  }
  flagged(){

  }
}
