import { SocketioService } from '../../Services/socketio.service';
import { AuthService } from '../../Services/auth.service';
import { PermissionService } from '../../Services/PermissionServices.service';
import { User } from '../../../environments/User';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AppSettings } from 'src/app/shared/app.settings';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatterCreateComponent } from './matter-create/matter-create.component';
import { MatterServiceProxy } from 'src/app/Services/matterServiceProxy.service';
import { MatTabGroup } from '@angular/material/tabs';
import { loaderActivation } from 'src/environments/loaderActivation';
import { Location } from '@angular/common';

declare const $: any;
@Component({
  templateUrl: './matter.component.html',

})
export class MatterComponent implements OnInit {
 dashboardRoute: any;
 public coloumn : any;
 allMatters:any = [];
 allArchiveMatters:any=[];
  moduleName: string;
  loader:any=false;
  blist:any;
  @ViewChild(MatTabGroup) tabGroup: MatTabGroup;
  constructor(
    private title : Title,
    private formBuilder: FormBuilder,
    private router: Router,
    private matterService: MatterServiceProxy,
    private permission:PermissionService,
    private auth:AuthService,
    private _dialog: MatDialog,
    private socketService:SocketioService,
    private location: Location,
  ) {

    this.moduleName = "Matter";
       }
       backBtn(){
        this.location.back();
      }
 async getData(){

  this.loader = true;
  loaderActivation.loader=true;
  this.allMatters = [];
  let self = this;
  const promise =   this.matterService.getAllData().toPromise();
await promise.then(function (response) {
    console.log(response);
    self.allMatters=response['Matter'].filter(x=>!x.isDrafted);
    self.allArchiveMatters = response['Matter'].filter(x=>x.isDrafted);
    // self.allMatters = response['Matter'];
  }, error => {
    // console.log("error", error)
   });
  if(!this.blist){
    this.blist=(User.BranchId);
   }
this.coloumn = [
  {'Name':'Id','property':'Id','type':'Id'},
  {'Name':'Name','property':'Name','type':'text'},
  {'Name':'Archived','property':'isDrafted','type':'checkbox-with-click'}
];
this.loader = false;
loaderActivation.loader=false;
  }
  createMatter(){
    this._dialog.open(MatterCreateComponent,{
      disableClose:true,
      width:'800px',
      minHeight: '300px',
      maxHeight: '800px',
      height : 'auto',
      data: {
        value: null
      }
    }).afterClosed().subscribe(async (result) => {
      await  this.getData();

  });
  }
  ngAfterViewInit(): void {

  }
  checkPermission=(name,key,BranchId)=>{
    var ex = this.permission.checkVisibilityByModuleName(name,key,BranchId)
    return ex
  }
  ngOnInit() {
    if(!this.checkPermission("Matter","Visiblity",User.BranchId)){
      this.router.navigateByUrl("error401");
  }
  this.socketService.userSocket.onmessage=(msg)=>{
    this.getData();
}

this.socketService.userSocket.onerror = (error) =>
    console.log(`The server received: ${error['code']}`
    );
    this.socketService.userSocket.onclose = (why) =>
    console.log(`The server received: ${why.code} ${why.reason}`
    );

    this.getData();
    this.title.setTitle('Matter');
  }

  // Search(){
  //   let dic ={

  //   }
  //   if(this.Name){
  //     dic['Name'] = this.Name;
  //   }
  //   if(this.Status){
  //     dic['isActive'] = (this.Status==="true");
  //   }
  //   if(this.Role){
  //     dic['RoleId'] = parseInt(this.Role);
  //   }
  //   this.rows.splice(0, this.rows.length);
  //   var self= this;
  //   let temp =[];
  //   this.srch.forEach(function (d) {
  //     // console.log(d);
  //     let c:number=0;
  //     let dateCheck=false;
  //     const keys = Object.keys(dic);

  //     Object.entries(dic).forEach(([key, value]) => {
  //     if(d.User[key]==value)
  //       {  c++;

  //       }
  //    });
  //    console.log(  c,keys.length,keys);
  //    if(c===keys.length){
  //     //  console.log(dic);
  //      temp.push(d.User);
  //    }
  //     // return d.ticketSubject.toLowerCase().indexOf(val) !== -1 || !val;
  //   });
  //  this.Users=[];
  //   this.Users=temp
  // }
  // ClearFilter(){

  //   this.getData();
  // }
  // searchName(val) {
  //   this.rows.splice(0, this.rows.length);
  //   let temp = []
  //   this.srch.forEach(function (d) {
  //     val = val.toLowerCase();
  //     if( d.User.Name.toLowerCase().indexOf(val) !== -1 || !val)
  //     {


  //         temp.push(d.User);
  //     }
  //   });
  //  this.Users=[];
  //   this.Users=temp;
  // }
  // searchStatus(val) {
  //   if(val!=""){
  //     var boolValue = (val =="true");
  //   this.rows.splice(0, this.rows.length);
  //   let temp =[];
  //   this.srch.forEach(function (d) {

  //     if(d.User.isActive== boolValue){
  //       temp.push(d.User);
  //     }
  //   });
  //  this.Users=[];
  //   this.Users=temp;
  //   }
  //   else{

  //     this.rows.splice(0, this.rows.length);
  //     let temp =[];
  //     this.srch.forEach(function (d) {
  //       temp.push(d.User);

  //     });
  //    this.Users=[];
  //     this.Users=temp
  //   }
  // }
  // searchPriority(val) {
  //   if(val!="")
  //   {
  //     var i = parseInt(val);
  //   this.rows.splice(0, this.rows.length);
  //   let temp =[];
  //   this.srch.forEach(function (d) {

  //     if(d.User.RoleId == i){
  //       temp.push(d.User);
  //     }
  //     // val = val.toLowerCase();
  //     // return d.priorityId.toLowerCase().indexOf(i) !== -1 || !i;
  //   });
  //  this.Users=[]; this.Users=temp;
  //   }
  //   else{
  //   this.rows.splice(0, this.rows.length);
  //   let temp =[];
  //   this.srch.forEach(function (d) {
  //       temp.push(d.User);
  //     // val = val.toLowerCase();
  //     // return d.priorityId.toLowerCase().indexOf(i) !== -1 || !i;
  //   });
  //  this.Users=[]; this.Users=temp;
  //   }

  // }
  DashboardNavigate()
  {
    this.router.navigateByUrl(AppSettings.DASHBOARD_URL);
  }
  async deleteSelected(checkL){
    await checkL.forEach(element => {
       let toSetValues = this.allMatters.find(x=>x.Id=== element);
       toSetValues['isDeleted']=false;
       toSetValues['isDrafted']=true;
       toSetValues["addedByUserId"] = User.Id;
       const promise = this.matterService.PostMatter(toSetValues).toPromise();
       promise.then(Response=>{

           this.getData();
          },error=>{
           Swal.fire("Error","Something is not right","error");
          })
     });
     this.getData();
     Swal.fire("Success","Matters deleted successfully","success");
   }
   edit(value) {
    if(this.checkPermission("Matter","Read",User.BranchId) ){


     let toSetValues= value;
    if(Number.isInteger(value)){
      toSetValues = this.allMatters.find(x=>x.Id=== value);
    }
      this._dialog.open(MatterCreateComponent,{
        disableClose:true,
        width:'800px',
        minHeight: '300px',
        maxHeight: '800px',
        height : 'auto',
        data: {
          value: toSetValues
        }
      }).afterClosed().subscribe(async (result) => {
        await  this.getData();

    });
  }
    }
    deleteMatter(toSetValues){
      Swal.fire({
        title: 'Are you sure?',
        text: "The Matter is added to the archived Tab and you and give it back at any time!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Proceed'
      }).then((result) => {
        if (result.isConfirmed) {
          toSetValues['isDeleted']=false;
      toSetValues['isDrafted']=true;
      toSetValues["addedByUserId"] = User.Id;
      toSetValues['groups']= toSetValues['matterGroupList'];
      this.matterService.PostMatter(toSetValues).subscribe(Response=>{
          Swal.fire("Success","Matter Archived successfully","success");
          this.getData();
          // this.rerender();
      //  $("#delete_branch").modal("hide");
         },error=>{
          // console.log("",error.toString(),"error");
          Swal.fire("Error","Something is not right","error");
         })

        }
      })

  }
  deleteArcheived(toSetValues){
    Swal.fire({
      title: 'Are you sure?',
      text: "The Matter is added to the archived Tab and you and give it back at any time!",
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Proceed'
    }).then((result) => {
      if (result.isConfirmed) {
        toSetValues['isDeleted']=false;
    toSetValues['isDrafted']=false;
    toSetValues["addedByUserId"] = User.Id;
    toSetValues['groups']= toSetValues['matterGroupList'];
    this.matterService.PostMatter(toSetValues).subscribe(Response=>{
        Swal.fire("Success","Matter Added successfully","success");
        this.getData();
        // this.rerender();
    //  $("#delete_branch").modal("hide");
       },error=>{
        // console.log("",error.toString(),"error");
        Swal.fire("Error","Something is not right","error");
       })

      }
    })
  }
  async deleteSelectedArcheived(checkL){
    await checkL.forEach(element => {
       let toSetValues = this.allArchiveMatters.find(x=>x.Id=== element);
       toSetValues['isDeleted']=false;
       toSetValues['isDrafted']=false;
       toSetValues["addedByUserId"] = User.Id;
       const promise = this.matterService.PostMatter(toSetValues).toPromise();
       promise.then(Response=>{

           this.getData();
          },error=>{
           Swal.fire("Error","Something is not right","error");
          })
     });
     this.getData();
     Swal.fire("Success","Matters deleted successfully","success");
   }
   changeTab(opt){
     if(opt){
    this.tabGroup.selectedIndex +=1;
     }else{
    this.tabGroup.selectedIndex -=1;
     }
  }
}
