import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { CaseInvoiceServiceProxy } from "src/app/Services/caseInvoiceServiceProxy.service";
import { CaseServiceProxy } from "src/app/Services/caseServiceProxy.service";
import { EncryptionDecryptionService } from "src/app/Services/encryption-decryption.service";
import { PermissionService } from "src/app/Services/PermissionServices.service";
import { AppSettings } from "src/app/shared/app.settings";
import { User } from "src/environments/User";
import { Location } from '@angular/common';



@Component({
  selector:"accounts",
  templateUrl:'./accounts.componet.html',
})

export class AccountComponent implements OnInit{
  allCases:any=[];
  allCaseInvoices:any=[];
  column: { Name: string; property: string; type: string; }[];
  invoicecolumn: { Name: string; property: string; type: string; }[];
  filterFields: string[];
  moduleName: string;
  blist:any;
  invoiceFilterFields: string[];
  constructor(
    private caseService: CaseServiceProxy,
    private router: Router,
    private encryptionDecryptionService: EncryptionDecryptionService,
    private permission:PermissionService,
    private caseInvoiceService: CaseInvoiceServiceProxy,
    private location: Location,
  ){
    this.moduleName = "Accounts";
  }
  backBtn(){
    this.location.back();
  }
  ngOnInit(){

    this.allCases = [];
    this.getAllCaseInvoices();

  }
  async getAllCaseInvoices(){
    this.allCaseInvoices = [];
    let self = this;
    const promise=  this.caseInvoiceService.getAllData().toPromise();
    await promise.then(function(response){
      self.allCaseInvoices = [];
      self.allCaseInvoices = response['data'];
      self.allCaseInvoices.sort(function(a, b){return b.Id-a.Id});
      self.allCaseInvoices= self.allCaseInvoices.filter(x=>x.isDraft && x.isDeleted==false);
    });
    if(!this.blist){
      this.blist=(User.BranchId);
     }
    this.invoicecolumn =  [
      {'Name':'Id','property':'Id','type':'Id'},
      {'Name':'Payment Date','property':'paymentDate','type':'date'},
      {'Name':'Client Name','property':'clientName','type':'text'},
      {'Name':'Amount Paid','property':'amountPaid','type':'text'},
    ];
    this.invoiceFilterFields = ['Id','paymentDate','clientName','amountPaid'];
  }
  async getCaseData(){
    this.allCases = [];
    let self = this;
    const promise=  this.caseService.getAllData().toPromise();
    await promise.then(function(response){
      self.allCases = [];

      self.allCases = response['result'];
      // self.AwaitingCompletioncaseList = data;
      self.allCases.sort(function(a, b){return b.Id-a.Id});
    });
    this.allCases.forEach(element => {
      let sum = 0;
      let drafted = element['caseInvoice'].filter(x=>x.isDraft && x.isDeleted==false);
      drafted.forEach(element1 => {
        sum =sum+ Number(element1['agreedCost']) ;
      });
      let draftedLength = drafted.length;
      element['draftedLength'] = draftedLength;
      element['totalAmmount'] = sum;
      if(element['caseStatus']==0){
        element['status']="Active";
      }
      else if(element['caseStatus']==1){
        element['status']="Awaiting Decission";
      }
      else if(element['caseStatus']==2){
        element['status']="Awaiting Completion";
      }
      else if(element['caseStatus']==3){
        element['status']="Archeived";
      }
    });
    if(!this.blist){
      this.blist=(User.BranchId);
     }
    this.column =  [
      {'Name':'Id','property':'Id','type':'Id'},
      {'Name':'File Number','property':'fileNumber','type':'text'},
      {'Name':'Client Name','property':'clientName','type':'text'},
      {'Name':'Matter Type','property':'matterName','type':'text'},
      {'Name':'No. Of Draft Payments','property':'draftedLength','type':'number'},
      {'Name':'Total Amount','property':'totalAmmount','type':'number'},
      {'Name':'Status','property':'status','type':'text'},
    ];
    this.filterFields = ['Id','fileNumber','clientName','matterName','totalAmmount','draftedLength','status'];
  }
  DashboardNavigate()
  {
    this.router.navigateByUrl(AppSettings.DASHBOARD_URL);
  }
  checkPermission=(name,key,BranchId)=>{
    var ex = this.permission.checkVisibilityByModuleName(name,key,BranchId)
    return ex
  }
  rowClick(e) {
    let toSetValues= e.Id;
    const encId = this.encryptionDecryptionService.encrypt(toSetValues.toString());
    const param = { queryParams: { row: encId } };
    this.router.navigate([AppSettings.Case_View_URL],param);
  }
  rowClickInvoice(e) {
    let toSetValues= e.caseId;
    const encId = this.encryptionDecryptionService.encrypt(toSetValues.toString());
    const param = { queryParams: { row: encId } };
    this.router.navigate([AppSettings.Case_View_URL],param);
  }
  viewSheet(){

  }
  async onTabChange(tab){
    if(tab.index==1){
      this.getCaseData();

    }

  }
}
