import { Component, Inject } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { CaseInvoiceServiceProxy } from 'src/app/Services/caseInvoiceServiceProxy.service';
import { ClientAccountServiceProxy } from "src/app/Services/clienAccountServiceProxy.service";
import Swal from "sweetalert2";
import { User } from 'src/environments/User';

import { DraftedClientEditComponent } from "../CaseWorkerDetailModal/drafted-client-edit/drafted-client-edit.component";
declare const $: any;

@Component({
    selector: 'drafted-client',
    templateUrl: 'drafted-client-account.component.html',
  })
  export class DraftedClientAccountComponent  {
    caseData:any;
    caseInvoices=[];
  submit: boolean;
    constructor(
        public dialogRef: MatDialogRef<DraftedClientAccountComponent>,
        private caseInvoiceService: CaseInvoiceServiceProxy,
        private clientAccountService: ClientAccountServiceProxy,

        @Inject(MAT_DIALOG_DATA) public data: {data},
        private _dialog: MatDialog,
        ) {
            this.caseData = data.data;
        }

        async getData(){
    this.caseInvoices= [];
          let self = this;
 const promise=  this.caseInvoiceService.getById(this.caseData['Id']).toPromise();
  await promise.then(function(response){
    self.caseInvoices = response['data'];
    // self.allCases = self.allCases.filter(x=>data.indexOf( x.Id)!=-1 && x.isDraft==true);
    // console.log(self.allCases);
  });
  this.caseInvoices= this.caseInvoices.filter(x=>x.isDraft);


           }

          async ngOnInit() {
            await this.getData();
           }

    close(){
      this.dialogRef.close();
    }
    openDropDown(id){

      if ($('#_dropdown'+id).find('.dropdown-menu').is(":hidden")){
        $('#toggle_drop'+id).dropdown('toggle');

      }
    }
    Edit(e){
      let self =this;
      let data = {
        'caseData': this.caseData,
        'Invoice':e
      }
      this._dialog.open(DraftedClientEditComponent,{
        disableClose:true,
            width:'600px',
            height:'610px',
            data: {
             data: data
            }
          }).afterClosed().subscribe(async (result) => {
            self.close();

        });
    }
    Delete(e){
      this.submit = true;
      let self = this;
    // let data = {
    //   "Id": 0,
    //   "entryDate": new Date(),
    //   "transactionDate": new Date(),
    //   "typeOfPayment": e.typeOfPayment,
    //   "bankAccount": this.caseData.paymentAccount,
    //   "paymentMethod": e.methodOfPayment,
    //   "receiptNumber": null,
    //   "receiptForm": this.caseData['clientName'],
    //   "reasonOfReceipt": e.notesOnCost,
    //   "amount": e.amountPaid,
    //   "memo": '',
    //   "caseId": this.caseData.Id,
    //   "isDraft": false,
    //   "isDeleted": true,
    //   "isCompleted": false,
    //   "CaseInoviceId": e.Id,
    // }
    e['addedByUserId'] = User.Id;
    e['isDeleted'] = true;
    e['isDraft'] = false;
    this.caseInvoiceService.PostCaseInvoice(e).subscribe(function (resp) {
      self.submit = false;
      // self.getData();
      Swal.fire(
        'Deleted!',
        'Payment is deleted Successfully.',
        'success'
      );
      self.close();
    }, error => {
      self.submit = false;
      Swal.fire(
        'Failure!',
        'Fail to Delete.',
        'error'
      )
      // console.log(error);
    })
    }
  }
