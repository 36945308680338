import { Component, Inject } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { CaseInvoiceServiceProxy } from 'src/app/Services/caseInvoiceServiceProxy.service';
import { ClientAccountServiceProxy } from "src/app/Services/clienAccountServiceProxy.service";
import Swal from "sweetalert2";
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
declare const $: any;

@Component({
  selector: 'drafted-client',
  templateUrl: 'drafted-client-edit.component.html',
})
export class DraftedClientEditComponent {
  caseData: any;
  caseInvoices: any;
  receiptForm: any;
  entryDate: any;
  transactionDate: any;
  reasonOfReceipt: any;
  amount: any;
  memo: any;
  title:string;
  public Editor = ClassicEditor;
  submit = false;
  paymentAccountls = ['Manchester', 'London', 'Birmingham', 'Chester'];
  typeOfPayment = ['Disbursement', 'Legal FEE'];
  paymentMethod = ['BACS', 'Cheque', 'Cash', 'Card'];
  constructor(
    public dialogRef: MatDialogRef<DraftedClientEditComponent>,
    private caseInvoiceService: CaseInvoiceServiceProxy,
    private clientAccountService: ClientAccountServiceProxy,
    @Inject(MAT_DIALOG_DATA) public data: { data },
    private _dialog: MatDialog,
  ) {
    this.caseData = data.data.caseData;
    this.caseInvoices = data.data.Invoice;
    if(this.caseInvoices.Id===0){
      this.title= "Add New Receipt";
      this.entryDate = new Date();
      this.receiptForm = this.caseData['clientName'];
    }
    else{
      this.title ="Drafted Payment - Client Account";
      this.transactionDate = this.caseInvoices.paymentDate;
      this.receiptForm = this.caseData['clientName'];
      this.entryDate = this.caseInvoices.creationDateTime;

    }
  }



  async ngOnInit() {

  }

  close() {
    this.dialogRef.close();
  }
  openDropDown(id) {

    if ($('#_dropdown' + id).find('.dropdown-menu').is(":hidden")) {
      $('#toggle_drop' + id).dropdown('toggle');

    }
  }
  bankAccountInvalid = false;
  paymentTypeInvalid = false;
  paymentMethodInvalid = false;
  receiptFromInvalid = false;
  transDateInvalid = false;
  reasonReceptInvalid = false;
  amountInvalid = false;
  memoInvalid = false;
  checkValidation() {
    let ret = true;
    if (this.caseData.paymentAccount == undefined) {
      this.bankAccountInvalid = true;
      ret = false;

    } else {
      this.bankAccountInvalid = false;

    }


    if (this.caseInvoices.typeOfPayment == undefined) {
      this.paymentTypeInvalid = true;
      ret = false;
    } else {
      this.paymentTypeInvalid = false;
    }


    if (this.caseInvoices.methodOfPayment == undefined) {
      this.paymentMethodInvalid = true;
      ret = false;
    } else {
      this.paymentMethodInvalid = false;
    }


    if (this.receiptForm == null) {
      this.receiptFromInvalid = true;
      ret = false;
    } else {
      this.receiptFromInvalid = false;
    }

    if (this.transactionDate == null) {
      this.transDateInvalid = true;
      ret = false;
    } else {
      this.transDateInvalid = false;
    }

    if (this.caseInvoices.notesOnCost == undefined) {
      this.reasonReceptInvalid = true;
      ret = false;
    } else {
      this.reasonReceptInvalid = false;
    }


    if (this.caseInvoices.amountPaid == undefined) {
      this.amountInvalid = true;
      ret = false;
    } else {
      this.amountInvalid = false;
    }




    if (this.memo == null) {
      this.memoInvalid = true;
      ret = false;
    } else {
      this.memoInvalid = false;
    }
    return ret;
  }
  Submit() {

    if(this.checkValidation()){
      this.submit = true;
      let self = this;
    let data = {
      "Id": 0,
      "entryDate": this.entryDate,
      "transactionDate": this.transactionDate,
      "typeOfPayment": this.caseInvoices.typeOfPayment,
      "bankAccount": this.caseData.paymentAccount,
      "paymentMethod": this.caseInvoices.methodOfPayment,
      "receiptNumber": null,
      "receiptForm": this.receiptForm,
      "reasonOfReceipt": this.caseInvoices.notesOnCost,
      "amount": parseFloat(this.caseInvoices.amountPaid).toFixed(2),
      "memo": this.memo,
      "caseId": this.caseData.Id,
      "isDraft": true,
      "isDeleted": false,
      "isCompleted": false,
      "CaseInoviceId": this.caseInvoices.Id,
    }
    this.clientAccountService.PostClientAccount(data).subscribe(function (resp) {
      self.submit = false;
      // self.getData();
      Swal.fire(
        'Update!',
        'Payment Is transfered to Client Account.',
        'success'
      );
      self.close();
    }, error => {
      self.submit = false;
      // console.log(error);
    })
    }

  }
}
