import { User } from './../../../../environments/User';
import { Component, Inject } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { CaseInvoiceServiceProxy } from 'src/app/Services/caseInvoiceServiceProxy.service';
import { ClientAccountServiceProxy } from "src/app/Services/clienAccountServiceProxy.service";
import Swal from "sweetalert2";
declare const $: any;

@Component({
    selector: 'add-new-payment',
    templateUrl: 'add-new-payment.component.html',
  })
  export class AddNewPaymentComponent  {
    caseData:any;
    clientAccount:any;
    receiptForm:any;
    entryDate:any;
    transactionDate:any;
    reasonOfReceipt: any;
    amount: any;
    memo: any;
    addnew:any;
    paymentAccountls = ['Manchester', 'London', 'Birmingham', 'Chester'];
    typeOfPayment=['Disbursement','Legal FEE'];
    paymentMethod = ['BACS', 'Cheque', 'Cash', 'Card'];
    constructor(
        public dialogRef: MatDialogRef<AddNewPaymentComponent>,
        private caseInvoiceService: CaseInvoiceServiceProxy,
        private clientAccountService: ClientAccountServiceProxy,
        @Inject(MAT_DIALOG_DATA) public data: {data},
        private _dialog: MatDialog,
        ) {
            this.caseData = data.data.caseData;
            this.clientAccount = data.data.clientAccount;
            this.addnew = data.data.addNew;
            this.transactionDate = new Date();
        }



          async ngOnInit() {

           }

    close(){
      this.dialogRef.close();
    }
    openDropDown(id){

      if ($('#_dropdown'+id).find('.dropdown-menu').is(":hidden")){
        $('#toggle_drop'+id).dropdown('toggle');

      }
    }
    bankAccountValid= false;
    paymentMethodValid= false;
    payToValid= false;
    addressValid= false;
    reasonValid= false;
    memoValid= false;
    checkValidation() {
      let ret = true;
      if (this.clientAccount.bankAccount == null || this.clientAccount.bankAccount == undefined) {
        this.bankAccountValid = true;
        ret = false;

      } else {
        this.bankAccountValid = false;

      }


      if (this.clientAccount.paymentMethod == null || this.clientAccount.paymentMethod == undefined) {
        this.paymentMethodValid = true;
        ret = false;

      } else {
        this.paymentMethodValid = false;

      }


      if (this.clientAccount.payTo == null || this.clientAccount.payTo == undefined) {
        this.payToValid = true;
        ret = false;

      } else {
        this.payToValid = false;

      }


      if (this.clientAccount.address == null || this.clientAccount.address == undefined) {
        this.addressValid = true;
        ret = false;

      } else {
        this.addressValid = false;

      }


      if (this.clientAccount.reasonOfPayment == null || this.clientAccount.reasonOfPayment == undefined) {
        this.reasonValid = true;
        ret = false;

      } else {
        this.reasonValid = false;

      }

      if (this.clientAccount.memo == null || this.clientAccount.memo == undefined) {
        this.memoValid = true;
        ret = false;

      } else {
        this.memoValid = false;

      }
      return ret;
    }
    Submit(){
      if(this.checkValidation()){
        let self = this;
        this.clientAccount['addedByUserId']= User.Id;
        if(this.addnew){
        this.clientAccount['isCompleted']= true;
        }
        this.clientAccount['addNew'] = this.addnew;
        this.clientAccount['newTransactionDate']  = this.transactionDate;
        this.clientAccount['newEntryDate']  = new Date();
        this.clientAccountService.PostClientAccount(this.clientAccount).subscribe(function(resp){
          // self.getData();
          Swal.fire(
            'Update!',
            'Payment Is Updated.',
            'success'
          );
          self.close();
        });
      }


    }
  }
