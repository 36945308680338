import { EditClientAccountComponent } from './../edit-client-account/edit-client-account.component';
import { OfficeInvoiceServiceProxy } from './../../../Services/officeInvoiceServiceProxy.service';
import { DraftedClientEditComponent } from './drafted-client-edit/drafted-client-edit.component';
import { ApplicationProcessWorkerComponent } from './../../application-process/application-process-worker/application-process-worker.component';
import { UserServiceProxy } from './../../../Services/userServiceProxy.service';
import { CaseWorkerServiceProxy } from './../../../Services/caseWorkerServiceProxy.service';
import { CaseGroupListServiceProxy } from 'src/app/Services/caseGroupListServiceProxy.service';
import { CaseServiceProxy } from 'src/app/Services/caseServiceProxy.service';
import { Component, Inject } from "@angular/core";
import { MatCheckbox } from "@angular/material/checkbox";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { User } from 'src/environments/User';
import Swal from 'sweetalert2';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SocketioService } from 'src/app/Services/socketio.service';
import { DocumentFileServiceProxy } from 'src/app/Services/document.service';
import { commentService } from 'src/app/Services/commentService.service';

import { Lightbox } from "ng-gallery/lightbox";
import {
  Gallery,
  GalleryItem,
  ThumbnailsPosition,
  ImageSize,
  ImageItem
} from "ng-gallery";
import { MatSnackBar } from '@angular/material/snack-bar';
import { AutoCompleteModel } from 'src/@shared-components/auto-complete/models/auto-model';
import { WorkSourceServiceProxy } from 'src/app/Services/workSourceServiceProxy';
import { WorkSourceDialogComponent } from '../../work-source/work-source.component';
import { DraftedClientAccountComponent } from '../drafted-client-account/drafted-client-account.component';
import { ClientAccountServiceProxy } from 'src/app/Services/clienAccountServiceProxy.service';
import { AddNewPaymentComponent } from '../add-new-payment/add-new-payment.component';
import { AddNewInvoiceComponent } from '../add-new-invoice/add-new-invoice.component';
import { MoveToOfficeDialogeComponent } from '../move-to-office-dialog/move-to-office-dialog.component';
import { AddNewReceiptOfficeAccountComponent } from '../add-new-receipt-office-account/add-new-receipt-office-account.component';
import { AddNewPaymentOfficeAccountComponent } from '../add-new-payment-office-account/add-new-payment-office-account.component';
import { EditOfficeAccountComponent } from '../edit-office-account/edit-office-account.component';
import { OfficeAccountServiceProxy } from 'src/app/Services/officeAccountServiceProxy';
@Component({
  selector: 'CaseWorkerDetailModal',
  templateUrl: 'CaseWorkerDetailModal.html',
})
export class CaseWorkerDetailModal {
  public caseData: any;
  public caseWorker: any;
  clientAccounts: any;
  invoices: any;
  public user: any;
  public disabled: any;
  worksource: any;
  workSourceDropDownData: Array<AutoCompleteModel>;
  workSourceAutoCompleteCtrl: FormControl;
  public paymentAccount: any;
  public methodOfPayment: any;
  CommentForm: FormGroup;
  caseInvoice: any;
  files: any = [];
  visibleBtn= false;
  visibleMove = false;
  paymentBtnDisable = true;
  saveRow:any;
  saveAccountRow:any;
  public personResponsibleId=0;
  paymentMethod = ['BACS', 'Cheque', 'Cash', 'Card'];
  paymentAccountls = ['Manchester', 'London', 'Birmingham', 'Chester'];
  typeOfPayment = ['Disbursement', 'Legal FEE'];
  paymentType: any;
  groupNames=[];
  filterGroupNames="";
  constructor(
    public dialogRef: MatDialogRef<CaseWorkerDetailModal>,
    @Inject(MAT_DIALOG_DATA) public data: { data },
    private _dialog: MatDialog,
    private caseService: CaseServiceProxy,
    private socketService: SocketioService,
    private userService: UserServiceProxy,
    private _snackBar: MatSnackBar,
    public gallery: Gallery, public lightbox: Lightbox,
    private formBuilder: FormBuilder,
    private commentService: commentService,
    private workSourceService: WorkSourceServiceProxy,
    private docService: DocumentFileServiceProxy,
    private caseWorkerService: CaseWorkerServiceProxy,
    private caseGroupListService: CaseGroupListServiceProxy,
    private clientAccountService: ClientAccountServiceProxy,
    private officeAccountService: OfficeAccountServiceProxy,
    private officeInvoiceService: OfficeInvoiceServiceProxy,
  ) {
    this.caseData = data.data;


    this.personResponsibleId = this.caseData['personResposibleId'];
      this.caseInvoice = this.caseData['caseInvoice'][0]
      this.paymentType = this.caseInvoice['typeOfPayment'];
      this.workSourceDropDownData = new Array<AutoCompleteModel>();
      this.workSourceAutoCompleteCtrl = new FormControl();
      // this.workSource = this.caseData['workSource'];
      this.paymentAccount = this.caseData['paymentAccount'];
      this.methodOfPayment = this.caseData['methodOfPayment']
      this.disabled = data['disabled'];

    console.log(this.caseData);
  }

  async getData() {




    const promise = this.caseService.getById(this.caseData.Id).toPromise();
    await promise.then(resp => {

      var data = resp['data'];
      this.caseWorker = data['caseWorkerList'];
      this.caseData = data;
      this.groupNames = [];
      this.workSourceAutoCompleteCtrl.setValue(this.caseData['workSource']);
      this.caseWorker.forEach(element => {
        element.caseWorkerGroupList.forEach(element1 => {
          this.groupNames.push(element1.groupName);
        });

      });
      this.groupNames = Array.from(new Set(this.groupNames));
      let self = this;
      this.filterGroupNames = "";
      if(this.groupNames.length>1){
        this.groupNames.forEach(element => {
          this.filterGroupNames = this.filterGroupNames + ' , ' + element

        });
      }
      else{
        if(this.groupNames.length>0){
          this.filterGroupNames = this.groupNames[0];
        }
      }




      console.log(this.caseWorker);


    }, error => {
    });


  }
  public allUser: any = [];
 onlyUnique(value, index, self)
 { return self.indexOf(value) === index;
}
  PersonResponsibleChange(e) {
    if (e != "null") {
      this.caseData['personResposibleId'] = e;
    } else {
      this.caseData['personResposibleId'] = this.personResponsibleId;
    }
  }
  check(workers, i) {
    if (i == 0) {
      return false;
    } else {
      if (workers[i - 1]['status'] == 2) {
        return false;
      } else {
        return true;
      }

    }
  }
  typeOfPaymentChange(e) {
    if (e != "null") {
      this.caseInvoice['typeOfPayment'] = e;
      this.caseData['caseInvoiceList'][0] = this.caseInvoice;
    } else {
      this.caseInvoice['typeOfPayment'] = this.paymentType;

      this.caseData['caseInvoiceList'][0] = this.caseInvoice;
    }
  }
  ngOnInit() {
    this.user = User;
    this.getData();
    this.socketService.setupSocketConnection(this.caseData.Id);

    this.socketService.socket.onmessage = (msg) => {
      let data = JSON.parse(msg['data'])['payload']['data']
      data = JSON.parse(data);

      this.getData();
      this.getAllClientAccount();
      this.getAllInvoices();
    }

    this.socketService.socket.onerror = (error) => {
      console.log(`The server received: ${error['code']}`
      );
      this.socketService.setupSocketConnection(this.caseData.Id);

    }
    this.socketService.socket.onclose = (why) => {
      console.log(`The server received: ${why.code} ${why.reason}`
      );
      this.socketService.setupSocketConnection(this.caseData.Id);
    }
    this.CommentForm = this.formBuilder.group({
      comment: ["", [Validators.required]],
      // roleId: ["", [Validators.required]],

    });
    const promise = this.userService.getAllData().toPromise();
    promise.then(resp => {
      this.allUser = resp['users']

    })



    this.getAllClientAccount();
    this.getAllInvoices();
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  deleteFromArray(index) {
    // console.log(this.files);
    this.files.splice(index, 1);
    this.fileIndex--;
  }
  fileIndex: any = 0

  approve(data) {
    var self = this;
    data['status'] = 2
    data['selectedUser'] = data['userId'];
    data['selectedCheckList'] = null;
    data['groupsUpdate'] = null;
    data['revised'] = false;
    this.caseWorkerService.PostCase(data).subscribe(function (resp) {
      self.getData();
      Swal.fire(
        'Update!',
        'Checklist has been updated.',
        'success'
      )
    })
  }
  PaymentAccountChange(e) {

    if (e != "null") {
      this.caseData.paymentAccount = e
    } else {
      this.caseData.paymentAccount = this.paymentAccount;
    }
  }
  WorkSourceChange(e) {
    if (e != "null") {
      this.caseData.workSource = e
    } else {
      // this.caseData.workSource = this.workSource;
    }
  }
  PaymentMethodChange(e) {
    if (e != "null") {
      this.caseData.methodOfPayment = e
    } else {
      this.caseData.methodOfPayment = this.methodOfPayment;
    }
  }
  async reject(data, revised) {
    var users = [];

    const promise = this.userService.getUserbyId(data['userId']).toPromise();
    await promise.then(resp => {
      var _data = { "User": resp['users'][0]['User'], 'isAvaiblible': false }
      users.push(_data)
    });
    let dataToSend = {
      allUser: users,
      caseGroups: this.caseData['matterGroups'],
      user: data,
      caseId: data['caseId'],
      revised: true,
      editable: revised
    }
    this._dialog.open(ApplicationProcessWorkerComponent, {
      width: '800px',
      height: '768px',
      disableClose: true,
      data: {
        value: dataToSend
      }
    }).afterClosed().subscribe(async (result) => {
      this.getData();
    });
  }
  addCaseWorker() {
    var Users = this.allUser;

    Users.forEach(element => {
      element['isAvaiblible'] = true;
    });
    this.caseWorker.forEach(element => {
      let index = Users.findIndex(x => x.User.Id == element['userId']);
      Users[index]['isAvaiblible'] = false;
    });

    let self = this;
    let dataToSend = {
      allUser: Users,
      caseGroups: this.caseData['matterGroups'],
      user: null,
      caseId: this.caseData['Id'],

    }
    this._dialog.open(ApplicationProcessWorkerComponent, {
      disableClose: true,
      width: '800px',
      height: '750px',
      data: {
        value: dataToSend
      }
    }).afterClosed().subscribe(async (result) => {
      //   let promise =this.caseWorkerService.getAllData(this.caseData['caseId']).toPromise();
      // await promise.then(function(response){
      //   // self.caseGroups = response['caseGroups'];
      //   self.caseWorker = response['caseWorker'];
      //   self.allUser.forEach(element => {
      //     element['isAvaiblible'] = true;
      //   });
      //   self.caseWorker.forEach(element => {
      //     let index = self.allUser.findIndex(x=>x.User.Id==element['userId']);
      //     self.allUser[index]['isAvaiblible']= false;
      //   });
      // });
      this.getData();
    });

  }
  CheckListChange(e, _list) {
    if (e.checked) {
      var self = this;
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes'
      }).then((result) => {
        if (result.isConfirmed) {
          _list.isCompleted = true;
          _list["addedByUserId"] = self.user.Id;
          self.caseGroupListService.PostCaseGroupList(_list).subscribe(resp => {
            console.log(resp);
          });

          Swal.fire(
            'Update!',
            'Checklist has been updated.',
            'success'
          )
          self.getData();
        } else {
          _list.isCompleted = false;
          e.checked = false;
        }
      })
    }
  }
  close() {
    this.dialogRef.close();
  }
  updateCase() {
    this.caseData['addedByUserId'] = this.user.Id;
    this.caseService.PostCase(this.caseData).subscribe(Response => {
      console.log(Response, "response");
      Swal.fire("Success", "Case Updated successfully", "success");
    }, error => {
      Swal.fire("Error", "Something is not right", "error");
    });
  }
  items: GalleryItem[];
  openImage(files, i) {
    this.items = files.map(item => {
      return new ImageItem({ src: item.filePath, thumb: item.filePath });
    });

    const lightboxRef = this.gallery.ref("lightbox");
    lightboxRef.setConfig({
      imageSize: ImageSize.Cover,
      thumbPosition: ThumbnailsPosition.Top,

      gestures: false
    });
    // Add custom gallery config to the lightbox (optional)

    // Load items into the lightbox gallery ref
    lightboxRef.load(this.items);

    this.lightbox.open(i, 'lightbox');
  }
  onFileChange(event) {
    let pFileList = event.target.files;
    let _files = Object.keys(pFileList).map(key => pFileList[key]);
    this._snackBar.open("Successfully upload!", "Close", {
      duration: 2000
    });

    if (event.target.files && event.target.files[0]) {
      _files.forEach(item => {
        var reader = new FileReader();

        reader.readAsDataURL(item); // read file as data url

        reader.onload = event => {
          // called once readAsDataURL is completed
          item.filePath = event.target.result;

        };
        item.Id = this.fileIndex;
        this.files.push(item);
        this.fileIndex++;
      });
    }
  }

  postComment() {
    let data = this.CommentForm.value;

    data["Id"] = 0;
    data["sendBy"] = User.Id;

    data["caseId"] = this.caseData.Id;
    data["isDeleted"] = false;
    let imgs = []
    this.files.forEach(item => {
      imgs.push({ "filePath": item.filePath, 'name': item.name, "type": item.type })
    })
    data["files"] = this.files.length;
    this.commentService.PostComment(data).subscribe(resp => {
      let docData = { "Id": 0, "commentId": resp['data'].Id, "files": imgs, "userId": null }
      this.docService.PostDoc(docData).subscribe(resp => {
        this.files = [];
      }, error => {
        // console.log(error);
      });

      if (this.socketService.socket.readyState === WebSocket.CLOSED || this.socketService.socket.readyState === WebSocket.CLOSING) {
        this.socketService.setupSocketConnection(this.caseData.Id);
      }
      // if(this.socketService.socket.readyState ===WebSocket.OPEN)
      // {this.socketService.sendData();
      // }

      this.CommentForm.reset();
    }, error => {
      // console.log(error);
    })
  }
  async getAllWorkSources() {
    let self = this;
    const prom = this.workSourceService.getAllData().toPromise();
    await prom.then(function (response) {
      self.worksource = response['WorkSource'];
    });
    this.workSourceDropDownData = [];
    this.worksource.forEach(item => {
      this.workSourceDropDownData.push({
        text: item.Name,
        url: '',
        icon: '',
        action: '',
        value: item.Id
      });
      this.workSourceDropDownData = [...this.workSourceDropDownData];
    });
  }
  addNewWorkSource() {

    let bol = this.caseData['workSourceId'] ? false : true;
    if (!this.caseData['workSourceId']) {
      console.log('Add new WorkSource Event');
      this._dialog.open(WorkSourceDialogComponent, {
        disableClose: true,
        width: '800px',
        height: '510px',
        data: {
          workSourceName: this.workSourceAutoCompleteCtrl.value
        }
      }).afterClosed().subscribe(async (result) => {
        await this.getAllWorkSources();
        this.caseData['workSourceId'] = result;
      });
    }
  }
  autoFillValueChangedForWorkSource(data: any) {
    this.caseData['workSourceId'] = data;

    // this.roleChange(data);
  }
  OpenDraft() {
    let self = this;
    this._dialog.open(DraftedClientAccountComponent, {
      disableClose: true,
      width: '600px',
      height: '410px',
      data: {
        data: this.caseData
      }
    }).afterClosed().subscribe(async (result) => {
      self.getData();
      self.getAllClientAccount();
    });
  }
  async getAllClientAccount() {
    this.visibleMove = false;
    this.visibleBtn= false;
    this.paymentBtnDisable= true;
    this.saveRow = [];
    this.clientAccounts = [];
    let self = this;
    const promise = this.clientAccountService.getById(this.caseData['Id']).toPromise();
    await promise.then(function (response) {
      self.clientAccounts = response['data'];

    });
  }
  async getAllInvoices(){
    this.invoices = [];
    let self = this;
    const promise = this.officeInvoiceService.getById(this.caseData['Id']).toPromise();
    await promise.then(function (response) {
      self.invoices = response['data'];
      self.invoices.forEach(element => {
        let totalAmount= Number(element['amount'])
        let balance = totalAmount;
        element['officeAccount'].forEach(element1 => {
          if(element1['isInPayment'] && !element1['isDeleted']){
            let amount = Number(element1['amount'])
             balance = balance- amount;
          }



        });
        element['balance']= balance;
      });

    });
  }
  AddNewReceipt(){
    let self =this;
    let caseInvoice= {
      'Id':0,
      'typeOfPayment':null,
      'methodOfPayment':null,
    }
      let data = {
        'caseData': this.caseData,
        'Invoice':caseInvoice
      }
    this._dialog.open(DraftedClientEditComponent, {
      disableClose: true,
      width: '600px',
      height: '610px',
      data: {
        data: data
      }
    }).afterClosed().subscribe(async (result) => {
      self.getData();
      self.getAllClientAccount();

    });
  }
  officeAccountRowSelect(e,index,outterIndex){
    if(e.target.checked){
      this.invoices.forEach(element => {
        element['officeAccount'].forEach(element1 => {
          if(element1!= this.invoices[outterIndex].officeAccount[index]){
            element1['select']= false;
          }

        });
      });

      if(this.invoices[outterIndex].officeAccount[index].typeOfPayment==='Disbursement'){
        this.saveAccountRow = this.invoices[outterIndex].officeAccount[index];
        this.paymentBtnDisable = false;
      }
      else{
        this.paymentBtnDisable = true;
      }

    }
    else{
      this.paymentBtnDisable= true;
        this.saveAccountRow = [];
    }
  }
  clientAccountRowSelect(e,index){
    if(e.target.checked){
      this.clientAccounts.forEach(element => {
        if(element!= this.clientAccounts[index]){
        element['select']= false;
        }
      });
      this.saveRow = this.clientAccounts[index];
      this.visibleMove = true;
      if(this.clientAccounts[index].typeOfPayment==='Disbursement'){
        this.visibleBtn= true;
      }
      else{
        this.visibleBtn= false;
      }

    }
    else{
      this.visibleBtn= false;
      this.visibleMove = false;
        this.saveRow = [];
    }

  }
  AddNewPayment(){
    let self = this;
    let data = {
      'caseData': this.caseData,
      'clientAccount':this.saveRow,
      'addNew': true,
    }
    this._dialog.open(AddNewPaymentComponent, {
      disableClose: true,
      width: '600px',
      height: '610px',
      data: {
        data: data
      }
    }).afterClosed().subscribe(async (result) => {
      self.getData();
      self.getAllClientAccount();

    });
  }
  Edit(row){
    let self = this;
    let data = {
      'caseData': this.caseData,
      'clientAccount':row,
      'addNew': false
    }
    this._dialog.open(EditClientAccountComponent, {
      disableClose: true,
      width: '600px',
      height: '610px',
      data: {
        data: data
      }
    }).afterClosed().subscribe(async (result) => {
      self.getData();
      self.getAllClientAccount();

    });
  }
  Delete(row){
    let self = this;
    row.isDeleted= true;
    this.clientAccountService.PostClientAccount(row).subscribe(function(resp){
      // self.getData();
      Swal.fire(
        'Update!',
        'Payment Is Updated.',
        'success'
      );
      self.getData();
      self.getAllClientAccount();
    })
  }
  AddNewInvoice(){
    let self = this;
    let data = {
      'caseData': this.caseData,
    }
    this._dialog.open(AddNewInvoiceComponent, {
      disableClose: true,
      width: '600px',
      height: '610px',
      data: {
        data: data
      }
    }).afterClosed().subscribe(async (result) => {
      self.getData();
      self.getAllClientAccount();
      self.getAllInvoices();

    });
  }
  MoveToOfficeAccount(){
    let self = this;
    let data = {
      'caseData': this.caseData,
      'officeInvoices': this.invoices,
      'clientAccount':this.saveRow,
    }
    this._dialog.open(MoveToOfficeDialogeComponent, {
      disableClose: true,
      width: '550px',
      height: '350px',
      data: {
        data: data
      }
    }).afterClosed().subscribe(async (result) => {
      self.getData();
      self.getAllClientAccount();
      self.getAllInvoices();

    });
  }
  AddNewReceiptOfficeAccount(){
    let self =this;
    let caseInvoice= {
      'Id':0,
      'typeOfPayment':null,
      'methodOfPayment':null,
    }
      let data = {
        'caseData': this.caseData,
        'Invoice':caseInvoice,
        'officeInvoices':this.invoices,
      }
    this._dialog.open(AddNewReceiptOfficeAccountComponent, {
      disableClose: true,
      width: '600px',
      height: '610px',
      data: {
        data: data
      }
    }).afterClosed().subscribe(async (result) => {
      self.getData();
      self.getAllClientAccount();
      self.getAllInvoices();

    });
  }
  AddNewPaymentInOfficeAccount(){
    let self = this;
    let data = {
      'caseData': this.caseData,
      'officeAccount':this.saveAccountRow,
      'addNew': true,
    }
    this._dialog.open(AddNewPaymentOfficeAccountComponent, {
      disableClose: true,
      width: '600px',
      height: '610px',
      data: {
        data: data
      }
    }).afterClosed().subscribe(async (result) => {
      self.getData();
      self.getAllClientAccount();
      self.getAllInvoices();

    });
  }
  EditOfficeAccountRow(row){
    let self = this;
    let data = {
      'caseData': this.caseData,
      'officeAccount':row,
      'addNew': false
    }
    this._dialog.open(EditOfficeAccountComponent, {
      disableClose: true,
      width: '600px',
      height: '610px',
      data: {
        data: data
      }
    }).afterClosed().subscribe(async (result) => {
      self.getData();
      self.getAllClientAccount();
      self.getAllInvoices();

    });
  }
  DeleteOfficeAccount(row){
    let self = this;
    row.isDeleted= true;
    this.officeAccountService.Post(row).subscribe(function(resp){
      // self.getData();
      Swal.fire(
        'Update!',
        'Payment Is Updated.',
        'success'
      );
      self.getData();
      self.getAllClientAccount();
      self.getAllInvoices();
    })
  }
}
