import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-error401',
  templateUrl: './error401.component.html',

})
export class Error401Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
