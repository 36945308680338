import { User } from 'src/environments/User';
import { Component, Inject } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ClientAccountServiceProxy } from "src/app/Services/clienAccountServiceProxy.service";
import Swal from "sweetalert2";
declare const $: any;

@Component({
    selector: 'edit-client-account',
    templateUrl: 'edit-client-account.component.html',
  })
  export class EditClientAccountComponent  {
    caseData:any;
    clientAccount:any;
    addNew:any;
    paymentAccountls = ['Manchester', 'London', 'Birmingham', 'Chester'];
    typeOfPayment=['Disbursement','Legal FEE'];
    paymentMethod = ['BACS', 'Cheque', 'Cash', 'Card'];
    constructor(
        public dialogRef: MatDialogRef<EditClientAccountComponent>,
        private clientAccountService: ClientAccountServiceProxy,
        @Inject(MAT_DIALOG_DATA) public data: {data},
        private _dialog: MatDialog,
        ) {
            this.caseData = data.data.caseData;
            this.clientAccount = data.data.clientAccount;
            this.addNew = data.data.addNew;
        }



          async ngOnInit() {

           }

    close(){
      this.dialogRef.close();
    }

    Submit(){
      if(this.clientAccount.amount){
        let self = this;
      this.clientAccount['addedByUserId'] = User.Id;
      this.clientAccount['addNew']= this.addNew;
      this.clientAccountService.PostClientAccount(this.clientAccount).subscribe(function(resp){
        Swal.fire(
          'Update!',
          'Client Account is Updated.',
          'success'
        );
        self.close();
      }, error => {
        Swal.fire("Error", "Something is not right", "error");
      });
      }
      else{
        Swal.fire("Error", "Please Fill All the fields", "error");
      }

    }
  }
