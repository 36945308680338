import { DatePipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router";
import { CryptoService } from "src/app/Services/CryptoService.service";
import { PermissionService } from "src/app/Services/PermissionServices.service";
import { ProfileServiceProxy } from "src/app/Services/profile.service";
import { AppSettings } from "src/app/shared/app.settings";
import { loaderActivation } from "src/environments/loaderActivation";
import { User } from "src/environments/User";
import { Location } from '@angular/common';


@Component({
  selector:"profile",
  templateUrl:'./profile.component.html',
})

export class ProfileComponent implements OnInit{
  user=User;
   caseTags:any;
   userData :any;
   cases:any;
   _user:any;
  ActiveFiles: any[];
  ActiveCases: number;
  AwatingDecisionFiles: any[];
  AwatingDecisionCases: number;
  AwatingCompletionFiles: any[];
  AwatingCompletionCases: number;
  pendingBalance: number;
  draftBalance: number;
  pendingBalanceFile: any[];
  leads:any;
  lostRevenue:any;
  generatedRevenue:any;
  expectedRevenue:any;
  activeLeads:any;
  lostLeads:any;
  winLeads:any;
  draftBalanceFiles: any[];
  winLeadsFiles: any[];
  lostLeadFiles: any[];
  activeLeadsFiles: any[];
  lostRevenueFiles: any[];
  generatedRevenueFiles: any[];
  expectedRevenueFiles: any[];
  showActiveFiles=false;
  showAwaitingDecision=false;
  showToBeClosed=false;
  showPendingBalance=false;
  showExpectedRevenue=false;
  showGeneratedRevenue=false;
  showLostRevenue=false;
  showNoOfLeads=false;
  showTotalLDeals=false;
  showTotalWDeals=false;
  showDraftBalance=false;

  constructor(private router: Router,
    public datepipe: DatePipe,
    private profileService: ProfileServiceProxy,
    private permissions : PermissionService,
    private encrypt: CryptoService,
    private route: ActivatedRoute,
    private titleservice: Title,
    private location: Location,
    ){
      this.titleservice.setTitle("Profile");
  }
  backBtn(){
    this.location.back();
  }
 async ngOnInit(){
  loaderActivation.loader = true;
   let userId;
    var id = this.route.snapshot.queryParamMap.get('userId');
    if(id){
      id = JSON.parse(this.encrypt.decryptUsingAES256(id));
      id = JSON.parse(id);
      userId = id
    }
    else{
      userId = this.user.Id;

    }
    this.user=User;

    const prom = this.profileService.getbyId(userId).toPromise();
    await prom.then(resp=>{
      this.userData = resp['data'];
      this.cases= resp['data']['cases'];
      this.leads = resp['data']['leads'];

     this._user = this.userData['user'];
     this._user['branchName']=resp['data']['branchName'];
     var pp = this.checkPermissionUser('Dashboard', 'level', this._user.BranchId,this._user);
     pp.forEach(el=>{
      if(el=='1'){
        this.showActiveFiles=true;
        this.showAwaitingDecision=true;
        this.showToBeClosed=true;
        this.showPendingBalance=true;
        this.showExpectedRevenue=true;
        this.showGeneratedRevenue=true;
        this.showLostRevenue=true;
        this.showNoOfLeads=true;
        this.showTotalLDeals=true;
        this.showTotalWDeals=true;
        this.showDraftBalance=true;
      }
      else if(el=='2'){
        this.showActiveFiles=true;
        this.showAwaitingDecision=true;
        this.showToBeClosed=true;
        this.showPendingBalance=true;
        this.showExpectedRevenue=true;
        this.showGeneratedRevenue=true;
        this.showLostRevenue=true;
        this.showNoOfLeads=true;
        this.showTotalLDeals=true;
        this.showTotalWDeals=true;
        this.showDraftBalance=true;
      }
      else if(el=='3'){
        this.showActiveFiles=true;
        this.showAwaitingDecision=true;
        this.showToBeClosed=true;
        this.showPendingBalance=true;
        this.showExpectedRevenue=false;
        this.showGeneratedRevenue=false;
        this.showLostRevenue=false;
        this.showNoOfLeads=false;
        this.showTotalLDeals=false;
        this.showTotalWDeals=false;
        this.showDraftBalance=true;
      }
      else if(el=='4'){
        this.showActiveFiles=false;
        this.showAwaitingDecision=false;
        this.showToBeClosed=false;
        this.showPendingBalance=false;
        this.showExpectedRevenue=false;
        this.showGeneratedRevenue=false;
        this.showLostRevenue=false;
        this.showNoOfLeads=false;
        this.showTotalLDeals=false;
        this.showTotalWDeals=false;
        this.showDraftBalance=true;
      }
      else if(el=='5'){
        this.showActiveFiles=true;
        this.showAwaitingDecision=true;
        this.showToBeClosed=true;
        this.showPendingBalance=false;
        this.showExpectedRevenue=false;
        this.showGeneratedRevenue=false;
        this.showLostRevenue=false;
        this.showNoOfLeads=false;
        this.showTotalLDeals=false;
        this.showTotalWDeals=false;
        this.showDraftBalance=false;
      }
      else if(el=='6'){
        this.showActiveFiles=true;
        this.showAwaitingDecision=true;
        this.showToBeClosed=true;
        this.showPendingBalance=false;
        this.showExpectedRevenue=false;
        this.showGeneratedRevenue=false;
        this.showLostRevenue=false;
        this.showNoOfLeads=false;
        this.showTotalLDeals=false;
        this.showTotalWDeals=false;
        this.showDraftBalance=false;
      }
      else if(el=='7'){
        this.showActiveFiles=false;
        this.showAwaitingDecision=false;
        this.showToBeClosed=false;
        this.showPendingBalance=false;
        this.showExpectedRevenue=false;
        this.showGeneratedRevenue=false;
        this.showLostRevenue=false;
        this.showNoOfLeads=false;
        this.showTotalLDeals=false;
        this.showTotalWDeals=false;
        this.showDraftBalance=false;
      }
      else if(el=='8'){
        this.showActiveFiles=false;
        this.showAwaitingDecision=false;
        this.showToBeClosed=false;
        this.showPendingBalance=false;
        this.showExpectedRevenue=true;
        this.showGeneratedRevenue=true;
        this.showLostRevenue=true;
        this.showNoOfLeads=true;
        this.showTotalLDeals=true;
        this.showTotalWDeals=true;
        this.showDraftBalance=false;
      }
      else if(el=='9'){
        this.showActiveFiles=false;
        this.showAwaitingDecision=false;
        this.showToBeClosed=false;
        this.showPendingBalance=false;
        this.showExpectedRevenue=false;
        this.showGeneratedRevenue=false;
        this.showLostRevenue=false;
        this.showNoOfLeads=false;
        this.showTotalLDeals=false;
        this.showTotalWDeals=false;
        this.showDraftBalance=false;
      }
     });

      var p =  this.checkPermissionUser('Case Tags','level',"All",this._user);
    var str =[] ;
     p.forEach(el=>{
      el.forEach(el1 => {
        str.push(el1['Name']);
      });
    })
    str = Array.from(new Set(str));
    this.caseTags = str;
      // console.log(resp);
    })
    // console.log(this.user);
   await this.calData();


  }
  async calData(){
    this.ActiveFiles =[];
    this.ActiveCases =0;
    this.AwatingDecisionFiles =[];
    this.AwatingDecisionCases =0;
    this.AwatingCompletionFiles =[];
    this.AwatingCompletionCases =0;
    this.pendingBalanceFile =[];
    this.pendingBalance =0;
    this.lostRevenue = 0;
    this.lostRevenueFiles=[];
    this.generatedRevenue =0;
    this.generatedRevenueFiles=[];
    this.expectedRevenue =0;
    this.expectedRevenueFiles=[];
    this.activeLeads =0;
    this.activeLeadsFiles=[];
    this.lostLeads =0;
    this.lostLeadFiles =[];
    this.winLeads =0;
    this.winLeadsFiles =[];
    this.draftBalance=0;
    this.draftBalanceFiles =[]
    this.cases.forEach(element => {

      if(element.isDraft==true) // check for not in retainer
      {
        let check = false; // check variable for checking existing of person
        if(element.personResposibleId== this._user.Id){ // checking if person  the Person Responsible
          check = true; // setting the existing true
        }
        else{
          element.caseWorkerList.forEach(el1 => { // checking if person  the Case Worker
            if(el1.userId_id==this._user.Id){
              check = true; // setting the existing true
            }
          });
        }
        if(check){ // If Person exist then we can check the status of the file and add accordingly.
          let invoiceCheck= false;
          let invoiceBal = 0;
      element['caseInvoice'].forEach(invoice => {
        if(invoice.isDraft && invoice.amountPaid){
          this.draftBalance += parseFloat(invoice.amountPaid);
          invoiceBal+=parseFloat(invoice.amountPaid);
          invoiceCheck= true;
        }
      });
      if(invoiceCheck){
        if(invoiceBal>0){
          element['invoiceBal']=invoiceBal;
          this.draftBalanceFiles.push(element);

        }
      }
          let caseInvoice = element.caseInvoice;
          let agreedCost = Number(caseInvoice[0].agreedCost)
          let clientAccount = element.clientAccount;
          let totalAmount = 0;
          clientAccount.forEach(el2 => {
              if(!el2.isDraft){
                let amount = Number(el2.amount)
                totalAmount+=amount;
              }
          });
          let balance = agreedCost - totalAmount;
          element['outBalance']=balance;
          if(balance !=0){
            this.pendingBalanceFile.push(element);
          }

          this.pendingBalance += balance;
          if(element.caseStatus==0){ // check for active files
          this.ActiveCases+=1; // increment the count
          this.ActiveFiles.push(element); // adding in the array of activeFiles for navigations

          }
          else if(element.caseStatus==1){ // check for Awaiting Decision files
            this.AwatingDecisionCases+=1; // increment the count
            this.AwatingDecisionFiles.push(element); // adding in the array of activeFiles for navigations

          }
          else if(element.caseStatus==2){ // check for Awaiting Completion files
            this.AwatingCompletionCases+=1; // increment the count
            this.AwatingCompletionFiles.push(element); // adding in the array of activeFiles for navigations

          }
        }

      }

    });
    this.leads.forEach(element => {
      if(!element.isDrafted && element.dealOwner && element.dealOwner== this._user.Id){

        if(element.status && element.status.toLowerCase()=='lost'){
          if(element.dealAmount){
            this.lostRevenue +=Number(element.dealAmount);
            this.lostRevenueFiles.push(element)
          }

          this.lostLeads +=1;
          this.lostLeadFiles.push(element);
        }
        else if(element.status && element.status.toLowerCase()=='win'){
          if(element.dealAmount){
            this.generatedRevenue+= Number(element.dealAmount);
            this.generatedRevenueFiles.push(element)
          }

          this.winLeads +=1;
          this.winLeadsFiles.push(element);

        }
        else{
          if(element.dealAmount){
            this.expectedRevenue+= Number(element.dealAmount);
            this.expectedRevenueFiles.push(element)
          }
          this.activeLeads +=1;
          this.activeLeadsFiles.push(element);
        }

      }

    });
    loaderActivation.loader = false;
  }
  navigateToDraftBalance(){
    loaderActivation.loader = true;

    let navigationExtras: NavigationExtras = {
      queryParams: {
        "DraftBalanceFiles": this.encrypt.encryptUsingAES256(JSON.stringify(this.draftBalanceFiles))
      }
    };
    this.router.navigate(['/dashboard-view'], navigationExtras);
    // const url = this.router.serializeUrl(this.router.createUrlTree(['/dashboard-view'],navigationExtras));
    // window.open(url);
  }
  navigateToLeadFiles(obj){
    loaderActivation.loader = true;

    let navigationExtras: NavigationExtras = {
      queryParams: {
        "LeadFiles": this.encrypt.encryptUsingAES256(JSON.stringify(obj))
      }
    };
    this.router.navigate(['/lead-file-view'], navigationExtras);
    // const url = this.router.serializeUrl(this.router.createUrlTree(['/lead-file-view'],navigationExtras));
    // window.open(url);
  }
  navigateToLeadFilesRevenue(obj){
    loaderActivation.loader = true;

    let navigationExtras: NavigationExtras = {
      queryParams: {
        "LeadFilesRevenue": this.encrypt.encryptUsingAES256(JSON.stringify(obj))
      }
    };
    this.router.navigate(['/lead-file-view'], navigationExtras);
    // const url = this.router.serializeUrl(this.router.createUrlTree(['/lead-file-view'],navigationExtras));
    // window.open(url);
  }
  navigateToActiveFiles() {
    loaderActivation.loader = true;

    let navigationExtras: NavigationExtras = {
      queryParams: {
        "ActiveFiles": this.encrypt.encryptUsingAES256(JSON.stringify(this.ActiveFiles))
      }
    };
    this.router.navigate(['/profile-view'], navigationExtras);
    // const url = this.router.serializeUrl(this.router.createUrlTree(['/profile-view'],navigationExtras));
    // window.open(url);
  }
  navigateToPendingBalance(){
    loaderActivation.loader = true;

    let navigationExtras: NavigationExtras = {
      queryParams: {
        "PendingBalance": this.encrypt.encryptUsingAES256(JSON.stringify(this.pendingBalanceFile))
      }
    };
    this.router.navigate(['/profile-view'], navigationExtras);
    // const url = this.router.serializeUrl(this.router.createUrlTree(['/profile-view'],navigationExtras));
    // window.open(url);
  }
  navigateToAwaitingFiles() {
    loaderActivation.loader = true;

    let navigationExtras: NavigationExtras = {
      queryParams: {
        "AwaitingFiles": this.encrypt.encryptUsingAES256(JSON.stringify(this.AwatingDecisionFiles))
      }
    };
    this.router.navigate(['/profile-view'], navigationExtras);
    // const url = this.router.serializeUrl(this.router.createUrlTree(['/profile-view'],navigationExtras));
    // window.open(url);
  }
  navigateToAwaitingCompleteion() {
    loaderActivation.loader = true;

    let navigationExtras: NavigationExtras = {
      queryParams: {
        "AwaitingCompletion": this.encrypt.encryptUsingAES256(JSON.stringify(this.AwatingCompletionFiles))
      }
    };
    this.router.navigate(['/profile-view'], navigationExtras);
    // const url = this.router.serializeUrl(this.router.createUrlTree(['/profile-view'],navigationExtras));
    // window.open(url);
  }
  DashboardNavigate()
  {
    this.router.navigateByUrl(AppSettings.DASHBOARD_URL);

  }
  checkPermission =(moduleName,key,branchId)=>{
    var p= this.permissions.checkDashBoardPermission(moduleName,key,branchId);
    return p
   }
 checkPermissionUser =(moduleName,key,branchId,_user)=>{
    var p= this.permissions.checkDashBoadPermissionForSpecificUser(moduleName,key,branchId,_user);
    return p
   }


}
