import { User } from '../../../../environments/User';
import { Component, Inject } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { CaseInvoiceServiceProxy } from 'src/app/Services/caseInvoiceServiceProxy.service';
import { ClientAccountServiceProxy } from "src/app/Services/clienAccountServiceProxy.service";
import Swal from "sweetalert2";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { OfficeInvoiceServiceProxy } from 'src/app/Services/officeInvoiceServiceProxy.service';
declare const $: any;

@Component({
    selector: 'update-new-invoice',
    templateUrl: 'update-office-invoice.component.html',
  })
  export class UpdateOfficeInvoiceComponent  {
    caseData:any;
    amountUpdate=false;
    paymentAccountls = ['Manchester', 'London', 'Birmingham', 'Chester'];
    typeOfPayment=['Disbursement','Legal FEE'];
    paymentMethod = ['BACS', 'Cheque', 'Cash', 'Card'];
    public addForm: FormGroup;
  officeInvoice: any;
    constructor(
        public dialogRef: MatDialogRef<UpdateOfficeInvoiceComponent>,
        private officeInvoiceService: OfficeInvoiceServiceProxy,
        private formBuilder: FormBuilder,
        @Inject(MAT_DIALOG_DATA) public data: {data},
        private _dialog: MatDialog,
        ) {
            this.caseData = data.data.caseData;
            this.officeInvoice = data.data.officeInvoice;
        }



           ngOnInit() {
            if(this.officeInvoice.officeAccount.length>0){
              this.amountUpdate=true;
            }else{
              this.amountUpdate= false;
            }

            this.addForm = this.formBuilder.group({
              Id: this.officeInvoice.Id,
              amount: [this.officeInvoice.amount, [Validators.required]],
              bankAccount: [this.officeInvoice.bankAccount, [Validators.required]],
              invoiceTo: [this.officeInvoice.invoiceTo, [Validators.required]],
              address: [this.officeInvoice.address, [Validators.required]],
              typeOfPayment: [this.officeInvoice.typeOfPayment, [Validators.required]],
              invoiceDate: [this.officeInvoice.invoiceDate, [Validators.required]],
              description: [this.officeInvoice.description, [Validators.required]],
              memo: [this.officeInvoice.memo, [Validators.required]],
              invoiceNumber: [this.officeInvoice.invoiceNumber, [Validators.required]],
              caseId: [this.officeInvoice.caseId_id],
            });
           }

    close(){
      this.dialogRef.close();
    }
    openDropDown(id){

      if ($('#_dropdown'+id).find('.dropdown-menu').is(":hidden")){
        $('#toggle_drop'+id).dropdown('toggle');

      }
    }
    Submit(){
      let self = this;
      if(this.addForm.valid){
        let data = this.addForm.value;
        data = JSON.stringify(data);
        data = JSON.parse(data);
        data["isDeleted"] = false;
        data['addedByUserId']= User.Id;
        data['caseId']= this.caseData['Id'];
        this.officeInvoiceService.Post(data).subscribe(function(resp){
          // self.getData();
          Swal.fire(
            'Success!',
            'Invoice is Updated.',
            'success'
          );
          self.close();
        })

      }
      else{
        for(let i in this.addForm.controls){
          this.addForm.controls[i].markAsTouched();
        }
      }
      // let self = this;
      // this.clientAccount['addedByUserId']= User.Id;
      // if(this.addnew){
      // this.clientAccount['isCompleted']= true;
      // }
      // this.clientAccount['addNew'] = this.addnew;
      // this.clientAccountService.PostClientAccount(this.clientAccount).subscribe(function(resp){
      //   // self.getData();
      //   Swal.fire(
      //     'Update!',
      //     'Payment Is Updated.',
      //     'success'
      //   );
      //   self.close();
      // })
    }
  }
