import { DashboardViewComponent } from './all-modules/dashboard/admin-dashboard/Dashboard-view/dashboard-view/dashboard-view.component';
import { ApplicationProcessStartedComponent } from './all-modules/application-process-started/application-process-started.component';

import { Error404Component } from './errorpages/error404/error404.component';
import { RoleComponent } from './all-modules/roles/roles.component';
import { Error401Component } from './errorpages/error401/error401.component';
import { branchesComponent } from './all-modules/branches/branches..component';

import { AdminDashboardComponent } from './all-modules/dashboard/admin-dashboard/admin-dashboard.component';


import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from '../app/login/login/login.component';
import { ForgotComponent } from '../app/login/forgot/forgot.component';
import { RegisterComponent } from '../app/login/register/register.component';
import { AuthGuard } from './Services/auth.service';
import { ITFormComponent } from './Support/it-form/it-form.component';
import { AppComponent } from './app.component';
import { usercomponent } from './Users/user.component';
import { ITFormViewDialogComponent } from './Support/it-form/it-form-view/it-form-view.component';
import { ToDoComponent } from './all-modules/to-do/to-do.component';
import { ApplicationProcessComponent} from './all-modules/application-process/application-process.component';
import { MatterComponent } from './all-modules/matter/matter.component';
import { ApplicationProcessDetailComponent } from './all-modules/application-process/application-process-detail/application-process-detail.component';
import { ItTicketComponent } from './all-modules/to-do/it-ticket/it-ticket.component';
import { CaseToDoComponent } from './all-modules/to-do/cases/cases.component';
import { ResponsibilitiesToDoComponent } from './all-modules/to-do/responsibilities/responsibilities.component';
import { CaseViewComponent } from './all-modules/to-do/cases/case-view/case-view.component';
import { AwaitingDecissionCasesComponent } from './all-modules/to-do/awaiting-decission-cases/awaiting-decission-cases.component';
import { AwaitingCompletionCasesComponent } from './all-modules/to-do/awaiting-completion-cases/awaiting-completion-cases.component';
import { ArchivedCasesComponent } from './all-modules/to-do/archived-cases/archived-cases.component';
import { NotificationComponent } from './all-modules/notifications/notifications.componet';
import { PulseComponent } from './all-modules/pulse/pulse.componet';
import { GoalsComponent } from './all-modules/goals/goals.componet';
import { LeadComponent } from './all-modules/marketing/leads/leads.componet';
import { MarketingLeadFormComponent } from './all-modules/marketing/marketing-lead-form/marketing-lead-form.componet';
import { DraftedLeadFormComponent } from './all-modules/marketing/drafted-lead-form/drafted-lead-form.componet';
import { AccountComponent } from './all-modules/accounts/accounts.componet';
import { ReceptionLeadFormComponent } from './all-modules/reception-lead-form/reception-lead-form.componet';
import { DocumentComponent } from './all-modules/documents/documents.componet';
import { SupportComponent } from './all-modules/support/support.componet';
import { ChatRoomComponent } from './all-modules/chat-room/chat-room.componet';
import { CalenderComponent } from './all-modules/calender/calender.componet';
import { SettingsComponent } from './all-modules/settings/settings.componet';
import { KnowledgeBaseComponent } from './all-modules/knowledge-base/knowledge-base.componet';
import { DetailLeadFormComponent } from './all-modules/marketing/detail-lead-form/detail-lead-form.component';
import { AllCasesComponent } from './all-modules/to-do/all-cases/all-cases.component';
import { DocumentGeneratorComponent } from './all-modules/document-generator/document-generator.componet';
import { ApplicationTagsComponent } from './all-modules/application-tags/application-tags.component';
import { ReportComponent } from './all-modules/reports/reports.component';
import { ProfileComponent } from './all-modules/profile/profile.component';
import { LeadFileViewComponent } from './all-modules/dashboard/admin-dashboard/lead-file-view/lead-file-view.component';
const routes: Routes = [
  { path: '',component:AppComponent,canActivate:[AuthGuard]},
  { path: 'login',component:LoginComponent},
  {path: 'register',component:RegisterComponent },
  // { path: 'error', loadChildren: () => import(`./errorpages/errorpages.module`).then(m => m.ErrorpagesModule) },
  // { path: '', loadChildren: () => import(`./all-modules/all-modules.module`).then(m => m.AllModulesModule) },

  {path:'dashboard',component:AdminDashboardComponent,canActivate:[AuthGuard]},


  {path:'users',component:usercomponent,canActivate:[AuthGuard]},
  {path:'to-do',component:ToDoComponent,canActivate:[AuthGuard]},
  {path:'retainer',component:ApplicationProcessComponent,canActivate:[AuthGuard]},
  {path:'application-process-detail',component:ApplicationProcessDetailComponent,canActivate:[AuthGuard]},
  {path:'application-process',component:ApplicationProcessStartedComponent,canActivate:[AuthGuard]},
  {path:'matter-checklists',component:MatterComponent,canActivate:[AuthGuard]},
  {path:'notifications',component:NotificationComponent,canActivate:[AuthGuard]},
  {path:'pulse',component:PulseComponent,canActivate:[AuthGuard]},
  {path:'goals',component:GoalsComponent,canActivate:[AuthGuard]},


  {path:'lead',component:LeadComponent,canActivate:[AuthGuard]},
  {path:'marketing-lead-form',component:MarketingLeadFormComponent,canActivate:[AuthGuard]},
  {path:'drafted-lead-form',component:DraftedLeadFormComponent,canActivate:[AuthGuard]},
  {path:'accounts',component:AccountComponent,canActivate:[AuthGuard]},
  {path:'reception-lead-form',component:ReceptionLeadFormComponent,canActivate:[AuthGuard]},
  {path:'detail-lead-form/:param',component:DetailLeadFormComponent,canActivate:[AuthGuard]},


  {path:'documents',component:DocumentComponent,canActivate:[AuthGuard]},
  {path:'support',component:SupportComponent,canActivate:[AuthGuard]},
  {path:'chat-room',component:ChatRoomComponent,canActivate:[AuthGuard]},
  {path:'calender',component:CalenderComponent,canActivate:[AuthGuard]},
  {path:'profile',component:ProfileComponent,canActivate:[AuthGuard]},

  {path:'settings',component:SettingsComponent,canActivate:[AuthGuard]},
  {path:'knowledge-base',component:KnowledgeBaseComponent,canActivate:[AuthGuard]},
  {path:'document-generator',component:DocumentGeneratorComponent,canActivate:[AuthGuard]},
  {path:'to-do/it-ticket',component:ItTicketComponent,canActivate:[AuthGuard]},
  {path:'to-do/cases',component:CaseToDoComponent,canActivate:[AuthGuard]},
  {path:'to-do/all-cases',component:AllCasesComponent,canActivate:[AuthGuard]},
  {path:'to-do/awaiting-decision',component:AwaitingDecissionCasesComponent,canActivate:[AuthGuard]},
  {path:'to-do/awaiting-completion',component:AwaitingCompletionCasesComponent,canActivate:[AuthGuard]},
  {path:'to-do/archeivd-cases',component:ArchivedCasesComponent,canActivate:[AuthGuard]},
  {path:'to-do/cases/case-view',component:CaseViewComponent,canActivate:[AuthGuard]},
  {path:'to-do/responsibilities',component:ResponsibilitiesToDoComponent,canActivate:[AuthGuard]},
  {path:'branches',component:branchesComponent,canActivate:[AuthGuard]},
  {path:'application-tags',component:ApplicationTagsComponent,canActivate:[AuthGuard]},
  {path:'support/it-form/view/:id',component:ITFormViewDialogComponent,canActivate:[AuthGuard]},
  {path:'roles',component:RoleComponent,canActivate:[AuthGuard]},
  {path:'support/it-form',component:ITFormComponent,canActivate:[AuthGuard] },
  {path:'dashboard-view',component:DashboardViewComponent,canActivate:[AuthGuard] },
  {path:'lead-file-view',component:LeadFileViewComponent,canActivate:[AuthGuard] },
  {path:'profile-view',component:DashboardViewComponent,canActivate:[AuthGuard] },
  {path:'reports',component:ReportComponent,canActivate:[AuthGuard] },
  {path:'error404',component:Error404Component},
  {path:'error401',component:Error401Component}
  // { path: '**',component:LoginComponent,canActivate: [AuthGuard]},

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
export const RoutingComponents = [RoleComponent,branchesComponent,Error404Component,
  ITFormComponent,LoginComponent,RegisterComponent,ForgotComponent,CaseViewComponent,
  AdminDashboardComponent,usercomponent,Error401Component,ITFormViewDialogComponent,
  ToDoComponent,ApplicationProcessComponent,AwaitingDecissionCasesComponent,
  AwaitingCompletionCasesComponent,ArchivedCasesComponent,NotificationComponent,
  ApplicationProcessDetailComponent,CaseToDoComponent,ResponsibilitiesToDoComponent,
  MatterComponent,ApplicationProcessStartedComponent,ItTicketComponent,ReceptionLeadFormComponent,
PulseComponent,GoalsComponent,LeadComponent,MarketingLeadFormComponent,DraftedLeadFormComponent,
AccountComponent,DetailLeadFormComponent,KnowledgeBaseComponent,ApplicationTagsComponent,
AllCasesComponent,DocumentGeneratorComponent,ReportComponent,
ProfileComponent,LeadFileViewComponent];
