import { SocketioService } from './../../Services/socketio.service';

import { CryptoService } from './../../Services/CryptoService.service';
import { DocumentFileServiceProxy } from './../../Services/document.service';
import { AuthService } from './../../Services/auth.service';
import { ITFormViewDialogComponent } from './it-form-view/it-form-view.component';
import { PermissionService } from './../../Services/PermissionServices.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Component, OnInit, ViewChild, Pipe } from '@angular/core';
import { AppSettings } from 'src/app/shared/app.settings';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ItFormServiceProxy } from 'src/app/Services/itformServiceProxy.service';
import { User } from 'src/environments/User';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar'
import { DialogConfirmComponent } from 'src/app/shared/dialog-confirm/dialog-confirm.component';
import { ticket } from 'src/app/shared/ticket';

import { Lightbox } from 'ng-gallery/lightbox';
import { Location } from '@angular/common';
import {
  Gallery,
  GalleryItem,
  ThumbnailsPosition,
  ImageSize,
  ImageItem
} from "ng-gallery";
import { loaderActivation } from 'src/environments/loaderActivation';

declare const $: any;
@Component({
  templateUrl: './it-form.component.html',
  styleUrls: ["./it-form.component.css"]

})
export class ITFormComponent implements OnInit {
  dtOptions: DataTables.Settings = {};
  @ViewChild(DataTableDirective, { static: false })
  public dtElement: DataTableDirective;
  dashboardRoute: any;
  ticketSubject: any;
  public coloumn: any;
  items: GalleryItem[];
  status: any;
  priority: any;
  dateFrom: any;
  dateTo: any;
  public allTickets: any = [];
  public addTicketForm: FormGroup;
  public editTicketForm: FormGroup;
  public pipe = new DatePipe("en-US");
  public dtTrigger: Subject<any> = new Subject();
  public editId: any;
  public tempId: any;
  public rows = [];
  public srch = [];
  public files: any[] = [];
  moduleName: string;
  branches = User.Branches;
  blist: any;
  data: any;
  Roles: any;
  Role = [];
  Priorities: any;
  pendingForms: any = 0;
  solvedForms: any = 0;
  completedForm = 0;
  uncompletedForm = 0;
  completeFormPercentage: any = 0;
  uncompleteFormPercentage: any = 0;
  tickets: any
  loader: any = false;
  ticketAddSubmit: any=false;
  editButtonSubmit:  any=false;
  constructor(
    private title: Title,
    private location: Location,
    private formBuilder: FormBuilder,
    private router: Router,
    private itformservice: ItFormServiceProxy,
    private permission: PermissionService,
    private _dialog: MatDialog,
    private auth: AuthService,
    private _snackBar: MatSnackBar,
    private docService: DocumentFileServiceProxy,
    private shareTicket: ticket,
    private CryptoService: CryptoService,
    public gallery: Gallery, public lightbox: Lightbox,
    private socketService: SocketioService,

  ) {
    this.moduleName = "ITForm";

  }
  backBtn(){
    this.location.back();
  }


  sortTicket() {
    this.tickets.sort(function (a, b) { return b.Id - a.Id });
  }
  allBranches() {
    this.allTickets = this.tickets;
  }
  filterTicket() {
    this.completedForm = 0;
    this.uncompletedForm = 0;
    if (!this.blist) {
      this.blist = (User.BranchId);
    }
    if ((User.RoleId == 1) || (this.checkPermission('ITForm', 'Read', this.blist))) {
      // show all IT Forms to Super User or Person having Read Permission


      // if (User.RoleName === "IT") {
      this.allTickets = this.tickets.filter(x => x.branchId == this.blist);
      this.sortTicket();
      // }

    }
    else if ((this.checkPermission('ITForm', 'Create', this.blist)) &&
      (!this.checkPermission('ITForm', 'Read', this.blist))) {
      // Role other than super,
      // if they have the permission of create and don't have a permission of read
      // so they can see their own submitted form only
      this.tickets = this.tickets.filter(x => x.userId == User.Id);
      this.sortTicket();


      this.allTickets = this.tickets.filter(x => x.branchId == this.blist);
    }

    this.rows = this.allTickets;
    this.srch = [...this.rows];
    const totalForms = this.allTickets.length;
    for (let it in this.allTickets) {
      if (this.allTickets[it].isCompleted) {
        this.completedForm += 1;
      }
      else {
        this.uncompletedForm += 1;
      }
    }
    this.completeFormPercentage = ((this.completedForm / totalForms) * 100).toFixed(2);
    if (this.completeFormPercentage === "NaN") {
      this.completeFormPercentage = 0
    }
    this.uncompleteFormPercentage = ((this.uncompletedForm / totalForms) * 100).toFixed(2);
    if (this.uncompleteFormPercentage === "NaN") {
      this.uncompleteFormPercentage = 0
    }
  }
  async getData() {
    this.loader = true;
    loaderActivation.loader = true;
    const promise = this.itformservice.getAllData().toPromise();
    await promise.then(response => {
      this.completedForm = 0;
      this.uncompletedForm = 0;
      this.Roles = response['Roles'];
      response['Roles'].forEach(item => {
        if (item.Name === "IT") {
          this.Role.push(item);

        }
      });
      this.Priorities = response['Priority'];
      this.tickets = response['ITForms']
      this.sortTicket();
      this.filterTicket()
      this.sortTicket();
    },
      error => {
        // console.log(error)
      })
    this.coloumn = [
      { 'Name': 'Id', 'property': 'Id', 'type': 'Id' },
      { 'Name': 'Ticket Id', 'property': 'ticketId', 'type': 'text' },
      { 'Name': 'Ticket Subject', 'property': 'ticketSubject', 'type': 'text' },
      { 'Name': 'Branch Name', 'property': 'branchName', 'type': 'text' },
      { 'Name': 'Created Date', 'property': 'issueDate', 'type': 'date' },
      { 'Name': 'Priority', 'property': 'priorityName', 'type': 'text' },
      { 'Name': 'Status', 'property': 'isCompleted', 'type': 'complete' },
      { 'Name': 'Actions', 'property': '', 'type': 'actions' }
    ];
    this.loader = false;
    loaderActivation.loader = false;
  }
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.dtTrigger.next();
    }, 1000);
  }

  checkPermission = (name, key, branchId) => {

    if (!branchId) {
      branchId = User.BranchId
    }
    var ex = this.permission.checkVisibilityByModuleName(name, key, branchId)


    return ex
  }
  user: any;
  today: any;
  ngOnInit() {
    this.today = new Date();
    this.branches = User.Permissions;
    this.user = User.Id;
    this.getData();
    this.socketService.setupItForm();
    this.socketService.itForm.onmessage = (msg) => {
      let data = JSON.parse(msg['data'])['payload']['data']
      let Action = JSON.parse(msg['data'])['payload']['action']

      data = JSON.parse(data);
      if (Action === "0") {
        this.tickets.push(data)
        console.log(this.tickets, Action)
      }
      else if (Action === "1") {
        let temp = [];
        this.tickets.forEach(element => {
          if (element.Id != data.Id) {
            temp.push(element);
          }
        })
        this.tickets = [];
        this.tickets = temp;
      }
      else if (Action === "2") {
        let temp = [];
        this.tickets.forEach(element => {
          if (element.Id == data.Id) {
            temp.push(data);
          } else {
            temp.push(element);
          }
        })
        this.tickets = [];
        this.tickets = temp;
      }
      this.sortTicket();
      this.filterTicket()
      this.sortTicket();
      // this.rerender();
    }


    this.socketService.itForm.onerror = (error) => {
      console.log(`The server received: ${error['code']}`
      ); this.socketService.setupItForm();
    }

    this.socketService.itForm.onclose = (why) => {
      console.log(`The server received: ${why.code} ${why.reason}`
      ); this.socketService.setupItForm();
    }

    this.title.setTitle('IT Form');
    if (!this.checkPermission("ITForm", "Visiblity", User.BranchId)) {
      this.router.navigateByUrl("error401");
    }

    // this.dashboardRoute = AppSettings.DASHBOARD_URL;
    this.addTicketForm = this.formBuilder.group({
      Id: [0],
      ticketSubject: ["", [Validators.required]],
      // roleId: ["", [Validators.required]],
      issueDate: ["", Validators.required],
      priorityId: ["", [Validators.required]],
      issueDescription: ["", [Validators.required]],
      imagePath: [""],
    });
    this.editTicketForm = this.formBuilder.group({
      Id: [0],
      ticketSubject: ["", [Validators.required], { disabled: true }],
      // roleId: ["", [Validators.required]],
      issueDate: ["", Validators.required, { disabled: true }],
      priorityId: ["", [Validators.required], { disabled: true }],
      issueDescription: ["", [Validators.required], { disabled: true }],
      isCompleted: [""],
      imagePath: [""],
    });
    this.dtOptions = {
      // ... skipped ...
      pageLength: 10,
      dom: "lrtip",
    };
  }
  checkBlist(id) {

    // var idx = this.blist.indexOf(id);
    // if (idx > -1) {
    //  return true;
    // }else{
    //   return false;
    // }
  }
  ticketView(ticket) {
    // { state: { id:1 , name:'Angular' } }
    this._dialog.open(ITFormViewDialogComponent, {
      disableClose: true,
      width: '800px',
      height: '768px',
      data: {
        Id: ticket.Id
      }
    }).afterClosed().subscribe(async (result) => {
      this.title.setTitle("IT Form");
      this.getData();
    });
    // this.router.navigateByUrl('/support/it-form/view/'+this.CryptoService.encryptUsingAES256(ticket.Id).toString().replace('+','xMl3Jk').replace('/','Por21Ld').replace('=','Ml32'));
    // let priorityName = this.Priorities[ticket.priorityId-1].Name
    // console.log(ticket.images);
    // ticket['priorityName'] = priorityName;
    // this.shareTicket.ticketSubject = ticket.ticketSubject;
    // this.shareTicket.issueDescription = ticket.issueDescription;
    // this.shareTicket.createUserName = ticket.createUserName;
    // this.shareTicket.branchName = ticket.branchName;
    // this.shareTicket.issueDate = ticket.issueDate;
    // this.shareTicket.priorityName = ticket.priorityName;
    // this.shareTicket.userBranch = ticket.userBranch;
    // this.shareTicket.isCompleted = ticket.isCompleted;
    // this.shareTicket.images = ticket.images;
    // this.shareTicket.ticketId = ticket.ticketId;
    // this.shareTicket.Id = ticket.Id;
    // this.shareTicket.branchId = ticket.branchId;
    // this.shareTicket.priorityId = ticket.priorityId;
    // this.shareTicket.imagePath = ticket.imagePath?ticket.imagePath:'';
    // this.router.navigateByUrl('support/it-form/view');
    //   this._dialog.open(ITFormViewDialogComponent,{
    //     width:'1000px',
    //     height:'700px',
    //     data: {
    //       ticket: ticket
    //     }
    //   }).afterClosed().subscribe((result) => {

    // });
  }
  openConfirmDialog(pIndex): void {
    const dialogRef = this._dialog.open(DialogConfirmComponent, {
      disableClose: true,
      panelClass: "modal-xs"
    });
    dialogRef.componentInstance.fName = this.files[pIndex].name;
    dialogRef.componentInstance.fIndex = pIndex;

    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined) {
        this.deleteFromArray(result);
      }
    });
  }

  deleteFromArray(index) {
    // console.log(this.files);
    this.files.splice(index, 1);
    this.fileId = -1;
    this.fileIndex--;
  }
  fileIndex: any = 0
  onFileChange(event) {
    let pFileList = event.target.files;
    let _files = Object.keys(pFileList).map(key => pFileList[key]);
    this._snackBar.open("Successfully upload!", "Close", {
      duration: 2000
    });

    if (event.target.files && event.target.files[0]) {
      _files.forEach(item => {
        var reader = new FileReader();

        reader.readAsDataURL(item); // read file as data url

        reader.onload = event => {
          // called once readAsDataURL is completed
          item.filePath = event.target.result;

        };
        item.Id = this.fileIndex;
        this.files.push(item);
        this.fileIndex++;
      });
    }
  }
  async toggleSelection(id) {
    //     var idx = this.blist.indexOf(id);
    //     if (idx > -1) {
    //       this.blist.splice(idx, 1);
    //     }
    //     else {
    //       this.blist.push(id);
    //     }
    // console.log(this.blist,"blist")
    this.blist = id;
    // this.auth.checkToken();
    if (!this.checkPermission("ITForm", "Visiblity", this.blist)) {
      this.router.navigateByUrl("error401");
    }

    await this.getData();

    // var temp =   this.tickets.filter(element => {
    //     // var _idx = this.blist.indexOf(element.branchId);
    //     if(element.branchId===this.blist){
    //    return (element);

    //     }
    //   });
    //   this.allTickets = temp;
  }
  ClearFilter() {
    this.ticketSubject = "";
    this.status = "";
    this.priority = "";
    this.dateFrom = "";
    this.dateTo = "";
    this.getData();
  }
  Search() {
    let dic = {

    }
    if (this.ticketSubject) {
      dic['ticketSubject'] = this.ticketSubject;
    }
    if (this.status) {
      dic['isCompleted'] = (this.status === "true");
    }
    if (this.priority) {
      dic['priorityId'] = parseInt(this.priority);
    }
    if (this.dateFrom) {
      dic['dateFrom'] = this.dateFrom;
    }
    if (this.dateTo) {
      dic['dateTo'] = this.dateTo;
    }
    this.rows.splice(0, this.rows.length);
    var self = this;
    let temp = this.srch.filter(function (d) {
      // console.log(d);
      let c: number = 0;
      let dateCheck = false;
      const keys = Object.keys(dic);
      if (keys.indexOf('dateTo') !== -1 && keys.indexOf('dateFrom') !== -1) {
        let mySimpleFormatTo = self.pipe.transform(dic['dateTo'], "MM-dd-yyyy");
        let dateTo = new Date(mySimpleFormatTo);
        let check = self.pipe.transform(d.issueDate, "MM-dd-yyyy");
        let issueDate = new Date(check);
        let mySimpleFormatFrom = self.pipe.transform(dic['dateFrom'], "MM-dd-yyyy");
        let dateFrom = new Date(mySimpleFormatFrom);
        if (dateTo >= issueDate && dateFrom <= issueDate) {
          c++;
          dateCheck = true;
          c++;
        }
        // delete dic['dateTo'];
        // delete dic['dateFrom'];



      } else if (keys.indexOf('dateTo') !== -1) {
        let mySimpleFormat = self.pipe.transform(dic['dateTo'], "MM-dd-yyyy");
        let date = new Date(mySimpleFormat);
        let check = self.pipe.transform(d.issueDate, "MM-dd-yyyy");
        let issueDate = new Date(check);

        if (date >= issueDate) {
          dateCheck = true;
          c++;
        }
        // delete dic['dateTo'];
      }
      else if (keys.indexOf('dateFrom') !== -1) {
        let mySimpleFormat = self.pipe.transform(dic['dateFrom'], "MM-dd-yyyy");
        let date = new Date(mySimpleFormat);
        let check = self.pipe.transform(d.issueDate, "MM-dd-yyyy");
        let issueDate = new Date(check);
        if (date <= issueDate) {
          dateCheck = true;
          c++;
        }
        // delete dic['dateFrom'];
      }
      Object.entries(dic).forEach(([key, value]) => {
        if (d[key] == value) {
          c++;

        }
      });
      //  console.log(  c,keys.length,keys);
      if (c === keys.length && dateCheck) {
        //  console.log(dic);
        return d;
      } else if (c === keys.length) {
        return d;
      }
      // return d.ticketSubject.toLowerCase().indexOf(val) !== -1 || !val;
    });
    this.rows.push(...temp);
  }
  searchName(val) {
    this.rows.splice(0, this.rows.length);
    let temp = this.srch.filter(function (d) {
      val = val.toLowerCase();
      return d.ticketSubject.toLowerCase().indexOf(val) !== -1 || !val;
    });
    this.rows.push(...temp);
  }
  searchStatus(val) {
    if (val != "") {
      var boolValue = (val == "true");
      this.rows.splice(0, this.rows.length);
      let temp = this.srch.filter(function (d) {
        if (d.isCompleted == boolValue) {
          return d;
        }
      });
      this.rows.push(...temp);
    }
    else {

      this.rows.splice(0, this.rows.length);
      let temp = this.srch.filter(function (d) {

        return d;

      });
      this.rows.push(...temp);
    }
  }
  searchPriority(val) {
    if (val != "") {
      var i = parseInt(val);
      this.rows.splice(0, this.rows.length);
      let temp = this.srch.filter(function (d) {
        if (d.priorityId == i) {
          return d;
        }
        // val = val.toLowerCase();
        // return d.priorityId.toLowerCase().indexOf(i) !== -1 || !i;
      });
      this.rows.push(...temp);
    }
    else {
      this.rows.splice(0, this.rows.length);
      let temp = this.srch.filter(function (d) {
        return d;
        // val = val.toLowerCase();
        // return d.priorityId.toLowerCase().indexOf(i) !== -1 || !i;
      });
      this.rows.push(...temp);
    }
    this.allTickets = this.rows;
  }
  //search by purchase
  searchFrom(val) {
    let mySimpleFormat = this.pipe.transform(val, "MM-dd-yyyy");
    let date = new Date(mySimpleFormat);
    this.rows.splice(0, this.rows.length);
    var self = this;
    let temp = this.srch.filter(function (d) {
      let check = self.pipe.transform(d.issueDate, "MM-dd-yyyy");
      let issueDate = new Date(check);

      if (date <= issueDate) {
        return d;
      }
      // return d.issueDate.indexOf(mySimpleFormat) !== -1 || !mySimpleFormat;
    });
    this.rows.push(...temp);
    $(".floating")
      .on("focus blur", function (e) {
        $(this)
          .parents(".form-focus")
          .toggleClass("focused", e.type === "focus" || this.value.length > 0);
      })
      .trigger("blur");
  }

  //search by warranty
  searchTo(val) {
    let mySimpleFormat = this.pipe.transform(val, "MM-dd-yyyy");
    let date = new Date(mySimpleFormat);
    this.rows.splice(0, this.rows.length);
    var self = this;
    let temp = this.srch.filter(function (d) {
      let check = self.pipe.transform(d.issueDate, "MM-dd-yyyy");
      let issueDate = new Date(check);

      if (date >= issueDate) {
        return d;
      }
    });
    this.rows.push(...temp);
    $(".floating")
      .on("focus blur", function (e) {
        $(this)
          .parents(".form-focus")
          .toggleClass("focused", e.type === "focus" || this.value.length > 0);
      })
      .trigger("blur");
  }
  fileId: any = -1;

  // async rerender() {
  //   $("#datatable").DataTable().clear();


  //   this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
  //     // dtInstance.clear().draw();
  //     dtInstance.destroy();

  //     // setTimeout(() => {
  //     //   this.dtTrigger.next();
  //     // }, 100);

  //   });
  //   this.allTickets = [];
  //   await this.getData();

  //   this.dtTrigger.next();
  //   // setTimeout(() => {
  //   //   this.dtTrigger.next();
  //   // }, 1000);
  // }
  DashboardNavigate() {
    this.router.navigateByUrl(AppSettings.DASHBOARD_URL);
  }
  userId = -1;
  edit(value) {
    this.editId = value;
    let toSetValues = this.allTickets.find(x => x.Id === value);
    // let toSetValues = this.allTickets[index];
    this.userId = toSetValues.userId;
    this.editTicketForm = this.formBuilder.group({
      Id: toSetValues.Id,
      ticketSubject: toSetValues.ticketSubject,
      // roleId: toSetValues.roleId,
      issueDate: toSetValues.issueDate,
      priorityId: toSetValues.priorityId,
      issueDescription: toSetValues.issueDescription,
      imagePath: toSetValues.imagePath,
      isCompleted: toSetValues.isCompleted,
    });
    $("#edit_ticket").appendTo("body");
    $('#edit_ticket').modal('show');
  }
  delete(e) {
    this.socketService.setupSocketConnection(this.tempId);
    e.stopPropagation();
    $("#delete_ticket").appendTo("body");
    $('#delete_ticket').modal('show');
  }
  toggleDropDownId = 0;
  toggleDropDown(id, e) {
    this.toggleDropDownId = id;
    e.stopPropagation();
  }
  createTicket() {
    $("#add_ticket").appendTo("body");
    $('#add_ticket').modal('show');
    this.files = [];
    this.addTicketForm.reset();
  }
  editTicket() {
    if (this.editTicketForm.valid) {
      this.editButtonSubmit= true;
      let data = this.editTicketForm.value;
      data = JSON.stringify(data);
      data = JSON.parse(data);
      data['userId'] = User.Id;
      data['isDeleted'] = false;
      data['resolvedBy'] = User.Name;
      data['deletedBy'] = "";
      data['DeletedbyId'] = "";
      data['resolvedById'] = User.Id;
      data['branchId'] = this.blist;
      this.itformservice.PostItForm(data).subscribe(
        success => {
          // this.getData();
          if (this.socketService.itForm.readyState === WebSocket.CLOSED || this.socketService.itForm.readyState === WebSocket.CLOSING) {
            this.socketService.setupItForm()
          }
          // this.socketService.senditFormData();+


          Swal.fire({ width: 1024, title: success['data']['ticketId'], text: "This ticket id is Succesfully Updated.", icon: 'success' });
          $('#edit_ticket').modal('hide');
          this.editTicketForm.reset();
      this.editButtonSubmit= false;

          this.getData();
        }, error => {
      this.editButtonSubmit= false;

          //  console.log("",error.toString(),"error");
        }
      );
    }
  }

  openImage(files, i) {
    this.items = files.map(item => {
      return new ImageItem({ src: item.filePath, thumb: item.filePath });
    });

    const lightboxRef = this.gallery.ref("lightbox");
    lightboxRef.setConfig({
      imageSize: ImageSize.Cover,
      thumbPosition: ThumbnailsPosition.Top,

      gestures: false
    });
    // Add custom gallery config to the lightbox (optional)

    // Load items into the lightbox gallery ref
    lightboxRef.load(this.items);

    this.lightbox.open(i, 'lightbox', { closeIcon: 'fa fa-times' });
  }
  addTickets() {

    if (this.addTicketForm.valid) {
      this.ticketAddSubmit= true;
      let data = this.addTicketForm.value;
      data = JSON.stringify(data);
      data = JSON.parse(data);
      data['userId'] = User.Id;
      data['branchId'] = this.blist;

      let imgs = []
      this.files.forEach(item => {
        imgs.push({ "filePath": item.filePath, 'name': item.name, "type": item.type })
      })
      // data['files']= JSON.parse(data['files']);


      this.itformservice.PostItForm(data).subscribe(
        success => {
          let docData = { "Id": 0, "itFormId": success['data'].Id, "files": imgs, "userId": null }
          this.docService.PostDoc(docData).subscribe(resp => {
            //  console.log(resp)
          }, error => {
            //  console.log(error);
          });
          // this.rerender();
          // this.getData();
          if (this.socketService.itForm.readyState === WebSocket.CLOSED || this.socketService.itForm.readyState === WebSocket.CLOSING) {
            this.socketService.setupItForm()
          }
          // this.socketService.senditFormData();
          Swal.fire({ width: 1024, title: success['data']['ticketId'], text: "This is the ticket id", icon: 'success' });
          $('#add_ticket').modal('hide');
          this.addTicketForm.reset();
          this.ticketAddSubmit= false;
          this.getData();
        }, error => {
          this.ticketAddSubmit= false;

          // console.log("",error.toString(),"error");
        }
      )
    }
  }

  deleteTicket(id) {
    let toSetValues = this.allTickets.find(x => x.Id === id);
    // let obj = {
    //   'Id': this.tempId
    // };
    toSetValues['isDeleted'] = true
    toSetValues['deletedBy'] = User.Name
    toSetValues['DeletedbyId'] = User.Id;
    toSetValues['resolvedById'] = User.Id;
    let data = JSON.stringify(toSetValues);
    data = JSON.parse(data);
    this.itformservice.PostItForm(data).subscribe(
      success => {
        // this.rerender();
        // this.getData();
        this.socketService.setupItForm()
        // this.socketService.senditFormData();

        // this.socketService.sendData();
        $("#delete_ticket").modal("hide");
        Swal.fire({ width: 1024, title: 'Deleted', text: "Succesfully Deleted.", icon: 'success' });

      }
    )
  }
  checkList: any = []
  clearList() {
    this.checkList = []

  }
  SelectAll() {
    this.allTickets.forEach(element => {
      this.checkList.push(element.Id)
    });
  }
  Check(Id) {
    const index = this.checkList.indexOf(Id);
    if (index > -1) { return true }
    else {
      return false
    }
  }
  checkChange(e, Id) {
    if (e.target.checked) {
      this.checkList.push(Id)
    } else {
      const index = this.checkList.indexOf(Id);
      if (index > -1) {
        this.checkList.splice(index, 1);
      }

    }
  }
  async deleteSelected(checkL) {
    await checkL.forEach(element => {
      let toSetValues = this.allTickets.find(x => x.Id === element);
      toSetValues['isDeleted'] = true
      toSetValues['deletedBy'] = User.Name;
      toSetValues['DeletedbyId'] = User.Id;
      toSetValues['resolvedById'] = User.Id;

      const promise = this.itformservice.PostItForm(toSetValues).toPromise();
      promise.then(Response => {

        this.tempId = -1;



      }, error => {
        console.log("", error.toString(), "error");
        //  Swal.fire("Error","Something is not right","error");
      })
    });
    this.getData();
    this.checkList = [];
    Swal.fire("Success", "Tickets deleted successfully", "success");
    $("#delete_selected_ticket").modal("hide");
  }
}
