import { tap, shareReplay } from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { environment } from '../../environments/environment';
import { HttpClient, HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';



@Injectable()
export class CaseWorkerServiceProxy{

  private apiRoot = environment.apiURL;

  constructor(private http: HttpClient) { }

  PostCase(data){
    return this.http.post(this.apiRoot.concat('caseWorker/'),data).pipe(
      tap(response =>{

        }),
      shareReplay(),
    );
  }
  getCasesByUserId(data){
    return this.http.put(this.apiRoot.concat('caseWorker/'+data+'/'),{}).pipe(
      tap(response =>{

        }),
      shareReplay(),
    );
  }
  getAllData(CaseId)
  {
    var url =  this.apiRoot.concat('caseWorker/'+CaseId+'/')
     return this.http.get(
    url
    ).pipe(
      tap(response =>{

        }),
      shareReplay(),
    );


  }

}
