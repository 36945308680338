import { tap, shareReplay } from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { environment } from './../../environments/environment';
import { HttpClient, HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';



@Injectable()
export class ItFormServiceProxy{

  private apiRoot = environment.apiURL;

  constructor(private http: HttpClient) { }
   getAllData()
  {

    var url =  this.apiRoot.concat('getItFormData')
     return this.http.get(
    url
    ).pipe(
      tap(response =>{

        }),
      shareReplay(),
    );


  }
  getById(id){
    return this.http.put(this.apiRoot.concat('getItFormbyId/'+id+'/'),{}).pipe(
      tap(response =>{

        }),
      shareReplay(),
    );
  }
  PostItForm(data){
    return this.http.post(this.apiRoot.concat('postItFormData/'),data).pipe(
      tap(response =>{

        }),
      shareReplay(),
    );
  }
  DeleteItForm(data){
    return this.http.delete(this.apiRoot.concat('deleteItFormData/'+data['Id'])).pipe(
      tap(response =>{

        }),
      shareReplay(),
    );
  }
}
