import Swal  from 'sweetalert2';
import { loaderActivation } from 'src/environments/loaderActivation';
import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { AppSettings } from "src/app/shared/app.settings";
import { Title } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';
import { AuthService } from 'src/app/Services/auth.service';
import { notificationService } from 'src/app/Services/notificationService.service';
import { PermissionService } from 'src/app/Services/PermissionServices.service';
import { SocketioService } from 'src/app/Services/socketio.service';
import { User } from 'src/environments/User';
import { NotifDataService } from 'src/app/Services/notifData.service';
import { Subscription } from 'rxjs';
import { Location } from '@angular/common'



@Component({
  selector:"notifications",
  templateUrl:'./notifications.componet.html',
  styleUrls: ['./notifications.component.scss'],

})

export class NotificationComponent implements OnInit{

  constructor(  private route: Router,private titleservice: Title,
    private authservice:AuthService,
    private notifService:notificationService,
    private socketService:SocketioService,
    private permission:PermissionService,
    private data: NotifDataService,
    private location: Location,
    ){
      this.subscription=    this.data.getClickEvent().subscribe(()=>{
        this.getData();
        })
  }
  backBtn(){
    this.location.back();
  }
  jsonData: any = {
    notification: [],
    message: [],
  };
  seenNotif=[];
  allSeenNotif=[]
  public pipe = new DatePipe("en-US");
  notifications: any=[];
  messagesData: any;
  user:any;
  public currentDate = new Date();
  public currentYear = new Date().getFullYear();
  ncurrentDate = new Date();
  async seen(Id){
    var self = this;
    let type = ""
    let cls = ""
    if(Id.type==1){
      type="IT SUPPORT"
      cls = "bg-yellow"
    }else if(Id.type==2){
      type="Application Process"
      cls ="bg-pink-medium"
    }
    else if(Id.type==3){
      cls ="bg-light-seagreen"
      type="Retainer"
    }
    let notifDate = new Date(Id.creationTime);

    Swal.fire({
      title: 'Are you sure\
      you want to clear this notification?',
      html: '<div class="d-flex flex-column justify-items-center align-items-center">\
      <div><p class="'+cls+' py-2 px-3 h5" >'+type+'</p></div>\
      <div class="text-center">\
              <p class="small"><b>Sender Name:</b> '+Id.sendByName+'</p>\
              <p class="small"><b>Date Received:</b> '+notifDate.getDate()+"/"+(notifDate.getMonth()+1)+"/"+notifDate.getFullYear()+'</p>\
              <p class="text-truncate small"><b>Details:</b> '+Id.notification_info+'</p>\
            </div>\
      </div>',
      // html: true,
      // icon: 'warning',

      customClass:{confirmButton:"text-dark",cancelButton:"text-dark"},
      showCancelButton: true,

      cancelButtonText:"No",
      confirmButtonColor: '#AFF591',
      showLoaderOnConfirm:true,
      cancelButtonColor: '#F59191',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.isConfirmed) {
        var data ={
          "Id":0,
          "notifId":Id.Id,
          "seen":true,
          "userId":this.user.Id

        }
        const promise = this.notifService.seen(data).toPromise()
        self.seenFalse=0;
         promise.then(resp=> {
         this.allNotif = this.allNotif.filter(x=>x.Id!=Id.Id);

         this.notifications = this.notifications.filter(x=>x.Id!=Id.Id);
         this.allSeenNotif.push(Id);
         this.seenNotif.push(Id);
         this.newSeen();
         } ,error=>console.log(error));


  }
});


  }
  type=0;
  newSeen(){
    this.data.changeMessage(this.notifications.length);
  }
  typeChange(e){
    this.type=parseInt(e);

    if(this.type==0){
   this.notifications = this.allNotif;
   this.seenNotif = this.allSeenNotif;
    }else{
      this.notifications = this.allNotif.filter(x=>x.type===this.type);
      this.seenNotif = this.allSeenNotif.filter(x=>x.type===this.type);
    }
  this.newSeen();
  }
  allNotif = []
  async getData(){

   loaderActivation.loader = true;
    // await this.authservice.checkToken();
    this.seenNotif =[]
    this.allNotif = []
    this.allSeenNotif = []
    this.notifications = []
   const promise =  this.notifService.getNotif(this.ncurrentDate.toISOString()).toPromise()

  this.notifications = [];
   await promise.then(resp=>{
    this.seenFalse=0;

    this.allNotif = resp['data'];
    console.log(this.notifications);
    this.Calnotifications();
   },error=>{
     console.log(error);
   })
   loaderActivation.loader=false;
  }
  monthChange(e){
   this.currentDate = e;
  let d = new Date(Date.parse(this.currentDate.toISOString()));
  let _d =  this.currentDate.toISOString();
  d = new Date(_d);
  d.setDate( d.getDate()+1 );
  console.log(d.toISOString());
  this.ncurrentDate = d;
    this.getData();
  }
  Calnotifications(){
    let mySimpleFormatTo = this.pipe.transform(this.user.creationTime, "MM-dd-yyyy hh:mm:ss");
    let userDate = new Date(this.user.creationTime);

    userDate.setHours(0,0,0,0);
    let self=this;
    this.notifications = this.allNotif.filter(x=>{
      let check = self.pipe.transform(x.creationTime, "MM-dd-yyyy hh:mm:ss");
      let notifTime = new Date(x.creationTime);
      notifTime.setHours(0,0,0,0);

      let cDate = this.currentDate;
      cDate.setHours(0,0,0,0);

      let current = notifTime.toDateString()==cDate.toDateString();

      console.log(cDate.toDateString(),notifTime.toDateString(),current);
      if((x.type==4 ||this.user.RoleId==1) && (notifTime>=userDate ) && (x.roleId==this.user.RoleId || this.user.RoleId==1 || x.sendTo==this.user.Id )){
        let check=false;
       for(let element of x.seen) {
         if(element.seenBy){
           if(element.seenBy==this.user.Id && element.seen){
             check=true;
            break;
           }
         }
       };
       if(check){
       this.seenNotif.push(x);
       this.allSeenNotif.push(x);
       }else{
        return x;
       }
      }
      else if((x.sendBy!= parseInt(this.user.Id) ||this.user.RoleId==1  ) && (notifTime>=userDate ) && (x.roleId==this.user.RoleId || this.user.RoleId==1 || x.sendTo==this.user.Id )){
       if(x.type==2 && this.user.RoleId==1){
    // let str =     x.notification_info;

        x.notification_info = x.notification_info.replace("You have been assigned",x.sendToName+" has been assigned");
        x.notification_info = x.notification_info.replace("Its your ","Its "+x.sendToName+"'s ")

        x.notification_info = x.notification_info.replace("You are selected",x.sendToName+" has been selected ")
       }
       let check=false;
       for(let element of x.seen) {
         if(element.seenBy){
           if(element.seenBy==this.user.Id && element.seen){
             check=true;
            break;
           }
         }
       };
       if(check){
       this.seenNotif.push(x);
       this.allSeenNotif.push(x);
       }else{
        return x;
       }
      }

    });

    this.notifications.forEach(element => {
      if(element['ticketId'] || element['sendTo']){
        if(this.checkPermission("ITForm","Read",element['branchId']) || this.checkPermission("ITForm","Read",element['branchId'])){
      if(element.seen===false){
        this.seenFalse+=1;
      }
    }
  }
    });
    this.allNotif = this.notifications ;
    this.sortNotification();
    this.newSeen();
    console.log(this.notifications)
  }
  seenFalse:any=0;
  sortNotification(){
    this.notifications.sort(function(a, b){return b.Id-a.Id});
  }
  convertTime(data){

    var unixtimestamp = data.creationTime.split('Z')[0];
    return unixtimestamp;
  }

  subscription :Subscription
  ngOnInit(){

    loaderActivation.loader=true;
    this.titleservice.setTitle("Notifications")
    this.user=User;


    this.getData();

    this.notifications = [];

    this.messagesData = [];
    this.socketService.setupNotficationSocket();
    this.socketService.notification_socket.ondisconnect=(msg)=>{
      console.log("disconnect",msg);
      this.socketService.setupNotficationSocket();
    }
    this.socketService.notification_socket.onmessage = (msg) => {
      // this.authservice.checkToken();

    let  data = JSON.parse(msg['data'])['payload']['data']
    if(data){
    data=JSON.parse(data);
    }
    let mySimpleFormatTo = this.pipe.transform(this.user.creationTime, "MM-dd-yyyy hh:mm:ss");
    let userDate = new Date(this.user.creationTime);

    let check = this.pipe.transform(data.creationTime, "MM-dd-yyyy hh:mm:ss");
    let notifTime = new Date(data.creationTime);
    let cDate = this.currentDate;
    cDate.setHours(0,0,0,0);

    let current = notifTime.toDateString()==cDate.toDateString();
    console.log(cDate.toDateString(),notifTime.toDateString(),current);
    if(data){
      if((data.type==4 ||this.user.RoleId==1) && (notifTime>=userDate ) && (data.roleId==this.user.RoleId || this.user.RoleId==1 || data.sendTo==this.user.Id )){
        data['seen']=false;
     this.seenFalse+=1;
      this.notifications.push(data)
      this.allNotif.push(data);
      // this.getData();
      this.sortNotification();
      }
      else if((data.sendBy!= parseInt(this.user.Id) ||this.user.RoleId==1  ) && (notifTime>=userDate ) && (data.roleId==this.user.RoleId || this.user.RoleId==1 || data.sendTo==this.user.Id )){
        if(data.type==2 && this.user.RoleId==1){
     // let str =     x.notification_info;

     data.notification_info = data.notification_info.replace("You have been assigned",data.sendToName+" has been assigned");
     data.notification_info = data.notification_info.replace("Its your ","Its "+data.sendToName+"'s ")

     data.notification_info = data.notification_info.replace("You are selected",data.sendToName+" has been selected ")
        }

     data['seen']=false;
     this.seenFalse+=1;
      this.notifications.push(data)
      this.allNotif.push(data);
      // this.getData();
      this.sortNotification();
      console.log(data);
 }
 }
 this.newSeen();
    }

    this.socketService.notification_socket.onerror = (error) =>{
      console.log(`The server received: ${error['code']}` );
      this.socketService.setupNotficationSocket();}

    this.socketService.notification_socket.onclose = (why) =>{
      console.log(`The server received: ${why.code} ${why.reason}`
      );
      this.socketService.setupNotficationSocket();
    }
  }
  checkPermission=(name,key,branchId)=>{

    if(!branchId){
      branchId=User.BranchId
    }
    var ex = this.permission.checkVisibilityByModuleName(name,key,branchId)


    return ex
  }

  DashboardNavigate() {
    this.route.navigateByUrl(AppSettings.DASHBOARD_URL);
  }
}
