import { tap, shareReplay } from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { environment } from '../../environments/environment';
import { HttpClient, HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';



@Injectable()
export class CaseGroupListServiceProxy{

  private apiRoot = environment.apiURL;

  constructor(private http: HttpClient) { }

  PostCaseGroupList(data){
    return this.http.post(this.apiRoot.concat('caseGroupList/'),data).pipe(
      tap(response =>{

        }),
      shareReplay(),
    );
  }

  getAllData()
  {
    var url =  this.apiRoot.concat('caseGroupList')
     return this.http.get(
    url
    ).pipe(
      tap(response =>{

        }),
      shareReplay(),
    );


  }

}
