import { SocketioService } from "./socketio.service";

import { environment } from "./../../environments/environment";
import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from "@angular/common/http";
import {
  CanActivate,
  Router,
  ActivatedRoute,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  NavigationEnd,
  RoutesRecognized,
} from "@angular/router";

import { Observable } from "rxjs";
import { tap, shareReplay, filter, pairwise, finalize } from "rxjs/operators";

import jwtDecode from "jwt-decode";

import { User } from "src/environments/User";

@Injectable()
export class AuthService {
  private apiRoot = environment.apiURL;

  constructor(private http: HttpClient, private router: Router) {}

  private setSession(authResult) {
    const token = authResult.Token;

    const payload = <JWTPayload>jwtDecode(token);

    localStorage.setItem("token", token);
    localStorage.setItem("loggedin", "true");
    User.isLoggedin = true;
    User.Email = authResult.Email;
    User.Id = authResult.Id;
    User.RoleId = authResult.user.RoleId;

    User.BranchId = authResult.user.branchId;
    User.Permissions = authResult.Permissions;
    User.Branches = authResult.Branches;
    User.RoleName = authResult.RoleName;
    User.creationTime = authResult.Creation_Time;
  }

  get token(): string {
    return localStorage.getItem("token");
  }

  login(Email: string, password: string) {
    var url = this.apiRoot.concat("api/token/");

    var data = {
      Email: Email,
      password: password,
    };
    return this.http.post(url, data).pipe(
      tap((response) => {
        this.setSession(response["data"]);
      }),
      shareReplay()
    );
  }

  //   signup(username: string, email: string, password1: string, password2: string) {
  //     // TODO: implement signup
  //   }

  logout() {
    localStorage.removeItem("token");
    localStorage.setItem("loggedin", "false");
    User.isLoggedin = false;
    this.router.navigate(["login"]);
  }

  async checkToken() {
    var check = true;

    const promise = this.http.get(this.apiRoot.concat("login/")).toPromise();

    await promise
      .then((data) => {
        // console.log(data,"data");
        let permission = data["Permissions"];
        let branches = data["Branches"];
        data = data["user"];
        if (data["isDelete"] == true || data["isActive"] == false) {
          this.logout();
        }
        User.RoleName = data["RoleName"];
        User.Name = data["UserName"];
        User.isLoggedin = true;
        User.Email = data["Email"];
        User.Id = data["Id"];
        User.RoleId = data["RoleId"];
        User.BranchId = data["branchId"];
        User.Permissions = permission;
        User.Branches = branches;
        User.creationTime = data["Creation_Time"];
        if (data["profilePic"].length > 0) {
          User.profilePic = data["profilePic"][0];
        }
        // console.log(User);
        check = true;
      })
      .catch((error) => {
        check = false;
      });

    return check;
  }
}

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private logRequestTime(startTime: number, url: string, method: string) {
    const requestDuration = `${performance.now() - startTime}`;
    console.log(`HTTP ${method} ${url} - ${requestDuration} milliseconds`);
  }
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = localStorage.getItem("token");

    if (token) {
      const cloned = req.clone({
        headers: req.headers.set("Authorization", "Bearer ".concat(token)),
      });

      const begin = performance.now();
      return next.handle(cloned).pipe(
        finalize(() => {
          this.logRequestTime(begin, req.url, req.method);
        })
      );
    } else {
      return next.handle(req);
    }
  }
}

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  private apiRoot = environment.apiURL;
  is_valid = true;

  async canActivate(
    _route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    await this.authService
      .checkToken()
      .then((success) => {
        this.is_valid = success;
      })
      .catch((error) => {
        this.authService.logout();

        this.router.navigate(["login"]);
      });

    if (this.is_valid) {
      this.router.events
        .pipe(
          filter((e) => e instanceof RoutesRecognized),
          pairwise()
        )
        .subscribe((event: any[]) => {
          if (event[0].urlAfterRedirects != "/login") {
            localStorage.setItem("preUrl", event[0].urlAfterRedirects);
          }
        });

      return true;
    } else {
      this.authService.logout();

      this.router.navigate(["login"]);

      return true;
    }
  }
}

interface JWTPayload {
  user_id: number;
  username: string;
  Email: string;
}
