import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CaseListModal } from 'src/app/all-modules/application-process-started/CaseListModal/CaseListModal';
import { CaseServiceProxy } from 'src/app/Services/caseServiceProxy.service';
import { CryptoService } from 'src/app/Services/CryptoService.service';
import { EncryptionDecryptionService } from 'src/app/Services/encryption-decryption.service';
import { AppSettings } from 'src/app/shared/app.settings';
import { loaderActivation } from 'src/environments/loaderActivation';
import { User } from 'src/environments/User';
import Swal from 'sweetalert2';

declare const $: any;
@Component({
  selector: 'app-dashboard-view',
  templateUrl: './dashboard-view.component.html',
  styleUrls: ['./dashboard-view.component.css']
})

export class DashboardViewComponent implements OnInit {
  activeFiles: any = [];
  heading='';
  isITticket: any = false;
  isSolvedTicket: any = false;
  isIncompleteTicket: any = false;
  isAwaitingFiles: any = false;
  isActiveFiles: any = false;
  isIncomplete: any = false;
  isRetainer: any = false;
  isPending: any = false;
  caseWorker :boolean= false;
  isPprev: any = false;
  isCurrent: any = false;
  isOutStanding: any = false;
  coloumn: any = [];
  itTickets: any = [];
  ITcoloumn: any = [];
  Balanccoloumn: any = [];
  islate: any = false;
  balance: any = [];
  moduleName: any;
  user = User;
  ispendingBalance: boolean;
  personResponsible :boolean= false;
  filterFields: string[];
  constructor(private route: ActivatedRoute,
    private encrypt: CryptoService,
    private router: Router,
    private caseService:CaseServiceProxy,
    private _dialog: MatDialog,
    private title : Title,
    private encryptionDecryptionService: EncryptionDecryptionService,) {
      // console.log(this.router.getCurrentNavigation().extras.state);
     }
  getData() {
    var id = this.route.snapshot.queryParamMap.get('ActiveFiles');
    let self = this;
    loaderActivation.loader = true;
    if (id) {
      this.isActiveFiles = true;
      this.heading = 'Active Files';
      this.title.setTitle("List of Active Files");
      try {
        var check=false;
        this.moduleName = 'Application Process';
        id = JSON.parse(this.encrypt.decryptUsingAES256(id));
        id = JSON.parse(id);

        this.activeFiles = id;
        if(this.activeFiles.length>0){
          this.activeFiles.forEach(el => {
            let caseWorkerList = el.caseWorkerList;
            let totalCompleted=0;
            let completed = 0;
            let total = 0;
            let checkStatus = true;
            if(caseWorkerList.length>0){
              let caseWorkerLs = [];
               let dic = {}
               caseWorkerList.forEach(element => {
                let caseWorkDic ={
                  id: element['userId_id'],
                  name: element.CaseWorkerName,
                  profilePic:element.profilePic
                }
                // if(element['userId'] in case)
                caseWorkerLs.push(caseWorkDic);
                if(Object.keys(dic).indexOf(element['userId_id'])){
                  dic[element['userId_id']]=[]
                  dic[element['userId_id']].push(caseWorkDic)
                }
                else{
                  dic[element['userId_id']].push(caseWorkDic)
                }
                if(element.status==2){
                  totalCompleted +=1;
                }

                if(element.status==1){
                  checkStatus= false;

                }
                if(element['userId']===self.user.Id ){
                  self.caseWorker =true;
                }

                if(element['caseWorkerGroupList'].length>0){
                  element['caseWorkerGroupList'].forEach(element1 => {
                    total+=1;
                    if(element1['isCompleted']){
                      completed+=1;
                    }
                  });
                }

              });

              let caseWorkerDistant =  []
        for (const [key, value] of Object.entries(dic)) {
          caseWorkerDistant.push(dic[key][0]);

        }
        el['caseWorkerProfileList']= caseWorkerDistant;
            }
            if((totalCompleted==caseWorkerList.length && (el["personResposibleId"]==self.user.Id || self.user.RoleId==1)&& el['caseStatus']===0)){
              el["approve"] = true
              check=true;
            }else{
              el["approve"]=false
            }

            if(checkStatus){
              let status = completed.toString() + ' / ' + total.toString();
            el['status'] = status;
            }
            else{
              el['status']= 'Waiting For Aproval';
            }

          });
        }
      } catch (error) {
        // console.error(error);
        // expected output: ReferenceError: nonExistentFunction is not defined
        // Note - error messages will vary depending on browser
      }
      this.filterFields = ['Id','fileNumber','clientName','matterName','creationTime','deadLine'
      ,'status'];
      this.coloumn = [
        { 'Name': 'Id', 'property': 'Id', 'type': 'Id' },
        { 'Name': 'File Number', 'property': 'fileNumber', 'type': 'text' },
        { 'Name': 'Client Name', 'property': 'clientName', 'type': 'text' },
        {'Name':'Matter Name','property':'matterName','type':'text'},
        {'Name':'Deadline Date','property':'deadLine','type':'date'},
        {'Name':'Status','property':'status','type':'status-bar'},
        {'Name':'Case Workers','property':'caseWorkerProfileList','type':'textOrPic'},
        // {'Name':'Email','property':'email','type':'text'},
        // {'Name':'Phone Number','property':'phoneNumber','type':'text'},
        // {'Name':'Work Source','property':'workSource','type':'text'},
        // { 'Name': 'Creation Date', 'property': 'creationTime', 'type': 'date' },
        // {'Name':'Actions','property':'','type':'actions'}
        {'Name':'Actions','property':'approve',"type":"actions-with-approve"}
      ]
      // if(check){
      //   this.coloumn.pop();
      //   this.coloumn.push({'Name':'Actions','property':'approve',"type":"actions-with-approve"})
      // }
    }
    var awaiting = this.route.snapshot.queryParamMap.get('AwaitingFiles');
    if (awaiting) {
      this.isAwaitingFiles = true;
      this.title.setTitle("List of Awaiting Files");
      try {
        var check=false;
        this.moduleName = 'Application Process';
        awaiting = JSON.parse(this.encrypt.decryptUsingAES256(awaiting));
        awaiting = JSON.parse(awaiting);

        this.activeFiles = awaiting;
        if(this.activeFiles.length>0){
          this.activeFiles.forEach(el => {
            let caseWorkerList = el.caseWorkerList;
            let totalCompleted=0;
            let completed = 0;
            let total = 0;
            let checkStatus = true;
            if(caseWorkerList.length>0){
              let caseWorkerLs = [];
               let dic = {}
               caseWorkerList.forEach(element => {
                let caseWorkDic ={
                  id: element['userId_id'],
                  name: element.CaseWorkerName,
                  profilePic:element.profilePic
                }
                // if(element['userId'] in case)
                caseWorkerLs.push(caseWorkDic);
                if(Object.keys(dic).indexOf(element['userId_id'])){
                  dic[element['userId_id']]=[]
                  dic[element['userId_id']].push(caseWorkDic)
                }
                else{
                  dic[element['userId_id']].push(caseWorkDic)
                }
                if(element.status==2){
                  totalCompleted +=1;
                }

                if(element.status==1){
                  checkStatus= false;

                }
                if(element['userId']===self.user.Id ){
                  self.caseWorker =true;
                }

                if(element['caseWorkerGroupList'].length>0){
                  element['caseWorkerGroupList'].forEach(element1 => {
                    total+=1;
                    if(element1['isCompleted']){
                      completed+=1;
                    }
                  });
                }

              });

              let caseWorkerDistant =  []
        for (const [key, value] of Object.entries(dic)) {
          caseWorkerDistant.push(dic[key][0]);

        }
        el['caseWorkerProfileList']= caseWorkerDistant;
            }

            if((totalCompleted==caseWorkerList.length && (el["personResposibleId"]==self.user.Id || self.user.RoleId==1)&& el['caseStatus']===0)){
              el["approve"] = true
              check=true;
            }else{
              el["approve"]=false
            }
            el["approveWait"]=true


            if(checkStatus){
              let status = completed.toString() + ' / ' + total.toString();
            el['status'] = status;
            }
            else{
              el['status']= 'Waiting For Aproval';
            }

          });
        }
      } catch (error) {
        // console.error(error);
        // expected output: ReferenceError: nonExistentFunction is not defined
        // Note - error messages will vary depending on browser
      }
      this.coloumn = [
        { 'Name': 'Id', 'property': 'Id', 'type': 'Id' },
        { 'Name': 'File Number', 'property': 'fileNumber', 'type': 'text' },
        { 'Name': 'Client Name', 'property': 'clientName', 'type': 'text' },
        {'Name':'Matter Name','property':'matterName','type':'text'},
        {'Name':'Deadline Date','property':'deadLine','type':'date'},
        {'Name':'Status','property':'status','type':'status-awaiting-decision'},
        {'Name':'Case Workers','property':'caseWorkerProfileList','type':'textOrPic'},
        // {'Name':'Email','property':'email','type':'text'},
        // {'Name':'Phone Number','property':'phoneNumber','type':'text'},
        // {'Name':'Work Source','property':'workSource','type':'text'},
        // { 'Name': 'Creation Date', 'property': 'creationTime', 'type': 'date' },
        // {'Name':'Actions','property':'','type':'actions'}
        {'Name':'Actions','property':'approveWait',"type":"actions-with-approveAwait"}

      ]
      this.filterFields = ['Id','fileNumber','clientName','matterName','creationTime','deadLine'
,'status'];
      // if(check){
      //   this.coloumn.pop();
      //   this.coloumn.push({'Name':'Actions','property':'approve',"type":"actions-with-approve"})
      // }

    }
    var pendingBalance = this.route.snapshot.queryParamMap.get('PendingBalance');
    if(pendingBalance){
      this.ispendingBalance = true;
      this.title.setTitle("List of Pedning Files");
      try{
        pendingBalance = JSON.parse(this.encrypt.decryptUsingAES256(pendingBalance));
        pendingBalance = JSON.parse(pendingBalance);

        this.activeFiles = pendingBalance;

      } catch (error){

      }
      this.coloumn = [
        { 'Name': 'Id', 'property': 'Id', 'type': 'Id' },
        { 'Name': 'File Number', 'property': 'fileNumber', 'type': 'text' },
        { 'Name': 'Balance', 'property': 'outBalance', 'type': 'bgtext-1' },
        { 'Name': 'Client Name', 'property': 'clientName', 'type': 'text' },
        // {'Name':'Email','property':'email','type':'text'},
        // {'Name':'Phone Number','property':'phoneNumber','type':'text'},
        // {'Name':'Work Source','property':'workSource','type':'text'},
        { 'Name': 'Creation Date', 'property': 'creationTime', 'type': 'date' },
        // {'Name':'Actions','property':'','type':'actions'}
      ]
    }
    var incomplete = this.route.snapshot.queryParamMap.get('AwaitingCompletion');
    if (incomplete) {
      this.isIncomplete = true;
      this.title.setTitle("Awaiting Completion Files");
      try {
        this.moduleName = 'Application Process';
        incomplete = JSON.parse(this.encrypt.decryptUsingAES256(incomplete));
        incomplete = JSON.parse(incomplete);

        this.activeFiles = incomplete;
        self.personResponsible= false;
        if(this.activeFiles.length>0){
          this.activeFiles.forEach(el => {
            let caseWorkerList = el.caseWorkerList;
            let totalCompleted=0;
            let completed = 0;
            let total = 0;
            let checkStatus = true;
            el["archive"] = true


            if(el["personResposibleId"]==self.user.Id  || self.user.RoleId==1 ){
              self.personResponsible = true;
            }

            if(caseWorkerList.length>0){
              let caseWorkerLs = [];
               let dic = {}
               caseWorkerList.forEach(element => {
                let caseWorkDic ={
                  id: element['userId_id'],
                  name: element.CaseWorkerName,
                  profilePic:element.profilePic
                }
                // if(element['userId'] in case)
                caseWorkerLs.push(caseWorkDic);
                if(Object.keys(dic).indexOf(element['userId_id'])){
                  dic[element['userId_id']]=[]
                  dic[element['userId_id']].push(caseWorkDic)
                }
                else{
                  dic[element['userId_id']].push(caseWorkDic)
                }
                if(element.status==2){
                  totalCompleted +=1;
                }

                if(element.status==1){
                  checkStatus= false;

                }
                if(element['userId']===self.user.Id ){
                  self.caseWorker =true;
                }

                if(element['caseWorkerGroupList'].length>0){
                  element['caseWorkerGroupList'].forEach(element1 => {
                    total+=1;
                    if(element1['isCompleted']){
                      completed+=1;
                    }
                  });
                }

              });

              let caseWorkerDistant =  []
        for (const [key, value] of Object.entries(dic)) {
          caseWorkerDistant.push(dic[key][0]);

        }
        el['caseWorkerProfileList']= caseWorkerDistant;
            }

            if((totalCompleted==caseWorkerList.length && (el["personResposibleId"]==self.user.Id || self.user.RoleId==1)&& el['caseStatus']===0)){
              el["approve"] = true
              check=true;
            }else{
              el["approve"]=false
            }
            el["approveWait"]=true


            if(checkStatus){
              let status = completed.toString() + ' / ' + total.toString();
            el['status'] = status;
            }
            else{
              el['status']= 'Waiting For Aproval';
            }

          });
        }
      } catch (error) {
        // console.error(error);
        // expected output: ReferenceError: nonExistentFunction is not defined
        // Note - error messages will vary depending on browser
      }
      this.filterFields = ['Id','fileNumber','clientName','matterName','creationTime','deadLine'
,'status'];
      this.coloumn = [
        { 'Name': 'Id', 'property': 'Id', 'type': 'Id' },
        { 'Name': 'File Number', 'property': 'fileNumber', 'type': 'text' },
        { 'Name': 'Client Name', 'property': 'clientName', 'type': 'text' },
        {'Name':'Matter Name','property':'matterName','type':'text'},
        {'Name':'Deadline Date','property':'deadLine','type':'date'},
  {'Name':'Case Workers','property':'caseWorkerProfileList','type':'textOrPic'},

        // {'Name':'Email','property':'email','type':'text'},
        // {'Name':'Phone Number','property':'phoneNumber','type':'text'},
        // {'Name':'Work Source','property':'workSource','type':'text'},
        // { 'Name': 'Creation Date', 'property': 'creationTime', 'type': 'date' },
        // {'Name':'Actions','property':'','type':'actions'}
      ]
      if( this.personResponsible){
        this.coloumn.push({'Name':'Actions','property':'archive',"type":"actions-with-archive"})
      }
      else{
        this.coloumn.push({'Name':'Actions','property':'',"type":"actions"})
      }
    }
    var retainer = this.route.snapshot.queryParamMap.get('Retainer');
    if (retainer) {
      this.isRetainer = true;
      this.title.setTitle("Retiner Files");
      try {
        retainer = JSON.parse(this.encrypt.decryptUsingAES256(retainer));
        retainer = JSON.parse(retainer);

        this.activeFiles = retainer;
      } catch (error) {
        // console.error(error);
        // expected output: ReferenceError: nonExistentFunction is not defined
        // Note - error messages will vary depending on browser
      }
      this.coloumn = [
        { 'Name': 'Id', 'property': 'Id', 'type': 'Id' },
        { 'Name': 'File Number', 'property': 'fileNumber', 'type': 'text' },
        { 'Name': 'Client Name', 'property': 'clientName', 'type': 'text' },
        // {'Name':'Email','property':'email','type':'text'},
        // {'Name':'Phone Number','property':'phoneNumber','type':'text'},
        // {'Name':'Work Source','property':'workSource','type':'text'},
        { 'Name': 'Creation Date', 'property': 'creationTime', 'type': 'date' },
        // {'Name':'Actions','property':'','type':'actions'}
      ]
    }
    var lateFile = this.route.snapshot.queryParamMap.get('Latefiles');
    if (lateFile) {
      this.islate = true;
      this.title.setTitle("Late Files");

      try {
        lateFile = JSON.parse(this.encrypt.decryptUsingAES256(lateFile));
        lateFile = JSON.parse(lateFile);

        this.activeFiles = lateFile;
      } catch (error) {
        // console.error(error);
        // expected output: ReferenceError: nonExistentFunction is not defined
        // Note - error messages will vary depending on browser
      }
      this.coloumn = [
        { 'Name': 'Id', 'property': 'Id', 'type': 'Id' },
        { 'Name': 'File Number', 'property': 'fileNumber', 'type': 'text' },
        { 'Name': 'Client Name', 'property': 'clientName', 'type': 'text' },
        // {'Name':'Email','property':'email','type':'text'},
        // {'Name':'Phone Number','property':'phoneNumber','type':'text'},
        // {'Name':'Work Source','property':'workSource','type':'text'},
        { 'Name': 'Creation Date', 'property': 'creationTime', 'type': 'date' },
        // {'Name':'Actions','property':'','type':'actions'}
      ]
    }
    var pending = this.route.snapshot.queryParamMap.get('Pending');
    if (pending) {
      this.isPending = true;
      this.title.setTitle("Pending Files");

      try {
        pending = JSON.parse(this.encrypt.decryptUsingAES256(pending));
        pending = JSON.parse(pending);

        this.activeFiles = pending;
      } catch (error) {
        // console.error(error);
        // expected output: ReferenceError: nonExistentFunction is not defined
        // Note - error messages will vary depending on browser
      }
      this.coloumn = [
        { 'Name': 'Id', 'property': 'Id', 'type': 'Id' },
        { 'Name': 'File Number', 'property': 'fileNumber', 'type': 'text' },
        { 'Name': 'Client Name', 'property': 'clientName', 'type': 'text' },
        // {'Name':'Email','property':'email','type':'text'},
        // {'Name':'Phone Number','property':'phoneNumber','type':'text'},
        // {'Name':'Work Source','property':'workSource','type':'text'},
        { 'Name': 'Creation Date', 'property': 'creationTime', 'type': 'date' },
        // {'Name':'Actions','property':'','type':'actions'}
      ]
    }
    var itTicket = this.route.snapshot.queryParamMap.get('ITTicket');
    if (itTicket) {
      this.isITticket = true;
      this.title.setTitle("IT Ticket Files");

      try {
        itTicket = JSON.parse(this.encrypt.decryptUsingAES256(itTicket));
        itTicket = JSON.parse(itTicket);

        this.itTickets = itTicket;
      } catch (error) {
        // console.error(error);
        // expected output: ReferenceError: nonExistentFunction is not defined
        // Note - error messages will vary depending on browser
      }
      this.ITcoloumn = [
        { 'Name': 'Id', 'property': 'Id', 'type': 'Id' },
        { 'Name': 'Ticket Id', 'property': 'ticketId', 'type': 'text' },
        { 'Name': 'Ticket Subject', 'property': 'ticketSubject', 'type': 'text' },
        { 'Name': 'Assign Name', 'property': 'createUserName', 'type': 'text' },
        { 'Name': 'Created Date', 'property': 'creationTime', 'type': 'date' },
        { 'Name': 'Deadline', 'property': 'issueDate', 'type': 'date' },
        { 'Name': 'Priority', 'property': 'priority', 'type': 'text' },
      ]
    }
    var solveditTicket = this.route.snapshot.queryParamMap.get('Solved');
    if (solveditTicket) {
      this.isSolvedTicket = true;
      this.title.setTitle("Solved IT Ticket Files");

      try {
        solveditTicket = JSON.parse(this.encrypt.decryptUsingAES256(solveditTicket));
        solveditTicket = JSON.parse(solveditTicket);

        this.itTickets = solveditTicket;
      } catch (error) {
        // console.error(error);
        // expected output: ReferenceError: nonExistentFunction is not defined
        // Note - error messages will vary depending on browser
      }
      this.ITcoloumn = [
        { 'Name': 'Id', 'property': 'Id', 'type': 'Id' },
        { 'Name': 'Ticket Id', 'property': 'ticketId', 'type': 'text' },
        { 'Name': 'Ticket Subject', 'property': 'ticketSubject', 'type': 'text' },
        { 'Name': 'Assign Name', 'property': 'createUserName', 'type': 'text' },
        { 'Name': 'Created Date', 'property': 'creationTime', 'type': 'date' },
        { 'Name': 'Deadline', 'property': 'issueDate', 'type': 'date' },
        { 'Name': 'Priority', 'property': 'priority', 'type': 'text' },
      ]
    }
    var pendingitTicket = this.route.snapshot.queryParamMap.get('PendingIT');
    if (pendingitTicket) {
      this.isIncompleteTicket = true;
      this.title.setTitle("Pending IT Files");

      try {
        pendingitTicket = JSON.parse(this.encrypt.decryptUsingAES256(pendingitTicket));
        pendingitTicket = JSON.parse(pendingitTicket);

        this.itTickets = pendingitTicket;
      } catch (error) {
        // console.error(error);
        // expected output: ReferenceError: nonExistentFunction is not defined
        // Note - error messages will vary depending on browser
      }
      this.ITcoloumn = [
        { 'Name': 'Id', 'property': 'Id', 'type': 'Id' },
        { 'Name': 'Ticket Id', 'property': 'ticketId', 'type': 'text' },
        { 'Name': 'Ticket Subject', 'property': 'ticketSubject', 'type': 'text' },
        { 'Name': 'Assign Name', 'property': 'createUserName', 'type': 'text' },
        { 'Name': 'Created Date', 'property': 'creationTime', 'type': 'date' },
        { 'Name': 'Deadline', 'property': 'issueDate', 'type': 'date' },
        { 'Name': 'Priority', 'property': 'priority', 'type': 'text' },
      ]
    }
    var prev = this.route.snapshot.queryParamMap.get('PrevMonth');
    if (prev) {
      this.isPprev = true;
      this.title.setTitle("Previous Month Files");

      try {
        prev = JSON.parse(this.encrypt.decryptUsingAES256(prev));
        prev = JSON.parse(prev);

        this.balance = prev;
      } catch (error) {
        // console.error(error);
        // expected output: ReferenceError: nonExistentFunction is not defined
        // Note - error messages will vary depending on browser
      }
      this.Balanccoloumn = [
        { 'Name': 'Id', 'property': 'Id', 'type': 'Id' },
        { 'Name': 'File Number', 'property': 'FileNumber', 'type': 'text' },
        { 'Name': 'Client Name', 'property': 'ClientName', 'type': 'text' },
        { 'Name': 'Payment In Date', 'property': 'paymentIn', 'type': 'date' },
        { 'Name': 'Branch', 'property': 'Branch', 'type': 'text' },
        { 'Name': 'AmountIn', 'property': 'AmountIn', 'type': 'number' },
        { 'Name': 'Net Fee', 'property': 'VAT', 'type': 'number' },
      ]
    }
    var curr = this.route.snapshot.queryParamMap.get('CurrentMonth');
    if (curr) {
      this.isCurrent = true;
      this.title.setTitle("Current Month Files");

      try {
        curr = JSON.parse(this.encrypt.decryptUsingAES256(curr));
        curr = JSON.parse(curr);

        this.balance = curr;
      } catch (error) {
        // console.error(error);
        // expected output: ReferenceError: nonExistentFunction is not defined
        // Note - error messages will vary depending on browser
      }
      this.Balanccoloumn = [
        { 'Name': 'Id', 'property': 'Id', 'type': 'Id' },
        { 'Name': 'File Number', 'property': 'FileNumber', 'type': 'text' },
        { 'Name': 'Client Name', 'property': 'ClientName', 'type': 'text' },
        { 'Name': 'Payment In Date', 'property': 'paymentIn', 'type': 'date' },
        { 'Name': 'Branch', 'property': 'Branch', 'type': 'text' },
        { 'Name': 'AmountIn', 'property': 'AmountIn', 'type': 'number' },
        { 'Name': 'Net Fee', 'property': 'VAT', 'type': 'number' },
      ]
    }
    var oustanding = this.route.snapshot.queryParamMap.get('OutStanding');
    if (oustanding) {
      this.isOutStanding = true;
      this.title.setTitle("OutStanding Files");

      try {
        oustanding = JSON.parse(this.encrypt.decryptUsingAES256(oustanding));
        oustanding = JSON.parse(oustanding);

        this.balance = oustanding;
      } catch (error) {
        // console.error(error);
        // expected output: ReferenceError: nonExistentFunction is not defined
        // Note - error messages will vary depending on browser
      }
      this.Balanccoloumn = [
        { 'Name': 'Id', 'property': 'Id', 'type': 'Id' },
        { 'Name': 'File Number', 'property': 'FileNumber', 'type': 'text' },
        { 'Name': 'Client Name', 'property': 'ClientName', 'type': 'text' },
        { 'Name': 'Payment In Date', 'property': 'paymentIn', 'type': 'date' },
        { 'Name': 'Branch', 'property': 'Branch', 'type': 'text' },
        { 'Name': 'AmountIn', 'property': 'AmountIn', 'type': 'number' },
        { 'Name': 'Net Fee', 'property': 'VAT', 'type': 'number' },
      ]
    }
    var outStandingBalance = this.route.snapshot.queryParamMap.get('OutStandingBalanceFiles');
    if (outStandingBalance) {
      this.isActiveFiles = true;
      this.heading = 'Out Standing Balance';
      this.title.setTitle("Out Standing Balance Files");

      try {
        var check=false;
        this.moduleName = 'Application Process';
        id = JSON.parse(this.encrypt.decryptUsingAES256(outStandingBalance));
        id = JSON.parse(id);

        this.activeFiles = id;
        if(this.activeFiles.length>0){
          this.activeFiles.forEach(el => {
            let caseWorkerList = el.caseWorkerList;
            let totalCompleted=0;
            let completed = 0;
            let total = 0;
            let checkStatus = true;
            if(caseWorkerList.length>0){
              let caseWorkerLs = [];
               let dic = {}
               caseWorkerList.forEach(element => {
                let caseWorkDic ={
                  id: element['userId_id'],
                  name: element.CaseWorkerName,
                  profilePic:element.profilePic
                }
                // if(element['userId'] in case)
                caseWorkerLs.push(caseWorkDic);
                if(Object.keys(dic).indexOf(element['userId_id'])){
                  dic[element['userId_id']]=[]
                  dic[element['userId_id']].push(caseWorkDic)
                }
                else{
                  dic[element['userId_id']].push(caseWorkDic)
                }
                if(element.status==2){
                  totalCompleted +=1;
                }

                if(element.status==1){
                  checkStatus= false;

                }
                if(element['userId']===self.user.Id ){
                  self.caseWorker =true;
                }

                if(element['caseWorkerGroupList'].length>0){
                  element['caseWorkerGroupList'].forEach(element1 => {
                    total+=1;
                    if(element1['isCompleted']){
                      completed+=1;
                    }
                  });
                }

              });

              let caseWorkerDistant =  []
        for (const [key, value] of Object.entries(dic)) {
          caseWorkerDistant.push(dic[key][0]);

        }
        el['caseWorkerProfileList']= caseWorkerDistant;
            }
            if(el['caseStatus']===0 ){
              el['FileStatus']='Active Files';
            }
            else if(el['caseStatus']===1){
              el['FileStatus']='Awaiting Decission';
            }
            if((totalCompleted==caseWorkerList.length &&
               (el["personResposibleId"]==self.user.Id || self.user.RoleId==1)&& el['caseStatus']===0)){
              el["approve"] = true
              check=true;
            }else{
              el["approve"]=false
            }

            if(checkStatus){
              let status = completed.toString() + ' / ' + total.toString();
            el['status'] = status;
            }
            else{
              el['status']= 'Waiting For Aproval';
            }

          });
        }
      } catch (error) {
        // console.error(error);
        // expected output: ReferenceError: nonExistentFunction is not defined
        // Note - error messages will vary depending on browser
      }
      this.filterFields = ['Id','fileNumber','clientName','matterName','creationTime','deadLine'
      ,'status'];
      this.coloumn = [
        { 'Name': 'Id', 'property': 'Id', 'type': 'Id' },
        { 'Name': 'File Number', 'property': 'fileNumber', 'type': 'text' },
        { 'Name': 'Client Name', 'property': 'clientName', 'type': 'text' },
        {'Name':'Matter Name','property':'matterName','type':'text'},

        {'Name':'Deadline Date','property':'deadLine','type':'date'},
        {'Name':'Status','property':'status','type':'status-bar'},
        {'Name':'File Status','property':'FileStatus','type':'bgtext'},
        { 'Name': 'OutStanding', 'property': 'outBalance', 'type': 'bgtext-1' },
        {'Name':'Case Workers','property':'caseWorkerProfileList','type':'textOrPic'},
        // {'Name':'Email','property':'email','type':'text'},
        // {'Name':'Phone Number','property':'phoneNumber','type':'text'},
        // {'Name':'Work Source','property':'workSource','type':'text'},
        // { 'Name': 'Creation Date', 'property': 'creationTime', 'type': 'date' },
        // {'Name':'Actions','property':'','type':'actions'}
        {'Name':'Actions','property':'approve',"type":"actions-with-approve"}
      ]
      // if(check){
      //   this.coloumn.pop();
      //   this.coloumn.push({'Name':'Actions','property':'approve',"type":"actions-with-approve"})
      // }
    }
    var activeFileBalance = this.route.snapshot.queryParamMap.get('ActiveFileBalance');
    if (activeFileBalance) {
      this.isActiveFiles = true;
      this.heading = 'Active Balance';
      this.title.setTitle("Active Balance Files");

      try {
        var check=false;
        this.moduleName = 'Application Process';
        id = JSON.parse(this.encrypt.decryptUsingAES256(activeFileBalance));
        id = JSON.parse(id);

        this.activeFiles = id;
        if(this.activeFiles.length>0){
          this.activeFiles.forEach(el => {
            let caseWorkerList = el.caseWorkerList;
            let totalCompleted=0;
            let completed = 0;
            let total = 0;
            let checkStatus = true;
            if(caseWorkerList.length>0){
              let caseWorkerLs = [];
               let dic = {}
               caseWorkerList.forEach(element => {
                let caseWorkDic ={
                  id: element['userId_id'],
                  name: element.CaseWorkerName,
                  profilePic:element.profilePic
                }
                // if(element['userId'] in case)
                caseWorkerLs.push(caseWorkDic);
                if(Object.keys(dic).indexOf(element['userId_id'])){
                  dic[element['userId_id']]=[]
                  dic[element['userId_id']].push(caseWorkDic)
                }
                else{
                  dic[element['userId_id']].push(caseWorkDic)
                }
                if(element.status==2){
                  totalCompleted +=1;
                }

                if(element.status==1){
                  checkStatus= false;

                }
                if(element['userId']===self.user.Id ){
                  self.caseWorker =true;
                }

                if(element['caseWorkerGroupList'].length>0){
                  element['caseWorkerGroupList'].forEach(element1 => {
                    total+=1;
                    if(element1['isCompleted']){
                      completed+=1;
                    }
                  });
                }

              });

              let caseWorkerDistant =  []
        for (const [key, value] of Object.entries(dic)) {
          caseWorkerDistant.push(dic[key][0]);

        }
        el['caseWorkerProfileList']= caseWorkerDistant;
            }
            if(el['caseStatus']===0 ){
              el['FileStatus']='Active Files';
            }
            else if(el['caseStatus']===1){
              el['FileStatus']='Awaiting Decission';
            }
            if((totalCompleted==caseWorkerList.length &&
               (el["personResposibleId"]==self.user.Id || self.user.RoleId==1)&& el['caseStatus']===0)){
              el["approve"] = true
              check=true;
            }else{
              el["approve"]=false
            }

            if(checkStatus){
              let status = completed.toString() + ' / ' + total.toString();
            el['status'] = status;
            }
            else{
              el['status']= 'Waiting For Aproval';
            }

          });
        }
      } catch (error) {
        // console.error(error);
        // expected output: ReferenceError: nonExistentFunction is not defined
        // Note - error messages will vary depending on browser
      }
      this.filterFields = ['Id','fileNumber','clientName','matterName','creationTime','deadLine'
      ,'status'];
      this.coloumn = [
        { 'Name': 'Id', 'property': 'Id', 'type': 'Id' },
        { 'Name': 'File Number', 'property': 'fileNumber', 'type': 'text' },
        { 'Name': 'Client Name', 'property': 'clientName', 'type': 'text' },
        {'Name':'Matter Name','property':'matterName','type':'text'},

        {'Name':'Deadline Date','property':'deadLine','type':'date'},
        {'Name':'Status','property':'status','type':'status-bar'},
        // {'Name':'File Status','property':'FileStatus','type':'bgtext'},
        { 'Name': 'Active Balance', 'property': 'activeBalance', 'type': 'bgtext-1' },
        {'Name':'Case Workers','property':'caseWorkerProfileList','type':'textOrPic'},
        // {'Name':'Email','property':'email','type':'text'},
        // {'Name':'Phone Number','property':'phoneNumber','type':'text'},
        // {'Name':'Work Source','property':'workSource','type':'text'},
        // { 'Name': 'Creation Date', 'property': 'creationTime', 'type': 'date' },
        // {'Name':'Actions','property':'','type':'actions'}
        {'Name':'Actions','property':'approve',"type":"actions-with-approve"}
      ]
      // if(check){
      //   this.coloumn.pop();
      //   this.coloumn.push({'Name':'Actions','property':'approve',"type":"actions-with-approve"})
      // }
    }
    var draftBalanceFiles = this.route.snapshot.queryParamMap.get('DraftBalanceFiles');
    if (draftBalanceFiles) {
      this.isActiveFiles = true;
      this.heading = 'Draft Balance';
      this.title.setTitle("Draft Balance Files");

      try {
        var check=false;
        this.moduleName = 'Application Process';
        id = JSON.parse(this.encrypt.decryptUsingAES256(draftBalanceFiles));
        id = JSON.parse(id);

        this.activeFiles = id;
        if(this.activeFiles.length>0){
          this.activeFiles.forEach(el => {
            let caseWorkerList = el.caseWorkerList;
            let totalCompleted=0;
            let completed = 0;
            let total = 0;
            let checkStatus = true;
            if(caseWorkerList.length>0){
              let caseWorkerLs = [];
               let dic = {}
               caseWorkerList.forEach(element => {
                let caseWorkDic ={
                  id: element['userId_id'],
                  name: element.CaseWorkerName,
                  profilePic:element.profilePic
                }
                // if(element['userId'] in case)
                caseWorkerLs.push(caseWorkDic);
                if(Object.keys(dic).indexOf(element['userId_id'])){
                  dic[element['userId_id']]=[]
                  dic[element['userId_id']].push(caseWorkDic)
                }
                else{
                  dic[element['userId_id']].push(caseWorkDic)
                }
                if(element.status==2){
                  totalCompleted +=1;
                }

                if(element.status==1){
                  checkStatus= false;

                }
                if(element['userId']===self.user.Id ){
                  self.caseWorker =true;
                }

                if(element['caseWorkerGroupList'].length>0){
                  element['caseWorkerGroupList'].forEach(element1 => {
                    total+=1;
                    if(element1['isCompleted']){
                      completed+=1;
                    }
                  });
                }

              });

              let caseWorkerDistant =  []
        for (const [key, value] of Object.entries(dic)) {
          caseWorkerDistant.push(dic[key][0]);

        }
        el['caseWorkerProfileList']= caseWorkerDistant;
            }
            if(el['caseStatus']===0 ){
              el['FileStatus']='Active Files';
            }
            else if(el['caseStatus']===1){
              el['FileStatus']='Awaiting Decission';
            }
            if((totalCompleted==caseWorkerList.length &&
               (el["personResposibleId"]==self.user.Id || self.user.RoleId==1)&& el['caseStatus']===0)){
              el["approve"] = true
              check=true;
            }else{
              el["approve"]=false
            }

            if(checkStatus){
              let status = completed.toString() + ' / ' + total.toString();
            el['status'] = status;
            }
            else{
              el['status']= 'Waiting For Aproval';
            }

          });
        }
      } catch (error) {
        // console.error(error);
        // expected output: ReferenceError: nonExistentFunction is not defined
        // Note - error messages will vary depending on browser
      }
      this.filterFields = ['Id','fileNumber','clientName','matterName','creationTime','deadLine'
      ,'status'];
      this.coloumn = [
        { 'Name': 'Id', 'property': 'Id', 'type': 'Id' },
        { 'Name': 'File Number', 'property': 'fileNumber', 'type': 'text' },
        { 'Name': 'Client Name', 'property': 'clientName', 'type': 'text' },
        {'Name':'Matter Name','property':'matterName','type':'text'},

        {'Name':'Deadline Date','property':'deadLine','type':'date'},
        {'Name':'Status','property':'status','type':'status-bar'},
        // {'Name':'File Status','property':'FileStatus','type':'bgtext'},
        { 'Name': 'Active Balance', 'property': 'invoiceBal', 'type': 'bgtext-1' },
        {'Name':'Case Workers','property':'caseWorkerProfileList','type':'textOrPic'},
        // {'Name':'Email','property':'email','type':'text'},
        // {'Name':'Phone Number','property':'phoneNumber','type':'text'},
        // {'Name':'Work Source','property':'workSource','type':'text'},
        // { 'Name': 'Creation Date', 'property': 'creationTime', 'type': 'date' },
        // {'Name':'Actions','property':'','type':'actions'}
        {'Name':'Actions','property':'approve',"type":"actions-with-approve"}
      ]
      // if(check){
      //   this.coloumn.pop();
      //   this.coloumn.push({'Name':'Actions','property':'approve',"type":"actions-with-approve"})
      // }
    }
    var disInFiles = this.route.snapshot.queryParamMap.get('DisInBalance');
    if (disInFiles) {
      this.isActiveFiles = true;
      this.heading = 'Disbursement In';
      this.title.setTitle("Disbursement In Files");

      try {
        var check=false;
        this.moduleName = 'Application Process';
        id = JSON.parse(this.encrypt.decryptUsingAES256(disInFiles));
        id = JSON.parse(id);

        this.activeFiles = id;
        if(this.activeFiles.length>0){
          this.activeFiles.forEach(el => {
            let caseWorkerList = el.caseWorkerList;
            let totalCompleted=0;
            let completed = 0;
            let total = 0;
            let checkStatus = true;
            if(caseWorkerList.length>0){
              let caseWorkerLs = [];
               let dic = {}
               caseWorkerList.forEach(element => {
                let caseWorkDic ={
                  id: element['userId_id'],
                  name: element.CaseWorkerName,
                  profilePic:element.profilePic
                }
                // if(element['userId'] in case)
                caseWorkerLs.push(caseWorkDic);
                if(Object.keys(dic).indexOf(element['userId_id'])){
                  dic[element['userId_id']]=[]
                  dic[element['userId_id']].push(caseWorkDic)
                }
                else{
                  dic[element['userId_id']].push(caseWorkDic)
                }
                if(element.status==2){
                  totalCompleted +=1;
                }

                if(element.status==1){
                  checkStatus= false;

                }
                if(element['userId']===self.user.Id ){
                  self.caseWorker =true;
                }

                if(element['caseWorkerGroupList'].length>0){
                  element['caseWorkerGroupList'].forEach(element1 => {
                    total+=1;
                    if(element1['isCompleted']){
                      completed+=1;
                    }
                  });
                }

              });

              let caseWorkerDistant =  []
        for (const [key, value] of Object.entries(dic)) {
          caseWorkerDistant.push(dic[key][0]);

        }
        el['caseWorkerProfileList']= caseWorkerDistant;
            }
            if(el['caseStatus']===0 ){
              el['FileStatus']='Active Files';
            }
            else if(el['caseStatus']===1){
              el['FileStatus']='Awaiting Decission';
            }
            if((totalCompleted==caseWorkerList.length &&
               (el["personResposibleId"]==self.user.Id || self.user.RoleId==1)&& el['caseStatus']===0)){
              el["approve"] = true
              check=true;
            }else{
              el["approve"]=false
            }

            if(checkStatus){
              let status = completed.toString() + ' / ' + total.toString();
            el['status'] = status;
            }
            else{
              el['status']= 'Waiting For Aproval';
            }

          });
        }
      } catch (error) {
        // console.error(error);
        // expected output: ReferenceError: nonExistentFunction is not defined
        // Note - error messages will vary depending on browser
      }
      this.filterFields = ['Id','fileNumber','clientName','matterName','creationTime','deadLine'
      ,'status'];
      this.coloumn = [
        { 'Name': 'Id', 'property': 'Id', 'type': 'Id' },
        { 'Name': 'File Number', 'property': 'fileNumber', 'type': 'text' },
        { 'Name': 'Client Name', 'property': 'clientName', 'type': 'text' },
        {'Name':'Matter Name','property':'matterName','type':'text'},

        {'Name':'Deadline Date','property':'deadLine','type':'date'},
        {'Name':'Status','property':'status','type':'status-bar'},
        // {'Name':'File Status','property':'FileStatus','type':'bgtext'},
        { 'Name': 'Disbursement In Total', 'property': 'disInTotal', 'type': 'bgtext-1' },
        {'Name':'Case Workers','property':'caseWorkerProfileList','type':'textOrPic'},
        // {'Name':'Email','property':'email','type':'text'},
        // {'Name':'Phone Number','property':'phoneNumber','type':'text'},
        // {'Name':'Work Source','property':'workSource','type':'text'},
        // { 'Name': 'Creation Date', 'property': 'creationTime', 'type': 'date' },
        // {'Name':'Actions','property':'','type':'actions'}
        {'Name':'Actions','property':'approve',"type":"actions-with-approve"}
      ]
      // if(check){
      //   this.coloumn.pop();
      //   this.coloumn.push({'Name':'Actions','property':'approve',"type":"actions-with-approve"})
      // }
    }
    var disOutFiles = this.route.snapshot.queryParamMap.get('DisOutBalance');
    if (disOutFiles) {
      this.isActiveFiles = true;
      this.heading = 'Disbursement Out';
      this.title.setTitle("Disbursement Out Files");

      try {
        var check=false;
        this.moduleName = 'Application Process';
        id = JSON.parse(this.encrypt.decryptUsingAES256(disOutFiles));
        id = JSON.parse(id);

        this.activeFiles = id;
        if(this.activeFiles.length>0){
          this.activeFiles.forEach(el => {
            let caseWorkerList = el.caseWorkerList;
            let totalCompleted=0;
            let completed = 0;
            let total = 0;
            let checkStatus = true;
            if(caseWorkerList.length>0){
              let caseWorkerLs = [];
               let dic = {}
               caseWorkerList.forEach(element => {
                let caseWorkDic ={
                  id: element['userId_id'],
                  name: element.CaseWorkerName,
                  profilePic:element.profilePic
                }
                // if(element['userId'] in case)
                caseWorkerLs.push(caseWorkDic);
                if(Object.keys(dic).indexOf(element['userId_id'])){
                  dic[element['userId_id']]=[]
                  dic[element['userId_id']].push(caseWorkDic)
                }
                else{
                  dic[element['userId_id']].push(caseWorkDic)
                }
                if(element.status==2){
                  totalCompleted +=1;
                }

                if(element.status==1){
                  checkStatus= false;

                }
                if(element['userId']===self.user.Id ){
                  self.caseWorker =true;
                }

                if(element['caseWorkerGroupList'].length>0){
                  element['caseWorkerGroupList'].forEach(element1 => {
                    total+=1;
                    if(element1['isCompleted']){
                      completed+=1;
                    }
                  });
                }

              });

              let caseWorkerDistant =  []
        for (const [key, value] of Object.entries(dic)) {
          caseWorkerDistant.push(dic[key][0]);

        }
        el['caseWorkerProfileList']= caseWorkerDistant;
            }
            if(el['caseStatus']===0 ){
              el['FileStatus']='Active Files';
            }
            else if(el['caseStatus']===1){
              el['FileStatus']='Awaiting Decission';
            }
            if((totalCompleted==caseWorkerList.length &&
               (el["personResposibleId"]==self.user.Id || self.user.RoleId==1)&& el['caseStatus']===0)){
              el["approve"] = true
              check=true;
            }else{
              el["approve"]=false
            }

            if(checkStatus){
              let status = completed.toString() + ' / ' + total.toString();
            el['status'] = status;
            }
            else{
              el['status']= 'Waiting For Aproval';
            }

          });
        }
      } catch (error) {
        // console.error(error);
        // expected output: ReferenceError: nonExistentFunction is not defined
        // Note - error messages will vary depending on browser
      }
      this.filterFields = ['Id','fileNumber','clientName','matterName','creationTime','deadLine'
      ,'status'];
      this.coloumn = [
        { 'Name': 'Id', 'property': 'Id', 'type': 'Id' },
        { 'Name': 'File Number', 'property': 'fileNumber', 'type': 'text' },
        { 'Name': 'Client Name', 'property': 'clientName', 'type': 'text' },
        {'Name':'Matter Name','property':'matterName','type':'text'},

        {'Name':'Deadline Date','property':'deadLine','type':'date'},
        {'Name':'Status','property':'status','type':'status-bar'},
        // {'Name':'File Status','property':'FileStatus','type':'bgtext'},
        { 'Name': 'Disbursement Out Total', 'property': 'disOutTotal', 'type': 'bgtext-1' },
        {'Name':'Case Workers','property':'caseWorkerProfileList','type':'textOrPic'},
        // {'Name':'Email','property':'email','type':'text'},
        // {'Name':'Phone Number','property':'phoneNumber','type':'text'},
        // {'Name':'Work Source','property':'workSource','type':'text'},
        // { 'Name': 'Creation Date', 'property': 'creationTime', 'type': 'date' },
        // {'Name':'Actions','property':'','type':'actions'}
        {'Name':'Actions','property':'approve',"type":"actions-with-approve"}
      ]
      // if(check){
      //   this.coloumn.pop();
      //   this.coloumn.push({'Name':'Actions','property':'approve',"type":"actions-with-approve"})
      // }
    }




    var filesByMatter = this.route.snapshot.queryParamMap.get('FilesByMatter');
    if (filesByMatter) {
      this.isActiveFiles = true;
      this.heading = 'Files By Matter';
      this.title.setTitle("Files By Matter");

      try {
        var check=false;
        this.moduleName = 'Application Process';
        id = JSON.parse(this.encrypt.decryptUsingAES256(filesByMatter));
        id = JSON.parse(id);

        this.activeFiles = id;
        if(this.activeFiles.length>0){
          this.activeFiles.forEach(el => {
            let caseWorkerList = el.caseWorkerList;
            let totalCompleted=0;
            let completed = 0;
            let total = 0;
            let checkStatus = true;
            if(caseWorkerList.length>0){
              let caseWorkerLs = [];
               let dic = {}
               caseWorkerList.forEach(element => {
                let caseWorkDic ={
                  id: element['userId_id'],
                  name: element.CaseWorkerName,
                  profilePic:element.profilePic
                }
                // if(element['userId'] in case)
                caseWorkerLs.push(caseWorkDic);
                if(Object.keys(dic).indexOf(element['userId_id'])){
                  dic[element['userId_id']]=[]
                  dic[element['userId_id']].push(caseWorkDic)
                }
                else{
                  dic[element['userId_id']].push(caseWorkDic)
                }
                if(element.status==2){
                  totalCompleted +=1;
                }

                if(element.status==1){
                  checkStatus= false;

                }
                if(element['userId']===self.user.Id ){
                  self.caseWorker =true;
                }

                if(element['caseWorkerGroupList'].length>0){
                  element['caseWorkerGroupList'].forEach(element1 => {
                    total+=1;
                    if(element1['isCompleted']){
                      completed+=1;
                    }
                  });
                }

              });

              let caseWorkerDistant =  []
        for (const [key, value] of Object.entries(dic)) {
          caseWorkerDistant.push(dic[key][0]);

        }
        el['caseWorkerProfileList']= caseWorkerDistant;
            }
            if(el['caseStatus']===0 ){
              el['FileStatus']='Active Files';
            }
            else if(el['caseStatus']===1){
              el['FileStatus']='Awaiting Decission';
            }
            if((totalCompleted==caseWorkerList.length &&
               (el["personResposibleId"]==self.user.Id || self.user.RoleId==1)&& el['caseStatus']===0)){
              el["approve"] = true
              check=true;
            }else{
              el["approve"]=false
            }

            if(checkStatus){
              let status = completed.toString() + ' / ' + total.toString();
            el['status'] = status;
            }
            else{
              el['status']= 'Waiting For Aproval';
            }

          });
        }
      } catch (error) {
        // console.error(error);
        // expected output: ReferenceError: nonExistentFunction is not defined
        // Note - error messages will vary depending on browser
      }
      this.filterFields = ['Id','fileNumber','clientName','matterName','creationTime','deadLine'
      ,'status'];
      this.coloumn = [
        { 'Name': 'Id', 'property': 'Id', 'type': 'Id' },
        { 'Name': 'File Number', 'property': 'fileNumber', 'type': 'text' },
        { 'Name': 'Client Name', 'property': 'clientName', 'type': 'text' },
        {'Name':'Matter Name','property':'matterName','type':'bgtext-1'},

        {'Name':'Deadline Date','property':'deadLine','type':'date'},
        {'Name':'Status','property':'status','type':'status-bar'},
        // {'Name':'File Status','property':'FileStatus','type':'bgtext'},
        {'Name':'Case Workers','property':'caseWorkerProfileList','type':'textOrPic'},
        // {'Name':'Email','property':'email','type':'text'},
        // {'Name':'Phone Number','property':'phoneNumber','type':'text'},
        // {'Name':'Work Source','property':'workSource','type':'text'},
        // { 'Name': 'Creation Date', 'property': 'creationTime', 'type': 'date' },
        // {'Name':'Actions','property':'','type':'actions'}
        {'Name':'Actions','property':'approve',"type":"actions-with-approve"}
      ]
      // if(check){
      //   this.coloumn.pop();
      //   this.coloumn.push({'Name':'Actions','property':'approve',"type":"actions-with-approve"})
      // }
    }

    var CaseWorkerFiles = this.route.snapshot.queryParamMap.get('CaseWorkerFiles');
    if (CaseWorkerFiles) {
      this.isActiveFiles = true;
      this.heading = 'CaseWorker Files';
      this.title.setTitle("CaseWorker Files");

      try {
        var check=false;
        this.moduleName = 'Application Process';
        id = JSON.parse(this.encrypt.decryptUsingAES256(CaseWorkerFiles));
        id = JSON.parse(id);

        this.activeFiles = id;
        if(this.activeFiles.length>0){
          this.activeFiles.forEach(el => {
            let caseWorkerList = el.caseWorkerList;
            let totalCompleted=0;
            let completed = 0;
            let total = 0;
            let checkStatus = true;
            if(caseWorkerList.length>0){
              let caseWorkerLs = [];
               let dic = {}
               caseWorkerList.forEach(element => {
                let caseWorkDic ={
                  id: element['userId_id'],
                  name: element.CaseWorkerName,
                  profilePic:element.profilePic
                }
                // if(element['userId'] in case)
                caseWorkerLs.push(caseWorkDic);
                if(Object.keys(dic).indexOf(element['userId_id'])){
                  dic[element['userId_id']]=[]
                  dic[element['userId_id']].push(caseWorkDic)
                }
                else{
                  dic[element['userId_id']].push(caseWorkDic)
                }
                if(element.status==2){
                  totalCompleted +=1;
                }

                if(element.status==1){
                  checkStatus= false;

                }
                if(element['userId']===self.user.Id ){
                  self.caseWorker =true;
                }

                if(element['caseWorkerGroupList'].length>0){
                  element['caseWorkerGroupList'].forEach(element1 => {
                    total+=1;
                    if(element1['isCompleted']){
                      completed+=1;
                    }
                  });
                }

              });

              let caseWorkerDistant =  []
        for (const [key, value] of Object.entries(dic)) {
          caseWorkerDistant.push(dic[key][0]);

        }
        el['caseWorkerProfileList']= caseWorkerDistant;
            }
            if(el['caseStatus']===0 ){
              el['FileStatus']='Active Files';
            }
            else if(el['caseStatus']===1){
              el['FileStatus']='Awaiting Decission';
            }
            if((totalCompleted==caseWorkerList.length &&
               (el["personResposibleId"]==self.user.Id || self.user.RoleId==1)&& el['caseStatus']===0)){
              el["approve"] = true
              check=true;
            }else{
              el["approve"]=false
            }

            if(checkStatus){
              let status = completed.toString() + ' / ' + total.toString();
            el['status'] = status;
            }
            else{
              el['status']= 'Waiting For Aproval';
            }

          });
        }
      } catch (error) {
        // console.error(error);
        // expected output: ReferenceError: nonExistentFunction is not defined
        // Note - error messages will vary depending on browser
      }
      this.filterFields = ['Id','fileNumber','clientName','matterName','creationTime','deadLine'
      ,'status'];
      this.coloumn = [
        { 'Name': 'Id', 'property': 'Id', 'type': 'Id' },
        { 'Name': 'File Number', 'property': 'fileNumber', 'type': 'text' },
        { 'Name': 'Client Name', 'property': 'clientName', 'type': 'text' },
        {'Name':'Matter Name','property':'matterName','type':'text'},
        {'Name':'Pending Balance','property':'outBalance','type':'bgtext-1'},

        {'Name':'Deadline Date','property':'deadLine','type':'date'},
        {'Name':'Status','property':'status','type':'status-bar'},
        // {'Name':'File Status','property':'FileStatus','type':'bgtext'},
        {'Name':'Case Workers','property':'caseWorkerProfileList','type':'textOrPic'},
        // {'Name':'Email','property':'email','type':'text'},
        // {'Name':'Phone Number','property':'phoneNumber','type':'text'},
        // {'Name':'Work Source','property':'workSource','type':'text'},
        // { 'Name': 'Creation Date', 'property': 'creationTime', 'type': 'date' },
        // {'Name':'Actions','property':'','type':'actions'}
        {'Name':'Actions','property':'approve',"type":"actions-with-approve"}
      ]
      // if(check){
      //   this.coloumn.pop();
      //   this.coloumn.push({'Name':'Actions','property':'approve',"type":"actions-with-approve"})
      // }
    }


    var VISA = this.route.snapshot.queryParamMap.get('VISA');
    if (VISA) {
      this.isActiveFiles = true;
      this.heading = 'VISA Files';
      this.title.setTitle("VISA Files");

      try {
        var check=false;
        this.moduleName = 'Application Process';
        id = JSON.parse(this.encrypt.decryptUsingAES256(VISA));
        id = JSON.parse(id);

        this.activeFiles = id;
        if(this.activeFiles.length>0){
          this.activeFiles.forEach(el => {
            let caseWorkerList = el.caseWorkerList;
            let totalCompleted=0;
            let completed = 0;
            let total = 0;
            let checkStatus = true;
            if(caseWorkerList.length>0){
              let caseWorkerLs = [];
               let dic = {}
               caseWorkerList.forEach(element => {
                let caseWorkDic ={
                  id: element['userId_id'],
                  name: element.CaseWorkerName,
                  profilePic:element.profilePic
                }
                // if(element['userId'] in case)
                caseWorkerLs.push(caseWorkDic);
                if(Object.keys(dic).indexOf(element['userId_id'])){
                  dic[element['userId_id']]=[]
                  dic[element['userId_id']].push(caseWorkDic)
                }
                else{
                  dic[element['userId_id']].push(caseWorkDic)
                }
                if(element.status==2){
                  totalCompleted +=1;
                }

                if(element.status==1){
                  checkStatus= false;

                }
                if(element['userId']===self.user.Id ){
                  self.caseWorker =true;
                }

                if(element['caseWorkerGroupList'].length>0){
                  element['caseWorkerGroupList'].forEach(element1 => {
                    total+=1;
                    if(element1['isCompleted']){
                      completed+=1;
                    }
                  });
                }

              });

              let caseWorkerDistant =  []
        for (const [key, value] of Object.entries(dic)) {
          caseWorkerDistant.push(dic[key][0]);

        }
        el['caseWorkerProfileList']= caseWorkerDistant;
            }
            if(el['caseStatus']===0 ){
              el['FileStatus']='Active Files';
            }
            else if(el['caseStatus']===1){
              el['FileStatus']='Awaiting Decission';
            }
            if((totalCompleted==caseWorkerList.length &&
               (el["personResposibleId"]==self.user.Id || self.user.RoleId==1)&& el['caseStatus']===0)){
              el["approve"] = true
              check=true;
            }else{
              el["approve"]=false
            }

            if(checkStatus){
              let status = completed.toString() + ' / ' + total.toString();
            el['status'] = status;
            }
            else{
              el['status']= 'Waiting For Aproval';
            }

          });
        }
      } catch (error) {
        // console.error(error);
        // expected output: ReferenceError: nonExistentFunction is not defined
        // Note - error messages will vary depending on browser
      }
      this.filterFields = ['Id','fileNumber','clientName','matterName','creationTime','deadLine'
      ,'status'];
      this.coloumn = [
        { 'Name': 'Id', 'property': 'Id', 'type': 'Id' },
        { 'Name': 'File Number', 'property': 'fileNumber', 'type': 'text' },
        { 'Name': 'Client Name', 'property': 'clientName', 'type': 'text' },
        {'Name':'Matter Name','property':'matterName','type':'text'},
        // {'Name':'Pending Balance','property':'outBalance','type':'bgtext-1'},

        // {'Name':'Deadline Date','property':'deadLine','type':'date'},
        {'Name':'Status','property':'grantOrReject','type':'bgtext-1'},
        // {'Name':'File Status','property':'FileStatus','type':'bgtext'},
        {'Name':'Case Workers','property':'caseWorkerProfileList','type':'textOrPic'},
        // {'Name':'Email','property':'email','type':'text'},
        // {'Name':'Phone Number','property':'phoneNumber','type':'text'},
        // {'Name':'Work Source','property':'workSource','type':'text'},
        // { 'Name': 'Creation Date', 'property': 'creationTime', 'type': 'date' },
        // {'Name':'Actions','property':'','type':'actions'}
        {'Name':'Actions','property':'approve',"type":"actions-with-approve"}
      ]
      // if(check){
      //   this.coloumn.pop();
      //   this.coloumn.push({'Name':'Actions','property':'approve',"type":"actions-with-approve"})
      // }
    }



    var allCases = this.route.snapshot.queryParamMap.get('all-cases');
    if (allCases) {
      this.isActiveFiles = true;
      this.heading = 'Cases Need Actions';
      this.title.setTitle("Case Need Actions Files");

      try {
        var check=false;
        this.moduleName = 'Application Process';
        id = JSON.parse(this.encrypt.decryptUsingAES256(allCases));
        id = JSON.parse(id);

        this.activeFiles = id;

      } catch (error) {
        // console.error(error);
        // expected output: ReferenceError: nonExistentFunction is not defined
        // Note - error messages will vary depending on browser
      }
      this.filterFields = ['Id','fileNumber','clientName','matterName','creationTime','deadLine'
      ,'status'];
      this.coloumn = [
        { 'Name': 'Id', 'property': 'Id', 'type': 'Id' },
        { 'Name': 'File Number', 'property': 'fileNumber', 'type': 'text' },
        { 'Name': 'Client Name', 'property': 'clientName', 'type': 'text' },
        {'Name':'Matter Name','property':'matterName','type':'text'},
        {'Name':'Deadline Date','property':'deadLine','type':'date'},
        {'Name':'Stage','property':'status','type':'bgtext-1'},
        {'Name':'Actions','property':'approve',"type":"actions"}
      ]
    }


    var clientAmount = this.route.snapshot.queryParamMap.get('clientAmount');

    if (clientAmount) {
      this.isActiveFiles = true;
      this.heading = 'Pending Client to office transfers';
      this.title.setTitle("Pending Client to Office Transfers");

      try {
        var check=false;
        this.moduleName = 'Application Process';
        id = JSON.parse(this.encrypt.decryptUsingAES256(clientAmount));
        id = JSON.parse(id);

        this.activeFiles = id;
        if(this.activeFiles.length>0){
          this.activeFiles.forEach(el => {
            let caseWorkerList = el.caseWorkerList;
            let totalCompleted=0;
            let completed = 0;
            let total = 0;
            let checkStatus = true;
            if(caseWorkerList.length>0){
              let caseWorkerLs = [];
               let dic = {}
               caseWorkerList.forEach(element => {
                let caseWorkDic ={
                  id: element['userId_id'],
                  name: element.CaseWorkerName,
                  profilePic:element.profilePic
                }
                // if(element['userId'] in case)
                caseWorkerLs.push(caseWorkDic);
                if(Object.keys(dic).indexOf(element['userId_id'])){
                  dic[element['userId_id']]=[]
                  dic[element['userId_id']].push(caseWorkDic)
                }
                else{
                  dic[element['userId_id']].push(caseWorkDic)
                }
                if(element.status==2){
                  totalCompleted +=1;
                }

                if(element.status==1){
                  checkStatus= false;

                }
                if(element['userId']===self.user.Id ){
                  self.caseWorker =true;
                }

                if(element['caseWorkerGroupList'].length>0){
                  element['caseWorkerGroupList'].forEach(element1 => {
                    total+=1;
                    if(element1['isCompleted']){
                      completed+=1;
                    }
                  });
                }

              });

              let caseWorkerDistant =  []
        for (const [key, value] of Object.entries(dic)) {
          caseWorkerDistant.push(dic[key][0]);

        }
        el['caseWorkerProfileList']= caseWorkerDistant;
            }
            if((totalCompleted==caseWorkerList.length && (el["personResposibleId"]==self.user.Id || self.user.RoleId==1)&& el['caseStatus']===0)){
              el["approve"] = true
              check=true;
            }else{
              el["approve"]=false
            }

            if(checkStatus){
              let status = completed.toString() + ' / ' + total.toString();
            el['status'] = status;
            }
            else{
              el['status']= 'Waiting For Aproval';
            }

          });
        }
      } catch (error) {
        // console.error(error);
        // expected output: ReferenceError: nonExistentFunction is not defined
        // Note - error messages will vary depending on browser
      }
      this.filterFields = ['Id','fileNumber','clientName','matterName','creationTime','deadLine'
      ,'status'];
      this.coloumn = [
        { 'Name': 'Id', 'property': 'Id', 'type': 'Id' },
        { 'Name': 'File Number', 'property': 'fileNumber', 'type': 'text' },
        { 'Name': 'Client Name', 'property': 'clientName', 'type': 'text' },
        {'Name':'Matter Name','property':'matterName','type':'text'},
        {'Name':'Deadline Date','property':'deadLine','type':'date'},
        {'Name':'Amount','property':'clientAmount','type':'bgtext-1'},
        {'Name':'Case Workers','property':'caseWorkerProfileList','type':'textOrPic'},
        // {'Name':'Email','property':'email','type':'text'},
        // {'Name':'Phone Number','property':'phoneNumber','type':'text'},
        // {'Name':'Work Source','property':'workSource','type':'text'},
        // { 'Name': 'Creation Date', 'property': 'creationTime', 'type': 'date' },
        // {'Name':'Actions','property':'','type':'actions'}
        {'Name':'Actions','property':'approve',"type":"actions-with-approve"}
      ]
      // if(check){
      //   this.coloumn.pop();
      //   this.coloumn.push({'Name':'Actions','property':'approve',"type":"actions-with-approve"})
      // }
    }




    loaderActivation.loader = false;


  }

  ngOnInit() {
// console.log(history.state);
    this.getData();
    if (!this.isActiveFiles && !this.isAwaitingFiles && !this.isITticket && !this.isIncomplete && !this.isIncompleteTicket && !this.isPending
      && !this.isRetainer && !this.isSolvedTicket && !this.islate && this.isPprev && this.isCurrent && this.isOutStanding) {
      this.router.navigateByUrl("/error404");
    }
  }
  approveCompletion(data){
    var reject = false;
        if(data['visaExpiry']){
          reject = false;
        }else{
          reject = true
        }
        this._dialog.open(CaseListModal,{
          width:'800px',
          height:'600px',
          disableClose: true ,
          data: {
            case:data,
            reject:reject,
            isArchive:true,
            isEditable:true
          }
        }).afterClosed().subscribe(async (result) => {

      //     var self = this;
      //     Swal.fire({
      //       title: 'Are you sure to archive this?',
      //       text: "You won't be able to revert this!",
      //       icon: 'warning',
      //       showCancelButton: true,
      //       confirmButtonColor: '#3085d6',
      //       cancelButtonColor: '#d33',
      //       confirmButtonText: 'Yes'
      //     }).then((result) => {
      //       if (result.isConfirmed) {
      //     data['caseStatus']=3
      //     data['addedByUserId']=User.Id;
      //     self.caseService.PostCase(data).subscribe(function(resp){
      //       Swal.fire(
      //         'Update!',
      //         'Case has been updated.',
      //         'success'
      //       )
      //     })
      //   }
      // });
    });
      }
  grant(data){
    this._dialog.open(CaseListModal,{
    width:'400px',
    height:'450px',
    disableClose: true ,
    data: {
      case:data,
      reject:false,
      isEditable:true,
    }
  }).afterClosed().subscribe(async (result) => {
});
}
Reject(data){
  this._dialog.open(CaseListModal,{
    width:'400px',
    disableClose: true ,
    height:'450px',
    data: {
      case:data,
      reject:true,
      isEditable:true
    }
  }).afterClosed().subscribe(async (result) => {
});
}
  approveClick(data){
    var self = this;
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.isConfirmed) {
    data['caseStatus']=1
    data['addedByUserId']=User.Id;
    self.caseService.PostCase(data).subscribe(function(resp){
      // self.getData();
      Swal.fire(
        'Update!',
        'Case has been updated.',
        'success'
      )
    })
  }
});
  }
  DashboardNavigate() {
    this.router.navigateByUrl(AppSettings.DASHBOARD_URL);
  }
  async deleteSelectedActiveFiles(checkL) {
    await checkL.forEach(element => {
      let toSetValues = this.activeFiles.find(x => x.Id === element);
      toSetValues['isDeleted'] = true;
      toSetValues["rolePermission"] = []
      const promise = this.caseService.PostCase(toSetValues).toPromise()
      promise.then(Response => {
        Swal.fire("Success", "Cases deleted successfully", "success");
      }, error => {
        Swal.fire("Error", "Something is not right", "error");
      })
    });
    this.getData();
    Swal.fire("Success", "Leads deleted successfully", "success");
    $("#delete_selected_role").modal("hide");
  }
  deleteCaseActiveFiles(id) {
    let toSetValues = this.activeFiles.find(x => x.Id === id);
    toSetValues['isDeleted'] = true;
    toSetValues['addedByUserId'] = User.Id;
    this.caseService.PostCase(toSetValues).subscribe(Response => {
      Swal.fire("Success", "Case deleted successfully", "success");
      // this.tempId = -1;

      this.getData();
      $("#delete_role").modal("hide");
    }, error => {
      // console.log("",error.toString(),"error");
      Swal.fire("Error", "Something is not right", "error");
    })
  }
  editActiveFiles(e){

    // let toSetValues = e;
    let toSetValues;
      if(Number.isInteger(e)){
        toSetValues =  e;
      }
      else{
        toSetValues =  e.Id;
      }
      const encId = this.encryptionDecryptionService.encrypt(toSetValues.toString());
      const param = { queryParams: { row: encId } };
      this.router.navigate([AppSettings.Case_View_URL],param);
      // const url = this.router.serializeUrl(this.router.createUrlTree([AppSettings.Case_View_URL],param));
      // window.open(url);
  //  this._dialog.open(CaseWorkerDetailModal,{width:'800px',
  //   height:'768px',
  //   disableClose: true ,
  //   data: {
  //   "data": e,
  //   "disabled":false
  //   }
  // }).afterClosed().subscribe(async (result) => {
  // });
  }

}
