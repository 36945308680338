import { SocketioService } from 'src/app/Services/socketio.service';

import { PermissionService } from './../../Services/PermissionServices.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppSettings } from 'src/app/shared/app.settings';
import { BranchServiceProxy } from './../../Services/branchServiceProxy.service';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Component, OnInit, ViewChild } from "@angular/core";
import { User } from 'src/environments/User';
import Swal from 'sweetalert2';
import { DataTableDirective } from 'angular-datatables';
import { loaderActivation } from 'src/environments/loaderActivation';
import { Location } from '@angular/common';

declare const $: any;
@Component({
  selector: "branches",
  templateUrl: "./branches.component.html",

})
export class branchesComponent implements OnInit {
  dtOptions: DataTables.Settings = {};
  @ViewChild(DataTableDirective, { static: false })
  public dtElement: DataTableDirective;
loader:any=false;
    public allBranches:any = [];
    public tempId: any;
    public addBranchGroup: FormGroup;
    public editBranchForm: FormGroup;
    public coloumn : any;
    moduleName: string;
 public dtTrigger: Subject<any> = new Subject();

    constructor(private titleservice:Title,private route:Router,
      private branchService:BranchServiceProxy, private formBuilder: FormBuilder
      ,private permission:PermissionService,
      private location: Location,
      private socketService:SocketioService) {
        this.moduleName = 'Branches';

       }
       backBtn(){
        this.location.back();
      }
       DashboardNavigate()
       {
         this.route.navigateByUrl(AppSettings.DASHBOARD_URL);
       }
      async getData(){
        this.loader = true;
        loaderActivation.loader=true;
        this.allBranches = [];

           let self = this;
           const promise =   this.branchService.getAllData().toPromise();
      await promise.then(function(response){
            // console.log(response,"branches");
            self.allBranches = response['Branch'];

        },error=>{
          // console.log("error",error)
        });
        // setTimeout(() => {
        //   this.loader = false;
        // }, 1000);
        this.coloumn = [
          {'Name':'Id','property':'Id','type':'Id'},
          {'Name':'Name','property':'branchName','type':'text'},
          {'Name':'Actions','property':'','type':'actions'}
        ]
        this.loader = false;
        loaderActivation.loader=false;
    }


    user:any;
    ngAfterViewInit(): void {
        setTimeout(() => {
          this.dtTrigger.next();
        }, 1000);
      }
      checkPermission=(name,key)=>{
        var ex = this.permission.checkVisibilityByModuleName(name,key,User.BranchId)

        return ex
      }
    //  async rerender() {
    //   $("#datatable").DataTable().clear();


    //   this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
    //     // dtInstance.clear().draw();
    //     dtInstance.destroy();

    //     // setTimeout(() => {
    //     //   this.dtTrigger.next();
    //     // }, 100);

    //   });
    //     this.allBranches = [];
    //    await this.getData();

    //       this.dtTrigger.next();

    //   }
      ngOnInit() {
        this.getData();
        this.socketService.userSocket.onmessage=(msg)=>{
          console.log(msg)
          // this.rerender();
      }

      this.socketService.userSocket.onerror = (error) =>
          console.log(`The server received: ${error['code']}`
          );
          this.socketService.userSocket.onclose = (why) =>
          console.log(`The server received: ${why.code} ${why.reason}`
          );
        this.user=User;
        var per = this.checkPermission("Branches","Visiblity")

        if(!per){
            this.route.navigateByUrl("error401");
        }
        this.titleservice.setTitle("Branches");
        // this.dtOptions = {
        //     // ... skipped ...
        //     pageLength: 10,
        //     dom: "lrtip",
        //   };
          this.addBranchGroup = this.formBuilder.group({
            Id: [0],
            branchName: ["", [Validators.required]],

          });

          this.editBranchForm = this.formBuilder.group({
            Id: [0],
            branchName: ["", [Validators.required]],

          });
    }
    edit(value) {
      $("#edit_branch").appendTo("body");
      $('#edit_branch').modal('show');
      let toSetValues = value;
      if(Number.isInteger(value)){
        toSetValues = this.allBranches.find(x=>x.Id=== value);

      }
      else{
        toSetValues = this.allBranches.find(x=>x.Id=== value.Id);

      }



        this.editBranchForm = this.formBuilder.group({
            Id: toSetValues.Id,
            branchName:toSetValues.branchName,


            // isActive:toSetValues.fields.isActive
        });
      }
    addBranch=()=>{

        if(this.addBranchGroup.valid){
            let data = this.addBranchGroup.value;
            data = JSON.stringify(data);
            data = JSON.parse(data);
            data["isDeleted"]=false;
            data["addedByUserId"]= User.Id;
       this.branchService.PostBranch(data).subscribe(Response=>{
        Swal.fire("Success","Branch created successfully","success");
        this.addBranchGroup.reset();
        if (   this.socketService.userSocket.readyState === WebSocket.CLOSED ||this.socketService.userSocket.readyState ===WebSocket.CLOSING ) {
          this.socketService.setupUserConnetion()
        }
        this.socketService.sendUserData()
        $('#add_branch').modal('hide');
        this.getData();
       },error=>{
        Swal.fire("Error",error.error.error,"error");
       })

        }
    }
    createBranch(){
      $("#add_branch").appendTo("body");
      $('#add_branch').modal('show');
      this.addBranchGroup.reset();
    }
    editBranch(){
        if(this.editBranchForm.valid){
            let data = this.editBranchForm.value;
            data = JSON.stringify(data);
            data = JSON.parse(data);
            data["isDeleted"]=false;
            data["addedByUserId"] = User.Id;
       this.branchService.PostBranch(data).subscribe(Response=>{
        Swal.fire("Success","Branch updated successfully","success");
        this.editBranchForm.reset();
        this.getData();
        // this.rerender();
        if (   this.socketService.userSocket.readyState === WebSocket.CLOSED ||this.socketService.userSocket.readyState ===WebSocket.CLOSING ) {
          this.socketService.setupUserConnetion()
        }
        this.socketService.sendUserData()
        $('#edit_branch').modal('hide');
       },error=>{Swal.fire("Error",error.error.error,"error");
       })

        }
    }
    checkList:any=[]
    clearList(){
      this.checkList=[]

    }
    SelectAll(){
      this.allBranches.forEach(element => {
        if(element.Id !=1){
        this.checkList.push(element.Id)
        }
      });
    }
    Check(Id){
      const index = this.checkList.indexOf(Id);
      if (index > -1) {return true}
      else{
        return false
      }
    }
    checkChange(e,Id){
      if(e.target.checked){
        this.checkList.push(Id)
      }else{
        const index = this.checkList.indexOf(Id);
        if (index > -1) {
          this.checkList.splice(index, 1);
        }

      }
    }
    deleteBranch(id){
        let toSetValues = this.allBranches.find(x=>x.Id=== id);
        toSetValues['isDeleted']=true;
        toSetValues["addedByUserId"] = User.Id;
        this.branchService.PostBranch(toSetValues).subscribe(Response=>{
            Swal.fire("Success","Branch deleted successfully","success");
            this.tempId=-1;
            this.getData();
            // this.rerender();
         $("#delete_branch").modal("hide");
           },error=>{
            // console.log("",error.toString(),"error");
            Swal.fire("Error","Something is not right","error");
           })
    }
   async deleteSelected(checkL){
     await checkL.forEach(element => {
        let toSetValues = this.allBranches.find(x=>x.Id=== element);
        toSetValues['isDeleted']=true;
        toSetValues["rolePermission"] = [];
        toSetValues["addedByUserId"] = User.Id;
        const promise = this.branchService.PostBranch(toSetValues).toPromise();
        promise.then(Response=>{

            this.tempId=-1;
            this.getData();


           },error=>{
            // console.log("",error.toString(),"error");
            Swal.fire("Error","Something is not right","error");
           })
      });
      // this.rerender();
      this.checkList=[];
      this.getData();
      Swal.fire("Success","Branches deleted successfully","success");
      // $("#delete_selected_branch").modal("hide");
    }
}
