export  class AppSettings {
  // Encryption - Decryption Key
  public static ENCRYPTION_DECRYPTION_KEY = 'SOME SECRETE KEY';

  // URLS
  public static BASE_URL = 'app/home';
  public static DASHBOARD_URL = '/dashboard';
  public static ApplicationProcessDetailed_URL = '/application-process-detail';
  public static MarketingLeadForm_URL = '/marketing-lead-form';
  public static DraftedLeadForm_URL = '/drafted-lead-form';
  public static DetailLeadForm_URL = '/detail-lead-form';
  public static LeadForm_URL = '/lead';
  public static ApplicationProcess_URL = '/application-process';
  public static Retainer_URL = '/retainer';
  public static Case_View_URL = '/to-do/cases/case-view';
  public static Case_URL = '/to-do/cases';
}
