export const User = {
    Name:"",
    Id:0,
    Email:"",
    RoleId:0,
    RoleName:"",
    isLoggedin:false,
    Permissions:[],
    Branches:[],
    BranchId:0,
    creationTime:null,
    profilePic:null

  };
