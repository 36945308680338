import { Component, OnInit, ViewChild } from "@angular/core";




@Component({
  selector:"calender",
  templateUrl:'./calender.componet.html',
})

export class CalenderComponent implements OnInit{
  constructor(){

  }
  ngOnInit(){

  }
}
