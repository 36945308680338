import { Component, OnInit, ViewChild } from "@angular/core";




@Component({
  selector:"support",
  templateUrl:'./support.componet.html',
})

export class SupportComponent implements OnInit{
  constructor(){

  }
  ngOnInit(){

  }
}
