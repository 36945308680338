import { tap, shareReplay } from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { environment } from './../../environments/environment';
import { HttpClient, HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';



@Injectable()
export class commentService{

    private apiRoot = environment.apiURL;

    constructor(private http: HttpClient) { }
    PostComment(data){
        return this.http.post(this.apiRoot.concat('comments/'),data).pipe(
          tap(response =>{
    
            }),
          shareReplay(),
        );
      }

}