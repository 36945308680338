import { DatePipe } from "@angular/common";
import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { DocumentGeneratorServiceProxy } from "src/app/Services/documentGeneratorServiceProxy.service";
import { AppSettings } from "src/app/shared/app.settings";
import Swal from "sweetalert2";



@Component({
  selector:"ec-spouse-employed-male",
  templateUrl:'./ec-spouse-employed-male.component.html',
})

export class EcSpouseEmployedMaleComponent implements OnInit{
  options= ['A','B','C','D'];
  contact = ['UPLOADED TO TLS CONTACT','UPLOADED TO VFS GLOBAL','POSTED TO UKVI SCANNING HUB IN NEW YORK- ASC\nPOSTED TO UKVI SCANNING HUB IN NEW YORK- ASC']
  applicantVisaStatus= ['British Citizen','person with Indefinate leave to remain'];
  marraigeType= ['Arrange Marraige in family',
  'Love at First Sight',
  'Arrange Marraige not in family',
  'Work Commitments',
  'Other'];
  salaryStatus=['Salaried','Not Salaried'];
  typeOfDocument= ['Tenancy Agreement,','Land Registry Document and Property Inspection Report'];
  age65=['The Applicant is aged 65 and over','']
  selectedOption:string;
  showForm = false;
  warnColor = 'warn';
  tableItems= [];
  reasonNotToStayTogether=[
    {name:'COVID-19 pandemic',value:'COVID-19 pandemic;'},
    {name:'annual leave from work and travel costs',value:'annual leave from work and travel costs;'},
    {name:'N/A',value:''},
  ];
  documentItems= [];
  coupleFreetoMarryLs = ['The requirement in E-ECP.2.9 does not apply to the Applicant','The ??? previous relationship has permanently broken down and ???? therefore, meet the requirements of E-ECP.2.9']
  andOR = ['Applicant and their sponsor’s','Sponsor’s','Applicant’s'];
  sheThey = ['he','she','they'];
  finanalrequirement = ['£18,600',
  '£22,400 for the Applicant and a child',
  '£24,800 for the Applicant and two children',
  '£27,200 for the Applicant and three children',
  '£29,600 for the Applicant and four children',
  '£32,000 for the Applicant and five children',
  '£34,400 for the Applicant and six children',
  '£36,800 for the Applicant and seven children',
];
  coupleFreeToMarry = "";
  marraigeDes= "";
  englishCriteria = "";
  eligibilitySpouse= "";
  label=['Application','Relationship','Financial','Accommodation and maintenance','Other documents'];
  criteria=['The applicant has completed an approved SELT test for UKVI in English, showing proficiency in speaking and listening, at the minimum Level A1',
'The Applicant completed studies in a UK educational establishment',
'The Applicant meets the English language requirement and has provided a confirmation from UK ENIC, which shows that the overseas qualification that is held, meets the requirements',
'The Applicant is exempt from the English language requirement as he is aged 65 or over at the date of application',
'The applicant has a physical or mental condition which prevents them from meeting the requirement and is therefore exempt under E-ECP4.'];
  public addApplication: FormGroup;

  constructor(private router: Router,
    private formBuilder: FormBuilder,
    public datepipe: DatePipe,
    private documentGenratorService: DocumentGeneratorServiceProxy){
    this.addApplication = this.formBuilder.group({
      Id: [0],
      type:[""],
      uniqueApplicationReference: [""],
      ourReference: [""],
      email: [""],
      personVisaSatus: [""],
      applicantName: [""],
      dob: new Date(),
      nationality:[""],
      sponserName: [""],
      sponserDob:new Date(),
      sponserNationality:[""],
      dateOfMarraige:new Date(),
      fullNameOfApplicant:[""],
      nameOfPartner:[""],
      statusOfPartner:[""],
      countryOfApplicant: [""],
      bCity:[""],
      bMarried: [""],
      bDateOfMarriage: new Date(),
      bMonthYearOfMeet: new Date(),
      bSurroundedBy: [""],
      bReturnDateOfApplicant:new Date(),
      bContactMade: [""],
      applicantMarriedCountry:[""],
      marraigeType:[""],
      sponserReturnToUk:new Date(),
      other:[""],
      nameOfEmployer:[''],
      anualGrossPay:[''],
      salarystatus:[''],
      fullRAdrees:[''],
      postalCode:[''],
      qualification:[''],
      university:[''],
      typeofdocument:[''],
      criteria:[''],
      spouseStatus:[''],
      spouseNationality:[''],
      ageover65:[''],
      coupleFreeToMarry:[''],
      andor:[''],
      shethey:[''],
      financialRequirement:[''],
      reasonNotToStayTogether:['']
    });
  }
  ngOnInit(){

  }
  DashboardNavigate()
  {
    this.router.navigateByUrl(AppSettings.DASHBOARD_URL);
  }
  createDocument(){

      let data = this.addApplication.value;
      data = JSON.stringify(data);
      data = JSON.parse(data);
      data['marraigeDes'] = this.marraigeDes;
      data['tableItems'] = this.tableItems;
      data['documentItems'] = this.documentItems;
      data['englishCriteria'] = this.englishCriteria;
      data['eligibilitySpouse'] = this.eligibilitySpouse;
      data['coupleFreeToMarry'] = this.coupleFreeToMarry;
    this.documentGenratorService.Post(data).subscribe(Response => {
      let doc = Response['doc'];

      Swal.fire("Success", "Document created successfully", "success");
      const linkSource = doc.filePath;
      const downloadLink = document.createElement("a");
      downloadLink.href = linkSource;
      downloadLink.download = doc.fileName;
      downloadLink.click();
    }, error => {
      Swal.fire("Error", error.error.error, "error");
    })

  }
  changeEnglishCriteria(){
    let value = this.addApplication.get('criteria').value;
    this.englishCriteria= value;
  }
  changeSpouseStatus(){
    let value = this.addApplication.get('spouseStatus').value;
    this.eligibilitySpouse = value;
  }
  changeSpouseNationality(){
    let value = this.addApplication.get('spouseNationality').value;
    this.eligibilitySpouse = value+ " citizen with indefinite leave to remain";
  }
  qualifUniChange(){
      let qualification = this.addApplication.get('qualification').value;
      let university = this.addApplication.get('university').value;
      this.englishCriteria = "The Applicant completed studies in a UK educational establishment. The Applicant completed the studies in "+ qualification+" at the "+university+". He provides his university certificate and transcript."
  }
  optionChange(){
    this.showForm = true;

  }
  addNewListItem(){
    let item = {
      Id: 1,
      Month:'',
      GrossPay:''
    }
    this.tableItems.push(item);
  }
   addNewDocumentListItem(){
    let item = {
      Id: 1,
      label:'Application',
      text:'.'
    }
    this.documentItems.push(item);
  }
  _onLabelChangeList(val, index) {
    this.documentItems[index].label ="";
    this.documentItems[index].label =val;

  }
  _onTextChangeList(val, index){
    this.documentItems[index].text ="";
    this.documentItems[index].text =val;
  }
  _selectDeleteDocument(index){
    this.documentItems.splice(index,1);
  }
  _onMonthChangeList(val, index) {
    this.tableItems[index].Month ="";
    this.tableItems[index].Month =val;

  }
  _onGrossPayChangeList(val, index){
    this.tableItems[index].GrossPay ="";
    this.tableItems[index].GrossPay =val;
  }
  _selectDelete(index){
    this.tableItems.splice(index,1);
  }
  coupleChange(){
    this.coupleFreeToMarry = this.addApplication.get('coupleFreeToMarry').value;
  }
  changeAndor(){
    let andor = this.addApplication.get('andor').value;
    let shethey = this.addApplication.get('shethey').value;
    this.coupleFreeToMarry ='The ' +andor+ ' previous relationship has permanently broken down and '+shethey+' therefore, meet the requirements of E-ECP.2.9'
  }
  marraigeTypeChange(){
    let value = this.addApplication.get('marraigeType').value;
    if(value=="Arrange Marraige in family"){
      this.marraigeDes = "The Applicant and Sponsor’s marriage was an arranged marriage by their families. The applicant and his spouse are related outside the marriage and are cousins. The applicant and sponsor are not in a prohibited degree of relationship together"
    }
    else if(value=="Arrange Marraige not in family"){
      this.marraigeDes = "The couple got married surrounded by their friends and family."

    }
  }
  DateChangeOfLoveMarraige(month,year){

    this.marraigeDes = "The Applicant first met his wife in "+month+"-"+year+". It was love at first sight";
  }
  DateChangeOfSponserReturn(date){
    this.marraigeDes = "Due to work commitments the Sponsor returned to the UK on "+ date+" . However, the Sponsor maintains regular contact with the Applicant through social media, calls and messages.";
  }
  otherChange(){
    let value = this.addApplication.get('other').value;
    this.marraigeDes = value;
  }
  onOpenCalendar(container) {
    container.monthSelectHandler = (event: any): void => {
      container._store.dispatch(container._actions.select(event.date));
    };
    container.setViewMode('month');
   }

  ngAfterViewInit() {
    this.addApplication.get("bMonthYearOfMeet").valueChanges.subscribe(x => {
      let month = x.getMonth() +1;
      let year = x.getFullYear();
      this.DateChangeOfLoveMarraige(month,year);
   })

   this.addApplication.get("sponserReturnToUk").valueChanges.subscribe(x => {
    let val =this.datepipe.transform(x, 'dd-MM-yyyy');
    this.DateChangeOfSponserReturn(val);
 })
 this.addApplication.get("sponserReturnToUk").valueChanges.subscribe(x => {
  let val =this.datepipe.transform(x, 'dd-MM-yyyy');
  this.DateChangeOfSponserReturn(val);
})

  }

}
