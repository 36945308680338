import { User } from '../../../../environments/User';
import { Component, Inject } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { CaseInvoiceServiceProxy } from 'src/app/Services/caseInvoiceServiceProxy.service';
import { ClientAccountServiceProxy } from "src/app/Services/clienAccountServiceProxy.service";
import Swal from "sweetalert2";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { OfficeInvoiceServiceProxy } from 'src/app/Services/officeInvoiceServiceProxy.service';
import { UserServiceProxy } from 'src/app/Services/userServiceProxy.service';
import { DispositionServiceProxy } from 'src/app/Services/dispositionServiceProxy.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Gallery, GalleryItem, ImageItem, ImageSize, ThumbnailsPosition } from 'ng-gallery';
import { Lightbox } from 'ng-gallery/lightbox';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { KnowledgeBaseServiceProxy } from 'src/app/Services/knowledgeBaseServiceProxy.service';

declare const $: any;

@Component({
    selector: 'add-new-knowledge-base-dialouge',
    templateUrl: 'add-new-knowledge-base-dialouge.component.html',
  })
  export class AddNewKnowledgeBaseDialougeComponent  {
    knowLedgeBase:any;
    userList: any;
    btnText:any;
  public Editor = ClassicEditor;
    public addForm: FormGroup;
  submit: boolean=false;
    constructor(
        public dialogRef: MatDialogRef<AddNewKnowledgeBaseDialougeComponent>,
        private knowledgeBaseService: KnowledgeBaseServiceProxy,
        private formBuilder: FormBuilder,
        private _snackBar:MatSnackBar,

        @Inject(MAT_DIALOG_DATA) public data: {data},
        private _dialog: MatDialog,
        ) {
          this.knowLedgeBase = data.data;

        }
        async getData() {
        }



           ngOnInit() {
             this.getData();
             if(this.knowLedgeBase){
              this.btnText = 'Update Answer';
              this.addForm = this.formBuilder.group({
                Id: this.knowLedgeBase.Id,
                question: this.knowLedgeBase.question,
                answer: this.knowLedgeBase.answer,
                isDeleted: this.knowLedgeBase.isDeleted,
                isFlaged: this.knowLedgeBase.isFlaged,
                markAsFlaged: this.knowLedgeBase.markAsFlaged,
                reason: this.knowLedgeBase.reason,
              });
             }
             else{
              this.btnText = 'Save Answer';
              this.addForm = this.formBuilder.group({
                Id: [0],
                question: ["", [Validators.required]],
                answer: ["", [Validators.required]],
                isDeleted: false,
                isFlaged: false,
                markAsFlaged: false,
                reason: null,
              });
             }

           }

    close(){
      this.dialogRef.close();
    }
    Submit(){
      let self = this;
      if(this.addForm.valid){
        let data = this.addForm.value;
        this.submit=true;
        data = JSON.stringify(data);
        data = JSON.parse(data);
        data["addedByUserId"]= User.Id;
      let self = this;
        this.knowledgeBaseService.Post(data).subscribe(function(resp){

          Swal.fire(
            'Success!',
            'Knowledge Base is Posted.',
            'success'
          );
          self.close();
          this.submit=false;
        },error=>{
          this.submit=false;
        })

      }
      else{
        for(let i in this.addForm.controls){
          this.addForm.controls[i].markAsTouched();
        }
      }
    }

  }
