import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PermissionService } from 'src/app/Services/PermissionServices.service';
import { User } from 'src/environments/User';
import { DeleteDialougeComponent } from './delete-dialoge/delete-dialoge.component';
declare const $: any;
@Component({
    selector: 'data-grid',
    templateUrl: './data-grid.component.html',
    styleUrls: ['./data-grid.component.css']
})
export class DataGridComponent implements OnInit {
  selectMultiple:any;
  checkList:any=[];
  tempId:any;
  deleteDiv:any=null;
    @Input() data: [];
    @Input() column: [];
    @Input() globalFilterFields : [];
    @Input() lazy: boolean;
    @Input() showGlobalFilter: boolean;
    @Input() loading: string;
    @Input() totalRecords: number;
    @Input() moduleName : string;
    @Output() loadDataEventEmitter: EventEmitter<any>;
    @Output() checkBoxClickEventEmitter: EventEmitter<any>;
    @Output() rowClickClickEventEmitter: EventEmitter<any>;
    @Output() deleteSelectedEventEmitter: EventEmitter<any>;
    @Output() deleteEventEmitter: EventEmitter<any>;
    @Output() editEventEmitter: EventEmitter<any>;
    @Output() aprroveEventEmitter: EventEmitter<any>;
    @Output() buttonEventEmitter: EventEmitter<any>;
    @Output() grantEventEmitter: EventEmitter<any>;
    @Output() rejectedEventEmitter: EventEmitter<any>;

    constructor(private permission:PermissionService, private _dialog: MatDialog,) {
        this.loadDataEventEmitter = new EventEmitter();
        this.checkBoxClickEventEmitter = new EventEmitter();
        this.rowClickClickEventEmitter = new EventEmitter();
        this.deleteSelectedEventEmitter = new EventEmitter();
        this.deleteEventEmitter = new EventEmitter();
        this.editEventEmitter = new EventEmitter();
        this.lazy = false;
        this.showGlobalFilter = false;
        this.aprroveEventEmitter = new EventEmitter();
        this.buttonEventEmitter = new EventEmitter();
        this.grantEventEmitter = new EventEmitter();
        this.rejectedEventEmitter = new EventEmitter();


    }
    ngOnInit() {
      $("#delete_selected").appendTo("body");
      $("#delete").appendTo("body");
      this.filterValueChange();
      $(document).click(function(){
        $('.dropdown-menu').removeClass('show')
       });

    }
    filterValueChange(){
      setTimeout(function(){
        $('data-grid  table  tbody tr').each(function() {
        var td = $(this).find('td').eq(5);

          if(td.text().includes("Discovery")){
            td.addClass("bg-ticket-light text-center");
          }
          if(td.text().includes("Contact Made")){
            td.addClass("bg-yellow text-center");
          }

          if(td.text().includes("Negotiation")){
            td.addClass("bg-negotiation-green text-center");
          }


          if(td.text().includes("Qualified")){
            td.addClass("bg-seagreen text-center");
          }


          if(td.text().includes("WIN")){
            td.addClass("bg-light-seagreen text-center");
          }


          if(td.text().includes("Lost")){
            td.addClass("bg-mustard text-center");
          }

          if(td.text().includes("Multi-Label")){
            td.addClass("bg-app-light text-center");
          }
          if(td.text().includes("New Client")){
            td.addClass("bg-light-seagreen text-center");
          }
          if(td.text().includes("Existing-Active")){
            td.addClass("bg-retainer-light text-center");
          }
          if(td.text().includes("Existing-Awaiting Decission")){
            td.addClass("bg-retainer-light text-center");
          }
          if(td.text().includes("Existing-Awaiting Completion")){
            td.addClass("bg-retainer-light text-center");
          }
          if(td.text().includes("Existing-Archieved")){
            td.addClass("bg-retainer-light text-center");
          }
          if(td.text().includes("Existing-Retainer")){
            td.addClass("bg-retainer-light text-center");
          }

        });
      //  alert( $('data-grid table tr:eq(2) td:eq(4)').text())

      },1500);
    }
    checkPermission=(name,key)=>{
      var ex = this.permission.checkVisibilityByModuleName(name,key,User.BranchId)

      return ex
    }
    SelectAll(){
      this.data.forEach(element => {

        this.checkList.push(element['Id'])

      });
    }
    getPercentageStatus(str){
      str = str.replace(' ','')
      let strList = str.split('/')
      let progress = Number(strList[0])
      let total = Number(strList[1])
      let percentage = ((progress/total)*100).toFixed(2);
      return percentage;
    }
    getTotalStatus(str){
      str = str.replace(' ','')
      let strList = str.split('/')
      return Number(strList[1]);

    }
    getProgressStatus(str){
      str = str.replace(' ','')
      let strList = str.split('/')
      return Number(strList[0]);
    }
    clearList(){
      this.checkList=[]
    }
    deleteSelected(){
      this.deleteSelectedEventEmitter.emit(this.checkList);
      $("#delete_selected").modal("hide");
    }
    Check(Id){
      const index = this.checkList.indexOf(Id);
      if (index > -1) {return true}
      else{
        return false
      }
    }
    checkChange(e,Id){
      if(e.target.checked){
        this.checkList.push(Id)
      }else{
        const index = this.checkList.indexOf(Id);
        if (index > -1) {
          this.checkList.splice(index, 1);
        }
      }
    }
    getcolumnsName(){
     let arr = [];
      this.column.forEach(
       x=>{
         arr.push(x['Name']);
       }
      );
      return arr;
    }
    checkBoxClick(data){
      this.checkBoxClickEventEmitter.emit(data);
    }
    rowClick(data){
      this.rowClickClickEventEmitter.emit(data);
    }
    loadData() {
        this.loadDataEventEmitter.emit();
    }
    edit(id){
      this.editEventEmitter.emit(id);
    }
    deleteBtn(){
      this.deleteEventEmitter.emit(this.tempId);
    //   $("#delete").modal("hide");
    //  this.deleteDiv= $("#delete").detach();

    }
    deleteCancel(){
      $("#delete").modal("hide");
      this.deleteDiv= $("#delete").detach();
    }
    openDeleDialog(id){

      this._dialog.open(DeleteDialougeComponent,{
        disableClose:true,
        width:'500px',
        height:'275px',
        data: {
          check:true,
          moduleName:this.moduleName
        }
      }).afterClosed().subscribe(async (result) => {
        if(result){
          this.deleteBtn();
            // this.addApplication.patchValue({ status: 'WIN' });
            // this.onStatusChange('a');
            // this.router.navigateByUrl(AppSettings.LeadForm_URL);
         }
    });
      // if ( this.deleteDiv ) {
      //   this.deleteDiv.appendTo( "body" );
      //   this.deleteDiv = null;
      // } else {
      //   $("#delete").appendTo("body");
      // }

      // $("#delete").modal("show");
      this.tempId=id;


    }
    approveClick(data){
      this.aprroveEventEmitter.emit(data)
    }
    buttonClick(data){
      this.buttonEventEmitter.emit(data)
    }

    openDropDown(id){
      let hasClass=$('#_dropdown'+id).find('.dropdown-menu').hasClass("show");
      if (hasClass){
        $('.dropdown-menu').removeClass('show')
        $('#_dropdown'+id).find('.dropdown-menu').removeClass('show');

      }else{
        $('.dropdown-menu').removeClass('show')
        $('#_dropdown'+id).find('.dropdown-menu').addClass('show');
      }
    }
    grant(data){
      this.grantEventEmitter.emit(data)
    }
    reject(data){
      this.rejectedEventEmitter.emit(data)
    }
}
