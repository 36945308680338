import { ApplicationProcessSendEmailDialogueComponent } from './../application-process-send-email-dialogue/application-process-send-email-dialogue.component';
import { User } from 'src/environments/User';
import { DocumentFileServiceProxy } from '../../../Services/document.service';
import { Title } from '@angular/platform-browser';
import { CryptoService } from '../../../Services/CryptoService.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Component, Inject, Injector, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA, } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar'
import { MatCheckboxChange } from '@angular/material/checkbox'
import { finalize } from 'rxjs/operators';
import * as _ from 'lodash';

import {
  Gallery,
  GalleryItem,
  ThumbnailsPosition,
  ImageSize,
  ImageItem
} from "ng-gallery";

import { Lightbox } from "ng-gallery/lightbox";
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ticket } from 'src/app/shared/ticket';
import { ItFormServiceProxy } from 'src/app/Services/itformServiceProxy.service';
import Swal from 'sweetalert2';
import { commentService } from 'src/app/Services/commentService.service';
import { RoleServiceProxy } from 'src/app/Services/rolesServiceProxy.service';
import { MatterServiceProxy } from 'src/app/Services/matterServiceProxy.service';
import { CaseServiceProxy } from 'src/app/Services/caseServiceProxy.service';
import { WorkSourceServiceProxy } from 'src/app/Services/workSourceServiceProxy';
import { AutoCompleteModel } from 'src/@shared-components/auto-complete/models/auto-model';
import { WorkSourceDialogComponent } from '../../work-source/work-source.component';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

declare const $: any;
@Component({
  templateUrl: './application-process-create.component.html',
})

export class ApplicationProcessCreateComponent
  implements OnInit {
  public addApplication: FormGroup;
  matterList = [{"Id":"k"}];
  worksource :any;
  paymentMethod = ['BACS', 'Cheque', 'Cash', 'Card'];
  user: any;
  workSourceDropDownData: Array<AutoCompleteModel>;
 workSourceAutoCompleteCtrl: FormControl;
 public Editor = ClassicEditor;
 submit= false;
  caseTags: any;


  constructor(
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private matterService: MatterServiceProxy,
    private caseService: CaseServiceProxy,
    private workSourceService: WorkSourceServiceProxy,
    public dialogRef: MatDialogRef<ApplicationProcessCreateComponent>,
    private _dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public ReceviedData: any ,
  ) {
    this.workSourceDropDownData = new Array<AutoCompleteModel>();
    this.workSourceAutoCompleteCtrl = new FormControl();


  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }
   ngOnInit()  {
    this.getData();
    this.user = User;
    if(this.ReceviedData.check){
      this.addApplication = this.formBuilder.group({
        Id: [0],
        clientName: this.ReceviedData.array.name,
        matterId: [null, [Validators.required]],
        caseTag: [null, [Validators.required]],

        workSourceId: [null, [Validators.required]],
        email: this.ReceviedData.array.email,
        phoneNumber: this.ReceviedData.array.phoneNumber,
        deadLine: [new Date(), [Validators.required]],
        creationTime: [new Date(), [Validators.required]],
        agreedCost: this.ReceviedData.array.dealAmount,
        vatStatus: ['false', [Validators.required]],
        inclusiveOfVat: ['false', [Validators.required]],
        feePaid: ["", [Validators.required]],
        paymentMethod: ["", [Validators.required]],
        referenceOfPayment: ["", [Validators.required]],
        detailsOfMatter: this.ReceviedData.array.notes,
        isApproved:false,
      });
    }
    else{
      this.addApplication = this.formBuilder.group({
        Id: [0],
        clientName: ["", [Validators.required]],
        matterId: [null, [Validators.required]],
        caseTag: [null, [Validators.required]],
        workSourceId: [null, [Validators.required]],
        email: ["", [Validators.required]],
        phoneNumber: ["", [Validators.required]],
        deadLine: [new Date(), [Validators.required]],
        creationTime: [new Date(), [Validators.required]],
        agreedCost: ["", [Validators.required]],
        vatStatus: ['false', [Validators.required]],
        inclusiveOfVat: ['false', [Validators.required]],
        feePaid: ["", [Validators.required]],
        paymentMethod: ["", [Validators.required]],
        referenceOfPayment: ["", [Validators.required]],
        detailsOfMatter: ["", [Validators.required]],
        isApproved:true
      });
    }

  }

  addApplicationProcess = () => {
    let self = this;
    if (this.addApplication.valid) {
      this.submit = true;
      let bol = this.addApplication.get('vatStatus').value=="true";
      this.addApplication.patchValue({ vatStatus: bol })
      let bol1 = this.addApplication.get('inclusiveOfVat').value=="true";
      this.addApplication.patchValue({ inclusiveOfVat: bol1 })
      let data = this.addApplication.value;
      let agreedCost =Number(data['agreedCost']);
      let feedPaid = Number(data['feePaid'])
      if(feedPaid>agreedCost){
        Swal.fire("Need Revision", 'Feed Paid is greater than Agreed Cost', "warning");
        this.submit = false;
      }
      else{
        data = JSON.stringify(data);
        data = JSON.parse(data);
        data["isDeleted"] = false;
        data['addedByUserId']= User.Id;
        data['createdBy']= User.Name;
        data['agreedCost']= parseFloat(data['agreedCost']).toFixed(2);
        data['feePaid']= parseFloat(data['feePaid']).toFixed(2);

        this.caseService.PostCase(data).subscribe(Response => {
          Swal.fire("Success", "Case created successfully", "success");
          this.submit = false;
          this.addApplication.reset();
          this._dialog.open(ApplicationProcessSendEmailDialogueComponent,{
            disableClose:true,
                width:'600px',
                height:'510px',
              }).afterClosed().subscribe(async (result) => {
                self.dialogRef.close(true);
            });
        }, error => {
          this.submit = false;
          Swal.fire("Error", error.error.error, "error");
        })
      }


    }
    else{
      for( let i in this.addApplication.controls){
        this.addApplication.controls[i].markAsTouched();
      }
    }
  }

  async getData() {
    let self = this;
    const promise=this.matterService.getAllData().toPromise();
    await promise.then(function(response){
      // self.matterList = response['Matter'];
      self.matterList=response['Matter'].filter(x=>!x.isDrafted);
      self.caseTags = response['caseTags'];
    });
    await this.getAllWorkSources();


  }
 async getAllWorkSources(){
  let self = this;
    const prom = this.workSourceService.getAllData().toPromise();
    await prom.then(function(response){
      self.worksource = response['WorkSource'];
    });
    this.workSourceDropDownData = [];
    this.worksource.forEach(item => {
      this.workSourceDropDownData.push({
          text: item.Name,
          url: '',
          icon: '',
          action: '',
          value: item.Id
      });
      this.workSourceDropDownData = [...this.workSourceDropDownData];
  });
  }
  addNewWorkSource() {

    let bol = this.addApplication.get('workSourceId').value ? false: true;
    if(!this.addApplication.get('workSourceId').value)
    {
      console.log('Add new WorkSource Event');
      this._dialog.open(WorkSourceDialogComponent,{
        disableClose:true,
            width:'800px',
            height:'510px',
            data: {
              workSourceName: this.workSourceAutoCompleteCtrl.value
            }
          }).afterClosed().subscribe(async (result) => {
            await this.getAllWorkSources();
            this.addApplication.patchValue({workSourceId:result});
        });
    }
  }
  autoFillValueChangedForWorkSource(data: any) {
    this.addApplication.patchValue({ workSourceId: data });

    // this.roleChange(data);
  }
  save(): void {
  }
  closePermissions(){
    // this.selected_permission=[];
  }
  close() {
    this.dialogRef.close(false);
  }
  SendEmail(){
    let self = this;
    this._dialog.open(ApplicationProcessSendEmailDialogueComponent,{
      disableClose:true,
          width:'600px',
          height:'510px',
          // data: {
          //   workSourceName: this.workSourceAutoCompleteCtrl.value
          // }
        }).afterClosed().subscribe(async (result) => {
          self.dialogRef.close(true);
      });
  }
}
