import { Component, OnInit, ViewChild } from "@angular/core";




@Component({
  selector:"pudocumentslse",
  templateUrl:'./documents.componet.html',
})

export class DocumentComponent implements OnInit{
  constructor(){

  }
  ngOnInit(){

  }
}
