import { tap, shareReplay } from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { environment } from './../../environments/environment';
import { HttpClient, HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { User } from "src/environments/User";


@Injectable()
export class LeadFormServiceProxy{

  private apiRoot = environment.apiURL;

  constructor(private http: HttpClient) { }

   Post(data){
     data['addedByUserId'] = User.Id;
    return this.http.post(this.apiRoot.concat('leadForm/'),data).pipe(
      tap(response =>{

        }),
      shareReplay(),
    );
  }
  getById(id){
    return this.http.put(this.apiRoot.concat('leadForm/'+id+'/'),{}).pipe(
      tap(response =>{

        }),
      shareReplay(),
    );
  }
  LeadFormAvailibiltyCheck(data){
    return this.http.post(this.apiRoot.concat('leadFormAvailibilityCheck/'),data).pipe(
      tap(response =>{

        }),
      shareReplay(),
    );
  }
  getAllData()
  {

    var url =  this.apiRoot.concat('leadForm')
     return this.http.get(
    url
    ).pipe(
      tap(response =>{

        }),
      shareReplay(),
    );


  }

}
