import { CaseServiceProxy } from './../../../Services/caseServiceProxy.service';
import { User } from './../../../../environments/User';
import { Component, OnInit, ViewChild } from "@angular/core";
import {Location} from '@angular/common';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { EncryptionDecryptionService } from "src/app/Services/encryption-decryption.service";
import { LeadFormServiceProxy } from "src/app/Services/leadForm.service";
import { MarketingLeadFormServiceProxy } from "src/app/Services/marketingLeadFormProxy.service";
import Swal from "sweetalert2";
import { DispositionServiceProxy } from 'src/app/Services/dispositionServiceProxy.service';
import { SelectMarkettingDialougeComponent } from '../select-marketting-dialogue/select-marketting-dialogue.component';
import { MatDialog } from '@angular/material/dialog';
import { loaderActivation } from 'src/environments/loaderActivation';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { AppSettings } from 'src/app/shared/app.settings';
import { PermissionService } from 'src/app/Services/PermissionServices.service';




@Component({
  selector:"marketing-lead-form",
  templateUrl:'./marketing-lead-form.componet.html',
})

export class MarketingLeadFormComponent implements OnInit{
  loader: any;
  public Editor = ClassicEditor;
  public addApplication: FormGroup;
  nameBtn="";
  leadFormId:any;
  matterList = [{"Id":"k"}];
  worksource :any=[];
  branches= ['Manchester','Chester','Birmingham','London'];
  convertDealBtn = false;
  storeBtn = false;
  lostBtn = true;
  allLeadForms: any[];
  allRetainers: any[];
  allBranches: any[];
  userbranches: any[];
  blist: any;
  leadCreated = true;


  constructor(
    private formBuilder: FormBuilder,
    private leadFormService: LeadFormServiceProxy,
    private activateRoute: ActivatedRoute,
    private encryptionDecryptionService: EncryptionDecryptionService,
    private marketingLeadFormService: MarketingLeadFormServiceProxy,
    private dispositionService: DispositionServiceProxy,
    private caseService: CaseServiceProxy,
    private _dialog: MatDialog,
    private router: Router,
    private permission:PermissionService,

    private _location: Location
  ){
    this.addApplication = this.formBuilder.group({
      Id: [0],
      name: ["", [Validators.required]],
      email: [''],
      phoneNumber: [null, [Validators.required]],
      source: [null, [Validators.required]],
      matterType: [null, [Validators.required]],
      branch: [""],
      branchId: [null, [Validators.required]],
      notes: [""],
      label:[""],
        status:[""],
        dealAmount:[""],
        forecast:[""],
        isCalled:[""],
        isEmailed: [""],
        isMessaged: [""],
        isCalledStep2: [""],
        isEmailedStep2: [""],
        isMessagedStep2:[""],
        isCalledStep3: [""],
        isEmailedStep3: [""],
        isMessagedStep3:[""],
    });
  }
  async getData(){
    this.loader = true;
    loaderActivation.loader=true;
    let self = this;
    const promise = this.leadFormService.getById(this.leadFormId).toPromise();
    await promise.then(resp=>{
      var data = resp['data'];
      if( data.label=='New Client'){
        this.convertDealBtn = false;
        this.lostBtn = false;
        this.storeBtn = true;
      }
      if( data.label.match('Existing') && data.status =='WIN'){
        this.convertDealBtn = false;
        this.lostBtn = true;
        this.storeBtn = false;
      }
      if( data.label=='WIN'){
        this.convertDealBtn = false;
        this.lostBtn = true;
        this.storeBtn = false;
      }
      if( data.label.match('Existing') && data.status !='WIN'){
        this.convertDealBtn = false;
        this.lostBtn = true;
        this.storeBtn = false;
      }
      if( data.label=='Multi-Label'){
        this.convertDealBtn = false;
        this.lostBtn = true;
        this.storeBtn = false;
      }
      this.addApplication = this.formBuilder.group({
        Id: data.Id,
        name: data.name,
        email: data.email,
        phoneNumber: data.phoneNumber,
        source: data.source,
        matterType: data.matterType,
        branch: data.branch,
        branchId:data.branchId,
        notes: data.notes,
        label:data.label,
        status:data.status,
        dealAmount:data.dealAmount,
        forecast:data.forecast,
        isCalled: data.isCalled,
        isEmailed: data.isEmailed,
        isMessaged: data.isMessaged,
        isCalledStep2: data.isCalledStep2,
        isEmailedStep2: data.isEmailedStep2,
        isMessagedStep2:data.isMessagedStep2,
        isCalledStep3: data.isCalledStep3,
        isEmailedStep3: data.isEmailedStep3,
        isMessagedStep3:data.isMessagedStep3,
      });
      // self.matterList= resp['matters'];
      self.matterList=resp['matters'].filter(x=>!x.isDrafted);
      self.worksource = resp['workSources'];
      self.allBranches = resp['branches'];
    })


    this.loader = false;
    loaderActivation.loader=false;

  }
  checkPermission=(name,key,BranchId)=>{
    var ex = this.permission.checkVisibilityByModuleName(name,key,BranchId)
    return ex
  }
async  ngOnInit(){
  if(!this.blist){
    this.blist=(User.BranchId);
  }
  // if(!this.checkPermission("Drafted Lead Forms","Update",User.BranchId) &&
  // !this.checkPermission("Drafted Lead Forms","Create",User.BranchId) ){
  //   this.router.navigateByUrl("error401");
  //     }
    const row = this.activateRoute.snapshot.queryParams.row;
    if(row){
      this.leadFormId = JSON.parse(this.encryptionDecryptionService.decrypt(row));
      await this.getData();
      this.nameBtn= 'Store';

    }else{
      this.loader = true;
      this.leadCreated = false;
      this.nameBtn= 'Convert to Deal';
      this.convertDealBtn = false;
        this.lostBtn = true;
        this.storeBtn = true;
      let self = this;
      const promise=  this.marketingLeadFormService.getAllData().toPromise();
    await promise.then(function(resp){
      // self.matterList= resp['matters'];
      self.matterList=resp['matters'].filter(x=>!x.isDrafted);
      self.worksource = resp['workSources'];
      self.allBranches = resp['branches'];

    });
      this.addApplication = this.formBuilder.group({
        Id: [0],
        name: ["", [Validators.required]],
        email: [''],
        phoneNumber: [null, [Validators.required]],
        source: [null, [Validators.required]],
        matterType: [null, [Validators.required]],
        branch: [""],
        branchId: [null,[Validators.required]],
        notes: [""],
        label:[""],
        status:[""],
        dealAmount:[""],
        forecast:[""],
        isCalled:[""],
        isEmailed: [""],
        isMessaged: [""],
        isCalledStep2: [""],
        isEmailedStep2: [""],
        isMessagedStep2:[""],
        isCalledStep3: [""],
        isEmailedStep3: [""],
        isMessagedStep3:[""],
      });
      this.loader = false;
    }
    this.getAllLeadForm();
    this.getAllRetainer();

  }
  // Convert to Deal
 async Submit(){
    if(this.addApplication.valid){
      let data = this.addApplication.value;
      data = JSON.stringify(data);
      data = JSON.parse(data);
      data["isDeleted"] = false;
      data["isDrafted"] = false;
      if(data['label']!='New Client' && data['Id']!=0 && data['Id']!=null){
        Swal.fire({
          title: 'Are you sure?',
          text: "These details already exist for another lead, do you want to create a new one?!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes'
        }).then((result) => {
          if (result.isConfirmed) {
             data["status"]= 'Discovery';
            data['addedByUserId']= User.Id;
            this.leadFormService.Post(data).subscribe(Response => {
              Swal.fire("Success", "Lead Form created successfully", "success");
              this.addApplication.reset();

            }, error => {

              Swal.fire("Error", error.error.error, "error");
            });
           }
         });
      }
      else{
        this.leadFormService.LeadFormAvailibiltyCheck(data).subscribe(resp=>{
            var check = resp['exist'];
            if(check){
              Swal.fire({
                title: 'Are you sure?',
                text: "These details already exist for another lead, do you want to create a new one?!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes'
              }).then((result) => {
                if (result.isConfirmed) {
                   data["status"]= 'Discovery';
                  data['addedByUserId']= User.Id;
                  this.leadFormService.Post(data).subscribe(Response => {
                    Swal.fire("Success", "Lead Form created successfully", "success");
                    this.addApplication.reset();

                  }, error => {

                    Swal.fire("Error", error.error.error, "error");
                  });
                 }
               });
            }
            else{
              data["status"]= 'Discovery';
              data['addedByUserId']= User.Id;
              this.leadFormService.Post(data).subscribe(Response => {
                Swal.fire("Success", "Lead Form created successfully", "success");
                this.addApplication.reset();

              }, error => {

                Swal.fire("Error", error.error.error, "error");
              });
            }
        });




        // data["status"]= 'Discovery';
        //     data['addedByUserId']= User.Id;
        //     this.leadFormService.Post(data).subscribe(Response => {
        //       Swal.fire("Success", "Lead Form created successfully", "success");
        //       this.addApplication.reset();

        //     }, error => {

        //       Swal.fire("Error", error.error.error, "error");
        //     });
      }
    }else{
      for( let i in this.addApplication.controls){
        this.addApplication.controls[i].markAsTouched();
      }
    }
  }
  Store(){
  if(this.addApplication.valid){
      let data = this.addApplication.value;
      data = JSON.stringify(data);
      data = JSON.parse(data);
      data["isDeleted"] = false;
      data["isDrafted"] = true;
      data["status"]= '';
      data['addedByUserId']= User.Id;
      let self = this;


      if( data.label=='Multi-Label'){
        this._dialog.open(SelectMarkettingDialougeComponent, {
          disableClose: true,
          width: '800px',
          height: '750px',
          data: {
            leadForm: this.allLeadForms,
            retainer: this.allRetainers,
            isRetainer: true,
            isLeadForm: true,
            phoneNumber:data['phoneNumber'],
            email: data['email'],
            notes: data['notes']
          }
        }).afterClosed().subscribe(async (result) => {
          this.leadFormService.Post(data).subscribe(Response => {
            // Swal.fire("Success", "Lead Form created successfully", "success");
            // self.addApplication.reset();

          }, error => {

            // Swal.fire("Error", error.error.error, "error");
          })
        });
      }
      else if( data.label=='WIN'){
        this._dialog.open(SelectMarkettingDialougeComponent, {
          disableClose: true,
          width: '800px',
          height: '750px',
          data: {
            leadForm: this.allLeadForms,
            retainer: this.allRetainers,
            isRetainer: false,
            isLeadForm: true,
            phoneNumber:data['phoneNumber'],
            email: data['email'],
            notes: data['notes']
          }
        }).afterClosed().subscribe(async (result) => {
          this.leadFormService.Post(data).subscribe(Response => {
            // Swal.fire("Success", "Lead Form created successfully", "success");
            // self.addApplication.reset();

          }, error => {

            // Swal.fire("Error", error.error.error, "error");
          })
        });
      }
     else if( data.label.match('Existing') && data.status =='WIN'){
        this._dialog.open(SelectMarkettingDialougeComponent, {
          disableClose: true,
          width: '800px',
          height: '750px',
          data: {
            leadForm: this.allLeadForms,
            retainer: this.allRetainers,
            isRetainer: true,
            isLeadForm: true,
            phoneNumber:data['phoneNumber'],
            email: data['email'],
            notes: data['notes']
          }
        }).afterClosed().subscribe(async (result) => {
          this.leadFormService.Post(data).subscribe(Response => {
            // Swal.fire("Success", "Lead Form created successfully", "success");
            // self.addApplication.reset();

          }, error => {

            // Swal.fire("Error", error.error.error, "error");
          })
          // this.getData();
        });
      }
      else if( data.label.match('Existing') && data.status !='WIN'){
        this._dialog.open(SelectMarkettingDialougeComponent, {
          disableClose: true,
          width: '800px',
          height: '750px',
          data: {
            leadForm: this.allLeadForms,
            retainer: this.allRetainers,
            isRetainer: true,
            isLeadForm: false,
            phoneNumber:data['phoneNumber'],
            email: data['email'],
            notes: data['notes']
          }
        }).afterClosed().subscribe(async (result) => {
          this.leadFormService.Post(data).subscribe(Response => {
            // Swal.fire("Success", "Lead Form created successfully", "success");
            // self.addApplication.reset();

          }, error => {

            // Swal.fire("Error", error.error.error, "error");
          })
          // this.getData();
        });
      }
      else if(data["label"]=="Contact Made"||data["label"]=="Discovery"||data["label"]=="Qualified"||
      data["label"]=="Negotiation"||data["label"]=="Lost"){
        this._dialog.open(SelectMarkettingDialougeComponent, {
          disableClose: true,
          width: '800px',
          height: '750px',
          data: {
            leadForm: this.allLeadForms,
            retainer: this.allRetainers,
            isRetainer: false,
            isLeadForm: true,
            phoneNumber:data['phoneNumber'],
            email: data['email'],
            notes: data['notes']
          }
        }).afterClosed().subscribe(async (result) => {
          this.leadFormService.Post(data).subscribe(Response => {
            // Swal.fire("Success", "Lead Form created successfully", "success");
            // self.addApplication.reset();

          }, error => {

            // Swal.fire("Error", error.error.error, "error");
          })
          // this.getData();
        });


      }





    }else{
      for( let i in this.addApplication.controls){
        this.addApplication.controls[i].markAsTouched();
      }
    }
  }
  Lost(){
    if(this.addApplication.valid){
      let data = this.addApplication.value;
      data = JSON.stringify(data);
      data = JSON.parse(data);
      data["isDeleted"] = false;
      data["isDrafted"] = false;
      data["status"]= 'Lost';
      data['addedByUserId']= User.Id;
      this.leadFormService.Post(data).subscribe(Response => {
        Swal.fire("Success", "Lead Form is Lost", "success");
        // this.addApplication.reset();

      }, error => {

        Swal.fire("Error", error.error.error, "error");
      })

    }else{
      for( let i in this.addApplication.controls){
        this.addApplication.controls[i].markAsTouched();
      }
    }
  }
  Update(){
    if(this.addApplication.valid){
      let data = this.addApplication.value;
      data = JSON.stringify(data);
      data = JSON.parse(data);
      data["isDeleted"] = false;
      data["isDrafted"] = true;
      data["status"]= '';
      data['addedByUserId']= User.Id;
      this.leadFormService.Post(data).subscribe(Response => {
        Swal.fire("Success", "Lead Form Updated successfully", "success");
        this.router.navigateByUrl(AppSettings.DraftedLeadForm_URL);
        // this.addApplication.reset();s

      }, error => {

        Swal.fire("Error", error.error.error, "error");
      })

    }else{
      for( let i in this.addApplication.controls){
        this.addApplication.controls[i].markAsTouched();
      }
    }
  }
  async getAllLeadForm(){
    let self = this;
    this.allLeadForms = []
    const promise=  this.leadFormService.getAllData().toPromise();
    await promise.then(function(response){
      let data = response['LeadForms'];
      data.forEach(element => {
        if(!element['isDrafted']){
          self.allLeadForms.push(element);
        }
      });

    });
  }
  Back(){
    this._location.back();
  }
  async getAllRetainer(){
    let self = this;
    this.allRetainers = [];
    const promise=  this.caseService.getAllData().toPromise();
  await promise.then(function(response){
    let data = response['result'];
    self.allRetainers=data;
  });
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }
}
