import { Component, OnInit, ViewChild } from "@angular/core";
import { Location } from '@angular/common'



@Component({
  selector:"pulse",
  templateUrl:'./pulse.componet.html',
})

export class PulseComponent implements OnInit{
  constructor(
    private location: Location,
  ){

  }
  backBtn(){
    this.location.back();
  }
  ngOnInit(){

  }

}
