import { Component, Inject } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
    selector: 'selected-delete-dialoge',
    templateUrl: 'selected-delete-dialoge.component.html',
  })
  export class DeleteSelectedDialougeComponent  {
    moduleName:any;
    constructor(
        public dialogRef: MatDialogRef<DeleteSelectedDialougeComponent>,

        @Inject(MAT_DIALOG_DATA) public data: {moduleName},
        private _dialog: MatDialog,
        ) {
          this.moduleName = data.moduleName;

        }
    close(){
      this.dialogRef.close(false);
    }
    deleteBtn(){
      this.dialogRef.close(true);

    }
  }
