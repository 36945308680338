import { OfficeInvoiceServiceProxy } from 'src/app/Services/officeInvoiceServiceProxy.service';
import { User } from 'src/environments/User';
import { Component, Inject } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ClientAccountServiceProxy } from "src/app/Services/clienAccountServiceProxy.service";
import Swal from "sweetalert2";
import { OfficeAccountServiceProxy } from 'src/app/Services/officeAccountServiceProxy';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
declare const $: any;

@Component({
    selector: 'edit-office-account',
    templateUrl: 'edit-office-account.component.html',
  })
  export class EditOfficeAccountComponent  {
    caseData:any;
    officeAccount:any;
    addNew:any;
    public Editor = ClassicEditor;
    paymentAccountls = ['Manchester', 'London', 'Birmingham', 'Chester'];
    typeOfPayment=['Disbursement','Legal FEE'];
    paymentMethod = ['BACS', 'Cheque', 'Cash', 'Card'];
    constructor(
        public dialogRef: MatDialogRef<EditOfficeAccountComponent>,
        private officeAccountService: OfficeAccountServiceProxy,
        @Inject(MAT_DIALOG_DATA) public data: {data},
        private _dialog: MatDialog,
        ) {
            this.caseData = data.data.caseData;
            this.officeAccount = data.data.officeAccount;
            this.addNew = data.data.addNew;
        }



          async ngOnInit() {

           }

    close(){
      this.dialogRef.close();
    }

    Submit(){
      let self = this;
      this.officeAccount['addedByUserId'] = User.Id;
      this.officeAccount['addNew']= this.addNew;
      this.officeAccountService.Post(this.officeAccount).subscribe(function(resp){
        Swal.fire(
          'Update!',
          'Office Account is Updated.',
          'success'
        );
        self.close();
      })
    }
  }
