import { CaseServiceProxy } from '../../../Services/caseServiceProxy.service';
import { SocketioService } from 'src/app/Services/socketio.service';
import { loaderActivation } from '../../../../environments/loaderActivation';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Component, OnInit, ViewChild, Pipe } from '@angular/core';
import { AppSettings } from 'src/app/shared/app.settings';
import { User } from 'src/environments/User';
import Swal from 'sweetalert2';
import { MatDialog } from '@angular/material/dialog';
import { EncryptionDecryptionService } from 'src/app/Services/encryption-decryption.service';
import { CaseListModal } from '../../application-process-started/CaseListModal/CaseListModal';
import { PermissionService } from 'src/app/Services/PermissionServices.service';
import { Location } from '@angular/common';


declare const $: any;
@Component({
  templateUrl: './archived-cases.component.html'

})
export class ArchivedCasesComponent implements OnInit {
  dashboardRoute: any;
 public coloumn : any;
  moduleName: string;
  loader:any=false;
  blist:any;
  allCases:any = [];
  ActivecaseList = [];
 AwaitingcaseList = [];
 AwaitingCompletioncaseList = [];
 ArchivecaseList = [];
 archiveCol = [];
 CompletionCol=[];
 caseWorker :boolean= false;
 personResponsible :boolean= false;
 await:boolean=false;
 AwaitCol:any=[];
 user=User
  filterFields: string[];
  constructor(
    private title : Title,
    private router: Router,
    private permission:PermissionService,
    private _dialog: MatDialog,
    private socketService:SocketioService,
    private caseService: CaseServiceProxy,
    private encryptionDecryptionService: EncryptionDecryptionService,
    private location: Location,
  ) {

    this.moduleName = "Application Process";
       }
       backBtn(){
        this.location.back();
      }
 async getData(){

  let self = this;
  var check=false;


  this.archiveCol = [];
 this.CompletionCol=[];



 this.AwaitCol=[];

 const promise=  this.caseService.getArchived().toPromise();
  await promise.then(function(response){
    self.allCases = [];
    self.ActivecaseList = [];
    self.AwaitingcaseList = [];
    self.AwaitingCompletioncaseList = [];
    self.ArchivecaseList = [];

    let data = response['result'];
    self.ArchivecaseList = data
    // data.forEach(element => {
    //   if(element['isDraft']){
    //     self.allCases.push(element);

    //   }

    // });
    self.caseWorker =false;
    self.personResponsible= false;
    self.await = false;
    self.ArchivecaseList.forEach(el => {
      let total = 0;
      let completed = 0;
      let caseWorkerList = el.caseWorkerList;
      let totalCompleted=0;


      if(el["personResposibleId"]==self.user.Id || self.user.RoleId==1 ){
        self.personResponsible = true;
      }
    if(caseWorkerList.length>0){
      let caseWorkerLs = [];
      let dic = {}

      caseWorkerList.forEach(element => {
        let caseWorkDic ={
          id: element['userId_id'],
          name: element.CaseWorkerName,
          profilePic:element.profilePic
        }
        // if(element['userId'] in case)
        caseWorkerLs.push(caseWorkDic);
        if(Object.keys(dic).indexOf(element['userId_id'])){
          dic[element['userId_id']]=[]
          dic[element['userId_id']].push(caseWorkDic)
        }
        else{
          dic[element['userId_id']].push(caseWorkDic)
        }
        if(element.status==2){
          totalCompleted +=1;
        }
        if(element['userId']===self.user.Id ){
          self.caseWorker =true;
        }
        if(element['caseWorkerGroupList'].length>0){
          element['caseWorkerGroupList'].forEach(element1 => {
            total+=1;
            if(element1['isCompleted']){
              completed+=1;
            }
          });

          let caseWorkerDistant =  []
          for (const [key, value] of Object.entries(dic)) {
            caseWorkerDistant.push(dic[key][0]);

          }
          el['caseWorkerProfileList']= caseWorkerDistant;
        }
      });
    }
    if((totalCompleted==caseWorkerList.length && el["personResposibleId"]==self.user.Id && el['caseStatus']===0)){
      el["approve"] = true
      check=true;
    }else{
      el["approve"]=false
    }
    if(self.caseWorker && el['caseStatus']===1){
      self.await=true;
    }
    let status = completed.toString() + ' / ' + total.toString();
    el['status'] = status;
    if(el['caseStatus']===0){
      self.ActivecaseList.push(el)
    }else if(el['caseStatus']===1 && (self.caseWorker)){
      self.AwaitingcaseList.push(el)
    }else if(el['caseStatus']===2  && (el["personResposibleId"]==self.user.Id)){
      el["archive"] = true
      self.AwaitingCompletioncaseList.push(el)
    }
    // else if(el['caseStatus']===3  && (el["personResposibleId"]==self.user.Id || self.user.RoleId==1)){
    //   self.ArchivecaseList.push(el)
    // }
    });



  });
  if(!this.blist){
    this.blist=(User.BranchId);
   }
   self.ArchivecaseList.sort(function(a, b){return b.Id-a.Id});
// this.coloumn = [
//   {'Name':'Id','property':'Id','type':'Id'},
//   {'Name':'Client Name','property':'clientName','type':'text'},
//   {'Name':'Matter Type','property':'matterName','type':'text'},
//   {'Name':'Creation Date','property':'creationTime','type':'date'},
//   {'Name':'Deadline','property':'deadLine','type':'date'},
//   {'Name':'Status','property':'status','type':'text'},
//   {'Name':'Actions','property':'','type':'actions'}
// ];
this.archiveCol =  [
  {'Name':'Id','property':'Id','type':'Id'},
  {'Name':'File Number','property':'fileNumber','type':'text'},
  {'Name':'Client Name','property':'clientName','type':'text'},
  // {'Name':'Email','property':'email','type':'text'},
  // {'Name':'Phone Number','property':'phoneNumber','type':'text'},
  // {'Name':'Work Source','property':'workSource','type':'text'},

  {'Name':'Matter Name','property':'matterName','type':'text'},
  // {'Name':'Creation Date','property':'creationTime','type':'date'},
  {'Name':'Deadline Date','property':'deadLine','type':'date'},
  {'Name':'Case Workers','property':'caseWorkerProfileList','type':'textOrPic'},
  // {'Name':'Status','property':'status','type':'text'},
  // {'Name':'Actions','property':'','type':'actions'}
];
this.filterFields = ['Id','fileNumber','clientName','matterName','creationTime','deadLine'
,'status'];
// this.CompletionCol =  [
//   {'Name':'Id','property':'Id','type':'Id'},
//   {'Name':'Client Name','property':'clientName','type':'text'},
//   {'Name':'Matter Type','property':'matterName','type':'text'},
//   {'Name':'Creation Date','property':'creationTime','type':'date'},
//   {'Name':'Deadline','property':'deadLine','type':'date'},
//   {'Name':'Status','property':'status','type':'text'},
//   {'Name':'Actions','property':'','type':'actions'}
// ];
// this.AwaitCol = [
//   {'Name':'Id','property':'Id','type':'Id'},
//   {'Name':'Client Name','property':'clientName','type':'text'},
//   {'Name':'Matter Type','property':'matterName','type':'text'},
//   {'Name':'Creation Date','property':'creationTime','type':'date'},
//   {'Name':'Deadline','property':'deadLine','type':'date'},
//   {'Name':'Status','property':'status','type':'text'},
//   {'Name':'Actions','property':'','type':'actions'}
// ];
if(check ){
this.coloumn.push({'Name':'Verification','property':'approve',"type":"approve"})
}
if( this.personResponsible){
  this.CompletionCol.push({'Name':'Verification','property':'archive',"type":"archive"})
}
if(this.AwaitingcaseList.length>0){
  this.AwaitCol.push({'Name':'Verification','property':'approve',"type":"approveAwait"})
}
loaderActivation.loader=false;
this.loader = false;
  }
  grant(data){
      this._dialog.open(CaseListModal,{
      width:'400px',
      height:'450px',
      disableClose: true ,
      data: {
        case:data,
        reject:false,
        isEditable:true
      }
    }).afterClosed().subscribe(async (result) => {
      // this.getData();
  });
  }
  Reject(data){
    this._dialog.open(CaseListModal,{
      width:'400px',
      disableClose: true ,
      height:'450px',
      data: {
        case:data,
        reject:true,
        isEditable:true
      }
    }).afterClosed().subscribe(async (result) => {
      // this.getData();
  });
  }
  createApplicationProcess(){
  //   this._dialog.open(ApplicationProcessCreateComponent,{
  //     width:'800px',
  //     height:'750px',
  //     data: {
  //     }
  //   }).afterClosed().subscribe(async (result) => {
  //     this.getData();
  // });
  }
  ngAfterViewInit(): void {

  }
  checkPermission=(name,key,BranchId)=>{
    var ex = this.permission.checkVisibilityByModuleName(name,key,BranchId)
    return ex
  }
  ngOnInit() {
    if(!this.checkPermission("Application Process","Visiblity",User.BranchId)){
      this.router.navigateByUrl("error401");
  }
  loaderActivation.loader=true;
  this.loader = true;
  this.socketService.setupCaseSocket();
  this.socketService.caseSocket.onmessage=(msg)=>{
this.getData();
}

this.socketService.caseSocket.onerror = (error) =>
    console.log(`The server received: ${error['code']}`
    );
    this.socketService.caseSocket.onclose = (why) =>
    console.log(`The server received: ${why.code} ${why.reason}`
    );

    this.getData();
    this.title.setTitle('Application Process');
  }
  edit(e) {
    let toSetValues= e.Id;
    // if(Number.isInteger(value)){
    //   toSetValues = this.allCases.find(x=>x.Id=== value);
    // }
    const encId = this.encryptionDecryptionService.encrypt(toSetValues.toString());
    const param = { queryParams: { row: encId } };
    this.router.navigate([AppSettings.Case_View_URL],param);
    // const url = this.router.serializeUrl(this.router.createUrlTree([AppSettings.Case_View_URL],param));
    //   window.open(url);
//     this._dialog.open(CaseWorkerDetailModal,{width:'800px',
//   height:'768px',
//   disableClose: true ,
//   data: {
//   "data": value,
//   "disabled":true,
//   }
// }).afterClosed().subscribe(async (result) => {
// });
  }
  async deleteSelected(checkL){
    await checkL.forEach(element => {
       let toSetValues = this.allCases.find(x=>x.Id=== element);
       toSetValues['isDeleted']=true;
       toSetValues["addedByUserId"] = User.Id;
       const promise = this.caseService.PostCase(toSetValues).toPromise();
       promise.then(Response=>{
          //  this.getData();
          },error=>{
           Swal.fire("Error","Something is not right","error");
          })
     });
    //  this.getData();
     Swal.fire("Success","Case deleted successfully","success");
   }
  deleteCase(id){
    let toSetValues = this.allCases.find(x=>x.Id=== id);
    toSetValues['isDeleted']=true;
    toSetValues["addedByUserId"] = User.Id;
    this.caseService.PostCase(toSetValues).subscribe(Response=>{
        Swal.fire("Success","Case deleted successfully","success");
        // this.getData();
       },error=>{
        Swal.fire("Error","Something is not right","error");
       });
}
  DashboardNavigate()
  {
    this.router.navigateByUrl(AppSettings.DASHBOARD_URL);
  }
  approveCompletion(data){
var reject = false;
    if(data['visaExpiry']){
      reject = false;
    }else{
      reject = true
    }
    this._dialog.open(CaseListModal,{
      width:'400px',
      height:'450px',
      disableClose: true ,
      data: {
        case:data,
        reject:reject,
        isEditable:true
      }
    }).afterClosed().subscribe(async (result) => {
      // this.getData();

    var self = this;
    Swal.fire({
      title: 'Are you sure to archive this?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.isConfirmed) {
    data['caseStatus']=3
    data['addedByUserId']=User.Id;
    self.caseService.PostCase(data).subscribe(function(resp){
      // self.getData();
      Swal.fire(
        'Update!',
        'Case has been updated.',
        'success'
      )
    })
  }
});
});
  }
  approveClick(data){
    var self = this;
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.isConfirmed) {
    data['caseStatus']=1
    data['addedByUserId']=User.Id;
    self.caseService.PostCase(data).subscribe(function(resp){
      // self.getData();
      Swal.fire(
        'Update!',
        'Case has been updated.',
        'success'
      )
    })
  }
});
  }
}
