import { Injectable } from "@angular/core";

@Injectable()
export  class ticket {
  // Encryption - Decryption Key
  public  ticketSubject: any;
  public  issueDescription: any;
  public  createUserName: any;
  public  branchName: any;
  public  issueDate: any;
  public  priorityName: any;
  public  userBranch: any;
  public  isCompleted: any;
  public  images: any;
  public ticketId:any;
  public Id:any;
  public branchId:any;
  public priorityId:any;
  public imagePath:any;

  // URLS
}
