import { User } from '../../../../environments/User';
import { Component, Inject } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { CaseInvoiceServiceProxy } from 'src/app/Services/caseInvoiceServiceProxy.service';
import { ClientAccountServiceProxy } from "src/app/Services/clienAccountServiceProxy.service";
import Swal from "sweetalert2";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { OfficeInvoiceServiceProxy } from 'src/app/Services/officeInvoiceServiceProxy.service';
import { UserServiceProxy } from 'src/app/Services/userServiceProxy.service';
import { DispositionServiceProxy } from 'src/app/Services/dispositionServiceProxy.service';
import { DocumentFileServiceProxy } from 'src/app/Services/document.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Gallery, GalleryItem, ImageItem, ImageSize, ThumbnailsPosition } from 'ng-gallery';
import { Lightbox } from 'ng-gallery/lightbox';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { LeadFormReminderServiceProxy } from 'src/app/Services/leadFormReminderServiceProxy.service';
import { NavigationExtras, Router } from '@angular/router';
import { CryptoService } from 'src/app/Services/CryptoService.service';

declare const $: any;

@Component({
    selector: 'all-active-lead-dialouge',
    templateUrl: 'all-active-lead-dialouge.component.html',
  })
  export class AllActiveLeadsDialougeComponent  {
    leadForm:any;
    constructor(
        public dialogRef: MatDialogRef<AllActiveLeadsDialougeComponent>,
        public gallery: Gallery,
        public lightbox: Lightbox,
        public docService:DocumentFileServiceProxy,
        public leadFormReminderService: LeadFormReminderServiceProxy,
        private encrypt: CryptoService,
        private router: Router,

        @Inject(MAT_DIALOG_DATA) public data: any,
        private _dialog: MatDialog,
        ) {
            this.leadForm = data.activeLeads;
        }
           ngOnInit() {
           }

    close(){
      this.dialogRef.close();
    }
    navigateToLeadFiles(obj){
      this.close();

      let navigationExtras: NavigationExtras = {
        queryParams: {
          "LeadFiles": this.encrypt.encryptUsingAES256(JSON.stringify(obj))
        }
      };
      this.router.navigate(['/lead-file-view'], navigationExtras);
    }

  }
