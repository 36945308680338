import { UserServiceProxy } from './../../../Services/userServiceProxy.service';
import { User } from 'src/environments/User';
import { Component, Inject, Injector, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA, } from '@angular/material/dialog';
import * as _ from 'lodash';


import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { CaseServiceProxy } from 'src/app/Services/caseServiceProxy.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { EmailSenderServiceProxy } from 'src/app/Services/emailSenderServiceProxy.service';
declare const $: any;
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
@Component({
  templateUrl: './application-process-send-email-dialogue.component.html',
})

export class ApplicationProcessSendEmailDialogueComponent
  implements OnInit {

    user: any;
    userList: any;
    dropdownList = [];
  selectedItems = [];
  mailText= null;
  dropdownSettings:IDropdownSettings
  public Editor = ClassicEditor;
  constructor(
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private caseService: CaseServiceProxy,
    private userService: UserServiceProxy,
    private mailService: EmailSenderServiceProxy,
    public dialogRef: MatDialogRef<ApplicationProcessSendEmailDialogueComponent>,
    private _dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: { RoleName: string },
  ) {

  }
   ngOnInit()  {
    this.getData();
    this.user = User;


    // this.selectedItems = [
    //   { item_id: 3, item_text: 'Pune' },
    //   { item_id: 4, item_text: 'Navsari' }
    // ];
    // this.dropdownSettings = {
    //   singleSelection: false,
    //   idField: 'item_id',
    //   textField: 'item_text',
    //   selectAllText: 'Select All',
    //   unSelectAllText: 'UnSelect All',
    //   itemsShowLimit: 3,
    //   allowSearchFilter: true
    // };
  }
  onItemSelect(item: any) {
    console.log(item);
  }
  onSelectAll(items: any) {
    console.log(items);
  }


  async getData() {
    let self = this;
    const promise=this.userService.getAllUserData().toPromise();
    await promise.then(function(response){
      self.userList = response['users'];
          self.dropdownList = [
      // { item_id: 3, item_text: 'Pune' },
      // { item_id: 4, item_text: 'Navsari' }
    ];
      self.userList.forEach(element => {
        self.dropdownList.push({
          email:element['User']['Email'],
          name:element['User']['Name']
        })

      });
      self.dropdownSettings = {
        singleSelection: false,
        idField: 'email',
        textField: 'name',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        itemsShowLimit: 3,
        allowSearchFilter: true
      };
    });


  }


  close() {
    this.dialogRef.close();
  }
  Send(){
    let obj = {data:[]};
    if(this.selectedItems.length>0){
      this.selectedItems.forEach(element=>{
        obj['data'].push({
          name: element['name'],
          email: element['email'],

        })
      });
    let data = JSON.stringify(obj);
      data = JSON.parse(data);
      data['text']= this.mailText;
      this.mailService.SendEmail(data).subscribe(Response => {
        Swal.fire("Success", "Mail Send", "success");
        this.close();
      }, error => {

        Swal.fire("Error", error.error.error, "error");
      })

    }
    else{
      Swal.fire("Mail Not Send", "You didn't select any options so no mail is send.", "info");
    }
  }
}
