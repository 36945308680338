// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  hmr: false,
  // apiURL: 'http://localhost:8000/',
  socketUrl: "wss://be.xlaw.lawyer/",
  imageUrl: "https://be.xlaw.lawyer/AxisIms/Uploads/",

  apiURL: "https://be.xlaw.lawyer/",
  // socketUrl:'wss://backend.portal.axis.lawyer/'

  //  apiURL: 'https://backend.portal.axis.lawyer/',
  //  socketUrl:'wss://backend.portal.axis.lawyer/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
