import { tap, shareReplay } from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { environment } from './../../environments/environment';
import { HttpClient, HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';

import * as CryptoJS from 'crypto-js';

@Injectable()
export class CryptoService{
    tokenFromUI: string = "0123456789123456";




    encryptUsingAES256(string) {
      let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);

      let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
      let encrypted = CryptoJS.AES.encrypt(
        JSON.stringify(string), _key, {
          keySize: 16,
          iv: _iv,
          mode: CryptoJS.mode.ECB,
          padding: CryptoJS.pad.Pkcs7
        });
      return encrypted.toString();
    }
    decryptUsingAES256(string) {
      let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
      let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);

      return CryptoJS.AES.decrypt(
        string, _key, {
          keySize: 16,
          iv: _iv,
          mode: CryptoJS.mode.ECB,
          padding: CryptoJS.pad.Pkcs7
        }).toString(CryptoJS.enc.Utf8);
    }

    encryptUsingAES(string) {
      let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);

      let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
      let encrypted = CryptoJS.AES.encrypt(
        JSON.stringify(string), _key, {
          keySize: 16,
          iv: _iv,
          mode: CryptoJS.mode.ECB,
          padding: CryptoJS.pad.Pkcs7
        });
      return encrypted.toString();
    }
}
