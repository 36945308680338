import { CaseServiceProxy } from './../../../Services/caseServiceProxy.service';
import { teamsCalendarServiceProxy } from './../../../Services/teamsCalendarServiceProxy.service';
import { User } from './../../../../environments/User';
import { filter } from 'rxjs/operators';
import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, Router } from "@angular/router";
import { Gallery, GalleryItem, ImageItem, ImageSize, ThumbnailsPosition } from "ng-gallery";
import { Lightbox } from "ng-gallery/lightbox";
import { DocumentFileServiceProxy } from "src/app/Services/document.service";
import { EncryptionDecryptionService } from "src/app/Services/encryption-decryption.service";
import { LeadFormServiceProxy } from "src/app/Services/leadForm.service";
import { LeadFormMailSenderServiceProxy } from "src/app/Services/leadFormMailServiceProxy.service";
import { MarketingLeadFormServiceProxy } from "src/app/Services/marketingLeadFormProxy.service";
import { UserServiceProxy } from "src/app/Services/userServiceProxy.service";
import { AppSettings } from "src/app/shared/app.settings";
import { loaderActivation } from "src/environments/loaderActivation";
import Swal from "sweetalert2";
import { ApplicationProcessCreateComponent } from "../../application-process/application-process-create/application-process-create.component";
import { AddNewDispostionDialougeComponent } from "../add-new-call-dispostion-dialouge/add-new-call-dispostion-dialouge.component";
import { PermissionService } from 'src/app/Services/PermissionServices.service';
import { LeadFormNotesServiceProxy } from 'src/app/Services/leadFormNotes.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CreateReminderDialougeComponent } from '../create-reminder-dialouge/create-reminder-dialouge.component';
import { LeadFormReminderServiceProxy } from 'src/app/Services/leadFormReminderServiceProxy.service';
import { Location } from '@angular/common';




@Component({
  selector:"detail-lead-form",
  templateUrl:'./detail-lead-form.component.html',
})

export class DetailLeadFormComponent implements OnInit{
  loader: any;
  public Editor = ClassicEditor;
  public addApplication: FormGroup;
  public sheduelarApplication: FormGroup;
  public emailApplication: FormGroup;
  public notesApplication: FormGroup;
  nameBtn="Update";
  leadFormId:any;
  matterList = [{"Id":"k"}];
  worksource :any=[];
  dispositions :any=[];
  leadFormNotes :any=[];
  scheduleList :any=[];
  leadForm: any;
  fileId:any=-1;
  items: GalleryItem[];
  alldataUpdate = true;
  branches= ['Manchester','Chester','Birmingham','London'];
  callMethods= ['Face to Face', 'On Call', 'email'];
  ls = ['Contact Made','Discovery','Qualified','Negotiation','Lost','WIN'];


  files:any=[];
  allBranches:any=[];
  allTeamUsers:any=[];
  postFiles:any=[];
  fileIndex:any=0;
  fileId1:any=-1;
  items1: GalleryItem[];
  allUser: any;
  allUserNotes: any;
  allUserReminder: any=[];
  cadenceLabel ='';
  cadenceStep1Completed = false;
  cadenceStep2Completed = false;
  emailSubmit = false;
  notesSubmit = false;
  blist: any;
  constructor(
    private formBuilder: FormBuilder,
    private location: Location,
    private leadFormService: LeadFormServiceProxy,
    private teamCalendarService: teamsCalendarServiceProxy,
    private activateRoute: ActivatedRoute,
    private encryptionDecryptionService: EncryptionDecryptionService,
    private marketingLeadFormService: MarketingLeadFormServiceProxy,
    private _dialog: MatDialog,
    public docService:DocumentFileServiceProxy,
    public leadFormMailService:LeadFormMailSenderServiceProxy,
    public gallery: Gallery,
    public lightbox: Lightbox,
    private _snackBar:MatSnackBar,
    private router: Router,
    private userService: UserServiceProxy,
    private permission: PermissionService,
    private leadFormNoteService: LeadFormNotesServiceProxy,
    public leadFormReminderService: LeadFormReminderServiceProxy,
    public caseService : CaseServiceProxy,


  ){
    this.emailSubmit = false;
  this.notesSubmit = false;
    this.addApplication = this.formBuilder.group({
      Id: [0],
      name: ["", [Validators.required]],
      email: [null,[Validators.required]],
      phoneNumber: [null, [Validators.required]],
      source: [null, [Validators.required]],
      matterType: [null, [Validators.required]],
      branch: [""],
      branchId:[null, [Validators.required]],
      notes: [""],
      label:[""],
        status:[""],
        dealAmount:[""],
        forecast:[""],
        creationTime:[""],
        isDeleted: [""],
        isDrafted: [""],
        isCalled:[""],
        isEmailed: [""],
        isMessaged: [""],
        isCalledStep2: [""],
        isEmailedStep2: [""],
        isMessagedStep2:[""],
        isCalledStep3: [""],
        isEmailedStep3: [""],
        isMessagedStep3:[""],
        dealOwner: [""]
    });
    this.emailApplication = this.formBuilder.group({
      Id: [0],
      email: ["", [Validators.required]],
      clientName:[null,[Validators.required]],
      subject: [null, [Validators.required]],
      message: [null, [Validators.required]],
    });
    this.notesApplication = this.formBuilder.group({
      Id: [0],
      name: ["", [Validators.required]],
      userId:User.Id,
      leadFormId: [null, [Validators.required]],
    });
    this.sheduelarApplication = this.formBuilder.group({
      Id: [0],
      userEmail:["", [Validators.required]],
      leadFormId: [null],
      dateTime: [new Date (),[Validators.required]],
      method:["", [Validators.required]],
      notes:["", [Validators.required]],
      timeSlot:[""]
    });
  }
  backBtn(){
    this.location.back();
  }
  async getData(){
    this.loader = true;
    let self = this;
    this.getAllUsers();
    const promise = this.leadFormService.getById(this.leadFormId).toPromise();
    await promise.then(resp=>{
      var data = resp['data'];
      this.allTeamUsers = resp['teamUsers'];
      if(this.alldataUpdate){
        this.allBranches= resp['branches'];
        this.leadFormNotes = resp['leadFormNotes'];
        this.leadFormNotes = this.leadFormNotes.reverse();
        this.allUserReminder = resp['leadFormReminder'];
        this.allUserReminder = this.allUserReminder.reverse();
        this.leadForm = data;
        this.addApplication = this.formBuilder.group({
          Id: data.Id,
          name: data.name,
          email: data.email,
          phoneNumber: data.phoneNumber,
          source: data.source,
          matterType: data.matterType,
          branch: data.branch,
          branchId: data.branchId,
          notes: data.notes,
          label:data.label,
          status:data.status,
          dealAmount:data.dealAmount,
          forecast:data.forecast,
          creationTime: data.creationTime,
          isDeleted: data.isDeleted,
          isDrafted: data.isDrafted,
          isCalled: data.isCalled,
          isEmailed: data.isEmailed,
          isMessaged: data.isMessaged,
          isCalledStep2: data.isCalledStep2,
          isEmailedStep2: data.isEmailedStep2,

          isMessagedStep2:data.isMessagedStep2,
          isCalledStep3: data.isCalledStep3,
          isEmailedStep3: data.isEmailedStep3,
          isMessagedStep3:data.isMessagedStep3,
          dealOwner:data.dealOwner,
        });
        this.emailApplication = this.formBuilder.group({
          Id: [0],
          email: data.email,
          subject: [null, [Validators.required]],
          clientName:data.name,
          message: [null, [Validators.required]],
        });
        this.notesApplication = this.formBuilder.group({
          Id: [0],
          name: '',
          userId: User.Id,
          clientName:data.name,
        });
        // self.matterList= resp['matters'];
        self.matterList=resp['matters'].filter(x=>!x.isDrafted);
        self.worksource = resp['workSources'];
      }
      this.dispositions = resp['dispositions'];
      this.dispositions = this.dispositions.reverse();
      this.notesApplication = this.formBuilder.group({
        Id: [0],
        name: '',
        userId: User.Id,
        clientName:data.name,
      });

    })

    this.CadenceCheck();
    this.loader = false;
    loaderActivation.loader=false;
    this.alldataUpdate = true;

  }
async  ngOnInit(){
  if(!this.blist){
    this.blist=(User.BranchId);
  }
  // if(!this.checkPermission("Leads","Update",User.BranchId) &&
  // !this.checkPermission("Leads","Create",User.BranchId) ){
  //   this.router.navigateByUrl("error401");
  //     }
    const row = this.activateRoute.snapshot.params.param;
    if(row){
      // this.leadFormId = JSON.parse(this.encryptionDecryptionService.decrypt(row));
      this.leadFormId = row;
      await this.getData();

    }else{
      this.loader = true;
      loaderActivation.loader=false;
    //   let self = this;
    //   const promise=  this.marketingLeadFormService.getAllData().toPromise();
    // await promise.then(function(resp){
    //   self.matterList= resp['matters'];
    //   self.worksource = resp['workSources'];


    // });
      this.addApplication = this.formBuilder.group({
        Id: [0],
        name: ["", [Validators.required]],
        email: [null,[Validators.required]],
        phoneNumber: [null, [Validators.required]],
        source: [null, [Validators.required]],
        matterType: [null, [Validators.required]],
        branch: [""],
        branchId:[null,[Validators.required]],
        notes: [""],
        label:[""],
        status:[""],
        dealAmount:[""],
        forecast:[""],
        creationTime: [""],
        isDeleted: [""],
        isDrafted: [""],
        isCalled:[""],
        isEmailed: [""],
        isMessaged: [""],
        isCalledStep2: [""],
        isEmailedStep2: [""],
        isMessagedStep2:[""],
        isCalledStep3: [""],
        isEmailedStep3: [""],
        isMessagedStep3:[""],
        dealOwner: [""],
      });
      this.loader = false;
    }

  }
  clearedReminder(row){
    let obj = {
      Id:row.Id,
      comments:row.comments,
      personActing: row.personActing,
      date: row.date,
      isDeleted:true,
      leadFormId: row.leadFormId,
      isDrafted:row.isDrafted,
      addedByUserId: User.Id,
    }
    this.leadFormReminderService.Post(obj).subscribe(function(resp){

      // self.getData();
      Swal.fire(
        'Success!',
        'Reminder is Cleared.',
        'success'
      );
      self.close();
    })

  }
  deleteFile(index){
    this.docService.PostDoc({"Id":this.fileId,'isDeleted':true}).subscribe(resp=>{
    this.fileId=-1;
    this.dispositions[index].files.splice(this.dispositions[index].files.findIndex(x=>x.Id==this.fileId),1);
    },error=>{
      // console.log("error",error)
    });
  }
  CadenceCheck(){
    let count = 0;
    if(this.addApplication.get('isCalled').value && this.addApplication.get('isEmailed').value &&
    this.addApplication.get('isMessaged').value){
      count++;
      this.cadenceStep1Completed= true;
    }
    if(this.addApplication.get('isCalledStep2').value && this.addApplication.get('isEmailedStep2').value &&
    this.addApplication.get('isMessaged').value){
      count++;
      this.cadenceStep2Completed = true;
    }
    if(this.addApplication.get('isCalledStep3').value && this.addApplication.get('isEmailedStep3').value &&
    this.addApplication.get('isMessagedStep3').value){
      count++;
    }
    this.cadenceLabel= "Cadence ("+count.toString()+'/3)';
  }
  CalledStep1(){
    this.addApplication.patchValue({ isCalled: true });
    this.SubmitWithOutCheck();
  }
  EmailStep1(){
    this.addApplication.patchValue({ isEmailed: true });
    this.SubmitWithOutCheck();
  }
  MessageStep1(){
    this.addApplication.patchValue({ isMessaged: true });
    this.SubmitWithOutCheck();
  }
  CalledStep2(){
    this.addApplication.patchValue({ isCalledStep2: true });
    this.SubmitWithOutCheck();
  }
  EmailStep2(){
    this.addApplication.patchValue({ isEmailedStep2: true });
    this.SubmitWithOutCheck();
  }
  MessageStep2(){
    this.addApplication.patchValue({ isMessagedStep2: true });
    this.SubmitWithOutCheck();
  }
  CalledStep3(){
    this.addApplication.patchValue({ isCalledStep3: true });
    this.SubmitWithOutCheck();
  }
  EmailStep3(){
    this.addApplication.patchValue({ isEmailedStep3: true });
    this.SubmitWithOutCheck();
  }
  MessageStep3(){
    this.addApplication.patchValue({ isMessagedStep3: true });
    this.SubmitWithOutCheck();
  }
  SubmitWithOutCheck(){
      let data = this.addApplication.value;
      data = JSON.stringify(data);
      data = JSON.parse(data);
      // data["isDeleted"] = false;
      // data["isDrafted"] = false;
      // data["status"]= 'Discovery';
      data['addedByUserId']= User.Id;
      this.leadFormService.Post(data).subscribe(Response => {
        this.CadenceCheck();
        // Swal.fire("Success", "Lead Form created successfully", "success");
        // this.addApplication.reset();

      }, error => {

        // Swal.fire("Error", error.error.error, "error");
      })
  }
  Submit(){
    if(this.addApplication.valid){
      let data = this.addApplication.value;
      data = JSON.stringify(data);
      data = JSON.parse(data);
      data['createdBy']= User.Name;
      // data["isDeleted"] = false;
      // data["isDrafted"] = false;
      // data["status"]= 'Discovery';
      data['addedByUserId']= User.Id;
      this.leadFormService.Post(data).subscribe(Response => {
        Swal.fire("Success", "Lead Form created successfully", "success");
        // this.addApplication.reset();

      }, error => {

        Swal.fire("Error", error.error.error, "error");
      })

    }else{
      for( let i in this.addApplication.controls){
        this.addApplication.controls[i].markAsTouched();
      }
    }
  }
  SendNotes(){
    if(this.notesApplication.valid){
      this.notesSubmit = true;
      let data = this.notesApplication.value;

      data = JSON.stringify(data);
      data = JSON.parse(data);
      data['leadFormId'] = this.leadFormId;
      data['addedByUserId']= User.Id;
      this.leadFormNoteService.Post(data).subscribe(resp=>{
        this.notesSubmit = false;
        Swal.fire("Success", "Save successfully", "success");
        this.getData();

      }, error => {
        this.notesSubmit = false;

        Swal.fire("Error", error.error.error, "error");
      })
    }
  }
  Send(){
    if(this.emailApplication.valid){
      this.emailSubmit = true;
      let data = this.emailApplication.value;
      data = JSON.stringify(data);
      data = JSON.parse(data);
      data['leadFormId']=this.leadFormId;
      data['personActing']= User.Id;
      data['SenderName']= User.Name;
      data['ClientName']= this.addApplication.get('name').value;
      let imgs = [];

    this.files.forEach(item=>{
      imgs.push({"filePath":item.filePath,'name':item.name,"type":item.type})
    });
    let self = this;
    let docData = {"Id":0,"files":imgs}
    this.docService.PostDoc(docData).subscribe(resp=>{
      data['attachFileNames']= resp['data'];
      this.leadFormMailService.Post(data).subscribe(resp=>{
      this.emailSubmit = false;

        Swal.fire("Success", "Mail Send successfully", "success");
        this.getData();

      });
      self.files=[];
  },error=>{
    this.emailSubmit = false;

    // console.log(error);
  });
    }
    else{
      for( let i in this.emailApplication.controls){
        this.emailApplication.controls[i].markAsTouched();
      }
    }

    // data["files"]= this.files.length;

  }
  openImage(files,i){
    this.items = files.map(item => {
      return new ImageItem({ src: item.filePath, thumb: item.filePath });
    });

  const lightboxRef = this.gallery.ref("lightbox");
  lightboxRef.setConfig({
    imageSize: ImageSize.Cover,
    thumbPosition: ThumbnailsPosition.Top,

    gestures: false
  });
  // Add custom gallery config to the lightbox (optional)

  // Load items into the lightbox gallery ref
  lightboxRef.load(this.items);

    this.lightbox.open(i,'lightbox');
  }
  downloadFile(files,i){
    const linkSource = files[i].filePath;
     const downloadLink = document.createElement("a");
     downloadLink.href = linkSource;
     downloadLink.download = files[i].fileName;
     downloadLink.click();
  }
  createDispositions(){
    if(this.checkPermission('Leads','Update',this.blist)){


    this._dialog.open(AddNewDispostionDialougeComponent, {
      disableClose: true,
      width: '800px',
      height: '750px',
      data: {
        data: this.leadForm
      }
    }).afterClosed().subscribe(async (result) => {
      this.getData();
     this.alldataUpdate = false;
    });
  }
  }
  deleteFromArray(index) {
    this.files.splice(index, 1);
    this.fileIndex--;
  }
  onFileChange(event) {
    let pFileList = event.target.files;
    let _files = Object.keys(pFileList).map(key => pFileList[key]);
    this._snackBar.open("Successfully upload!", "Close", {
      duration: 2000
    });

    if (event.target.files && event.target.files[0]) {
      _files.forEach(item => {
        var reader = new FileReader();

        reader.readAsDataURL(item); // read file as data url

        reader.onload = event => {
          // called once readAsDataURL is completed
          item.filePath = event.target.result;

        };
        item.Id=this.fileIndex;
        this.files.push(item);
        this.fileIndex++;
      });
    }
  }
  onStatusChange(value){
      let data = this.addApplication.value;
      data = JSON.stringify(data);
      data = JSON.parse(data);
      data['addedByUserId']= User.Id;
      this.leadFormService.Post(data).subscribe(Response => {
        Swal.fire("Success", "Status Updated Successfully", "success");
        // this.addApplication.reset();

      }, error => {

        Swal.fire("Error", error.error.error, "error");
      })


  }
  bookAppoinment(){
    if(this.sheduelarApplication.valid){
      let data = this.sheduelarApplication.value;
    data = JSON.stringify(data);
    data = JSON.parse(data);
    data['addedByUserEmail'] = User.Email;
    data['location'] = User.BranchId;
    data['clientMail'] = this.addApplication.get('email').value;
    data['clientName'] = this.addApplication.get('name').value;
    data['subject'] = this.addApplication.get('name').value+'-'+this.sheduelarApplication.get('method').value;
    data['addedByUserId']= User.Id;
    data['post'] = true;
    loaderActivation.loader = true;
    this.teamCalendarService.Post(data).subscribe(Response => {
      this.sheduelarApplication.reset();
      loaderActivation.loader = false;
        Swal.fire("Success", "Meeting Hass been Created Successfully", "success");
      }, error => {

        Swal.fire("Error", error.error.error, "error");
      })
    }

  }
  onPersonChange(value){
    if(this.checkPermission('Leads','Update',this.blist)){



    if(this.sheduelarApplication.get('userEmail').valid && this.sheduelarApplication.get('dateTime').valid){
      let data = this.sheduelarApplication.value;
      data = JSON.stringify(data);
      data = JSON.parse(data);
      data['addedByUserId']= User.Id;
      data['post'] = false;


      loaderActivation.loader = true;
      // this.loader = true;
      this.teamCalendarService.Post(data).subscribe(Response => {
      loaderActivation.loader = false;
        let scheduleDic = Response['schedule']['list'];
        this.scheduleList = [];
      for (const [key, value] of Object.entries(scheduleDic)) {

        this.scheduleList.push({ endTime: value["endTime"], isBooked: value["isBooked"],
        location: value["location"], startTime: value["startTime"]
        , subject: value["subject"]
        , text: value["text"],timeSlot: value['timeSlot'] });

      }
        // this.scheduleList = Response['schedule']['list'];
        this.scheduleList = [...this.scheduleList];


        // Swal.fire("Success", "Status Updated Successfully", "success");
        // this.addApplication.reset();

      }, error => {

        Swal.fire("Error", error.error.error, "error");
      })
    }
    else{
      this.sheduelarApplication.get('userEmail').markAsTouched();
      this.sheduelarApplication.get('dateTime').markAsTouched();
    }

  }

}
numberOnly(event): boolean {
  const charCode = (event.which) ? event.which : event.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    return false;
  }
  return true;

}
  createRetainer(){
  if(this.checkPermission('Leads','Update',this.blist)){
        let data = this.addApplication.value;
        data = JSON.stringify(data);
        data = JSON.parse(data);
        const promise = this.caseService.checkCaseAvailibility(data['email'],data['phoneNumber']).toPromise();
       promise.then(resp=>{
         let check = resp['exist'];
        console.log(check)
        if(check){
          Swal.fire({
            title: 'Are you sure you want to create this?',
            text: "The system already have a retainer with the same name!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
          }).then((result) => {
            if (result.isConfirmed) {
              this.retainerDialoge(data);


        }
       });
       }
       else{
         this.retainerDialoge(data)
       }




  })




}
  }
  retainerDialoge(data){
    this._dialog.open(ApplicationProcessCreateComponent,{
      disableClose:true,
      width:'800px',
      height:'750px',
      data: {
        check:true,
        array:data
      }
    }).afterClosed().subscribe(async (result) => {
      if(result){
          this.addApplication.patchValue({ status: 'WIN' });
          this.onStatusChange('a');
          this.router.navigateByUrl(AppSettings.LeadForm_URL);
       }
  });
  }
  createReminder(){
    if(this.checkPermission('Leads','Update',this.blist)){
    this._dialog.open(CreateReminderDialougeComponent, {
      disableClose: true,
      width: '800px',
      height: '750px',
      data: {
        data: this.leadForm
      }
    }).afterClosed().subscribe(async (result) => {
      this.getData();
    });}
  }

  async getAllUsers(){
    const promise = this.userService.getAllData().toPromise();
    promise.then(resp => {
      this.allUser =[];
      let user= resp['users'];
      this.allUserNotes = user;

      user.forEach(element => {
        element.permission.forEach(element1 => {
            element1.permissions.forEach(element2 => {
                if(element2.ModuleName=='Leads'){
                  if(element2.Visiblity){
                    if(!this.allUser.includes(element)){
                    this.allUser.push(element);
                    }
                  }
                }
            });
        });
      });

      // this.allUser.filter(x=>x.)
    })
  }
  checkPermission = (name, key, branchId) => {

    if (!branchId) {
      branchId = User.BranchId
    }
    var ex = this.permission.checkVisibilityByModuleName(name, key, branchId)


    return ex
  }
}
