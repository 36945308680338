import { Component, Inject } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { CaseInvoiceServiceProxy } from 'src/app/Services/caseInvoiceServiceProxy.service';
import { ClientAccountServiceProxy } from "src/app/Services/clienAccountServiceProxy.service";
import { OfficeAccountServiceProxy } from "src/app/Services/officeAccountServiceProxy";
import Swal from "sweetalert2";
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
declare const $: any;

@Component({
    selector: 'drafted-client',
    templateUrl: 'add-new-receipt-office-account.component.html',
  })
  export class AddNewReceiptOfficeAccountComponent  {
    caseData:any;
    caseInvoices:any;
    receiptForm:any;
    entryDate:any;
    transactionDate:any;
    reasonOfReceipt: any;
    amount: any;
    memo: any;
    allInvoices:any;
    selectedInvoices=[];
    officeInvoiceId:any;
    public Editor = ClassicEditor;
    paymentAccountls = ['Manchester', 'London', 'Birmingham', 'Chester'];
    typeOfPayment=['Disbursement','Legal FEE'];
    paymentMethod = ['BACS', 'Cheque', 'Cash', 'Card'];
    constructor(
        public dialogRef: MatDialogRef<AddNewReceiptOfficeAccountComponent>,
        private officeAccountService: OfficeAccountServiceProxy,
        @Inject(MAT_DIALOG_DATA) public data: {data},
        private _dialog: MatDialog,
        ) {
            this.caseData = data.data.caseData;
            this.caseInvoices = data.data.Invoice;
            this.allInvoices =data.data.officeInvoices;
            this.entryDate = new Date();
        }



          async ngOnInit() {


           }

    close(){
      this.dialogRef.close();
    }
    typeOfPaymentChange(type){
      this.selectedInvoices= this.allInvoices.filter(x=>x.typeOfPayment===type);

    }
    bankAccountInvalid = false;
  paymentTypeInvalid = false;
  paymentMethodInvalid = false;
  receiptFromInvalid = false;
  transDateInvalid = false;
  reasonReceptInvalid = false;
  amountInvalid = false;
  memoInvalid = false;
  officeInvoiceInvalid= false;
  checkValidation() {
    let ret = true;
    if (this.caseData.paymentAccount == undefined) {
      this.bankAccountInvalid = true;
      ret = false;

    } else {
      this.bankAccountInvalid = false;

    }


    if (this.caseInvoices.typeOfPayment == undefined) {
      this.paymentTypeInvalid = true;
      ret = false;
    } else {
      this.paymentTypeInvalid = false;
    }


    if (this.caseInvoices.methodOfPayment == undefined) {
      this.paymentMethodInvalid = true;
      ret = false;
    } else {
      this.paymentMethodInvalid = false;
    }


    if (this.receiptForm == null) {
      this.receiptFromInvalid = true;
      ret = false;
    } else {
      this.receiptFromInvalid = false;
    }

    if (this.transactionDate == null) {
      this.transDateInvalid = true;
      ret = false;
    } else {
      this.transDateInvalid = false;
    }

    if (this.reasonOfReceipt == null) {
      this.reasonReceptInvalid = true;
      ret = false;
    } else {
      this.reasonReceptInvalid = false;
    }


    if (this.amount == null) {
      this.amountInvalid = true;
      ret = false;
    } else {
      this.amountInvalid = false;
    }




    if (this.memo == null) {
      this.memoInvalid = true;
      ret = false;
    } else {
      this.memoInvalid = false;
    }


    if (this.officeInvoiceId == null) {
      this.officeInvoiceInvalid = true;
      ret = false;
    } else {
      this.officeInvoiceInvalid = false;
    }


    return ret;
  }
    Submit(){
      if(this.checkValidation()){
        let temp1 = Number(this.officeInvoiceId)
        let temp = this.allInvoices.find(x=>x.Id===temp1)
        let officeAmount = Number(temp.balance);
        let clientAmount = Number(this.amount);

        if(officeAmount<clientAmount && clientAmount>=0){
          Swal.fire(
            'Need Revision',
            'The Invoice Amount is less than the payment amount.',
            'error'
          );
        }
        else{
          let self = this;
        let data = {
          "Id":0,
          "entryDate":this.entryDate,
          "transactionDate":this.transactionDate,
          "typeOfPayment":this.caseInvoices.typeOfPayment,
          "bankAccount":this.caseData.paymentAccount,
          "paymentMethod":this.caseInvoices.methodOfPayment,
          "receiptNumber":null,
          "paymentNumber":null,
          "receiptForm":this.receiptForm,
          "reasonOfReceipt":this.reasonOfReceipt,
          "amount":parseFloat(this.amount).toFixed(2),
          "memo":this.memo,
          "payTo":null,
          "address":null,
          "reasonOfPayment":null,
          "caseId_id":this.caseData.Id,
          "isDraft":true,
          "isDeleted":false,
          "isCompleted":false,
          "officeInvoiceId":this.officeInvoiceId,
          "clientAccountId":0
        }
        this.officeAccountService.Post(data).subscribe(function(resp){
          // self.getData();
          Swal.fire(
            'Update!',
            'Payment Is transfered to Client Account.',
            'success'
          );
          self.close();
        });
        }

      }

    }
  }
