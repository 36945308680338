import { tap, shareReplay } from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { environment } from '../../environments/environment';
import { HttpClient, HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';



@Injectable()
export class CaseServiceProxy{

  private apiRoot = environment.apiURL;

  constructor(private http: HttpClient) { }

  PostCase(data){
    return this.http.post(this.apiRoot.concat('case/'),data).pipe(
      tap(response =>{

        }),
      shareReplay(),
    );
  }
  getById(id){
    return this.http.put(this.apiRoot.concat('getCasebyId/'+id+'/'),{}).pipe(
      tap(response =>{

        }),
      shareReplay(),
    );
  }
  getRetainer(){
    var url =  this.apiRoot.concat('getRetainers/')
    return this.http.get(
   url
   ).pipe(
     tap(response =>{

       }),
     shareReplay(),
   );

  }

  getActiveCases(){
    var url =  this.apiRoot.concat('case/?status=0')
    return this.http.get(
   url
   ).pipe(
     tap(response =>{

       }),
     shareReplay(),
   );
  }

  getAwaitingDecision(){
    var url =  this.apiRoot.concat('case/?status=1');
    return this.http.get(
   url
   ).pipe(
     tap(response =>{

       }),
     shareReplay(),
   );
  }
  getAwaitingCompletion(){
    var url =  this.apiRoot.concat('case/?status=2');
    return this.http.get(
   url
   ).pipe(
     tap(response =>{

       }),
     shareReplay(),
   );
  }
  getArchived(){
    var url =  this.apiRoot.concat('case/?status=3');
    return this.http.get(
   url
   ).pipe(
     tap(response =>{

       }),
     shareReplay(),
   );
  }
  getAllData()
  {

    var url =  this.apiRoot.concat('case/')
     return this.http.get(
    url
    ).pipe(
      tap(response =>{

        }),
      shareReplay(),
    );


  }
  checkCaseAvailibility(email,phone){
    var url =  this.apiRoot.concat('caseAvailibility/?email='+email+'&phone='+phone);
    return this.http.get(
   url
   ).pipe(
     tap(response =>{

       }),
     shareReplay(),
   );
  }

}
