import { Component, OnInit, ViewChild } from "@angular/core";




@Component({
  selector:"settings",
  templateUrl:'./settings.componet.html',
})

export class SettingsComponent implements OnInit{
  constructor(){

  }
  ngOnInit(){

  }
}
