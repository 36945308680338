
import { CaseServiceProxy } from './../../../Services/caseServiceProxy.service';
import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { User } from 'src/environments/User';

@Component({
    selector: 'CaseListModal',
    templateUrl: 'CaseListModal.html',
  })
  export class CaseListModal  {



    user=User;

    granted:FormGroup;
    rejected:FormGroup;
    constructor(
       public dialogRef: MatDialogRef<CaseListModal>,
  @Inject (MAT_DIALOG_DATA)  public data: {data},
  private caseService:CaseServiceProxy,
  private formBuilder: FormBuilder,
      private _dialog: MatDialog,){
        if(!data['isArchive']){
        this.granted = this.formBuilder.group({

          visaExpiry: [null,[Validators.required]],
          decisionToClient: [null,[Validators.required]],
          emailReviewed: [null,[Validators.required]],



          WorkCompleted:[0],
            CopyDeleted:[0],
            isOrganized:[0],
            titleDeeds:[0],
            DocReturn:[0],
            PrecedendSaved:[0],
            futureDates:[0],
            finalAmountPaid:[0],
            complaintsResolved:[0],
            dbUpdated:[0],
            workwrittenoff:[0],
            diswrittenoff:[0],
            debator:[0],
            clientAccountBalance:[0],
            clearedLedger:[0],
            archived:[0],
            workwrittenoffvalue:[0],
            diswrittenoffvalue:[0],
            debatorvalue:[0],
            clientAccountBalancevalue:[0],

        });

        this.rejected = this.formBuilder.group({

          rejectedForm: [null,[Validators.required]],

          WorkCompleted:[0],
            CopyDeleted:[0],
            isOrganized:[0],
            titleDeeds:[0],
            DocReturn:[0],
            PrecedendSaved:[0],
            futureDates:[0],
            finalAmountPaid:[0],
            complaintsResolved:[0],
            dbUpdated:[0],
            workwrittenoff:[0],
            diswrittenoff:[0],
            debator:[0],
            clientAccountBalance:[0],
            clearedLedger:[0],
            archived:[0],
            workwrittenoffvalue:[0],
            diswrittenoffvalue:[0],
            debatorvalue:[0],
            clientAccountBalancevalue:[0],
        });
      }
      else{

          this.granted = this.formBuilder.group({

            visaExpiry: [null,[Validators.required]],
            decisionToClient: [null,[Validators.required]],
            emailReviewed: [null,[Validators.required]],


            WorkCompleted:[null,[Validators.required]],
            CopyDeleted:[null,[Validators.required]],
            isOrganized:[null,[Validators.required]],
            titleDeeds:[null,[Validators.required]],
            DocReturn:[null,[Validators.required]],
            PrecedendSaved:[null,[Validators.required]],
            futureDates:[null,[Validators.required]],
            finalAmountPaid:[null,[Validators.required]],
            complaintsResolved:[null,[Validators.required]],
            dbUpdated:[null,[Validators.required]],
            workwrittenoff:[null,[Validators.required]],
            diswrittenoff:[null,[Validators.required]],
            debator:[null,[Validators.required]],
            clientAccountBalance:[null,[Validators.required]],
            clearedLedger:[null,[Validators.required]],
            archived:[null,[Validators.required]],
            workwrittenoffvalue:[0],
            diswrittenoffvalue:[null,[Validators.required]],
            debatorvalue:[null,[Validators.required]],
            clientAccountBalancevalue:[null,[Validators.required]],
          });

          this.rejected = this.formBuilder.group({

            rejectedForm: [null,[Validators.required]],


            WorkCompleted:[null,[Validators.required]],
            CopyDeleted:[null,[Validators.required]],
            isOrganized:[null,[Validators.required]],
            titleDeeds:[null,[Validators.required]],
            DocReturn:[null,[Validators.required]],
            PrecedendSaved:[null,[Validators.required]],
            futureDates:[null,[Validators.required]],
            finalAmountPaid:[null,[Validators.required]],
            complaintsResolved:[null,[Validators.required]],
            dbUpdated:[null,[Validators.required]],
            workwrittenoff:[null,[Validators.required]],
            diswrittenoff:[null,[Validators.required]],
            debator:[null,[Validators.required]],
            clientAccountBalance:[null,[Validators.required]],
            clearedLedger:[null,[Validators.required]],
            archived:[null,[Validators.required]],
            workwrittenoffvalue:[null,[Validators.required]],
            diswrittenoffvalue:[null,[Validators.required]],
            debatorvalue:[null,[Validators.required]],
            clientAccountBalancevalue:[null,[Validators.required]],


          });
      }
        // console.log(this.data)
        if(this.data['case']['visaExpiry']){
        this.granted.patchValue({ visaExpiry: this.data['case']['visaExpiry'], decisionToClient:this.data['case']['decisionToClient'],emailReviewed:this.data['case']['emailReviewed']})
       }else {
       if(this.data['case']['appealDescision'] ){
         this.rejected.patchValue({rejectedForm:"2"})

       }else if( this.data['case']['fileClosedwithInstructions']){
        this.rejected.patchValue({rejectedForm:"1"})
       }
      }
       }
       radioChange(e,cName){
         if(parseInt(e)===1){
          if(this.data['reject']){
              this.rejected.controls[cName].setValue(null);
          }else{
            this.granted.controls[cName].setValue(null)
          }
         }else{
          if(this.data['reject']){
            this.rejected.controls[cName].setValue(0);
        }else{
          this.granted.controls[cName].setValue(0);
        }
         }
         console.log(this.rejected.controls[cName].value);
       }
    reject(){
      let check = true;
      if('clientAccountBalancevalue' in this.rejected.controls && 'workwrittenoffvalue' in this.rejected.controls){
        check = true
        let clientAccountBalance =  Number(this.rejected.get('clientAccountBalancevalue').value);
        let workwrittenoffvalue = Number(this.rejected.get('workwrittenoffvalue').value);
        if(clientAccountBalance>workwrittenoffvalue){
          Swal.fire(
            'Attention!',
            'Please clear the balance before closing the file.',
            'warning'
          )
          check = false;
        }
      }


      if(check){
        var self = this;
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes'
      }).then((result) => {
        if (result.isConfirmed) {

      var _data = self.rejected.value;
      var rejectedForm = parseInt(_data['rejectedForm'])
      if(rejectedForm===1){
        self.data['case']['appealDescision'] = false
        self.data['case']['fileClosedwithInstructions'] = true;
      }else{
        self.data['case']['appealDescision'] = true
        self.data['case']['fileClosedwithInstructions'] = false;
      }
      if(self.data['isArchive']){
        self.data['case']['caseStatus']=3
      }else{
        self.data['case']['caseStatus'] = 2;
      }
      self.data['case']['addedByUserId']=self.user.Id
      self.data['case']['WorkCompleted'] = _data['WorkCompleted'];

      self.data['case']['CopyDeleted'] = _data['CopyDeleted'];

      self.data['case']['isOrganized'] = _data['isOrganized'];

      self.data['case']['titleDeeds'] = _data['titleDeeds'];

      self.data['case']['DocReturn'] = _data['DocReturn'];

      self.data['case']['PrecedendSaved'] = _data['PrecedendSaved'];

      self.data['case']['futureDates'] = _data['futureDates'];

      self.data['case']['finalAmountPaid'] = _data['finalAmountPaid'];

      self.data['case']['complaintsResolved'] = _data['complaintsResolved'];

      self.data['case']['dbUpdated'] = _data['dbUpdated'];

      self.data['case']['workwrittenoff'] = _data['workwrittenoff'];

      self.data['case']['diswrittenoff'] = _data['diswrittenoff'];


      self.data['case']['debator'] = _data['debator'];
      self.data['case']['clientAccountBalance'] = _data['clientAccountBalance'];
      self.data['case']['clearedLedger'] = _data['clearedLedger'];
      self.data['case']['archived'] = _data['archived'];
      self.data['case']['workwrittenoffvalue'] = _data['workwrittenoffvalue'];
      self.data['case']['diswrittenoffvalue'] = _data['diswrittenoffvalue'];


      self.data['case']['debatorvalue'] = _data['debatorvalue'];
      self.data['case']['clientAccountBalancevalue'] = _data['clientAccountBalancevalue'];
      self.data['addedByUserId']=User.Id;

      console.log(self.data['case']);
      this.caseService.PostCase(self.data['case']).subscribe(function(resp){
        self.close();
        Swal.fire(
          'Update!',
          'Case has been updated.',
          'success'
        )
      });
    }
  });
      }

    }
    grant(){

      let clientAccountBalance =  Number(this.granted.get('clientAccountBalancevalue').value);
      let workwrittenoffvalue = Number(this.granted.get('workwrittenoffvalue').value);
      if(clientAccountBalance>workwrittenoffvalue){
        Swal.fire(
          'Attention!',
          'Please clear the balance before closing the file.',
          'warning'
        )
      }
      else{
        var self = this;
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes'
      }).then((result) => {
        if (result.isConfirmed) {
      var _data = self.granted.value;
      if(self.data['isArchive']){
        self.data['case']['caseStatus']=3
      }else{
        self.data['case']['caseStatus'] = 2;
      }
      self.data['case']['visaExpiry'] = _data['visaExpiry'];
      self.data['case']['decisionToClient'] = _data['decisionToClient'];
      self.data['case']['emailReviewed'] = _data['emailReviewed'];

      self.data['case']['addedByUserId']=self.user.Id;
      self.data['case']['WorkCompleted'] = _data['WorkCompleted'];

      self.data['case']['CopyDeleted'] = _data['CopyDeleted'];

      self.data['case']['isOrganized'] = _data['isOrganized'];

      self.data['case']['titleDeeds'] = _data['titleDeeds'];

      self.data['case']['DocReturn'] = _data['DocReturn'];

      self.data['case']['PrecedendSaved'] = _data['PrecedendSaved'];

      self.data['case']['futureDates'] = _data['futureDates'];

      self.data['case']['finalAmountPaid'] = _data['finalAmountPaid'];

      self.data['case']['complaintsResolved'] = _data['complaintsResolved'];

      self.data['case']['dbUpdated'] = _data['dbUpdated'];

      self.data['case']['workwrittenoff'] = _data['workwrittenoff'];

      self.data['case']['diswrittenoff'] = _data['diswrittenoff'];


      self.data['case']['debator'] = _data['debator'];
      self.data['case']['clientAccountBalance'] = _data['clientAccountBalance'];
      self.data['case']['clearedLedger'] = _data['clearedLedger'];
      self.data['case']['archived'] = _data['archived'];
      self.data['case']['workwrittenoffvalue'] = _data['workwrittenoffvalue'];
      self.data['case']['diswrittenoffvalue'] = _data['diswrittenoffvalue'];


      self.data['case']['debatorvalue'] = _data['debatorvalue'];
      self.data['case']['clientAccountBalancevalue'] = _data['clientAccountBalancevalue'];

      self.data['addedByUserId']=User.Id;

      this.caseService.PostCase(self.data['case']).subscribe(function(resp){
        self.close();
        Swal.fire(
          'Update!',
          'Case has been updated.',
          'success'
        )
      });
        }else{
          self.close();
        }
      });
      }

    }
    close() {
      this.dialogRef.close();
    }
  }
